import _ from 'lodash'
import { store } from '../store'
import { TransferItem, EXCHANGES as tokenTransferExchangeConfigs, ACCOUNT_TYPES, TransferAccount, PairName } from '../components/account/TokenTransferEditor'
import { EXCHANGES_OPTION_PRICED_IN_COIN, SYMBOLS_WITH_MUTLIPLIER_IN_BTC } from '../configs/tradingConfig'
import { getSymbolAttributeByName, INSTRUMENT_TYPES } from './symbolUtil'

export const getPositionLiquidationRatio = (positionItem, pricingItem) => {
    let ratio = null
    if (!_.isNil(positionItem) && positionItem.liquidation_price && !_.isNil(pricingItem)) {
        const { bid, ask, last } = pricingItem
        const lastPrice = last ? last 
            : bid && ask ? (bid + ask) / 2
            : null
        if (lastPrice) {
            ratio = (positionItem.liquidation_price - lastPrice) / lastPrice
        }
    }
    return ratio
}

export const getMarginTradingLiquidationRatio = (marginAccountBalance, pricingItem) => {
    let ratio = null
    if (_.has(marginAccountBalance, 'liquidation_price') 
        && Number(marginAccountBalance.liquidation_price) !== 0
        && !_.isNil(pricingItem)) {
        const { bid, ask, last } = pricingItem
        const lastPrice = last ? last 
            : bid && ask ? (bid + ask) / 2
            : null
        if (lastPrice) {
            ratio = (marginAccountBalance.liquidation_price - lastPrice) / lastPrice
        }
    }
    return ratio
}

export const getNotional = ({ symbolItem, quantity, price, BTCUSDIndexLastPrice }) => {
    quantity = Number(quantity)
    price = Number(price)
    BTCUSDIndexLastPrice = Number(BTCUSDIndexLastPrice) || NaN
    let notional = null
    if (symbolItem) {
        const { symbol_name, multiplier, trading_in_notional } = symbolItem
        const { instrumentType, exchangeName } = getSymbolAttributeByName(symbol_name)
        if (instrumentType === INSTRUMENT_TYPES.REPO) {
            notional = null
        } else if (instrumentType === INSTRUMENT_TYPES.OPTION && EXCHANGES_OPTION_PRICED_IN_COIN.includes(exchangeName)) {
            notional = quantity * multiplier
        } else if (SYMBOLS_WITH_MUTLIPLIER_IN_BTC.includes(symbol_name)) {
            notional = quantity * multiplier * price * BTCUSDIndexLastPrice
        } else {
            notional = quantity * multiplier * (trading_in_notional === '1' ? 1 : price)
        }
    }

    return notional
}

export const getQuantityByNotional = ({ symbolItem, notional, price, BTCUSDIndexLastPrice }) => {
    notional = Number(notional)
    price = Number(price)
    BTCUSDIndexLastPrice = Number(BTCUSDIndexLastPrice) || NaN
    let quantity = null
    if (symbolItem) {
        const { symbol_name, multiplier, trading_in_notional } = symbolItem
        if (SYMBOLS_WITH_MUTLIPLIER_IN_BTC.includes(symbol_name)) {
            quantity = Math.floor(notional / BTCUSDIndexLastPrice / price / multiplier)
        } else {
            quantity = Math.floor(notional / (trading_in_notional === '1' ? 1 : price) / multiplier)
        }
    }
    return quantity
}

export const filterAccountItemsByExchangeName = (accountItems, exchangeName) => {
    exchangeName = ['OKEXSPOT', 'OKEXSPOTMARGIN', 'OKEXCONTRACT', 'OKEXUSDSWAP', 'OKEXUSDTSWAP'].includes(exchangeName) ? 'OKEX' 
        : ['HUOBIFUTSWAP'].includes(exchangeName) ? 'HUOBIFUT' 
        : ['BNBFUTAUSDT', 'BNBFUTABUSD', 'BNBFUTACOIN'].includes(exchangeName) ? 'BNBFUTA'
        : ['PHEMEXBTCCONTRACT', 'PHEMEXUSDCONTRACT'].includes(exchangeName) ? 'PHEMEX'
        : ['FTXSPOT', 'FTXFUT', 'FTXOPT'].includes(exchangeName) ? 'FTX'
        : exchangeName // It is fucking dirty here
    return _.filter(accountItems, accountItem => accountItem.exchange_name === exchangeName)
}

export const getPositionItemTransferAdvice = (positionItem={}) => {
    const { items: accountItems } = store.getState().account
    const { product_name: symbolName, account_name: accountName } = positionItem
    const result = {
        token: null,
        transferItem: null,
        validOriginTransferAccountNames: []
    }

    if (!_.isNil(symbolName) && _.has(accountItems, accountName)) {
        const { base, quote, exchangeName, instrumentType } = getSymbolAttributeByName(symbolName)
        const accountItem = accountItems[accountName]
        const tokenTransferExchangeConfig = tokenTransferExchangeConfigs[exchangeName]

        let tokenToTransfer = null, destinationTransferAccountType = null
        if (exchangeName === 'HUOBIFUT') {
            tokenToTransfer = quote === 'USDT' ? 'USDT' : base
            destinationTransferAccountType = instrumentType === INSTRUMENT_TYPES.FUTURE ? ACCOUNT_TYPES.FUTURE 
                : instrumentType === INSTRUMENT_TYPES.SWAP ? ACCOUNT_TYPES.SWAP
                : null
        } else if (exchangeName === 'BNBFUTA') {
            tokenToTransfer = ['USDT', 'BUSD'].includes(quote) ? quote : base
            destinationTransferAccountType = ['USDT', 'BUSD'].includes(quote) ? ACCOUNT_TYPES.BINANCE_USDT_FUTURES : ACCOUNT_TYPES.BINANCE_COIN_FUTURES
        } else if (symbolName === 'btc_usd_PHEMEX_SWAP') {
            tokenToTransfer = base
            destinationTransferAccountType = ACCOUNT_TYPES.PHEMEX_BTC_CONTRACT
        } else if (exchangeName === 'FTX') {
            tokenToTransfer = 'USD'
            destinationTransferAccountType = ACCOUNT_TYPES.FTX_WALLET
        } else if (exchangeName === 'OKEX') {
            tokenToTransfer = 'USDT'
            destinationTransferAccountType = ACCOUNT_TYPES.OKEX_UNIFIED_ACCOUNT
        }

        if (!_.isNil(tokenToTransfer) && !_.isNil(destinationTransferAccountType)) {
            const destinationTransferAccount = TransferAccount({
                accountName,
                accountType: destinationTransferAccountType,
                pairName: _.has(tokenTransferExchangeConfig, 'shouldDefinePairName') && _.isFunction(tokenTransferExchangeConfig.shouldDefinePairName) 
                    && tokenTransferExchangeConfig.shouldDefinePairName({ 
                        tokenToTransfer, 
                        accountTypeKey: _.has(destinationTransferAccountType, 'key') ? destinationTransferAccountType.key : null 
                    }) 
                    ? (accountItem.exchange_name === 'HUOBIFUT' && instrumentType === INSTRUMENT_TYPES.SWAP && quote === 'USDT' 
                        ? 'USDT' 
                        : PairName(base, quote))
                    : null
            })

            let originTransferAccount = null
            if (accountItem.exchange_name === 'BNBFUTA') {
                const originAccountName = accountItem.portfolio_name === 'orchid' ? 'binance_orchid1'
                    : accountItem.portfolio_name === 'panda' ? 'binance_panda'
                    : 'binance_mining001'
                if (_.has(accountItems, originAccountName) && accountItems[originAccountName].exchange_name === 'BINANCE') {
                    originTransferAccount = TransferAccount({
                        accountName: originAccountName,
                        accountType: ACCOUNT_TYPES.CROSS_MARGIN,
                        pairName: null
                    })
                }
            } else if (accountItem.exchange_name === 'PHEMEX'
                && (accountItem.is_main === '1' || !_.isEmpty(accountItem.main_acct_name))) {
                originTransferAccount = TransferAccount({
                    accountName: accountItem.is_main === '1' ? accountName : accountItem.main_acct_name,
                    accountType: ACCOUNT_TYPES.SPOT,
                    pairName: null
                })
            } else if (accountItem.exchange_name === 'FTX' && _.has(accountItems, 'ftx_vault')) {
                originTransferAccount = TransferAccount({
                    accountName: 'ftx_vault',
                    accountType: ACCOUNT_TYPES.FTX_WALLET,
                    pairName: null
                })
            } else if (accountItem.exchange_name === 'DERIBIT' && accountItem.is_main !== '1') {
                originTransferAccount = TransferAccount({
                    accountName: accountItem.is_main === '1' ? accountName : accountItem.main_acct_name,
                    accountType: ACCOUNT_TYPES.SWAP,
                    pairName: null
                })
            } else if (accountItem.exchange_name === 'OKEX') {
                originTransferAccount = TransferAccount({
                    accountName: accountItem.is_main === '1' ? accountName : accountItem.main_acct_name,
                    accountType: ACCOUNT_TYPES.OKEX_FUNDING_ACCOUNT,
                    pairName: null
                })
            } else {
                originTransferAccount = TransferAccount({})
            }

            result.token = tokenToTransfer
            result.transferItem = TransferItem({
                originTransferAccount,
                destinationTransferAccount
            })
            result.validOriginTransferAccountNames = _.has(tokenTransferExchangeConfig, 'getCounterTransferAccountNames') && _.isFunction(tokenTransferExchangeConfig.getCounterTransferAccountNames)
                ? tokenTransferExchangeConfig.getCounterTransferAccountNames({ tokenToTransfer, transferAccount: destinationTransferAccount, accountItems })
                : []
        }
    }

    return result
}

export const getCrossMarginAccountTransferAdivce = (crossMarginAccountBalance) => {
    const { acct_name: accountName, coin } = crossMarginAccountBalance
    const { items: accountItems } = store.getState().account
    const result = {
        token: null,
        transferItem: null,
        validOriginTransferAccountNames: []
    }

    const accountItem = accountItems[accountName]
    const accountExchangeName = _.has(accountItem, 'exchange_name') ? accountItem.exchange_name : null
    const tokenTransferExchangeConfig = tokenTransferExchangeConfigs[accountExchangeName]
    
    let tokenToTransfer, originTransferAccount, destinationTransferAccount, validOriginTransferAccountNames=[]
    if (accountExchangeName === 'BINANCE') {
        tokenToTransfer = _.upperCase(coin || '')
        originTransferAccount = accountItem.is_main === '1' || !_.isEmpty(accountItem.main_acct_name)
            ? TransferAccount({
                accountName: accountItem.is_main === '1' ? accountName : accountItem.main_acct_name,
                accountType: ACCOUNT_TYPES.SPOT,
                pairName: null
            }) : TransferAccount({})

        destinationTransferAccount = TransferAccount({
            accountName,
            accountType: ACCOUNT_TYPES.CROSS_MARGIN,
            pairName: null
        })

        validOriginTransferAccountNames = _.has(tokenTransferExchangeConfig, 'getCounterTransferAccountNames') && _.isFunction(tokenTransferExchangeConfig.getCounterTransferAccountNames)
            ? tokenTransferExchangeConfig.getCounterTransferAccountNames({ tokenToTransfer, transferAccount: destinationTransferAccount, accountItems })
            : []
    }

    if (!_.isNil(tokenToTransfer) && !_.isNil(destinationTransferAccount)) {
        result.token = tokenToTransfer
        result.transferItem = TransferItem({
            originTransferAccount,
            destinationTransferAccount
        })
        result.validOriginTransferAccountNames = validOriginTransferAccountNames
    }

    return result
}

export const getRiskRatioThresholdByPositionItem = (riskRatioThresholds={}, positionItem={}) => {
    const { account_name: accountName, product_name: productName } = positionItem
    return riskRatioThresholds[`${productName}--${accountName}`] || riskRatioThresholds[productName] || null
}