import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'

import { toNumberWithSmartPrecision } from '../../util/util'

export default class SwapAccountBalanceItem extends Component {

    Row ({ name, value, displayValue, hasDirection }) {
        return (
            <div className='swap-account-balance-item--row'>
                <div className='swap-account-balance-item--row--name'>{name}</div>
                <div className={'swap-account-balance-item--row--value' + (value > 0 && hasDirection? ' positive' : value < 0 && hasDirection? ' negative' : '')}>
                    {!_.isNil(displayValue) ? displayValue : value}
                </div>
            </div>
        )
    }

    InlineData ({ name, value, shouldHighlight }) {
        return (
            <div className='swap-account-balance-item--inline-data'>
                <span className='swap-account-balance-item--inline-data--name'>{name}</span>
                <div className={'swap-account-balance-item--inline-data--value' + (shouldHighlight ? ' highlight' : '')}>{value}</div>
            </div>
        )
    }

    render () {
        const { swapAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName, shouldShowDetail } = this.props
        return (
            <div className='swap-account-balance-item'>
                <div className='swap-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='swap-account-balance-item--header--info-bar'>
                        <div className='swap-account-balance-item--header--info-bar--account-type swap'>{'SWAP'}</div>
                        <div className='swap-account-balance-item--header--info-bar--timestamp'>{moment(swapAccountBalance.timestamp).format('HH:mm:ss')}</div>
                    </div>}
                    <div className='swap-account-balance-item--coin'>
                        {swapAccountBalance.coin}
                        {shouldShowAccountName ? ` @ ${swapAccountBalance.acct_name}` : ''}
                    </div>
                    {!shouldShowAccountTypeTag && <div className='swap-account-balance-item--timestamp'>{moment(swapAccountBalance.timestamp).format('HH:mm:ss')}</div>}
                </div>
                {shouldShowDetail && 
                <div className='swap-account-balance-item--rows'>
                    {this.Row({
                        name: 'Realized P/L',
                        value: Number(swapAccountBalance.realized_pnl),
                        displayValue: toNumberWithSmartPrecision({ number: swapAccountBalance.realized_pnl, shouldReturnLocalString: true }),
                        hasDirection: true
                    })}
                    {this.Row({
                        name: 'Unrealized P/L',
                        value: Number(swapAccountBalance.unrealized_pnl),
                        displayValue: toNumberWithSmartPrecision({ number: swapAccountBalance.unrealized_pnl, shouldReturnLocalString: true }),
                        hasDirection: true
                    })}
                    {this.Row({
                        name: 'Margin Frozen',
                        value: Number(swapAccountBalance.margin_frozen),
                        displayValue: toNumberWithSmartPrecision({ number: swapAccountBalance.margin_frozen, shouldReturnLocalString: true })
                    })}
                    {!_.isEmpty(_.toString(swapAccountBalance.transferable)) && this.Row({
                        name: 'Transferable',
                        value: Number(swapAccountBalance.transferable),
                        displayValue: toNumberWithSmartPrecision({ number: swapAccountBalance.transferable, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                        hasDirection: false
                    })}
                </div>}
                <div className='swap-account-balance-item--summary'>
                    {this.InlineData({
                        name: 'Equity',
                        value: toNumberWithSmartPrecision({ number: swapAccountBalance.equity, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {this.InlineData({
                        name: 'Margin',
                        value: toNumberWithSmartPrecision({ number: swapAccountBalance.margin, shouldReturnLocalString: true})
                    })}
                    {this.InlineData({
                        name: 'Avail Balance',
                        value: toNumberWithSmartPrecision({ number: swapAccountBalance.total_avail_balance, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                </div>
            </div>
        )
    } 
}

SwapAccountBalanceItem.propTypes = {
    swapAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool,
    shouldShowDetail: PropTypes.bool,
}