import _ from 'lodash'

export const RECEIVED_WEBSOCKET_MESSAGE_BUFFER_INTERVAL = 3200
export const RECOVER_PROFILE_STATE_CHECKING_INTERVAL = 4000 // !!! This value should be larger than RECEIVED_WEBSOCKET_MESSAGE_BUFFER_INTERVAL !!!

const server = ({ hostname, locationName, apiBaseUrl, wsBaseUrl, enabled, profileDisabled }) => {
    return {
        hostname, apiBaseUrl, wsBaseUrl, locationName, enabled, profileDisabled
    }
}

export const SERVERS = {
    devland: server({
        hostname: 'devland',
        locationName: 'devland',
        apiBaseUrl: 'https://devland.devicesformula.com/v1',
        wsBaseUrl: 'wss://devland.devicesformula.com/stream',
        enabled: process.env.REACT_APP_DEVLAND_ENABLED === 'TRUE',
        profileDisabled: false
    }),
    hk0c1: server({
        hostname: 'hk0c1',
        locationName: 'hk0c1',
        apiBaseUrl: 'https://hk0c1.devicesformula.com/v1',
        wsBaseUrl: 'wss://hk0c1.devicesformula.com/stream',
        enabled: process.env.REACT_APP_PRODUCTION_SERVERS_ENABLED === 'TRUE',
        profileDisabled: false
    }),
    tk1a1: server({
        hostname: 'tk1a1',
        locationName: 'tk1a1',
        apiBaseUrl: 'https://tk1a1.devicesformula.com/v1',
        wsBaseUrl: 'wss://tk1a1.devicesformula.com/stream',
        enabled: process.env.REACT_APP_PRODUCTION_SERVERS_ENABLED === 'TRUE',
        profileDisabled: false
    }),
    tk1c1: server({
        hostname: 'tk1c1',
        locationName: 'tk1c1',
        apiBaseUrl: 'https://tk1c1.devicesformula.com/v1',
        wsBaseUrl: 'wss://tk1c1.devicesformula.com/stream',
        enabled: process.env.REACT_APP_PRODUCTION_SERVERS_ENABLED === 'TRUE',
        profileDisabled: false
    }),
    tk1c2: server({
        hostname: 'tk1c2',
        locationName: 'tk1c2',
        apiBaseUrl: 'https://tk1c2.devicesformula.com/v1',
        wsBaseUrl: 'wss://tk1c2.devicesformula.com/stream',
        enabled: process.env.REACT_APP_PRODUCTION_SERVERS_ENABLED === 'TRUE',
        profileDisabled: true
    }),
    mt1d1: server({
        hostname: 'mt1d1',
        locationName: 'mt1d1',
        apiBaseUrl: 'https://mt1d1.devicesformula.com/v1',
        wsBaseUrl: 'wss://mt1d1.devicesformula.com/stream',
        enabled: process.env.REACT_APP_PRODUCTION_SERVERS_ENABLED === 'TRUE',
        profileDisabled: false
    }),
    sg1a1: server({
        hostname: 'sg1a1',
        locationName: 'sg1a1',
        apiBaseUrl: 'https://sg1a1.devicesformula.com/v1',
        wsBaseUrl: 'wss://sg1a1.devicesformula.com/stream',
        enabled: process.env.REACT_APP_PRODUCTION_SERVERS_ENABLED === 'TRUE',
        profileDisabled: false
    })
}

const DEFAULT_SERVER_HOSTNAME = Object.keys(SERVERS).includes(process.env.REACT_APP_DEFAULT_SERVER_HOSTNAME)
    ? process.env.REACT_APP_DEFAULT_SERVER_HOSTNAME
    : SERVERS.tk1c1.hostname
export const DEFAULT_SERVER = SERVERS[DEFAULT_SERVER_HOSTNAME]
export const ELF_API_BASE_URL = process.env.REACT_APP_ELF_API_BASE_URL
export const ELF_WEBSOCKET_URL = process.env.REACT_APP_ELF_WEBSOCKET_URL
export const ORDER_EDITOR_MULTIPLE_ACCOUNT_BLACKLIST = []

export const getApiBaseUrlByHostname = (hostname) => {
    const server = _.find(SERVERS, { hostname })
    return _.has(server, 'apiBaseUrl') ? server.apiBaseUrl : null
}

export const TRADERS = {
    BoxiaoXu: 'BoxiaoXu',
    HuiwenZhu: 'HuiwenZhu',
    JiayiLin: 'JiayiLin',
    LeonDing: 'LeonDing',
    StevenLi: 'StevenLi',
    WenzhenDong: 'WenzhenDong',
    ZexuanZhang: 'ZexuanZhang'
}