import _ from 'lodash'
import { Howl, Howler } from 'howler'

export const SOUND_EFFECTS = {
    READY_CHECK: {
        value: 'READY_CHECK',
        name: 'Ready Check',
        audio: require('../audio/ready_check.webm')
    },
    QUEST: {
        value: 'QUEST',
        name: 'Quest Added',
        audio: require('../audio/quest.webm')
    },
    QUEST_COMPLETE: {
        value: 'QUEST_COMPLETE',
        name: 'Quest Complete',
        audio: require('../audio/quest_complete.webm')
    },
    FLAG_HORDE: {
        value: 'FLAG_HORDE',
        name: 'Flag Horde',
        audio: require('../audio/flag_horde.webm')
    },
    FLAG_TAKEN: {
        value: 'FLAG_TAKEN',
        name: 'Flag Taken',
        audio: require('../audio/flag_taken.webm')
    },
    ENQUEUE: {
        value: 'ENQUEUE',
        name: 'Enqueue',
        audio: require('../audio/enqueue.webm')
    },
    ALARM_WARNING: {
        value: 'ALARM_WARNING',
        name: 'Alarm Warning',
        audio: require('../audio/alarm_warning.webm')
    },
    RAID_WARNING: {
        value: 'RAID_WARNING',
        name: 'Raid Warning',
        audio: require('../audio/raid_warning.webm')
    },
    ALLIANCE_WARNING: {
        value: 'ALLIANCE_WARNING',
        name: 'Alliance Warning',
        audio: require('../audio/alliance_warning.webm')
    }
}

const howlerObjects = _.mapValues(SOUND_EFFECTS, soundEffect => {
    return {
        sound: new Howl({
            src: soundEffect.audio,
            preload: true
        }),
        locked: false
    }
})

export const playSound = (key) => {
    const howlerObj = howlerObjects[key]
    const isAudioContextSuspended = _.has(Howler, 'ctx') && Howler.ctx.state === 'suspended'
    if (howlerObj && !isAudioContextSuspended
        && !howlerObj.sound.playing()
        && !howlerObj.locked) {
        howlerObj.locked = true
        howlerObj.sound.play()
        setTimeout(() => {
            howlerObj.locked = false
        }, 800)
    }
}
