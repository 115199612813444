import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { SERVERS } from '../../configs/config'

const READY_STATE_TO_TITLE_MAPS = {
    [`${WebSocket.CONNECTING}`]: 'CONNECTING',
    [`${WebSocket.OPEN}`]: 'OPEN',
    [`${WebSocket.CLOSING}`]: 'CLOSING',
    [`${WebSocket.CLOSED}`]: 'CLOSED'
}

class SocketConnection extends Component {
    constructor (props) {
        super(props)
    }

    render () {
        const { webSocket, hostname, shouldHideDelay, shouldHideReconnectInfo } = this.props
        const socket = webSocket[hostname]
        const locationName = _.has(SERVERS, `${hostname}.locationName`) ? SERVERS[hostname].locationName : null
        const minDelayMilliseconds = _.min(_.map(webSocket, s => Number(s.delayMilliseconds)))
        const shouldUseBenchmark = Number(minDelayMilliseconds) < 0

        return !_.isNil(socket) ? (
            <div className='socket-connection' title={READY_STATE_TO_TITLE_MAPS[socket.readyState]}>
                {!shouldHideReconnectInfo && _.isNumber(socket.reconnectTimeout) && <div className={'socket-connection--reconnect-timeout'}>{`Reconnect in ${socket.reconnectTimeout}s`}</div>}
                <div className='socket-connection--text'>
                    {!shouldHideDelay 
                    && socket.readyState === 1 
                    && !_.isNil(socket.delayMilliseconds)
                    && 
                    <span className='socket-connection--text--delay'>
                        {shouldUseBenchmark 
                        ? (socket.delayMilliseconds  === minDelayMilliseconds ? '-' : `+${socket.delayMilliseconds - minDelayMilliseconds} ms`) 
                        : `${socket.delayMilliseconds} ms`}
                    </span>}
                    {socket.isFetchingTicket && <span className='socket-connection--fetching-ticket'>{'Fetching Ticket'}</span>}
                    {locationName}
                </div>
                <div className={`socket-connection--bulb vertical-centered ready-state-${socket.readyState}`} />
            </div>
        ) : null
    }
}

SocketConnection.propTypes = {
    webSocket: PropTypes.object.isRequired,
    
    hostname: PropTypes.string.isRequired,
    shouldHideDelay: PropTypes.bool,
    shouldHideReconnectInfo: PropTypes.bool
}

function mapStateToProps (state) {
    return {
        webSocket: state.webSocket
    }
}

export default connect(mapStateToProps)(SocketConnection)