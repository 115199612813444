import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'

import { toNumberWithSmartPrecision } from '../../util/util'
import { getPricePrecisionBySymbolItem } from '../../util/symbolUtil'

class OrderBook extends Component {

    getSeivedOrderBook () {
        const { symbolOrderBook } = this.props
        const result = _.cloneDeep(symbolOrderBook)
        if (result && _.isArray(result.bids) && _.isArray(result.asks)) {
            _.remove(result.bids, bidItem => bidItem.price === 0)
            _.remove(result.asks, askItem => askItem.price === 0)
            result.bids = _.take(_.sortBy(result.bids, bidItem => -bidItem.price), 5)
            result.asks = _.take(_.sortBy(result.asks, askItem => askItem.price), 5)
            let bidCumulativeQty = 0, askCumulativeQty = 0
            result.bids.forEach(bidItem => {
                bidCumulativeQty += bidItem.qty
                bidItem.cumulativeQty = bidCumulativeQty
            })
            result.asks.forEach(askItem => {
                askCumulativeQty += askItem.qty
                askItem.cumulativeQty = askCumulativeQty
            })
            _.reverse(result.asks)
        }
        return result
    }

    Block (side = 'buy') {
        const { symbolItem, onClickPrice, onClickAmount } = this.props
        const seivedOrderBook = this.getSeivedOrderBook()
        const bidCumulativeQty = seivedOrderBook && !_.isEmpty(seivedOrderBook.bids) ? _.last(seivedOrderBook.bids).cumulativeQty : 0
        const askCumulativeQty = seivedOrderBook && !_.isEmpty(seivedOrderBook.asks) ? _.first(seivedOrderBook.asks).cumulativeQty : 0
        const data = seivedOrderBook ? (seivedOrderBook[side === 'buy'  ? 'bids' : 'asks'] || []) : []
        const maxCumulativeQty = Math.max(bidCumulativeQty, askCumulativeQty)
        return (
            <div className={`order-book--block ${side}`}>
                {data.map((item, index) => {
                    const { price, qty, cumulativeQty } = item
                    const pricePrecision = getPricePrecisionBySymbolItem(symbolItem)
                    const priceNumber = Number(price).toLocaleString(undefined, { minimumFractionDigits: pricePrecision, maximumFractionDigits: pricePrecision, useGrouping: true })
                    const amountNumber = toNumberWithSmartPrecision({ 
                        number: qty, 
                        shouldReturnLocalString: true 
                    })
                    return (
                        <div className='order-book--block--item clearfix' key={index}>
                            <div className={`order-book--block--item--cumulative-amount-bar ${side}`} 
                                style={{
                                    width: (cumulativeQty / maxCumulativeQty) * 100 + '%'
                                }} />
                            <div className={`order-book--block--item--price ${side}`} onClick={() => { onClickPrice(price) }}>{priceNumber}</div>
                            <div className='order-book--block--item--amount' onClick={() => { onClickAmount(qty) }}>{amountNumber}</div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render () {
        const { symbolItem, symbolOrderBook, shouldHideTitle, onClickReSubscribeButton } = this.props
        return (
            <div className='order-book'>
                {!shouldHideTitle && <div className='order-book--title'>
                    {'Order Book'}
                    {_.has(symbolOrderBook, 'timestamp') && <span className='order-book--timestamp'>{moment(symbolOrderBook.timestamp).format('HH:mm:ss')}</span>}
                </div>}
                <div className='order-book--body'>
                    <div className='order-book--body--header clearfix'>
                        <div className='order-book--body--header--text price'>{'Price'}</div>
                        <div className='order-book--body--header--text amount'>{'Amount'}</div>
                    </div>
                    <div className='order-book--body--main'>
                        {this.Block('sell')}
                        {this.Block('buy')}
                    </div>
                    <div className='order-book--body--footer clearfix'>
                        {_.has(symbolOrderBook, 'timestamp') && <span className='order-book--body--footer--timestamp'>{moment(symbolOrderBook.timestamp).format('HH:mm:ss')}</span>}
                        {symbolItem && <button className='order-book--body--footer--resubscribe-button' onClick={() => { onClickReSubscribeButton() }}>{'Re-Subscribe'}</button>}
                    </div>
                </div>
            </div>
        )
    }
}

OrderBook.propTypes = {
    symbolItem: PropTypes.object.isRequired,
    symbolOrderBook: PropTypes.object,
    shouldHideTitle: PropTypes.bool,

    onClickPrice: PropTypes.func,
    onClickAmount: PropTypes.func,
    onClickReSubscribeButton: PropTypes.func
}

OrderBook.defaultProps = {
    shouldHideTitle: false,
    onClickPrice: () => {},
    onClickAmount: () => {},
    onClickReSubscribeButton: () => {}
}

function mapStateToProps (state, ownProps) {
    return {
        symbolOrderBook: state.symbol.orderBook[ownProps.symbolItem.symbol_name]
    }
}

export default connect(mapStateToProps)(OrderBook)

