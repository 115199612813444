import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import dotProp from 'dot-prop-immutable'
import _ from 'lodash'
import moment from 'moment'

import LegPopup from './LegPopup'
import SymbolPopup from '../symbol/NewSymbolPopup'
import ProfileLiquidations from './ProfileLiquidations'
import ProfileSwitches from './ProfileSwitches'

import { toNumberInputValue, toFixedNumber, toNumberWithSmartPrecision } from '../../util/util'
import { getSymbolFundingRatePrecision, isDynamicFundingRateSymbol, INSTRUMENT_TYPES, 
    getSymbolAttributeByName, getPricePrecisionBySymbolItem } from '../../util/symbolUtil'
import { getProfileAccountNameBySymbol, getProfileTradingAccountNamesBySymbol, shouldProfileLegHaveLiquidationColumn } from '../../util/profileUtil'
import { getNotional } from '../../util/tradingUtil'
import { EXCHANGES_CAN_USE_REDUCE_ONLY } from '../../configs/tradingConfig'
import ProfileReduceOnlySwitches from './ProfileReduceOnlySwitches'


class HedgeSymbolTable extends Component {

    shouldComponentUpdate (nextProps, nextState) {
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
    }

    _hasSymbolWithFundingRate () {
        const { profile, symbol } = this.props
        const fundingRateSymbolNames = Object.keys(symbol.fundingRates)
        const result = _.has(profile, `legs.2.symbols`) && _.isArray(profile.legs[2].symbols)
            ? profile.legs[2].symbols.reduce((hasFundingRate, symbolItem) => { return hasFundingRate || fundingRateSymbolNames.includes(symbolItem.name) }, false)
            : false
        return result
    }

    _hasReduceOnlyColumn () {
        const { profile } = this.props
        return _.has(profile, 'legs.2.symbols') && _.some(profile.legs['2'].symbols, profileSymbolItem => {
            const { exchangeName, instrumentType } = getSymbolAttributeByName(profileSymbolItem.name)
            return EXCHANGES_CAN_USE_REDUCE_ONLY.includes(exchangeName) && instrumentType !== INSTRUMENT_TYPES.SPOT
        })
    }


    isSymbolSwitchedOff ({ symbolName, accountName, side }) {
        const { runningState } = this.props
        return _.isArray(runningState.switchOffs)
            && _.some(runningState.switchOffs, { symbol: symbolName, account: accountName, side: side })
    }

    getSymbolSwitchOffItem ({ symbolName, accountName, side }) {
        const { runningState } = this.props
        return _.isArray(runningState.switchOffs)
            ? _.find(runningState.switchOffs, { symbol: symbolName, account: accountName, side: side })
            : null
    }

    handleKeyDownInput (e) {
        const { onKeyDownEnter } = this.props
        if (e.key === 'Enter') {
            onKeyDownEnter()
        }
    }

    renderSymbol (symbolItem, index) {       
        const { profile, runningState, symbol, positions, account, onChangeProfile } = this.props
        const { strategyInfo } = runningState
        const { instrumentType, quote } = getSymbolAttributeByName(symbolItem.name)
        const hasSymbolWithFundingRate = this._hasSymbolWithFundingRate()
        const hasLiquidationColumn = shouldProfileLegHaveLiquidationColumn({ profileItem: profile, legKey: '2', positionItems: positions, marginAccountBalances: account.balance.margin })
        const hasReduceOnlyColumn = this._hasReduceOnlyColumn()
        const symbolAccountName = getProfileAccountNameBySymbol(profile, symbolItem.name)
        const symbolTradingAccountNames = getProfileTradingAccountNamesBySymbol(profile, symbolItem.name)
        const unionTradingAccountNames = _.uniq([...symbolTradingAccountNames.BUY, symbolTradingAccountNames.SELL])
        const symbolPositionData = profile.started && _.has(runningState, `position.${symbolItem.name}`) && !_.isEmpty(runningState.position[symbolItem.name]) 
            ? runningState.position[symbolItem.name] 
            : {}
        const pricePrecision = _.has(symbol.items, symbolItem.name) ? getPricePrecisionBySymbolItem(symbol.items[symbolItem.name]) : 5

        const pricingItem = symbol.pricings[symbolItem.name]
        const lastPrice = pricingItem 
            ? (pricingItem.last ? pricingItem.last : pricingItem.bid && pricingItem.ask ? (pricingItem.bid + pricingItem.ask) / 2 : null)
            : null

        const netPosition = _.sumBy(Object.values(symbolPositionData), 'net') || 0
        const netPositionNotional = getNotional({
            symbolItem: symbol.items[symbolItem.name],
            quantity: netPosition,
            price: lastPrice,
            BTCUSDIndexLastPrice: _.has(symbol.pricings, `btc_usd_OKEX_INDEX.last`) ? symbol.pricings['btc_usd_OKEX_INDEX'].last : null
        })

        const profilePortfolioNames = _.uniq(_.map(unionTradingAccountNames, accountName => {
            return _.has(account, `items.${accountName}.portfolio_name`)
                ? account.items[accountName].portfolio_name
                : null
        }))

        const symbolPositionNotionalSum = _.filter(positions, position => {
            const { product_name, account_name } = position
            const accountPortfolioName = _.has(account, `items.${account_name}.portfolio_name`) ? account.items[account_name].portfolio_name : null
            return product_name === symbolItem.name && accountPortfolioName && profilePortfolioNames.includes(accountPortfolioName)
        }).reduce((result, positionItem) => {
            const positionNotional = getNotional({
                symbolItem: symbol.items[symbolItem.name],
                quantity: Number(positionItem.long_position) - Number(positionItem.short_position),
                price: lastPrice,
                BTCUSDIndexLastPrice: _.has(symbol.pricings, `btc_usd_OKEX_INDEX.last`) ? symbol.pricings['btc_usd_OKEX_INDEX'].last : null
            })
            return result + Number(positionNotional)
        }, 0)

        let bid, ask
        if (_.has(strategyInfo, `symbols.${symbolItem.name}`)) {
            const strategySymbolInfo = strategyInfo.symbols[symbolItem.name]
            if (_.isArray(strategySymbolInfo.price)) {
                bid = strategySymbolInfo.price[0]
                ask = strategySymbolInfo.price[1]
            }
        }

        const renderPricingData = (direction) => {
            const directionName = direction === 'BUY' ? 'ask' : 'bid'
            const displayPrice = direction === 'BUY' && _.isNumber(ask) ? ask.toFixed(pricePrecision)
                : direction === 'SELL' && _.isNumber(bid) ? bid.toFixed(pricePrecision)
                : 'N/A'
            return (
                <td className={'hedge-symbol-table--pricing-data ' + direction}>
                    <span className={`hedge-symbol-table--pricing-direction-label ${directionName}`}>{directionName}</span>
                    <span>{displayPrice}</span>
                </td>
            )
        }

        const renderPositionData = (direction) => {
            const positionData = symbolPositionData[symbolAccountName[direction]] || {}
            const longPosition = positionData && !_.isNull(positionData.long) ? positionData.long : null
            const shortPosition = positionData && !_.isNull(positionData.short) ? positionData.short : null
            const virtualLongPosition = !_.isNull(longPosition) ? (longPosition + (positionData.open_long || 0)) : null
            const virtualShortPosition = !_.isNull(shortPosition) ? (shortPosition + (positionData.open_short || 0)) : null
            
            const fixedVirtualLongPosition = Number(toFixedNumber(virtualLongPosition, 4))
            const fixedVirtualShortPosition = Number(toFixedNumber(virtualShortPosition, 4))

            const virtualLongPositionNotional = _.isFinite(fixedVirtualLongPosition)
                ? getNotional({
                    symbolItem: symbol.items[symbolItem.name],
                    quantity: fixedVirtualLongPosition,
                    price: lastPrice,
                    BTCUSDIndexLastPrice: _.has(symbol.pricings, `btc_usd_OKEX_INDEX.last`) ? symbol.pricings['btc_usd_OKEX_INDEX'].last : null
                })
                : null
            const virtualShortPositionNotional = _.isFinite(fixedVirtualShortPosition)
                ? getNotional({
                    symbolItem: symbol.items[symbolItem.name],
                    quantity: fixedVirtualShortPosition,
                    price: lastPrice,
                    BTCUSDIndexLastPrice: _.has(symbol.pricings, `btc_usd_OKEX_INDEX.last`) ? symbol.pricings['btc_usd_OKEX_INDEX'].last : null
                })
                : null

            const hasBuySideNotional = direction === 'BUY' && Number(virtualLongPositionNotional) !== 0
            const hasSellSideNotional = direction === 'SELL' && Number(virtualShortPositionNotional) !== 0
            return (
                <td className={'hedge-symbol-table--position-data ' + direction}>
                    {(profile.started || profile.isStarting) 
                    ? <div className={'quote-symbol-table--position-data--vpos' + ((hasBuySideNotional || hasSellSideNotional) ? ' has-notional' : '')}>
                        {(direction === 'BUY' 
                        ? (_.isFinite(fixedVirtualLongPosition) ? fixedVirtualLongPosition : '0') 
                        : _.isFinite(fixedVirtualShortPosition) ? fixedVirtualShortPosition : '0')}
                        {hasBuySideNotional && 
                        <div className='hedge-symbol-table--position-data--vpos-notional'>
                            {`${toNumberWithSmartPrecision({ number: virtualLongPositionNotional, shouldReturnLocalString: true, defaultPrecision: quote === 'BTC' ? 2 : 0 })} ${quote}`}
                        </div>}
                        {hasSellSideNotional && 
                        <div className='hedge-symbol-table--position-data--vpos-notional'>
                            {`${toNumberWithSmartPrecision({ number: virtualShortPositionNotional, shouldReturnLocalString: true, defaultPrecision: quote === 'BTC' ? 2 : 0 })} ${quote}`}
                        </div>}
                    </div>
                    : <input 
                        title={'Initial Position'}
                        type={'number'}
                        value={symbolItem.params[direction === 'BUY' ? 'LEG2_PROD_INITIAL_LONG_POS' : 'LEG2_PROD_INITIAL_SHORT_POS']} 
                        onKeyDown={(e) => { this.handleKeyDownInput(e) }} 
                        onBlur={(e) => {
                            if (e.target.value === '' || _.isNaN(Number(e.target.value))) {
                                const newProfile = dotProp.set(profile, `legs.2.symbols.${index}.params.${direction === 'BUY' ? 'LEG2_PROD_INITIAL_LONG_POS' : 'LEG2_PROD_INITIAL_SHORT_POS'}`, 0)
                                onChangeProfile(newProfile)
                            }
                        }}
                        onChange={(e) => {
                            const newProfile = dotProp.set(profile, `legs.2.symbols.${index}.params.${direction === 'BUY' ? 'LEG2_PROD_INITIAL_LONG_POS' : 'LEG2_PROD_INITIAL_SHORT_POS'}`, toNumberInputValue(e.target.value))
                            onChangeProfile(newProfile)
                        }} />}
                </td>
            )
        }

        const renderSwitches = (direction='BUY') => {
            return (
                <td className={`hedge-symbol-table--symbol-switch ${direction}`}>
                    <ProfileSwitches 
                        profileItem={profile}
                        symbolName={symbolItem.name} 
                        direction={direction} />    
                </td>
            )
        }

        const renderReduceOnlySwitches = (direction='BUY') => {
            return (
                <td className={`hedge-symbol-table--symbol-reduce-only-switch ${direction}`}>
                    <ProfileReduceOnlySwitches 
                        profileItem={profile} 
                        symbolName={symbolItem.name} 
                        direction={direction} />
                </td>
            )
        }

        const renderFundingRate = () => {
            const symbolFundingRate = symbol.fundingRates[symbolItem.name]
            if (_.isEmpty(symbolFundingRate)) {
                return null
            } else {
                const isDynamicFundingRate = isDynamicFundingRateSymbol(symbolItem.name)
                const currentFundingRate = symbolFundingRate.current_funding_rate.trim()
                const indicativeFundingRate = symbolFundingRate.indicative_funding_rate.trim()
                const precision = Math.max(getSymbolFundingRatePrecision(symbolItem.name) - 2, 2)
                return (
                    <div className='hedge-symbol-table--funding-rate'>
                        {currentFundingRate.length > 0 && <div className='hedge-symbol-table--funding-block current-funding'>
                            <span className='hedge-symbol-table--funding-block--time'>
                                {symbolFundingRate.timestamp && !isDynamicFundingRate ? moment(new Date(symbolFundingRate.timestamp)).format('HH:mm') : 'Current'}
                            </span>
                            <div className={'hedge-symbol-table--funding-block--value ' + (currentFundingRate > 0 ? 'positive' : 'negative')}>
                                {(Number(currentFundingRate) * 10000).toFixed(precision) + '%%'}
                            </div>
                        </div>}
                        {indicativeFundingRate.length > 0 && <div className='hedge-symbol-table--funding-block next-funding'>
                            <span className='hedge-symbol-table--funding-block--time'>
                                {symbolFundingRate.next_period && !isDynamicFundingRate ? moment(new Date(symbolFundingRate.next_period)).format('HH:mm') : 'Next'}
                            </span>
                            <div className={'hedge-symbol-table--funding-block--value ' + (indicativeFundingRate > 0 ? 'positive' : 'negative')}>
                                {(Number(indicativeFundingRate) * 10000).toFixed(precision) + '%%'}
                            </div>
                        </div>}
                    </div>
                )
            }
        }

        return (
            <Fragment key={index}>
                <tr>
                    <td className='hedge-symbol-table--symbol-name' rowSpan={2}>
                        {symbol.items[symbolItem.name] 
                        ? <SymbolPopup
                            symbolName={symbolItem.name}
                            profileId={profile.id} />
                        // <SymbolPopup 
                        //     symbolItem={symbol.items[symbolItem.name]} 
                        //     showAccountBalance
                        //     accountName={accountName} /> 
                        : symbolItem.name}
                        {Number(symbolPositionNotionalSum) !== 0 && 
                        <div className='hedge-symbol-table--symbol-name--position-notional-sum' title={'Position Notional Sum'}>
                            <span className={symbolPositionNotionalSum > 0 ? 'positive' : 'negative'}>{`${toNumberWithSmartPrecision({ number: symbolPositionNotionalSum, shouldReturnLocalString: true, defaultPrecision: quote === 'BTC' ? 2 : 0 })}`}</span>
                            {quote}
                        </div>}
                    </td>
                    {hasSymbolWithFundingRate && <td className='hedge-symbol-table--funding-rate' rowSpan={2}>{renderFundingRate()}</td>}
                    {hasLiquidationColumn && <td className='hedge-symbol-table--liquidation' rowSpan={2}>
                        {((instrumentType === INSTRUMENT_TYPES.SPOT && _.has(symbolItem, 'params')  
                            && _.isArray(symbolItem.params.HEDGE_MARGIN_TRADE_ENABLED)
                            && _.isArray(symbolItem.params.HEDGE_CROSS_MARGIN_TRADE_ENABLED)
                            && (_.some(symbolItem.params.HEDGE_MARGIN_TRADE_ENABLED, enabled => enabled === true)
                                || _.some(symbolItem.params.HEDGE_CROSS_MARGIN_TRADE_ENABLED, enabled => enabled === true))
                            )
                        || ([INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType)))
                        ? <ProfileLiquidations 
                            symbolName={symbolItem.name} 
                            accountNames={unionTradingAccountNames} />
                        : null}
                    </td>}
                    <td className='hedge-symbol-table--direction-label BUY'>{'Buy'}</td>
                    {renderSwitches('BUY')}
                    {hasReduceOnlyColumn && renderReduceOnlySwitches('BUY')}
                    {renderPricingData('BUY')}
                    {renderPositionData('BUY')}
                    <td rowSpan={2} className={'hedge-symbol-table--net-position-value' + 
                        (netPosition > 0 ? ' positive' 
                        : netPosition < 0 ? ' negative' 
                        : '')}>
                        {netPosition ? toNumberWithSmartPrecision({ number: netPosition, shouldReturnLocalString: true }) : 0}
                        {Number(netPositionNotional) !== 0 && <div className='hedge-symbol-table--net-position-value--notional'>{`(${toNumberWithSmartPrecision({ number: netPositionNotional, shouldReturnLocalString: true, defaultPrecision: quote === 'BTC' ? 2 : 0 })} ${quote})`}</div>}
                    </td>
                </tr>
                <tr>
                    <td className='hedge-symbol-table--direction-label SELL'>{'Sell'}</td>
                    {renderSwitches('SELL')}
                    {hasReduceOnlyColumn && renderReduceOnlySwitches('SELL')}
                    {renderPricingData('SELL')}
                    {renderPositionData('SELL')}
                </tr>
            </Fragment>
        )
    }

    render () {
        const { profile, positions, account } = this.props
        const hasSymbolWithFundingRate = this._hasSymbolWithFundingRate()
        const hasLiquidationColumn = shouldProfileLegHaveLiquidationColumn({ profileItem: profile, legKey: '2', positionItems: positions, marginAccountBalances: account.balance.margin })
        const hasReduceOnlyColumn = this._hasReduceOnlyColumn()

        return (
            <table className='hedge-symbol-table'>
                <thead>
                    <tr>
                        <th className='hedge-symbol-table--header symbols'><LegPopup profile={profile} legNumber={2} /></th>
                        {hasSymbolWithFundingRate && <th className='hedge-symbol-table--header funding-rate'>{'Funding %'}</th>}
                        {hasLiquidationColumn && <th className='hedge-symbol-table--header liquidation'>{'Liquidation'}</th>}
                        <th className='hedge-symbol-table--header direction'>{'Direction'}</th>
                        <th className='hedge-symbol-table--header switch'>{'Trading'}</th>
                        {hasReduceOnlyColumn && <th className='hedge-symbol-table--header reduce-only-switch'>{'Reduce-Only'}</th>}
                        <th>{'Pricing'}</th>
                        <th>{profile.started || profile.isStarting ? 'V Pos' : 'Int Pos'}</th>
                        <th>{'Net Pos'}</th>
                    </tr>
                </thead>
                <tbody>{(profile.legs[2].symbols || []).map((symbolItem, index) => this.renderSymbol(symbolItem, index))}</tbody>
            </table>
        )
    }
}

HedgeSymbolTable.propTypes = {
    profile: PropTypes.object.isRequired,
    symbol: PropTypes.object.isRequired,
    runningState: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    account: PropTypes.object.isRequired,
    onChangeProfile: PropTypes.func.isRequired,
    onKeyDownEnter: PropTypes.func.isRequired
}

HedgeSymbolTable.defaultProps = {
    onChangeProfile: () => {},
    onKeyDownEnter: () => {}
}

function mapStateToProps (state, ownProps) {
    return {
        symbol: state.symbol,
        runningState: state.profile.runningState[ownProps.profile.id] || {},
        positions: state.trading.positions,
        account: state.account
    }
}

export default connect(mapStateToProps)(HedgeSymbolTable)