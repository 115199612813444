import _ from 'lodash'
import moment from 'moment'
import { EXCHANGES_OPTION_PRICED_IN_COIN } from '../configs/tradingConfig'

export const INSTRUMENT_TYPES = {
    SPOT: 'SPOT',
    FUTURE: 'FUTURE',
    SWAP: 'SWAP',
    INDEX: 'INDEX',
    OPTION: 'OPTION',
    SPREAD: 'SPREAD',
    REPO: 'REPO',
    UNKOWN: 'UNKOWN'
}

export const SYMBOL_SWITCH_TYPES = {
    BUY_SELL_TO_OPEN_CLOSE: 'BUY_SELL_TO_OPEN_CLOSE',
    BUY_SELL: 'BUY_SELL'
}

export const getNormalizedCurrencyName = (currencyName) => {
    currencyName = currencyName.toUpperCase()
    return currencyName === 'XBT' ? 'BTC' 
        : ['USD', 'HUSD'].includes(currencyName) ? 'USDT'
        : currencyName
}

export const getFuturesMonthByCode = (monthCode) => {
    const codes = {
        F: 0, 
        G: 1,
        H: 2,
        J: 3,
        K: 4,
        M: 5, 
        N: 6,
        Q: 7,
        U: 8,
        V: 9,
        X: 10,
        Z: 11
    }
    return codes[monthCode]
}

export const getSymbolAttributeByName = (symbolName) => {

    // const getLastFridayByMonthMoment = (monthMoment) => {
    //     let lastDay = monthMoment.endOf('month').endOf('day')
    //     return lastDay.subtract((lastDay.day() + 2) % 7, 'days').format('YYYY-MM-DD')
    // }

    const tokens = symbolName.split('_')
    const exchangeName = tokens[tokens.length - 2]
    const originalType = tokens[tokens.length - 1]

    const instrumentType = originalType === 'INDEX' ? INSTRUMENT_TYPES.INDEX
        : originalType === 'SPT' ? INSTRUMENT_TYPES.SPOT
        : ['FTW', 'FNW', 'FQR', 'FNQ', 'FUT'].includes(originalType) ? INSTRUMENT_TYPES.FUTURE
        : originalType === 'SWAP' ? INSTRUMENT_TYPES.SWAP
        : ['CALL', 'PUT', 'QMOV'].includes(originalType) ? INSTRUMENT_TYPES.OPTION
        : originalType === 'SPR' ? INSTRUMENT_TYPES.SPREAD
        : originalType === 'REPO' ? INSTRUMENT_TYPES.REPO
        : INSTRUMENT_TYPES.UNKOWN

    const switchType = ((['OKEX', 'HUOBIFUT'].includes(exchangeName) && [INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType)) || _.endsWith(symbolName, '_usdt_BYBIT_SWAP')) 
        ? SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE : SYMBOL_SWITCH_TYPES.BUY_SELL
    const base = instrumentType === INSTRUMENT_TYPES.OPTION && EXCHANGES_OPTION_PRICED_IN_COIN.includes(exchangeName) ? 'OPTION_CONTRACT'
        : [INSTRUMENT_TYPES.OPTION, INSTRUMENT_TYPES.SPREAD].includes(instrumentType) ? tokens[0].substring(0, 3).toUpperCase()
        : _.startsWith(tokens[0], 'XBT') ? 'BTC' 
        : tokens[0] === 'shib1000' ? 'SHIB'
        : tokens.length === 4 ? tokens[0].toUpperCase() 
        : tokens[0].length > 6 && _.endsWith(tokens[0], 'USD', tokens[0].length - 3) ? tokens[0].substring(0, tokens[0].length - 6)
        : tokens[0].length > 7 && _.endsWith(tokens[0], 'USDT', tokens[0].length - 3) ? tokens[0].substring(0, tokens[0].length - 7)
        : tokens[0].substring(0, tokens[0].length - 3)
    const quote = instrumentType === INSTRUMENT_TYPES.OPTION && EXCHANGES_OPTION_PRICED_IN_COIN.includes(exchangeName) ? tokens[0].substring(0, 3).toUpperCase() 
        : [INSTRUMENT_TYPES.OPTION, INSTRUMENT_TYPES.SPREAD].includes(instrumentType) ? 'USD' 
        : tokens.length === 4 ? tokens[1].toUpperCase()
        : exchangeName === 'BITMEX' && base !== 'BTC' && !tokens[0].includes('USD') ? 'BTC'
        : tokens[0].length > 7 && _.endsWith(tokens[0], 'USDT', tokens[0].length - 3) ? 'USDT'
        : 'USD'
    
    const symbolAttributes = {
        base,
        quote,
        pairName: `${getNormalizedCurrencyName(base)}/${base !== 'USDT' ? getNormalizedCurrencyName(quote) : quote}`,
        exchangeName,
        originalType,
        instrumentType,
        switchType
        //settlementDate
    } 

    if (instrumentType === INSTRUMENT_TYPES.OPTION) {
        symbolAttributes.optionExpiryDate = moment(tokens[0].substring(3), 'YYMMDD').format('YYYY-MM-DD')
        symbolAttributes.optionStrikePrice = tokens[1]
    }

    return symbolAttributes
}

export const getPairsWithValidIndex = (symbolPricings) => {
    const pairs = {}
    Object.values(symbolPricings).forEach((pricingItem) => {
        const { base, quote, instrumentType } = getSymbolAttributeByName(pricingItem.symbolName)
        if (instrumentType === INSTRUMENT_TYPES.INDEX && Number(pricingItem.last) > 0) {
            const normalizedQuote = quote === 'USD' && base !== 'USDT' ? 'USDT' : quote
            const pairName = `${base}/${normalizedQuote}`
            pairs[pairName] = {
                base,
                quote,
                pairName,
                symbolName: pricingItem.symbolName,
                value: Number(pricingItem.last)
            }
        }
    })
    return pairs
}

export const getSymbolFundingRatePrecision = (symbolName) => {
    const symbolAttribute = getSymbolAttributeByName(symbolName)
    const { exchangeName } = symbolAttribute
    return exchangeName === 'DERIBIT' ? 5 
        : ['FTX', 'COINFLEX'].includes(exchangeName) ? 4
        : 3
}

export const getSymbolExpiryDate = (symbolItem) => {
    const expiryMoment = _.has(symbolItem, 'expiry') ? moment(symbolItem.expiry) : null
    return expiryMoment && expiryMoment.year() !== 1970 ? expiryMoment.format('YYYY-MM-DD') : null
}

export const isDynamicFundingRateSymbol = (symbolName) => {
    const symbolAttribute = getSymbolAttributeByName(symbolName)
    return _.has(symbolAttribute, 'exchangeName') && symbolAttribute.exchangeName === 'DERIBIT'
}

export const getPricePrecisionBySymbolItem = (symbolItem) => {
    let pricePrecision = 2
    if (!_.isNil(symbolItem)) {
        const { symbol_name: symbolName, price_tick: priceTick } = symbolItem
        const { instrumentType } = getSymbolAttributeByName(symbolName)
        pricePrecision = Number(priceTick) > 0 && Number(priceTick) < 1 && (priceTick || '').includes('.') ? priceTick.split('.')[1].length
            : Number(priceTick) >= 1 ? 0
            : instrumentType === INSTRUMENT_TYPES.REPO ? 5
            : 5
    }
    return pricePrecision
}