import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { UPDATE_SYMBOL_ITEMS, UPDATE_SYMBOL_PRICINGS, UPDATE_SYMBOL_FUNDING_RATES, 
    UPDATE_OPTION_IMPLIED_VOLATILITIES, UPDATE_FUNDING_RATE_HISTORY,
    UPDATE_FUNDING_RATE_HISTORY_SYMBOLS, UPDATE_DEFI_LENDING_INFO,
    UPDATE_SYMBOL_ORDER_BOOKS, REMOVE_SYMBOL_ORDER_BOOK,
    UPDATE_OPTION_SYMBOL_DELTA, UPDATE_OPTION_COIN_DELTA,
    UPDATE_FUNDING_TAGS } from './symbolAction'

import { UPDATE_WEB_SOCKET_BUFFERED_PROFILE_STATE_DATA } from '../webSocket/webSocketAction'

const initialState = {
    items: {},
    pricings: {},
    orderBook: {},
    fundingRates: {},
    fundingRateHistorySymbols: [],
    fundingRateHistory: {},
    fundingTags: [],
    optionImpliedVolatilities: {},
    optionSymbolDelta: {},
    optionCoinDelta: {},
    defiLendingInfo: []
}

export function symbolReducer (state = initialState, action) {
    let newFundingRateHistory
    switch(action.type) {
        case UPDATE_SYMBOL_ITEMS:
            return dotProp.set(state, 'items', action.symbols)

        case UPDATE_SYMBOL_PRICINGS:
            return dotProp.merge(state, 'pricings', action.pricings)

        case UPDATE_SYMBOL_FUNDING_RATES:
            return dotProp.merge(state, 'fundingRates', action.fundingRates)

        case UPDATE_OPTION_IMPLIED_VOLATILITIES:
            return dotProp.merge(state, 'optionImpliedVolatilities', _.keyBy(action.optionImpliedVolatilities, 'symbol'))

        case UPDATE_FUNDING_RATE_HISTORY:
            newFundingRateHistory = _.cloneDeep(state.fundingRateHistory)
            _.forEach(action.fundingRateHistory, (symbolFundingRateHistory, symbolName) => {
                const mergedSymbolFundingRateHistory =  _.unionBy(newFundingRateHistory[symbolName] || [], symbolFundingRateHistory, 'time')
                const sortedSymbolFundingRateHistory = _.sortBy(mergedSymbolFundingRateHistory, 'time')
                newFundingRateHistory[symbolName] = sortedSymbolFundingRateHistory
            })
            return dotProp.set(state, 'fundingRateHistory', newFundingRateHistory)

        case UPDATE_FUNDING_RATE_HISTORY_SYMBOLS:
            return dotProp.set(state, 'fundingRateHistorySymbols', action.symbols)

        case UPDATE_DEFI_LENDING_INFO:
            return dotProp.set(state, 'defiLendingInfo', action.info)

        case UPDATE_SYMBOL_ORDER_BOOKS:
            return dotProp.merge(state, 'orderBook', action.symbolOrderBooks)

        case REMOVE_SYMBOL_ORDER_BOOK:
            return dotProp.delete(state, `orderBook.${action.symbolName}`)

        case UPDATE_OPTION_SYMBOL_DELTA:
            return dotProp.set(state, 'optionSymbolDelta', action.optionSymbolDelta)

        case UPDATE_OPTION_COIN_DELTA:
            return dotProp.set(state, 'optionCoinDelta', action.optionCoinDelta)

        case UPDATE_WEB_SOCKET_BUFFERED_PROFILE_STATE_DATA:
            return dotProp.merge(state, 'pricings', action.pricingItems)

        case UPDATE_FUNDING_TAGS:
            return dotProp.set(state, 'fundingTags', action.fundingTags)

        default:
            return state
    }
}  