import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'

import Popup from '../common/popup/Popup'
import Toggle from '../common/toggle/Toggle'
import { fetchTransfersToBalanceAccountGroup, fetchAccountBalancerState, updateAccountBalancerVariables, accountTransferFund } from './accountAction'
import { EXCHANGES as TOKEN_TRANSFER_EXCHANGE_CONFIGS } from './TokenTransferEditor'
import { isMetSearchStringCriteria, toNumberWithSmartPrecision } from '../../util/util'
import Checkbox from '../common/checkbox/Checkbox'
import SearchSelect from '../common/searchSelect/SearchSelect'
import SaveButton from '../common/saveButton/SaveButton'
import FutureAccountBalanceItem from './FutureAccountBalanceItem'
import SwapAccountBalanceItem from './SwapAccountBalanceItem'

const AccountBalanceParam = ({ accountName, market, pair, currency, riskLimit, enabled }) => {
    return {
        accountName,
        market,
        pair,
        currency,
        riskLimit,
        enabled
    }
}

const GROUP_FILTERS = {
    ALL: {
        key: 'ALL',
        name: 'All'
    },
    AUTO_TRANSFER_ON: {
        key: 'AUTO_TRANSFER_ON',
        name: 'Auto Transfer ON'
    },
    AUTO_TRANSFER_OFF: {
        key: 'AUTO_TRANSFER_OFF',
        name: 'Auto Transfer OFF'
    }
}

const GROUP_SORT_BYS = {
    EXCHANGE: {
        key: 'EXCHANGE',
        name: 'Exchange'
    },
    MARKET: {
        key: 'MARKET',
        name: 'Market'
    },
    CURRENCY: {
        key: 'CURRENCY',
        name: 'Currency'
    },
    ACCOUNT_COUNT: {
        key: 'ACCOUNT_COUNT',
        name: 'Account Count'
    }
}

const AccountBalanceGroupTransferState = ({ groupId, isFetchingTransferParams=false, transferParams=[], isTransfering=false }) => {
    return {
        groupId,
        isFetchingTransferParams,
        transferParams,
        isTransfering
    }
}

class AccountBalancer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            accountBalanceItems: {},
            isAutoTransferEnabled: false,
            variables: {
                isAutoTransferOn: false,
                enabledGroupIds: [],
                accountBalanceParams: {}
            },
            lastUpdateTimestamp: null,
            editingAccountBalanceParams: {},
            editingEnabledGroupIds: null,
            searchString: '',
            canEditParams: false,
            groupFilter: this.getGroupFilter(),
            groupSortBy: this.getGroupSortBy(),
            shouldHideGroupsWithSingleAccount: true,
            isFetchingState: false,
            isSwitchingAutoTransfer: false,
            isSavingVariables: false,
            accountBalanceGroupsTransferState: {}
        }
        this._mounted = false
        this.fetchBalancerStateInterval = null
    }

    componentDidMount () {
        this._mounted = true
        this.getAccountBalancerState()
        this.registerFetchBalancerStateInterval()
    }

    componentDidUpdate (prevProps) {
        const { updateStateId: prevUpdateStateId } = prevProps
        const { updateStateId } = this.props
        if (!_.isEqual(prevUpdateStateId, updateStateId)) {
            this.getAccountBalancerState()
        }
    }

    componentWillUnmount () {
        if (this.fetchBalancerStateInterval) {
            window.clearInterval(this.fetchBalancerStateInterval)
        }
    }

    registerFetchBalancerStateInterval () {
        if (this.fetchBalancerStateInterval) {
            window.clearInterval(this.fetchBalancerStateInterval)
        }
        this.fetchBalancerStateInterval = setInterval(() => {
            if (this._mounted) {
                this.getAccountBalancerState()
            }
        }, 30000)
    }

    getGroupFilter () {
        return _.has(sessionStorage, 'accountBalancerGroupFilter') && Object.keys(GROUP_FILTERS).includes(sessionStorage.accountBalancerGroupFilter)
            ? sessionStorage.accountBalancerGroupFilter
            : GROUP_FILTERS.ALL.key
    }

    updateGroupFilter (groupFilter) {
        sessionStorage.accountBalancerGroupFilter = groupFilter
        this.setState({
            groupFilter: this.getGroupFilter()
        })
    }

    getGroupSortBy () {
        return _.has(sessionStorage, 'accountBalancerGroupSortBy') && Object.keys(GROUP_SORT_BYS).includes(sessionStorage.accountBalancerGroupSortBy)
            ? sessionStorage.accountBalancerGroupSortBy
            : GROUP_SORT_BYS.EXCHANGE.key
    }

    updateGroupSortBy (groupSortBy) {
        sessionStorage.accountBalancerGroupSortBy = groupSortBy
        this.setState({
            groupSortBy: this.getGroupSortBy()
        })
    }

    getShouldHideGroupsWithSingleAccount () {
        return sessionStorage.accountBalancerShouldHideSingleAccount === '1'
    }

    updateShouldHideGroupWithSingleAccount (newValue) {
        sessionStorage.accountBalancerShouldHideSingleAccount = newValue === true ? '1' : '0'
        this.setState({
            shouldHideGroupsWithSingleAccount: this.getShouldHideGroupsWithSingleAccount()
        })
    }

    getAccountBalancerState () {
        const { dispatch } = this.props
        this.setState({ isFetchingState: true })
        dispatch(fetchAccountBalancerState())
        .then(response => {
            if (response && response.status === 200) {
                response.json().then(body => {
                    if (_.isObject(body) && this._mounted) {
                        this.setState({
                            ...body,
                            lastUpdateTimestamp: moment().toISOString()
                        })
                    }
                })
            }
        })
        .finally(() => {
            this.setState({ isFetchingState: false })
        })
    }

    handleClickConfirmBalanceGroupButton (groupId) {
        const { dispatch, onTransferFail } = this.props
        const { accountBalanceGroupsTransferState } = this.state
        const transferState = accountBalanceGroupsTransferState[groupId]
        let transferParamIndex = 0

        const transferLoop = () => {
            const transferParam = transferState.transferParams[transferParamIndex]
            if (!_.isNil(transferParam)) {
                dispatch(accountTransferFund(transferParam))
                .then(response => {
                    if (this._mounted && response && response.status !== 200) {
                        response.text().then(text => onTransferFail(groupId, text))
                    }
                })
                .catch(error => {
                    if (this._mounted) {
                        onTransferFail(groupId, error)
                    }
                })
                .finally(() => {
                    if (this._mounted && transferParamIndex >= transferState.transferParams.length) {
                        this.setState(prevState => {
                            return {
                                accountBalanceGroupsTransferState: dotProp.set(prevState.accountBalanceGroupsTransferState, `${groupId}.isTransfering`, false) 
                            }
                        })
                    }
                })

                transferParamIndex++
                if (transferParamIndex < transferState.transferParams.length) {
                    setTimeout(() => {
                        transferLoop()
                    }, 5000)
                }
            }
        }

        this.setState(prevState => {
            return {
                accountBalanceGroupsTransferState: dotProp.set(prevState.accountBalanceGroupsTransferState, `${groupId}.isTransfering`, true) 
            }
        })
        transferLoop()
    }

    handleClickSaveButton () {
        const { editingAccountBalanceParams, editingEnabledGroupIds} = this.state
        const { dispatch } = this.props
        const newVariables = {}
        if (!_.isEmpty(editingAccountBalanceParams)) {
            newVariables.accountBalanceParams = editingAccountBalanceParams
        }
        if (_.isArray(editingEnabledGroupIds)) {
            newVariables.enabledGroupIds = editingEnabledGroupIds
        }
        this.setState({ isSavingVariables: true })
        dispatch(updateAccountBalancerVariables(newVariables))
        .finally(() => {
            this.setState({ 
                isSavingVariables: false,
                editingAccountBalanceParams: {},
                editingEnabledGroupIds: null,
                canEditParams: false
            })
        })
    }

    handleClickAutoTransferToggle (newIsAutoTransferOn) {
        const { isSwitchingAutoTransfer } = this.state
        const { dispatch } = this.props
        if (!isSwitchingAutoTransfer) {
            this.setState({ isSwitchingAutoTransfer: true })
            dispatch(updateAccountBalancerVariables({ isAutoTransferOn: newIsAutoTransferOn }))
            .finally(() => {
                this.setState({ isSwitchingAutoTransfer: false })
            })
        }
    }

    GroupTransferPreview (groupId) {
        const { accountBalanceGroupsTransferState } = this.state
        const { dispatch, accountItems } = this.props

        const transferState = accountBalanceGroupsTransferState[groupId] || AccountBalanceGroupTransferState({ groupId })
        const { isFetchingTransferParams, transferParams, isTransfering } = transferState
        return (
            <Popup
                className='account-balancer--transfers-preview'
                disabled={isTransfering}
                on={'click'}
                trigger={
                    isTransfering 
                    ? <span className='account-balancer--transfers-preview--transfering-message'>{'Transfering...'}</span>
                    : <button className='account-balancer--transfers-preview--trigger-button'>{'Balance Accounts'}</button>}
                onOpen={() => {
                    this.setState(prevState => {
                        return {
                            accountBalanceGroupsTransferState: dotProp.set(prevState.accountBalanceGroupsTransferState, groupId, AccountBalanceGroupTransferState({ groupId, isFetchingTransferParams: true }))
                        }
                    })
                    dispatch(fetchTransfersToBalanceAccountGroup(groupId))
                    .then(response => {
                        if (response && response.status === 200) {
                            return response.json()
                        }
                    })
                    .then(body => { 
                        this.setState(prevState => {
                            return {
                                accountBalanceGroupsTransferState: dotProp.set(prevState.accountBalanceGroupsTransferState, `${groupId}.transferParams`, body || [])
                            }
                        })
                    })
                    .finally(() => {
                        this.setState(prevState => {
                            return {
                                accountBalanceGroupsTransferState: dotProp.set(prevState.accountBalanceGroupsTransferState, `${groupId}.isFetchingTransferParams`, false)
                            }
                        })
                    })
                }}>
                <div className='account-balancer--transfers-preview--header'>{'Transfer Preview'}</div>
                <div className='account-balancer--transfers-preview--main clearfix'>
                    {isFetchingTransferParams && <div className='account-balancer--transfers-preview--message'>{'Fetching...'}</div>}
                    {!isFetchingTransferParams && _.isEmpty(transferParams) && <div className='account-balancer--transfers-preview--message'>{'Oops... It is empty.'}</div>}
                    {!isFetchingTransferParams && !_.isEmpty(transferParams) && 
                    <Fragment>
                        <table>
                            <thead>
                                <tr>
                                    <th>{'Num.'}</th>
                                    <th>{'From'}</th>
                                    <th>{'Amount'}</th>
                                    <th>{'To'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.map(transferParams, (transferParam, index) => {
                                    const { account_name: originAccountName, currency, from, instrument_id, 
                                        to_account_name: destinationAccountName, to, to_instrument_id, amount } = transferParam

                                    const originAccountItem = accountItems[originAccountName]
                                    const destinationAccountItem = accountItems[destinationAccountName]
                                    const originExchangeAccountTypes = _.has(originAccountItem, 'exchange_name') && _.has(TOKEN_TRANSFER_EXCHANGE_CONFIGS, `${originAccountItem.exchange_name}.getAccountTypesCanTransferToken`)
                                        ? TOKEN_TRANSFER_EXCHANGE_CONFIGS[originAccountItem.exchange_name].getAccountTypesCanTransferToken({ tokenToTransfer: currency, accountName: originAccountName, accountItems })
                                        : []
                                    const destinationExchangeAccountTypes = _.has(destinationAccountItem, 'exchange_name') && _.has(TOKEN_TRANSFER_EXCHANGE_CONFIGS, `${destinationAccountItem.exchange_name}.getAccountTypesCanTransferToken`)
                                        ? TOKEN_TRANSFER_EXCHANGE_CONFIGS[destinationAccountItem.exchange_name].getAccountTypesCanTransferToken({ tokenToTransfer: currency, accountName: destinationAccountName, accountItems })
                                        : []
                                    const originAccountType = _.find(originExchangeAccountTypes, { value: from })
                                    const destinationAccountType = _.find(destinationExchangeAccountTypes, { value: to })

                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <span>{originAccountName}</span>
                                                <span className='account-balancer--transfers-preview--account-type'>{originAccountType ? originAccountType.name : `${from}`}</span>
                                                {instrument_id && <span className='account-balancer--transfers-preview--instrument-id'>{instrument_id}</span>}
                                            </td>
                                            <td>{`${amount} ${currency}`}</td>
                                            <td>
                                                <span>{destinationAccountName}</span>
                                                <span className='account-balancer--transfers-preview--account-type'>{destinationAccountType ? destinationAccountType.name : `${to}`}</span>
                                                {to_instrument_id && <span className='account-balancer--transfers-preview--instrument-id'>{to_instrument_id}</span>}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <div className='account-balancer--transfers-preview--footer'>
                            {(!isTransfering && !_.isEmpty(transferParams)) && 
                            <button className='account-balancer--transfers-preview--confirm-button' 
                            onClick={() => { this.handleClickConfirmBalanceGroupButton(groupId) }}>{'CONFIRM'}</button>}
                            {isTransfering && <div className='account-balancer--transfers-preview--footer--message transfering'>{'Transfering...'}</div>}
                        </div>
                    </Fragment>}
                </div>
            </Popup>
        )
    }

    ParamInput ({ value, shouldShowAsPercent=false, isModified=false, disabled=false, onChange=()=>{} }) {
        return (
            <div className='account-balancer--param-input-wrapper'>
                <input
                    className={'account-balancer--param-input' + (isModified ? ' modified' : '')}
                    type={'number'}
                    disabled={disabled}
                    value={shouldShowAsPercent && _.isNumber(value) ? `${Math.round(value * 10000)/100}` : value} 
                    onChange={(e) => {
                        let newValue = e.target.value.trim()
                        if (newValue.length > 0) {
                            newValue = Math.round(newValue * 100) / (shouldShowAsPercent ? 10000 : 100)
                        }
                        onChange(newValue)
                    }} />
                {shouldShowAsPercent && <span>{'%'}</span>}
            </div>
        )
    }

    Header () {
        const { searchString, variables, isSwitchingAutoTransfer, shouldHideGroupsWithSingleAccount, groupFilter,
            groupSortBy, lastUpdateTimestamp, isFetchingState, canEditParams, editingAccountBalanceParams, editingEnabledGroupIds,
            isAutoTransferEnabled } = this.state

        const groupFilterOptions = _.map(GROUP_FILTERS, groupFilter => {
            return {
                value: groupFilter.key,
                name: groupFilter.name
            }
        })
        const groupSortByOptions = _.map(GROUP_SORT_BYS, groupSortBy => {
            return {
                value: groupSortBy.key,
                name: groupSortBy.name
            }
        })
        return (
            <div className='account-balancer--header clearfix'>
                <div className='account-balancer--header--left-section'>
                    <input className='account-balancer--header--search-input'
                        placeholder={'Search Exchange, Market, Currency'}
                        type={'text'}
                        value={searchString}
                        spellCheck={false}
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                    <div className='account-balancer--header--criteria'>
                        <div className='account-balancer--header--group-filter'>
                            <span>{'Group Filter'}</span>
                            <SearchSelect 
                                value={groupFilter}
                                options={groupFilterOptions}
                                hideSearchBar
                                onChange={(newOption) => {
                                    this.updateGroupFilter(newOption.value)
                                }} />
                        </div>
                        <div className='account-balancer--header--group-sort-by'>
                            <span>{'Group Sort By'}</span>
                            <SearchSelect 
                                value={groupSortBy}
                                options={groupSortByOptions}
                                hideSearchBar
                                onChange={(newOption) => {
                                    this.updateGroupSortBy(newOption.value)
                                }} />
                        </div>
                        <div className='account-balancer--header--hide-single-account-checkbox'>
                            <Checkbox 
                                checked={shouldHideGroupsWithSingleAccount}
                                onChange={(newChecked) => { this.updateShouldHideGroupWithSingleAccount(newChecked) }}/>
                            <span>{'Hide groups with single Acct.'}</span>
                        </div>
                    </div>
                </div>

                <div className='account-balancer--header--right-section'>
                    <div className='account-balancer--header--last-update-wrapper'>
                        <button className='account-balancer--header--fetch-last-button' onClick={() => {
                            this.getAccountBalancerState()
                        }}>{'Fetch Latest'}</button>
                        {lastUpdateTimestamp && 
                        <span className='account-balancer--header--last-update-timestamp'>{'Last Update: ' + moment(lastUpdateTimestamp).format('HH:mm:ss')}</span>}
                        {isFetchingState && <span className='account-balancer--header--is-fetching'>{'Fetching...'}</span>}
                    </div>
                    <div className='account-balancer--header--buttons'>
                        {(!_.isEmpty(editingAccountBalanceParams) || !_.isNil(editingEnabledGroupIds)) &&
                        <Fragment>
                            <button className='account-balancer--header--reset-button'
                                onClick={() => {
                                    this.setState({
                                        canEditParams: false,
                                        editingAccountBalanceParams: {},
                                        editingEnabledGroupIds: null
                                    })
                                }}>
                                {'RESET'}
                            </button>
                            <SaveButton 
                                className='account-balancer--header--save-button' 
                                text={'SAVE'}
                                onClick={() => { this.handleClickSaveButton() }} />
                        </Fragment>}
                        {!canEditParams && 
                        <button className='account-balancer--header--edit-button' 
                            onClick={() => { 
                                this.setState({ 
                                    canEditParams: true,
                                    editingEnabledGroupIds: variables.enabledGroupIds
                                }) 
                            }}>
                            {'EDIT'}
                        </button>}
                        <div className='account-balancer--auto-transfer-toggle'>
                            <Popup className='account-balancer--auto-transfer-toggle--description-popup'
                                on={'hover'}
                                trigger={<span>{'Auto Transfer'}</span>}>
                                {`When Auto Transfer is enabled, the service will try balacing accounts' Net Balance in the defined group every hour.`}
                            </Popup>
                            <Toggle 
                                checked={isAutoTransferEnabled && variables.isAutoTransferOn}
                                disabled={!isAutoTransferEnabled}
                                trueText={'ON'}
                                falseText={'OFF'}
                                isChanging={isSwitchingAutoTransfer}
                                onChange={(newValue) => { this.handleClickAutoTransferToggle(newValue) }} />
                        </div>
                    </div>       
                </div>
            </div>
        )
    }

    List () {
        const { accountBalanceItems, variables, editingAccountBalanceParams, shouldHideGroupsWithSingleAccount, searchString,
            groupFilter, groupSortBy, canEditParams, editingEnabledGroupIds } = this.state 
        const marketNames = {
            BINANCE_USDT_FUTURES: 'USDT-Futures'
        }
        const trimmedSearchString = searchString.trim().toLowerCase()
        const accountBalanceGroups = _.map(_.groupBy(accountBalanceItems, 'groupId'), (accountBalanceItems, groupId) => {
            const { exchangeName, market, pair, currency } = accountBalanceItems[0]
            return {
                groupId,
                exchangeName,
                market,
                pair,
                currency,
                accountBalanceItems
            }
        })
        const filteredAccountBalanceGroups = _.filter(accountBalanceGroups, group => {
            const { groupId, exchangeName, market, pair, currency, accountBalanceItems } = group
            return (!shouldHideGroupsWithSingleAccount || _.size(accountBalanceItems) > 1)
                && (groupFilter === GROUP_FILTERS.ALL.key 
                    || (groupFilter === GROUP_FILTERS.AUTO_TRANSFER_ON.key && variables.enabledGroupIds.includes(groupId))
                    || (groupFilter === GROUP_FILTERS.AUTO_TRANSFER_OFF.key && !variables.enabledGroupIds.includes(groupId))
                )
                && (_.isEmpty(trimmedSearchString) 
                    || isMetSearchStringCriteria(`${exchangeName} ${market} ${pair} ${currency}`, trimmedSearchString)
                )
        })
        const seivedAccountBalanceGroups = _.sortBy(filteredAccountBalanceGroups, group => {
            if (groupSortBy === 'EXCHANGE') {
                return group.exchangeName
            } else if (groupSortBy === 'MARKET') {
                return group.market
            } else if (groupSortBy === 'CURRENCY') {
                return group.currency
            } else {
                return -group.accountBalanceItems.length
            }
        })

        return _.map(seivedAccountBalanceGroups, group => {
            const { groupId, exchangeName, market, pair, currency, accountBalanceItems } = group
            const canBalance = _.filter(accountBalanceItems, { enabled: true }).length > 1
            const isGroupAutoTransferOn = variables.enabledGroupIds.includes(groupId)
            return (
                <Fragment key={groupId}>
                    <thead className='account-balancer--group-head'>
                        <tr>
                            <th colSpan={99}>
                                <div className='account-balancer--group-title'>
                                    <span className='account-balancer--group-title--exchange-name'>{exchangeName}</span>
                                    <span className={`account-balancer--group-title--market ${market}`}>{marketNames[market] || market}</span>
                                    {pair && <span className={`account-balancer--group-title--pair`}>{pair}</span>}
                                    {currency && <span className={`account-balancer--group-title--currency`}>{currency}</span>}
                                </div>
                                {!canEditParams && <div className='account-balancer--auto-transfer-state'>
                                    <div className={'account-balancer--auto-transfer-state--dot' + (isGroupAutoTransferOn ? ' on' : '')} />
                                    <span>{`Auto Transfer ${isGroupAutoTransferOn ? 'ON' : 'OFF'}`}</span>
                                </div>}
                                {canEditParams && 
                                <div className='account-balancer--group-auto-transfer-toggle'>
                                    <span>{'Auto Transfer'}</span>
                                    <Toggle 
                                        checked={editingEnabledGroupIds.includes(groupId)}
                                        onChange={(newChecked) => {
                                            this.setState({
                                                editingEnabledGroupIds: newChecked 
                                                    ? _.union(editingEnabledGroupIds, [groupId]) 
                                                    : _.without(editingEnabledGroupIds, groupId)
                                            })
                                        }} />
                                </div>}

                                {!canEditParams && canBalance && this.GroupTransferPreview(groupId)}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(accountBalanceItems, accountBalanceItem => {
                            const { id: accountBalanceItemId, accountName, market, pair, currency, 
                                netBalance, availableBalance, marginRatio, transferableAmount, riskLimit, enabled, detail } = accountBalanceItem
                            const editingParam = editingAccountBalanceParams[accountBalanceItemId]
                            return (
                                <tr key={accountBalanceItemId}>
                                    <td className='left-align'>
                                        <Popup
                                            on={'click'}
                                            className='account-balancer--item--account-popup'
                                            trigger={<span className='account-balancer--item--account-name'>{accountName}</span>}>
                                            {market === 'FUTURE' && !_.isNil(detail) ? <FutureAccountBalanceItem 
                                                futureAccountBalance={detail}
                                                shouldShowDetail 
                                                shouldShowAccountTypeTag
                                                shouldShowAccountName />
                                            : ['SWAP', 'BINANCE_USDT_FUTURES'].includes(market) && !_.isNil(detail) ? <SwapAccountBalanceItem 
                                                swapAccountBalance={detail}
                                                shouldShowDetail 
                                                shouldShowAccountTypeTag
                                                shouldShowAccountName />
                                            : null}
                                        </Popup>
                                    </td>
                                    <td className='left-align'>
                                        <span className={`account-balancer--item--market-tag ${market}`}>{marketNames[market] || market}</span>
                                        {pair && <span className='account-balancer--item--pair'>{pair}</span>}
                                    </td>
                                    <td>{currency}</td>
                                    <td>{_.has(detail, 'timestamp') ? moment(detail.timestamp).format('HH:mm:ss') : ''}</td>
                                    <td className='right-align currency-number'>
                                        {!_.isNil(netBalance)
                                        ? <Fragment>{toNumberWithSmartPrecision({ number: netBalance, shouldReturnLocalString: true })}<span>{currency}</span></Fragment>
                                        : 'N/A'}
                                    </td>
                                    <td className='right-align currency-number'>                                    
                                        {!_.isNil(availableBalance)
                                        ? <Fragment>{toNumberWithSmartPrecision({ number: availableBalance, shouldReturnLocalString: true })}<span>{currency}</span></Fragment>
                                        : 'N/A'}
                                    </td>
                                    <td className='right-align currency-number'>
                                        {toNumberWithSmartPrecision({ number: transferableAmount, shouldReturnLocalString: true })}
                                        <span>{currency}</span>
                                    </td>
                                    <td className='right-align'>{!_.isNil(marginRatio) ? `${(marginRatio * 100).toFixed(2) + '%'}` : 'N/A'}</td>
                                    <td>
                                        {this.ParamInput({
                                            disabled: !canEditParams,
                                            value: _.has(editingParam, 'riskLimit') ? editingParam.riskLimit : riskLimit,
                                            shouldShowAsPercent: true,
                                            isModified: _.has(editingParam, 'riskLimit') && editingParam.riskLimit !== riskLimit,
                                            onChange: (newValue) => {
                                                const newEditingParam = !_.isNil(editingParam) 
                                                    ?  dotProp.set(editingParam, 'riskLimit', newValue)
                                                    : AccountBalanceParam({ 
                                                        accountName, 
                                                        market, 
                                                        pair, 
                                                        currency, 
                                                        riskLimit: newValue,
                                                        enabled
                                                    })
                                                this.setState({
                                                    editingAccountBalanceParams: dotProp.set(editingAccountBalanceParams, accountBalanceItemId, newEditingParam)
                                                })
                                            }
                                        })}
                                    </td>
                                    <td className='left-align'>
                                        <div className={'account-balancer--item--check-box' + (_.has(editingParam, 'enabled') && editingParam.enabled !== enabled ? ' modified' : '')}>
                                            <Checkbox 
                                                disabled={!canEditParams}
                                                checked={_.has(editingParam, 'enabled') ? editingParam.enabled : enabled} 
                                                onChange={(newChecked) => { 
                                                    const newEditingParam = !_.isNil(editingParam) 
                                                        ?  dotProp.set(editingParam, 'enabled', newChecked)
                                                        : AccountBalanceParam({ 
                                                            accountName, 
                                                            market, 
                                                            pair, 
                                                            currency, 
                                                            riskLimit,
                                                            enabled: newChecked
                                                        })
                                                    this.setState({
                                                        editingAccountBalanceParams: dotProp.set(editingAccountBalanceParams, accountBalanceItemId, newEditingParam)
                                                    })
                                                }} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Fragment>
            )
        })        
    }

    render () {
        return (
            <div className='account-balancer'>
                {this.Header()}
                <div className='account-balancer--main'>
                    <table className='account-balancer--table'>
                        <thead className='account-balancer--table--head'>
                            <tr>
                                <th className='left-align'>{'Account'}</th>
                                <th className='left-align'>{'Market'}</th>
                                <th>{'Currency'}</th>
                                <th>{'Timestamp'}</th>
                                <th className='right-align'>{'Net Balance'}</th>
                                <th className='right-align'>{'Available Bal.'}</th>
                                <th className='right-align'>{'Adj. Transferable'}</th>
                                <th className='right-align'>{'Margin Ratio'}</th>
                                <th>{'Risk Limit'}</th>
                                <th className='left-align'>{'Enabled'}</th>
                            </tr>
                        </thead>
                        {this.List()}
                    </table>
                </div>
            </div>    
        )
    }
}

AccountBalancer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    updateStateId: PropTypes.string,

    accountItems: PropTypes.object,

    onTransferFail: PropTypes.func
}

AccountBalancer.defaultProps = {
    onTransferFail: () => {}
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(AccountBalancer)