import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import uuidv4 from 'uuid/v4' 
import dotProp from 'dot-prop-immutable'

import Modal from 'react-modal'
import { FiX } from 'react-icons/fi'
import ProfileStateSnapshotModal from './ProfileStateSnapshotModal'


const ProfileState = ({ id, name, hostname, started, resumed }) => {
    return { id, name, hostname, started, resumed }
}

const ProfileStateSnapshot = ({ id, name, createdTime, profileStates=[] }) => {
    return { id, name, createdTime, profileStates }
}

class ProfileStateSnapshotContainer extends Component {
    constructor (props) {
        super (props)
        this.state = {
            snapshots: this.getProfileStateSnapshots(),
            snapshotToDelete: null,
            snapshotIndexEditing: null
        }
    }

    getProfileStateSnapshots () {
        const localStorageSnapshots = localStorage.profileStateSnapshots
        return !_.isNil(localStorageSnapshots) && localStorageSnapshots.trim().length > 0
            ? JSON.parse(localStorageSnapshots)
            : []
    }

    updateProfileStateSnapshots (snapshots=[]) {
        localStorage.profileStateSnapshots = !_.isEmpty(snapshots) ? JSON.stringify(snapshots) : ''
        this.setState({
            snapshots: this.getProfileStateSnapshots()
        })
    }

    handleClickCreateSnapshotButton () {
        const { snapshots } = this.state
        const { focusedGroupProfileIds, profileItems } = this.props

        const newSnapshotProfileStates = focusedGroupProfileIds.reduce((result,  profileId) => {
            if (_.has(profileItems, `${profileId}.params`)) {
                const profileItem = profileItems[profileId]
                const profileState = ProfileState({
                    id: profileId,
                    name: profileItem.name,
                    hostname: profileItem.hostname,
                    started: profileItem.started,
                    resumed: profileItem.resumed
                })
                result.push(profileState)
            }
            return result
        }, [])

        const newSnapshots = [ProfileStateSnapshot({
            id: uuidv4(),
            name: `Snapshot - ${Math.ceil(Math.random() * 999)}`,
            createdTime: moment().toISOString(),
            profileStates: newSnapshotProfileStates
        })].concat(snapshots)

        this.updateProfileStateSnapshots(newSnapshots)
    }

    SnapshotDeleteModal () {
        const { snapshots, snapshotToDelete } = this.state
        return snapshotToDelete ? (
            <Modal
                overlayClassName='modal-overlay'
                className='profile-state-snapshot-container--delete-modal'
                isOpen>
                <div className='profile-state-snapshot-container--delete-modal--body centered'>
                    <div className='profile-state-snapshot-container--delete-modal--caption'>{`Are you sure to delete ${snapshotToDelete.name}?`}</div>
                    <div className='profile-state-snapshot-container--delete-modal--buttons'>
                        <button className='profile-state-snapshot-container--delete-modal--button cancel' 
                            onClick={() => { this.setState({ snapshotToDelete: null }) }}>
                            {'Never Mind'}
                        </button>
                        <button className='profile-state-snapshot-container--delete-modal--button delete' onClick={() => {
                            const newSnapshots = snapshots.filter(snapshot => snapshot.id !== snapshotToDelete.id)
                            this.setState({ snapshotToDelete: null })
                            this.updateProfileStateSnapshots(newSnapshots)
                        }}>{'Delete'}</button>
                    </div>
                </div>
            </Modal>
        ) : null
    }

    SnapshotList () {
        const { snapshots } = this.state
        return (
            <div className='profile-state-snapshot-container--list'>
                {snapshots.map((snapshot, index) => {
                    const { id, name, createdTime, profileStates } = snapshot
                    return (
                        <div className='profile-state-snapshot-container--item' key={id}
                            onClick={() => { this.setState({ snapshotIndexEditing: index }) }}>
                            <button className='profile-state-snapshot-container--item--remove-button' 
                                onClick={(e) => { 
                                    e.stopPropagation()
                                    this.setState({ snapshotToDelete: snapshot }) 
                                }}>
                                <FiX />
                            </button>
                            <div className='profile-state-snapshot-container--item--name'>{name}</div>
                            <div className='profile-state-snapshot-container--item--footer clearfix'>
                                <div className='profile-state-snapshot-container--item--profile-count'>{`${profileStates.length} Profile${profileStates.length > 1 ? 's' : ''}`}</div>
                                <div className='profile-state-snapshot-container--item--created-time'>{`Created At: ${moment(createdTime).format('MM-DD HH:mm:ss')}`}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render () {
        const { snapshots, snapshotToDelete, snapshotIndexEditing } = this.state
        return (
            <div className='profile-state-snapshot-container'>
                <div className='profile-state-snapshot-container--header'>
                    <div className='profile-state-snapshot-container--header--title'>{'Profile State Snapshot'}</div>
                    <button className='profile-state-snapshot-container--header--create-snapshot-button' 
                        onClick={() => { this.handleClickCreateSnapshotButton() }}>
                        {'Save Current Group As Snapshot'}
                    </button>
                </div>
                <div className='profile-state-snapshot-container--body'>
                    {this.SnapshotList()}
                </div>
                {!_.isNil(snapshotIndexEditing) && 
                <ProfileStateSnapshotModal 
                    snapshot={snapshots[snapshotIndexEditing]} 
                    onClickCloseButton={() => { this.setState({ snapshotIndexEditing: null }) }} 
                    onUpdateSnapshot={(newSnapshot) => {
                        const snapshotIndex = _.findIndex(snapshots, { id: newSnapshot.id })
                        if (snapshotIndex >=0) {
                            const newSnapshots = dotProp.set(snapshots, snapshotIndex, newSnapshot)
                            this.updateProfileStateSnapshots(newSnapshots)
                        }
                    }}/>}
                {this.SnapshotDeleteModal(snapshotToDelete)}
            </div>
        )
    }
}

ProfileStateSnapshotContainer.propTypes = {
    focusedGroupProfileIds: PropTypes.array.isRequired,
    profileItems: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    const { group, items: profileItems } = state.profile
    const focusedGroupProfileIds = _.has(group.items, `${group.focusedId}.profileIds`) ? group.items[group.focusedId].profileIds : []
    return {
        focusedGroupProfileIds,
        profileItems
    }
}

export default connect(mapStateToProps)(ProfileStateSnapshotContainer)