export const MARKETS = {
    SPOT: {
        value: 0,
        name: 'Spot'
    },
    MARGIN: {
        value: 1,
        name: 'Margin'
    },
    FUTURE: {
        value: 2,
        name: 'Futures'
    },
    SWAP: {
        value: 3,
        name: 'Swap'
    },
    OPTION: {
        value: 5,
        name: 'Options'
    },
    WALLET: {
        value: 4,
        name: 'Wallet'
    }
}