import { batch } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import uuidv4 from 'uuid/v4'
import dotProp from 'dot-prop-immutable'
import * as fflate from 'fflate'

import { store } from '../../store'
import { secureFetch } from '../../util/util'
import { playSound } from '../../util/soundEffectUtil'
import { DEFAULT_SERVER, SERVERS, getApiBaseUrlByHostname, RECEIVED_WEBSOCKET_MESSAGE_BUFFER_INTERVAL } from '../../configs/config'
import { EXCHANGES as TOKEN_TRANSFER_EXCHANGE_CONFIGS } from '../account/TokenTransferEditor'

import { getProfileTradingAccountNamesBySymbol } from '../../util/profileUtil'
import { updateProfileItem, updateProfileRunningState, fetchProfileParams, updateProfileOrderEditorVariables } from '../profile/profileAction'
import { addNotificationItem, updateManualOrders, PROFILE_ORDER_STATUS, removeManulOrders, UPDATE_TRADING_POSITIONS } from '../trading/tradingAction'
import { updateSymbolOrderBooks } from '../symbol/symbolAction'
import { fetchTimerById, removeTimerItem } from '../timer/timerAction'
import { fetchAccountMarginBalance, UPDATE_ACCOUNT_ASSET, UPDATE_ACCOUNT_CROSS_BALANCE, UPDATE_ACCOUNT_CROSS_MARGIN_BALANCE, UPDATE_ACCOUNT_FUTURE_BALANCE, UPDATE_ACCOUNT_MARGIN_BALANCE, UPDATE_ACCOUNT_SPOT_BALANCE, UPDATE_ACCOUNT_SWAP_BALANCE, UPDATE_ACCOUNT_WALLET_BALANCE } from '../account/accountAction'
import { updateProfileSymbolPricingUpdateTime } from './ProfileSymbolPricingMonitor'

export const UPDATE_WEB_SOCKET = 'UPDATE_WEB_SOCKET'
export const UPDATE_WEB_SOCKET_DELAY_MILLISECONDS = 'UPDATE_WEB_SOCKET_DELAY_MILLISECONDS'
export const UPDATE_WEB_SOCKET_BUFFERED_TRADING_MESSAGE_DATA = 'UPDATE_WEB_SOCKET_BUFFERED_TRADING_MESSAGE_DATA'
export const UPDATE_WEB_SOCKET_BUFFERED_PROFILE_STATE_DATA = 'UPDATE_WEB_SOCKET_BUFFERED_PROFILE_STATE_DATA'

const webSockets = _.filter(SERVERS, { enabled: true }).reduce((result, server) => {
    return dotProp.set(result, server.hostname, {
        hostname: server.hostname,
        url: server.wsBaseUrl,
        socket: null,
        checkSocketReadyStateInterval: null,
        pingPongInterval: null,
        lastPingId: null
    })
}, {})

const bufferedData = {
    profileItems: {},
    runningStates: {},
    pricingItems: {},
    transactionItems: [],
    notificationItems: [],
    orderBook: {},
    manualOrders: {},
    socketDelayMilliseconds: {},
    accountBalances: {
        spotBalance: {},
        marginBalance: {},
        crossMarginBalance: {},
        futureBalance: {},
        swapBalance: {},
        walletBalance: {},
        crossBalance: {},
        asset: {}
    }
}
const dumpBufferedDataIntervals = {}

const marginAccountBalanceScheduler = {
    isLocked: false,
    isQueued: false,
    fetchData: function () {
        if (!this.isLocked) {
            this.isLocked = true
            setTimeout(() => { this.isLocked = false }, 3000)
            store.dispatch(fetchAccountMarginBalance())
        } else if (!this.isQueued) {
            this.isQueued = true
            setTimeout(() => {
                this.fetchData()
                this.isQueued = false
            }, 3000)
        }
    }
}

function updateWebSocket (hostname, params) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_WEB_SOCKET,
            hostname,
            params
        })
    }
}

const bufferProfileItem = (profileId, params) => {
    bufferedData.profileItems[profileId] = Object.assign({}, bufferedData.profileItems[profileId] || {}, params)
}

const bufferRunningState = (profileId, params) => {
    bufferedData.runningStates[profileId] = Object.assign({}, bufferedData.runningStates[profileId] || {}, params)
}

const bufferPricingItem = (symbolName, params) => {
    bufferedData.pricingItems[symbolName] = Object.assign({}, bufferedData.pricingItems[symbolName] || {}, params)
}

const bufferTransactionItem = (transactionItem) => {
    bufferedData.transactionItems.unshift(transactionItem)
}

const bufferNotificationItem = (notificationItem) => {
    bufferedData.notificationItems.unshift(notificationItem)
}

const bufferOrderBook = (symbolOrderBook) => {
    bufferedData.orderBook = Object.assign({}, bufferedData.orderBook, symbolOrderBook)
}

const bufferManualOrders = (manualOrders) => {
    bufferedData.manualOrders = Object.assign({}, bufferedData.manualOrders, manualOrders)
}

const bufferSocketDelayMilliseconds = (hostname, delayMilliseconds) => {
    const prevDelayMilliseconds = bufferedData.socketDelayMilliseconds[hostname]

    bufferedData.socketDelayMilliseconds[hostname] = _.isNumber(prevDelayMilliseconds) 
        ? Math.max(prevDelayMilliseconds, delayMilliseconds)
        : delayMilliseconds
}

const bufferAccountBalances = (key, items) => {
    bufferedData.accountBalances = dotProp.merge(bufferedData.accountBalances, key, items)
}

export function dumpBufferedAccountBalances () {
    return (dispatch) => {
        const { accountBalances } = bufferedData
        batch(() => {
            if (!_.isEmpty(accountBalances.spotBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_SPOT_BALANCE,
                    spotBalance: accountBalances.spotBalance
                })
                bufferedData.accountBalances.spotBalance = {}
            }
            if (!_.isEmpty(accountBalances.marginBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_MARGIN_BALANCE,
                    marginBalance: accountBalances.marginBalance
                })
                bufferedData.accountBalances.marginBalance = {}
            }
            if (!_.isEmpty(accountBalances.crossMarginBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_CROSS_MARGIN_BALANCE,
                    crossMarginBalance: accountBalances.crossMarginBalance
                })
                bufferedData.accountBalances.crossMarginBalance = {}
            }
            if (!_.isEmpty(accountBalances.futureBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_FUTURE_BALANCE,
                    futureBalance: accountBalances.futureBalance
                })
                bufferedData.accountBalances.futureBalance = {}
            }
            if (!_.isEmpty(accountBalances.swapBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_SWAP_BALANCE,
                    swapBalance: accountBalances.swapBalance
                })
                bufferedData.accountBalances.swapBalance = {}
            }
            if (!_.isEmpty(accountBalances.crossBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_CROSS_BALANCE,
                    crossBalance: accountBalances.crossBalance
                })
                bufferedData.accountBalances.crossBalance = {}
            }
            if (!_.isEmpty(accountBalances.walletBalance)) {
                dispatch({
                    type: UPDATE_ACCOUNT_WALLET_BALANCE,
                    walletBalance: accountBalances.walletBalance
                })
                bufferedData.accountBalances.walletBalance = {}
            }
            if (!_.isEmpty(accountBalances.asset)) {
                dispatch({
                    type: UPDATE_ACCOUNT_ASSET,
                    asset: accountBalances.asset
                })
                bufferedData.accountBalances.asset = {}
            }
        })
    }
}

const registerDumpingBufferedDataInterval = () => {
    return (dispatch) => {
        if (dumpBufferedDataIntervals.S1) {
            window.clearInterval(dumpBufferedDataIntervals.S1)
        }
        if (dumpBufferedDataIntervals.S2) {
            window.clearInterval(dumpBufferedDataIntervals.S2)
        }
        if (dumpBufferedDataIntervals.S3) {
            window.clearInterval(dumpBufferedDataIntervals.S3)
        }

        dumpBufferedDataIntervals.S1 = setInterval(() => {
            const { orderBook, manualOrders } = bufferedData
            batch(() => {
                if (!_.isEmpty(orderBook)) {
                    dispatch(updateSymbolOrderBooks(orderBook))
                    bufferedData.orderBook = {}
                }
                if (!_.isEmpty(manualOrders)) {
                    dispatch(updateManualOrders(manualOrders))
                    bufferedData.manualOrders = {}
                }
            })
        }, 600)

        dumpBufferedDataIntervals.S2 = setInterval(() => {
            const { transactionItems, notificationItems } = bufferedData
            if (!_.isEmpty(transactionItems) || !_.isEmpty(notificationItems)) {
                dispatch({
                    type: UPDATE_WEB_SOCKET_BUFFERED_TRADING_MESSAGE_DATA,
                    transactionItems,
                    notificationItems
                })
                bufferedData.transactionItems = []
                bufferedData.notificationItems = []
            }
        }, 2000)

        dumpBufferedDataIntervals.S3 = setInterval(() => {
            const { profileItems, runningStates, pricingItems, socketDelayMilliseconds } = bufferedData
            batch(() => {
                if (!_.isEmpty(profileItems) || !_.isEmpty(runningStates) || !_.isEmpty(pricingItems)) {
                    dispatch({
                        type: UPDATE_WEB_SOCKET_BUFFERED_PROFILE_STATE_DATA,
                        profileItems,
                        runningStates,
                        pricingItems
                    })
                    bufferedData.profileItems = {}
                    bufferedData.runningStates = {}
                    bufferedData.pricingItems = {}
                }
                if (!_.isEmpty(socketDelayMilliseconds)) {
                    dispatch({
                        type: UPDATE_WEB_SOCKET_DELAY_MILLISECONDS,
                        socketDelayMilliseconds
                    })
                    bufferedData.socketDelayMilliseconds = {}
                }
            })
            dispatch(dumpBufferedAccountBalances())
        }, RECEIVED_WEBSOCKET_MESSAGE_BUFFER_INTERVAL)
    }
}

const fetchWebSocketTicket = (hostname) => {
    return (dispatch) => {
        return dispatch(secureFetch(`${getApiBaseUrlByHostname(hostname)}/user/ws-ticket`)).then(response => {
            if (response.status === 200) {
                return response.text()
            }
        })
    }
}

const registerCheckSocketReadyStateInterval = (hostname) => {
    return (dispatch, getState) => {
        const webSocketItem = webSockets[hostname]
        if (webSocketItem) {
            const { socket, checkSocketReadyStateInterval } = webSocketItem
            if (checkSocketReadyStateInterval) {
                window.clearInterval(checkSocketReadyStateInterval)
            }
            webSocketItem.checkSocketReadyStateInterval = setInterval(() => {
                if (!navigator.onLine && ![WebSocket.CLOSING, WebSocket.CLOSED].includes(socket.readyState)) {
                    socket.close()
                }  
                if (socket.readyState !== getState().webSocket[hostname].readyState) {
                    dispatch(updateWebSocket(hostname, { readyState: socket.readyState }))
                }
                if (socket.readyState === WebSocket.CLOSED) {
                    window.clearInterval(checkSocketReadyStateInterval)
                }
            }, 1000)
        }
    }
}

const registerPingPong = (hostname) => {
    return (dispatch, getState) => {
        const { pingPongInterval } = webSockets[hostname]
        const ping = () => {
            const { username } = getState().auth
            const { socket } = webSockets[hostname]
            webSockets[hostname].lastPingId = uuidv4()
            if (socket.readyState === WebSocket.OPEN && !_.isEmpty(username)) {
                webSocketSendData(hostname, {
                    user: username,
                    id: webSockets[hostname].lastPingId,
                    time: moment().toISOString(),
                    type: 'ping'
                })
            }
        }
        ping()
        if (pingPongInterval) {
            window.clearInterval(pingPongInterval)
        }
        webSockets[hostname].pingPongInterval = setInterval(() => {
            ping()
        }, 10000)

    }
}

const ReconnectSocketScheduler = {
    hostnamesWillReconnect: {},
    shouldReconnect: function (hostname) {
        return (dispatch, getState) => {
            const { socket } = webSockets[hostname]
            const { isLoggedIn } = getState().auth
            return (_.isNil(socket) || socket.readyState !== WebSocket.OPEN) && isLoggedIn
        }
    },
    addHostnameWillReconnect: function (hostname) {
        this.hostnamesWillReconnect[hostname] = 1
    },
    removeHostnameWillReconnect: function (hostname) {
        delete this.hostnamesWillReconnect[hostname]
    },
    reconnect: function (hostname, timeoutInSeconds = 3) {
        return (dispatch) => {
            if (!_.has(this.hostnamesWillReconnect, hostname) && this.shouldReconnect(hostname)) {
                this.addHostnameWillReconnect(hostname)

                timeoutInSeconds = parseInt(Math.max(timeoutInSeconds, 1))
                console.log(`It will try reconnect WebSocket: ${hostname} in ${timeoutInSeconds}s`)

                const { socket } = webSockets[hostname]
                let reconnectTimeoutInterval

                dispatch(updateWebSocket(hostname, {
                    reconnectTimeout: timeoutInSeconds,
                    readyState: !_.isNil(socket) ? socket.readyState : WebSocket.CLOSED
                }))

                reconnectTimeoutInterval = setInterval(() => {
                    if (this.shouldReconnect(hostname)) {
                        timeoutInSeconds--
                        if (timeoutInSeconds > 0) {
                            dispatch(updateWebSocket(hostname, { reconnectTimeout: timeoutInSeconds }))
                        } else {
                            dispatch(webSocketConnect(hostname, true))
                            dispatch(updateWebSocket(hostname, { reconnectTimeout: null }))
                            window.clearTimeout(reconnectTimeoutInterval)
                            this.removeHostnameWillReconnect(hostname)
                        }
                    } else {
                        window.clearTimeout(reconnectTimeoutInterval)
                        this.removeHostnameWillReconnect(hostname)
                    }
                }, 1000)
            }
        }
    }
}


const onSocketOpen = (e, hostname) => {
    return (dispatch) => {
        console.log(`${hostname} WebSocket is connected: `, e)
        dispatch(updateWebSocket(hostname, { readyState: WebSocket.OPEN }))
        dispatch(registerCheckSocketReadyStateInterval(hostname))
        dispatch(registerPingPong(hostname))
    }
}

const onSocketClose = (e, hostname) => {
    return (dispatch) => {
        console.log(`${hostname} WebSocket is closed: `, e)
        dispatch(updateWebSocket(hostname, { readyState: WebSocket.CLOSED }))
        dispatch(ReconnectSocketScheduler.reconnect(hostname, 5))
    }
}

const onSocketError = (e, hostname) => {
    return (dispatch) => {
        const { socket } = webSockets[hostname] 
        console.error(`${hostname} WebSocket error: `, e)
        dispatch(updateWebSocket(hostname, { readyState: socket ? socket.readyState : WebSocket.CLOSED }))
    }
}

export function webSocketConnect (hostname = DEFAULT_SERVER.hostname, shouldSkipIfConnected=false) {
    return (dispatch, getState) => {
        const webSocketItem = webSockets[hostname]
        if (webSocketItem && getState().auth.isLoggedIn) {
            const { socket, url } = webSocketItem
            if (shouldSkipIfConnected && socket && socket.readyState === WebSocket.OPEN) {
                return
            } else {
                if (socket && ![WebSocket.CLOSING, WebSocket.CLOSED].includes(socket.readyState)) {
                    socket.close()
                    updateWebSocket(hostname, { readyState: WebSocket.CLOSED })
                }
    
                dispatch(updateWebSocket(hostname, { isFetchingTicket: true }))
    
                const isFetchingTicketTimeout = setTimeout(() => {
                    console.error('webSocketConnect fetchWebSocketTicket Timeout')
                    dispatch(updateWebSocket(hostname, { isFetchingTicket: false }))
                    dispatch(ReconnectSocketScheduler.reconnect(hostname, 8))
                }, 10000)
    
                dispatch(fetchWebSocketTicket(hostname))
                .then(text => {
                    if (_.isString(text)) {
                        console.log(`Try to connect ${hostname} WebSocket`)
                        dispatch(updateWebSocket(hostname, { readyState: WebSocket.CONNECTING }))
                        dispatch(registerDumpingBufferedDataInterval())
                        webSockets[hostname].socket = new WebSocket(`${url}?ticket=${text}`)
                        const { socket: newSocket } = webSockets[hostname]
                        newSocket.onopen = (e) => { dispatch(onSocketOpen(e, hostname)) }
                        newSocket.onmessage = (e) => { dispatch(onReceiveSocketMessage(e, hostname)) }
                        newSocket.onclose = (e) => { dispatch(onSocketClose(e, hostname)) }
                        newSocket.onerror = (e) => { dispatch(onSocketError(e, hostname)) }
                    } else {
                        throw new Error('Unexpected Return')
                    }
                })
                .catch(error => {
                    console.error('webSocketConnect fetchWebSocketTicket Error: ', error)
                    dispatch(ReconnectSocketScheduler.reconnect(hostname, 8))
                })
                .finally(() => {
                    window.clearTimeout(isFetchingTicketTimeout)
                    dispatch(updateWebSocket(hostname, { isFetchingTicket: false }))
                })
            }
        }
    }
}

export function webSocketConnectAll () {
    return (dispatch) => {
        _.filter(SERVERS, { enabled: true }).forEach(server => {
            dispatch(webSocketConnect(server.hostname))
        })
    }
}

export function webSocketSendData (hostname, data) {
    const webSocketItem = webSockets[hostname]
    if (webSocketItem) {
        const { socket } = webSocketItem
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify(data))
            // console.log(`${hostname} WebSocket sent data: `, data)
        }

    }
}

export function webSocketDisconnectAll () {
    Object.values(webSockets).forEach(webSocket => {
        if (webSocket.socket) {
            webSocket.socket.close()
        }
    })
}

const onReceiveSocketMessage = (e, hostname) => {
    return async (dispatch, getState) => {
        try {
            let profileId, profileRunningState, params, switchOffMessages, reduceOnlySwitches, profileSmartPosAccounts,
                profileSmartPosAccountExceptions, orderIds, orderIdsToRemove, runningStatePosition, webSocketItem
            let data
            if (_.isObject(e.data)) {
                const compressed = new Uint8Array(await e.data.arrayBuffer())
                const decompressed = fflate.decompressSync(compressed)
                data = JSON.parse(fflate.strFromU8(decompressed))
            } else if (_.isString(e.data)){
                data = JSON.parse(e.data)
            } else {
                return
            }
            if (process.env.REACT_APP_WEB_SOCKET_RECEIVE_MESSAGE_LOGGER_ENABLED !== 'FALSE') {
                console.log(`${hostname} WebSocket received message: `, data)
            } 
            
            // if (['profile_strategy_info', 'position_initial_snapshot', 'atweb_order_update', 'position', 'position_update', 
            //     'spot_account_balance', 'margin_account_balance', 'cross_margin_account_balance', 'future_account_balance',
            //     'swap_account_balance', 'wallet_account_balance', 'balance'].includes(data.type) 
            //     && _.has(data, 'time')) {
            //     bufferSocketDelayMilliseconds(data.hostname || hostname, moment().diff(data.time))
            // } else if (data.type === 'ORDER_BOOK' && !_.isEmpty(data.info)) {
            //     const orderBookHeadSymbolItem = _.head(Object.values(data.info))
            //     if (_.has(orderBookHeadSymbolItem, 'timestamp')) {
            //         bufferSocketDelayMilliseconds(data.hostname || hostname, moment().diff(orderBookHeadSymbolItem.timestamp))
            //     }
            // } 

            switch (data.type) {
                case 'pong':
                    webSocketItem = webSockets[hostname]
                    if (!_.isNil(webSocketItem)) {
                        if (webSocketItem.lastPingId === data.id) {
                            bufferSocketDelayMilliseconds(hostname, moment().diff(data.time))
                        } else {
                            bufferSocketDelayMilliseconds(hostname, '10000+')
                        }
                    }
                    break

                case 'position':
                    if (_.has(data, 'info') && _.isArray(data.info)) {
                        dispatch({
                            type: UPDATE_TRADING_POSITIONS,
                            positions: data.info
                        })
                    }
                    break

                case 'spot_account_balance':
                    if (_.has(data, 'info') && _.isArray(data.info)) {
                        bufferAccountBalances('spotBalance', _.keyBy(data.info, spotAccountBalance => `${spotAccountBalance.acct_name}--${spotAccountBalance.coin}`))
                    }
                    break

                case 'margin_account_balance':
                    if (_.has(data, 'info') && _.isArray(data.info)) {
                        bufferAccountBalances('marginBalance', _.keyBy(data.info, marginAccountBalance => `${marginAccountBalance.acct_name}--${marginAccountBalance.pair}`))
                    }
                    break

                case 'cross_margin_account_balance':
                    if (_.has(data, 'info') && _.isArray(data.info)) {
                        bufferAccountBalances('crossMarginBalance', _.keyBy(data.info, crossMarginAccountBalance => `${crossMarginAccountBalance.acct_name}--${crossMarginAccountBalance.coin}`))
                    }
                    break

                case 'future_account_balance':
                    if (_.has(data, 'info') && _.isArray(data.info)) {
                        bufferAccountBalances('futureBalance', _.keyBy(data.info, futureAccountBalance => `${futureAccountBalance.acct_name}--${futureAccountBalance.coin}`))
                    }
                    break

                case 'swap_account_balance':
                    if (_.has(data, 'info') && _.isArray(data.info)) {
                        bufferAccountBalances('swapBalance', _.keyBy(data.info, swapAccountBalance => `${swapAccountBalance.acct_name}--${swapAccountBalance.coin}`))
                    }
                    break

                case 'wallet_account_balance':
                    if (_.has(data, 'info') && _.isArray(data.info)) {
                        bufferAccountBalances('walletBalance', _.keyBy(data.info, walletAccountBalance => `${walletAccountBalance.acct_name}--${walletAccountBalance.coin}`))
                    }
                    break
            
                case 'cross_account_balance':
                    if (_.has(data, 'info') && _.isArray(data.info)) {
                        bufferAccountBalances('crossBalance', _.keyBy(data.info, crossAccountBalance => `${crossAccountBalance.acct_name}--${crossAccountBalance.coin}`))
                    }
                    break

                case 'balance':
                    if (_.has(data, 'info') && _.isArray(data.info)) {
                        bufferAccountBalances('asset', _.keyBy(data.info, 'acct_name'))
                    }
                break

                case 'para_config_details':
                    profileId = `${data.profile}_${data.hostname}`
                    params = Object.assign({}, data.info, { 
                        id: profileId,
                        name: data.profile, 
                        user: data.user,
                        hostname: data.hostname
                    })
                    bufferProfileItem(profileId, params)
                    break
    
                case 'status_initial_snapshot':
                    profileId = `${data.profile}_${data.hostname}`
                    params = { 
                        id: profileId,
                        crashed: data.info.crashed,
                        started: data.info.start, 
                        resumed: !data.info.pause, 
                        pauseReason: data.info.pause_reason,
                        pauseTimestamp: data.info.pause_timestamp,
                        log_level: data.info.log_level,
                        clean_start_timestamp: data.info.clean_start_timestamp,
                        start_timestamp: data.info.start_timestamp,
                        name: data.profile,  
                        user: data.user,
                        hostname: data.hostname
                    }
                    bufferProfileItem(profileId, params)
                    if (profileId === `atweb_order_server_pf_${process.env.REACT_APP_DEFAULT_SERVER_HOSTNAME}` && _.has(data, 'info')) {
                        dispatch(updateProfileOrderEditorVariables({
                            global_margin_ratio_threshold: data.info.global_margin_ratio_threshold
                        }))
                    }
                    break

                case 'switch_initial_snapshot':
                    profileId = `${data.profile}_${data.hostname}`
                    if (_.isArray(data.info) && data.info.length > 0) {
                        switchOffMessages = data.info.filter(message => message.type === 'SWITCH_OFF')
                        switchOffMessages.forEach((switchOffMessage) => {
                            const profileItem = getState().profile.items[profileId] || bufferedData.profileItems[profileId]
                            const symbolTradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, switchOffMessage.symbol)
                            const unionSymbolTradingAccountNames = _.uniq([...symbolTradingAccountNames.BUY, ...symbolTradingAccountNames.SELL])

                            if (!_.has(profileItem, 'accounts') || unionSymbolTradingAccountNames.includes(switchOffMessage.account)) {
                                if (getState().setting.soundEffect.profileSwitchOffIncludingNAC || switchOffMessage.reason !== 'NAC switched off') {
                                    playSound(getState().setting.soundEffect.events.PROFILE_SWITCH_OFF)
                                }
                                // bufferNotificationItem({
                                //     id: uuidv4(),
                                //     timestamp: switchOffMessage.timestamp,
                                //     profileId: profileId,
                                //     user: switchOffMessage.user,
                                //     hostname: switchOffMessage.hostname,
                                //     type: 'SWITCH_OFF',
                                //     message: `<b>${switchOffMessage.account}</b> switched off <b>${switchOffMessage.symbol}</b> in <strong>${switchOffMessage.side}</strong> side due to <i>${switchOffMessage.reason}</i>.`,
                                //     originalMessage: switchOffMessage
                                // })
                            }
                        })
                    }
                    bufferRunningState(profileId, { switchOffs: switchOffMessages || [] })
                    break

                case 'reduceonly_switch_initial_snapshot':
                    profileId = `${data.profile}_${data.hostname}`
                    if (_.isArray(data.info) && data.info.length > 0) {
                        reduceOnlySwitches = data.info
                    }
                    bufferRunningState(profileId, { reduceOnlySwitches: reduceOnlySwitches || [] })
                    break

                case 'position_initial_snapshot':
                    profileId = `${data.profile}_${data.hostname}`
                    _.forEach(data.info || {}, (symbolPositions, symbolName) => {
                        _.forEach(symbolPositions, (accountPosition, accountName) => {
                            _.merge(accountPosition, {
                                symbol: symbolName,
                                acct_name: accountName
                            })
                        })
                    })
                    bufferRunningState(profileId, { 
                        position: data.info
                    })
                    break

                case 'position_update':
                    profileId = `${data.profile}_${data.hostname}`                    
                    runningStatePosition = _.has(bufferedData.runningStates, `${profileId}.position`) ? _.cloneDeep(bufferedData.runningStates[profileId].position || {})
                        : _.has(getState().profile.runningState, `${profileId}.position`) ? _.cloneDeep(getState().profile.runningState[profileId].position || {})
                        : {}

                    if (!_.has(runningStatePosition, data.info.symbol)) {
                        runningStatePosition[data.info.symbol] = {}
                    }
                    runningStatePosition[data.info.symbol][data.info.acct_name] = data.info
                    bufferRunningState(profileId, { position: runningStatePosition })
                    break

                case 'profile_strategy_info':
                    profileId = `${data.profile}_${data.hostname}`
                    bufferRunningState(profileId, { strategyInfo: Object.assign({}, data.info, { timestamp: data.time }) })
                    if (!_.isEmpty(data.info.symbols)) {
                        _.forEach(data.info.symbols, (symbolItem, symbolName) => {
                            const pricingItem = getState().symbol.pricings[symbolName]
                            bufferPricingItem(symbolName, {
                                symbolName: symbolName,
                                bid: symbolItem && _.isArray(symbolItem.price) ? symbolItem.price[0] : null,
                                ask: symbolItem && _.isArray(symbolItem.price) ? symbolItem.price[1] : null,
                                last: symbolItem && !_.isNil(symbolItem.last) ? symbolItem.last : (pricingItem && !_.isNil(pricingItem.last) ? pricingItem.last : null),
                                timestamp: data.time
                            })
                            updateProfileSymbolPricingUpdateTime({
                                profileId,
                                symbolName,
                                price: symbolItem.price
                            })
                            if (!_.isNil(symbolItem.ref_prods)) {
                                _.forEach(symbolItem.ref_prods, (refSymbolItem, refSymbolName) => {
                                    const pricingItem = getState().symbol.pricings[refSymbolName]
                                    bufferPricingItem(refSymbolName, {
                                        symbolName: refSymbolName,
                                        bid: refSymbolItem && _.isArray(refSymbolItem.price) ? refSymbolItem.price[0] : null,
                                        ask: refSymbolItem && _.isArray(refSymbolItem.price) ? refSymbolItem.price[1] : null,
                                        last: refSymbolItem && !_.isNil(refSymbolItem.last) ? refSymbolItem.last : (pricingItem && !_.isNil(pricingItem.last) ? pricingItem.last : null),
                                        timestamp: data.time
                                    })
                                    if (!_.isNil(refSymbolItem)) {
                                        updateProfileSymbolPricingUpdateTime({
                                            profileId,
                                            symbolName: refSymbolName,
                                            price: _.isArray(refSymbolItem.price) ? refSymbolItem.price : refSymbolItem.last
                                        })
                                    }
                                })
                            }
                        })
                    }   
                    break

                case 'LOG_LEVEL_UPDATED':
                    profileId = `${data.profile}_${data.hostname}`
                    dispatch(updateProfileItem(profileId, { log_level: data.reason }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: data.timestamp,
                        profileId: profileId,
                        user: data.user,
                        hostname: data.hostname,
                        type: 'LOG_LEVEL_UPDATED',
                        message: `New <i>LOG LEVEL</i> is <strong>${data.reason}</strong>`
                    }))
                    break
    
                case 'START':
                    profileId = `${data.profile}_${data.hostname}`
                    dispatch(updateProfileItem(profileId, { started: true, isStarting: false }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: data.timestamp,
                        profileId: profileId,
                        user: data.user,
                        hostname: data.hostname,
                        type: 'STARTED',
                        message: 'Profile is started.'
                    }))
                    break
    
                case 'STOP':
                    profileId = `${data.profile}_${data.hostname}`
                    dispatch(updateProfileItem(profileId, { started: false, isStopping: false }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: data.timestamp,
                        profileId: profileId,
                        user: data.user,
                        hostname: data.hostname,
                        type: 'STOPPED',
                        message: 'Profile is stopped.'
                    }))
                    dispatch(fetchProfileParams({ 
                        profileId,
                        updateStore: true
                    }))
                    break
    
                case 'RESUME':
                    profileId = `${data.profile}_${data.hostname}`
                    dispatch(updateProfileItem(profileId, { resumed: true, isResuming: false, pauseReason: null }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: data.timestamp,
                        profileId: profileId,
                        user: data.user,
                        hostname: data.hostname,
                        type: 'RESUMED',
                        message: 'Profile is resumed.'
                    }))
                    break
    
                case 'PAUSE':
                    profileId = `${data.profile}_${data.hostname}`
                    playSound(getState().setting.soundEffect.events.PROFILE_PAUSED)
                    dispatch(updateProfileItem(profileId, { resumed: false, isPausing: false, pauseReason: data.reason, pauseTimestamp: data.timestamp }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: data.timestamp,
                        profileId: profileId,
                        user: data.user,
                        hostname: data.hostname,
                        type: 'PAUSED',
                        message: `Profile is paused due to <i>${data.reason}</i>.`,
                        originalMessage: data
                    }))
                    break

                case 'ORDER_DISCARD':
                    profileId = `${data.profile}_${data.hostname}`
                    bufferNotificationItem({
                        id: uuidv4(),
                        timestamp: data.timestamp,
                        profileId: profileId,
                        user: data.user,
                        hostname: data.hostname,
                        type: 'ORDER_DISCARD',
                        message: `<b>${data.account}</b> discarded <b>${data.side}</b> order @ <b>${data.symbol}</b> due to <i>${data.reason}</i>.`
                    })
                    break

                case 'SWITCH_OFF':
                    profileId = `${data.profile}_${data.hostname}`
                    profileRunningState = getState().profile.runningState[profileId]
                    if (profileRunningState) {
                        let newProfileSwitchOffs = _.isArray(profileRunningState.switchOffs) ? _.cloneDeep(profileRunningState.switchOffs) : []
                        if (!_.find(newProfileSwitchOffs, { symbol: data.symbol, account: data.account, side: data.side })) {
                            newProfileSwitchOffs.push(data)
                        }
                        dispatch(updateProfileRunningState(profileId, { switchOffs: newProfileSwitchOffs }))
                    }
                    if (getState().setting.soundEffect.profileSwitchOffIncludingNAC || data.reason !== 'NAC switched off') {
                        playSound(getState().setting.soundEffect.events.PROFILE_SWITCH_OFF)
                    }
                    // dispatch(addNotificationItem({
                    //     id: uuidv4(),
                    //     timestamp: data.timestamp,
                    //     profileId: profileId,
                    //     user: data.user,
                    //     hostname: data.hostname,
                    //     type: 'SWITCH_OFF',
                    //     message: `<b>${data.account}</b> switched off <b>${data.symbol}</b> in <b>${data.side}</b> side due to <i>${data.reason}</i>.`,
                    //     originalMessage: data        
                    // }))
                    break

                case 'SWITCH_ON':
                    profileId = `${data.profile}_${data.hostname}`
                    profileRunningState = getState().profile.runningState[profileId]
                    if (profileRunningState) {
                        let newProfileSwitchOffs = _.isArray(profileRunningState.switchOffs) ? _.cloneDeep(profileRunningState.switchOffs) : []
                        _.remove(newProfileSwitchOffs, (switchOff) => switchOff.symbol === data.symbol && switchOff.account === data.account && switchOff.side === data.side)
                        dispatch(updateProfileRunningState(profileId, { switchOffs: newProfileSwitchOffs }))
                    }

                    // dispatch(addNotificationItem({
                    //     id: uuidv4(),
                    //     timestamp: data.timestamp,
                    //     profileId: profileId,
                    //     user: data.user,
                    //     hostname: data.hostname,
                    //     type: 'SWITCH_ON',
                    //     message: `<b>${data.account}</b> switched on <b>${data.symbol}</b> in <b>${data.side}</b> side.`
                    // }))
                    break

                case 'REDUCE_ONLY_SWITCH_OFF':
                    profileId = `${data.profile}_${data.hostname}`
                    profileRunningState = getState().profile.runningState[profileId]
                    if (profileRunningState) {
                        let newProfileReduceOnlySwitches = _.isArray(profileRunningState.reduceOnlySwitches) ? _.cloneDeep(profileRunningState.reduceOnlySwitches) : []
                        _.remove(newProfileReduceOnlySwitches, (reduceOnlySwtich) => reduceOnlySwtich.symbol === data.symbol && reduceOnlySwtich.account === data.account && reduceOnlySwtich.side === data.side && reduceOnlySwtich.type === 'REDUCE_ONLY_SWITCH_ON')
                        dispatch(updateProfileRunningState(profileId, { reduceOnlySwitches: newProfileReduceOnlySwitches }))
                    }
                    // dispatch(addNotificationItem({
                    //     id: uuidv4(),
                    //     timestamp: data.timestamp,
                    //     profileId,
                    //     user: data.user,
                    //     hostname: data.hostname,
                    //     type: 'REDUCE_ONLY_SWITCH_OFF',
                    //     message: `<b>${data.account}</b> switched off Reduce-Only for <b>${data.symbol}</b> in <b>${data.side}</b> side.`
                    // }))
                    break

                case 'REDUCE_ONLY_SWITCH_ON':
                    profileId = `${data.profile}_${data.hostname}`
                    profileRunningState = getState().profile.runningState[profileId]
                    if (profileRunningState) {
                        let newProfileReduceOnlySwitches = _.isArray(profileRunningState.reduceOnlySwitches) ? _.cloneDeep(profileRunningState.reduceOnlySwitches) : []
                        if (!_.find(newProfileReduceOnlySwitches, { symbol: data.symbol, account: data.account, side: data.side })) {
                            newProfileReduceOnlySwitches.push(data)
                        }
                        dispatch(updateProfileRunningState(profileId, { reduceOnlySwitches: newProfileReduceOnlySwitches }))
                    }
                    // dispatch(addNotificationItem({
                    //     id: uuidv4(),
                    //     timestamp: data.timestamp,
                    //     profileId,
                    //     user: data.user,
                    //     hostname: data.hostname,
                    //     type: 'REDUCE_ONLY_SWITCH_ON',
                    //     message: `<b>${data.account}</b> switched on Reduce-Only for <b>${data.symbol}</b> in <b>${data.side}</b> side.`
                    // }))
                    break

                case 'QTY_CAPPED':
                    profileId = `${data.profile}_${data.hostname}`
                    playSound(getState().setting.soundEffect.events.PROFILE_QTY_CAPPED)
                    // bufferNotificationItem({
                    //     id: uuidv4(),
                    //     timestamp: data.timestamp,
                    //     profileId: profileId,
                    //     user: data.user,
                    //     hostname: data.hostname,
                    //     type: 'QTY_CAPPED',
                    //     message: `<b>${data.account}</b> is capped by quantity @ <b>${data.symbol}</b> in <b>${data.side}</b> side.`,
                    //     originalMessage: data
                    // })
                    break

                case 'CRASH':
                    profileId = `${data.profile}_${data.hostname}`
                    playSound(getState().setting.soundEffect.events.PROFILE_CRASH)
                    dispatch(updateProfileItem(profileId, { crashed: true, started: false, isStarting: false }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: data.timestamp,
                        profileId: profileId,
                        user: data.user,
                        hostname: data.hostname,
                        type: 'CRASH',
                        message: `Profile is crashed due to <i>${data.reason || 'unknown reason'}</i>.`,
                        originalMessage: data
                    }))
                    break

                case 'PARA_LOAD_FAIL':
                    profileId = `${data.data.profile}_${data.data.hostname}`
                    playSound(getState().setting.soundEffect.events.PROFILE_PARA_LOAD_FAIL)
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: moment().toISOString(),
                        profileId: profileId,
                        user: data.data.user,
                        hostname: data.data.hostname,
                        type: 'PARA_LOAD_FAIL',
                        message: `Profile was failed to load parameters due to <i>${data.data.reason || 'unknown reason'}</i>.`
                    }))
                    break

                case 'START_FAIL':
                    profileId = `${data.profile}_${data.hostname}`
                    playSound(getState().setting.soundEffect.events.PROFILE_START_FAIL)
                    dispatch(updateProfileItem(profileId, { started: false, isStarting: false }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: data.timestamp,
                        profileId: profileId,
                        user: data.user,
                        hostname: data.hostname,
                        type: 'START_FAIL',
                        message: `Profile was failed to start due to <i>${data.reason || 'unknown reason'}</i>.`
                    }))
                    break

                case 'PARA_UPDATED':
                    profileId = `${data.profile}_${data.hostname}`
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: data.timestamp,
                        profileId: profileId,
                        user: data.user,
                        hostname: data.hostname,
                        type: 'PARA_UPDATED',
                        message: `Profile successfully reloaded parameters.`
                    }))
                    break

                case 'fill_details':
                    profileId = `${data.profile}_${data.hostname}`
                    bufferTransactionItem({
                        id: uuidv4(),
                        orderId: data.info.id,
                        profileId: profileId,
                        timestamp: data.info.timestamp,
                        symbolName: data.info.symbol,
                        avgFillPrice: data.info.avg_fill_price,
                        quantity: (data.info.side.includes('BUY') ? 1 : -1) * data.info.last_qty,
                        accountName: data.info.account,
                        tag: data.info.tag,
                        accumulatedCOGCancelCount: data.info.accu_cog_cancel_cnt
                    })
                    break

                case 'timers_event':
                    if (_.isArray(data.info)) {
                        data.info.forEach((event) => {
                            if (['ADDED', 'MODIFIED'].includes(event.type)) {
                                dispatch(fetchTimerById(event.id))
                            } else if (['EXPIRED', 'DELETED'].includes(event.type)) {
                                dispatch(removeTimerItem(event.id))
                            }
                            // else if (event.type === 'snapshot_refresh') {
                            //     dispatch(fetchTimers())
                            // }
                        })
                    }
                    break

                case 'transfer_event':
                    if (_.isArray(data.data)) {
                        const { items: accountItems } = getState().account
                        data.data.forEach(fundTransfer => {
                            const { account_name: originAccountName, to_account_name: destinationAccountName } = fundTransfer
                            const originAccountItem = accountItems[originAccountName]
                            const destinationAccountItem = accountItems[destinationAccountName]
                            const originExchangeAccountTypes = _.has(originAccountItem, 'exchange_name') && _.has(TOKEN_TRANSFER_EXCHANGE_CONFIGS, `${originAccountItem.exchange_name}.getAccountTypesCanTransferToken`)
                                ? TOKEN_TRANSFER_EXCHANGE_CONFIGS[originAccountItem.exchange_name].getAccountTypesCanTransferToken({ tokenToTransfer: fundTransfer.currency, accountName: originAccountName, accountItems })
                                : []
                            const destinationExchangeAccountTypes = _.has(destinationAccountItem, 'exchange_name') && _.has(TOKEN_TRANSFER_EXCHANGE_CONFIGS, `${destinationAccountItem.exchange_name}.getAccountTypesCanTransferToken`)
                                ? TOKEN_TRANSFER_EXCHANGE_CONFIGS[destinationAccountItem.exchange_name].getAccountTypesCanTransferToken({ tokenToTransfer: fundTransfer.currency, accountName: destinationAccountName, accountItems })
                                : []
                            const originAccountType = _.find(originExchangeAccountTypes, { value: fundTransfer.from })
                            const destinationAccountType = _.find(destinationExchangeAccountTypes, { value: fundTransfer.to })
                            dispatch(addNotificationItem({
                                id: uuidv4(),
                                timestamp: moment().toISOString(),
                                type: 'FUND_TRANSFER',
                                user: fundTransfer.user,
                                message: `<b>${originAccountName}</b> - <b>${originAccountType ? originAccountType.name : fundTransfer.from}${fundTransfer.instrument_id ? ` - ${fundTransfer.instrument_id}` : ''}</b> 
                                    transferred <b>${fundTransfer.amount}</b> ${fundTransfer.currency} 
                                    to <b>${destinationAccountName ? `${destinationAccountName} - ` : ''}${destinationAccountType ? destinationAccountType.name : fundTransfer.to}${fundTransfer.to_instrument_id ? ` - ${fundTransfer.to_instrument_id}` : ''}</b>`
                            }))
                        })
                    }
                    break

                case 'borrow_event':
                    if (_.isArray(data.data)) {
                        data.data.forEach(borrowParam => {
                            const { account_name, pair, currency, amount, user } = borrowParam
                            dispatch(addNotificationItem({
                                id: uuidv4(),
                                timestamp: moment().toISOString(),
                                type: 'BORROW_COIN',
                                user: user,
                                message: `<b>${account_name} - ${(pair || '').toUpperCase()}</b> Borrowed ${amount} <b>${(currency || '').toUpperCase()}</b>`
                            }))
                            if (_.has(getState(), 'auth.username') && getState().auth.username !== user) {
                                marginAccountBalanceScheduler.fetchData()
                            }
                        })
                    }
                    break 

                case 'repay_event':
                    if (_.isArray(data.data)) {
                        data.data.forEach(borrowParam => {
                            const { account_name, pair, currency, amount, user } = borrowParam
                            dispatch(addNotificationItem({
                                id: uuidv4(),
                                timestamp: moment().toISOString(),
                                type: 'REPAY_COIN',
                                user: user,
                                message: `<b>${account_name} - ${(pair || '').toUpperCase()}</b> Repayed ${amount} <b>${(currency || '').toUpperCase()}</b>`
                            }))
                            if (_.has(getState(), 'auth.username') && getState().auth.username !== user) {
                                marginAccountBalanceScheduler.fetchData()
                            }
                        })
                    }
                    break 

                case 'prod_info_initial_snapshot':
                    if (_.isArray(data.info)) {
                        profileId = `${data.profile}_${data.hostname}`
                        bufferRunningState(profileId, {
                            smartPosAccounts: data.info,
                            smartPosAccountExceptions: []
                        })
                    }
                    break

                case 'SMART_POS_ACCT_UPDATE':
                    profileId = `${data.profile}_${data.hostname}`
                    profileSmartPosAccounts = _.unionBy([data],
                        (_.has(getState().profile.runningState, `${profileId}.smartPosAccounts`) ? (getState().profile.runningState[profileId].smartPosAccounts || []) : []), 
                        profileSmartPosAccount => `${profileSmartPosAccount.symbol}--${profileSmartPosAccount.side}`)
                    profileSmartPosAccountExceptions = _.has(getState().profile.runningState, `${profileId}.smartPosAccountExceptions`) 
                        ? ( getState().profile.runningState[profileId].smartPosAccountExceptions.filter(exception => exception.symbol !== data.symbol || exception.side !== data.side) 
                            || [] ) 
                        : []
                    dispatch(updateProfileRunningState(profileId, {
                        smartPosAccounts: profileSmartPosAccounts,
                        smartPosAccountExceptions: profileSmartPosAccountExceptions
                    }))
                    break

                case 'SMART_POS_ACCT_EXCEPTION':
                    profileId = `${data.profile}_${data.hostname}`
                    profileSmartPosAccounts = _.has(getState().profile.runningState, `${profileId}.smartPosAccounts`)
                        ? ( getState().profile.runningState[profileId].smartPosAccounts.filter(smartPosAccount => smartPosAccount.symbol !== data.symbol || smartPosAccount.side !== data.side)
                            || [] ) 
                        : []
                    profileSmartPosAccountExceptions = _.unionBy([data], 
                        (_.has(getState().profile.runningState, `${profileId}.smartPosAccountExceptions`) ? (getState().profile.runningState[profileId].smartPosAccountExceptions || []) : []),
                        exception => `${exception.symbol}--${exception.side}`)
                    dispatch(updateProfileRunningState(profileId, {
                        smartPosAccounts: profileSmartPosAccounts,
                        smartPosAccountExceptions: profileSmartPosAccountExceptions
                    }))
                    // dispatch(addNotificationItem({
                    //     id: uuidv4(),
                    //     timestamp: moment().toISOString(),
                    //     type: 'SMART_POS_ACCT_EXCEPTION',
                    //     user: data.user,
                    //     hostname: data.hostname,
                    //     profileId: profileId,
                    //     message: `<b>${data.side}</b> <i>${data.symbol}</i> Exception - ${data.reason || 'Unkown Reason'}`,
                    //     originalMessage: data
                    // }))
                    break

                case 'ORDER_BOOK':
                    bufferOrderBook(data.info)
                    break

                case 'atweb_order_update':
                    bufferManualOrders({
                        [data.info.client_order_id]: Object.assign({}, data.info, { profile: data.profile })
                    })
                    break

                case 'atweb_orders_snapshot':   
                    orderIds = (data.info || []).map(manualOrder => manualOrder.client_order_id) 
                    orderIdsToRemove = _.filter(getState().trading.manualOrders, manualOrder => {
                        return manualOrder.hostname === data.hostname
                            && manualOrder.profile === data.profile
                            && [PROFILE_ORDER_STATUS.CONFIRM, PROFILE_ORDER_STATUS.PARTIAL_FILL].includes(manualOrder.status)
                            && !orderIds.includes(manualOrder.client_order_id)
                    }).map(manualOrder => manualOrder.client_order_id)

                    if (!_.isEmpty(orderIdsToRemove)) {
                        dispatch(removeManulOrders(orderIdsToRemove))
                    }

                    if (!_.isEmpty(data.info) && _.isArray(data.info)) {
                        data.info.forEach(manualOrder => manualOrder.profile = data.profile)
                        dispatch(updateManualOrders(_.keyBy(data.info, 'client_order_id')))
                    }
                    break

                case 'para_update_event':
                    if (_.isArray(data.data)) {
                        data.data.forEach(profileUpdateItem => {
                            const { hostname, profile } = profileUpdateItem
                            const profileId = `${profile}_${hostname}`
                            dispatch(fetchProfileParams({ profileId, updateStore: true }))
                        })
                    }
                    break

                case 'web_global_margin_ratio_threshold_updated':
                    break
    
                default:
                    return null
            }
        } catch (error) {
            console.error(`${hostname} Websocket Receive Message Error: `, error, e.data)
        }
    }
}