import React, { Component } from 'react'
import PropTypes from 'prop-types'

import uudiv4 from 'uuid/v4'
import moment from 'moment'
import _ from 'lodash'

import { AiOutlineSwap } from 'react-icons/ai'

import Popup from '../common/popup/Popup'
import TokenTransferEditor, { TRANSFER_STATES } from './TokenTransferEditor'
import TokenLendingEditor from './TokenLendingEditor'

import { toNumberWithSmartPrecision } from '../../util/util'
import { getCrossMarginAccountTransferAdivce } from '../../util/tradingUtil'

export default class CrossMarginAccountBalanceItem extends Component {

    constructor (props) {
        super(props)
        this.state = {
            defaultSingleTransferConfig: null
        }

        this._mounted = null
    }

    TokenTransferPopup () {
        const { defaultSingleTransferConfig } = this.state
        const { crossMarginAccountBalance } = this.props
        const { token, transferItem, validOriginTransferAccountNames } = getCrossMarginAccountTransferAdivce(crossMarginAccountBalance)
        
        return !_.isNil(token) && !_.isNil(transferItem) ? (
            <Popup
                className='cross-margin-account-balance-item--token-transfer-popup'
                trigger={<button className='cross-margin-account-balance-item--token-transfer-popup--trigger-button'><AiOutlineSwap /></button>}
                on={'click'}
                align={'horizontal'}
                onOpen={() => {
                    this.setState({
                        defaultSingleTransferConfig: {
                            id: uudiv4(),
                            token,
                            transferItem,
                            amount: 0,
                            amountPercentInput: '',
                            state: TRANSFER_STATES.NULL,
                            message: null
                        }
                    })
                }}>
                <div className='cross-margin-account-balance-item--token-transfer-popup--main' onClick={(e) => { e.stopPropagation() }}>
                    <TokenTransferEditor 
                        shouldAutoFocusAmountInput
                        defaultSingleTransferConfig={defaultSingleTransferConfig} 
                        validSingleTransferOriginAccountNames={validOriginTransferAccountNames}/>
                </div>
            </Popup>
        ) : null
    }

    Row ({ name, value, displayValue, hasDirection }) {
        return (
            <div className='cross-margin-account-balance-item--row'>
                <div className='cross-margin-account-balance-item--row--name'>{name}</div>
                <div className={'cross-margin-account-balance-item--row--value' + (value > 0 && hasDirection? ' positive' : value < 0 && hasDirection? ' negative' : '')}>
                    {!_.isNil(displayValue) ? displayValue : value}
                </div>
            </div>
        )
    }

    InlineData ({ name, value, shouldHighlight }) {
        return (
            <div className='cross-margin-account-balance-item--inline-data'>
                <span className='cross-margin-account-balance-item--inline-data--name'>{name}</span>
                <div className={'cross-margin-account-balance-item--inline-data--value' + (shouldHighlight ? ' highlight' : '')}>{value}</div>
            </div>
        )
    }

    render () {
        const { crossMarginAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName, shouldShowDetail, tokenTransferEnabled, tokenLendingEnabled, onChangeComponentHeight } = this.props
        return (
            <div className='cross-margin-account-balance-item'>
                <div className='cross-margin-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='cross-margin-account-balance-item--header--info-bar'>
                        <div className='cross-margin-account-balance-item--header--info-bar--account-type cross-margin'>{'CROSS MARGIN'}</div>
                        <div className='cross-margin-account-balance-item--header--info-bar--timestamp'>{moment(crossMarginAccountBalance.update_time).format('HH:mm:ss')}</div>
                        {tokenTransferEnabled && 
                        <div className='cross-margin-account-balance-item--header--token-transfer-popup' onClick={(e) => { 
                            e.stopPropagation() 
                            const virtualClickEvent = new Event('click')
                            Object.defineProperty(virtualClickEvent, 'target', { writable: false, value: document.body })
                            window.dispatchEvent(virtualClickEvent)
                        }}>
                            {this.TokenTransferPopup()}
                        </div>}
                    </div>}
                    <div className='cross-margin-account-balance-item--coin'>
                        {crossMarginAccountBalance.coin}
                        {shouldShowAccountName ? ` @ ${crossMarginAccountBalance.acct_name}` : ''}
                    </div>
                    {!shouldShowAccountTypeTag && <div className='cross-margin-account-balance-item--timestamp'>{moment(crossMarginAccountBalance.update_time).format('HH:mm:ss')}</div>}
                </div>
                {shouldShowDetail && 
                <div className='cross-margin-account-balance-item--rows'>
                    {this.Row({
                        name: 'Borrowed',
                        value: Number(crossMarginAccountBalance.borrowed),
                        displayValue: toNumberWithSmartPrecision({ number: crossMarginAccountBalance.borrowed, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {this.Row({
                        name: 'Max Borrowable',
                        value: Number(crossMarginAccountBalance.max_borrowable),
                        displayValue: toNumberWithSmartPrecision({ number: crossMarginAccountBalance.max_borrowable, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {this.Row({
                        name: 'Frozen',
                        value: Number(crossMarginAccountBalance.frozen),
                        displayValue: toNumberWithSmartPrecision({ number: crossMarginAccountBalance.frozen, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                    })}
                    {this.Row({
                        name: 'Lending Fee',
                        value: Number(crossMarginAccountBalance.lending_fee),
                        displayValue: toNumberWithSmartPrecision({ number: crossMarginAccountBalance.lending_fee, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                    })}
                    {this.Row({
                        name: 'Balance Sum',
                        value: Number(crossMarginAccountBalance.acct_balance_sum),
                        displayValue: toNumberWithSmartPrecision({ number: crossMarginAccountBalance.acct_balance_sum, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                    })}
                    {this.Row({
                        name: 'Debt Sum',
                        value: Number(crossMarginAccountBalance.acct_debt_sum),
                        displayValue: toNumberWithSmartPrecision({ number: crossMarginAccountBalance.acct_debt_sum, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                </div>}
                <div className='cross-margin-account-balance-item--summary'>
                    {this.InlineData({
                        name: 'Acct Balance',
                        value: toNumberWithSmartPrecision({ number: crossMarginAccountBalance.balance, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(crossMarginAccountBalance.balance)) >= 1000 ? 0 : 2 })
                    })}
                    {this.InlineData({
                        name: 'Available',
                        value: toNumberWithSmartPrecision({ number: crossMarginAccountBalance.available, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(crossMarginAccountBalance.available)) >= 1000 ? 0 : 2  })
                    })}
                    {this.InlineData({
                        name: 'Risk Rate',
                        value: toNumberWithSmartPrecision({ number: crossMarginAccountBalance.risk_rate, shouldReturnLocalString: true, defaultPrecision: 4, shouldApplyFloorValue: true }),
                        shouldHighlight: true
                    })}
                </div>
                {tokenLendingEnabled && shouldShowDetail && <div className='cross-margin-account-balance-item--lending' onClick={(e) => { e.stopPropagation() }}>
                    <div className='cross-margin-account-balance-item--lending--title'>{'LENDING'}</div>
                    <TokenLendingEditor 
                        defaultAccountName={crossMarginAccountBalance.acct_name} 
                        defaultCoin={_.upperCase(crossMarginAccountBalance.coin)} 
                        onChangeComponentHeight={() => { onChangeComponentHeight() }}/>
                </div>}
            </div>
        )
    } 
}

CrossMarginAccountBalanceItem.propTypes = {
    crossMarginAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool,
    shouldShowDetail: PropTypes.bool,
    tokenTransferEnabled: PropTypes.bool,
    tokenLendingEnabled: PropTypes.bool,
    onChangeComponentHeight: PropTypes.func
}

CrossMarginAccountBalanceItem.defaultProps = {
    tokenLendingEnabled: true,
    onChangeComponentHeight: () => {}
}