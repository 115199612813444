import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import uudiv4 from 'uuid/v4'

import Popup from '../common/popup/Popup'
import { toAbbreviateNumber, toNumberWithSmartPrecision } from '../../util/util'
import { getPositionLiquidationRatio, getNotional, getPositionItemTransferAdvice, getRiskRatioThresholdByPositionItem } from '../../util/tradingUtil'
import { getPricePrecisionBySymbolItem, getSymbolAttributeByName } from '../../util/symbolUtil'
import { AiOutlineSwap } from 'react-icons/ai'
import TokenTransferEditor, { TRANSFER_STATES } from '../account/TokenTransferEditor'

class PositionItem extends Component {

    constructor (props) {
        super(props)
        this.pricingItemNode = null
        this.state = {
            defaultSingleTransferConfig: null
        }
    }

    renderDataRow ({ name, value, hasDirection, postfix, shouldShowAsLocalString }) {
        return (
            <div className='position-item--data-row'>
                <div className='position-item--data-row--name'>{name}</div>
                <div className={'position-item--data-row--value'}>
                    <span className={hasDirection && Number(value) > 0 ? ' positive' : hasDirection && Number(value) < 0 ? ' negative' : null}>
                        {shouldShowAsLocalString ? toNumberWithSmartPrecision({ number: value, shouldReturnLocalString: true }) : value}
                    </span>
                    <span>{postfix}</span>
                </div>
            </div>
        )
    }

    renderDirectionTableRow (name, value1, value2) {
        const value1String = (value1 || '').toString()
        const value2String = (value2 || '').toString()
        return (
            <tr>
                <th>{name}</th>
                <td>{value1String.includes('.') && value1String.split('.')[1].length > 6 ? Number(value1).toFixed(6) : value1}</td>
                <td>{value2String.includes('.') && value2String.split('.')[1].length > 6 ? Number(value2).toFixed(6) : value2}</td>
            </tr>
        )
    }

    TokenTransferPopup () {
        const { defaultSingleTransferConfig } = this.state
        const { positionItem } = this.props
        const positionItemTransferAdvice = getPositionItemTransferAdvice(positionItem)
        const { token, transferItem, validOriginTransferAccountNames } = positionItemTransferAdvice

        return !_.isNil(token) && !_.isNil(transferItem) ? (
            <Popup 
                className='position-item--token-transfer-popup'
                trigger={<button className='position-item--token-transfer-popup--trigger-button'><AiOutlineSwap /></button>}
                on={'click'}
                align={'horizontal'}
                onOpen={() => {
                    this.setState({
                        defaultSingleTransferConfig: {
                            id: uudiv4(),
                            token,
                            transferItem,
                            amount: 0,
                            amountPercentInput: '',
                            state: TRANSFER_STATES.NULL,
                            message: null
                        }
                    })
                }}>
                <div className='position-item--token-transfer-popup--main' onClick={(e) => { e.stopPropagation() }}>
                    <TokenTransferEditor 
                        shouldAutoFocusAmountInput
                        defaultSingleTransferConfig={defaultSingleTransferConfig} 
                        validSingleTransferOriginAccountNames={validOriginTransferAccountNames} />
                </div>
            </Popup>
        ) : null
    }

    render () {
        const { positionItem, pricings, symbolItems, riskRatioThresholds, hideDetails, shouldShowTag, tokenTransferEnabled,
            isAccountNameClickable, onClickAccountName } = this.props
        const { product_name: symbol, long_position: longPosition, short_position: shortPosition, long_available_position: longAvailablePosition, short_available_position: shortAvailablePosition,
            long_avg_cost: longAvgCost, short_avg_cost: shortAvgCost, long_settlement_price: longSettlementPrice, short_settlement_price: shortSettlementPrice,
            margin } = positionItem
        const { quote } = getSymbolAttributeByName(symbol)
        const pricingItem = pricings[symbol]
        const ratio = getPositionLiquidationRatio(positionItem, pricingItem)
        const riskRatioThreshold = getRiskRatioThresholdByPositionItem(riskRatioThresholds, positionItem)
        const ratioReference = _.has(riskRatioThreshold, 'reference') ? riskRatioThreshold.reference : null
        const shouldHighlightRatio = _.isNumber(ratio) && _.isNumber(ratioReference) && Math.abs(ratio) < Number(ratioReference)
        const symbolItem = symbolItems[symbol]
        const pricePrecision = getPricePrecisionBySymbolItem(symbolItem)

        let lastPrice = null
        if (pricingItem) {
            const { bid, ask, last } = pricingItem
            lastPrice = last ? last 
                : bid && ask ? (bid + ask) / 2
                : null
        }
        const notional = getNotional({
            symbolItem: symbolItem,
            quantity: Number(longPosition) - Number(shortPosition),
            price: Number(lastPrice),
            BTCUSDIndexLastPrice: _.has(pricings, `btc_usd_OKEX_INDEX.last`) ? pricings['btc_usd_OKEX_INDEX'].last : null
        })
        const renderPricingSection = (name, value, highlight, highlightColor) => {
            return (
                <div className={`position-item--pricing-section ${name.toLowerCase()}`}>
                    <span className='position-item--pricing-section--label'>{name}</span>
                    <div className={'position-item--pricing-section--value' + (highlight ? ' highlight' : '')}
                        style={{ color: highlight && highlightColor ? highlightColor : null }}>{value}</div>
                </div>
            )
        }
        return (
            <div className='position-item' ref={(node) => { this.pricingItemNode = node }}>
                <div className='position-item--header clearfix'>
                    <div className='position-item--header--info'>
                        {shouldShowTag && <div className='position-item--header--tag'>{'Position'}</div>}
                        <div className='position-item--header--timestamp'>{moment(positionItem.timestamp).format('HH:mm:ss')}</div>
                        {tokenTransferEnabled && 
                        <div className='position-item--header--token-transfer-popup' onClick={(e) => { 
                            e.stopPropagation()
                            const virtualClickEvent = new Event('click')
                            Object.defineProperty(virtualClickEvent, 'target', { writable: false, value: document.body })
                            window.dispatchEvent(virtualClickEvent)
                        }}>
                            {this.TokenTransferPopup()}
                        </div>}
                    </div>
                    <div className='position-item--header--title'>
                        {`${positionItem.product_name} @ `}
                        <span className={'position-item--header--title--account-name' + (isAccountNameClickable ? ' clickable' : '')}
                            onClick={(e) => { 
                            if (isAccountNameClickable) {
                                e.stopPropagation()
                                onClickAccountName(positionItem.account_name)
                            }
                        }}>{`${positionItem.account_name}`}</span>
                    </div>
                </div>
                {!hideDetails && <table className='position-item--direction-table'>
                    <thead>
                        <tr>
                            <th>{'Direction'}</th><th>{'Long'}</th><th>{'Short'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderDirectionTableRow('Position', Number(longPosition).toLocaleString(undefined, { useGrouping: true }), Number(shortPosition).toLocaleString(undefined, { useGrouping: true }))}
                        {(!_.isEmpty(longAvailablePosition) || !_.isEmpty(shortAvailablePosition)) && 
                        this.renderDirectionTableRow('Avail Pos', Number(longAvailablePosition).toLocaleString(undefined, { useGrouping: true }), Number(shortAvailablePosition).toLocaleString(undefined, { useGrouping: true }))}
                        {(!_.isEmpty(longAvgCost) || !_.isEmpty(shortAvgCost)) && 
                        this.renderDirectionTableRow('Avg Cost',  Number(longAvgCost).toLocaleString(undefined, { useGrouping: true }),  Number(shortAvgCost).toLocaleString(undefined, { useGrouping: true }))}
                        {(!_.isEmpty(longSettlementPrice) || !_.isEmpty(shortSettlementPrice)) && 
                        this.renderDirectionTableRow('Set Price',  Number(longSettlementPrice).toLocaleString(undefined, { useGrouping: true }),  Number(shortSettlementPrice).toLocaleString(undefined, { useGrouping: true }))}
                    </tbody>
                </table>}
                {!hideDetails && <div className='position-item--data'>
                    {!_.isEmpty(positionItem.leverage) && this.renderDataRow({
                        name: 'Leverage',
                        value: positionItem.leverage
                    })}
                    {this.renderDataRow({
                        name: 'Margin',
                        value: margin,
                        shouldShowAsLocalString: true
                    })}
                    {this.renderDataRow({
                        name: 'Realized P/L',
                        value: positionItem.realized_pnl,
                        hasDirection: true,
                        shouldShowAsLocalString: true
                    })}
                    {this.renderDataRow({
                        name: 'Unrealized P/L',
                        value: positionItem.unrealized_pnl,
                        hasDirection: true,
                        shouldShowAsLocalString: true
                    })}
                    {this.renderDataRow({
                        name: 'Notional',
                        value: notional,
                        hasDirection: true,
                        postfix: ` ${quote}`,
                        shouldShowAsLocalString: true
                    })}
                </div>}
                <div className='position-item--pricing-wrapper'>
                    {renderPricingSection('Liq.', positionItem.liquidation_price ? (Math.abs(ratio) > 3 ? toAbbreviateNumber(positionItem.liquidation_price, 2) : toNumberWithSmartPrecision({ number: positionItem.liquidation_price, defaultPrecision: pricePrecision, shouldReturnLocalString: true }) )
                        : 'N/A')}
                    {renderPricingSection('Last Pr.', lastPrice ? toNumberWithSmartPrecision({ number: lastPrice, defaultPrecision: pricePrecision, shouldReturnLocalString: true }) : 'N/A')}
                    {renderPricingSection('R Ref.', !_.isNil(ratioReference) ? `${(ratioReference * 100).toFixed(0)}%` : 'N/A', _.isNil(ratioReference) && !_.isNil(ratio), '#f78991')}
                    <Popup className='position-item--pricing-wrapper--ratio-description'
                        trigger={renderPricingSection('Ratio', ratio ? (ratio > 10 ? `${toAbbreviateNumber(ratio * 100, 1)} %` : `${(ratio * 100).toFixed(2)}%`) : 'N/A', true, shouldHighlightRatio ? '#f78991' : null)}>
                        <span>{'Ratio = (Liquidation - Last Price) / Last Price * 100%'}</span>
                    </Popup>
                </div>
            </div>
        )
    }
}

PositionItem.propTypes = {
    pricings: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    riskRatioThresholds: PropTypes.object.isRequired,

    positionItem: PropTypes.object.isRequired,
    hideDetails: PropTypes.bool,
    shouldShowTag: PropTypes.bool,
    tokenTransferEnabled: PropTypes.bool,
    isAccountNameClickable: PropTypes.bool,
    onClickAccountName: PropTypes.func
}

PositionItem.defaultProps = {
    hideAccountName: false,
    hideSymbolName: false,
    hideDetails: false,
    shouldShowTag: false,
    tokenTransferEnabled: false,
    isAccountNameClickable: false,
    onClickAccountName: () => {}
}

function mapStateToProps (state) {
    return {
        pricings: state.symbol.pricings,
        symbolItems: state.symbol.items,
        riskRatioThresholds: state.trading.riskRatioThresholds
    }
}

export default connect(mapStateToProps)(PositionItem)


