import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { RiExchangeBoxLine, RiExchangeDollarLine } from 'react-icons/ri'
import { IoMdSwap, IoIosTimer } from 'react-icons/io'
import { FaBitcoin, FaChartLine, FaCameraRetro } from 'react-icons/fa'
import { FiPower, FiSettings, FiPercent, FiAlertTriangle, FiGrid } from 'react-icons/fi'

import MenuTools from './MenuTools'
import TradingContainer from '../trading/TradingContainer'
import RiskContainer from '../trading/RiskContainer'
import PricingContainer from '../symbol/PricingContainer'
import DefiLendingInfoContainer from '../symbol/DefiLendingInfoContainer'
import SymbolFundingRateContainer from '../symbol/SymbolFundingRateContainer'
import TokenTransferContainer from '../account/TokenTransferContainer'
import SettingModal from '../setting/SettingModal'
import TimerContainer from '../timer/TimerContainer'
import ProfileStateSnapshotContainer from '../profile/ProfileStateSnapshotContainer'
import { authLogout } from '../auth/authAction'
import AlertManagementPopup from '../support/AlertManagementPopup'

const CurrencyPairChartContainer = React.lazy(() => import('../market/CurrencyPairChartContainer'))

class Menu extends Component {
    constructor (props) {
        super(props)
        this.contents = {
            pricing: {
                name: 'Pricings',
                iconComponent: <FaBitcoin />,
                component: <PricingContainer />
            },
            fundingRate: {
                name: 'Funding Rates',
                iconComponent: <FiPercent />,
                component: <SymbolFundingRateContainer />
            },
            defiLending: {
                name: 'Defi Lending Info',
                iconComponent: <RiExchangeBoxLine />,
                component: <DefiLendingInfoContainer />
            },
            market: {
                name: 'Market',
                iconComponent: <FaChartLine />,
                component: <Suspense fallback={
                    <div style={{ 
                        textAlign: 'center',
                        margin: '7px auto',
                        width: '100%', 
                        fontWeight: 'bold' 
                    }
                }>{'Loading...'}</div>}><CurrencyPairChartContainer /></Suspense>
            },
            trading: {
                name: 'Transactions & Notifications',
                iconComponent: <IoMdSwap />,
                component: <TradingContainer />
            },
            risk: {
                name: 'Risk',
                iconComponent: <FiAlertTriangle />,
                component: <RiskContainer />
            },
            // account: {
            //     name: 'Accounts',
            //     iconComponent: <FaCubes />,
            //     component: <AccountContainer />
            // },
            timer: {
                name: 'Timers',
                iconComponent: <IoIosTimer />,
                component: <TimerContainer />
            },
            tokenTransfer: {
                name: 'Token Transfer',
                iconComponent: <RiExchangeDollarLine />,
                component: <TokenTransferContainer />
            }, 
            profileStateSnapshots: {
                name: 'Profile State Snapshots',
                iconComponent: <FaCameraRetro />,
                component: <ProfileStateSnapshotContainer />
            },
            tools: {
                name: 'Tools',
                iconComponent: <FiGrid />,
                component: null,
            },
            alertCall: {
                name: 'Mute Alert Call',
                iconComponent: null,
                component: null
            },
            requestSupport: {
                name: 'Request Support',
                iconComponent: null,
                component: null
            },
            alertManagement: {
                name: 'Alert Management',
                iconComponent: null,
                component: null
            },
            setting: {
                name: 'Settings',
                iconComponent: <FiSettings />,
                component: null
            }
        }
        this.ALERT_CALL_MUTE_TIMEOUT_SECONDS = 300
        this.state = {
            activeContent: Object.keys(this.contents)[0],
            shouldShowSetting: false
        }
    }

    // handleClickMuteAlertCallButton (duration=300000) {
    //     const { dispatch } = this.props
    //     const { alertCall } = this.state
    //     dispatch(muteAlertCall(duration))
    //     .then(() => { 
    //         const newAlertCall = Object.assign({}, alertCall, {
    //             key: moment().valueOf(),
    //             muted: true,
    //             duration: duration
    //         })
    //         this.setState({
    //             alertCall: newAlertCall
    //         })
    //     })
    // }

    render () {
        const { contents } = this
        const { activeContent, shouldShowSetting } = this.state
        const { dispatch } = this.props

        return (
            <div className='menu'>
                <div className='menu--side-bar'>
                    {_.map(contents, (content, key) => {
                        return (
                            <button key={key} className={`menu--side-bar--button ${key}` + (activeContent === key ? ' active' : '')} 
                                title={content.name}
                                onClick={() => {
                                    if (content.component || key === 'tools') {
                                        this.setState({ activeContent: (activeContent === key) ? null : key })
                                    } else if (key === 'setting') {
                                        this.setState({ shouldShowSetting: !shouldShowSetting })
                                    }
                                }}>
                                {content.iconComponent}
                                {key === 'alertManagement' && <AlertManagementPopup />}
                            </button>
                        )
                    })}
                    <button className='menu--side-bar--button logout' title={'Log Out'}
                        onClick={() => { dispatch(authLogout()) }}><FiPower /></button>
                </div>
                <div className={'menu--contents' + (activeContent ? ` ${activeContent}` : '') + (!Object.keys(contents).includes(activeContent) ? ' hidden' : '')}>
                    {_.has(contents, `${activeContent}.component`) && !_.isNil(contents[activeContent].component) && <div className='menu--content'>{contents[activeContent].component}</div>}
                    <div className={'menu--content' + (activeContent !== 'tools' ? ' hidden' : '')}><MenuTools /></div>
                </div>
                {shouldShowSetting && <SettingModal onClickClose={() => { this.setState({ shouldShowSetting: false }) }} />}
            </div>
        )
    }
}

Menu.propTypes = {
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps () {
    return {}
}

export default connect(mapStateToProps)(Menu)