import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { store } from './store'

import NoMatchPage from './pages/NoMatchPage'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import PrivateRoute from './components/auth/PrivateRoute'
import * as serviceWorker from './serviceWorker'

import './styles/global.scss'

import './components/common/searchSelect/searchSelect.scss'
import './components/common/toggle/toggle.scss'
import './components/common/saveButton/saveButton.scss'
import './components/common/contentIndex/contentIndex.scss'
import './components/common/checkbox/checkbox.scss'
import './components/common/webSocket/webSocketClient.scss'

import './styles/homePage.scss'
import './styles/loginPage.scss'

import './components/layout/layout.scss'
import './components/auth/auth.scss'
import './components/profile/profile.scss'
import './components/profile/legEditor.scss'
import './components/profile/profileActionPopup.scss'
import './components/profile/profileBulkUploadEditor.scss'
import './components/trading/trading.scss'
import './components/market/market.scss'
import './components/market/currencyPair.scss'
import './components/account/account.scss'
import './components/account/accountBalanceMonitor.scss'
import './components/account/tokenTransfer.scss'
import './components/symbol/symbol.scss'
import './components/symbol/option.scss'
import './components/symbol/fundingTag.scss'
import './components/timer/timer.scss'
import './components/setting/setting.scss'
import './components/webSocket/webSocket.scss'
import './components/support/support.scss'

import 'react-virtualized/styles.css'

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <PrivateRoute exact path='/' 
                    component={HomePage} />
                <Route path='/login' component={LoginPage} />
                <Route path='/*' component={NoMatchPage} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root'),
    () => {
        document.getElementById('page-loading-animation').remove()
    }
)



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
