import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { toNumberWithSmartPrecision } from '../../util/util'


class OptionDeltaContainer extends Component {
    constructor (props) {
        super(props)
        this.state = {

        }
    }

    Section ({ title, className, component }) {
        return (
            <div className={'option-delta-container--section' + (className ? ` ${className}` : '')}>
                <div className='option-delta-container--section--title'>{title}</div>
                <div className='option-delta-container--section--body'>
                    {component}
                </div>
            </div>
        )
    }

    SymbolDeltaTable () {
        const { optionSymbolDelta } = this.props
        return (
            <table className='option-delta-container--symbol-delta-table'>
                <thead>
                    <tr>
                        <th>{'Symbol'}</th>
                        <th>{'Timestamp'}</th>
                        <th className='align-right'>{'Current Price (USD)'}</th>
                        <th className='align-right'>{'Spot Price'}</th>
                        <th className='align-right'>{'Implied Vol.'}</th>
                        <th className='align-right'>{'Net Position'}</th>
                        <th className='align-right'>{'Delta'}</th>
                        <th className='align-right'>{'Hedge'}</th>
                    </tr>
                </thead>
                <tbody>
                    {_.map(optionSymbolDelta, (symbolDelta, symbolName) => (
                        <tr key={symbolName}>
                            <td>{symbolName}</td>
                            <td>{moment(symbolDelta.ts).format('HH:mm:ss')}</td>
                            <td className='align-right'>{toNumberWithSmartPrecision({ number: symbolDelta.price, shouldReturnLocalString: true })}</td>
                            <td className='align-right'>{toNumberWithSmartPrecision({ number: symbolDelta.S, shouldReturnLocalString: true })}</td>
                            <td className='align-right'>{(symbolDelta.vol*100).toFixed(2) + '%'}</td>
                            <td className='align-right'>{symbolDelta.net_position}</td>
                            <td className='align-right'>{symbolDelta.delta}</td>
                            <td className='align-right'>{symbolDelta.hedge}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    }

    CoinDelta () {
        const { optionCoinDelta } = this.props

        const TableRow = ({ coinDeltaArray=[], absoluteBumps=[], direction=1 }) => {
            const filteredCoinDelta = coinDeltaArray.filter(item => direction > 0 ? (item.bump_pct >= 0) : (item.bump_pct <= 0))
            const seivedCoinDelta = _.sortBy(filteredCoinDelta, item => Math.abs(item.bump_pct))
            absoluteBumps.forEach((absoluteBump, index) => {
                if (Math.abs(seivedCoinDelta[index].bump_pct) !== absoluteBump) {
                    seivedCoinDelta.splice(index, 0, null)
                }
            })
            return (
                <tr>
                    <th className={`option-delta-container--coin-delta--item--table--row-header ${direction > 0 ? 'positive' : 'negative'}`}>{direction > 0 ? '+' : '-'}</th>
                    {seivedCoinDelta.map((coinDeltaItem, index) => {
                        return (
                            <td key={index}>
                                {coinDeltaItem ? (
                                    <div className='option-delta-container--coin-delta--item--table--data'>
                                        <span className='option-delta-container--coin-delta--item--table--data--price'>{coinDeltaItem.spot_bump}</span>
                                        <span className='option-delta-container--coin-delta--item--table--data--hedge'>{coinDeltaItem.hedge}</span>
                                    </div>
                                ) : null}
                            </td>
                        )
                    })}
                </tr>
            )
        }

        return (
            <div className='option-delta-container--coin-delta'>
                {_.map(optionCoinDelta, (coinDeltaArray, coin) => {
                    const absoluteBumps = _.uniq(coinDeltaArray.map(item => Math.abs(item.bump_pct))).sort()
                    return (
                        <div className='option-delta-container--coin-delta--item' key={coin}>
                            <div className='option-delta-container--coin-delta--item--header clearfix'>
                                <div className='option-delta-container--coin-delta--item--header--coin'>{coin}</div>
                                <div className='option-delta-container--coin-delta--item--header--net-pos'>
                                    <span>{'Net Position: '}</span>
                                    {!_.isEmpty(coinDeltaArray) ? coinDeltaArray[0].net_positio : 'N/A'}
                                </div>
                                {!_.isEmpty(coinDeltaArray) && 
                                <div className='option-delta-container--coin-delta--item--header--timestamp'>{moment(coinDeltaArray[0].ts).format('HH:mm:ss')}</div>}
                            </div>
                            <div className='option-delta-container--coin-delta--item--main'>
                                <table className='option-delta-container--coin-delta--item--table'>
                                    <thead>
                                        <tr>
                                            <th>{'Bump PCT.'}</th>
                                            {absoluteBumps.map((bumpPercent, index) => (
                                                <th key={index}>{bumpPercent === 0 ? 0 : `${(bumpPercent * 100).toFixed(1)}%`}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {TableRow({
                                            absoluteBumps,
                                            coinDeltaArray,
                                            direction: 1
                                        })}
                                        {TableRow({
                                            absoluteBumps,
                                            coinDeltaArray,
                                            direction: -1
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } 

    render () {
        return (
            <div className='option-delta-container'>
                {this.Section({
                    title: 'SYMBOL',
                    className: 'symbol-delta',
                    component: this.SymbolDeltaTable()
                })}
                {this.Section({
                    title: 'COIN (Spot Price, Hedge)',
                    className: 'coin-delta',
                    component: this.CoinDelta()
                })}
            </div>
        )
    }
}

OptionDeltaContainer.propTypes = {
    optionSymbolDelta: PropTypes.object.isRequired,
    optionCoinDelta: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    const { optionSymbolDelta, optionCoinDelta } = state.symbol
    return {
        optionSymbolDelta,
        optionCoinDelta
    }
}

export default connect(mapStateToProps)(OptionDeltaContainer)