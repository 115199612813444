import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { FaSearch, FaTrashAlt } from 'react-icons/fa'

import AccountPopup from '../account/AccountPopup' 
import SymbolPopup from '../symbol/NewSymbolPopup'

import { updateProfileSearchString } from '../profile/profileAction'
import { clearTransactionItems } from './tradingAction'
import { SYMBOLS_WITH_MUTLIPLIER_IN_BTC } from '../../configs/tradingConfig'
import { toFixedNumber } from '../../util/util'
import { getNotional } from '../../util/tradingUtil'
import { getSymbolAttributeByName } from '../../util/symbolUtil'

class TransactionContainer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            showSearch: false,
            searchString: ''
        }
    }

    renderItem (transaction) {
        const { dispatch, profileItems, accountItems, symbolItems, pricings } = this.props
        const profileItem = profileItems[transaction.profileId]
        const accountItem = accountItems[transaction.accountName]
        const symbolItem = symbolItems[transaction.symbolName]
        const { quote } = getSymbolAttributeByName(transaction.symbolName)
        const notional = getNotional({
            symbolItem: symbolItem,
            quantity: transaction.quantity,
            price: transaction.avgFillPrice,
            BTCUSDIndexLastPrice: SYMBOLS_WITH_MUTLIPLIER_IN_BTC.includes(transaction.symbolName) && _.has(pricings, `btc_usd_OKEX_INDEX.last`) 
                ? pricings['btc_usd_OKEX_INDEX'].last
                : null 
        })
        const renderTransactionData = (name, value, hasDirectionColor) => {
            return (
                <div className='transaction-container--item--data'>
                    <div className='transaction-container--item--data-name'>{name}</div>
                    <div className={'transaction-container--item--data-value' + (hasDirectionColor ? (value > 0 ? ' positive' : ' negative') : '')}>{value}</div>
                </div>
            )
        }

        return (
            <div className='transaction-container--item' key={transaction.id}>
                <div className={`transaction-container--item--tag ${transaction.tag.includes('HEDGE') ? 'HEDGE' : 'QUOTE'}`}>{transaction.tag}</div>
                <div className='transaction-container--item--symbol-name'>
                    {symbolItem 
                    ? <SymbolPopup 
                        symbolName={symbolItem.symbol_name}
                        profileId={transaction.profileId} />
                    : transaction.symbolName}
                </div>
                {accountItem && <div className='transaction-container--item--account'>
                    <AccountPopup className='transaction-container--account-popup' accountItem={accountItem} />
                    <span style={{ fontWeight: 'noraml' }}>{' @ '}</span>
                    <span>{profileItem ? profileItem.hostname : 'unknown host'}</span>
                </div>}
                <div className='transaction-container--item--profile-name'
                    onClick={() => { 
                        if (profileItem) {
                            dispatch(updateProfileSearchString(profileItem.name))
                        }
                    }}>{profileItem ? profileItem.name : `Profile ID: ${transaction.profileId}`}</div>
                <div className='transaction-container--item--main'>
                    {renderTransactionData('Datetime', moment(transaction.timestamp).format('YYYY-M-D HH:mm:ss'))}
                    {Number(transaction.accumulatedCOGCancelCount) >= 0 && renderTransactionData('COG Cancel Count', transaction.accumulatedCOGCancelCount)}
                    {renderTransactionData('Avg Fill Price', transaction.avgFillPrice)}
                    {renderTransactionData('Quantity', toFixedNumber(transaction.quantity, 5), true)}
                    {renderTransactionData('Notional', notional ? (toFixedNumber(Math.abs(notional), 5) + ` ${quote}`) : 'N/A')}
                </div>
            </div>
        )
    }

    render () {
        const { showSearch, searchString } = this.state
        const { dispatch, profileItems, accountItems, transactions } = this.props
        const trimedSearchString = searchString.toLowerCase().trim()

        return (
            <div className='transaction-container'>
                <div className='transaction-container--title'>{'Transactions'}</div>
                <button className={'transaction-container--search-button' + (showSearch ? ' active' : '')} onClick={() => { 
                    this.setState({ 
                        searchString: '',
                        showSearch: !showSearch 
                    }) 
                }}><FaSearch /></button>
                <button className='transaction-container--clear-button' onClick={() => { dispatch(clearTransactionItems()) }}><FaTrashAlt /></button>
                {showSearch && <input className='transaction-container--search-input' value={searchString}
                    placeholder={'Search Symbol, Account, Profile'}
                    autoFocus
                    spellCheck={false}
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }} />}
                <div className='transaction-container--list'>
                    {transactions.slice(0, 100)
                        .filter(transaction => {
                            const profileItem = profileItems[transaction.profileId]
                            const accountItem = accountItems[transaction.accountName]
                            return `${transaction.symbolName} ${accountItem ? accountItem.account_name: ''} ${profileItem ? profileItem.name : ''}`.toLowerCase().includes(trimedSearchString)
                        })
                        .map(transaction => this.renderItem(transaction))}
                </div>
            </div>
        )
    }
}

TransactionContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileItems: PropTypes.object.isRequired,
    transactions: PropTypes.array.isRequired,
    accountItems: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items,
        transactions: state.trading.transactions,
        accountItems: state.account.items,
        symbolItems: state.symbol.items,
        pricings: state.symbol.pricings
    }
}

export default connect(mapStateToProps)(TransactionContainer)