import fetch from 'isomorphic-fetch'
import _ from 'lodash'

import { DEFAULT_SERVER } from '../../configs/config'
import { secureFetch } from '../../util/util'

import { fetchAccounts, fetchAvailableBalance } from '../account/accountAction'
import { fetchSymbols, fetchSymbolPricings, fetchSymbolFundingRates, 
    fetchOptionImpliedVolatilities, fetchFundingRateHistorySymbols,
    fetchDefiLendingInfo, fetchOptionSymbolDelta, fetchOptionCoinDelta, fetchFundingTags} from '../symbol/symbolAction'
import { fetchExchanges } from '../exchange/exchangeAction'
import { fetchProfilesAndConnectWSS, fetchProfileGroups, fetchUserProfiles, fetchSignedSwitchedOffProfileSymbols } from '../profile/profileAction'
import { fetchSingleLimits, fetchExposures, fetchInitialBalance, fetchExpsoureVariables, fetchRiskRatioThresholds, fetchOptionGreek } from '../trading/tradingAction'
import { fetchTimers } from '../timer/timerAction'
import { webSocketDisconnectAll } from '../webSocket/webSocketAction'

export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS'
export const AUTH_VERIFY_SUCCESS = 'AUTH_VERIFY_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'

let fetchDataInterval

function onLoginSuccess () {
    return (dispatch, getState) => {

        const fetchData = () => {
            dispatch(fetchSymbols())
            dispatch(fetchSymbolPricings())
            dispatch(fetchSymbolFundingRates())
            dispatch(fetchOptionImpliedVolatilities())
            dispatch(fetchOptionSymbolDelta())
            dispatch(fetchOptionCoinDelta())
            dispatch(fetchDefiLendingInfo())
            dispatch(fetchExposures())
            dispatch(fetchRiskRatioThresholds())
            dispatch(fetchUserProfiles())
            dispatch(fetchSignedSwitchedOffProfileSymbols())
            dispatch(fetchAvailableBalance())
            dispatch(fetchOptionGreek())
        }

        dispatch(fetchProfileGroups())
        dispatch(fetchExchanges())
        dispatch(fetchAccounts())
        dispatch(fetchSingleLimits())
        dispatch(fetchInitialBalance())
        dispatch(fetchFundingRateHistorySymbols())
        dispatch(fetchFundingTags())
        dispatch(fetchProfilesAndConnectWSS())
        dispatch(fetchTimers())
        dispatch(fetchExpsoureVariables())
        fetchData()

        if (fetchDataInterval) {
            window.clearInterval(fetchDataInterval)
        }

        fetchDataInterval = setInterval(() => {
            if (getState().auth.isLoggedIn) {
                fetchData()
            } else {
                window.clearInterval(fetchDataInterval)
            }
        }, 40000)
    }
}

export function authLogin (username, password, code) {
    return (dispatch) => new Promise((resolve, reject) => {
        fetch(`${DEFAULT_SERVER.apiBaseUrl}/user/login`, {
            method: 'POST',
            body: JSON.stringify({
                user: username,
                passwd: password,
                code: code
            })
        })
        .then(response => {
            response.text()
            .then(result => {
                if (response.status === 200) {
                    if (_.isString(result)) {
                        dispatch({
                            type: AUTH_LOGIN_SUCCESS,
                            accessToken: result,
                            username: username
                        })
                        dispatch(onLoginSuccess())
                        resolve(true)
                    } else {
                        reject('Unexpected return')
                    }
                } else {
                    reject(result)
                }
            })
        })
        .catch(error => {
            console.error('authLogin error: ', error)
            dispatch(authLogout())
            reject(error)
        })
    })
}

export function authVerifyUser () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/user/verify`, {
            method: 'POST'
        }))
        .then((response) => {
            if (response.status === 200) {
                dispatch({ type: AUTH_VERIFY_SUCCESS })
                dispatch(onLoginSuccess())
                resolve('success')
            } else {
                console.error(`authVerifyUser error - Status Code: ${response.status}`)
                dispatch(authLogout())
                reject(`Unexpected response stats: ${response.status}`)
            }
        })
        .catch(error => {
            console.error('authVerifyUser error: ', error)
            dispatch(authLogout())
            reject(error)
        })
    })
}

export function authLogout () {
    return (dispatch) => {
        webSocketDisconnectAll()
        dispatch({
            type: AUTH_LOGOUT
        })
    }
}

