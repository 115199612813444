import React, { Component } from 'react'
import { connect } from 'react-redux' 
import PropTypes from 'prop-types'
import _ from 'lodash'
import uuidv4 from 'uuid/v4'
import moment from 'moment'

import NewWindow from 'react-new-window'
import AccountBalanceMonitor from '../components/account/AccountBalanceMonitor'
import AccountBalancer from '../components/account/AccountBalancer'
import MarginLendingTable from '../components/account/MarginLendingTable'
import TokenTransferEditor, { TransferItem, TransferAccount,
     ACCOUNT_TYPES, TRANSFER_STATES, OPERATION_MODES, TRANSFER_MODES, EXCHANGES } from '../components/account/TokenTransferEditor'
import { updateAccountBalances } from '../components/account/accountAction'
import  { fetchExpsoureVariables, removeIssue } from '../components/trading/tradingAction'

import { ELF_API_BASE_URL, ELF_WEBSOCKET_URL } from '../configs/config'
import { secureFetch, toNumberWithSmartPrecision } from '../util/util'
import { fetchFundingTags } from '../components/symbol/symbolAction'

const MESSAGE_TYPES = {
    UPDATE_ACCOUNT_BALANCER_VARIABLES_SUCCESS: 'UPDATE_ACCOUNT_BALANCER_VARIABLES_SUCCESS',
    UPDATE_MARGIN_LENDING_MONITOR_VARIABLES_SUCCESS: 'UPDATE_MARGIN_LENDING_MONITOR_VARIABLES_SUCCESS',
    UPDATE_FUNDING_TAGS_SUCCESS: 'UPDATE_FUNDING_TAGS_SUCCESS',
    UPDATE_EXPOSURE_MONITOR_VARIABLES_SUCCESS: 'UPDATE_EXPOSURE_MONITOR_VARIABLES_SUCCESS'
}

const NotificaitonItem = ({ id, timestamp, tag, message, user }) => {
    return {
        id: id || uuidv4(),
        timestamp: timestamp || moment().toISOString(),
        tag,
        message,
        user
    }
}
class AccountBalanceWindow extends Component {
    constructor (props) {
        super(props)
        this.tabs = {
            MONITOR: {
                key: 'MONITOR',
                name: 'Risk Monitor'
            },
            BALANCER: {
                key: 'BALANCER',
                name: 'Balancer'
            },
            MARGIN_LENDING: {
                key: 'MARGIN_LENDING',
                name: 'Margin Lending'
            }
        }
        this.state = {
            variables: {},
            accountBalances: [],
            accountBalancesLastUpdateTimestamp: null,
            autoTransferEnabled: false,
            notifications: [],
            transferFundAdvices: [],
            wsReadyState: WebSocket.CLOSED,
            searchString: '',
            hideGroupWithOnlySpot: true,
            willConnectWSInSeconds: null,
            tokenTransferEditorDefaultConfig: {},
            activeTabKey: this.tabs.MONITOR.key,
            canSync: true,

            accountBalancerUpdateStateId: null,
            marginLendingTableUpdateStateId: null
        }
        this._mounted = false
        this.reactNewWindowNode = null
        this.ws = null
        this.checkWSStateInterval = null
        this.startWSConnectionCountdownInterval = null
    }

    static getDerivedStateFromProps (props, state) {
        const { fundTransferNotifications } = props
        const mergedNotifications = fundTransferNotifications.map(notification => NotificaitonItem({
            id: notification.id,
            timestamp: notification.timestamp,
            tag: (notification.type || '').replace(/_/g, ' '),
            user: notification.user,
            message: notification.message
        })).concat(state.notifications)
        const seivedNotifications = _.sortBy(_.uniqBy(mergedNotifications, 'id'), 'timestamp').reverse()
        return {
            notifications: _.take(seivedNotifications, 100)
        }
    }

    componentDidMount () {
        this._mounted = true
        this.connectWebSocket()
    }

    componentDidUpdate (prevProps) {
        const { fundTransferNotifications: prevFundTransferNotifications, windowId: prevWindowId } = prevProps
        const { fundTransferNotifications, auth, windowId } = this.props
        const prevHeadFundTransferNotification = _.head(prevFundTransferNotifications)
        const headFundTransferNotification = _.head(fundTransferNotifications)
        if (headFundTransferNotification 
            && (!prevHeadFundTransferNotification || headFundTransferNotification.id !== prevHeadFundTransferNotification.id) 
            && headFundTransferNotification.user === auth.username) {
            this.syncData()        
        }
        if (!_.isEqual(prevWindowId, windowId) && _.has(this.reactNewWindowNode, 'window')) {
            this.reactNewWindowNode.window.focus()
        }
    }

    componentWillUnmount () {
        this._mounted = false
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.close()
        }
        if (this.checkWSStateInterval) {
            window.clearInterval(this.checkWSStateInterval)
        }
        if (this.startWSConnectionCountdownInterval) {
            window.clearInterval(this.startWSConnectionCountdownInterval)
        }
    }

    isWSConnected () {
        return !_.isNil(this.ws) && this.ws.readyState === WebSocket.OPEN
    }

    startWSConnectionCountdown (willConnectWSInSeconds=8) {
        if (this._mounted && !this.isWSConnected() && _.isNull(this.startWSConnectionCountdownInterval)) {
            this.setState({ willConnectWSInSeconds: willConnectWSInSeconds })
            this.startWSConnectionCountdownInterval = setInterval(() => {
                if (this._mounted && !this.isWSConnected()) {
                    if (this.state.willConnectWSInSeconds > 0) {
                        this.setState((prevState) => {
                            return { willConnectWSInSeconds: prevState.willConnectWSInSeconds - 1 }
                        })
                    } else {
                        window.clearInterval(this.startWSConnectionCountdownInterval)
                        this.startWSConnectionCountdownInterval = null
                        this.connectWebSocket()
                    }
                } else {
                    window.clearInterval(this.startWSConnectionCountdownInterval)
                    this.startWSConnectionCountdownInterval = null
                }
            }, 1000)
        }
    }

    registerCheckWSStateInterval () {
        if (this.checkWSStateInterval) {
            window.clearInterval(this.checkWSStateInterval)
        }
        this.checkWSStateInterval = setInterval(() => {
            if (this._mounted && !this.isWSConnected()) {
                if (this.state.wsReadyState !== WebSocket.CLOSED) {
                    this.setState({ wsReadyState: WebSocket.CLOSED })
                }
                this.startWSConnectionCountdown()
            }
        }, 30000)
    }

    connectWebSocket () {
        const { dispatch } = this.props
        this.registerCheckWSStateInterval()
        dispatch(secureFetch(`${ELF_API_BASE_URL}/ws-ticket`, {
            method: 'GET' 
        }))
        .then(response => response.json())
        .then(body => {
            if (body && body.ticket) {
                this.setState({ 
                    wsReadyState: WebSocket.CONNECTING,
                    willConnectWSInSeconds: null
                })
                if (this.isWSConnected()) { this.ws.close() }
                this.ws = new WebSocket(`${ELF_WEBSOCKET_URL}?ticket=${body.ticket}`)
                this.ws.onopen = () => { 
                    this.setState({ wsReadyState: WebSocket.OPEN })
                    console.log('Account Balance Monitor WebSocket connected') 
                }
                this.ws.onmessage = (message) => { this.handleReceiveMessage(message) }
                this.ws.onclose = () => {
                    if (this._mounted) {
                        this.setState({ 
                            wsReadyState: WebSocket.CLOSED,
                            notifications: _.take([NotificaitonItem({
                                tag: 'WS CLOSED',
                                message: 'WebSocket connection was closed.'
                            })].concat(this.state.notifications), 200)
                        })
                        console.log('Account Balance Monitor WebSocket disconnected')
                        this.startWSConnectionCountdown()
                    }
                }
            } else {
                this.setState({ wsReadyState: WebSocket.CLOSED })
            }
        })
        .catch(error => {
            if (this._mounted) {
                this.addNotification({  
                    tag: 'API ERROR',
                    message: `Fetch WS Ticket error. ${error.toString()}`
                })
                console.log('Fetch ws ticket error', error)
                this.startWSConnectionCountdown()
            }
        })
    }

    addNotification ({ tag, message }) {
        const { notifications } = this.state
        this.setState({
            notifications: _.take([NotificaitonItem({ tag, message })].concat(notifications), 100)
        })
    }

    handleReceiveAccountBalancesStateMessage (payload, isUpdateVariablesSuccess) {
        const { variables, accountBalances, autoTransferEnabled } = payload
        const { notifications } = this.state
        const { dispatch, onReceiveAccountBalances } = this.props
        const spotAccountBalances = [],
        marginAccountBalances = [],
        futureAccountBalances = [],
        swapAccountBalances = []
        accountBalances.forEach(accountBalance => {
            const { market } = accountBalance
            if (market === 'SPOT') {
                spotAccountBalances.push(accountBalance.detail)
            } else if (market === 'MARGIN') {
                marginAccountBalances.push(accountBalance.detail)
            } else if (market === 'FUTURE') {
                futureAccountBalances.push(accountBalance.detail)
            } else if (market === 'SWAP') {
                swapAccountBalances.push(accountBalance.detail)
            }
        })
        this.setState({
            variables,
            accountBalances,
            accountBalancesLastUpdateTimestamp: moment().valueOf(),
            autoTransferEnabled,
            accountBalancerUpdateStateId: uuidv4(),
            notifications: isUpdateVariablesSuccess ? _.take([NotificaitonItem({
                tag: 'UPDATE SUCCESS',
                message: `Monitor variables succesfully updated.`
            })].concat(notifications), 100) : notifications
        })
        onReceiveAccountBalances({
            accountBalancesWithCrossedThreshold: _.filter(accountBalances, accountBalance => 
                _.isNumber(accountBalance.marginRatio) && accountBalance.marginRatio < accountBalance.riskThreshold)
        })
        dispatch(updateAccountBalances({ 
            spotAccountBalances: _.keyBy(spotAccountBalances, spotBalance => `${spotBalance.acct_name}--${spotBalance.coin}`),
            marginAccountBalances: _.uniqBy(marginAccountBalances, marginAccountBalance => `${marginAccountBalance.acct_name}--${marginAccountBalance.pair}`), 
            futureAccountBalances, 
            swapAccountBalances ,
            shouldMerge: true
        }))
    }

    handleReceiveIssueSolvedMessage (payload) {
        const { dispatch, issues } = this.props
        const { issue, solvedBy } = payload
        if (_.find(issues, { id: issue.id })) {
            dispatch(removeIssue(issue, solvedBy))
        }
    }

    handleReceiveMessage (message) {
        const { dispatch } = this.props
        try {
            const messageData = JSON.parse(message.data)
            console.log('Receive Message: ', messageData)
            const { type, payload } = messageData

            switch (type) {
                case 'ACCOUNT_BALANCES_STATE':
                    this.handleReceiveAccountBalancesStateMessage(payload, false)
                    break

                case 'UPDATE_ACCOUNT_BALANCE_MONITOR_VARIABLES_SUCCESS':
                    this.handleReceiveAccountBalancesStateMessage(payload, true)
                    break

                case 'ACCOUNT_BALANCES_SHOULD_TRANSFER_FUND_TO':
                    this.setState({ 
                        transferFundAdvices: payload || [],
                    })
                    break

                case 'ISSUE_SOLVED':
                    this.handleReceiveIssueSolvedMessage(payload)
                    break

                case MESSAGE_TYPES.UPDATE_ACCOUNT_BALANCER_VARIABLES_SUCCESS:
                    this.setState({ accountBalancerUpdateStateId: uuidv4() })
                    this.addNotification({ tag: 'UPDATE SUCCESS', message: 'Balancer variables successfully updated.'})
                    break

                case MESSAGE_TYPES.UPDATE_MARGIN_LENDING_MONITOR_VARIABLES_SUCCESS:
                    this.setState({ marginLendingTableUpdateStateId: uuidv4() })
                    this.addNotification({ tag: 'UPDATE SUCCESS', message: 'Margin Lending variables successfully updated.' })
                    break

                case MESSAGE_TYPES.UPDATE_FUNDING_TAGS_SUCCESS:
                    dispatch(fetchFundingTags())
                    break

                case MESSAGE_TYPES.UPDATE_EXPOSURE_MONITOR_VARIABLES_SUCCESS:
                    dispatch(fetchExpsoureVariables())
                    break
                
                default:
                    return null
            }
        } catch (e) {
            console.log(e)
        }
    }

    handleClickTransferFundAdvice (transferFundAdvice) {
        const { accountItems } = this.props
        if (transferFundAdvice) {
            const { accountBalanceShouldTransferFundFrom, accountBalanceShouldTransferFundTo, transferAmount } = transferFundAdvice
            const originAccountName = accountBalanceShouldTransferFundFrom.accountName
            const destinationAccountName = accountBalanceShouldTransferFundTo.accountName
            const originAccountItem = accountItems[originAccountName], destinationAccountItem = accountItems[destinationAccountName]
            const token = accountBalanceShouldTransferFundTo.currency.toUpperCase()
            const shouldOriginAccountDefinePairName = _.has(originAccountItem, 'exchange_name') 
                && EXCHANGES[originAccountItem.exchange_name].shouldDefinePairName({ 
                    tokenToTransfer: token, 
                    accountTypeKey: accountBalanceShouldTransferFundFrom.market
                })
            const shouldDestinationDefinePairName = _.has(destinationAccountItem, 'exchange_name')
                && EXCHANGES[destinationAccountItem.exchange_name].shouldDefinePairName({
                    tokenToTransfer: token,
                    accountTypeKey: accountBalanceShouldTransferFundTo.market
                })
            this.setState({
                tokenTransferEditorDefaultConfig: {
                    id: uuidv4(),
                    token: token,
                    transferItem: TransferItem({
                        originTransferAccount: TransferAccount({
                            accountName: originAccountName,
                            accountType: ACCOUNT_TYPES[accountBalanceShouldTransferFundFrom.market] || null,
                            pairName: shouldOriginAccountDefinePairName && accountBalanceShouldTransferFundFrom.pair 
                                ? accountBalanceShouldTransferFundFrom.pair.toLowerCase() 
                                : null
                        }),
                        destinationTransferAccount: TransferAccount({
                            accountName: destinationAccountName,
                            accountType: ACCOUNT_TYPES[accountBalanceShouldTransferFundTo.market] || null,
                            pairName: shouldDestinationDefinePairName && accountBalanceShouldTransferFundTo.pair 
                                ? accountBalanceShouldTransferFundTo.pair.toLowerCase() 
                                : null
                        }),
                        amount: toNumberWithSmartPrecision({
                            number: transferAmount,
                            shouldApplyFloorValue: true
                        }),
                        amountPercentInput: '',
                        state: TRANSFER_STATES.NULL,
                        message: null
                    })
                }
            })
        }  
    }

    syncData () {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
            this.ws.send(JSON.stringify({
                type: 'SYNC'
            }))
        }
    }

    Header () {
        const { wsReadyState, accountBalancesLastUpdateTimestamp, willConnectWSInSeconds, activeTabKey, canSync } = this.state
        return (
            <div className='account-balance-window--header clearfix'>
                <div className='account-balance-window--header--tabs'>
                    {_.map(this.tabs, tab => {
                        const { key, name } = tab
                        return (
                            <button key={key}
                                className={'account-balance-window--header--tab' + (activeTabKey === key ? ' active' : '')} 
                                onClick={() => { this.setState({ activeTabKey: key }) }}>
                                {name}
                            </button>
                        )
                    })}
                </div>
                <div className='account-balance-window--header--right-section'>
                    <div className='account-balance-window--header--connection-state'>
                        {willConnectWSInSeconds > 0 && <span className='account-balance-window--header--reconnect-time'>{`Try to connect in ${willConnectWSInSeconds}s`}</span>}
                        <span className={`account-balance-window--header--connection-state--bulb ready-state-${wsReadyState}`} />
                        {wsReadyState === WebSocket.CONNECTING ? 'CONNECTING' 
                        : wsReadyState === WebSocket.OPEN ? 'CONNECTED'
                        : 'CLOSED'}
                    </div>
                    {accountBalancesLastUpdateTimestamp && activeTabKey === this.tabs.MONITOR.key && 
                    <div className='account-balance-window--header--last-update-timestamp'>{`Last Update: ${moment(accountBalancesLastUpdateTimestamp).format('HH:mm:ss')}`}</div>}
                    {activeTabKey === this.tabs.MONITOR.key && <button className='account-balance-window--header--sync-button' 
                        disabled={!canSync}
                        onClick={() => {
                            if (canSync) {
                                this.setState({ canSync: false })
                                this.syncData()
                                if (this._mounted) {
                                    setTimeout(() => { this.setState({ canSync: true }) }, 3000)
                                }
                            }
                        }}>{'Sync'}</button>}
                </div>
            </div>
        )
    }

    Notification (notification) {
        const { id, timestamp, tag, message, user } = notification
        return (
            <div className='account-balance-window--notification' key={id}>
                <div className='account-balance-window--notification--header clearfix'>
                    <div className='account-balance-window--notification--header--left'>
                        {user && <div className='account-balance-window--notification--user'>{user}</div>}
                        <div className={`account-balance-window--notification--tag ${tag ? tag.replace(/\s+/g, '_') : ''}`}>{tag}</div>
                    </div>
                    <div className='account-balance-window--notification--timestamp'>{moment(timestamp).format('HH:mm:ss')}</div>
                </div>
                <div className='account-balance-window--notification--message' dangerouslySetInnerHTML={{__html: message}} />
            </div>
        )
    }

    render () {
        const { accountBalances, variables, notifications, transferFundAdvices, autoTransferEnabled, 
            tokenTransferEditorDefaultConfig, accountBalancesLastUpdateTimestamp, activeTabKey,
            accountBalancerUpdateStateId, marginLendingTableUpdateStateId } = this.state
        const { hidden, onWindowClose } = this.props
        return !hidden ? (
            <NewWindow 
                ref={(node) => { this.reactNewWindowNode = node }}
                name={'Account Balance Manager'}
                title={'Account Balance Manager - Antelope Technology'}
                features={{ width: 1500, height: 800 }}
                onUnload={() => { 
                    this.reactNewWindowNode = null
                    onWindowClose() 
                }}>
                <div className='account-balance-window'>
                    <div className='account-balance-window--body'>
                        <div className='account-balance-window--left-section'>
                            {this.Header()}
                            <div className='account-balance-window--left-section--main'>
                                {activeTabKey === this.tabs.MONITOR.key ? <AccountBalanceMonitor 
                                    globalVariables={variables}
                                    accountBalances={accountBalances} 
                                    transferFundAdvices={transferFundAdvices} 
                                    autoTransferEnabled={autoTransferEnabled}
                                    accountBalancesLastUpdateTimestamp={accountBalancesLastUpdateTimestamp}
                                    onUpdateVariablesFail={(errorString) => {
                                        this.addNotification({ 
                                            tag: 'UPDATE VARIABLES FAIL',
                                            message: errorString
                                        })
                                    }} 
                                    onClickTransferFundAdvice={(transferFundAdvice) => { this.handleClickTransferFundAdvice(transferFundAdvice) }} />
                                : activeTabKey === this.tabs.BALANCER.key ? <AccountBalancer 
                                    updateStateId={accountBalancerUpdateStateId} 
                                    onTransferFail={(groupId, error) => {
                                        this.addNotification({
                                            tag: 'TRANSFER FAIL',
                                            message: `Group ID: ${groupId}. ${error.toString()}`  
                                        })
                                    }} />
                                : activeTabKey === this.tabs.MARGIN_LENDING.key ? <MarginLendingTable 
                                    updateStateId={marginLendingTableUpdateStateId}
                                    onBorrowRepaySuccess={({ type, message }) => {
                                        this.addNotification({
                                            tag: `${type} SUCCESS`,
                                            message
                                        })
                                    }} 
                                    onBorrowRepayFail={({ params, type, message }) => {
                                        const { account_name, pair, currency, amount } = params || {}
                                        this.addNotification({
                                            tag: `${type} FAIL`,
                                            message: `Account: ${account_name}, Pair: ${(pair || '').toUpperCase()}, Currency: ${(currency || '').toUpperCase()}, Amount: ${amount}: ${message}`
                                        })
                                    }} />
                                : null}
                            </div>
                        </div>
                        <div className='account-balance-window--right-section'>
                            <div className='account-balance-window--fund-transfer-editor'>
                            <div className='account-balance-window--fund-transfer-editor--title'>{'Fund Transfer'}</div>
                                <div className='account-balance-window--fund-transfer-editor--main'>
                                    <TokenTransferEditor 
                                        defaultSingleTransferConfig={tokenTransferEditorDefaultConfig} 
                                        defaultOperationMode={OPERATION_MODES.SINGLE}
                                        transferMode={TRANSFER_MODES.TRANSFER} />
                                        {/* onTransferSuccess={() => { this.syncData() }} /> */}
                                </div>
                            </div>
                            <div className='account-balance-window--messages'>
                                <div className='account-balance-window--messages--title'>{'Notifications'}</div>
                                <div className='account-balance-window--messages--main'>
                                    {notifications.map((notification, index) => {
                                        return this.Notification(notification, index)
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </NewWindow>
        ) : null
    }
}

AccountBalanceWindow.propTypes = {
    dispatch: PropTypes.func.isRequired,
    windowId: PropTypes.string,
    hidden: PropTypes.bool,

    fundTransferNotifications: PropTypes.array,
    issues: PropTypes.array.isRequired,
    accountItems: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,

    onWindowClose: PropTypes.func.isRequired,
    onReceiveAccountBalances: PropTypes.func
}

AccountBalanceWindow.defaultProps = {
    hidden: false,
    onWindowClose: () => {},
    onReceiveAccountBalances: () => {}
}

function mapStateToProps (state) {
    return {
        fundTransferNotifications: _.filter(state.trading.notifications, notification => ['FUND_TRANSFER', 'BORROW_COIN', 'REPAY_COIN'].includes(notification.type)),
        issues: state.trading.issues,
        accountItems: state.account.items,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(AccountBalanceWindow)