import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'

import { FiEdit, FiSliders, FiX } from 'react-icons/fi'
import Checkbox from '../common/checkbox/Checkbox'
import SaveButton from '../common/saveButton/SaveButton'
import Toggle from '../common/toggle/Toggle'

import AutoTransferPopup from '../trading/AutoTransferPopup'
import Popup from '../common/popup/Popup'
import SpotAccountBalanceItem from './SpotAccountBalanceItem'
import MarginAccountBalanceItem from './MarginAccountBalanceItem'
import SwapAccountBalanceItem from './SwapAccountBalanceItem'
import FutureAccountBalanceItem from './FutureAccountBalanceItem'

import { MARKETS } from '../../configs/accountConfig'
import { updateAccountBalanceMonitorVariables } from './accountAction'
import { isMetSearchStringCriteria, toNumberWithSmartPrecision } from '../../util/util'

class AccountBalanceMonitor extends Component {
    constructor (props) {
        super(props)
        this.defaultBulkUpload = {
            enabled: false,
            accountSearchString: '',
            marketSearchString: '',
            currencySearchString: '',
            pairNameSearchString: '',
            riskThreshold: '',
            riskLimit: ''
        }
        this.state = {
            searchString: '',
            shouldHideGroupWithOnlySpot: true,
            shouldShowGlobalVariablesPopup: false,
            globalVariablesPopupMessage: null,
            globalVariables: props.globalVariables,
            accountBalanceVariablesEditing: {},
            shouldClearAccountBalanceVariablesEditingOnReceiveNewProps: false,
            accountBalancesLastUpdateTimestamp: props.accountBalancesLastUpdateTimestamp,
            isSavingVariables: false,
            isSaveGlobalVariablesSuccess: false,
            isSwitchingAutoTransfer: false,
            sortBy: 'market',
            sortOrder: 'ASC',
            accountBalanceIdShouldHighlight: null,

            bulkUpload: this.defaultBulkUpload
        }
    }

    static getDerivedStateFromProps (props, state) {
        if (state.shouldClearAccountBalanceVariablesEditingOnReceiveNewProps 
            && !_.isEqual(props.accountBalancesLastUpdateTimestamp, state.accountBalancesLastUpdateTimestamp)) {
            return {
                accountBalanceVariablesEditing: {},
                shouldClearAccountBalanceVariablesEditingOnReceiveNewProps: false,
                accountBalancesLastUpdateTimestamp: props.accountBalancesLastUpdateTimestamp
            }
        } else {
            return null
        }
    }

    componentDidUpdate (prevProps) {
        if (!_.isEqual(prevProps.globalVariables, this.props.globalVariables)) {
            this.updateGlobalVariables(this.props.globalVariables)
        }
    }

    getBulkUploadAccountBalances () {
        const { bulkUpload } = this.state
        const { accountBalances } = this.props

        return _.filter(accountBalances, accountBalanceItem => {
            return accountBalanceItem.market !== 'SPOT'
                && (_.isEmpty(bulkUpload.accountSearchString) || _.some(bulkUpload.accountSearchString.toLowerCase().split(/\|{2}|\s+/), v => v.trim().length > 0 && accountBalanceItem.accountName.toLowerCase().includes(v.trim()))) 
                && (_.isEmpty(bulkUpload.marketSearchString) ||  _.some(bulkUpload.marketSearchString.toLowerCase().split(/\|{2}|\s+/), v => v.trim().length > 0 && accountBalanceItem.market.toLowerCase().includes(v.trim())))
                && (_.isEmpty(bulkUpload.currencySearchString) || _.some(bulkUpload.currencySearchString.toLowerCase().split(/\|{2}|\s+/), v => v.trim().length > 0 && accountBalanceItem.currency.toLowerCase().includes(v.trim())))
                && (_.isEmpty(bulkUpload.pairNameSearchString) || _.some(bulkUpload.pairNameSearchString.toLowerCase().split(/\|{2}|\s+/), v => v.trim().length > 0 && accountBalanceItem.pair.toLowerCase().includes(v.trim())))
        })
    }

    updateGlobalVariables (newGlobalVariables) {
        this.setState({ globalVariables: newGlobalVariables })
    }

    handleClickSaveAccountBalanceVariablesButton () {
        const { dispatch, onUpdateVariablesFail } = this.props
        const { isSavingVariables, accountBalanceVariablesEditing } = this.state
        const newAccountBalanceVariables = _.cloneDeep(accountBalanceVariablesEditing)
        _.forEach(newAccountBalanceVariables, (values, accountBalanceId) => {
            newAccountBalanceVariables[accountBalanceId] = _.omitBy(values, v => !_.isEmpty(v))
        })
        
        if (!isSavingVariables && !_.isEmpty(newAccountBalanceVariables)) {
            this.setState({ isSavingVariables: true })
            dispatch(updateAccountBalanceMonitorVariables({
                accountBalances: newAccountBalanceVariables
            })).then(response => {
                this.setState({ 
                    isSavingVariables: false,
                    shouldClearAccountBalanceVariablesEditingOnReceiveNewProps: true
                })
                if (response.status !== 200) {
                    response.json().then(body => {
                        if (body && body.error) {
                            onUpdateVariablesFail(body.error.toString())
                        }
                    })
                }
            })
        }
    }

    handleClickSaveGlobalVariablesButton () {
        const { globalVariables, isSavingVariables } = this.state
        const { dispatch, onUpdateVariablesFail } = this.props
        if (_.some(globalVariables, v => _.isNull(v) || (_.isString(v) && v.trim().length === 0))) {
            this.setState({ globalVariablesPopupMessage: 'Empty param value is not allowed' })
        } else if (!isSavingVariables && !_.isEmpty(globalVariables)) {

            this.setState({ isSavingVariables: true })
            dispatch(updateAccountBalanceMonitorVariables(globalVariables)).then(response => {
                if (response.status !== 200) {
                    this.setState({ isSavingVariables: false })
                    response.json().then(body => {
                        if (body && body.error) {
                            onUpdateVariablesFail(body.error.toString())
                        }
                    })
                } else {
                    this.setState({
                        isSavingVariables: false,
                        isSaveGlobalVariablesSuccess: true
                    })
                }
            })
        }
    }

    handleClickAutoTransferToggle (newIsAutoTransferOn) {
        const { isSwitchingAutoTransfer } = this.state
        const { dispatch } = this.props
        if (!isSwitchingAutoTransfer) {
            this.setState({ isSwitchingAutoTransfer: true })
            dispatch(updateAccountBalanceMonitorVariables({ isAutoTransferOn: newIsAutoTransferOn }))
            .then(() => {
                this.setState({ isSwitchingAutoTransfer: false })
            })
        }
    }

    handleClickTableHeader (key) {
        const { sortBy, sortOrder } = this.state
        this.setState({
            sortBy: key,
            sortOrder: sortBy === key ? (sortOrder === 'ASC' ? 'DESC' : 'ASC') : sortOrder
        })
    }

    GlobalVariablesPopup () {
        const { shouldShowGlobalVariablesPopup, shouldHideGroupWithOnlySpot, globalVariables, globalVariablesPopupMessage, 
            isSavingVariables, isSaveGlobalVariablesSuccess } = this.state
        const variableNames = {
            marginRatioCap: 'Margin Ratio Cap',
            minMarginRatioDiff: 'Min Margin Ratio Diff'
        }
        const variablesShouldShowAsPercent = ['marginRatioCap', 'minMarginRatioDiff']
        const variablesShouldIgnore = ['isAutoTransferOn']
        return (
            <div className='account-balance-monitor--global-variables-popup' onClick={(e) => { e.stopPropagation() }}>
                <button className='account-balance-monitor--global-variables-popup--trigger-button' onClick={() => { 
                    this.setState({ 
                        shouldShowGlobalVariablesPopup: !shouldShowGlobalVariablesPopup,
                        isSaveGlobalVariablesSuccess: false
                    })
                }}><FiSliders /></button>
                {shouldShowGlobalVariablesPopup && <div className='account-balance-monitor--global-variables-popup--body'>
                    <div className='account-balance-monitor--global-variables-popup--header'>
                        <div className='account-balance-monitor--global-variables-popup--hide-group'>
                            <Checkbox
                                checked={shouldHideGroupWithOnlySpot}
                                onChange={(newCheckd) => { this.setState({ shouldHideGroupWithOnlySpot: newCheckd }) }} />
                            <span>{'Hide Groups with SPT only'}</span>
                        </div>
                    </div>
                    <div className='account-balance-monitor--global-variables-popup--main'>
                        {_.map(globalVariables, (value, key) => {
                            const variableName = variableNames[key] || key
                            const shouldShowAsPercent = variablesShouldShowAsPercent.includes(key)
                            return !variablesShouldIgnore.includes(key) ? ( 
                                <div className='account-balance-monitor--global-variables-popup--param' key={key}>
                                    <div className='account-balance-monitor--global-variables-popup--param--name'>{variableName}</div>
                                    <div className='account-balance-monitor--global-variables-popup--param--value'>
                                        <input className='account-balance-monitor--global-variables-popup--param--input' 
                                            type={'number'}
                                            value={shouldShowAsPercent && _.isNumber(value) ? `${Math.round(value * 10000)/100}` : value} 
                                            onChange={(e) => {
                                                let newValue = e.target.value.trim()
                                                if (newValue.length > 0) {
                                                    newValue = Math.round(newValue * 100) / (shouldShowAsPercent ? 10000 : 100)
                                                }
                                                this.setState({ 
                                                    globalVariables: dotProp.set(globalVariables, key, newValue),
                                                    globalVariablesPopupMessage: null,
                                                    isSaveGlobalVariablesSuccess: false
                                                })
                                            }} />
                                        {shouldShowAsPercent && <span>{'%'}</span>}
                                    </div>
                                </div>
                            ) : null
                        })}
                    </div>
                    {globalVariablesPopupMessage && <div className='account-balance-monitor--global-variables-popup--message'>{globalVariablesPopupMessage}</div>}
                    <div className='account-balance-monitor--global-variables-popup--footer'>
                        <button className='account-balance-monitor--global-variables-popup--button reset' onClick={() => {
                            this.setState({ globalVariables: this.props.globalVariables })
                        }}>{'RESET'}</button>
                        <SaveButton className='account-balance-monitor--global-variables-popup--button confirm' 
                            text={'CONFIRM'} 
                            isSaving={isSavingVariables}
                            isSaved={isSaveGlobalVariablesSuccess}
                            onClick={() => { this.handleClickSaveGlobalVariablesButton() }} />
                    </div>
                </div>}
            </div>
        )
    }

    Header () {
        const { searchString, isSwitchingAutoTransfer, bulkUpload } = this.state
        const { globalVariables, autoTransferEnabled } = this.props
        return (
            <div className='account-balance-monitor--header clearfix'>
                <input className='account-balance-monitor--search-input vertical-centered' 
                    placeholder={'Search Account, Currency'} 
                    spellCheck={false} 
                    disabled={bulkUpload.enabled}
                    value={searchString}
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                <div className='account-balance-monitor--header--right-section'>
                    <div className='account-balance-monitor--auto-transfer-toggle'>
                        <Popup className='account-balance-monitor--auto-transfer-toggle--description-popup'
                            on={'hover'}
                            trigger={<span>{'Auto Transfer'}</span>}>
                            {`When Auto Transfer is enabled, the monitor will scan all accounts every 30 seconds. 
                            If any account with margin ratio less than Threshold is found, the monitor will automatically transfer specific amount of tokens determined by defined algorithm to it if possible.`}
                        </Popup>
                        <Toggle 
                            checked={autoTransferEnabled && globalVariables.isAutoTransferOn}
                            disabled={!autoTransferEnabled}
                            trueText={'ON'}
                            falseText={'OFF'}
                            isChanging={isSwitchingAutoTransfer}
                            onChange={(newValue) => { this.handleClickAutoTransferToggle(newValue) }} />
                    </div>
                    <div className='account-balance-monitor--auto-transfer-records'>
                        <AutoTransferPopup />
                    </div>
                    {this.GlobalVariablesPopup()}
                    <button className='account-balance-monitor--bulk-upload-button' 
                        title={'Bulk Edit'}
                        onClick={() => {
                            this.setState({
                                searchString: '',
                                bulkUpload: dotProp.set(this.defaultBulkUpload, 'enabled', !bulkUpload.enabled)
                            })
                        }}><FiEdit /></button>
                </div>
            </div>
        )
    }

    VariableInput ({ accountBalance, key, shouldShowAsPercent }) {
        const { id: accountBalanceId } = accountBalance
        const { accountBalanceVariablesEditing } = this.state
        const isVariableEditing =  _.has(accountBalanceVariablesEditing, `${accountBalanceId}.${key}`) 
        const value = isVariableEditing ? accountBalanceVariablesEditing[accountBalanceId][key] : accountBalance[key]
        return (
            <div className='account-balance-monitor--variable-input-wrapper'>
                <input className={'account-balance-monitor--variable-input' + (isVariableEditing ? ' editing' : '')} 
                    type={'number'}
                    value={shouldShowAsPercent && _.isNumber(value) ? `${Math.round(value * 10000)/100}` : value} 
                    onChange={(e) => {
                        let newValue = e.target.value.trim()
                        if (newValue.length > 0) {
                            newValue = Math.round(newValue * 100) / (shouldShowAsPercent ? 10000 : 100)
                        }
                        const newAccountBalanceVariablesEditing = _.cloneDeep(accountBalanceVariablesEditing)
                        if (!_.has(newAccountBalanceVariablesEditing, accountBalanceId)) {
                            newAccountBalanceVariablesEditing[accountBalanceId] = {}
                        }
                        newAccountBalanceVariablesEditing[accountBalanceId][key] = newValue
                        this.setState({ accountBalanceVariablesEditing: newAccountBalanceVariablesEditing })
                    }} />
                {shouldShowAsPercent && <span>{'%'}</span>}
            </div>
        )
    }

    AccountBalanceGroup (group) {
        const { sortBy, sortOrder, accountBalanceIdShouldHighlight, bulkUpload } = this.state
        const { transferFundAdvices, onClickTransferFundAdvice } = this.props
        const firstBalanceItem = _.head(group)
        const { normalizedAccountName, currency } = firstBalanceItem
        const accountNames = _.uniq(group.map(accountBalance => accountBalance.accountName))
        const hasTransferFundAdvices = !_.isEmpty(transferFundAdvices)
        const sortedAccountBalances = _.sortBy(group, accountBalance => {
            const { accountName, market, pair, detail, netBalance, availableBalance, transferableAmount, marginRatio, riskThreshold } = accountBalance
            const sortOrderValue = sortOrder === 'ASC' ? 1 : -1
            if (sortBy === 'pair') {
                return pair
            } else if (sortBy === 'timestamp') {
                return sortOrderValue * moment(detail.timestamp).valueOf()
            } else if (sortBy === 'netBalance') {
                return sortOrderValue * netBalance
            } else if (sortBy === 'availableBalance') {
                return sortOrderValue * availableBalance
            } else if (sortBy === 'transferableAmount') {
                return sortOrderValue * transferableAmount
            } else if (sortBy === 'marginRatio') {
                return sortOrderValue * marginRatio
            } else if (sortBy === 'variables') {
                return sortOrderValue * riskThreshold
            } else if (sortBy === 'accountName') {
                return accountName
            } else {
                return _.has(MARKETS, `${market}.value`) ? MARKETS[market].value : null
            }
        })
        const marketNames = {
            BINANCE_USDT_FUTURES: 'USDT-Futrues'
        }
        return (
            <Fragment key={`${normalizedAccountName}--${currency}`}>
                <thead className='account-balance-monitor--group-info-header'>
                    <tr>
                        <th colSpan={99} className={'account-balance-monitor--group-info' + (bulkUpload.enabled ? ' shift-down' : '')}>
                            <div className='account-balance-monitor--group-info--account'>
                                <span>{accountNames.sort().join(', ')}</span>
                            </div>
                            <div className='account-balance-monitor--group-info--currency'>{firstBalanceItem.currency}</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {sortedAccountBalances.map((accountBalance, index) => {
                        const { id, accountName, market, pair, marginRatio, currency,
                            netBalance, availableBalance, riskThreshold, transferableAmount, detail } = accountBalance
                        const hasMarginRatioWarning = _.isNumber(marginRatio) && marginRatio < riskThreshold 
                        const transferableAdvice = hasMarginRatioWarning
                            ? _.find(transferFundAdvices, advice => 
                                advice.accountBalanceShouldTransferFundTo.id === id
                                && !_.isEmpty(advice.accountBalanceShouldTransferFundFrom)
                                && advice.transferAmount > 0)
                            : null
                        const adviceMessageHtml = !_.isEmpty(transferableAdvice)
                            ? `Transfer <b>${toNumberWithSmartPrecision({ number: transferableAdvice.transferAmount, shouldReturnLocalString: true })}</b> ${currency}
                                from <b>${transferableAdvice.accountBalanceShouldTransferFundFrom.market}${transferableAdvice.accountBalanceShouldTransferFundFrom.pair ? ('.' + transferableAdvice.accountBalanceShouldTransferFundFrom.pair ) : '' }</b>`
                            : `No Account is available transfering <b>${currency}</b> to it`
                        return (
                            <tr className={'account-balance-monitor--account-balance-row' 
                                + (hasMarginRatioWarning ? ' warning' : '') 
                                + (accountBalanceIdShouldHighlight === id ? ' highlight' : '')} 
                                key={index}
                                onMouseEnter={() => {
                                    if (_.has(transferableAdvice, 'accountBalanceShouldTransferFundFrom.id')) {
                                        this.setState({ accountBalanceIdShouldHighlight: transferableAdvice.accountBalanceShouldTransferFundFrom.id })
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (accountBalanceIdShouldHighlight) {
                                        this.setState({ accountBalanceIdShouldHighlight: null })
                                    }
                                }}>
                                <td className='account-balance-monitor--account-name left-align'>
                                    <Popup
                                        on={'click'} 
                                        className='account-balance-monitor--account-popup'
                                        trigger={<span>{accountName}</span>}>
                                        {market === 'SPOT' && !_.isNil(detail) ? <SpotAccountBalanceItem 
                                            spotAccountBalance={detail} 
                                            shouldShowAccountTypeTag
                                            shouldShowDetail
                                            shouldShowAccountName />
                                        : market === 'MARGIN' && !_.isNil(detail) ? <MarginAccountBalanceItem 
                                            marginAccountBalance={detail}
                                            shouldShowAccountTypeTag
                                            shouldShowAccountName
                                            shouldShowDetail />
                                        : market === 'FUTURE' && !_.isNil(detail) ? <FutureAccountBalanceItem 
                                            futureAccountBalance={detail}
                                            shouldShowAccountTypeTag
                                            shouldShowAccountName
                                            shouldShowDetail />
                                        : ['SWAP', 'BINANCE_USDT_FUTURES'].includes(market) && !_.isNil(detail) ? <SwapAccountBalanceItem 
                                            swapAccountBalance={detail}
                                            shouldShowDetail 
                                            shouldShowAccountTypeTag
                                            shouldShowAccountName />
                                        : null}
                                    </Popup>
                                </td>
                                <td className={`account-balance-monitor--market-value ${market} left-align`}>
                                    {marketNames[market] || market}
                                    {!_.isEmpty(pair) && <span className='account-balance-monitor--market-value--pair'>{pair}</span>}
                                </td>
                                <td>{currency}</td>
                                <td>{moment(accountBalance.detail.timestamp).format('HH:mm:ss')}</td>
                                <td className='account-balance-monitor--net-balance-value right-align'>
                                    {!_.isNil(netBalance)
                                    ? <Fragment>{toNumberWithSmartPrecision({ number: netBalance, shouldReturnLocalString: true })}<span>{currency}</span></Fragment>
                                    : 'N/A'}
                                </td>
                                <td className='account-balance-monitor--available-balance-value right-align'>
                                    {!_.isNil(availableBalance)
                                    ? <Fragment>{toNumberWithSmartPrecision({ number: availableBalance, shouldReturnLocalString: true })}<span>{currency}</span></Fragment>
                                    : 'N/A'}
                                </td>
                                <td className='account-balance-monitor--transferable right-align'>
                                    {toNumberWithSmartPrecision({ number: transferableAmount, shouldReturnLocalString: true })}
                                    <span>{currency}</span>
                                </td>
                                <td className='right-align'>{marginRatio ? `${(marginRatio * 100).toFixed(2) + '%'}` : 'N/A'}</td>
                                <td className='account-balance-monitor--variables-data'>{accountBalance.market !== 'SPOT' && 
                                    <Fragment> 
                                        {this.VariableInput({
                                            accountBalance,
                                            key: 'riskThreshold',
                                            shouldShowAsPercent: true
                                        })}   
                                        <span>{'|'}</span>
                                        {this.VariableInput({
                                            accountBalance,
                                            key: 'riskLimit',
                                            shouldShowAsPercent: true
                                        })}
                                    </Fragment>}
                                </td>
                                {hasTransferFundAdvices && <td>
                                    {hasMarginRatioWarning && <div className='account-balance-monitor--transfer-fund-advice'>
                                        <span dangerouslySetInnerHTML={{__html: adviceMessageHtml}} />
                                        {!_.isEmpty(transferableAdvice) && <button onClick={() => {
                                            onClickTransferFundAdvice(transferableAdvice)
                                        }}>{'Transfer'}</button>}
                                    </div>}
                                </td>}
                            </tr>
                        )
                    })}
                </tbody>
            </Fragment>
        )
    }

    BulkUploadTableRow () {
        const { bulkUpload, accountBalanceVariablesEditing } = this.state
        const bulkUploadAccountBalances = this.getBulkUploadAccountBalances()
        return (
            <tr className='account-balance-monitor--bulk-upload-table-row'>
                <td>
                    <input className='account-balance-monitor--bulk-upload-table-row--filter-input' 
                        value={bulkUpload.accountSearchString} 
                        autoFocus
                        placeholder={'Filter Account'}
                        spellCheck={false}
                        type={'text'}
                        onChange={(e) => { 
                            this.setState({ 
                                bulkUpload: dotProp.set(bulkUpload, 'accountSearchString', e.target.value)
                            }) 
                        }} />
                </td>
                <td>
                    <input className='account-balance-monitor--bulk-upload-table-row--filter-input' 
                        value={bulkUpload.marketSearchString} 
                        spellCheck={false}
                        placeholder={'Filter Market'}
                        type={'text'}
                        onChange={(e) => { 
                            this.setState({ 
                                bulkUpload: dotProp.set(bulkUpload, 'marketSearchString', e.target.value)
                            }) 
                        }} />
                </td>
                <td>
                    <input className='account-balance-monitor--bulk-upload-table-row--filter-input'
                        vluae={bulkUpload.currencySearchString}
                        spellCheck={false}
                        placeholder={'Filter Currency'}
                        type={'text'}
                        onChange={(e) => {
                            this.setState({
                                bulkUpload: dotProp.set(bulkUpload, 'currencySearchString', e.target.value)
                            })
                        }} />
                </td>
                <td>
                    <input className='account-balance-monitor--bulk-upload-table-row--filter-input'
                        value={bulkUpload.pairNameSearchString}
                        spellCheck={false}
                        placeholder={'Filter Pair'}
                        type={'text'}
                        onChange={(e) => {
                            this.setState({
                                bulkUpload: dotProp.set(bulkUpload, 'pairNameSearchString', e.target.value)
                            })
                        }} />
                </td>
                <td />
                <td />
                <td />
                <td />
                <td className='account-balance-monitor--bulk-upload-table-row--variables'>
                    <div className='account-balance-monitor--bulk-upload-table-row--variable'>
                        <input
                            value={bulkUpload.riskThreshold}
                            type={'number'}
                            onChange={(e) => { 
                                const newAccountBalanceVariablesEditing = _.cloneDeep(accountBalanceVariablesEditing)
                                const newValue = e.target.value.trim()
                                bulkUploadAccountBalances.forEach(accountBalanceItem => {
                                    if (!_.has(newAccountBalanceVariablesEditing, accountBalanceItem.id)) {
                                        newAccountBalanceVariablesEditing[accountBalanceItem.id] = {}
                                    }
                                    if (newValue.length > 0) {
                                        newAccountBalanceVariablesEditing[accountBalanceItem.id].riskThreshold = Math.round(newValue * 100) / 10000
                                    } else {
                                        delete newAccountBalanceVariablesEditing[accountBalanceItem.id].riskThreshold
                                    }
                                })
                                this.setState({
                                    accountBalanceVariablesEditing: newAccountBalanceVariablesEditing,
                                    bulkUpload: dotProp.set(bulkUpload, 'riskThreshold', e.target.value)
                                })
                            }} />
                        <span>{'%'}</span>
                    </div>
                    <span>{'|'}</span>
                    <div className='account-balance-monitor--bulk-upload-table-row--variable'>
                        <input
                            value={bulkUpload.riskLimit}
                            type={'number'}
                            onChange={(e) => { 
                                const newAccountBalanceVariablesEditing = _.cloneDeep(accountBalanceVariablesEditing)
                                const newValue = e.target.value.trim()
                                bulkUploadAccountBalances.forEach(accountBalanceItem => {
                                    if (!_.has(newAccountBalanceVariablesEditing, accountBalanceItem.id)) {
                                        newAccountBalanceVariablesEditing[accountBalanceItem.id] = {}
                                    }
                                    if (newValue.length > 0) {
                                        newAccountBalanceVariablesEditing[accountBalanceItem.id].riskLimit = Math.round(newValue * 100) / 10000
                                    } else {
                                        delete newAccountBalanceVariablesEditing[accountBalanceItem.id].riskLimit
                                    }
                                })
                                this.setState({
                                    accountBalanceVariablesEditing: newAccountBalanceVariablesEditing,
                                    bulkUpload: dotProp.set(bulkUpload, 'riskLimit', e.target.value)
                                })
                            }} />
                        <span>{'%'}</span>
                    </div>
                </td>
                <td><button className='account-balance-monitor--bulk-upload-table-row--cancel-button' onClick={() => {
                    this.setState({ bulkUpload: this.defaultBulkUpload })
                }}><FiX /></button></td>
            </tr>
        )
    }

    render () {
        const { searchString, shouldShowGlobalVariablesPopup, shouldHideGroupWithOnlySpot, accountBalanceVariablesEditing, isSavingVariables, sortBy, bulkUpload } = this.state
        const { accountBalances, transferFundAdvices } = this.props
        const trimmedSearchString = searchString.trim().toLowerCase()
        const accountBalanceGroups = _.groupBy(bulkUpload.enabled ? this.getBulkUploadAccountBalances() : accountBalances, 'groupId')
        const filteredAccountBalanceGroups = _.pickBy(accountBalanceGroups, group => {
            const markets = _.uniq(group.map(accountBalanceItem => accountBalanceItem.market))
            const accountNames = _.uniq(group.map(accountBalanceItem => accountBalanceItem.accountName))
            const currency = group[0].currency
            return (!shouldHideGroupWithOnlySpot || !_.isEqual(markets, ['SPOT'])) 
                && (trimmedSearchString.length === 0 || isMetSearchStringCriteria(`${accountNames.join()} ${currency}`, trimmedSearchString))
        })
        const seivedAccountBalanceGroups = _.sortBy(filteredAccountBalanceGroups, accountBalances => _.min(accountBalances.map(accountBalance => accountBalance.marginRatio || Infinity)))
        const headers = {
            accountName: {
                key: 'accountName',
                name: 'Account',
                className: 'left-align',
                hidden: false,
                sortable: true
            },
            market: {
                key: 'market',
                name: 'Market',
                className: 'left-align',
                hidden: false,
                sortable: true
            },
            currency: {
                key: 'currency',
                name: 'Currency',
                hidden: false,
                sortable: false,
            },
            timestamp: {
                key: 'timestamp',
                name: 'Timestamp',
                hidden: false,
                sortable: true
            },
            netBalance: {
                key: 'netBalance',
                name: 'Net Balance',
                className: 'right-align',
                hidden: false,
                sortable: true
            },
            availableBalance: {
                key: 'availableBalance',
                name: 'Available Bal.',
                className: 'right-align',
                hidden: false,
                sortable: true
            },
            transferableAmount: {
                key: 'transferableAmount',
                name: 'Adj. Transferable ',
                className: 'right-align',
                hidden: false,
                sortable: true
            },
            marginRatio: {
                key: 'marginRatio',
                name: 'Margin Ratio',
                className: 'right-align',
                hidden: false,
                sortable: true
            },
            variables: {
                key: 'variables',
                name: 'Threshold | Limit',
                hidden: false,
                sortable: true
            }, 
            advice: {
                key: 'advice',
                name: 'Advice',
                hidden: _.isEmpty(transferFundAdvices),
                sortable: false
            }
        }
        return (
            <div className='account-balance-monitor' onClick={() => {
                if (shouldShowGlobalVariablesPopup) {
                    this.setState({
                        shouldShowGlobalVariablesPopup: false
                    })
                }
            }}>
                {this.Header()}
                <div className='account-balance-monitor--main'>
                    <table className='account-balance-monitor--table'>
                        <thead className='account-balance-monitor--table--header'>
                            {bulkUpload.enabled && this.BulkUploadTableRow()}
                            <tr className={'account-balance-monitor--table--header--title-row' + (bulkUpload.enabled ? ' shift-down' : '')}>
                                {_.filter(headers, h => !h.hidden).map(header => {
                                    return (
                                        <th className={'account-balance-monitor--table--header-element' + 
                                            (header.className ? ` ${header.className}` : '') + 
                                            (sortBy === header.key ? ' sorted' : '') + (header.sortable ? ' sortable' : '')} 
                                            key={header.key} 
                                            onClick={() => { 
                                                if (header.sortable) {
                                                    this.handleClickTableHeader(header.key) 
                                                }
                                            }}>{header.name}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        {_.map(seivedAccountBalanceGroups, group => {
                            return this.AccountBalanceGroup(group)
                        })}
                    </table>
                </div>
                {!_.isEmpty(accountBalanceVariablesEditing) && <div className='account-balance-monitor--buttons'>
                    <button className='account-balance-monitor--button reset' onClick={() => {
                        this.setState({ accountBalanceVariablesEditing: {} })
                    }}>{'RESET'}</button>
                    <SaveButton className='account-balance-monitor--button confirm' 
                        isSaving={isSavingVariables} 
                        text={'CONFIRM'} 
                        onClick={() => { this.handleClickSaveAccountBalanceVariablesButton() }}/>
                </div>}
            </div>
        )
    }
}

AccountBalanceMonitor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    globalVariables: PropTypes.object.isRequired,
    accountBalances: PropTypes.array.isRequired,
    autoTransferEnabled: PropTypes.bool.isRequired,
    transferFundAdvices: PropTypes.array,
    accountBalancesLastUpdateTimestamp: PropTypes.number,
    onUpdateVariablesFail: PropTypes.func.isRequired,
    onClickTransferFundAdvice : PropTypes.func,
}

AccountBalanceMonitor.defaultProps = {
    autoTransferEnabled: false,
    transferFundAdvices: [],
    onUpdateVariablesFail: () => {},
    onClickTransferFundAdvice: () => {}
}

export default connect()(AccountBalanceMonitor)