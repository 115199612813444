import React, { Component, Suspense } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import NewWindow from 'react-new-window'
import OptionDeltaContainer from './OptionDeltaContainer'

const OptionImpliedVolatilityContainer = React.lazy(() => import('./OptionImpliedVolatilityContainer'))

export default class OptionWindow extends Component {
    constructor (props) {
        super(props)
        this.tabs = {
            DELTA: 'Delta',
            OPTION_IV: 'Implied Vol.'
        }
        this.state = {
            tab: Object.keys(this.tabs)[0]
        }
        this.reactNewWindowNode = null
    }

    componentDidUpdate (prevProps) {
        const { windowId: prevWindowId } = prevProps
        const { windowId } = this.props
        if (!_.isEqual(prevWindowId, windowId) && _.has(this.reactNewWindowNode, 'window')) {
            this.reactNewWindowNode.window.focus()
        }
    }

    render () {
        const { onClose } = this.props
        const { tab } = this.state
        return (
            <NewWindow
                ref={(node) => { this.reactNewWindowNode = node }}
                name={'Option'} 
                title={'Option - Antelope Technology'}
                features={{ width: 1400, height: 900 }}
                onUnload={() => { 
                    this.reactNewWindowNode = null
                    onClose() 
                }}>
                <div className='option-window'>
                    <div className='option-window--header'>
                        <div className='option-window--header--title'>{'OPTION'}</div>
                        <div className='option-window--header--tabs'>
                            {_.map(this.tabs, (name, key) => (
                                <div className={'option-window--header--tab' + (tab === key ? ' active' : '')} 
                                    key={key}
                                    onClick={() => { this.setState({ tab: key }) }}>
                                    {name}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='option-window--body'>
                        {tab === 'DELTA' && <OptionDeltaContainer />}
                        {tab === 'OPTION_IV' && 
                        <Suspense fallback={<div style={{ margin: '11px' }}>{'Loading...'}</div>}>
                            <OptionImpliedVolatilityContainer />
                        </Suspense>}
                    </div>
                </div>
            </NewWindow>
        )
    }
}

OptionWindow.propTypes = {
    windowId: PropTypes.string,
    onClose: PropTypes.func.isRequired
}

OptionWindow.defaultProps = {
    onClose: () => {}
}