import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import _ from 'lodash'
import moment from 'moment'
// import dotProp from 'dot-prop-immutable'

import NewWindow from 'react-new-window'
import Popup from '../common/popup/Popup'
import { AutoSizer, Table, Column } from 'react-virtualized'
import SearchSelect from '../common/searchSelect/SearchSelect'

import { fetchValueAtRisk } from './tradingAction'
import { getPortfolioNames } from '../../util/accountUtil'

import { isMetSearchStringCriteria, toNumberWithSmartPrecision } from '../../util/util'

const ALL = 'ALL'

const SORT_ORDERS = {
    ASC: 'ASC',
    DESC: 'DESC'
}

const CrossMarginGroupBalance = ({ coin='', balance, price=0, balanceInUSDT=0 }) => {
    return {
        coin,
        balance,
        price,
        balanceInUSDT
    }
}

class ValueAtRiskWindow extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isFetching: false,
            searchingString: '',
            portfolio: ALL,
            tableSortBy: 'var_ratio',
            tableSortOrder: SORT_ORDERS.DESC,
        }

        this._mounted = false
        this.polling = null
        this.reactNewWindowNode = null
        this.tableNode = null
    }

    componentDidMount () {
        this._mounted = true
        this._getData()
        this.polling = setInterval(() => {
            if (this._mounted) {
                this._getData()
            }
        }, 10000)
    }

    componentDidUpdate (prevProps) {
        const { windowId: prevWindowId } = prevProps
        const { windowId } = this.props

        if (!_.isEqual(prevWindowId, windowId) && _.has(this.reactNewWindowNode, 'window')) {
            this.reactNewWindowNode.window.focus()
        }
    }

    componentWillUnmount () {
        this._mounted = false
        if (this.polling) {
            window.clearInterval(this.polling)
            this.polling = null
        }
    }

    _getData () {
        const { dispatch } = this.props
        this.setState({ isFetching: true })
        dispatch(fetchValueAtRisk())
        .finally(() => { 
            if (this._mounted) {
                this.setState({ 
                    isFetching: false 
                })
            }
        })
    }

    _getUSDTPriceByCoin (coin) {
        const { pricings } = this.props
        coin = coin.includes('OPTION') 
            ? coin.replace(/\s+/g, '').split('-')[0].toLowerCase() 
            : coin.toLowerCase()
    
        const symbolNamesToInsepct = [`${coin}_usdt_BINANCE_SPT`, `${coin}_usdt_OKEX_SPT`, 
            `${coin}_usdt_FTX_SPT`, `usdt_${coin}_FTX_SPT`, 
            `${coin}_usdt_PHEMEX_SPT`, `${coin}_usdt_COINFLEX_SPT`,
            `${coin}_usd_FTX_SPT`, `${coin}_busd_BINANCE_SPT`, `${coin}_usd_COINFLEX_SPT`]

        const symbolName = _.find(symbolNamesToInsepct, name => _.has(pricings, name))
        const lastPrice = !_.isNil(symbolName) && _.has(pricings, `${symbolName}.last`) ? Number(pricings[symbolName].last) : null
        
        return coin === 'usdt' ? 1
            : lastPrice ? (symbolName.split('_')[0] === coin ? lastPrice : (1 / lastPrice))
            : null  
    }

    _getCrossMarginGroupBalancesByAccount (accountName, coin='') {
        const { accountAsset } = this.props
        const accountAssetItem = accountAsset[accountName]
        const result = {
            totalBalanceInUSDT: null,
            timestamp: null,
            items: []
        }
        if (!_.isNil(accountAssetItem)) {
            result.timestamp = accountAssetItem.timestamp
            const pickedAccountAssetItem = _.pickBy(accountAssetItem, (value, key) => {
                return key !== 'acct_name' && key !== 'timestamp'
                    && (_.isEmpty(coin) || key === _.lowerCase(coin))
                    && Number(value) !== 0
            })
            _.forEach(pickedAccountAssetItem, (value, key) => {
                const price = this._getUSDTPriceByCoin(key)
                const balanceInUSDT = !_.isNil(price) ? Number(price) * Number(value) : null
                result.items.push(CrossMarginGroupBalance({
                    coin: _.upperCase(key),
                    balance: value,
                    price,
                    balanceInUSDT
                }))
                if (!_.isNil(balanceInUSDT)) {
                    result.totalBalanceInUSDT += Number(balanceInUSDT)
                }
            })
        }
        return result
    }

    Table () {
        const { valueAtRisk, accountItems } = this.props
        const { searchingString, portfolio, tableSortBy, tableSortOrder } = this.state
        const filteredValueAtRisk = _.filter(valueAtRisk, item => {
            const accountItem = accountItems[item.account_name]
            return isMetSearchStringCriteria(`${item.account_name} ${item.cross_margin_group}`, searchingString)
                && (portfolio === ALL || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolio))
        })
        
        _.forEach(filteredValueAtRisk, item => {
            const { account_name, cross_margin_group } = item
            const accountItem = accountItems[account_name]
            const crossMarginGroupCoin = _.has(accountItem, 'exchange_name') && accountItem.exchange_name === 'BNBFUTA' && (cross_margin_group || '').split('_').length > 1 ? cross_margin_group.split('_')[1] : null
            item.balance = this._getCrossMarginGroupBalancesByAccount(account_name, crossMarginGroupCoin)
            item.var_ratio = _.has(item.balance, 'totalBalanceInUSDT') && !_.isNil(item.balance.totalBalanceInUSDT) && Number(item.balance.totalBalanceInUSDT) !== 0
                ? Number(item.var_usdt)/Number(item.balance.totalBalanceInUSDT)
                : null
        })

        const seivedValueAtRisk = _.sortBy(filteredValueAtRisk, item => {
            if (tableSortBy === 'account_name') {
                return item.account_name
            } else if (tableSortBy === 'cross_margin_group') {
                return item.cross_margin_group
            } else if (tableSortBy === 'last_updated') {
                return moment(item.last_updated).valueOf()
            } else if (tableSortBy === 'pos_notional_sum_usdt') {
                return Number(item.pos_notional_sum_usdt)
            } else if (tableSortBy === 'portfolio_stddev') {
                return Number(item.portfolio_stddev)
            } else if (tableSortBy === 'tail_return') {
                return Number(item.tail_return)
            } else if (tableSortBy === 'var_usdt') {
                return Number(item.var_usdt)
            } else if (tableSortBy === 'balance') {
                return _.has(item, 'balance.totalBalanceInUSDT') ? Number(item.balance.totalBalanceInUSDT) : 0
            } else {
                return item.var_ratio
            }
        })

        if (tableSortOrder === SORT_ORDERS.DESC) {
            _.reverse(seivedValueAtRisk)
        }

        return (
            <AutoSizer>
                {({ width, height }) => (
                    <Table
                        ref={(node) => { this.tableNode = node }}
                        className='value-at-risk--table'
                        headerClassName={'value-at-risk--table--header'}
                        headerHeight={27}
                        width={Math.max(width, 1100)}
                        height={height}
                        rowCount={seivedValueAtRisk.length}
                        rowGetter={({ index }) => seivedValueAtRisk[index]}
                        rowClassName={({ index }) => { 
                            const className = 'value-at-risk--table--row' + (index % 2 === 1 ? ' odd-row' : ' even-row')
                            return className
                        }}
                        rowHeight={45}
                        overscanRowCount={5}
                        noRowsRenderer={() => ( <div className='position-table--no-content'>{'There is no matched records.'}</div> )}
                        sort={({ sortBy: newTableSortBy }) => {
                            const newTableSortOrder = _.isEqual(tableSortBy, newTableSortBy)
                                ? (tableSortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC)
                                : tableSortOrder
                            this.setState({
                                tableSortBy: newTableSortBy,
                                tableSortOrder: newTableSortOrder
                            })
                        }}>
                        <Column dataKey={'account_name'}
                            label={'ACCOUNT'}
                            headerClassName={'sortable' + (tableSortBy === 'account_name' ? ' sorted' : '')}
                            width={100}
                            flexGrow={1}
                            flexShrink={0} />
                        <Column dataKey={'cross_margin_group'}
                            label={'CROSS MARGIN GROUP'}
                            headerClassName={'sortable' + (tableSortBy === 'cross_margin_group' ? ' sorted' : '')}
                            width={160}
                            flexGrow={1}
                            flexShrink={0} />
                        <Column dataKey={'last_updated'}
                            label={'TIMESTAMP'}
                            headerClassName={'sortable' + (tableSortBy === 'last_updated' ? ' sorted' : '')}
                            width={80}
                            flexGrow={1} 
                            flexShrink={0}
                            cellRenderer={({ rowData }) => {
                                return moment(rowData.last_updated).format('MM-DD HH:mm:ss')
                            }} />
                        <Column dataKey={'pos_notional_sum_usdt'}
                            label={'NOTIONAL SUM (USDT)'}
                            className='right-align'
                            headerClassName={'sortable right-align' + (tableSortBy === 'pos_notional_sum_usdt' ? ' sorted' : '')}
                            width={100}
                            flexGrow={1} 
                            flexShrink={0}
                            cellRenderer={({ rowData }) => {
                                return (
                                    <span className={'right-align' + (rowData.pos_notional_sum_usdt > 0 ? ' positive' : ' negative')}>
                                        {toNumberWithSmartPrecision({ number: rowData.pos_notional_sum_usdt, defaultPrecision: 0, shouldReturnLocalString: true })}
                                    </span>
                                )
                            }} />
                            <Column dataKey={'portfolio_stddev'}
                                label={'PORTFOLIO SD'}
                                className='right-align'
                                headerClassName={'sortable right-align' + (tableSortBy === 'portfolio_stddev' ? ' sorted' : '')}
                                width={80}
                                flexGrow={1} 
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    return Number(rowData.portfolio_stddev).toFixed(5)
                                }} />
                            <Column dataKey={'quantile'}
                                label={'QUANTILE'}
                                className='right-align'
                                headerClassName={'sortable right-align' + (tableSortBy === 'quantile' ? ' sorted' : '')}
                                width={30}
                                flexGrow={1} 
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    return (Number(rowData.quantile) * 100).toFixed(1) + '%'
                                }} />
                            <Column dataKey={'tail_return'}
                                label={'TAIL RETURN'}
                                className='right-align'
                                headerClassName={'sortable right-align' + (tableSortBy === 'tail_return' ? ' sorted' : '')}
                                width={50}
                                flexGrow={1} 
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    return (Number(rowData.tail_return) * 100).toFixed(2) + '%'
                                }} />
                            <Column dataKey={'var_usdt'}
                                label={'VaR (USDT)'}
                                className='right-align'
                                headerClassName={'sortable right-align' + (tableSortBy === 'var_usdt' ? ' sorted' : '')}
                                width={50}
                                flexGrow={1} 
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    return toNumberWithSmartPrecision({ number: rowData.var_usdt, defaultPrecision: 0, shouldReturnLocalString: true })
                                }} />
                            <Column dataKey={'balance'}
                                label={'Balance (USDT)'}
                                className='right-align'
                                headerClassName={'sortable right-align' + (tableSortBy === 'balance' ? ' sorted' : '')}
                                width={80}
                                flexGrow={1} 
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    return _.has(rowData, 'balance.totalBalanceInUSDT') && !_.isNil(rowData.balance.totalBalanceInUSDT) ? (
                                        <Popup 
                                            className='value-at-risk--balance-popup'
                                            on={'click'}
                                            trigger={<span className='value-at-risk--balance-popup--trigger'>
                                                {toNumberWithSmartPrecision({ number: rowData.balance.totalBalanceInUSDT, defaultPrecision: 0, shouldReturnLocalString: true })}
                                            </span>}>
                                            <Fragment>
                                                <div className='value-at-risk--balance-popup--timestamp'>
                                                    <label>{'Timestamp: '}</label>
                                                    <span>{moment(rowData.balance.timestamp).format('HH:mm:ss')}</span>
                                                </div>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td>{'COIN'}</td>
                                                            <td className='right-align'>{'BALANCE'}</td>
                                                            <td className='right-align'>{'PRICE (USDT)'}</td>
                                                            <td className='right-align'>{'BALANCE (USDT)'}</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {_.map(rowData.balance.items || [], crossMarginGroupBalance => {
                                                            const { coin, balance, price, balanceInUSDT } = crossMarginGroupBalance
                                                            return (
                                                                <tr key={coin}>
                                                                    <td>{coin}</td>
                                                                    <td className='right-align'>{toNumberWithSmartPrecision({ number: balance, defaultPrecision: 2, shouldReturnLocalString: true, shouldApplyFloorValue: true })}</td>
                                                                    <td className='right-align'>{!_.isNil(price) ? toNumberWithSmartPrecision({ number: price, defaultPrecision: 2, shouldReturnLocalString: true }) : 'N/A'}</td>
                                                                    <td className='right-align'>{!_.isNil(balanceInUSDT) ? toNumberWithSmartPrecision({ number: balanceInUSDT, defaultPrecision: 0, shouldReturnLocalString: true }) : 'N/A'}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </Fragment>
                                        </Popup>
                                    ) : 'N/A'
                                }} />
                            <Column dataKey={'var_ratio'}
                                label={'RATIO'}
                                className={'value-at-risk--table--var-ratio-cell right-align'}
                                headerClassName={'value-at-risk--table--var-ratio-cell sortable right-align' + (tableSortBy === 'var_ratio' ? ' sorted' : '')}
                                width={50}
                                flexGrow={1} 
                                flexShrink={0}
                                cellRenderer={({ rowData }) => {
                                    const { var_ratio } = rowData
                                    return !_.isNil(var_ratio) ? (Number(var_ratio) * 100).toFixed(2) + '% ' : 'N/A '
                                }} />
                            
                    </Table>
                )}
            </AutoSizer>
        )
    }

    render () {
        const { onClose } = this.props
        const { portfolio, searchingString } = this.state
        const portfolioNames = getPortfolioNames()
        const portfolioOptions = _.concat([{
            value: ALL,
            name: ALL
        }], portfolioNames.map(portfolioName => {
            return {
                value: portfolioName,
                name: portfolioName
            }
        }))

        return (
            <NewWindow
                ref={(node) => { this.reactNewWindowNode = node }}
                name={'VaR'}
                title={'Value at Risk - Antelope Technology'}
                features={{ width: 1200, height: 800 }}
                onUnload={() => { 
                    this.reactNewWindowNode = null
                    onClose() 
                }}>
                <div className='value-at-risk'>
                    <div className='value-at-risk--header'>
                        <div className='value-at-risk--header--title'>{'Value at Risk'}</div>
                        <div className='value-at-risk--header--filters'>
                            <div className='value-at-risk--header--filter'>
                                <label>{'Portfolio'}</label>
                                <SearchSelect 
                                    hideSearchBar
                                    value={portfolio}
                                    options={portfolioOptions} 
                                    onChange={(newOption) => {
                                        this.setState({ portfolio: newOption.value })
                                    }} />
                            </div>
                            <input type={'text'} 
                                placeholder={'Search Account, Margin Group'} 
                                spellCheck={false}
                                value={searchingString}
                                onChange={(e) => {  
                                    this.setState({ searchingString: e.target.value })
                                }} />
                        </div>
                    </div>
                    <div className='value-at-risk--main'>
                        {this.Table()}
                    </div>
                </div>
            </NewWindow>
        )
    }
}

ValueAtRiskWindow.propTypes = {
    dispatch: PropTypes.func.isRequired,
    valueAtRisk: PropTypes.array.isRequired,
    accountAsset: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,

    windowId: PropTypes.string,
    onClose: PropTypes.func.isRequired
}

ValueAtRiskWindow.defaultTypes = {
    onClose: () => {}
}

function mapStateToProps (state) {
    return {
        valueAtRisk: state.trading.valueAtRisk,
        accountAsset: state.account.asset,
        accountItems: state.account.items,
        pricings: state.symbol.pricings
    }
}

export default connect(mapStateToProps)(ValueAtRiskWindow)