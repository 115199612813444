import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import AccountPopup from '../account/AccountPopup'
import { filterAccountItemsByExchangeName } from '../../util/tradingUtil'
import { getProfileExchangeNames, getProfileSmartPositionAccountsByExchangeName, getProfileExchangeNameBySymbol, getExchangeDisplayName } from '../../util/profileUtil'

class ProfileAccounts extends Component {
    constructor (props) {
        super(props)
        this.state = {
            editable: false
        }
    }

    componentDidUpdate (prevProps) {
        const { editable } = this.state
        const { disableModification } = this.props
        if (!_.isEqual(prevProps.disableModification, disableModification) && editable) {
            this._updateEditable(false)
        }
    }

    _updateEditable (editable) {
        this.setState({
            editable: editable
        })
    }

    SmartPosAccounts (exchangeName) {
        const { profileItem, accountItems, profileRunningState } = this.props
        const smartPositionAccounts = getProfileSmartPositionAccountsByExchangeName(profileItem, exchangeName)
        const isBackupAccountSelectedInBuySide = !_.isEmpty(smartPositionAccounts.BACKUP) && _.some(profileRunningState.smartPosAccounts, item => 
                item.account === smartPositionAccounts.BACKUP 
                && item.side === 'BUY' 
                && getProfileExchangeNameBySymbol(profileItem, item.symbol).BUY === exchangeName)
        const isBackupAccountSelectedInSellSide = !_.isEmpty(smartPositionAccounts.BACKUP) && _.some(profileRunningState.smartPosAccounts, item => 
            item.account === smartPositionAccounts.BACKUP 
            && item.side === 'SELL' 
            && getProfileExchangeNameBySymbol(profileItem, item.symbol).SELL === exchangeName)

        const renderSingleSideAccounts = (side='BUY') => {
            return (
                <div className={`profile-accounts--smart-pos-accounts--single-side ${side}`}>
                    <div className={`profile-accounts--smart-pos-accounts--single-side--name ${side}`}>{side}</div>
                    <div className='profile-accounts--smart-pos-accounts--single-side--account-names'>
                        {smartPositionAccounts[side].map((accountName, index) => {
                            const accountItem = accountItems[accountName]
                            const isAccountSelected = _.some(profileRunningState.smartPosAccounts, item => 
                                item.account === accountName 
                                && item.side === side 
                                && getProfileExchangeNameBySymbol(profileItem, item.symbol)[side] === exchangeName)
                            return (
                                <div className={'profile-accounts--smart-pos-accounts--single-side--account-name' + (isAccountSelected ? ' selected' : '')} 
                                    key={index}
                                    title={isAccountSelected ? 'Selected as Trading Account' : null}>
                                    {!_.isNil(accountItem)
                                    ? <AccountPopup accountItem={accountItem} />
                                    : <span>{accountName}</span>}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }


        return (
            <div className='profile-accounts--smart-pos-accounts'>
                {renderSingleSideAccounts('BUY')}
                {renderSingleSideAccounts('SELL')}
                <div className='profile-accounts--smart-pos-accounts--backup'>
                    <label>{'Backup'}</label>
                    <div className='profile-accounts--smart-pos-accounts--backup--main'>
                        {_.has(accountItems, smartPositionAccounts.BACKUP) && <Fragment>
                            <div className={'profile-accounts--smart-pos-accounts--backup--name' + ((isBackupAccountSelectedInBuySide || isBackupAccountSelectedInSellSide) ? ' selected' : '')}>
                                <AccountPopup accountItem={accountItems[smartPositionAccounts.BACKUP]} />
                            </div>
                            {(isBackupAccountSelectedInBuySide || isBackupAccountSelectedInSellSide) && 
                            <span className='profile-accounts--smart-pos-accounts--backup--selected-message'>{`(Selected in ${isBackupAccountSelectedInBuySide && isBackupAccountSelectedInSellSide ? 'both BUY and SELL sides' : `${isBackupAccountSelectedInBuySide ? 'BUY' : 'SELL'} side`})`}</span>}
                        </Fragment>}
                    </div>
                </div>
            </div>
        )
    }

    render () {
        const { editable } = this.state
        const { disableModification, profileItem, accountItems, optionsMaxHeight, onChangeAccounts } = this.props
        const profileExchangeNames = getProfileExchangeNames(profileItem)
        const profileAccounts = _.pick(profileItem.accounts, profileExchangeNames)
        return (
            <div className='profile-accounts'>
                <div className='profile-accounts--title'>
                    <span>{'Accounts'}</span>
                    {!editable 
                    ? <Popup
                        disabled={!disableModification}
                        className='profile-accounts--enable-edit-button--disabled-popup'
                        trigger={
                            <button 
                                className={'profile-accounts--enable-edit-button vertical-centered' + (disableModification ? ' is-disabled' : '')} 
                                onClick={() => { 
                                    if (!disableModification) {
                                        this.setState({ editable: true })        
                                    }
                                }
                        }>{'Edit'}</button>}>
                        <span>{'You are allowed to modify accounts only when the profile is '}<strong>{'STOPPED'}</strong>{'.'}</span>
                    </Popup>
                    : <button className='profile-accounts--cancle-edit-button vertical-centered' onClick={() => { this.setState({ editable: false }) }}>{'Cancel Edit'}</button>}
                </div>
                <table className='profile-accounts--table'>
                    <tbody>
                        {_.map(profileAccounts, (accountName, exchangeName) => {
                            const filteredAccountItems = filterAccountItemsByExchangeName(accountItems, exchangeName)
                            const accountOptions = filteredAccountItems.map(accountItem => {
                                return {
                                    value: accountItem['account_name'],
                                    name: accountItem['account_name']
                                }
                            })
                            return (
                                <tr key={exchangeName}>
                                    <td className='profile-accounts--exchange-name'>{getExchangeDisplayName(exchangeName)}</td>
                                    <td className='profile-accounts--account-name'>
                                        {editable && accountName !== 'smart_pos_acct' ? <SearchSelect
                                            className='profile-accounts--account-search-select' 
                                            options={accountOptions} 
                                            optionsMaxHeight={optionsMaxHeight}
                                            value={accountName}
                                            optionPosition={'top'}
                                            placeholder={'Select Trading Account'}
                                            onChange={(option) => {
                                                const newAccounts = dotProp.set(profileAccounts, exchangeName, option.name)
                                                onChangeAccounts(newAccounts)
                                            }} />
                                        : accountName === 'smart_pos_acct' ? <Popup className='profile-accounts--smart-pos-accounts-popup'
                                            on={'click'}
                                            trigger={<div className='profile-accounts--smart-pos-accounts-popup--trigger'>{'Smart Position Accounts'}</div>}>
                                            <div className='profile-accounts--smart-pos-accounts-popup--main'>
                                                {editable && <div className='profile-accounts--smart-pos-accounts-popup--main--message'>{'Smart accounts can be modified in the Profile Editor'}</div>}
                                                {this.SmartPosAccounts(exchangeName)}
                                            </div>
                                            
                                        </Popup>
                                        : accountItems[accountName] ? <AccountPopup accountItem={accountItems[accountName]} />
                                        : (accountName || null) }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        ) 
    }
}

ProfileAccounts.propTypes = {
    disableModification: PropTypes.bool,
    profileItem: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    profileRunningState: PropTypes.object.isRequired,
    optionsMaxHeight: PropTypes.number,
    onChangeAccounts: PropTypes.func.isRequired
}

ProfileAccounts.defaultProps = {
    disableModification: false,
    optionsMaxHeight: null,
    onChangeAccounts: () => {}
}

function mapStateToProps (state, ownProps) {
    return {
        accountItems: state.account.items,
        profileRunningState: state.profile.runningState[ownProps.profileItem.id] || {}
    }
}

export default connect(mapStateToProps)(ProfileAccounts)