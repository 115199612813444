import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { MdClose } from 'react-icons/md'
import { FiAlertCircle, FiX } from 'react-icons/fi'

import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import ProfileParam from '../profile/ProfileParam'
import { getSymbolAttributeByName } from '../../util/symbolUtil'
import { resolveTimerProfileItemSymbolConflict, timerProfileItemHasSymbolConflict } from '../../util/profileUtil'
import { symbolParametrs } from '../../configs/profileConfig'

class TimerProfileItem extends Component {
    constructor (props) {
        super(props)
        this.symbolParamKeys = ['QUOTE_SPREAD_THRESHOLD', 'QUOTE_MAX_SIDE_POS']
        this.paramModeOptions = [{
            value: 'absolute',
            name: 'Absolute'
        }, {
            value: 'relative',
            name: 'Relative'
        }]
        this.switchOptions = [{
            value: null,
            name: 'No Change'
        }, {
            value: true,
            name: 'ON'
        }, {
            value: false,
            name: 'OFF'
        }]
        this.state = {
            shouldShowTagInput: false,
            tagInput: ''
        }
    }

    handleClickSolveConflictButton () {
        const { timerProfileItem, profileItems, onChangeProfileParams } = this.props
        const profileItem = profileItems[timerProfileItem.profileId]
        const newTimerProfileItem = resolveTimerProfileItemSymbolConflict(timerProfileItem, profileItem)
        onChangeProfileParams(newTimerProfileItem)
    }

    ProfileName ({profileId, onChange, onClickRemove}) {
        const { profileItems, shouldDisableModification } = this.props
        const profileItemOptions = _.map(profileItems, (profileItem) => {
            return {
                value: profileItem.id,
                name: `${profileItem.name} - ${profileItem.hostname}`
            }
        })
        const isProfileItemNil = profileId && _.isNil(profileItems[profileId])
        return (
            <div className='timer-profile-item--profile-name'>
                <div className='timer-profile-item--profile-name--label'>{'Profile'}</div>
                <SearchSelect 
                    placeholder={isProfileItemNil ? profileId : 'Select Profile'}
                    value={profileId || null} 
                    disabled={shouldDisableModification}
                    options={profileItemOptions}
                    onChange={(newOption) => { 
                        if (profileId !== newOption.value) {
                            onChange(newOption.value)
                        }
                    }} />
                {!shouldDisableModification && <button className='timer-profile-item--profile-name--remove-button' onClick={() => {
                    onClickRemove()
                }}><MdClose /></button>}
            </div>
        )
    }

    ProfileTags () {
        const { shouldShowTagInput, tagInput } = this.state
        const { timerProfileItem, onChangeProfileParams } = this.props
        return (
            <div className='timer-profile-item--tags clearfix'>
                {(timerProfileItem.tags || []).map((tag, index) => {
                    return (
                        <div className='timer-profile-item--tags--item' key={index}>
                            <span>{tag}</span>
                            <button className='timer-profile-item--tags--remove-button' onClick={() => {
                                const newProfileItem = dotProp.delete(timerProfileItem, `tags.${index}`)
                                onChangeProfileParams(newProfileItem)
                            }}><FiX /></button>
                        </div>
                    )
                })}
                {shouldShowTagInput 
                ? <div className='timer-profile-item--tags--input-wrapper'>
                    <input className='timer-profile-item--tags--input' 
                        type={'text'}
                        spellCheck={false}
                        placeholder={'Add Tag'}
                        autoFocus
                        value={tagInput} 
                        onChange={(e) => { this.setState({ tagInput: e.target.value }) }} 
                        onKeyDown={(e) => {
                            const newTag = e.target.value.trim()
                            if (e.key === 'Enter' && newTag.length > 0) {
                                const newProfileItem = dotProp.merge(timerProfileItem, 'tags', [newTag])
                                this.setState({ shouldShowTagInput: false })
                                onChangeProfileParams(newProfileItem)
                            }
                        }}  />
                    <button className='timer-profile-item--tags--cancel-input-button' onClick={() => { this.setState({ shouldShowTagInput: false }) }}><FiX /></button>
                </div>
                : <button className='timer-profile-item--tags--add-button' onClick={() => { 
                    this.setState({
                        shouldShowTagInput: true,
                        tagInput: ''
                    })
                }}>{'Add Tag'}</button>}
            </div>
        )
    }

    SwitchSelect ({value, label, onChange}) {
        const { shouldDisableModification } = this.props
        return (
            <div className='timer-profile-item--switch-select'>
                {label && <span className='timer-profile-item--switch-select--label'>{label}</span>}
                <SearchSelect
                    className={value === true ? 'enabled' : value === false ? 'disabled' : null}
                    value={value}
                    options={this.switchOptions} 
                    hideSearchBar
                    disabled={shouldDisableModification}
                    onChange={(newOption) => {
                        onChange(newOption.value)
                    }} />
            </div>
        )
    }

    SectionLabel (side='BUY') {
        return (
            <span className={`timer-profile-item--symbol-switches--section-label ${side}`}>{side[0]}</span>
        )
    }

    ProfileParams ({timerProfile, onChange}) {
        const { profileItems, shouldHideSwitches, shouldDisableModification, shouldDisableParamMode } = this.props
        const hasSymbolConflicts = profileItems[timerProfile.profileId] && timerProfileItemHasSymbolConflict(timerProfile, profileItems[timerProfile.profileId])
        return (
            <table className='timer-profile-item--params'>
                <thead>
                    <tr>
                        <th>
                            {'Symbol'}
                            {hasSymbolConflicts && 
                            <Popup className='timer-profile-item--conflict--popup'
                                on={'click'}
                                trigger={<button className='timer-profile-item--conflict--button'><FiAlertCircle /></button>}>
                                <div>{`Original Profile's symbols have been changed as: `}<b>{profileItems[timerProfile.profileId].legs['1'].symbols.map(symbol => symbol.name).join(', ')}</b></div>
                                <button className='timer-profile-item--conflict--solve-button' 
                                    disabled={shouldDisableModification}
                                    onClick={() => {
                                        this.handleClickSolveConflictButton()
                                    }}>{'Apply Latest Symbols'}</button>
                            </Popup>}
                        </th>
                        {timerProfile.legs['1'].symbols.map((symbol, index) => {
                            return <th key={index}>{symbol.name}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {!shouldHideSwitches && <tr className='timer-profile-item--params--switches-row'>
                        <th>{'Switch'}</th>
                        {timerProfile.legs['1'].symbols.map((symbol, index) => {
                            const symbolAttribute = getSymbolAttributeByName(symbol.name)
                            return (
                                <td key={index}>
                                    <div className='timer-profile-item--symbol-switches'>
                                        <div className='timer-profile-item--symbol-switches--section BUY'>
                                            {this.SectionLabel('BUY')}
                                            {symbolAttribute.switchType === 'BUY_SELL' && 
                                            <div className='timer-profile-item--symbol-switches--single-toggle-wrapper'>
                                                {this.SwitchSelect({
                                                    value: symbol.switches['BUY'],
                                                    onChange: (newValue) => {
                                                        const newTimerProfile = dotProp.set(timerProfile, `legs.1.symbols.${index}.switches.BUY`, newValue)
                                                        onChange(newTimerProfile)
                                                    }
                                                })}
                                            </div>}
                                            {symbolAttribute.switchType === 'BUY_SELL_TO_OPEN_CLOSE' &&
                                            <div className='timer-profile-item--symbol-switches--buy-close-wrapper'>
                                                {this.SwitchSelect({
                                                    value: symbol.switches['BUY_OPEN'],
                                                    label: 'Open',
                                                    onChange: (newValue) => {
                                                        const newTimerProfile = dotProp.set(timerProfile, `legs.1.symbols.${index}.switches.BUY_OPEN`, newValue)
                                                        onChange(newTimerProfile)
                                                    }
                                                })}
                                                {this.SwitchSelect({
                                                    value: symbol.switches['BUY_CLOSE'],
                                                    label: 'Close',
                                                    onChange: (newValue) => {
                                                        const newTimerProfile = dotProp.set(timerProfile, `legs.1.symbols.${index}.switches.BUY_CLOSE`, newValue)
                                                        onChange(newTimerProfile)
                                                    }
                                                })}
                                            </div>}
                                        </div>
                                        <div className='timer-profile-item--symbol-switches--section SELL'>
                                            {this.SectionLabel('SELL')}
                                            {symbolAttribute.switchType === 'BUY_SELL' && 
                                            <div className='timer-profile-item--symbol-switches--single-toggle-wrapper'>
                                                {this.SwitchSelect({
                                                    value: symbol.switches['SELL'],
                                                    onChange: (newValue) => {
                                                        const newTimerProfile = dotProp.set(timerProfile, `legs.1.symbols.${index}.switches.SELL`, newValue)
                                                        onChange(newTimerProfile)
                                                    }
                                                })}
                                            </div>}
                                            {symbolAttribute.switchType === 'BUY_SELL_TO_OPEN_CLOSE' &&
                                            <div className='timer-profile-item--symbol-switches--buy-close-wrapper'>
                                                {this.SwitchSelect({
                                                    value: symbol.switches['SELL_OPEN'],
                                                    label: 'Open',
                                                    onChange: (newValue) => {
                                                        const newTimerProfile = dotProp.set(timerProfile, `legs.1.symbols.${index}.switches.SELL_OPEN`, newValue)
                                                        onChange(newTimerProfile)
                                                    }
                                                })}
                                                {this.SwitchSelect({
                                                    value: symbol.switches['SELL_CLOSE'],
                                                    label: 'Close',
                                                    onChange: (newValue) => {
                                                        const newTimerProfile = dotProp.set(timerProfile, `legs.1.symbols.${index}.switches.SELL_CLOSE`, newValue)
                                                        onChange(newTimerProfile)
                                                    }
                                                })}
                                            </div>}
                                        </div>
                                    </div>  
                                </td>
                            )
                        })}
                    </tr>}
                    {this.symbolParamKeys.map((paramKey) => {
                        const paramConfig = symbolParametrs.leg1[paramKey]
                        const symbols = timerProfile.legs['1'].symbols
                        const paramMode = _.has(symbols, `0.params.${paramKey}.mode`) ? symbols[0].params[paramKey].mode : null

                        return paramConfig ? (
                            <tr key={paramKey}>
                                <th>
                                    <div className='timer-profile-item--param-name'>{paramConfig.name}</div>
                                    {paramKey === 'QUOTE_SPREAD_THRESHOLD' && 
                                    <SearchSelect 
                                        className={`timer-profile-item--symbol-param-mode ${paramMode}`}
                                        disabled={shouldDisableModification || shouldDisableParamMode}
                                        hideSearchBar
                                        value={paramMode}
                                        options={this.paramModeOptions}  
                                        onChange={(newOption) => {
                                            const newTimerProfile = _.cloneDeep(timerProfile)
                                            newTimerProfile.legs['1'].symbols.forEach(symbol => {
                                                if (_.isEmpty(symbol.params[paramKey])) {
                                                    symbol.params[paramKey] = {
                                                        value: [null, null],
                                                        mode: newOption.value
                                                    }
                                                } else {
                                                    symbol.params[paramKey].mode = newOption.value
                                                }
                                            })
                                            onChange(newTimerProfile)
                                        }} />}
                                </th>
                                {symbols.map((symbol, index) => {
                                    const value = _.has(symbol.params, `${paramKey}.value`) 
                                        ? symbol.params[paramKey].value
                                        : paramConfig.type.includes('array') ? [null, null] : null
                                    return (
                                        <td key={index}>
                                            <ProfileParam
                                                paramConfig={paramConfig}
                                                disabled={shouldDisableModification}
                                                value={value} 
                                                onChange={(newValue) => {
                                                    if (_.isArray(newValue)) {
                                                        newValue.forEach((v, index) => {
                                                            if ([null, ''].includes(v)) {
                                                                newValue[index] = null
                                                            }
                                                        })
                                                    }
                                                    const newTimerProfile = dotProp.set(timerProfile, `legs.1.symbols.${index}.params.${paramKey}.value`, newValue)
                                                    onChange(newTimerProfile)
                                                }} />
                                        </td>
                                    )
                                })}
                            </tr>
                        ) : null
                    })}
                </tbody>
            </table>
        )
    }

    render () {
        const { timerProfileItem, isDuplicated, shouldHideProfileName, shouldHideTags,
            onChangeProfileId, onChangeProfileParams, onClickRemoveButton } = this.props
        return (
            <div className='timer-profile-item'>
                {!shouldHideProfileName && this.ProfileName({
                    profileId: timerProfileItem.profileId,
                    onChange: (newProfileId) => { onChangeProfileId(newProfileId) },
                    onClickRemove: () => { onClickRemoveButton() }
                })}
                {!shouldHideTags && this.ProfileTags()}
                {isDuplicated && <div className='timer-profile-item--duplicate-message'>{'It is a duplicate profile.'}</div>}
                {!_.isEmpty(timerProfileItem) && this.ProfileParams({
                    timerProfile: timerProfileItem,
                    onChange: (newProfileItem) => { onChangeProfileParams(newProfileItem) }
                })}
            </div>
        )
    }

}

TimerProfileItem.propTypes = {
    timerProfileItem: PropTypes.object.isRequired,
    profileItems: PropTypes.object.isRequired,
    isDuplicated: PropTypes.bool,
    shouldHideProfileName: PropTypes.bool,
    shouldHideTags: PropTypes.bool,
    shouldHideSwitches: PropTypes.bool,
    shouldDisableModification: PropTypes.bool,
    shouldDisableParamMode: PropTypes.bool,
    onChangeProfileId: PropTypes.func,
    onChangeProfileParams: PropTypes.func,
    onClickRemoveButton: PropTypes.func
}

TimerProfileItem.defaultProps = {
    isDuplicated: false,
    shouldHideProfileName: false,
    shouldHideTags: false,
    shouldHideSwitches: false,
    shouldDisableModification: false,
    shouldDisableParamMode: false,
    onChangeProfileId: () => {},
    onChangeProfileParams: () => {},
    onClickRemoveButton: () => {}
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(TimerProfileItem)