import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sleep from 'sleep-promise'
import delay from 'delay'

import dotProp from 'dot-prop-immutable'
import moment from 'moment'
import _ from 'lodash'

import NewWindow from 'react-new-window'
import BigNumber from 'bignumber.js'

const ResultItem = ({ timestamp='', index=0, value }) => {
    return {
        timestamp,
        index,
        value
    }
}

export default class TestWindow extends Component {
    constructor (props) {
        super(props)
        this.state = {
            intervalInput: 150,
            orderSize: 100,
            isSending: false,
            sentOrderSize: 0,
            results: {
                1: [],
                2: [],
                3: [],
                4: []
            }
        }

        this.cachedResults = []

        this.timeouts = {}
    }

    async handleClickSubmit1 () {
        const { intervalInput, orderSize, results } = this.state
        const orders = _.range(orderSize)
        this.setState({
            sentOrderSize: 0,
            isSending: true,
            results: dotProp.set(results, 1, [])
        })
        this.cachedResults = []
        for await (const [index, value] of orders.entries()) {
            if (index > 0 && intervalInput > 0) {
                await new Promise(resolve => setTimeout(resolve, intervalInput))
            }
            if (this.state.isSending) {
                this.cachedResults.push(ResultItem({
                    index,
                    timestamp: moment().toISOString(),
                    value
                }))
                this.setState(prevState => {
                    return {
                        sentOrderSize: prevState.sentOrderSize + 1,
                    }
                })
            } else {
                this.setState({ results: dotProp.set(results, 1, this.cachedResults) })
                break
            }
        }
        if (this.state.isSending) {
            this.setState({ 
                isSending: false,
                results: dotProp.set(results, 1, this.cachedResults)
            })
        }
    }

    async handleClickSubmit2 () {
        const { intervalInput, orderSize, results } = this.state
        const orders = _.range(orderSize)
        this.setState({
            sentOrderSize: 0,
            isSending: true,
            results: dotProp.set(results, 2, [])
        })
        this.cachedResults = []
        for await (const [index, value] of orders.entries()) {
            if (index > 0 && intervalInput > 0) {
                await sleep(intervalInput)
            }
            if (this.state.isSending) {
                this.cachedResults.push(ResultItem({
                    index,
                    timestamp: moment().toISOString(),
                    value
                }))
                this.setState(prevState => {
                    return {
                        sentOrderSize: prevState.sentOrderSize + 1,
                    }
                })
            } else {
                this.setState({ results: dotProp.set(results, 2, this.cachedResults) })
                break
            }
        }
        if (this.state.isSending) {
            this.setState({ 
                isSending: false,
                results: dotProp.set(results, 2, this.cachedResults)
            })
        }
    }

    async handleClickSubmit3 () {
        const { intervalInput, orderSize, results } = this.state
        const orders = _.range(orderSize)
        this.setState({
            sentOrderSize: 0,
            isSending: true,
            results: dotProp.set(results, 3, [])
        }, () => {
            console.log(this.state)
        })
        this.cachedResults = []
        for await (const [index, value] of orders.entries()) {
            if (index > 0 && intervalInput > 0) {
                await delay(intervalInput)
            }
            if (this.state.isSending) {
                this.cachedResults.push(ResultItem({
                    index,
                    timestamp: moment().toISOString(),
                    value
                }))
                this.setState(prevState => {
                    return {
                        sentOrderSize: prevState.sentOrderSize + 1,
                    }
                })
            } else {
                this.setState({ results: dotProp.set(results, 3, this.cachedResults) })
                break
            }
        }
        if (this.state.isSending) {
            this.setState({ 
                isSending: false,
                results: dotProp.set(results, 3, this.cachedResults)
            })
        }
    }

    async handleClickSubmit4 () {
        const { intervalInput, orderSize, results } = this.state
        const orders = _.range(orderSize)
        this.setState({
            sentOrderSize: 0,
            isSending: true,
            results: dotProp.set(results, 4, [])
        })
        this.cachedResults = []
        this.timeouts = {}
        for (const [index, value] of orders.entries()) {
            this.timeouts[index] = setTimeout(() => {
                if (this.state.isSending) {
                    this.cachedResults.push(ResultItem({
                        index,
                        timestamp: moment().toISOString(),
                        value
                    }))
                    this.setState(prevState => {
                        return {
                            sentOrderSize: prevState.sentOrderSize + 1
                        }
                    })
                } else {
                    _.forEach(this.timeouts, timeout => {
                        if (timeout) {
                            clearTimeout(timeout)
                        }
                    })
                    this.timeouts = {}
                    this.setState({ results: dotProp.set(results, 4, this.cachedResults) })
                }

                delete this.timeouts[index]
                if (_.isEmpty(this.timeouts) && this.state.isSending) {
                    this.setState({
                        isSending: false,
                        results: dotProp.set(results, 4, this.cachedResults)
                    })
                }
            }, intervalInput * index)
        }
    }


    render () {
        const { onClose } = this.props
        const { intervalInput, isSending, sentOrderSize, orderSize, results } = this.state
        return (
            <NewWindow
                title={'Test - Antelope Technology'}
                features={{ width: 1000, height: 900 }}
                onUnload={() => { 
                    onClose() 
                }}>
                <div style={{ position: 'absolute', padding: '30px', width: 'calc(100% - 85px)', height: 'calc(100% - 60px)', overflow: 'auto' }}>
                    <div>
                        <label style={{ minWidth: '120px', display: 'inline-block' }}>{'Interval (ms)'}</label>
                        <input 
                            type={'number'}
                            value={intervalInput} 
                            onChange={(e) => { 
                                this.setState({
                                    intervalInput: Math.abs(parseInt(e.target.value))
                                })
                            }} />
                    </div>
                    <br />
                    <div>
                        <label style={{ minWidth: '120px', display: 'inline-block' }}>{'Order Size '}</label>
                        <input 
                            type={'number'}
                            value={orderSize} 
                            onChange={(e) => { 
                                this.setState({
                                    orderSize: Math.abs(parseInt(e.target.value))
                                })
                            }} />
                    </div>
                    <br />
                    <div>
                        <button disabled={isSending} style={{ marginRight: '10px' }}
                            onClick={() => { this.handleClickSubmit1() }}>
                            {'Simulate Mode 1'}
                        </button>
                        <button disabled={isSending} style={{ marginRight: '10px' }}
                            onClick={() => { this.handleClickSubmit2() }}>
                            {'Simulate Mode 2'}
                        </button>
                        <button disabled={isSending} style={{ marginRight: '10px' }}
                            onClick={() => { this.handleClickSubmit3() }}>
                            {'Simulate Mode 3'}
                        </button>
                        <button disabled={isSending} style={{ marginRight: '10px' }}
                            onClick={() => { this.handleClickSubmit4() }}>
                            {'Simulate Mode 4'}
                        </button>
                        <button disabled={!isSending} style={{ marginRight: '10px' }}
                            onClick={() => { 
                                this.setState({ isSending: false })
                            }}>{'Discard'}</button>
                    </div>
                    <br />
                    <div>{`Orders Sent: ${sentOrderSize}`}</div>
                    <br />
                    <div style={{ fontSize: '13px', fontWeight: 'bold' , display: 'inline-flex'}}>
                        {_.map(results, (resultItems, key) => {
                            const avgInterval = _.size(resultItems) >= 2 ? moment(_.last(resultItems).timestamp).diff(_.head(resultItems).timestamp) / (_.size(resultItems) - 1) : null
                            return (
                                <div key={key} style={{ marginRight: '50px' }}>
                                    <div style={{ fontSize: '16px' }}>{`Mode ${key}`}</div>
                                    {!_.isNil(avgInterval) && <div>{`Avg Interval: ${BigNumber(avgInterval).toFixed(2, 1)} ms`}</div>}
                                    <div style={{ marginTop: '10px' }}>
                                        {_.map(resultItems, (resultItem, index) => {
                                            const interval = index >= 1 ? moment(resultItem.timestamp).diff(resultItems[index - 1].timestamp) : null
                                            return (
                                                <div key={index}>
                                                    <span style={{ display: 'inline-block', minWidth: '20px', marginRight: '10px' }}>{resultItem.index}</span>
                                                    <span style={{ marginRight: '5px' }}>{moment(resultItem.timestamp).format('HH:mm:ss.SSS')}</span>
                                                    {!_.isNil(interval) && <span>{'( +' + BigNumber(interval).toFixed(0, 1) + 'ms )'}</span>}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </NewWindow>
        )
    }
}

TestWindow.propTypes = {
    onClose: PropTypes.func.isRequired
}

