import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import dotProp from 'dot-prop-immutable'
import BigNumber from 'bignumber.js'
import uuidv4 from 'uuid/v4'
import moment from 'moment'
import _ from 'lodash'

import { AiOutlineSwap } from 'react-icons/ai' 
import { MdCancel } from 'react-icons/md'
import { FiX } from 'react-icons/fi'
import Popup from '../common/popup/Popup'
import SearchSelect from '../common/searchSelect/SearchSelect'
import Checkbox from '../common/checkbox/Checkbox'
import SaveButton from '../common/saveButton/SaveButton'
import SpotAccountBalanceItem from './SpotAccountBalanceItem'
import MarginAccountBalanceItem from './MarginAccountBalanceItem'
import FutureAccountBalanceItem from './FutureAccountBalanceItem'
import SwapAccountBalanceItem from './SwapAccountBalanceItem'
import WalletAccounBalanceItem from './WalletAccountBalanceItem'
import AccountAssetItem from './AccountAssetItem'
import CrossMarginAccountBalanceItem from './CrossMarginAccountBalanceItem'
import CrossAccountBalanceItem from './CrossAccountBalanceItem'

import { accountTransferFund, accountDepositFund, fetchAccountTransferableDetails, syncAccountBalances } from './accountAction'
import { toNumberWithSmartPrecision, toNumberInputValue, isMetSearchStringCriteria } from '../../util/util'
import { getNotional, getRiskRatioThresholdByPositionItem } from '../../util/tradingUtil'
import { getSymbolAttributeByName, INSTRUMENT_TYPES } from '../../util/symbolUtil'

BigNumber.config({ EXPONENTIAL_AT: 1e+9 })

export const OPERATION_MODES = {
    SINGLE: 'SINGLE',
    BULK: 'BULK'
}

export const TRANSFER_MODES = {
    TRANSFER: 'TRANSFER',
    WITHDRAW: 'WITHDRAW'
}

export const BULK_TRANSFER_MODES = {
    ONE_TO_MANY: {
        key: 'ONE_TO_MANY',
        name: '1:N'
    },
    MANY_TO_ONE: {
        key: 'MANY_TO_ONE',
        name: 'N:1'
    },
    ONE_TO_ONE: {
        key: 'ONE_TO_ONE',
        name: '1:1'
    }
}

const TRANSFER_ACCOUNT_DIRECTIONS = {
    ORIGIN: 'ORIGIN',
    DESTINATION: 'DESTINATION'
}

export const ACCOUNT_TYPES = {
    SPOT: {
        key: 'SPOT',
        value: 0,
        name: 'Spot'
    },
    MARGIN: {
        key: 'MARGIN',
        value: 1,
        name: 'Margin'
    },
    CROSS_MARGIN: {
        key: 'CROSS_MARGIN',
        value: 1,
        name: 'Cross Margin'
    },
    FUTURE: {
        key: 'FUTURE',
        value: 2,
        name: 'Futures'
    },
    SWAP: {
        key: 'SWAP',
        value: 3,
        name: 'Swap'
    },
    OPTION: {
        key: 'OPTION',
        value: 5,
        name: 'Options'
    },
    WALLET: {
        key: 'WALLET',
        value: 4,
        name: 'Wallet'
    },
    FTX_WALLET: {
        key: 'FTX_WALLET',
        value: 4,
        name: 'Wallet'
    },
    BINANCE_USDT_FUTURES: {
        key: 'BINANCE_USDT_FUTURES',
        value: 3,
        name: 'USD-M Futures'
    },
    BINANCE_COIN_FUTURES: {
        key: 'BINANCE_COIN_FUTURES',
        value: 2,
        name: 'COIN-M Futures'
    },
    PHEMEX_BTC_CONTRACT: {
        key: 'PHEMEX_BTC_CONTRACT',
        value: 2,
        name: 'BTC Contract Account'
    },
    OKEX_FUNDING_ACCOUNT: {
        key: 'OKEX_FUNDING_ACCOUNT',
        value: 4,
        name: 'Funding'
    },
    OKEX_UNIFIED_ACCOUNT: {
        key: 'OKEX_UNIFIED_ACCOUNT',
        value: 7,
        name: 'Unified Account'
    },
    CRYPTOFUT_DERIVATIVES: {
        key: 'CRYPTOFUT_DERIVATIVES',
        value: 3,
        name: 'Derivatives'
    }
}

export const TRANSFER_STATES = {
    NULL: 'NULL',
    WAITING: 'WAITING',
    TRANSFERING: 'TRANSFERING',
    TRANSFERRED: 'TRANSFERRED',
    FAILED: 'FAILED'
}

export const PairName = (base, quote) => {
    return `${base}-${quote}`.toLowerCase()
}

export const TransferAccount = ({ accountName, accountType, pairName }) => {
    return {
        accountName,
        accountType,
        pairName
    }
}

export const TransferItem = ({ originTransferAccount, destinationTransferAccount, amount='', amountPercentInput='', state=TRANSFER_STATES.NULL, message }) => {
    return {
        originTransferAccount: originTransferAccount || TransferAccount({}),
        destinationTransferAccount: destinationTransferAccount || TransferAccount({}),
        amount,
        amountPercentInput,
        state,
        message
    }
}

const Exchange = ({ key, name, accountTypeCanWidthdrawToken={},
    getAccountTypesCanTransferToken=() => { return [] },
    canAccountWithdrawToken=() => { return false },
    shouldDefinePairName=() => { return false },
    getCounterTransferAccountNames=() => { return [] } }) => { // Return the account names that can send/receive token to/from the specified account name in arg
    return {
        key,
        name,
        accountTypeCanWidthdrawToken,
        getAccountTypesCanTransferToken,
        canAccountWithdrawToken,
        shouldDefinePairName,
        getCounterTransferAccountNames
    }
}

export const EXCHANGES = {
    // OKEX: Exchange({
    //     key: 'OKEX',
    //     name: 'OKEX',
    //     accountTypeCanWidthdrawToken: ACCOUNT_TYPES.WALLET,
    //     getAccountTypesCanTransferToken: () => {
    //         return [ACCOUNT_TYPES.WALLET, ACCOUNT_TYPES.SPOT, ACCOUNT_TYPES.MARGIN, ACCOUNT_TYPES.FUTURE, ACCOUNT_TYPES.SWAP, ACCOUNT_TYPES.OPTION]
    //     },
    //     canAccountWithdrawToken: ({ accountItem }) => {
    //         return !_.isNil(accountItem) && accountItem.exchange_name === 'OKEX' && accountItem.is_main === '1'
    //     },
    //     shouldDefinePairName: ({ tokenToTransfer, accountTypeKey }) => {
    //         return accountTypeKey === ACCOUNT_TYPES.MARGIN.key
    //             || ([ACCOUNT_TYPES.FUTURE.key, ACCOUNT_TYPES.SWAP.key].includes(accountTypeKey) && tokenToTransfer === 'USDT')
    //     },
    //     getCounterTransferAccountNames: ({ transferAccount, accountItems }) => {
    //         const { accountName } = transferAccount
    //         const accountItem = accountItems[accountName]
    //         const result = [accountName]
    //         if (accountItem) {
    //             _.forEach(accountItems, counterAccountItem => {
    //                 const { account_name: counterAccountName, exchange_name: exchangeName, main_acct_name: counterMainAccountName } = counterAccountItem
    //                 if (exchangeName === 'OKEX' 
    //                 && ((!_.isEmpty(counterMainAccountName) && (counterMainAccountName === accountName || counterMainAccountName === accountItem.main_acct_name) && counterAccountName !== accountName)
    //                     || (accountItem.main_acct_name === counterAccountName)
    //                     )
    //                 ) {
    //                     result.push(counterAccountName)
    //                 }
    //             })
    //         }
    //         return result
    //     }
    // }),
    OKEX: Exchange({
        key: 'OKEX',
        name: 'OKEX',
        accountTypeCanWidthdrawToken: null,
        getAccountTypesCanTransferToken: () => {
            return [ACCOUNT_TYPES.OKEX_FUNDING_ACCOUNT, ACCOUNT_TYPES.OKEX_UNIFIED_ACCOUNT]
        },
        canAccountWithdrawToken: () => { return false },
        shouldDefinePairName: () => { return false },
        getCounterTransferAccountNames: ({ transferAccount, accountItems }) => {
            const { accountName } = transferAccount
            const transferAccountItem = accountItems[accountName]
            const accountNames = _.filter(accountItems, accountItem => {
                return accountItem.account_name === accountName
                    || accountItem.main_acct_name === accountName
                    || transferAccountItem.main_acct_name === accountItem.account_name
                    || (!_.isEmpty(transferAccountItem.main_acct_name) && transferAccountItem.main_acct_name === accountItem.main_acct_name)
            }).map(accountItem => accountItem.account_name)
            return accountNames
        }
    }),
    HUOBI: Exchange({
        key: 'HUOBI',
        name: 'HUOBI',
        accountTypeCanWidthdrawToken: ACCOUNT_TYPES.SPOT,
        getAccountTypesCanTransferToken: () => {
            return [ACCOUNT_TYPES.SPOT]
        },
        canAccountWithdrawToken: ({ accountItem }) => { 
            return !_.isNil(accountItem) && accountItem.exchange_name === 'HUOBI'
        },
        shouldDefinePairName: () => { return false },
        getCounterTransferAccountNames: ({ transferAccount, accountItems }) => { 
            const { accountName } = transferAccount
            const aliasAccountName = accountName.replace('huobi_', 'huobifut_') 
            const accountItem = accountItems[accountName]
            const result = []
            _.forEach(accountItems, counterAccountItem => {
                const { account_name: counterAccountName, main_acct_name: counterMainAccountName } = counterAccountItem
                const counterAccountAliasName = counterAccountName.includes('huobi_') ? counterAccountName.replace('huobi_', 'huobifut_') 
                    : counterAccountName.includes('huobifut_') ? counterAccountName.replace('huobifut_', 'huobi_')
                    : null
                if (counterAccountName === aliasAccountName 
                    || (!_.isEmpty(counterMainAccountName) && (counterMainAccountName === accountName || counterMainAccountName === aliasAccountName || counterMainAccountName === accountItem.main_acct_name) && counterAccountName !== accountName)
                    || (accountItem.main_acct_name === counterAccountName)
                    || (accountItem.main_acct_name === counterAccountAliasName)
                ) {
                    result.push(counterAccountName)
                }
            })
            return result
        }
    }),
    HUOBIFUT: Exchange({
        key: 'HUOBIFUT',
        name: 'HUOBIFUT',
        accountTypeCanWidthdrawToken: null,
        getAccountTypesCanTransferToken: () => {
            return [ACCOUNT_TYPES.FUTURE, ACCOUNT_TYPES.SWAP]
        },
        canAccountWithdrawToken: () => { return false },
        shouldDefinePairName: ({ tokenToTransfer, accountTypeKey }) => { 
            return accountTypeKey === ACCOUNT_TYPES.SWAP.key && tokenToTransfer === 'USDT'
        },
        getCounterTransferAccountNames: ({ transferAccount, accountItems }) => { 
            const { accountName } = transferAccount
            const aliasAccountName = accountName.replace('huobifut_', 'huobi_') 
            const accountItem = accountItems[accountName]
            const result = [accountName]
            if (!_.isNil(accountItem)) { 
                _.forEach(accountItems, counterAccountItem => {
                    const { account_name: counterAccountName, main_acct_name: counterMainAccountName } = counterAccountItem
                    const counterAccountAliasName = counterAccountName.includes('huobi_') ? counterAccountName.replace('huobi_', 'huobifut_') 
                        : counterAccountName.includes('huobifut_') ? counterAccountName.replace('huobifut_', 'huobi_')
                        : null
                    if (counterAccountName === aliasAccountName
                        || (!_.isEmpty(counterMainAccountName) && (counterMainAccountName === accountName || counterMainAccountName === aliasAccountName || counterMainAccountName === accountItem.main_acct_name) && counterAccountName !== accountName)
                        || (accountItem.main_acct_name === counterAccountName)
                        || (accountItem.main_acct_name === counterAccountAliasName)
                    ) {
                        result.push(counterAccountName)
                    }
                })
            }
            return result
        }
    }),
    BINANCE: Exchange({
        key: 'BINANCE',
        name: 'BINANCE',
        accountTypeCanWidthdrawToken: null,
        getAccountTypesCanTransferToken: () => {
            return [ACCOUNT_TYPES.SPOT, ACCOUNT_TYPES.CROSS_MARGIN]
        },
        canAccountWithdrawToken: () => { return false },
        shouldDefinePairName: () => { return false },
        getCounterTransferAccountNames: ({ transferAccount, accountItems }) => {
            const { accountName } = transferAccount
            const accountItem = accountItems[accountName]
            const aliasAccountName = accountName.replace('binance_', 'bnbfuta_')
            return !_.isNil(accountItem) 
                ? _.filter(accountItems, item => {
                    return [accountName, aliasAccountName].includes(item.account_name)
                        || (accountItem.is_main === '1' && [accountName, aliasAccountName].includes(item.main_acct_name))
                        || (accountItem.is_main !== '1' && !_.isEmpty(accountItem.main_acct_name)
                            && [item.account_name, item.account_name.replace('binance_', 'bnbfuta_'), (item.main_acct_name || ''), (item.main_acct_name || '').replace('binance_', 'bnbfuta_')].includes(accountItem.main_acct_name))
                }).map(item => item.account_name)
                : []
        }
    }),
    BNBFUTA: Exchange({
        key: 'BNBFUTA',
        name: 'BNBFUTA',
        accountTypeCanWidthdrawToken: null,
        getAccountTypesCanTransferToken: ({ tokenToTransfer }) => {
            return tokenToTransfer === 'USDT' ? [ACCOUNT_TYPES.BINANCE_USDT_FUTURES]
                : ['BUSD', 'BNB'].includes(tokenToTransfer) ? [ACCOUNT_TYPES.BINANCE_COIN_FUTURES, ACCOUNT_TYPES.BINANCE_USDT_FUTURES]
                : [ACCOUNT_TYPES.BINANCE_COIN_FUTURES]
        },
        canAccountWithdrawToken: () => { return false },
        shouldDefinePairName: () => { return false },
        getCounterTransferAccountNames: ({ transferAccount, accountItems }) => {
            const { accountName } = transferAccount
            const accountItem = accountItems[accountName]
            const aliasAccountName = accountName.replace('bnbfuta_', 'binance_')
            return !_.isNil(accountItem) 
                ? _.filter(accountItems, item => {
                    return [accountName, aliasAccountName].includes(item.account_name)
                        || (accountItem.is_main === '1' && [accountName, aliasAccountName].includes(item.main_acct_name))
                        || (accountItem.is_main !== '1' && !_.isEmpty(accountItem.main_acct_name)
                            && [item.account_name, item.account_name.replace('binance_', 'bnbfuta_'), (item.main_acct_name || ''), (item.main_acct_name || '').replace('binance_', 'bnbfuta_')].includes(accountItem.main_acct_name))
                }).map(item => item.account_name)
                : []
        }
    }),
    PHEMEX: Exchange({
        key: 'PHEMEX',
        name: 'PHEMEX',
        accountTypeCanWidthdrawToken: ACCOUNT_TYPES.SPOT,
        getAccountTypesCanTransferToken: ({ tokenToTransfer, accountName, accountItems, counterTransferAccount }) => {
            let result = []
            if (['BTC', 'USDT'].includes(tokenToTransfer)) {
                const accountItem = accountItems[accountName]
                if (accountItem) {
                    const counterAccountItem = _.has(counterTransferAccount, 'accountName') ? accountItems[counterTransferAccount.accountName] : null
                    if (_.isNil(counterAccountItem) || accountName === counterAccountItem.account_name) {
                        result = tokenToTransfer === 'BTC' && !accountName.includes('phemex_usd') ? [ACCOUNT_TYPES.SPOT, ACCOUNT_TYPES.PHEMEX_BTC_CONTRACT]
                            : tokenToTransfer === 'USDT' ? [ACCOUNT_TYPES.SPOT]
                            : []
                    } else {
                        if (counterAccountItem.main_acct_name === accountName || accountItem.main_acct_name === counterAccountItem.account_name) {
                            result = (!_.has(counterTransferAccount, 'accountType.key') || counterTransferAccount.accountType.key === ACCOUNT_TYPES.SPOT.key) 
                                && !accountName.includes('phemex_usd')
                                && tokenToTransfer === 'BTC'
                                ? [ACCOUNT_TYPES.SPOT, ACCOUNT_TYPES.PHEMEX_BTC_CONTRACT]
                                : [ACCOUNT_TYPES.SPOT]
                        } else if (!_.isEmpty(accountItem.main_acct_name) && accountItem.main_acct_name === counterAccountItem.main_acct_name) {
                            result = [ACCOUNT_TYPES.SPOT]
                        }
                    }
                }
            }
            return result
        },
        canAccountWithdrawToken: ({ accountItem }) => {
            return accountItem.exchange_name === 'PHEMEX'
        },
        shouldDefinePairName: () => { return false },
        getCounterTransferAccountNames: ({ tokenToTransfer, transferAccount, accountItems }) => {
            const { accountName, accountType } = transferAccount
            const accountItem = accountItems[accountName]
            return !_.isNil(accountItem)
                ? _.filter(accountItems, item => {
                    return item.exchange_name === 'PHEMEX'
                        && ((!_.isEmpty(item.main_acct_name) && item.main_acct_name === accountName) 
                            || (!_.isEmpty(accountItem.main_acct_name) && accountItem.main_acct_name === item.account_name) 
                            || (!_.isEmpty(accountItem.main_acct_name) && accountItem.main_acct_name === item.main_acct_name)
                            || (item.account_name === accountName)
                        )
                        && (tokenToTransfer !== 'BTC' || !item.account_name.includes('phemex_usd'))
                        && (_.isNil(accountType) 
                            || accountType.key !== ACCOUNT_TYPES.PHEMEX_BTC_CONTRACT.key 
                            || item.account_name === accountName 
                            || accountItem.is_main === '1' 
                            || item.is_main === '1')
                }).map(item => item.account_name)
                : []
        }
    }),
    FTX: Exchange({
        key: 'FTX',
        name: 'FTX',
        accountTypeCanWidthdrawToken: null,
        getAccountTypesCanTransferToken: () => {
            return [ACCOUNT_TYPES.FTX_WALLET]
        },
        canAccountWithdrawToken: () => { return false },
        shouldDefinePairName: () => { return false },
        getCounterTransferAccountNames: ({ transferAccount, accountItems }) => {
            const { accountName } = transferAccount
            const accountItem = accountItems[accountName]
            const result = []
            if (!_.isNil(accountItem)) {
                const { main_acct_name: originAccountMainAccountName, exchange_name: originAccountExchangeName } = accountItem
                _.forEach(accountItems, counterAccountItem => {
                    const { account_name: counterAccountName, main_acct_name: counterMainAccountName, exchange_name: counterAccountExchangeName } = counterAccountItem
                    if (counterAccountName !== accountName && originAccountExchangeName === counterAccountExchangeName &&
                        ((!_.isEmpty(counterMainAccountName) && counterMainAccountName === accountName)
                        || (!_.isEmpty(counterMainAccountName) && counterMainAccountName === originAccountMainAccountName)
                        || (!_.isEmpty(originAccountMainAccountName) && originAccountMainAccountName === counterAccountName))
                    ) {
                        result.push(counterAccountName)
                    }
                })
            }
            return result
        }
    })
    // CRYPTOSPT: Exchange({
    //     key: 'CRYPTOSPT',
    //     name: 'CRYPTOSPT',
    //     accountTypeCanWidthdrawToken: null,
    //     getAccountTypesCanTransferToken: () => {
    //         return [ACCOUNT_TYPES.SPOT]
    //     },
    //     canAccountWithdrawToken: () => { return false },
    //     shouldDefinePairName: () => { return false },
    //     getCounterTransferAccountNames: ({ transferAccount, accountItems }) => {
    //         const { accountName } = transferAccount
    //         const transferAccountItem = accountItems[accountName]
    //         const result = []
    //         if (transferAccountItem) {
    //             const transferAccountRelateingAccountNames = _.compact([
    //                 accountName, 
    //                 (accountName || '').replace('spt_', 'fut_'), 
    //                 transferAccountItem.main_acct_name, 
    //                 (transferAccountItem.main_acct_name || '').replace('fut_', 'spt_'), 
    //                 (transferAccountItem.main_acct_name || '').replace('spt_', 'fut_')
    //             ])
    //             _.forEach(accountItems, accountItem => {
    //                 if (!_.isEmpty(_.intersection(transferAccountRelateingAccountNames, [accountItem.account_name, accountItem.main_acct_name]))) {
    //                     result.push(accountItem.account_name)
    //                 }
    //             })
    //         }
    //         return result
    //     }
    // }),
    // CRYPTOFUT: Exchange({
    //     key: 'CRYPTOFUT',
    //     name: 'CRYPTOFUT',
    //     accountTypeCanWidthdrawToken: null,
    //     getAccountTypesCanTransferToken: () => {
    //         return [ACCOUNT_TYPES.CRYPTOFUT_DERIVATIVES]
    //     },
    //     canAccountWithdrawToken: () => { return false },
    //     shouldDefinePairName: () => { return false },
    //     getCounterTransferAccountNames: ({ transferAccount, accountItems }) => {
    //         const { accountName } = transferAccount
    //         const transferAccountItem = accountItems[accountName]
    //         const result = []
    //         if (transferAccountItem) {
    //             const transferAccountRelateingAccountNames = _.compact([
    //                 accountName, 
    //                 (accountName || '').replace('fut_', 'spt_'), 
    //                 transferAccountItem.main_acct_name, 
    //                 (transferAccountItem.main_acct_name || '').replace('fut_', 'spt_'), 
    //                 (transferAccountItem.main_acct_name || '').replace('spt_', 'fut_')
    //             ])
    //             _.forEach(accountItems, accountItem => {
    //                 if (!_.isEmpty(_.intersection(transferAccountRelateingAccountNames, [accountItem.account_name, accountItem.main_acct_name]))) {
    //                     result.push(accountItem.account_name)
    //                 }
    //             })
    //         }
    //         return result
    //     }
    // })
    // DERIBIT: Exchange({
    //     key: 'DERIBIT',
    //     name: 'DERIBIT',
    //     accountTypeCanWidthdrawToken: null,
    //     getAccountTypesCanTransferToken: () => {
    //         return [ACCOUNT_TYPES.SWAP]
    //     },
    //     canAccountWithdrawToken: () => { return false },
    //     shouldDefinePairName: () => { return false },
    //     getCounterTransferAccountNames: ({ transferAccount, accountItems }) => {
    //         const { accountName } = transferAccount
    //         const accountItem = accountItems[accountName]
    //         const result = []
    //         if (!_.isNil(accountItem)) {
    //             const { main_acct_name: originAccountMainAccountName, exchange_name: originAccountExchangeName } = accountItem
    //             _.forEach(accountItems, counterAccountItem => {
    //                 const { account_name: counterAccountName, main_acct_name: counterMainAccountName, exchange_name: counterAccountExchangeName } = counterAccountItem
    //                 if (counterAccountName !== accountName && originAccountExchangeName === counterAccountExchangeName &&
    //                     ((!_.isEmpty(counterMainAccountName) && counterMainAccountName === accountName)
    //                     || (!_.isEmpty(counterMainAccountName) && counterMainAccountName === originAccountMainAccountName)
    //                     || (!_.isEmpty(originAccountMainAccountName) && originAccountMainAccountName === counterAccountName))
    //                 ) {
    //                     result.push(counterAccountName)
    //                 }
    //             })
    //         }
    //         return result
    //     }
    // })
    // BIT: Exchange({
    //     key: 'BIT',
    //     name: 'BIT',
    //     accountTypeCanWidthdrawToken: null,
    //     getAccountTypesCanTransferToken: () => {
    //         return [ACCOUNT_TYPES.SWAP]
    //     },
    //     canAccountWithdrawToken: () => { return false },
    //     shouldDefinePairName: () => { return false },
    //     getCounterTransferAccountNames: ({ transferAccount, accountItems }) => {
    //         const { accountName } = transferAccount
    //         const accountItem = accountItems[accountName]
    //         const result = []
    //         if (!_.isNil(accountItem)) {
    //             const { main_acct_name: originAccountMainAccountName, exchange_name: originAccountExchangeName } = accountItem
    //             _.forEach(accountItems, counterAccountItem => {
    //                 const { account_name: counterAccountName, main_acct_name: counterMainAccountName, exchange_name: counterAccountExchangeName } = counterAccountItem
    //                 if (counterAccountName !== accountName && originAccountExchangeName === counterAccountExchangeName &&
    //                     ((!_.isEmpty(counterMainAccountName) && counterMainAccountName === accountName)
    //                     || (!_.isEmpty(counterMainAccountName) && counterMainAccountName === originAccountMainAccountName)
    //                     || (!_.isEmpty(originAccountMainAccountName) && originAccountMainAccountName === counterAccountName))
    //                 ) {
    //                     result.push(counterAccountName)
    //                 }
    //             })
    //         }
    //         return result
    //     }
    // })
}

const BulkTransferResult = ({ token='BTC', successfulTransferItems=[], failedTransferSize=0, shouldShow=true }) => {
    return {
        token,
        successfulTransferItems,
        failedTransferSize,
        shouldShow
    }
}

class TokenTransferEditor extends Component {
    constructor (props) {
        super(props)
        this.initialState = {
            operationMode: props.defaultOperationMode,
            transferMode: props.transferMode,
            token: 'BTC',
            transferItems: [TransferItem({})],
            bulkTransferMode: BULK_TRANSFER_MODES.ONE_TO_MANY,
            oneToManyOriginTransferAccount: TransferAccount({}),
            manyToOneDestinationTransferAccount: TransferAccount({}),
            isBulkTransferring: false,
            bulkTransferAmountOfEachInput: '',
            bulkTransferTotalAmountInput: '',
            bulkTransferAccountTypeKey: null,
            bulkTransferPairName: null,
            bulkTransferAccountNameSearchString: '',
            bulkTransferMessage: null,
            positionFilterSymbolName: null,
            bulkTransferResult: BulkTransferResult({ shouldShow: false })
        }
        this.state = {
            accountTransferableDetails: {},
            ...this.initialState
        }

        this._mounted = false
        this.bulkTransferInterval = null
        this.polling = null
        this.bulkTransferId = null

        this.bulkTransferBodyNode = null
        this.amountOfEachTransferInputNode = null
        this.bulkTransferAccountSearchInputNode = null
    }

    static getDerivedStateFromProps (props, state) {
        if (!_.isEqual(props.transferMode, state.transferMode)) {
            return {
                transferMode: props.transferMode,
                transferItems: [TransferItem({})]
            }
        } 
        return null
    }

    componentDidMount () {
        const { bulkTransferConfig } = this.props
        this._mounted = true
        this._getData()
        this.polling = setInterval(() => {
            this._getData()
        }, 5000)

        if (!_.isNil(bulkTransferConfig.updateId)) {
            this._updateBulkTransferConfig(bulkTransferConfig)
        }
    }

    componentDidUpdate (prevProps) {
        const { defaultSingleTransferConfig: prevDefaultSingleTransferConfig, bulkTransferConfig: prevBulkTransferConfig } = prevProps
        const { defaultSingleTransferConfig, bulkTransferConfig } = this.props
        const { operationMode } = this.state

        if (operationMode === OPERATION_MODES.SINGLE 
            && !_.isEqual(prevDefaultSingleTransferConfig, defaultSingleTransferConfig) 
            && !_.isEmpty(defaultSingleTransferConfig)) {
            const { token, transferItem } = defaultSingleTransferConfig
            this._updateDefaultSingleTransferConfig({
                token: token,
                transferItem: transferItem
            })
        } else if (!_.isEqual(prevBulkTransferConfig.updateId, bulkTransferConfig.updateId) && !_.isNil(bulkTransferConfig.updateId)) {
            this._updateBulkTransferConfig(bulkTransferConfig)
        }
    }

    componentWillUnmount () {
        if (this.bulkTransferInterval) {
            window.clearInterval(this.bulkTransferInterval)
        }
        if (this.polling) {
            window.clearInterval(this.polling)
        }
        this._mounted = false
    }

    _getData () {
        const { dispatch } = this.props
        dispatch(fetchAccountTransferableDetails())
        .then(details => {
            this.setState({
                accountTransferableDetails: _.keyBy(details, detail => `${detail.acct_name}--${detail.coin}`)
            })
        })
    }

    _updateDefaultSingleTransferConfig ({ token, transferItem }) {
        this.setState({
            token: token,
            transferItems: [transferItem || TransferItem({})]
        })
    }

    _updateBulkTransferConfig (bulkTransferConfig={}) {
        const { isBulkTransferring } = this.state
        if (isBulkTransferring) {
            this.setState({ bulkTransferMessage: 'You should not update the inputs before all transfers are complete.' })
        } else {
            this.setState(bulkTransferConfig)
            if (this.amountOfEachTransferInputNode) {
                this.amountOfEachTransferInputNode.focus()
            }
        }
    }

    validateTransferItems () {
        return new Promise(resolve => {
            const { token, transferItems } = this.state
            const newTransferItems = _.cloneDeep(transferItems)
            let result = true, invalidTransferItemSize = _.size(newTransferItems)
            newTransferItems.forEach(transferItem => {
                const { originTransferAccount, destinationTransferAccount, amount } = transferItem
                const originValidAccountTypeKeys = this.getValidAccountTypes({ tokenToTransfer: token, accountName: originTransferAccount.accountName, counterTransferAccount: destinationTransferAccount })
                    .map(accountType => accountType.key)
                const destinationValidAccountTypeKeys = this.getValidAccountTypes({ tokenToTransfer: token, accountName: destinationTransferAccount.accountName, counterTransferAccount: originTransferAccount })
                    .map(accountType => accountType.key)
                if (_.isNil(originTransferAccount.accountName)) {
                    transferItem.message = 'Please select the Account Name where the token is transfered from'
                    result = false
                } else if (_.isNil(originTransferAccount.accountType)) {
                    transferItem.message = 'Please select the Account Type where the token is transfered from'
                    result = false
                } else if (!originValidAccountTypeKeys.includes(originTransferAccount.accountType.key)) {
                    transferItem.message = 'Account Type the token is transfered from is invalid'
                    result = false
                } else if (this.shouldTransferAccountHavePairName(token, originTransferAccount) && _.isEmpty(originTransferAccount.pairName)) {
                    transferItem.message = 'Please select the Pair Name where the token is transfered from'
                    result = false
                } else if (_.isNil(destinationTransferAccount.accountName)) {
                    transferItem.message = 'Please select the Account Name where the token is transfered to'
                    result = false
                } else if (_.isNil(destinationTransferAccount.accountType)) {
                    transferItem.message = 'Please select the Account Type where the token is transfered to'
                    result = false
                } else if (!destinationValidAccountTypeKeys.includes(destinationTransferAccount.accountType.key)) {
                    transferItem.message = 'Account Type the token is transfered to is invalid'
                    result = false
                } else if (this.shouldTransferAccountHavePairName(token, destinationTransferAccount) && _.isEmpty(destinationTransferAccount.pairName)) {
                    transferItem.message = 'Please Select the Pair Name where the token is transfered to'
                    result = false
                } else if (_.isEqual(originTransferAccount, destinationTransferAccount)) {
                    transferItem.message = 'Both accounts should be distinct'
                    result = false
                } else if (Number(amount) < 0) {
                    transferItem.message = 'Amount is invalid'
                    result = false
                } else {
                    invalidTransferItemSize--
                    transferItem.message = null
                }
            })
            this.setState({ 
                transferItems: newTransferItems,
                bulkTransferMessage: invalidTransferItemSize > 0 ? `${invalidTransferItemSize} transfer${invalidTransferItemSize > 1 ? 's' : ''} fail${invalidTransferItemSize > 1 ? '' : 's'} to pass validation` : null
            })
            setTimeout(() => {
                resolve(result)
            })
        })
    }

    getOKEXSpotPriceByPairName (pairName=PairName({})) {
        const { pricings } = this.props
		const symbolName = `${pairName.toLowerCase().replace('-', '_')}_OKEX_SPT`
		return _.has(pricings, `${symbolName}.last`) ? Number(pricings[symbolName].last) : null
    }

    getAccountNamesCanWithdrawToken () {
        const { accountItems } = this.props
        const accountItemsCanWithdrawToken = _.filter(accountItems, accountItem => {
            const { exchange_name: exchangeName } = accountItem
            return EXCHANGES[exchangeName].canAccountWithdrawToken({ accountItem })
        })
        const accountNames = accountItemsCanWithdrawToken.map(item => item.account_name)
        return accountNames
    }

    getValidAccountTypes ({ tokenToTransfer, accountName, counterTransferAccount }) {
        const { transferMode } = this.state
        const { accountItems } = this.props
        const accountItem = accountItems[accountName]
        let accountTypes = []
        if (accountItem) {
            const { exchange_name: exchangeName } = accountItem
            const exchange = EXCHANGES[exchangeName]
            if (transferMode === TRANSFER_MODES.TRANSFER) {
                accountTypes = exchange.getAccountTypesCanTransferToken({ tokenToTransfer, accountName, accountItems, counterTransferAccount })
            } else if (transferMode === TRANSFER_MODES.WITHDRAW && !_.isNil(exchange.accountTypeCanWidthdrawToken)) {
                accountTypes = [exchange.accountTypeCanWidthdrawToken]
            }
        }
        return accountTypes
    }

    shouldTransferAccountHavePairName (tokenToTransfer, transferAccount=TransferAccount({})) {
        const { accountItems } = this.props
        const { accountName, accountType } = transferAccount
        const accountItem = accountItems[accountName]
        let shouldHavePairName = false
        if (!_.isNil(accountItem) && !_.isNil(accountType)) {
            const { exchange_name: exchangeName } = accountItem
            shouldHavePairName = EXCHANGES[exchangeName].shouldDefinePairName({ 
                tokenToTransfer,
                accountTypeKey: accountType.key
            })
        }
        return shouldHavePairName
    }

    getTransferAccountValidPairNames (tokenToTransfer, transferAccount=TransferAccount({})) {
        const { tokens, accountItems } = this.props
        let pairNames = []
        if (this.shouldTransferAccountHavePairName(tokenToTransfer, transferAccount)) {
            if (_.has(accountItems, `${transferAccount.accountName}.exchange_name`) 
                && accountItems[transferAccount.accountName].exchange_name === 'HUOBIFUT'
                && _.has(transferAccount, `accountType.key`) 
                && transferAccount.accountType.key === ACCOUNT_TYPES.SWAP.key
                && tokenToTransfer === 'USDT') {  
                // Handle HUOBIFUT USDT SWAP cross margin
                pairNames.push('USDT')
            } else {
                const quoteTokens = ['USDT', 'BTC']
                pairNames = quoteTokens.includes(tokenToTransfer)
                    ? _.without(tokens, tokenToTransfer).map(t => {
                        return t === 'USDT' ? PairName(tokenToTransfer, t) : PairName(t, tokenToTransfer)
                    })
                    : quoteTokens.map(quoteToken => PairName(tokenToTransfer, quoteToken))
                if (tokenToTransfer === 'USDT') {
                    pairNames.push(PairName('USDT', 'USD'))
                    pairNames.sort()
                }
            }
        }
        return pairNames
    }

    getCounterTransferAccountNames (transferAccount=TransferAccount({})) {
        const { token } = this.state
        const { accountName } = transferAccount
        const { accountItems } = this.props
        const accountItem = accountItems[accountName]
        let counterAccountNames = []
        if (!_.isNil(accountItem)) {
            const { exchange_name: exchangeName } = accountItem
            counterAccountNames = EXCHANGES[exchangeName].getCounterTransferAccountNames({ tokenToTransfer: token, transferAccount, accountItems })
        }
        return counterAccountNames
    }

    getAvailableBalanceCanTransfer (transferAccount=TransferAccount({})) {
        const { accountBalance, accountAsset } = this.props
        const { token, accountTransferableDetails } = this.state
        const { accountName, accountType, pairName } = transferAccount
        const result = {
            balanceItem: null,
            available: null,
            accountBalance: null,
            assetBalance: !_.isEmpty(token) && _.has(accountAsset, `${accountName}.${_.lowerCase(token)}`) ? _.toString(accountAsset[accountName][_.lowerCase(token)] || 0) : null
        }

        if (!_.isEmpty(token) && !_.isNil(accountName) && !_.isNil(accountType)) {
            if (accountType.key === ACCOUNT_TYPES.WALLET.key) {
                result.balanceItem = _.find(accountBalance.wallet, { acct_name: accountName, coin: token.toLowerCase() })
                result.available = result.balanceItem ? _.toString(result.balanceItem.avaiable || 0) : null
                result.accountBalance = result.balanceItem ? _.toString(result.balanceItem.balance || 0) : null
            } else if (accountType.key === ACCOUNT_TYPES.FTX_WALLET.key) {
                result.balanceItem = accountAsset[accountName]
                const accountTransferableDetail = _.find(accountTransferableDetails, { acct_name: accountName, coin: (token || '').toLowerCase() })
                if (!_.isNil(accountTransferableDetail)) {
                    result.available = _.toString(accountTransferableDetail.transferable || 0)
                }
                result.accountBalance = _.has(result.balanceItem, _.lowerCase(token)) ? _.toString(result.balanceItem[_.lowerCase(token)] || 0) : null
            } else if (accountType.key === ACCOUNT_TYPES.SPOT.key) {
                result.balanceItem = _.find(accountBalance.spot, { acct_name: accountName, coin: token.toLowerCase() })
                result.available = result.balanceItem ? _.toString(result.balanceItem.available || 0) : null
                result.accountBalance = result.balanceItem ? _.toString(result.balanceItem.balance || 0) : null
            } else if (accountType.key === ACCOUNT_TYPES.MARGIN.key && pairName) {
                result.balanceItem = _.find(accountBalance.margin, { acct_name: accountName, pair: pairName.toLowerCase() })

                if (result.balanceItem) {
                    const { coin1, coin2, transferable1, transferable2 } = result.balanceItem
                    if (token.toLowerCase() === coin1) {
                        result.available = _.toString(transferable1 || 0)
                    } else if (token.toLowerCase() === coin2) {
                        result.available = _.toString(transferable2 || 0)
                    }
                }
            } else if (accountType.key === ACCOUNT_TYPES.CROSS_MARGIN.key) {
                result.balanceItem = _.find(accountBalance.crossMargin, crossMarginAccountBalance => crossMarginAccountBalance.acct_name === accountName && _.upperCase(crossMarginAccountBalance.coin || '') === _.upperCase(token || ''))
                if (result.balanceItem) {
                    result.available = _.toString(result.balanceItem.available || 0)
                    result.accountBalance = _.toString(result.balanceItem.balance || 0)
                }
            } else if (accountType.key === ACCOUNT_TYPES.FUTURE.key) {
                const coin = token === 'USDT' 
                    ? (!_.isEmpty(pairName) ? pairName.toLowerCase() : token.toLowerCase()) 
                    : token.toLowerCase()

                if (coin) {
                    result.balanceItem = _.find(accountBalance.future, { acct_name: accountName, coin: coin })
                    if (result.balanceItem) {
                        result.available = _.toString(result.balanceItem.transferable || 0)
                        result.accountBalance = _.toString(result.balanceItem.equity || 0)
                    }
                }
            } else if (accountType.key === ACCOUNT_TYPES.SWAP.key) {
                const formatedPairName = pairName ? pairName.toUpperCase() 
                    : token && !this.shouldTransferAccountHavePairName(token, transferAccount) ? `${token}-USD` 
                    : null
                if (formatedPairName) {
                    result.balanceItem = _.find(accountBalance.swap, swapAccountBalance => {
                        return swapAccountBalance.acct_name === accountName
                            && [formatedPairName, `${formatedPairName}-SWAP`, token.toUpperCase(), token.toLowerCase()].includes(swapAccountBalance.coin)
                    })
                    if (result.balanceItem) {
                        result.available = _.toString(result.balanceItem.transferable || 0)
                        result.accountBalance = _.toString(result.balanceItem.equity || 0)
                    }
                }
            } else if (accountType.key === ACCOUNT_TYPES.BINANCE_COIN_FUTURES.key) {
                result.balanceItem = _.find(accountBalance.future, { acct_name: accountName, coin: token.toLowerCase() })
                if (result.balanceItem) {
                    result.available = _.toString(result.balanceItem.transferable || 0)
                    result.accountBalance = _.toString(result.balanceItem.equity || 0)
                }
            } else if (accountType.key === ACCOUNT_TYPES.BINANCE_USDT_FUTURES.key) {
                result.balanceItem = _.find(accountBalance.swap, { acct_name: accountName, coin: token.toUpperCase() })
                if (result.balanceItem) {
                    result.available = _.toString(result.balanceItem.transferable || 0)
                    result.accountBalance = _.toString(result.balanceItem.equity || 0)
                }
            } else if (accountType.key === ACCOUNT_TYPES.PHEMEX_BTC_CONTRACT.key) {
                result.balanceItem = _.find(accountBalance.swap, { acct_name: accountName, coin: token.toUpperCase() })
                if (result.balanceItem) {
                    result.available = _.toString(result.balanceItem.equity || 0)
                    result.accountBalance = _.toString(result.balanceItem.equity || 0)
                }
            } else if (accountType.key === ACCOUNT_TYPES.OKEX_FUNDING_ACCOUNT.key) {
                result.balanceItem = _.find(accountBalance.wallet, { acct_name: accountName, coin: token.toLowerCase() })
                if (result.balanceItem) {
                    result.available = _.toString(result.balanceItem.avaiable || 0)
                    result.accountBalance = _.toString(result.balanceItem.balance || 0)
                }
            } else if (accountType.key === ACCOUNT_TYPES.OKEX_UNIFIED_ACCOUNT.key) {
                result.balanceItem = _.find(accountBalance.cross, { acct_name: accountName, coin: token.toLowerCase() })
                if (result.balanceItem) {
                    result.available = _.toString(result.balanceItem.availEq || 0)
                    result.accountBalance = _.toString(result.balanceItem.eq || 0)
                }
            } else if (accountType.key === ACCOUNT_TYPES.CRYPTOFUT_DERIVATIVES.key) {
                result.balanceItem = _.find(accountBalance.swap, { acct_name: accountName, coin: token.toUpperCase() })
                if (result.balanceItem) {
                    result.available = _.toString(result.balanceItem.transferable || 0)
                    result.accountBalance = _.toString(result.balanceItem.equity || 0)
                }
            }
        }
        return result
    }

    _syncAccountBalanceByTransferItems (transferItems=[]) {
        const { dispatch } = this.props
        const accounts = {}
        _.forEach(transferItems, transferItem => {
            const { originTransferAccount, destinationTransferAccount } = transferItem
            _.forEach([originTransferAccount, destinationTransferAccount], transferAccount => {
                const { accountName, accountType } = transferAccount
                if (!_.isEmpty(accountName) && !_.isNil(accountType)) {
                    if (!_.has(accounts, accountName)) {
                        accounts[accountName] = {
                            name: accountName,
                            types: []
                        }
                    }
                    if ([ACCOUNT_TYPES.WALLET.key, ACCOUNT_TYPES.OKEX_FUNDING_ACCOUNT.key].includes(accountType.key)) {
                        accounts[accountName].types.push('wallet_account_balance', 'balance')
                    } else if (accountType.key === ACCOUNT_TYPES.FTX_WALLET.key) {
                        accounts[accountName].types.push('balance')
                    } else if (accountType.key === ACCOUNT_TYPES.SPOT.key) {
                        accounts[accountName].types.push('spot_account_balance', 'balance')
                    } else if (accountType.key === ACCOUNT_TYPES.OKEX_UNIFIED_ACCOUNT.key) {
                        accounts[accountName].types.push('cross_account_balance', 'balance')
                    } else if (accountType.key === ACCOUNT_TYPES.CROSS_MARGIN.key) {
                        accounts[accountName].types.push('cross_margin_account_balance', 'balance')
                    } else if ([ACCOUNT_TYPES.FUTURE.key, ACCOUNT_TYPES.BINANCE_COIN_FUTURES.key].includes(accountType.key)) {
                        accounts[accountName].types.push('future_account_balance', 'balance')
                    } else if ([ACCOUNT_TYPES.SWAP.key, ACCOUNT_TYPES.BINANCE_USDT_FUTURES.key, ACCOUNT_TYPES.PHEMEX_BTC_CONTRACT.key].includes(accountType.key)) {
                        accounts[accountName].types.push('swap_account_balance', 'balance')
                    } 
                }
            })
        })
        if (!_.isEmpty(accounts)) {
            _.forEach(accounts, (account, accountName) => {
                accounts[accountName].types = _.uniq(account.types)
            })
            dispatch(syncAccountBalances(Object.values(accounts)))
        }
    }

    transferToken ({ token, transferItemIndex=0, transferMode=TRANSFER_MODES.TRANSFER }) {
        const { dispatch, onTransferSuccess } = this.props
        const { transferItems } = this.state
        const transferItem = transferItems[transferItemIndex]
        if (this._mounted) {
            return new Promise((resolve, reject) => {
                if (!_.isEmpty(token) && !_.isNil(transferItem) && Number(transferItem.amount) > 0) {
                    const { originTransferAccount, destinationTransferAccount, amount } = transferItem
                    let transferAction, transferParams
                    if (transferMode === TRANSFER_MODES.TRANSFER) {
                        transferAction = accountTransferFund
                        transferParams = {
                            currency: token,
                            account_name: originTransferAccount.accountName,
                            from: originTransferAccount.accountType.value,
                            instrument_id: originTransferAccount.pairName,
                            to_account_name: destinationTransferAccount.accountName,
                            to: destinationTransferAccount.accountType.value,
                            to_instrument_id: destinationTransferAccount.pairName,
                            amount: Number(amount)
                        }
                    } else if (transferMode === TRANSFER_MODES.WITHDRAW) {
                        transferAction = accountDepositFund
                        transferParams = {
                            account_name: originTransferAccount.accountName,
                            to_account_name: destinationTransferAccount.accountName,
                            currency: token.toLowerCase(),
                            amount: Number(amount)
                        }
                    }
        
                    if (transferAction && transferParams) {
                        this.setState({
                            transferItems: dotProp.set(transferItems, `${transferItemIndex}.state`, TRANSFER_STATES.TRANSFERING)
                        })
                        dispatch(transferAction(transferParams))
                        .then(response => {
                            if (this._mounted) {
                                if (!_.isNil(response) && response.status) {
                                    response.text()
                                    .then(text => {
                                        this.setState(prevState => {
                                            return {
                                                transferItems: dotProp.merge(prevState.transferItems, transferItemIndex, {
                                                    state: response.status === 200 ? TRANSFER_STATES.TRANSFERRED : TRANSFER_STATES.FAILED,
                                                    message: response.status === 200 ? `Success: ${text}` : `Fail: Response Status ${response.status}, ${text || 'No Response Message'}`
                                                })
                                            }
                                        })
                                        if (response.status === 200) {
                                            onTransferSuccess(transferItem)
                                            resolve({
                                                token,
                                                transferItem
                                            })
                                        } else {
                                            reject(new Error(text))
                                        }
                                    })
                                    .catch(error => {
                                        console.error(`TokenTransferEditor transferToken error: `, error)
                                        reject(error)
                                    })
                                } else {
                                    this.setState(prevState => {
                                        return {
                                            transferItems: dotProp.merge(prevState.transferItems, transferItemIndex, {
                                                state: TRANSFER_STATES.FAILED,
                                                message: 'Fail: No Response Status'
                                            })
                                        }
                                    })
                                    reject(new Error('Receive no response'))
                                }
                            }
                        })
                        .catch(error => {
                            console.error(`TokenTransferEditor transferToken error: `, error)
                            reject(error)
                        })
                    } else {
                        reject(new Error('Either transfer action or params is invalid'))
                    }
                } else {
                    reject(new Error('Invalid transferItem'))
                }
            })
        }
    }

    finishBulkTransfer (shouldSyncAccountBalance=false) {
        const { transferItems } = this.state
        const { onFinishBulkTransfer } = this.props
        const newTransferItems = _.cloneDeep(transferItems)
        newTransferItems.forEach(transferItem => {
            if (transferItem.state === TRANSFER_STATES.WAITING) {
                transferItem.state = TRANSFER_STATES.NULL
            }
        })
        if (this.bulkTransferInterval) {
            window.clearInterval(this.bulkTransferInterval)
        }
        this.setState({ 
            transferItems: newTransferItems,
            isBulkTransferring: false 
        })
        if (shouldSyncAccountBalance) {
            this._syncAccountBalanceByTransferItems(transferItems)
        }
        onFinishBulkTransfer()
    }

    handleChangeSingleTransferAccount ({ transferItemIndex=0, transferAccountDirection=TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN, newTransferAccount }) {
        const { transferItems, transferMode, token } = this.state
        const newTransferItems = _.cloneDeep(transferItems)
        newTransferItems[transferItemIndex][transferAccountDirection === TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN ? 'originTransferAccount' : 'destinationTransferAccount'] = newTransferAccount
        newTransferItems[transferItemIndex].state = TRANSFER_STATES.NULL
        newTransferItems[transferItemIndex].message = null

        if (transferAccountDirection === TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN) {
            const { accountName: destinationAccountName, accountType: destinationAccountType } = newTransferItems[transferItemIndex].destinationTransferAccount
            const validDestinationAccountNames = transferMode === TRANSFER_MODES.TRANSFER ? this.getCounterTransferAccountNames(newTransferAccount)
                : transferMode === TRANSFER_MODES.WITHDRAW && newTransferAccount.accountName ? _.without(this.getAccountNamesCanWithdrawToken(), newTransferAccount.accountName)
                : []
            const validDestinationAccountTypes = this.getValidAccountTypes({ tokenToTransfer: token, accountName: destinationAccountName, counterTransferAccount: newTransferAccount })

            if (!validDestinationAccountNames.includes(destinationAccountName)) {
                const newDestinationAccountName = validDestinationAccountNames.length === 1 ? validDestinationAccountNames[0] : null
                const newValidDestinationAccountTypes = this.getValidAccountTypes({ tokenToTransfer: token, accountName: newDestinationAccountName, counterTransferAccount: newTransferAccount })
                const newCounterAccountType = _.size(newValidDestinationAccountTypes) === 1 ? newValidDestinationAccountTypes[0] : null

                newTransferItems[transferItemIndex].destinationTransferAccount = TransferAccount({
                    accountName: newDestinationAccountName,
                    accountType: newCounterAccountType,
                    pairName: null
                })
            } else if (!_.isNil(destinationAccountType) && !validDestinationAccountTypes.map(accountType => accountType.key).includes(destinationAccountType.key)) {
                const newCounterAccountType = !_.isEmpty(validDestinationAccountTypes) ? _.head(validDestinationAccountTypes) : null

                newTransferItems[transferItemIndex].destinationTransferAccount = TransferAccount({
                    accountName: destinationAccountName,
                    accountType: newCounterAccountType,
                    pairName: null
                })
            }

            newTransferItems[transferItemIndex].amount = 0
            newTransferItems[transferItemIndex].amountPercentInput = ''
        }
        this.setState({
            transferItems: newTransferItems
        })
    }

    handleChangeBulkTransferAccount ({ transferAccountDirection=TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN, newTransferAccount }) {
        const { token, transferItems } = this.state
        const newTransferItems = _.cloneDeep(transferItems)

        _.remove(newTransferItems, transferItem => {
            let result = false
            if (transferAccountDirection === TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN) {
                const { accountName: destinationAccountName, accountType: destinationAccountType } = transferItem.destinationTransferAccount
                const validDestinationAccountNames = this.getCounterTransferAccountNames(newTransferAccount)
                const validDestinationAccountTypeKeys = this.getValidAccountTypes({ 
                    tokenToTransfer: token,
                    accountName: destinationAccountName,
                    counterTransferAccount: newTransferAccount
                }).map(accountType => accountType.key)
                
                result = !validDestinationAccountNames.includes(destinationAccountName) 
                    || (!_.isNil(destinationAccountType) && !validDestinationAccountTypeKeys.includes(destinationAccountType.key))
            } else if (transferAccountDirection === TRANSFER_ACCOUNT_DIRECTIONS.DESTINATION) {
                const { accountName: originAccountName, accountType: originAccountType } = transferItem.originTransferAccount
                const validOriginAccountNames = this.getCounterTransferAccountNames(newTransferAccount)
                const validOriginAccountTypeKeys = this.getValidAccountTypes({  
                    tokenToTransfer: token,
                    accountName: originAccountName,
                    counterTransferAccount: newTransferAccount
                }).map(accountType => accountType.key)
                result = !validOriginAccountNames.includes(originAccountName)
                    || (!_.isNil(originAccountType) && !validOriginAccountTypeKeys.includes(originAccountType.key))
            }
            return result
        })

        _.forEach(newTransferItems, transferItem => {
            if (transferAccountDirection === TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN) {
                transferItem.originTransferAccount = newTransferAccount
            } else if (transferAccountDirection === TRANSFER_ACCOUNT_DIRECTIONS.DESTINATION) {
                transferItem.destinationTransferAccount = newTransferAccount
            }
            transferItem.amount = 0
            transferItem.amountPercentInput = ''
            transferItem.message = null
        })

        this.setState({
            [transferAccountDirection === TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN ? 'oneToManyOriginTransferAccount' : 'manyToOneDestinationTransferAccount']: newTransferAccount,
            transferItems: newTransferItems,
            bulkTransferAmountOfEachInput: '',
            bulkTransferTotalAmountInput: '',
            bulkTransferAccountTypeKey: null,
            bulkTransferPairName: null,
            bulkTransferAccountNameSearchString: ''
        })
    }

    handleClickSingleTransferConfirmButton () {
        this.validateTransferItems()
        .then(validateResult => {
            if (validateResult) {
                const { token, transferMode, transferItems } = this.state
                this.transferToken({ 
                    token,
                    transferItemIndex: 0,
                    transferMode: transferMode
                })
                .finally(() => {
                    this._syncAccountBalanceByTransferItems(transferItems)
                })
            }
        })
    }

    handleClickBulkTransferSubmitButton () {
        const { transferMode } = this.state
        const { onStartBulkTransfer } = this.props
        this.validateTransferItems()
        .then(validateResult => {
            if (validateResult) {
                const newBulkTransferId = uuidv4()
                this.bulkTransferId = newBulkTransferId
                onStartBulkTransfer()

                const { token, transferItems } = this.state
                const newTransferItems = _.cloneDeep(transferItems)
                newTransferItems.forEach(transferItem => {
                    transferItem.state = TRANSFER_STATES.WAITING
                })
                this.setState ({ 
                    transferItems: newTransferItems,
                    bulkTransferResult: BulkTransferResult({ token, shouldShow: true }),
                    isBulkTransferring: true
                })
    
                let nextTransferItemIndex = 0
                this.transferToken({ 
                    token, 
                    transferItemIndex: nextTransferItemIndex,
                    transferMode
                })
                .then(result => {
                    if (this._mounted && this.bulkTransferId === newBulkTransferId
                        && _.has(result, 'transferItem') && !_.isEmpty(result.transferItem)) {
                        this.setState(prevState => {
                            return {
                                bulkTransferResult: dotProp.merge(prevState.bulkTransferResult, 'successfulTransferItems', result.transferItem)
                            }
                        })
                    }
                })
                .catch(() => {
                    if (this._mounted && this.bulkTransferId === newBulkTransferId) {
                        this.setState(prevState => {
                            return {
                                bulkTransferResult: dotProp.set(prevState.bulkTransferResult, 'failedTransferSize', Number(prevState.bulkTransferResult.failedTransferSize) + 1)
                            }
                        })
                    }
                })
                .finally(() => {
                    if (_.size(transferItems) === 1) {
                        this._syncAccountBalanceByTransferItems(transferItems)
                    }
                })
                nextTransferItemIndex++
    
                if (nextTransferItemIndex < transferItems.length) {
                    if (this.bulkTransferInterval) {
                        window.clearInterval(this.bulkTransferInterval)
                    }
                    this.bulkTransferInterval = setInterval(() => {
                        if (this.state.isBulkTransferring && this._mounted) {
                            const currentIndex = nextTransferItemIndex
                            this.transferToken({ 
                                token, 
                                transferItemIndex: currentIndex,
                                transferMode
                            })
                            .then(result => {
                                if (this._mounted && this.bulkTransferId === newBulkTransferId
                                    && _.has(result, 'transferItem') && !_.isEmpty(result.transferItem)) {
                                    this.setState(prevState => {
                                        return {
                                            bulkTransferResult: dotProp.merge(prevState.bulkTransferResult, 'successfulTransferItems', result.transferItem)
                                        }
                                    })
                                }
                            })
                            .catch(() => {
                                if (this._mounted && this.bulkTransferId === newBulkTransferId) {
                                    this.setState(prevState => {
                                        return {
                                            bulkTransferResult: dotProp.set(prevState.bulkTransferResult, 'failedTransferSize', Number(prevState.bulkTransferResult.failedTransferSize) + 1)
                                        }
                                    })
                                }
                            })
                            .finally(() => {
                                if (currentIndex + 1 >= transferItems.length) {
                                    this._syncAccountBalanceByTransferItems(transferItems)
                                }
                            })
                            nextTransferItemIndex++
                        }
                        if (nextTransferItemIndex >= transferItems.length) {
                            this.finishBulkTransfer()
                        }
                    }, 650)
                } else {
                    this.finishBulkTransfer()
                }
            }
        })
    }

    handleClickBulkTransferDistributeAmountButton () {
        const { transferItems, bulkTransferMode, bulkTransferTotalAmountInput } = this.state

        let newTransferItems = _.cloneDeep(transferItems)
        let remainingAmountToDistribute = toNumberWithSmartPrecision({ number: Number(bulkTransferTotalAmountInput), shouldApplyFloorValue: true })
        
        if (_.isEqual(bulkTransferMode, BULK_TRANSFER_MODES.ONE_TO_MANY)) {
            newTransferItems = _.sortBy(newTransferItems, transferItem => {
                const destinationAvailableBalance = this.getAvailableBalanceCanTransfer(transferItem.destinationTransferAccount)
                return !_.isNil(destinationAvailableBalance.accountBalance) ? Number(destinationAvailableBalance.accountBalance) : 0
            })

            let minimumDestinationAccountBalance = !_.isEmpty(newTransferItems) 
                ? Number(this.getAvailableBalanceCanTransfer(_.head(newTransferItems).destinationTransferAccount).accountBalance)
                : null
            
            for (let i = 0; i < _.size(newTransferItems); i++) {
                newTransferItems[i].amount = 0

                let nextTransferItemDestinationAccountAccountBalance = Infinity
                if (_.has(newTransferItems, i + 1)) {
                    const nextTransferItem = newTransferItems[i + 1]
                    nextTransferItemDestinationAccountAccountBalance = this.getAvailableBalanceCanTransfer(nextTransferItem.destinationTransferAccount).accountBalance
                }

                if (_.isNumber(minimumDestinationAccountBalance) && remainingAmountToDistribute > 0) {
                    const totalAmountToSplit = _.clamp((Number(nextTransferItemDestinationAccountAccountBalance) - minimumDestinationAccountBalance) * (i + 1), 0, remainingAmountToDistribute)
                    if (totalAmountToSplit > 0) {
                        const amountSplit = totalAmountToSplit / (i + 1)
                        for (let j = 0; j <= i; j++) {
                            newTransferItems[j].amount = Number(newTransferItems[j].amount) + amountSplit
                        }
                        minimumDestinationAccountBalance = Number(this.getAvailableBalanceCanTransfer(newTransferItems[i].destinationTransferAccount).accountBalance) + newTransferItems[i].amount
                        remainingAmountToDistribute = toNumberWithSmartPrecision({ number: Number(remainingAmountToDistribute) - totalAmountToSplit, shouldApplyFloorValue: true }) 
                    }
                }
            }

            _.forEach(newTransferItems, transferItem => {
                transferItem.amount = toNumberWithSmartPrecision({ number: transferItem.amount, shouldApplyFloorValue: true })
            })
        } else if (_.isEqual(bulkTransferMode, BULK_TRANSFER_MODES.MANY_TO_ONE)) {
            newTransferItems = _.sortBy(newTransferItems, transferItem => {
                const originAvailableBalance = this.getAvailableBalanceCanTransfer(transferItem.originTransferAccount)
                return !_.isNil(originAvailableBalance.accountBalance) ? Number(originAvailableBalance.accountBalance) : 0
            })
            _.reverse(newTransferItems)
            newTransferItems.forEach(transferItem => transferItem.amount = 0)

            let stopIndex = 0, totalAmountCanCollect = 0
            while (stopIndex < _.size(newTransferItems) && totalAmountCanCollect < remainingAmountToDistribute) {
                let nextTransferItemOriginAccountAccountBalance = 0
                if (_.has(newTransferItems, stopIndex + 1)) {
                    const nextTransferItem = newTransferItems[stopIndex + 1]
                    nextTransferItemOriginAccountAccountBalance = Number(this.getAvailableBalanceCanTransfer(nextTransferItem.originTransferAccount).accountBalance)
                }

                totalAmountCanCollect = 0
                for (let i = 0; i <= stopIndex; i++) {
                    const transferItem = newTransferItems[i]
                    const { accountBalance, available } = this.getAvailableBalanceCanTransfer(transferItem.originTransferAccount)
                    totalAmountCanCollect += _.clamp(Number(accountBalance) - Number(nextTransferItemOriginAccountAccountBalance), 0, Number(available))
                }
                stopIndex++
            }

            let transferItemCandidateIndex = _.range(stopIndex)

            while (!_.isEmpty(transferItemCandidateIndex) && remainingAmountToDistribute > 0) {
                const pickedCandidateIndex = []
                const averageBalanceAfterDistribution = (_.sumBy(transferItemCandidateIndex, index => {
                    const { accountBalance } = this.getAvailableBalanceCanTransfer(newTransferItems[index].originTransferAccount)
                    return Number(accountBalance)
                }) - remainingAmountToDistribute) / _.size(transferItemCandidateIndex)

                _.forEach(transferItemCandidateIndex, index => {
                    const { available, accountBalance } = this.getAvailableBalanceCanTransfer(newTransferItems[index].originTransferAccount)
                    if (Number(accountBalance) - Number(available) >= averageBalanceAfterDistribution) {
                        newTransferItems[index].amount = Math.max(Number(available), 0)
                        remainingAmountToDistribute = remainingAmountToDistribute - newTransferItems[index].amount
                        pickedCandidateIndex.push(index)
                    }
                })

                if (!_.isEmpty(pickedCandidateIndex)) {
                    _.pull(transferItemCandidateIndex, ...pickedCandidateIndex)
                } else {
                    break
                }
            }

            if (!_.isEmpty(transferItemCandidateIndex) && remainingAmountToDistribute > 0) {
                const averageBalanceAfterDistribution = (_.sumBy(transferItemCandidateIndex, index => {
                    const { accountBalance } = this.getAvailableBalanceCanTransfer(newTransferItems[index].originTransferAccount)
                    return Number(accountBalance)
                }) - remainingAmountToDistribute) / _.size(transferItemCandidateIndex)

                _.forEach(transferItemCandidateIndex, index => {
                    const { accountBalance, available } = this.getAvailableBalanceCanTransfer(newTransferItems[index].originTransferAccount)
                    newTransferItems[index].amount = _.clamp(toNumberWithSmartPrecision({ number: Number(accountBalance) - averageBalanceAfterDistribution, shouldApplyFloorValue: true }), 0, Number(available))
                })
            }
        }

        _.forEach(newTransferItems, transferItem => {
            const { available } = this.getAvailableBalanceCanTransfer(transferItem.originTransferAccount)
            transferItem.amountPercentInput = Number(available) > 0 ? (Number(transferItem.amount) / Number(available) * 100).toFixed(2) : 0
        })

        this.setState({ transferItems: newTransferItems })
    }

    Token () {
        const { token, transferItems, isBulkTransferring, operationMode, bulkTransferMode, oneToManyOriginTransferAccount, manyToOneDestinationTransferAccount } = this.state
        const { tokens } = this.props
        const tokenOptions = tokens.map(token => {
            return {
                name: token,
                value: token
            }
        })

        const isTransferAccountHavingInvalidPairName = (tokenToTransfer='BTC', transferAccount=TransferAccount({})) => {
            const { pairName } = transferAccount
            return !_.isEmpty(pairName) 
                && !this.getTransferAccountValidPairNames(tokenToTransfer, transferAccount).includes(pairName)
        }

        const getAdjustedAccountType = ({ tokenToTransfer='BTC', originalTransferAccount=TransferAccount({}) }) => {
            const { accountName: originalAccountName, accountType: originalAccountType } = originalTransferAccount
            const validAccountTypes = this.getValidAccountTypes({ tokenToTransfer, accountName: originalAccountName })
            return !_.isNil(originalAccountType) && _.some(validAccountTypes, validAccountType => validAccountType.key === originalAccountType.key) ? originalAccountType
                : !_.isEmpty(validAccountTypes) ? _.head(validAccountTypes)
                : null
        }

        return (
            <SearchSelect 
                className='token-transfer-editor--token'
                placeholder={'Select Token'}
                value={token}
                options={tokenOptions} 
                disabled={isBulkTransferring}
                onChange={(newOption) => { 
                    const newToken = newOption.value
                    if (newToken !== token) {
                        const newTransferItems = _.cloneDeep(transferItems)
                        const newState = {
                            token: newToken
                        }
                        newTransferItems.forEach(transferItem => {
                            const { originTransferAccount, destinationTransferAccount } = transferItem
                            transferItem.originTransferAccount.accountType = getAdjustedAccountType({ tokenToTransfer: newToken, originalTransferAccount: originTransferAccount })
                            transferItem.destinationTransferAccount.accountType = getAdjustedAccountType({ tokenToTransfer: newToken, originalTransferAccount: destinationTransferAccount })

                            if (isTransferAccountHavingInvalidPairName(newToken, transferItem.originTransferAccount)) {
                                transferItem.originTransferAccount.pairName = null
                            }
                            if (isTransferAccountHavingInvalidPairName(newToken, transferItem.destinationTransferAccount)) {
                                transferItem.destinationTransferAccount.pairName = null
                            }
                            transferItem.amount = 0
                            transferItem.amountPercentInput = ''
                            transferItem.message = null
                        })
                        newState.transferItems = newTransferItems
                        if (operationMode === OPERATION_MODES.BULK) {
                            if (_.isEqual(bulkTransferMode, BULK_TRANSFER_MODES.ONE_TO_MANY)) {
                                const newAccountType = getAdjustedAccountType({ tokenToTransfer: newToken, originalTransferAccount: oneToManyOriginTransferAccount })
                                newState.oneToManyOriginTransferAccount = dotProp.set(oneToManyOriginTransferAccount, 'accountType', newAccountType)
                                if (isTransferAccountHavingInvalidPairName(newToken, newState.oneToManyOriginTransferAccount)) {
                                    newState.oneToManyOriginTransferAccount.pairName = null
                                }
                            } else if (_.isEqual(bulkTransferMode, BULK_TRANSFER_MODES.MANY_TO_ONE)) {
                                const newAccountType = getAdjustedAccountType({ tokenToTransfer: newToken, originalTransferAccount: manyToOneDestinationTransferAccount })
                                newState.manyToOneDestinationTransferAccount = dotProp.set(manyToOneDestinationTransferAccount, 'accountType', newAccountType)
                                if (isTransferAccountHavingInvalidPairName(newToken, newState.manyToOneDestinationTransferAccount)) {
                                    newState.manyToOneDestinationTransferAccount.pairName = null
                                }
                            }
                        }
                        this.setState(newState) 
                    }
                }} />
        )
    }

    TransferAccountSwap ({ transferItemIndex=0 }) {
        const { transferItems } = this.state
        const transferItem = transferItems[transferItemIndex]
        return transferItem ? (
            <button className='token-transfer-editor--transfer-account-swap' onClick={() => {
                const { originTransferAccount, destinationTransferAccount } = transferItem
                const newTransferItems = dotProp.merge(transferItems, transferItemIndex, {
                    originTransferAccount: destinationTransferAccount,
                    destinationTransferAccount: originTransferAccount,
                    amount: 0,
                    amountPercentInput: '',
                    state: TRANSFER_STATES.NULL,
                    message: null
                })
                this.setState({ transferItems: newTransferItems })
            }}><AiOutlineSwap /></button>
        ) : null
    }

    TransferAccount ({ transferAccount=TransferAccount({}), counterTransferAccount, accountNameOptions=[], disabled=false, shouldHighlightInvalidValue=false, canClickAvailableBalance=false, onChange=()=>{}, onClickAvailableBalance=()=>{} }) {
        const { token } = this.state
        const { accountName, accountType, pairName } = transferAccount
        const validAccountTypes = this.getValidAccountTypes({ tokenToTransfer: token, accountName, counterTransferAccount })
        const accountTypeOptions = validAccountTypes.map(accountType => {
            return {
                value: accountType.key,
                name: accountType.name
            }
        })
        const shouldHavePairName = this.shouldTransferAccountHavePairName(token, transferAccount)
        const pairNameOptions = this.getTransferAccountValidPairNames(token, transferAccount).map(pairName => {
            return {
                value: pairName,
                name: pairName.replace('-', '/').toUpperCase()
            }
        })
        const { balanceItem, available, accountBalance, assetBalance } = this.getAvailableBalanceCanTransfer(transferAccount)

        return (
            <div className='token-transfer-editor--transfer-account'>
                <div className='token-transfer-editor--transfer-account--main'>
                    <SearchSelect className='token-transfer-editor--transfer-account--name'
                        placeholder={'Select Account'}
                        value={accountName}
                        options={accountNameOptions}
                        disabled={disabled || _.isEmpty(accountNameOptions)}
                        shouldHighlightInvalidValue={shouldHighlightInvalidValue}
                        onChange={(newOption) => {
                            const newAccountName = newOption.value
                            const newTransferAccount = dotProp.set(transferAccount, 'accountName', newAccountName)
                            const newValidAccountTypes = this.getValidAccountTypes({ tokenToTransfer: token, accountName: newAccountName, counterTransferAccount })
                            newTransferAccount.accountType = !_.isNil(newTransferAccount.accountType) && _.some(newValidAccountTypes, validAccountType => validAccountType.key === newTransferAccount.accountType.key) ? newTransferAccount.accountType
                                : !_.isEmpty(newValidAccountTypes) ? _.head(newValidAccountTypes)
                                : null
                            const newValidPairNames = this.getTransferAccountValidPairNames(token, newTransferAccount)
                            newTransferAccount.pairName = !_.isNil(newTransferAccount.pairName) && newValidPairNames.includes(newTransferAccount.pairName)
                                ? newTransferAccount.pairName
                                : null
                            onChange(newTransferAccount)
                        }} />
                    <SearchSelect className={'token-transfer-editor--transfer-account--type' + (accountType ? ` ${accountType.key}` : '')}
                        placeholder={'Select Type'}
                        value={accountType ? accountType.key : null}
                        options={accountTypeOptions}
                        disabled={disabled || _.isEmpty(accountTypeOptions)}
                        shouldHighlightInvalidValue={shouldHighlightInvalidValue}
                        onChange={(newOption) => {
                            const newAccountType = _.find(validAccountTypes, validAccountType => validAccountType.key === newOption.value)
                            const newTransferAccount = dotProp.set(transferAccount, 'accountType', newAccountType)
                            const newValidPairNames = this.getTransferAccountValidPairNames(token, newTransferAccount)
                            newTransferAccount.pairName = !_.isNil(newTransferAccount.pairName) && newValidPairNames.includes(newTransferAccount.pairName)
                                ? newTransferAccount.pairName
                                : null
                            onChange(newTransferAccount)
                        }} />
                    {(shouldHavePairName || !_.isEmpty(pairName)) && <SearchSelect className='token-transfer-editor--transfer-account--pair-name' 
                        placeholder={'Select Pair'}
                        value={pairName}
                        options={pairNameOptions} 
                        disabled={disabled || _.isEmpty(pairNameOptions)}
                        shouldHighlightInvalidValue={shouldHighlightInvalidValue}
                        onChange={(newOption) => {
                            const newTransferAccount = dotProp.set(transferAccount, 'pairName', newOption.value)
                            onChange(newTransferAccount)
                        }} />}
                </div>
                <Popup
                    className='token-transfer-editor--account-balance-popup'
                    on={'hover'}
                    disabled={_.isNil(balanceItem) || !_.has(accountType, 'key')}
                    trigger={<div className='token-transfer-editor--transfer-account--available'>
                        <div className='token-transfer-editor--transfer-account--available--item'>
                            <label>{'Available'}</label>
                            <span className={canClickAvailableBalance && !_.isNil(available) ? 'clickable' : null}
                                disabled={disabled}
                                onClick={() => {
                                    if (canClickAvailableBalance && !_.isNil(available) && !disabled) {
                                        onClickAvailableBalance(available)
                                    }
                                }}>
                                    {`${!_.isNil(available) && !_.isEmpty(_.toString(available)) ? toNumberWithSmartPrecision({ number: available, defaultPrecision: Math.abs(Number(available)) >= 1000 ? 0 : 2, shouldReturnLocalString: true, shouldApplyFloorValue: true }) : 'N/A'}`}
                                </span>
                        </div>
                        <div className='token-transfer-editor--transfer-account--available--item'>
                            <label>{'Acct. Balance'}</label>
                            <span>{!_.isNil(accountBalance) ? toNumberWithSmartPrecision({ number: accountBalance, defaultPrecision: Math.abs(Number(accountBalance)) >= 1000 ? 0 : 2, shouldReturnLocalString: true, shouldApplyFloorValue: true }) : 'N/A'}</span>
                        </div>
                        <div className='token-transfer-editor--transfer-account--available--item'>
                            <label>{'Total Balance'}</label>
                            <span>{!_.isNil(assetBalance) ? toNumberWithSmartPrecision({ number: assetBalance, defaultPrecision: Math.abs(Number(assetBalance)) >= 1000 ? 0 : 2, shouldReturnLocalString: true, shouldApplyFloorValue: true }) : 'N/A'}</span>
                        </div>
                        <div className='token-transfer-editor--transfer-account--available--item'>
                            <label>{'Timestamp'}</label>
                            <span> {_.has(balanceItem, 'timestamp') || _.has(balanceItem, 'ts') || _.has(balanceItem, 'update_time') ? moment(balanceItem.timestamp || balanceItem.ts || balanceItem.update_time).format('HH:mm:ss') : 'N/A'}</span>
                        </div>
                    </div>}>
                    {!_.isNil(balanceItem) && _.has(accountType, 'key') && <Fragment>
                        {accountType.key === ACCOUNT_TYPES.SPOT.key ? <SpotAccountBalanceItem 
                            spotAccountBalance={balanceItem} 
                            shouldShowAccountTypeTag
                            shouldShowAccountName
                            shouldShowDetail />
                        : accountType.key === ACCOUNT_TYPES.MARGIN.key ? <MarginAccountBalanceItem
                            marginAccountBalance={balanceItem} 
                            shouldShowAccountTypeTag
                            shouldShowAccountName
                            shouldShowDetail 
                            shouldHideBorrowRepay />
                        : accountType.key === ACCOUNT_TYPES.CROSS_MARGIN.key ? <CrossMarginAccountBalanceItem 
                            crossMarginAccountBalance={balanceItem}
                            shouldShowAccountTypeTag
                            shouldShowAccountName
                            shouldShowDetail 
                            tokenLendingEnabled={false} />
                        : accountType.key === ACCOUNT_TYPES.OKEX_UNIFIED_ACCOUNT.key ? <CrossAccountBalanceItem 
                            crossAccountBalance={balanceItem}
                            shouldShowAccountTypeTag
                            shouldShowAccountName
                            shouldShowDetail />
                        : [ACCOUNT_TYPES.FUTURE.key, ACCOUNT_TYPES.BINANCE_COIN_FUTURES.key].includes(accountType.key) ? <FutureAccountBalanceItem 
                            futureAccountBalance={balanceItem} 
                            shouldShowAccountTypeTag
                            shouldShowAccountName
                            shouldShowDetail />
                        : ([ACCOUNT_TYPES.SWAP.key, ACCOUNT_TYPES.BINANCE_USDT_FUTURES.key, ACCOUNT_TYPES.PHEMEX_BTC_CONTRACT.key, ACCOUNT_TYPES.CRYPTOFUT_DERIVATIVES.key]).includes(accountType.key) ? <SwapAccountBalanceItem 
                            swapAccountBalance={balanceItem} 
                            shouldShowAccountTypeTag
                            shouldShowAccountName
                            shouldShowDetail />
                        : [ACCOUNT_TYPES.WALLET.key, ACCOUNT_TYPES.OKEX_FUNDING_ACCOUNT.key].includes(accountType.key) ? <WalletAccounBalanceItem 
                            walletAccountBalance={balanceItem}
                            shouldShowAccountTypeTag
                            shouldShowAccountName
                            shouldShowDetail />
                        : accountType.key === ACCOUNT_TYPES.FTX_WALLET.key ? <AccountAssetItem 
                            accountAssetItem={balanceItem} />
                        : null}
                    </Fragment>}
                </Popup>
            </div>
        )
    }

    Amount ({ transferItemIndex=0 }) {
        const { transferItems, isBulkTransferring } = this.state
        const { shouldAutoFocusAmountInput } = this.props
        const transferItem = transferItems[transferItemIndex]
        if (transferItem) {
            const { originTransferAccount, amount, amountPercentInput } = transferItem
            const { available } = this.getAvailableBalanceCanTransfer(originTransferAccount)
            const hasValidAvaiableBalance = !_.isNil(available) && !_.isEmpty(available.toString()) && Number(available) >= 0
            return (
                <div className='token-transfer-editor--amount clearfix'>
                    <input className='token-transfer-editor--amount--value-input' 
                        ref={(node) => { this.amountInputNode = node }}
                        type={'number'}
                        autoFocus={shouldAutoFocusAmountInput}
                        min={0}
                        value={amount}
                        disabled={isBulkTransferring}
                        onChange={(e) => { 
                            const newAmount = toNumberInputValue(e.target.value)
                            const newAmountPercentInput = _.isNumber(newAmount) && hasValidAvaiableBalance ? toNumberWithSmartPrecision({ number: _.clamp(newAmount/Number(available), 0, 1) * 100 }) : ''
                            const newTransferItems = dotProp.merge(transferItems, transferItemIndex, {
                                amount: newAmount,
                                amountPercentInput: newAmountPercentInput,
                                state: TRANSFER_STATES.NULL,
                                message: null
                            })
                            this.setState({ transferItems: newTransferItems })
                        }} />
                    <div className='token-transfer-editor--amount--percent'>
                        <input className='token-transfer-editor--amount--percent-input' 
                            type={hasValidAvaiableBalance ? 'number' : 'text'}
                            min={0}
                            max={100}
                            value={hasValidAvaiableBalance ? amountPercentInput : 'N/A'}
                            disabled={!hasValidAvaiableBalance || isBulkTransferring} 
                            onChange={(e) => {
                                const newAmountPercentInput = toNumberInputValue(e.target.value)
                                const newAmount = _.isNumber(newAmountPercentInput) ? BigNumber(available).times(_.clamp(newAmountPercentInput, 0, 100)).div(100).toString() : 0
                                const newTransferItems = dotProp.merge(transferItems, transferItemIndex, {
                                    amount: newAmount,
                                    amountPercentInput: newAmountPercentInput,
                                    state: TRANSFER_STATES.NULL,
                                    message: null
                                })
                                this.setState({ transferItems: newTransferItems })
                            }} />
                            <span>{'%'}</span>
                    </div>
                </div>
            )
        } else {
            return null
        }
    } 

    Row (label='', component) {
        return (
            <div className='token-transfer-editor--row'>
                {!_.isEmpty(label) && <div className='token-transfer-editor--row--label'>{label}</div>}
                <div className='token-transfer-editor--row--component'>{component}</div>
            </div>
        )
    }

    SingleTransfer () {
        const { transferItems, transferMode } = this.state
        const { accountItems, validSingleTransferOriginAccountNames } = this.props
        const transferItem = transferItems[0]
        if (transferItem) {
            const { originTransferAccount, destinationTransferAccount, state, message } = transferItem
            let originAccountNameOptions=[], destinationAccountNameOptions=[]
            if (transferMode === TRANSFER_MODES.TRANSFER) {
                originAccountNameOptions = _.isEmpty(validSingleTransferOriginAccountNames) ? _.map(accountItems, accountItem => {
                    return {
                        value: accountItem.account_name,
                        name: accountItem.account_name
                    }
                }) : validSingleTransferOriginAccountNames.map(accountName => {
                    return {
                        value: accountName,
                        name: accountName
                    }
                })
                destinationAccountNameOptions = this.getCounterTransferAccountNames(originTransferAccount).map(accountName => {
                    return {
                        value: accountName,
                        name: accountName
                    }
                })
            } else if (transferMode === TRANSFER_MODES.WITHDRAW) {
                const accountNamesCanWithdrawToken = this.getAccountNamesCanWithdrawToken()
                originAccountNameOptions = accountNamesCanWithdrawToken.map(accountName => {
                    return {
                        value: accountName,
                        name: accountName
                    }
                })
                destinationAccountNameOptions = !_.isNil(originTransferAccount.accountName) 
                    ? _.without(accountNamesCanWithdrawToken, originTransferAccount.accountName).map(accountName => {
                        return {
                            value: accountName,
                            name: accountName
                        }
                    }) : []
            }
            return (
                <div className='token-transfer-editor--single-transfer'>
                    <div className='token-transfer-editor--single-transfer--main'>
                        {this.Row('Token', this.Token())}
                        {this.Row('From', this.TransferAccount({
                            transferAccount: originTransferAccount,
                            accountNameOptions: originAccountNameOptions,
                            canClickAvailableBalance: true,
                            onChange: (newTransferAccount) => { this.handleChangeSingleTransferAccount({ transferItemIndex: 0, transferAccountDirection: TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN, newTransferAccount }) },
                            onClickAvailableBalance: (available) => { 
                                const newTransferItems = dotProp.merge(transferItems, '0', {
                                    amount: available,
                                    amountPercentInput: 100,
                                    state: TRANSFER_STATES.NULL,
                                    message: null
                                })
                                this.setState({ 
                                    transferItems: newTransferItems
                                })
                            }
                        }))}
                        {this.TransferAccountSwap({ transferItemIndex: 0 })}
                        {this.Row('To', this.TransferAccount({
                            transferAccount: destinationTransferAccount,
                            accountNameOptions: destinationAccountNameOptions,
                            counterTransferAccount: originTransferAccount,
                            shouldHighlightInvalidValue: true,
                            onChange: (newTransferAccount) => { this.handleChangeSingleTransferAccount({ transferItemIndex: 0, transferAccountDirection: TRANSFER_ACCOUNT_DIRECTIONS.DESTINATION, newTransferAccount })  }
                        }))}
                        {this.Row('Amount', this.Amount({ transferItemIndex: 0 }))}
                    </div>      
                    {!_.isEmpty(message) && <div className='token-transfer-editor--single-transfer--message'>{message}</div>}     
                    <div className='token-transfer-editor--single-transfer--buttons'>
                        <button className='token-transfer-editor--single-transfer--reset-button' onClick={() => {
                            this.setState({
                                token: 'BTC',
                                transferItems: [TransferItem({})]
                            })
                        }}>{'RESET'}</button>
                        <SaveButton className={`token-transfer-editor--single-transfer--confirm-button ${transferMode}`}
                            isSaving={state === TRANSFER_STATES.TRANSFERING}
                            text={transferMode}
                            isSavingText={'TRANSFERING'} 
                            disabled={transferMode === TRANSFER_MODES.WITHDRAW || state === TRANSFER_STATES.TRANSFERING}
                            onClick={() => { this.handleClickSingleTransferConfirmButton() }} /> 
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    BulkTransferTabs () {
        const { bulkTransferMode, isBulkTransferring, transferItems, oneToManyOriginTransferAccount, manyToOneDestinationTransferAccount, 
            bulkTransferAccountTypeKey, bulkTransferPairName, bulkTransferAccountNameSearchString, positionFilterSymbolName } = this.state
        return (
            <div className='token-transfer-editor--bulk-transfer--tabs'>
                {_.map(BULK_TRANSFER_MODES, (mode, key) => {
                    return (
                        <button className={'token-transfer-editor--bulk-transfer--tab' + (bulkTransferMode.key === key ? ' active' : '')} 
                            key={key}
                            disabled={isBulkTransferring}
                            onClick={() => { 
                                if (bulkTransferMode.key !== key) {
                                    let newTransferItems = [TransferItem({})], newOneToManyOriginTransferAccount = TransferAccount({}), newManyToOneDestinationTransferAccount = TransferAccount({})
                                    if (_.isEqual(bulkTransferMode, BULK_TRANSFER_MODES.ONE_TO_MANY) && key === BULK_TRANSFER_MODES.MANY_TO_ONE.key && !_.isEmpty(transferItems)) {
                                        newTransferItems = _.cloneDeep(transferItems)
                                        _.forEach(newTransferItems, (transferItem, index) => {
                                            const { originTransferAccount, destinationTransferAccount } = transferItem
                                            newTransferItems[index] = TransferItem({
                                                originTransferAccount: destinationTransferAccount,
                                                destinationTransferAccount: originTransferAccount
                                            })
                                        })
                                        newManyToOneDestinationTransferAccount = oneToManyOriginTransferAccount
                                    } else if (_.isEqual(bulkTransferMode, BULK_TRANSFER_MODES.MANY_TO_ONE) && key === BULK_TRANSFER_MODES.ONE_TO_MANY.key && !_.isEmpty(transferItems)) {
                                        newTransferItems = _.cloneDeep(transferItems)
                                        _.forEach(newTransferItems, (transferItem, index) => {
                                            const { originTransferAccount, destinationTransferAccount } = transferItem
                                            newTransferItems[index] = TransferItem({
                                                originTransferAccount: destinationTransferAccount,
                                                destinationTransferAccount: originTransferAccount
                                            })
                                        })
                                        newOneToManyOriginTransferAccount = manyToOneDestinationTransferAccount
                                    }
                                    this.setState({
                                        bulkTransferMode: mode,
                                        transferItems: newTransferItems,
                                        oneToManyOriginTransferAccount: newOneToManyOriginTransferAccount,
                                        manyToOneDestinationTransferAccount: newManyToOneDestinationTransferAccount,
                                        bulkTransferAmountOfEachInput: '',
                                        bulkTransferTotalAmountInput: '',
                                        bulkTransferAccountTypeKey: key === BULK_TRANSFER_MODES.ONE_TO_ONE.key ? null : bulkTransferAccountTypeKey,
                                        bulkTransferPairName: key === BULK_TRANSFER_MODES.ONE_TO_ONE.key ? null : bulkTransferPairName,
                                        bulkTransferAccountNameSearchString: key === BULK_TRANSFER_MODES.ONE_TO_ONE.key ? '' : bulkTransferAccountNameSearchString,
                                        positionFilterSymbolName: key === BULK_TRANSFER_MODES.ONE_TO_ONE.key ? null : positionFilterSymbolName,
                                    })
                                }
                            }}>
                            {mode.name}
                        </button>
                    )
                })}
            </div>
        )
    }

    BulkTransferSummary () {
        const { transferItems, bulkTransferResult } = this.state
        const pendingSize = _.size(_.filter(transferItems, transferItem => [TRANSFER_STATES.WAITING, TRANSFER_STATES.TRANSFERING].includes(transferItem.state)))
        const totalAmount = _.sumBy(bulkTransferResult.successfulTransferItems || [], transferItem => { return Number(transferItem.amount) })

        return (
            <div className='token-transfer-editor--bulk-transfer--summary'>
                <div className='token-transfer-editor--bulk-transfer--summary--title'>{'Summary:'}</div>
                <div className='token-transfer-editor--bulk-transfer--summary--main'>
                    <span className={pendingSize > 0 ? 'warning-yellow' : null}>{`Pending: ${pendingSize}`}</span>
                    <span>{`Success: ${_.size(bulkTransferResult.successfulTransferItems || [])} (Total Transferred Amount: ${toNumberWithSmartPrecision({ number: totalAmount, shouldApplyFloorValue: true, defaultPrecision: 2, shouldReturnLocalString: true })} ${bulkTransferResult.token})`}</span>
                    <span className={Number(bulkTransferResult.failedTransferSize) > 0 ? 'warning-red' : null}>{`Failed: ${bulkTransferResult.failedTransferSize}`}</span>
                </div>
                <button className='token-transfer-editor--bulk-transfer--summary--close-button'
                    onClick={() => {
                        this.setState({
                            bulkTransferResult: dotProp.set(bulkTransferResult, 'shouldShow', false)
                        })
                    }}><MdCancel /></button>
            </div>
        )
    }

    BulkTransfer () {
        const { accountItems, symbolItems, positions, pricings, riskRatioThresholds } = this.props
        const { token, bulkTransferMode, oneToManyOriginTransferAccount, manyToOneDestinationTransferAccount, 
            transferItems, isBulkTransferring, bulkTransferAmountOfEachInput, bulkTransferTotalAmountInput, bulkTransferAccountTypeKey, bulkTransferPairName,
            bulkTransferAccountNameSearchString, bulkTransferMessage, positionFilterSymbolName, bulkTransferResult } = this.state
        const allAccountNameOptions = _.map(accountItems, accountItem => { 
            return { 
                value: accountItem.account_name, 
                name: accountItem.account_name 
            } 
        })

        let originAccountNameOptions = [], destinationAccountNameOptions = [], positionFilterSymbolNameOptions = []
        
        if (_.has(bulkTransferMode, 'key') && [BULK_TRANSFER_MODES.ONE_TO_MANY.key, BULK_TRANSFER_MODES.MANY_TO_ONE.key].includes(bulkTransferMode.key)) {
            if (bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key) {
                destinationAccountNameOptions = this.getCounterTransferAccountNames(oneToManyOriginTransferAccount).map(accountName => {
                    return {
                        value: accountName,
                        name: accountName
                    }
                })
            } else {
                originAccountNameOptions = this.getCounterTransferAccountNames(manyToOneDestinationTransferAccount).map(accountName => {
                    return {
                        value: accountName,
                        name: accountName
                    }
                })
            }
            let positionFilterExchangeNames = _.reduce(transferItems, (result, transferItem) => {
                const { originTransferAccount, destinationTransferAccount } = transferItem
                if (_.has(originTransferAccount, 'accountName')) {
                    const originTransferAccountItem = accountItems[originTransferAccount.accountName]
                    if (_.has(originTransferAccountItem, 'exchange_name')) {
                        result.push(originTransferAccountItem.exchange_name)
                    }
                }
                if (_.has(destinationTransferAccount, 'accountName')) {
                    const destinationTransferAccountItem = accountItems[destinationTransferAccount.accountName]
                    if (_.has(destinationTransferAccountItem, 'exchange_name')) {
                        result.push(destinationTransferAccountItem.exchange_name)
                    }
                }
                return result
            }, [])

            positionFilterExchangeNames = _.compact(_.uniq(positionFilterExchangeNames))
            positionFilterSymbolNameOptions = _.reduce(symbolItems, (result, symbolItem) => {
                const { exchangeName } = getSymbolAttributeByName(symbolItem.symbol_name)
                if (positionFilterExchangeNames.includes(exchangeName)) {
                    result.push({
                        value: symbolItem.symbol_name,
                        name: symbolItem.symbol_name
                    })
                }
                return result
            }, [])
        }

        const BulkTransferAmountOfEachInput = () => {
            return (
                <input className='token-transfer-editor--bulk-transfer--bulk-transfer-amount-of-each-input' 
                    ref={(node) => { this.amountOfEachTransferInputNode = node }}
                    placeholder={'0.0'}
                    type={'number'}
                    min={0}
                    value={bulkTransferAmountOfEachInput} 
                    disabled={isBulkTransferring}
                    onChange={(e) => {
                        const newAmount = toNumberInputValue(e.target.value)
                        const newTransferItems = _.cloneDeep(transferItems)
                        _.forEach(newTransferItems, transferItem => {
                            const { originTransferAccount } = transferItem
                            const { available } = this.getAvailableBalanceCanTransfer(originTransferAccount)
                            const hasValidAvaiableBalance = !_.isNil(available) && !_.isEmpty(available.toString()) && Number(available) >= 0
                            const newAmountPercentInput = _.isNumber(newAmount) && hasValidAvaiableBalance ? toNumberWithSmartPrecision({ number: _.clamp(newAmount/Number(available), 0, 1) * 100 }) : ''
                            transferItem.amount = newAmount
                            transferItem.amountPercentInput = newAmountPercentInput
                            transferItem.message = null
                        })
                        this.setState({
                            bulkTransferAmountOfEachInput: newAmount,
                            bulkTransferTotalAmountInput: '',
                            transferItems: newTransferItems
                        })
                    }} />
            )
        }

        const BulkTransferTotalAmountInput = () => {
            let maxAvailableBalance = null
            if (_.isEqual(bulkTransferMode, BULK_TRANSFER_MODES.ONE_TO_MANY)) {
                const oneToManyOriginAccountAvailableBalance = this.getAvailableBalanceCanTransfer(oneToManyOriginTransferAccount)
                maxAvailableBalance = Number(oneToManyOriginAccountAvailableBalance.available)
            } else if (_.isEqual(bulkTransferMode, BULK_TRANSFER_MODES.MANY_TO_ONE)) {
                maxAvailableBalance = _.sumBy(transferItems, transferItem => {
                    const originAccountAvailableBalance = this.getAvailableBalanceCanTransfer(transferItem.originTransferAccount)
                    return Number(originAccountAvailableBalance.available)
                })
            }
            return (
                <div className='token-transfer-editor--bulk-transfer--bulk-transfer-total-amount'>
                    <input
                        className='token-transfer-editor--bulk-transfer--bulk-transfer-total-amount-input'
                        placeholder={'0.0'}
                        type={'number'}
                        min={0}
                        value={bulkTransferTotalAmountInput} 
                        disabled={isBulkTransferring}
                        onChange={(e) => {
                            this.setState({
                                bulkTransferAmountOfEachInput: '',
                                bulkTransferTotalAmountInput: toNumberInputValue(e.target.value)
                            })
                        }} />
                    <button onClick={() => {
                        this.handleClickBulkTransferDistributeAmountButton()
                    }}>{'Auto Distribute'}</button>
                    <br />
                    <div className='token-transfer-editor--bulk-transfer--bulk-transfer-total-amount--max-available'>{`Available: ${toNumberWithSmartPrecision({ number: maxAvailableBalance, shouldApplyFloorValue: true, shouldReturnLocalString: true })}`}</div>
                </div>
            )
        }

        const AccountSelectionPopup = () => {
            const accountNameOptions = bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? destinationAccountNameOptions : originAccountNameOptions
            const selectedAccountNames = _.uniq(_.compact(transferItems.map(transferItem => {
                return bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key
                    ? transferItem.destinationTransferAccount.accountName
                    : transferItem.originTransferAccount.accountName
            })))
            const filteredAccountNames = _.filter(accountNameOptions, accountNameOption => 
                isMetSearchStringCriteria(accountNameOption.value, bulkTransferAccountNameSearchString))
                .map(accountNameOption => accountNameOption.name)
            const filteredSelectedAccountNames = _.filter(selectedAccountNames, accountName => isMetSearchStringCriteria(accountName, bulkTransferAccountNameSearchString))
            const isEveryFilteredAccountSelected = _.every(filteredAccountNames, accountName => filteredSelectedAccountNames.includes(accountName))
            const accountNameGroups = _.groupBy(filteredAccountNames, accountName => {
                return _.has(accountItems, `${accountName}.portfolio_name`) ? accountItems[accountName].portfolio_name : 'Unkown Portfolio'
            })

            const _updateTransferItemsAndClearBulkTransferInput = (newTransferItems=[]) => {
                _.remove(newTransferItems, transferItem => _.isNil(transferItem.originTransferAccount) 
                    || _.isNil(transferItem.originTransferAccount.accountName)
                    || _.isNil(transferItem.destinationTransferAccount)
                    || _.isNil(transferItem.destinationTransferAccount.accountName))

                this.setState({ 
                    transferItems: newTransferItems,
                    bulkTransferAccountTypeKey: null,
                    bulkTransferPairName: null,
                    bulkTransferAmountOfEachInput: '',
                    bulkTransferTotalAmountInput: ''
                }, () => {
                    if (this.bulkTransferBodyNode) {
                        this.bulkTransferBodyNode.scrollTop = this.bulkTransferBodyNode.scrollHeight
                    }
                })
            }

            return (
                <Popup className='token-transfer-editor--bulk-transfer--account-selection-popup'
                    on={'click'}
                    align={'horizontal'}
                    shouldForceLeftBiased
                    trigger={<button className='token-transfer-editor--bulk-transfer--account-selection-popup--trigger'>{`${_.size(selectedAccountNames)}/${_.size(accountNameOptions)} Selected`}</button>}
                    onOpen={() => { 
                        this.setState({ bulkTransferAccountNameSearchString: '' }) 
                        if (this.bulkTransferAccountSearchInputNode) {
                            this.bulkTransferAccountSearchInputNode.focus()
                        }
                    }}>
                    <div className='token-transfer-editor--bulk-transfer--account-selection-popup--header'>{'Select Accounts'}</div>
                    <div className='token-transfer-editor--bulk-transfer--account-selection-popup--config-row clearfix'>
                        <button className='token-transfer-editor--bulk-transfer--account-selection-popup--toggle-all-button'
                            onClick={() => {
                                if (isEveryFilteredAccountSelected) {
                                    _updateTransferItemsAndClearBulkTransferInput([TransferItem({})])
                                } else if (_.size(filteredAccountNames) > 0 ) {
                                    const newTransferItems = []
                                    _.forEach(filteredAccountNames, accountName => {
                                        const newTransferAccount = TransferAccount({ accountName })
                                        const newValidAccountTypes = this.getValidAccountTypes({ tokenToTransfer: token, accountName, counterTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : manyToOneDestinationTransferAccount })
                                        newTransferAccount.accountType = !_.isEmpty(newValidAccountTypes) ? _.head(newValidAccountTypes) : null
                                        newTransferItems.push(TransferItem({
                                            originTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : newTransferAccount,
                                            destinationTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.MANY_TO_ONE.key ? manyToOneDestinationTransferAccount : newTransferAccount
                                        }))
                                    })
                                    _updateTransferItemsAndClearBulkTransferInput(newTransferItems)
                                }
                            }}>
                            {isEveryFilteredAccountSelected ? 'Unselect All' : 'Select All'}
                        </button>
                        <input className='token-transfer-editor--bulk-transfer--account-selection-popup--search-string'
                            ref={(node) => { this.bulkTransferAccountSearchInputNode = node }}
                            type={'text'}
                            placeholder={'Search Account'}
                            disabled={isBulkTransferring}
                            spellCheck={false}
                            value={bulkTransferAccountNameSearchString}
                            onChange={(e) => { this.setState({ bulkTransferAccountNameSearchString: e.target.value }) }} />
                    </div>
                    {!_.isEmpty(accountNameGroups) 
                    ? <div className='token-transfer-editor--bulk-transfer--account-selection-popup--portfolios' onClick={(e) => { e.stopPropagation() }}>
                        {_.map(accountNameGroups, (portfolioAccountNames, portfolioName) => {
                            const isAllPortfolioAccountNameSelected = !_.isEmpty(portfolioAccountNames) && _.every(portfolioAccountNames, portfolioAccountName => filteredSelectedAccountNames.includes(portfolioAccountName))
                            return (
                                <div className='token-transfer-editor--bulk-transfer--account-selection-popup--portfolio' key={portfolioName}>
                                    <div className='token-transfer-editor--bulk-transfer--account-selection-popup--portfolio--header'>
                                        <label>{portfolioName}</label>
                                        <button onClick={() => {
                                            let newTransferItems = _.cloneDeep(transferItems)
                                            
                                            if (isAllPortfolioAccountNameSelected) {
                                                _.remove(newTransferItems, transferItem => {
                                                    if (bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key) {
                                                        return !_.isNil(transferItem.destinationTransferAccount) && portfolioAccountNames.includes(transferItem.destinationTransferAccount.accountName)
                                                    } else {
                                                        return !_.isNil(transferItem.originTransferAccount) && portfolioAccountNames.includes(transferItem.originTransferAccount.accountName)
                                                    }
                                                })
                                            } else {
                                                const unselectedAccountNames = _.filter(portfolioAccountNames, accountName => !filteredSelectedAccountNames.includes(accountName))
                                                _.forEach(unselectedAccountNames, accountName => {
                                                    const newTransferAccount = TransferAccount({ accountName, pairName: null })
                                                    const newValidAccountTypes = this.getValidAccountTypes({ tokenToTransfer: token, accountName, counterTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : manyToOneDestinationTransferAccount })
                                                    newTransferAccount.accountType = !_.isEmpty(newValidAccountTypes) ? _.head(newValidAccountTypes) : null
                                                    newTransferItems.push(TransferItem({
                                                        originTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : newTransferAccount,
                                                        destinationTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.MANY_TO_ONE.key ? manyToOneDestinationTransferAccount : newTransferAccount
                                                    }))
                                                })
                                            }

                                            _updateTransferItemsAndClearBulkTransferInput(newTransferItems)
                                        }}>{isAllPortfolioAccountNameSelected ? 'Unselect All' : 'Select All'}</button>
                                    </div>
                                    <div className='token-transfer-editor--bulk-transfer--account-selection-popup--portfolio--main'>
                                        {_.map(portfolioAccountNames.sort(), accountName => {
                                            return (
                                                <div className='token-transfer-editor--bulk-transfer--account-selection-popup--portfolio--item' key={accountName}>
                                                    <span>{accountName}</span>
                                                    <Checkbox checked={filteredSelectedAccountNames.includes(accountName)}
                                                        disabled={isBulkTransferring}
                                                        onChange={(newChecked) => {
                                                            let newTransferItems = _.cloneDeep(transferItems)
                                                            if (newChecked) {
                                                                const newTransferAccount = TransferAccount({ accountName, pairName: null })
                                                                const newValidAccountTypes = this.getValidAccountTypes({ tokenToTransfer: token, accountName, counterTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : manyToOneDestinationTransferAccount })
                                                                newTransferAccount.accountType = !_.isEmpty(newValidAccountTypes) ? _.head(newValidAccountTypes) : null
                                                                newTransferItems = transferItems.concat([TransferItem({
                                                                    originTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : newTransferAccount,
                                                                    destinationTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.MANY_TO_ONE.key ? manyToOneDestinationTransferAccount : newTransferAccount
                                                                })])
                                                            } else {
                                                                _.remove(newTransferItems, transferItem => {
                                                                    if (bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key) {
                                                                        return !_.isNil(transferItem.destinationTransferAccount) && transferItem.destinationTransferAccount.accountName === accountName
                                                                    } else {
                                                                        return !_.isNil(transferItem.originTransferAccount) && transferItem.originTransferAccount.accountName === accountName
                                                                    }
                                                                })
                                                            }
                                                            _updateTransferItemsAndClearBulkTransferInput(newTransferItems)
                                                        }} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    : <div className='token-transfer-editor--bulk-transfer--account-selection-popup--empty-result'>{'No Selectable Account'}</div>}
                </Popup>
            )
        }

        const BulkAccountType = () => {
            const allValidAccountTypes = [], validPairNames = []
            let shouldHavePairName
            _.forEach(transferItems, transferItem => {
                const transferAccount = bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key 
                    ? transferItem.destinationTransferAccount
                    : transferItem.originTransferAccount
                const accountName = transferAccount.accountName
                if (!_.isNil(accountName)) {
                    const accountValidAccountTypes = this.getValidAccountTypes({
                        tokenToTransfer: token,
                        accountName,
                        counterTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : manyToOneDestinationTransferAccount
                    })
                    allValidAccountTypes.push(...accountValidAccountTypes)
                }
                const shouldAccountHavePairName = this.shouldTransferAccountHavePairName(token, transferAccount)
                if (shouldAccountHavePairName) {
                    validPairNames.push(...this.getTransferAccountValidPairNames(token, transferAccount))
                }
                shouldHavePairName = shouldHavePairName || shouldAccountHavePairName
            })

            const accountTypeOptions =  _.uniqBy(allValidAccountTypes, 'key').map(accountType => {
                const applicableTransferAccountSize = _.size(_.filter(transferItems, transferItem => {
                    const transferAccount = bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key 
                        ? transferItem.destinationTransferAccount
                        : transferItem.originTransferAccount
                    const accountName = transferAccount.accountName
                    let result = false
                    if (!_.isNil(accountName)) {
                        const accountValidAccountTypeKeys = this.getValidAccountTypes({
                            tokenToTransfer: token,
                            accountName,
                            counterTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : manyToOneDestinationTransferAccount
                        }).map(accountType => accountType.key)
                        if (accountValidAccountTypeKeys.includes(accountType.key)) {
                            result = true
                        }
                    }
                    return result
                }))

                return {
                    value: accountType.key,
                    name: `${accountType.name} (${applicableTransferAccountSize}/${_.size(transferItems)})`
                }
            })

            const pairNameOptions = _.uniq(validPairNames).map(pairName => {
                return {
                    value: pairName,
                    name: pairName.replace('-', '/').toUpperCase()
                }
            })

            return (
                <div className='token-transfer-editor--bulk-transfer--bulk-account-type'>
                    <SearchSelect 
                        className='token-transfer-editor--bulk-transfer--bulk-account-type--type-selector'
                        placeholder={'Select Type'}
                        value={bulkTransferAccountTypeKey}
                        options={accountTypeOptions}
                        disabled={_.isEmpty(accountTypeOptions) || isBulkTransferring}
                        shouldHighlightInvalidValue
                        onChange={(newOption) => {
                            const newTransferItems = _.cloneDeep(transferItems)
                            _.forEach(newTransferItems, transferItem => {
                                if (bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key) {
                                    transferItem.destinationTransferAccount.accountType = ACCOUNT_TYPES[newOption.value]
                                    transferItem.destinationTransferAccount.pairName = null
                                } else {
                                    transferItem.originTransferAccount.accountType = ACCOUNT_TYPES[newOption.value]
                                    transferItem.originTransferAccount.pairName = null
                                }
                                transferItem.message = null
                            })
                            this.setState({ 
                                bulkTransferAccountTypeKey: newOption.value,
                                bulkTransferPairName: null,
                                transferItems: newTransferItems
                            })
                        }} />
                    {(shouldHavePairName || !_.isEmpty(bulkTransferPairName)) && 
                    <SearchSelect
                        className='token-transfer-editor--bulk-transfer--bulk-account-type--pair-selector'
                        placeholder={'Select Pair'}
                        value={bulkTransferPairName}
                        options={pairNameOptions} 
                        disabled={_.isEmpty(pairNameOptions) || isBulkTransferring}
                        shouldHighlightInvalidValue
                        onChange={(newOption) => {
                            const newTransferItems = _.cloneDeep(transferItems)
                            _.forEach(newTransferItems, transferItem => {
                                const transferAccount = bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key 
                                    ? transferItem.destinationTransferAccount
                                    : transferItem.originTransferAccount
                                const shouldAccountHavePairName = this.shouldTransferAccountHavePairName(token, transferAccount)
                                if (shouldAccountHavePairName) {
                                    const accountValidPairNames = this.getTransferAccountValidPairNames(token, transferAccount)
                                    if (accountValidPairNames.includes(newOption.value)) {
                                        transferAccount.pairName = newOption.value
                                    }
                                }
                                transferItem.message = null
                            })
                            this.setState({ 
                                bulkTransferPairName: newOption.value,
                                transferItems: newTransferItems
                            })
                        }} />}
                </div>
            )
        }

        const PositionFilter = () => {
            return (
                <SearchSelect className='token-transfer-editor--bulk-transfer--position-filter-selector'
                    options={positionFilterSymbolNameOptions}
                    value={positionFilterSymbolName}
                    placeholder={'Search Symbol Positions'} 
                    hasClearButton
                    onChange={(newOption) => {
                        this.setState({ positionFilterSymbolName: newOption.value })
                    }} 
                    onClickClearButton={() => { 
                        this.setState({ positionFilterSymbolName: null })
                    }} />
            )
        }

        const PositionInfo = (accountName, symbolName) => {
            const symbolItem = symbolItems[symbolName]
            const positionItem = _.find(positions, { product_name: symbolName, account_name: accountName })

            if (!_.isNil(positionItem)) {
                const lastPrice = _.has(pricings, `${symbolName}.last`) ? pricings[symbolName].last : null
                const { long_position: longPosition, short_position: shortPosition, liquidation_price: liquidationPrice, timestamp } = positionItem
                const { quote } = getSymbolAttributeByName(symbolName)
                const positionNotional = !_.isNil(symbolItem) ? getNotional({
                    symbolItem,
                    quantity: Number(longPosition) - Number(shortPosition),
                    price: lastPrice,
                    BTCUSDIndexLastPrice: _.has(pricings, `btc_usd_OKEX_INDEX.last`) ? pricings['btc_usd_OKEX_INDEX'].last : null
                }) : null
                const ratio = Number(liquidationPrice) > 0 && Number(lastPrice) > 0 
                    ? (Number(liquidationPrice) - Number(lastPrice)) / Number(lastPrice)
                    : null
                const riskRatioThreshold = getRiskRatioThresholdByPositionItem(riskRatioThresholds, positionItem)
                const symbolRataioRefrence = _.has(riskRatioThreshold, 'reference') ? riskRatioThreshold.reference : null
                
                return (
                    <div className='token-transfer-editor--bulk-transfer--position-info'>
                        <div className='token-transfer-editor--bulk-transfer--position-info--row'>
                            <label>{'Time'}</label>
                            <span>{moment(timestamp).format('HH:mm')}</span>
                        </div>
                        <div className='token-transfer-editor--bulk-transfer--position-info--row'>
                            <label>{'Notional'}</label>
                            <span>
                                <span className={_.isNil(positionNotional) ? null : Number(positionNotional) > 0 ? 'positive' : 'negative'}>
                                    {!_.isNil(positionNotional) ? `${toNumberWithSmartPrecision({ number: positionNotional, shouldReturnLocalString: true })}` : 'N/A'}
                                </span>
                                {!_.isNil(positionNotional) && <span>{` ${quote}`}</span>}
                            </span>
                        </div>
                        <div className='token-transfer-editor--bulk-transfer--position-info--row'>
                            <label>{'Ratio'}</label>
                            <span className={!_.isNil(ratio) && !_.isNil(symbolRataioRefrence) && Math.abs(ratio) < Number(symbolRataioRefrence) ? 'highlight' : null}>
                                {!_.isNil(ratio) ? `${(Number(ratio) * 100).toFixed(2)}%` : 'N/A'}
                            </span>
                        </div>
                    </div>
                )
            } else if (!_.isEmpty(symbolName)){
                return (
                    <div className='token-transfer-editor--bulk-transfer--position-info'>
                        <span className='token-transfer-editor--bulk-transfer--position-info--empty-message'>{'No Position Found'}</span>
                    </div>
                )
            } else {
                return null
            }
        } 

        return (
            <div className='token-transfer-editor--bulk-transfer'>
                <div className='token-transfer-editor--bulk-transfer--header'>
                    {this.BulkTransferTabs()}
                    <div className='token-transfer-editor--bulk-transfer--config'>
                        {this.Row('Token', this.Token())}
                        {bulkTransferMode && [BULK_TRANSFER_MODES.ONE_TO_MANY.key, BULK_TRANSFER_MODES.MANY_TO_ONE.key].includes(bulkTransferMode.key) 
                        && this.Row(bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? 'From Account' : 'To Account', this.TransferAccount({
                            transferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : manyToOneDestinationTransferAccount,
                            accountNameOptions: allAccountNameOptions,
                            disabled: isBulkTransferring,
                            onChange: (newTransferAccount) => { 
                                this.handleChangeBulkTransferAccount({ 
                                    transferAccountDirection: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN : TRANSFER_ACCOUNT_DIRECTIONS.DESTINATION,
                                    newTransferAccount
                                }) 
                            }
                        }))}
                        {bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_ONE.key && this.Row('Amount of Each Transfer', BulkTransferAmountOfEachInput())}
                    </div>
                    {[BULK_TRANSFER_MODES.ONE_TO_MANY.key, BULK_TRANSFER_MODES.MANY_TO_ONE.key].includes(bulkTransferMode.key) && <Fragment>
                        <div className='token-transfer-editor--bulk-transfer--unified-control'>
                            {this.Row(bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? 'To Accounts' : 'From Accounts', AccountSelectionPopup())}
                            {this.Row('', BulkAccountType())}
                        </div>
                        <div className='token-transfer-editor--bulk-transfer--unified-control'>
                            {this.Row('Amount of Each Transfer', BulkTransferAmountOfEachInput())}
                            {this.Row('Total Amount to Distribute', BulkTransferTotalAmountInput())}
                        </div>
                    </Fragment>}
                </div>
                <div className='token-transfer-editor--bulk-transfer--body' ref={(node) => { this.bulkTransferBodyNode = node }}>
                    <table>
                        <thead>
                            <tr>
                                <th>{`From (${_.size(transferItems)})`}</th>
                                {bulkTransferMode && bulkTransferMode.key === BULK_TRANSFER_MODES.MANY_TO_ONE.key && <th>{PositionFilter()}</th>}
                                <th>{'Amount'}</th>
                                <th>{'To'}</th>
                                {bulkTransferMode && bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key && <th>{PositionFilter()}</th>}
                                <th>{'State'}</th>
                                <th>{'Message'}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {_.map(transferItems, (transferItem, index) => {
                                const { originTransferAccount, destinationTransferAccount, state, message } = transferItem
                                if (bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_ONE.key) {
                                    originAccountNameOptions = allAccountNameOptions
                                    destinationAccountNameOptions = this.getCounterTransferAccountNames(originTransferAccount).map(accountName => {
                                        return {
                                            value: accountName,
                                            name: accountName
                                        }
                                    })
                                }
                                return (
                                    <tr key={index}>
                                        <td>{this.TransferAccount({
                                            transferAccount: originTransferAccount,
                                            counterTransferAccount: destinationTransferAccount,
                                            accountNameOptions: originAccountNameOptions,
                                            disabled: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key || isBulkTransferring,
                                            shouldHighlightInvalidValue: bulkTransferMode.key === BULK_TRANSFER_MODES.MANY_TO_ONE.key,
                                            canClickAvailableBalance: true,
                                            onChange: (newTransferAccount) => {
                                                this.handleChangeSingleTransferAccount({ 
                                                    transferItemIndex: index,
                                                    transferAccountDirection: TRANSFER_ACCOUNT_DIRECTIONS.ORIGIN,
                                                    newTransferAccount: newTransferAccount
                                                }) 
                                            },
                                            onClickAvailableBalance: (available) => {
                                                const newTransferItems = dotProp.merge(transferItems, index, {
                                                    amount: available,
                                                    amountPercentInput: 100,
                                                    state: TRANSFER_STATES.NULL,
                                                    message: null
                                                })
                                                this.setState({ 
                                                    transferItems: newTransferItems
                                                })
                                            }
                                        })}</td>
                                        {bulkTransferMode && bulkTransferMode.key === BULK_TRANSFER_MODES.MANY_TO_ONE.key && <td>{PositionInfo(originTransferAccount.accountName, positionFilterSymbolName)}</td>}
                                        <td className='token-transfer-editor--bulk-transfer--amount'>
                                            {this.Amount({ transferItemIndex: index })}
                                            {_.isEqual(bulkTransferMode, BULK_TRANSFER_MODES.ONE_TO_ONE) && this.TransferAccountSwap({ transferItemIndex: index })}
                                        </td>
                                        <td>{this.TransferAccount({
                                            transferAccount: destinationTransferAccount,
                                            accountNameOptions: destinationAccountNameOptions,
                                            counterTransferAccount: originTransferAccount,
                                            disabled: bulkTransferMode.key === BULK_TRANSFER_MODES.MANY_TO_ONE.key || isBulkTransferring,
                                            shouldHighlightInvalidValue: [BULK_TRANSFER_MODES.ONE_TO_MANY.key, BULK_TRANSFER_MODES.ONE_TO_ONE.key].includes(bulkTransferMode.key),
                                            onChange: (newTransferAccount) => { 
                                                this.handleChangeSingleTransferAccount({ 
                                                    transferItemIndex: index,
                                                    transferAccountDirection: TRANSFER_ACCOUNT_DIRECTIONS.DESTINATION,
                                                    newTransferAccount: newTransferAccount
                                                }) 
                                            }
                                        })}</td>
                                        {bulkTransferMode && bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key && <td>{PositionInfo(destinationTransferAccount.accountName, positionFilterSymbolName)}</td>}
                                        <td className={`token-transfer-editor--bulk-transfer--state ${state}`}>{state !== 'NULL' ? state : ''}</td>
                                        <td className='token-transfer-editor--bulk-transfer--message'>{message}</td>
                                        <td className='token-transfer-editor--bulk-transfer--action'>
                                            {_.size(transferItems) > 1 && 
                                            <button className='token-transfer-editor--bulk-transfer--remove' 
                                                disabled={isBulkTransferring}
                                                onClick={() => {
                                                    const newTransferItems = dotProp.delete(transferItems, index)
                                                    this.setState({ transferItems: newTransferItems })
                                                }}><FiX /></button>}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {bulkTransferResult.shouldShow && this.BulkTransferSummary()}
                <div className='token-transfer-editor--bulk-transfer--footer clearfix'>
                    <button className='token-transfer-editor--bulk-transfer--footer--button add' 
                        disabled={isBulkTransferring}
                        onClick={() => {
                            const newTransferItems = transferItems.concat([TransferItem({
                                originTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.ONE_TO_MANY.key ? oneToManyOriginTransferAccount : null,
                                destinationTransferAccount: bulkTransferMode.key === BULK_TRANSFER_MODES.MANY_TO_ONE.key ? manyToOneDestinationTransferAccount : null
                            })])
                            this.setState({ transferItems: newTransferItems })
                        }}>{'Add'}</button>
                    {!isBulkTransferring 
                    ? <button className='token-transfer-editor--bulk-transfer--footer--button submit' onClick={() => { this.handleClickBulkTransferSubmitButton() }}>{'Submit'}</button>
                    : <button className='token-transfer-editor--bulk-transfer--footer--button discard' onClick={() => { this.finishBulkTransfer(true) }}>{'Discard'}</button>}
                    <button className='token-transfer-editor--bulk-transfer--footer--button reset' 
                        disabled={isBulkTransferring}
                        onClick={() => {
                            this.setState(Object.assign({}, this.state, this.initialState))
                        }}>{'Reset'}</button>
                    {bulkTransferMessage && <div className='token-transfer-editor--bulk-transfer--footer--message'>{bulkTransferMessage}</div>}
                </div>
            </div>
        )
         
    }

    render () {
        const { operationMode, transferMode } = this.state
        return (
            <div className='token-transfer-editor'>
                {operationMode === OPERATION_MODES.SINGLE && this.SingleTransfer()}
                {transferMode === TRANSFER_MODES.TRANSFER && operationMode === OPERATION_MODES.BULK && this.BulkTransfer()}
            </div>
        )
    }
}

TokenTransferEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,

    defaultOperationMode: PropTypes.oneOf(Object.keys(OPERATION_MODES)).isRequired,
    defaultSingleTransferConfig: PropTypes.shape({
        id: PropTypes.string,
        token: PropTypes.string,
        transferItem: PropTypes.object
    }),
    bulkTransferConfig: PropTypes.shape({
        updateId: PropTypes.string,
        token: PropTypes.string,
        bulkTransferMode: PropTypes.object,
        oneToManyOriginTransferAccount: PropTypes.object,
        manyToOneDestinationTransferAccount: PropTypes.object,
        transferItems: PropTypes.array
    }),
    transferMode: PropTypes.oneOf(Object.keys(TRANSFER_MODES)).isRequired,
    validSingleTransferOriginAccountNames: PropTypes.array,
    shouldAutoFocusAmountInput: PropTypes.bool,

    tokens: PropTypes.array.isRequired,
    accountItems: PropTypes.object.isRequired,
    accountBalance: PropTypes.object.isRequired,
    accountAsset: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    riskRatioThresholds: PropTypes.object.isRequired,

    onStartBulkTransfer: PropTypes.func,
    onFinishBulkTransfer: PropTypes.func,
    onTransferSuccess: PropTypes.func,
}

TokenTransferEditor.defaultProps = {
    bulkTransferConfig: {},
    defaultOperationMode: OPERATION_MODES.SINGLE,
    transferMode: TRANSFER_MODES.TRANSFER,
    shouldAutoFocusAmountInput: false,
    onStartBulkTransfer: () => {},
    onFinishBulkTransfer: () => {},
    onTransferSuccess: () => {}
}

function mapStateToProps (state) {
    const { account, symbol } = state
    const exchangeNamesCanTransferToken = Object.keys(EXCHANGES)
    const tokens = _.uniq(Object.keys(symbol.items).reduce((result, symbolName) => {
        const { base, quote, instrumentType } = getSymbolAttributeByName(symbolName)
        if ([INSTRUMENT_TYPES.SPOT, INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType)) {
            result.push(base, quote)
        }
        return result
    }, [])).sort()
    const accountItems = _.pickBy(account.items, accountItem => exchangeNamesCanTransferToken.includes(accountItem.exchange_name))

    return {
        tokens,
        accountItems: accountItems,
        accountBalance: account.balance,
        accountAsset: account.asset,
        symbolItems: symbol.items,
        pricings: symbol.pricings,
        positions: state.trading.positions,
        riskRatioThresholds: state.trading.riskRatioThresholds
    }
}

export default connect(mapStateToProps)(TokenTransferEditor)