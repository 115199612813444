import React, { Component, Fragment } from 'react'
import  { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import Toggle from '../common/toggle/Toggle'
import AccountPopup from '../account/AccountPopup'

import { SYMBOL_SWITCH_TYPES, getSymbolAttributeByName } from '../../util/symbolUtil'
import { switchOnProfileSymbol, switchOffProfileSymbol } from './profileAction'
import moment from 'moment'

class ProfileSwitchItem extends Component {

    getSymbolSwitchOffItem ({ symbolName, accountName, side }) {
        const { profileRunningState } = this.props
        return _.find((_.has(profileRunningState, 'switchOffs') ? profileRunningState.switchOffs : []), { symbol: symbolName, account: accountName, side })
    }

    SwitchToggle ({ name, checked, popupMessage, onChange=()=>{} }) {
        const { profileItem } = this.props
        return (
            <div className='profile-switch-item--toggle'>
                {name && <div className='profile-switch-item--toggle--name'>{name}</div>}
                <Popup className='profile-switch-item--toggle--popup'
                    align={'horizontal'}
                    trigger={
                        <div className='profile-switch-item--toggle--trigger'>
                            <Toggle 
                                checked={checked}
                                trueText={'ON'}
                                falseText={'OFF'} 
                                changingText={'Switching'} 
                                isChanging={false} 
                                disabled={!profileItem.started || profileItem.isPausing || profileItem.isStarting} 
                                onChange={(newChecked) => { onChange(newChecked) }} />
                        </div>}>
                    <span style={{ visibility: popupMessage ? 'visible' : 'hidden' }}>{popupMessage ? popupMessage.toString() : ''}</span>
                </Popup>
            </div>
        )
    }

    render () {
        const { dispatch, profileItem, shouldShowAcccountName, shouldHighlightAcccountName, accountName, accountItem, symbolName, direction, postfix } = this.props
        const { switchType } = getSymbolAttributeByName(symbolName)
        let switchOffItem, openSideSwitchOffItem, closeSideSwitchOffItem
        if (switchType === SYMBOL_SWITCH_TYPES.BUY_SELL) {
            switchOffItem = this.getSymbolSwitchOffItem({ symbolName, accountName, side: direction })
        } else {
            openSideSwitchOffItem = this.getSymbolSwitchOffItem({ symbolName, accountName, side: `${direction}_OPEN` })
            closeSideSwitchOffItem = this.getSymbolSwitchOffItem({ symbolName, accountName, side: `${direction}_CLOSE` })
        }
        return (
            <div className='profile-switch-item'>
                {shouldShowAcccountName && <div className={'profile-switch-item--account-name' + (shouldHighlightAcccountName ? ' selected' : '') + (_.isNil(accountItem) ? ' nil-account' : '')}
                    title={shouldHighlightAcccountName ? 'Selected as Trading Account' 
                        : _.isNil(accountItem) ? 'Account is not valid.' 
                        : null}>
                    {!_.isNil(accountItem) 
                    ? <AccountPopup accountItem={accountItem} />
                    : <span>{accountName}</span>}
                </div>}
                {switchType === SYMBOL_SWITCH_TYPES.BUY_SELL && this.SwitchToggle({
                    checked: _.isNil(switchOffItem),
                    popupMessage: _.has(switchOffItem, 'reason') ? `${switchOffItem.reason} ${!_.isNil(switchOffItem.timestamp) ? `@ ${moment(switchOffItem.timestamp).format('YYYY-MM-DD HH:mm:ss')}` : ' @ Unkonw Time'}` : null,
                    onChange: (newChecked) => {
                        const switchAction = newChecked ? switchOnProfileSymbol : switchOffProfileSymbol
                        dispatch(switchAction({ profileId: profileItem.id, symbolName, accountName, side: direction }))
                    }
                })}
                {switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE && 
                <Fragment>
                    {this.SwitchToggle({
                        name: 'Open',
                        checked: _.isNil(openSideSwitchOffItem),
                        popupMessage: _.has(openSideSwitchOffItem, 'reason') ? `${openSideSwitchOffItem.reason} ${!_.isNil(openSideSwitchOffItem.timestamp) ? `@ ${moment(openSideSwitchOffItem.timestamp).format('YYYY-MM-DD HH:mm:ss')}` : ' @ Unkonw Time'}` : null,
                        onChange: (newChecked) => {
                            const switchAction = newChecked ? switchOnProfileSymbol : switchOffProfileSymbol
                            dispatch(switchAction({ profileId: profileItem.id, symbolName, accountName, side: `${direction}_OPEN` }))
                        }
                    })}
                    {this.SwitchToggle({
                        name: 'Close',
                        checked: _.isNil(closeSideSwitchOffItem),
                        popupMessage: _.has(closeSideSwitchOffItem, 'reason') ? `${closeSideSwitchOffItem.reason} ${!_.isNil(closeSideSwitchOffItem.timestamp) ? `@ ${moment(closeSideSwitchOffItem.timestamp).format('YYYY-MM-DD HH:mm:ss')}` : ' @ Unkonw Time'}` : null,
                        onChange: (newChecked) => {
                            const switchAction = newChecked ? switchOnProfileSymbol : switchOffProfileSymbol
                            dispatch(switchAction({ profileId: profileItem.id, symbolName, accountName, side: `${direction}_CLOSE` }))
                        }
                    })}
                </Fragment>}
                {postfix && <div className='profile-switch-item--postfix'>{postfix}</div>}
            </div>
        )
    }
}

ProfileSwitchItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    shouldShowAcccountName: PropTypes.bool,
    shouldHighlightAcccountName: PropTypes.bool,
    profileItem: PropTypes.object.isRequired,
    accountName: PropTypes.string.isRequired,
    symbolName: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['BUY', 'SELL']).isRequired,
    postfix: PropTypes.string,

    accountItem: PropTypes.object.isRequired,
    profileRunningState: PropTypes.object.isRequired
}

ProfileSwitchItem.defaultProps = {
    shouldShowAcccountName: false,
    shouldHighlightAcccountName: false,
    direction: 'BUY'
}

function mapStateToProps (state, ownProps) {
    const { id: profileId } = ownProps.profileItem
    return {
        accountItem: state.account.items[ownProps.accountName],
        profileRunningState: _.has(state.profile.runningState, `${profileId}`) ? state.profile.runningState[profileId] : {},
    }
}

export default connect(mapStateToProps)(ProfileSwitchItem)