import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import uuidv4 from 'uuid/v4'
import moment from 'moment'
import _ from 'lodash'

import { FiX } from 'react-icons/fi'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import { TRADERS } from '../../configs/config'
import { fetchUserProfiles } from './profileAction'
import { isMetSearchStringCriteria } from '../../util/util'
import { sendData as nodeWebSocketSendData } from '../webSocket/NodeWebSocket'

export const UserProfileItem = ({ username='', profileIds=[] }) => {
    return {
        username,
        profileIds
    }
}

const PROFILES_NOT_ASSIGNED = 'PROFILES_NOT_ASSIGNED'

class UserProfiles extends Component {

    constructor (props) {
        super(props)
        this.state = {
            userProfiles: props.userProfiles,
            isFetching: false,
            isUpdating: false,
            userSearchString: '',
            profileSearchString: '',
            webSocketLastUpdateId: null
        }
        this._mounted = false
        this.updateId = null
        this.grid = 8
        this.columns = {
            [PROFILES_NOT_ASSIGNED]: 'Profiles Not Assigned',
            ...TRADERS
        }
        this.cachedProfileIdsNotAssigned = []
    }

    componentDidMount () {
        this._mounted = true
        this._fetchUserProfiles()
    }

    componentDidUpdate () {
        const { userProfiles: propsUserProfiles } = this.props
        const { userProfiles: stateUserProfiles, webSocketLastUpdateId } = this.state
        if (!_.isEmpty(propsUserProfiles.lastUpdateTime)
        && (_.isEmpty(stateUserProfiles.lastUpdateTime) || moment(stateUserProfiles.lastUpdateTime).isBefore(propsUserProfiles.lastUpdateTime))
        && (_.isNil(webSocketLastUpdateId) || webSocketLastUpdateId === propsUserProfiles.lastUpdateId)) {
            this._updateUserProfiles(propsUserProfiles)
        }
    }

    componentWillUnmount () {
        this._mounted = false
    }

    _updateUserProfiles (userProfiles) {
        this.setState({
            userProfiles,
            webSocketLastUpdateId: null
        })
    }

    _fetchUserProfiles () {
        const { isFetching } = this.state
        const { dispatch } = this.props
        if (!isFetching) {
            this.setState({ isFetching: true })
            dispatch(fetchUserProfiles())
            .finally(() => {
                if (this._mounted) {
                    this.setState({ 
                        isFetching: false,
                        webSocketLastUpdateId: null
                    })
                }
            })
        }
    }

    // _saveUserProfileItems () {
    //     const { dispatch } = this.props
    //     const { userProfiles } = this.state
    //     if (_.has(userProfiles, 'items')) {
    //         const newUpdateId = uuidv4()
    //         this.updateId = newUpdateId
    //         this.setState({ isUpdating: true })
    //         dispatch(saveUserProfiles(userProfiles.items))
    //         .finally(() => {
    //             if (this._mounted && this.updateId === newUpdateId) {
    //                 this.setState({ isUpdating: false })
    //             }
    //         })
    //     }
    // }

    handleDragEndContext (result) {
        const { auth } = this.props
        const { userProfiles, webSocketLastUpdateId } = this.state

        const { source, destination, draggableId: profileId } = result

        if (!_.isNil(source) && !_.isNil(destination) && !_.isNil(profileId)) {
            const { droppableId: sourceUsername } = source
            const { droppableId: destinationUsername, index } = destination
    
            const newUserProfiles = _.cloneDeep(userProfiles)
            if (sourceUsername === PROFILES_NOT_ASSIGNED) {
                _.pull(this.cachedProfileIdsNotAssigned, profileId)
            } else if (_.has(newUserProfiles, `items.${sourceUsername}.profileIds`)) {
                _.pull(newUserProfiles.items[sourceUsername].profileIds, profileId)
            }

            if (destinationUsername !== PROFILES_NOT_ASSIGNED && !_.has(newUserProfiles, `items.${destinationUsername}`)) {
                newUserProfiles.items[destinationUsername] = UserProfileItem({
                    username: destinationUsername,
                    profileIds: []
                })
            }

            if (destinationUsername === PROFILES_NOT_ASSIGNED) {
                this.cachedProfileIdsNotAssigned.splice(index, 0, profileId)
            } else if (_.has(newUserProfiles, `items.${destinationUsername}.profileIds`)) {
                newUserProfiles.items[destinationUsername].profileIds.splice(index, 0, profileId)
            }

            const shouldUpdate = destinationUsername !== PROFILES_NOT_ASSIGNED || sourceUsername !== PROFILES_NOT_ASSIGNED
            const newWebSocketLastUpdateId = uuidv4()
            this.setState({ 
                userProfiles: newUserProfiles,
                webSocketLastUpdateId: shouldUpdate ? newWebSocketLastUpdateId : webSocketLastUpdateId
            }, () => {
                if (shouldUpdate && destinationUsername !== PROFILES_NOT_ASSIGNED) {
                    nodeWebSocketSendData({
                        type: 'MOVE_USER_PROFILE',
                        payload: {
                            sourceUsername,
                            destinationUsername,
                            profileId,
                            profileIndex: index,
                            updateBy: auth.username,
                            updateId: newWebSocketLastUpdateId 
                        }   
                    })
                } else if (shouldUpdate && sourceUsername !== PROFILES_NOT_ASSIGNED) {
                    nodeWebSocketSendData({
                        type: 'REMOVE_USER_PROFILE',
                        payload: {
                            username: sourceUsername,
                            profileId,
                            updateBy: auth.username,
                            updateId: newWebSocketLastUpdateId
                        }   
                    })
                }
            })
        }
    }

    handleClickRemoveProfileButton (username, profileId) {
        const { userProfiles } = this.state
        const { auth } = this.props
        const newUserProfiles = _.cloneDeep(userProfiles)
        if (_.has(newUserProfiles, `items.${username}.profileIds`)) {
            _.pull(newUserProfiles.items[username].profileIds, profileId)
        }
        const webSocketLastUpdateId = uuidv4()
        this.setState({ 
            userProfiles: newUserProfiles,
            webSocketLastUpdateId
        }, () => {
            nodeWebSocketSendData({
                type: 'REMOVE_USER_PROFILE',
                payload: {
                    username,
                    profileId,
                    updateBy: auth.username,
                    updateId: webSocketLastUpdateId
                }   
            })
        })
    }

    _getListStyle (isDraggingOver=false) {
        return {
            background: isDraggingOver ? "#819b9b" : "#073e3d",
            width: 270
        }
    }

    _getItemStyle (isDragging=false, draggableStyle={}) {
        return {
            userSelect: 'none',
            background: isDragging ? 'burlywood' : '#4d7a7a',
            color: isDragging ? '#4c3211' : 'white',
            ...draggableStyle
        }
    }

    render () {
        const { profileItems, auth } = this.props
        const { userProfiles, profileSearchString, userSearchString, isFetching, webSocketLastUpdateId } = this.state
        
        const profileIdsAssigned = _.has(userProfiles, 'items') && !_.isEmpty(userProfiles.items)
            ? _.uniq(_.reduce(userProfiles.items, (result, userProfileItem) => {
                return _.concat(result, userProfileItem.profileIds || [])
            }, []))
            : []
        const profileIdsNotAssigned = _.difference(_.union(this.cachedProfileIdsNotAssigned, _.keys(profileItems)), profileIdsAssigned).filter(profileId => {
            const profileItem = profileItems[profileId]
            return !_.isNil(profileItem) && isMetSearchStringCriteria(`${profileItem.name} ${profileItem.hostname}`, profileSearchString)
        })
        this.cachedProfileIdsNotAssigned = profileIdsNotAssigned
        
        const columns = _.sortBy(_.reduce(this.columns, (result, name, key) => {
            result.push({
                key,
                name
            })
            return result
        }, []), column => {
            return column.key === PROFILES_NOT_ASSIGNED ? 1
                : column.key === auth.username ? 2
                : 3
        })
        
        return (
            <div className='user-profiles'>
                <div className='user-profiles--header'>
                    <div className='user-profiles--title'>{'Trader Profiles Management'}</div>
                    <input className='user-profiles--user-search' 
                        spellCheck={false}
                        type={'text'}
                        placeholder={'Filter Traders'}
                        value={userSearchString}
                        onChange={(e) => { this.setState({ userSearchString: e.target.value }) }} />
                    <div className='user-profiles--header--right-align-content'>
                        <button className='user-profiles--header--fetch-button' 
                            disabled={isFetching}
                            onClick={() => {
                                this._fetchUserProfiles()
                            }}>{isFetching ? 'Fetching' : 'Fetch Latest'}</button>
                        <div className='user-profiles--last-update-time'>
                            <label>{'Last Update: '}</label>
                            {!_.isNil(userProfiles.lastUpdateTime) && moment(userProfiles.lastUpdateTime).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                        <div className='user-profiles--is-updating'>{!_.isNil(webSocketLastUpdateId) ? 'Saving...' : ''}</div>
                    </div>
                </div>
                <div className='user-profiles--body' style={{ display: 'flex' }}>
                    <DragDropContext 
                        onDragEnd={(result) => { this.handleDragEndContext(result) }}>
                        {_.map(columns, (column) => {
                            const { key: columnKey, name: columnName } = column
                            const shouldRenderColumn = columnKey === PROFILES_NOT_ASSIGNED || _.isEmpty((userSearchString || '').trim()) || isMetSearchStringCriteria(columnKey, userSearchString)
                            if (shouldRenderColumn) {
                                const profileIds = columnKey === PROFILES_NOT_ASSIGNED ? profileIdsNotAssigned
                                    : _.has(userProfiles, `items.${columnKey}.profileIds`) ? (userProfiles.items[columnKey].profileIds || [])
                                    : []
                                return (
                                    <div className='user-profiles--list' key={columnKey}>
                                        <div className='user-profiles--list--title'>{columnName}</div>
                                        {columnKey === PROFILES_NOT_ASSIGNED && <div className='user-profiles--profile-search'>
                                            <input type={'text'}
                                                value={profileSearchString}
                                                spellCheck={false}
                                                placeholder='Search Profiles'
                                                onChange={(e) => { this.setState({ profileSearchString: e.target.value }) }} />
                                        </div>}
                                        <Droppable key={columnKey} droppableId={columnKey}>
                                            {(droppableProvided, droppableSnapshot) => (
                                                <div className='user-profiles--list--main'
                                                    ref={droppableProvided.innerRef}
                                                    style={this._getListStyle(droppableSnapshot.isDraggingOver)}
                                                    {...droppableProvided.droppableProps}>
                                                    {columnKey === PROFILES_NOT_ASSIGNED && _.isEmpty(profileIds) && <div className='user-profiles--empty-assigned-message'>{'Empty Data'}</div>}
                                                    {_.map(profileIds, (profileId, index) => {
                                                        const profileItem = profileItems[profileId]
                                                        return (
                                                            <Draggable
                                                                key={profileId}
                                                                draggableId={profileId}
                                                                index={index}>
                                                                {(draggableProvided, draggableSnapshot) => {
                                                                    return (
                                                                        <div
                                                                            className={'user-profiles--item-wrapper' + (!_.has(profileItems, profileId) ? ' not-found' : '')}
                                                                            ref={draggableProvided.innerRef}
                                                                            {...draggableProvided.draggableProps}
                                                                            {...draggableProvided.dragHandleProps}
                                                                            style={this._getItemStyle(draggableSnapshot.isDragging, draggableProvided.draggableProps.style)}>
                                                                            <div className={'user-profiles--item'}>
                                                                                <span>
                                                                                    {!_.isNil(profileItem) ? `${profileItem.name} @ ${profileItem.hostname}` : profileId}
                                                                                </span>
                                                                                {columnKey !== PROFILES_NOT_ASSIGNED && 
                                                                                <button className='user-profiles--item--remove-button'
                                                                                    onClick={(e) => { 
                                                                                        e.stopPropagation()
                                                                                        this.handleClickRemoveProfileButton(columnKey, profileId) 
                                                                                    }}><FiX /></button>}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }}
                                                            </Draggable>
                                                        )
                                                    })}
                                                    {droppableProvided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                )
                            } else {
                                return null
                            }
                        })}
                    </DragDropContext>
                </div>
            </div>
        )
    }
}

UserProfiles.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileItems: PropTypes.object.isRequired,
    userProfiles: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items,
        userProfiles: state.profile.userProfiles,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(UserProfiles)