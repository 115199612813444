import React, { Component } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import _ from 'lodash'

import { toNumberWithSmartPrecision } from '../../util/util'

export default class CrossAccountBalanceItem extends Component {

    constructor (props) {
        super(props)
        this.state = {
            defaultSingleTransferConfig: null
        }

        this._mounted = null
    }

    Row ({ name, value, displayValue, hasDirection }) {
        return (
            <div className='cross-account-balance-item--row'>
                <div className='cross-account-balance-item--row--name'>{name}</div>
                <div className={'cross-account-balance-item--row--value' + (value > 0 && hasDirection? ' positive' : value < 0 && hasDirection? ' negative' : '')}>
                    {!_.isNil(displayValue) ? displayValue : value}
                </div>
            </div>
        )
    }

    InlineData ({ name, value, shouldHighlight }) {
        return (
            <div className='cross-account-balance-item--inline-data'>
                <span className='cross-account-balance-item--inline-data--name'>{name}</span>
                <div className={'cross-account-balance-item--inline-data--value' + (shouldHighlight ? ' highlight' : '')}>{value}</div>
            </div>
        )
    }

    render () {
        const { crossAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName, shouldShowDetail } = this.props
        return (
            <div className='cross-account-balance-item'>
                <div className='cross-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='cross-account-balance-item--header--info-bar'>
                        <div className='cross-account-balance-item--header--info-bar--account-type cross'>{'CROSS'}</div>
                        <div className='cross-account-balance-item--header--info-bar--timestamp'>{moment(crossAccountBalance.timestamp).format('HH:mm:ss')}</div>
                    </div>}
                    <div className='cross-account-balance-item--coin'>
                        {crossAccountBalance.coin}
                        {shouldShowAccountName ? ` @ ${crossAccountBalance.acct_name}` : ''}
                    </div>
                    {!shouldShowAccountTypeTag && <div className='cross-account-balance-item--timestamp'>{moment(crossAccountBalance.timestamp).format('HH:mm:ss')}</div>}
                </div>
                {shouldShowDetail && 
                <div className='cross-account-balance-item--rows'>
                    {this.Row({
                        name: 'Discounted Equity',
                        value: Number(crossAccountBalance.disEq),
                        displayValue: toNumberWithSmartPrecision({ number: crossAccountBalance.disEq, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {this.Row({
                        name: 'Frozen Balance',
                        value: Number(crossAccountBalance.frozenBal),
                        displayValue: toNumberWithSmartPrecision({ number: crossAccountBalance.frozenBal, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {this.Row({
                        name: 'Liability',
                        value: Number(crossAccountBalance.liab),
                        displayValue: toNumberWithSmartPrecision({ number: crossAccountBalance.liab, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                    })}
                    {this.Row({
                        name: 'Cross Liability',
                        value: Number(crossAccountBalance.crossLiab),
                        displayValue: toNumberWithSmartPrecision({ number: crossAccountBalance.crossLiab, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                    })}
                    {this.Row({
                        name: 'Liab. due to UPL',
                        value: Number(crossAccountBalance.uplLiab),
                        displayValue: toNumberWithSmartPrecision({ number: crossAccountBalance.uplLiab, shouldReturnLocalString: true, shouldApplyFloorValue: true }),
                    })}
                </div>}
                <div className='cross-account-balance-item--summary'>
                    {this.InlineData({
                        name: 'UPL',
                        value: toNumberWithSmartPrecision({ number: crossAccountBalance.upl, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(crossAccountBalance.upl)) >= 1000 ? 0 : 2  })
                    })}
                    {this.InlineData({
                        name: 'Avail. Equity',
                        value: toNumberWithSmartPrecision({ number: crossAccountBalance.availEq, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(crossAccountBalance.availEq)) >= 1000 ? 0 : 2 })
                    })}
                    {this.InlineData({
                        name: 'Equity',
                        value: toNumberWithSmartPrecision({ number: crossAccountBalance.eq, shouldReturnLocalString: true, shouldApplyFloorValue: true, defaultPrecision: Math.abs(Number(crossAccountBalance.eq)) >= 1000 ? 0 : 2 })
                    })}
                </div>
            </div>
        )
    } 
}

CrossAccountBalanceItem.propTypes = {
    crossAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool,
    shouldShowDetail: PropTypes.bool
}

CrossAccountBalanceItem.defaultProps = {
}