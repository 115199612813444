import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import uudiv4 from 'uuid/v4'
import moment from 'moment'
import _ from 'lodash'

import { FiPhone } from 'react-icons/fi'
import { MdSpeakerPhone } from 'react-icons/md'

import Popup from '../common/popup/Popup'
import SupportRequestEditor from './SupportRequestEditor'
import { getPortfolioNames } from '../../util/accountUtil'
import { muteAlertServer } from '../setting/settingAction'
import { fetchPortfolioDetails, postAlertAllTraders } from './supportAction'

const DEFAULT_MUTE_DURATION_IN_MINUTES = 15
const PORTFOLIO_PUSHOVER_STATE = {
    NORMAL: 'NORMAL',
    MUTED: 'MUTED',
    UNKOWN: 'UNKOWN'
}

class AlertManagementPopup extends Component {

    constructor (props) {
        super(props)
        this.state = {
            isFetchingPortfolioDetails: false,
            portfolioDetails: {},
            portfolioMuteDurationInputs: {},
            isAlertAllTradersSuccess: false,
            isAlertAllTradersFailed: false
        }
        this._mounted = false
        this.polling = null
        this.alertAllTradersId = null
    }

    componentDidMount () {
        this._mounted = true
    }

    componentWillUnmount () {
        this._mounted = false
        if (this.polling) {
            window.clearInterval(this.polling)
        }
    }

    _getPortfolioDetails () {
        const { dispatch } = this.props
        const { isFetchingPortfolioDetails } = this.state
        if (!isFetchingPortfolioDetails && this._mounted) {
            this.setState({ isFetchingPortfolioDetails: true })
            dispatch(fetchPortfolioDetails())
            .then(details => {
                if (this._mounted) {
                    this.setState({ portfolioDetails: _.keyBy(details, 'name') })
                }
            })
            .finally(() => {
                if (this._mounted) {
                    this.setState({ isFetchingPortfolioDetails: false })
                }
            })
        }
    }

    handleClickMuteButton (portfolio='', duration=300000) {
        const { dispatch } = this.props
        if (!_.isEmpty(portfolio) && duration > 0) {
            dispatch(muteAlertServer(portfolio, duration))
            .finally(() => {
                this._getPortfolioDetails()
            })
        }
    }

    handleClickNotifyAllButton () {
        const { dispatch } = this.props
        const postId = uudiv4()
        this.alertAllTradersId = postId
        this.setState({
            isAlertAllTradersSuccess: false,
            isAlertAllTradersFailed: false
        })
        dispatch(postAlertAllTraders())
        .then(() => {
            if (this._mounted && this.alertAllTradersId === postId) {
                this.setState({ 
                    isAlertAllTradersSuccess: true,
                    isAlertAllTradersFailed: false
                })
            }
        })
        .catch(() => {
            if (this._mounted && this.alertAllTradersId === postId) {
                this.setState({ 
                    isAlertAllTradersSuccess: false,
                    isAlertAllTradersFailed: true
                })
            }
        })
    }

    render () {
        const { portfolioMuteDurationInputs, portfolioDetails, isFetchingPortfolioDetails, isAlertAllTradersSuccess, isAlertAllTradersFailed } = this.state
        const portfolioNames = getPortfolioNames().sort()
        return (
            <Popup className={'alert-management-popup'} 
                on={'click'}
                align={'horizontal'}
                trigger={<div className='alert-management-popup--trigger'><FiPhone /></div>}
                onOpen={() => {
                    this._getPortfolioDetails()
                    this.polling = setInterval(() => {
                        this._getPortfolioDetails()
                    }, 10000)
                }}
                onClose={() => {
                    this.setState({
                        isAlertAllTradersSuccess: false,
                        isAlertAllTradersFailed: false
                    })
                    if (this.polling) {
                        window.clearInterval(this.polling)
                    }
                }}>
                <div className='alert-management-popup--main'>
                    <section className='alert-management-popup--section'>
                        <div className='alert-management-popup--section--title clearfix'>
                            {'Alert Management'}
                            <span className='alert-management-popup--is-fetching-details'>{isFetchingPortfolioDetails ? 'fetching...' : ''}</span>
                        </div>
                        <div className='alert-management-popup--section--main'>
                            <div className='alert-management-popup--portfolio-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{'Portfolio'}</th>
                                            <th>{'State'}</th>
                                            <th>{'Muted Until'}</th>
                                            <th>{'Update By'}</th>
                                            <th>{'Mute Duration (Mins)'}</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {_.map(portfolioNames, portfolioName => {
                                            const muteDurationInput = _.has(portfolioMuteDurationInputs, portfolioName) ? portfolioMuteDurationInputs[portfolioName] : DEFAULT_MUTE_DURATION_IN_MINUTES
                                            const portfolioDetailItem = portfolioDetails[portfolioName] || {}
                                            const { pushover_mute_ts, mute_operation_user } = portfolioDetailItem
                                            const now = moment()
                                            const portfolioPushoverState = _.isNil(pushover_mute_ts) ? PORTFOLIO_PUSHOVER_STATE.UNKOWN
                                                : moment(pushover_mute_ts).isAfter(now) ? PORTFOLIO_PUSHOVER_STATE.MUTED
                                                : PORTFOLIO_PUSHOVER_STATE.NORMAL
                                            return portfolioName !== 'dp' && (
                                                <tr key={portfolioName}>
                                                    <td>{portfolioName}</td>
                                                    <td>
                                                        <div className='alert-management-popup--state'>
                                                            <span className={portfolioPushoverState === PORTFOLIO_PUSHOVER_STATE.UNKOWN ? 'unkown' : portfolioPushoverState === PORTFOLIO_PUSHOVER_STATE.MUTED ? 'muted' : null}/>
                                                            <label>{_.capitalize(_.lowerCase(portfolioPushoverState))}</label>
                                                        </div>
                                                    </td>
                                                    <td>{portfolioPushoverState === PORTFOLIO_PUSHOVER_STATE.MUTED ? moment(pushover_mute_ts).format('MM-DD HH:mm:ss') : 'N/A'}</td>
                                                    <td>{mute_operation_user || ''}</td>
                                                    <td>
                                                        <input className='alert-management-popup--duration-input' 
                                                            type={'number'}
                                                            placeholder={'15 Mins'}
                                                            value={muteDurationInput}
                                                            min={1}
                                                            max={45}
                                                            onKeyPress={(e) => { ['-', 'e', '+', '.'].includes(e.key) && e.preventDefault() }}
                                                            onChange={(e) => {
                                                                const newValue = e.target.value.trim().length > 0 ? _.clamp(e.target.value, 1, 45) : ''
                                                                this.setState({ portfolioMuteDurationInputs: dotProp.set(portfolioMuteDurationInputs, portfolioName, newValue) })
                                                            }} />
                                                    </td>
                                                    <td>
                                                        <button className='alert-management-popup--mute-button'
                                                            disabled={Number(muteDurationInput) <= 0 || Number(muteDurationInput) > 45}
                                                            onClick={() => {
                                                                this.handleClickMuteButton(portfolioName, muteDurationInput * 60 * 1000)
                                                            }}>{'Ack & Mute'}</button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <button className='alert-management-popup--notify-traders-button' 
                                onClick={() => {
                                    this.handleClickNotifyAllButton()
                                }}>
                                <MdSpeakerPhone />
                                {'Notify All Traders'}
                            </button>
                            {(isAlertAllTradersSuccess || isAlertAllTradersFailed) && <div className='alert-management-popup--notify-traders-message'>
                                {isAlertAllTradersSuccess ? 'OK! Request is submitted.' : 'Oops, request is failed.'}
                            </div>}
                        </div>
                    </section>
                    <section className='alert-management-popup--section'>
                        <div className='alert-management-popup--section--title'>{'Request IT support'}</div>
                        <div className='alert-management-popup--section--main request-support'>
                            <SupportRequestEditor />
                        </div>
                    </section>
                </div>
            </Popup>
        )
    }
}

AlertManagementPopup.propTypes = {
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(AlertManagementPopup)