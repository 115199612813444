import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import { ALL_PROFILE_GROUP_ID } from '../../configs/profileConfig'

import { UPDATE_HOSTNAME_PROFILE_STATE, ADD_PROFILE_ITEM, ADD_PROFILE_ITEMS, UPDATE_PROFILE_ITEM, UPDATE_PROFILE_ITEMS,
	ADD_PROFILE_GROUP_ITEM, UPDATE_PROFILE_GROUP_ITEM, UPDATE_PROFILE_GROUP,
	UPDATE_PROFILE_GROUP_FOCUSED_ID, UPDATE_PROFILE_GROUP_IDS, REMOVE_PROFILE_GROUP_ITEM,
	UPDATE_PROFILE_RUNNING_STATE, UPDATE_PROFILE_RUNNING_STATES, COPY_PROFILE_SUCCESS,
	UPDATE_PROFILE_SEARCH_STRING, UPDATE_PROFILE_STOPPED_UPDATING_SYMBOL_PRICINGS,
	UPDATE_PROFILE_ORDER_EDITOR_VARIABLES, UPDATE_USER_PROFILES, UPDATE_SIGNED_SWITCHED_OFF_PROFILE_SYMBOLS,
	HostnameProfileState } from './profileAction'
import { UPDATE_WEB_SOCKET_BUFFERED_PROFILE_STATE_DATA } from '../webSocket/webSocketAction'
import { AUTH_LOGOUT } from '../auth/authAction'
import { SERVERS } from '../../configs/config'

const initialState = {
	searchString: '',
	items: {},
    hostnameProfileState: _.reduce(SERVERS, (result, server) => {
        if (server.enabled) {
            result[server.hostname] = HostnameProfileState({})
        }
        return result
    }, {}),
	runningState: {},
	group: {
		focusedId: ALL_PROFILE_GROUP_ID,
		items: {
			[ALL_PROFILE_GROUP_ID]: {
				id: ALL_PROFILE_GROUP_ID,
				name: 'All Profiles',
				profileIds: [],
				editable: false
			}
		},
		ids: [ALL_PROFILE_GROUP_ID]
	},
	stoppedUpdatingSymbolPricings: [],
	userProfiles: {
		items: {},
		lastUpdateTime: null,
		lastUpdateBy: null,
		lastUpdateId: null
	},
	signedSwitchedOffProfileSymbols: {
		items: [],
		lastUpdateTime: null,
		lastUpdateBy: null,
		lastUpdateId: null
	},
	orderEditorVariables: {
		global_margin_ratio_threshold: null
	}
}

export function profileReducer (state = initialState, action) {
	let newState
    switch(action.type) {

        case UPDATE_HOSTNAME_PROFILE_STATE:
            return dotProp.merge(state, `hostnameProfileState.${action.hostname}`, action.params)

        case ADD_PROFILE_ITEM:
			newState = dotProp.set(state, `items.${action.profile.id}`, action.profile)
			newState.group.items[ALL_PROFILE_GROUP_ID].profileIds.unshift(action.profile.id)
			newState.group.focusedId = ALL_PROFILE_GROUP_ID
			return newState
			
		case ADD_PROFILE_ITEMS:
			newState = dotProp.merge(state, 'items', action.profiles)
			newState.group.items[ALL_PROFILE_GROUP_ID].profileIds = _.union(state.group.items[ALL_PROFILE_GROUP_ID].profileIds, Object.keys(action.profiles))
			return newState

        case UPDATE_PROFILE_ITEM:
			newState = dotProp.merge(state, `items.${action.profileId}`, action.params)
			if (!state.group.items[ALL_PROFILE_GROUP_ID].profileIds.includes(action.profileId)) {
				newState.group.items[ALL_PROFILE_GROUP_ID].profileIds.push(action.profileId)
			}
			return newState

		case UPDATE_PROFILE_ITEMS:
			newState = _.cloneDeep(state)
			_.forEach(action.profileItems, (profileItem, profileId) => {
				newState.items[profileId] = Object.assign({}, state.items[profileId] || {}, profileItem)
			})
			newState.group.items[ALL_PROFILE_GROUP_ID].profileIds = _.union(state.group.items[ALL_PROFILE_GROUP_ID].profileIds, Object.keys(action.profileItems))
			return newState

		case UPDATE_PROFILE_GROUP:
			newState = dotProp.merge(state, 'group', action.profileGroup)
			return newState

		case ADD_PROFILE_GROUP_ITEM:
			newState = dotProp.set(state, `group.items.${action.group.id}`, action.group)
			newState.group.ids.push(action.group.id)
			newState.group.focusedId = action.group.id
			return newState
			
		case UPDATE_PROFILE_GROUP_ITEM:
			newState = dotProp.merge(state, `group.items.${action.groupId}`, action.params)
			return newState

		case UPDATE_PROFILE_GROUP_FOCUSED_ID:
			newState = dotProp.set(state, 'group.focusedId', action.groupId)
			newState.searchString = ''
			return newState

		case UPDATE_PROFILE_GROUP_IDS:
			newState = dotProp.set(state, 'group.ids', action.groupIds)
			return newState

		case REMOVE_PROFILE_GROUP_ITEM:
			newState = dotProp.delete(state, `group.items.${action.groupId}`)
			_.pull(newState.group.ids, action.groupId)
			newState.group.focusedId = ALL_PROFILE_GROUP_ID
			return newState

		case UPDATE_PROFILE_RUNNING_STATE:
			return dotProp.merge(state, `runningState.${action.profileId}`, action.params)

		case UPDATE_PROFILE_RUNNING_STATES:
			newState = _.cloneDeep(state)
			_.forEach(action.runningStates, (runningState, profileId) => {
				newState.runningState[profileId] = Object.assign({}, state.runningState[profileId] || {}, runningState)
			})
			return newState

		case UPDATE_WEB_SOCKET_BUFFERED_PROFILE_STATE_DATA:
			newState = _.cloneDeep(state)
			_.forEach(action.profileItems, (profileItem, profileId) => {
				newState.items[profileId] = Object.assign({}, state.items[profileId] || {}, profileItem)
			})
			_.forEach(action.runningStates, (runningState, profileId) => {
				newState.runningState[profileId] = Object.assign({}, state.runningState[profileId] || {}, runningState)
			})
			newState.group.items[ALL_PROFILE_GROUP_ID].profileIds = _.union(state.group.items[ALL_PROFILE_GROUP_ID].profileIds, Object.keys(action.profileItems))
			return newState

		case COPY_PROFILE_SUCCESS:
			newState = _.cloneDeep(state)
			newState.items[action.newProfileItem.id] = Object.assign({}, state.items[action.newProfileItem.id], action.newProfileItem)

			_.pull(newState.group.items[ALL_PROFILE_GROUP_ID].profileIds, action.newProfileItem.id)
			.splice(_.findIndex(state.group.items[ALL_PROFILE_GROUP_ID].profileIds, v => v === action.originalProfileItem.id) + 1, 0, action.newProfileItem.id)

			if (state.group.focusedId !== ALL_PROFILE_GROUP_ID && state.group.items[state.group.focusedId].profileIds.includes(action.originalProfileItem.id)) {
				_.pull(newState.group.items[state.group.focusedId].profileIds, action.newProfileItem.id)
				.splice(_.findIndex(state.group.items[state.group.focusedId].profileIds, v => v === action.originalProfileItem.id) + 1, 0, action.newProfileItem.id)
			}
			return newState

		case UPDATE_PROFILE_SEARCH_STRING:
			newState = dotProp.set(state, 'searchString', action.searchString)
			if (action.shouldSwitchToAllProfileGroup) {
				newState = dotProp.set(newState, 'group.focusedId', ALL_PROFILE_GROUP_ID)
			}
			return newState

		case UPDATE_PROFILE_STOPPED_UPDATING_SYMBOL_PRICINGS:
			return dotProp.set(state, 'stoppedUpdatingSymbolPricings', action.stoppedUpdatingSymbolPricings)

		case UPDATE_PROFILE_ORDER_EDITOR_VARIABLES:
			return dotProp.merge(state, 'orderEditorVariables', action.variables)

		case UPDATE_USER_PROFILES:
			return dotProp.set(state, 'userProfiles', action.userProfiles)

		case UPDATE_SIGNED_SWITCHED_OFF_PROFILE_SYMBOLS:
			return dotProp.set(state, 'signedSwitchedOffProfileSymbols', action.signedSwitchedOffProfileSymbols)

		case AUTH_LOGOUT:
			return initialState
			
        default:
            return state
    }
}