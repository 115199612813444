import _ from 'lodash'

import { secureFetch, getQueryString } from '../../util/util'
import { DEFAULT_SERVER, ELF_API_BASE_URL } from '../../configs/config'

export const UPDATE_SYMBOL_ITEMS = 'UPDATE_SYMBOL_ITEMS'
export const UPDATE_SYMBOL_PRICINGS = 'UPDATE_SYMBOL_PRICINGS'
export const UPDATE_SYMBOL_FUNDING_RATES = 'UPDATE_SYMBOL_FUNDING_RATES'
export const UPDATE_OPTION_IMPLIED_VOLATILITIES = 'UPDATE_OPTION_IMPLIED_VOLATILITIES'
export const UPDATE_FUNDING_RATE_HISTORY = 'UPDATE_FUNDING_RATE_HISTORY'
export const UPDATE_FUNDING_RATE_HISTORY_SYMBOLS = 'UPDATE_FUNDING_RATE_HISTORY_SYMBOLS'
export const UPDATE_DEFI_LENDING_INFO = 'UPDATE_DEFI_LENDING_INFO'
export const UPDATE_SYMBOL_ORDER_BOOKS = 'UPDATE_SYMBOL_ORDER_BOOKS'
export const REMOVE_SYMBOL_ORDER_BOOK = 'REMOVE_SYMBOL_ORDER_BOOK'
export const UPDATE_OPTION_SYMBOL_DELTA = 'UPDATE_OPTION_SYMBOL_DELTA'
export const UPDATE_OPTION_COIN_DELTA = 'UPDATE_OPTION_COIN_DELTA'
export const UPDATE_FUNDING_TAGS = 'UPDATE_FUNDING_TAGS'

export function fetchSymbols () {
    return (dispatch) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/symbols`, {
            method: 'GET'
        })).then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    const symbolItems = _.keyBy(body.info, 'symbol_name')
                    dispatch({
                        type: UPDATE_SYMBOL_ITEMS,
                        symbols: symbolItems
                    })
                })
            }
        })
    }
}

export function fetchSymbolPricings () {
    return (dispatch, getState) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/tickersinfo`, {
            method: 'GET'
        })).then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    if (_.isArray(body.info) && !_.isEmpty(body.info)) {
                        const { pricings } = getState().symbol
                        const newPricings = {}
                        body.info.forEach((symbolItem) => {
                            const symbolName = symbolItem.symbol
                            const pricingItem = pricings[symbolName]
                            newPricings[symbolName] = Object.assign({}, pricingItem || {}, {
                                symbolName,
                                last: symbolItem.last,
                                bid: _.has(pricingItem, 'bid') ? pricingItem.bid : symbolItem.bid,
                                ask: _.has(pricingItem, 'ask') ? pricingItem.ask : symbolItem.ask,
                                timestamp: _.has(pricingItem, 'timestamp') && pricingItem.timestamp > symbolItem.ts ? pricingItem.timestamp : symbolItem.ts
                            })
                        })
                        dispatch(updateSymbolPricings(newPricings))
                    }
                })
            }
        })
    }
}

export function fetchSymbolFundingRates () {
    return (dispatch) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/funding-rate`, {
            method: 'GET'
        })).then((response) => {
            if (response.status === 200) {
                response.json().then((body) => {
                    dispatch({
                        type: UPDATE_SYMBOL_FUNDING_RATES,
                        fundingRates: _.keyBy(body.info, 'symbol')
                    })
                })
            }
        })
    }
}

export function updateSymbolPricings (pricings) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SYMBOL_PRICINGS,
            pricings
        })
    }
}

export function fetchOptionImpliedVolatilities () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/option-implied-volatilities`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_OPTION_IMPLIED_VOLATILITIES,
                    optionImpliedVolatilities: body
                })
            } else {
                throw new Error('unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchOptionImpliedVolatilities error: ', error)
        })
    }
}

export function fetchOptionSymbolDelta () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/delta`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_OPTION_SYMBOL_DELTA,
                    optionSymbolDelta: _.keyBy(body, 'prod_name')
                })
            } else {
                throw new Error('unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchOptionSymbolDelta error: ', error)
        })
    }
}

export function fetchOptionCoinDelta () {
    return (dispatch) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/sum-delta`))
        .then(response => response.json())
        .then(body => {
            if (_.isArray(body)) {
                dispatch({
                    type: UPDATE_OPTION_COIN_DELTA,
                    optionCoinDelta: _.groupBy(body, item => item.currency.toUpperCase())
                })
            } else {
                throw new Error('unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchOptionCoinDelta error: ', error)
        })
    }
}

export function fetchFundingRateHistory (symbols=[], from) {
    return (dispatch) => new Promise((resovle, reject) => {
        const queryString = getQueryString({
            symbols: symbols.join(),
            from
        }) 
        dispatch(secureFetch(`${ELF_API_BASE_URL}/funding-rate-history?${queryString}`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                reject({ error: `Response status: ${response.status}` })
            }
        })
        .then(body => {
            dispatch({
                type: UPDATE_FUNDING_RATE_HISTORY,
                fundingRateHistory: body
            })
            resovle(body)
        })
        .catch(error => {
            console.error('fetchFundingRateHistory error: ', error)
        })
    })
}

export function fetchFundingRateHistorySymbols () {
    return (dispatch) => new Promise((resolve) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/funding-rate-history/symbols`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error('unexpected status code')
            }
        })
        .then(body => {
            dispatch({
                type: UPDATE_FUNDING_RATE_HISTORY_SYMBOLS,
                symbols: body
            })
            resolve(body)
        })
        .catch(error => {
            console.error('fetchFundingRateHistorySymbols error: ', error)
        })
    }) 
}

export function fetchDefiLendingInfo () {
    return (dispatch) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/defi-lending-info/latest`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            }
        })
        .then(body => {
            dispatch({
                type: UPDATE_DEFI_LENDING_INFO,
                info: body
            })
            resolve(body)
        })
        .catch(error => {
            console.error('fetchDefiLendingInfo error', error)
        })
    })
}

export function updateSymbolOrderBooks (symbolOrderBooks) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SYMBOL_ORDER_BOOKS,
            symbolOrderBooks
        })
    }
}

export function removeSymbolOrderBook (symbolName) {
    return (dispatch) => {
        dispatch({
            type: REMOVE_SYMBOL_ORDER_BOOK,
            symbolName
        })
    }
}

export function fetchFundingTags () {
    return (dispatch) => new Promise(resolve => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/funding-tags`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body.items)) {
                dispatch(updateFundingTags(body.items))
                resolve(body.items)
            }
        })
        .catch(error => {
            console.error('fetchFundingTags error: ', error)
        })
    })
}

export function updateFundingTags (fundingTags=[]) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_FUNDING_TAGS,
            fundingTags
        })
    }
}

export function uploadFundingTags (newFundingTags) {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/funding-tags`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(newFundingTags)
        }))
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
}