import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import uuidv4 from 'uuid/v4'

import { SYMBOLS_WITH_MUTLIPLIER_IN_BTC } from '../../configs/tradingConfig'
import { fetchFills } from './tradingAction'
import { getNotional } from '../../util/tradingUtil'
import { toFixedNumber, toNumberWithSmartPrecision } from '../../util/util'
import { INSTRUMENT_TYPES, getSymbolAttributeByName } from '../../util/symbolUtil'

class FillContainer extends Component {

    constructor (props) {
        super(props)
        this.statisticsGroupBys = {
            NOTIONAL_CURRENCY: {
                value: 'NOTIONAL_CURRENCY',
                name: 'By Notional Currency'
            },
            SYMBOL: {
                value: 'SYMBOL',
                name: 'By Symbol'
            }
        }
        this.state = {
            from: null,
            to: null,
            fills: [],
            searchString: '',
            isFetching: false,
            statisticsGroupBy: this.statisticsGroupBys.NOTIONAL_CURRENCY.value,
        }

        this.fetchID = null
        this.fromInputNode = null
        this.toInputNode = null
        this._isMounted = null
        this.datetimeAPIFormat = 'YYYY-MM-DD HH:mm:ss'
        this.datetimeInputFormat = 'YYYY-MM-DDTHH:mm:ss'
    }

    componentDidMount () {
        this._isMounted = true
        this.getFills(true)
    }

    componentWillUnmount () {
        this._isMounted = false
    }

    _updateTimeRange () {
        const { fills } = this.state
        const fromValue = this.fromInputNode.value
        const toValue = this.toInputNode.value
        if (fromValue && toValue) {
            const newFrom = moment(fromValue).format(this.datetimeAPIFormat)
            const newTo = moment(toValue).format(this.datetimeAPIFormat)
            const clearFills = !_.isEmpty(fills) 
                && !moment(newTo).isBetween(_.last(fills).pending_ts, _.head(fills).pending_ts, null, '[]')
            this.setState({
                from: newFrom,
                to: newTo,
                fills: clearFills ? [] : fills
            })
            if (newFrom <= newTo) {
                this.getFills()
            }
        }
    }

    getFills (once=false) {
        const { dispatch, profileId } = this.props
        const pageSize = 200
        const fetchID = uuidv4()
        const _needFetchOlderFills = (fills, from) => {
            return this._isMounted
                && this.fetchID === fetchID
                && (_.isEmpty(fills)
                    || !from 
                    || moment(_.last(fills).pending_ts).isAfter(from)
                )
        }
        const _getOlderFills = () => {
            const paramTo = !_.isEmpty(this.state.fills)
                ? _.last(this.state.fills).pending_ts
                : this.state.to
            dispatch(fetchFills({
                profileId,
                sortBy: 'pending_ts',
                to: paramTo,
                pageSize
            })).then(body => {
                const { from, to, fills } = this.state
                if (this.fetchID === fetchID) {
                    if (body && _.isArray(body.info)) {
                        const newFills = _.unionBy(fills, body.info, 'order_id')
                        const isFromNil = _.isNil(from)
                        const isToNil = _.isNil(to)
                        let fromMoment, toMoment
                        if (isFromNil && this.fromInputNode) {
                            fromMoment = !_.isEmpty(newFills) && _.last(newFills).pending_ts ? moment(_.last(newFills).pending_ts) : moment()
                            this.fromInputNode.value = fromMoment.format(this.datetimeInputFormat)
                        }
                        if (isToNil && this.toInputNode) {
                            toMoment = !_.isEmpty(newFills) && _.head(newFills).pending_ts ? moment(_.head(newFills).pending_ts) : moment()
                            this.toInputNode.value = toMoment.format(this.datetimeInputFormat)
                        }
                        const needFetchMoreFills  = !once && body.info.length >= pageSize && _needFetchOlderFills(newFills, fromMoment || from)
                        this.setState({
                            fills: newFills,
                            isFetching: needFetchMoreFills,
                            from: isFromNil && fromMoment ? fromMoment.format(this.datetimeAPIFormat) : from,
                            to: isToNil && toMoment ? toMoment.format(this.datetimeAPIFormat) : to
                        })
                        if (needFetchMoreFills) {
                            _getOlderFills()
                        }
                    } else {
                        this.setState({ isFetching: false })
                    }
                }
            })
        }

        this.fetchID = fetchID
        if (_needFetchOlderFills(this.state.fills, this.state.from)) {
            if (!this.state.isFetching) {
                this.setState({ isFetching: true })
            }
            _getOlderFills()
        }
    }

    stopFetching () {
        this.fetchID = null
        this.setState({ isFetching: false })
    }

    _getFillStats (fills = []) {
        const { statisticsGroupBy } = this.state
        const { symbolItems, pricings } = this.props
        const InitialSideStats = () => {
            return {
                notionalSum: 0,
                qtySumInUnit: 0,
                qtySumInAsset: 0
            }
        }
        const stats = {}
        const USDGroupCurrencies = ['USD', 'USDT', 'BUSD']
        const USDGroupKey = 'USD | USDT | BUSD'

        fills.forEach(fill => {
            const { prod_name, side, qty, left_qty, avg_fill_price } = fill
            const symbolItem = symbolItems[prod_name]
            if (symbolItem) {
                const qtyFilled = qty - left_qty
                const { base, quote } = getSymbolAttributeByName(prod_name)
                const key = statisticsGroupBy === this.statisticsGroupBys.NOTIONAL_CURRENCY.value ? quote : prod_name  
                const shouldMergeUSDGroup = USDGroupCurrencies.includes(key)
                
                if (shouldMergeUSDGroup && !_.has(stats, USDGroupKey)) {
                    stats[USDGroupKey] = {
                        asset: base,
                        notionalCurrency: USDGroupKey,
                        BUY: InitialSideStats(),
                        SELL: InitialSideStats(),
                        isMergedGroup: true
                    }
                }
                if (!_.has(stats, key)) {
                    stats[key] = {
                        asset: base,
                        notionalCurrency: quote,
                        BUY: InitialSideStats(),
                        SELL: InitialSideStats(),
                        isMerged: false
                    }
                }
                const statsSide = side.includes('BUY') ? 'BUY' : 'SELL'
                const notional = getNotional({
                    symbolItem: symbolItem,
                    quantity: qtyFilled,
                    price: avg_fill_price,
                    BTCUSDIndexLastPrice: SYMBOLS_WITH_MUTLIPLIER_IN_BTC.includes(prod_name) && _.has(pricings, 'btc_usd_OKEX_INDEX.last') 
                        ? pricings['btc_usd_OKEX_INDEX'].last 
                        : null
                })
                stats[key][statsSide].notionalSum += notional
                stats[key][statsSide].qtySumInUnit += qtyFilled
                stats[key][statsSide].qtySumInAsset += (notional > 0 ? notional/avg_fill_price : 0)

                if (shouldMergeUSDGroup) {
                    stats[USDGroupKey][statsSide].notionalSum += notional
                    stats[USDGroupKey][statsSide].qtySumInUnit += qtyFilled
                    stats[USDGroupKey][statsSide].qtySumInAsset += (notional > 0 ? notional/avg_fill_price : 0)
                }
            }
        })
        
        if (statisticsGroupBy === this.statisticsGroupBys.NOTIONAL_CURRENCY.value 
            && _.filter(Object.keys(stats), notionalCurrency => USDGroupCurrencies.includes(notionalCurrency)).length === 1) {
            delete stats[USDGroupKey]
        }
        return stats
    }

    AggregatedStats (fills) {
        const { statisticsGroupBy } = this.state 
        const stats = this._getFillStats(fills)

        const renderFillStatsItem = ({ statsItem, name, showQtyInUnit=true }) => {

            const avgBuyPrice = statsItem.BUY.notionalSum/statsItem.BUY.qtySumInAsset
            const avgSellPrice = statsItem.SELL.notionalSum/statsItem.SELL.qtySumInAsset
            return (
                <div className='fill-stats-item'>
                    {name && <div className='fill-stats-item--name'>{name}</div>}
                    <table className='fill-stats-item--table'>
                        <thead>
                            <tr><th>{'Direction'}</th><th>{'BUY'}</th><th>{'SELL'}</th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{`Notional Sum (${statsItem.notionalCurrency})`}</th>
                                <td>{toNumberWithSmartPrecision({ number: statsItem.BUY.notionalSum, shouldReturnLocalString: true })}</td>
                                <td>{toNumberWithSmartPrecision({ number: statsItem.SELL.notionalSum, shouldReturnLocalString: true })}</td>
                            </tr>
                            {showQtyInUnit && <tr>
                                <th>{`Qty Sum (Unit)`}</th>
                                <td>{toNumberWithSmartPrecision({ number: statsItem.BUY.qtySumInUnit })}</td>
                                <td>{toNumberWithSmartPrecision({ number: statsItem.SELL.qtySumInUnit })}</td>
                            </tr>}
                            <tr>
                                <th>{`Qty Sum (${statsItem.asset})`}</th>
                                <td>{toNumberWithSmartPrecision({ number: statsItem.BUY.qtySumInAsset })}</td>
                                <td>{toNumberWithSmartPrecision({ number: statsItem.SELL.qtySumInAsset })}</td>
                            </tr>
                            <tr>
                                <th>{`Avg Price`}</th>
                                <td>{statsItem.BUY.qtySumInAsset === 0 ? 'N/A' : parseInt(avgBuyPrice) >= 10000 ? avgBuyPrice.toFixed(2) : (avgBuyPrice).toPrecision(5)}</td>
                                <td>{statsItem.SELL.qtySumInAsset === 0 ? 'N/A' : parseInt(avgSellPrice) >= 10000 ? avgSellPrice.toFixed(2) : (avgSellPrice).toPrecision(5)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )
        }

        return (
            <div className='fill-container--aggregated-stats' onDoubleClick={(e) => { e.stopPropagation() }}>
                <div className='fill-container--aggregated-stats--body'>
                    <div className='fill-container--aggregated-stats--title'>{'Statistics'}</div>
                    <div className='fill-container--aggregated-stats--group-by-selectors'>
                        {_.map(this.statisticsGroupBys, group => {
                            return (
                                <button className={'fill-container--aggregated-stats--group-by-selector' + (statisticsGroupBy === group.value ? ' active' : '')} 
                                    key={group.value}
                                    onClick={() => { this.setState({ statisticsGroupBy: group.value }) }}>
                                    {group.name}
                                </button>
                            )
                        })}
                    </div>
                    <div className='fill-container--aggregated-stats--main'>
                        {_.map(stats, (statsItem, key) => {
                            return (
                                <div className='fill-container--aggregated-stats--item' key={key}>
                                    {renderFillStatsItem({
                                        statsItem: statsItem,
                                        name: statsItem.isMergedGroup ? `Merged ${key}` : key,
                                        showQtyInUnit: statisticsGroupBy === this.statisticsGroupBys.SYMBOL.value 
                                            && getSymbolAttributeByName(key).instrumentType !== INSTRUMENT_TYPES.SPOT
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    FillItem (fillItem) {
        const { symbolItems, pricings } = this.props
        const { order_id, order_ref, tag_name, prod_name, acct_name, side, qty, left_qty, accu_cog_cancel_cnt,
            price, avg_fill_price, fee, pending_ts, fill_ts, status } = fillItem
        const symbolItem = symbolItems[prod_name]
        const BTCUSDIndexLastPrice = _.has(pricings, 'btc_usd_OKEX_INDEX.last') ? pricings['btc_usd_OKEX_INDEX'].last : null
        const { quote } = getSymbolAttributeByName(prod_name)
        const fillDiff = fill_ts > pending_ts ? moment(fill_ts).diff(pending_ts, 'seconds') : 0
        const notional = getNotional({
            symbolItem: symbolItem,
            quantity: qty - left_qty,
            price: avg_fill_price,
            BTCUSDIndexLastPrice: BTCUSDIndexLastPrice
        })
        
        let morePriceInfoHtml = `Notional: <b>${toFixedNumber(notional, 2)}</b> ${quote.toUpperCase()}`
        if (Number(fee) > 0) {
            morePriceInfoHtml = `Fee: <b>${fee}</b>, ${morePriceInfoHtml}`
        }
        if (avg_fill_price !== fillItem.price) {
            morePriceInfoHtml = `Avg: <b>${avg_fill_price }</b>, ${morePriceInfoHtml}`
        }
        const renderInfoRow = (name, value, className) => {
            return (
                <div className={'fill-item--info-row' + (className ? ` ${className}` : '')}>
                    <div className='fill-item--info-row--name'>{name}</div>
                    <div className='fill-item--info-row--value' dangerouslySetInnerHTML={{__html: value}} />
                </div>
            )
        }

        return (
            <div className='fill-item'>
                <div className='fill-item--identity'>
                    <div className='fill-item--identity--id'>
                        {'ID: '}<span>{order_id}</span>
                    </div>
                    <div className='fill-item--identity--ref'>
                        {'REF: '}<span>{order_ref}</span>
                    </div>
                </div>
                <div className='fill-item--tags'>
                    <div className={`fill-item--tags--tag-name ${tag_name}`}>{tag_name}</div>
                    <div className={`fill-item--tags--status ${status}`}>
                        {Number(accu_cog_cancel_cnt) > 0 && <span className='fill-item--tags--status--cog-cancel-count'>{`${accu_cog_cancel_cnt} COG Cancel`}</span>}
                        {status}
                    </div>
                </div>
                <div className='fill-item--main'>
                    <div className='fill-item--main--body'>
                        <div className={`fill-item--side ${side}`}>{side}</div>
                        <div className='fill-item--qty'>{qty}{left_qty > 0 && <span>{`(${left_qty} Left)`}</span>}</div>
                        <div className='fill-item--symbol'>{prod_name}</div>
                        <div className='fill-item--account'>
                            {'By '}<span>{acct_name}</span>
                        </div>
                    </div>
                    <div className='fill-item--prices'>
                        <div className='fill-item--fill-price'>
                            {'@ '}<span>{price}</span>
                        </div>
                        <div className='fill-item--price-info' dangerouslySetInnerHTML={{__html: `(${morePriceInfoHtml})`}} />
                    </div>
                </div>
                <div className='fill-item--table-info'>
                    {renderInfoRow('Pending', moment(pending_ts).format('YYYY-MM-DD HH:mm:ss'))}
                    {fill_ts > '1971' && 
                    renderInfoRow('Fill', (fillDiff > 0 ? `<span>(+${fillDiff}s)</span>` : '') + moment(fill_ts).format('YYYY-MM-DD HH:mm:ss'))}
                </div>
            </div>
        )
    }

    Header () {
        const { searchString, from, to, isFetching } = this.state
        const TimeRange = (side='from') => {
            return (
                <div className={`fill-container--header--time-range ${side}`}>
                    <span className='fill-container--header--time-range--side'>{side}</span>
                    <input className='fill-container--header--time-rage--input' 
                        ref={(node) => { this[side === 'from' ? 'fromInputNode' : 'toInputNode'] = node }}
                        disabled={isFetching}
                        type={'datetime-local'}
                        defaultValue={side === 'from' ? from : to} 
                        step={1} 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                this._updateTimeRange()
                            }
                        }} />
                </div>
            )
        }

        return (
            <div className='fill-container--header'>
                <input className='fill-container--header--search-input' 
                    type={'text'}
                    spellCheck={false}
                    placeholder={'Search Symbol/Account/Side/Tag'} 
                    value={searchString}
                    onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                <div className='fill-container--header--time-ranges'>
                    <div className='fill-container--header--time-ranges--inputs'>
                        {TimeRange('from')}
                        <span>{'-'}</span>
                        {TimeRange('to')}
                    </div>
                    <button className='fill-container--header--ok-button' onClick={() => {
                        setTimeout(() => {
                            this._updateTimeRange()
                        }, 20)
                    }}>{'OK'}</button>
                </div>
            </div>
        )
    }

    render () {
        const { profileId, profileItems } = this.props
        const { fills, isFetching, searchString, from, to } = this.state
        const profileItem = profileItems[profileId]
        const trimmedSearchString = searchString.toLowerCase().trim()
        const filteredFromMoment = moment(from)
        const filteredToMoment = moment(to).endOf('second')
        const filteredFills = fills.filter(f => {
            return moment(f.pending_ts).isBetween(filteredFromMoment, filteredToMoment, null, '[]') &&
                (trimmedSearchString.length === 0
                || _.every(trimmedSearchString.split(' '), token => 
                        `${f.prod_name} ${f.acct_name} ${f.side} ${f.tag_name} ${f.status}`.toLowerCase().includes(token)
                    )
                )
            }
        )
        return (
            <Fragment>
                {!_.isEmpty(filteredFills) && this.AggregatedStats(filteredFills)}
                <div className='fill-container' onDoubleClick={(e) => { e.stopPropagation() }}>
                    <div className='fill-container--title'>
                        {'Fills' + (filteredFills.length > 0 ? ` (${filteredFills.length})` : '')}
                        {profileItem && <span className='fill-container--title--profile-name'>{profileItem.name}</span>}
                    </div>
                    {this.Header()}
                    <div className='fill-container--main'>
                        {filteredFills.map((fill, index) => {
                            return (
                                <div className='fill-container--item' key={index}>
                                    {this.FillItem(fill)}
                                </div>
                            )
                        })}
                        {!isFetching && _.isEmpty(filteredFills) && 
                        <div className='fill-container--empty-message'>
                            {'No Matched Fills'}
                        </div>}
                    </div>
                    {isFetching && <div className='fill-container--fetching'>
                        {'Fetching...'}
                        <button className='fill-container--fetching--stop-button' onClick={(e) => { 
                            e.stopPropagation()
                            this.stopFetching() 
                        }}>{'STOP'}</button>
                    </div>}
                </div>
            </Fragment>
        )
    }
}

FillContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileId: PropTypes.string.isRequired,
    symbolItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,
    profileItems: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items,
        pricings: state.symbol.pricings,
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(FillContainer)