import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import dotProp from 'dot-prop-immutable'
import uuidv4 from 'uuid/v4'
import _ from 'lodash'

import { MdAccountBalanceWallet, MdExposure, MdShuffle, MdSwapVerticalCircle } from 'react-icons/md'
import { FiBriefcase, FiTriangle } from 'react-icons/fi'
import { RiBug2Line } from 'react-icons/ri'

import TradeWindow, { clearSessionStorageTradeWindowSubscribedSymbols } from '../trading/TradeWindow'
import NewWindow from 'react-new-window'
import PositionTable from '../trading/PositionTable'
import AccountAvailableBalanceTable from '../account/AccountAvailableBalanceTable'
import AccountBalanceWindow from '../../pages/AccountBalanceWindow'
import OptionWindow from '../symbol/OptionWindow'
import ExposureWindow from '../trading/ExposureWindow'
import BulkTransferWindow from '../trading/BulkTransferWindow'

import { playSound } from '../../util/soundEffectUtil'
import { clearLayoutBulkTransferWindowId, updateLayoutBulkTransferWindowId } from './layoutAction'
import { IoMdStats } from 'react-icons/io'
import ValueAtRiskWindow from '../trading/ValueAtRiskWindow'
import TestWindow from './TestWindow'

class MenuTools extends Component {
    constructor (props) {
        super(props)
        this.state = {
            tradeWindowIds: [],
            positionWindowId: null,
            availableBalanceWindowId: null,
            exposureWindowId: null,
            valueAtRiskWindowId: null,
            accountBalanceMonitorWindowId: null,
            optionStatsWindowId: null,
            testWindowIds: [],

            accountBalancesWithCrossedThreshold: [],
        }
        this.positionWindowNode = null
        this.availableBalanceWindowNode = null

        this.handleUnloadWindow = this.handleUnloadWindow.bind(this)
    }

    componentDidMount () {
        window.addEventListener('beforeunload', this.handleUnloadWindow)
    }

    componentWillUnmount () {
        this.handleUnloadWindow()
        window.removeEventListener('beforeunload', this.handleUnloadWindow)

    }

    handleUnloadWindow () {
        const { dispatch } = this.props
        this.setState({
            tradeWindowIds: [],
            positionWindowId: null,
            exposureWindowId: null,
            valueAtRiskWindowId: null,
            accountBalanceMonitorWindowId: null,
            optionStatsWindowId: null
        })
        dispatch(clearLayoutBulkTransferWindowId())
        clearSessionStorageTradeWindowSubscribedSymbols()
    }

    handleReceiveAccountBalancesMessage (message) {
        const { accountBalancesWithCrossedThreshold } = this.state
        const { soundEffect } = this.props
        const newAccountBalancesWithCrossedThreshold = _.has(message, 'accountBalancesWithCrossedThreshold')
            ? message.accountBalancesWithCrossedThreshold
            : []
        if (!_.isEmpty(_.differenceBy(newAccountBalancesWithCrossedThreshold, accountBalancesWithCrossedThreshold, 'id'))) {
            playSound(soundEffect.events.ACCOUNT_BALANCE_MARGIN_RATIO_WARNING)
        }
        this.setState({ accountBalancesWithCrossedThreshold: newAccountBalancesWithCrossedThreshold })
    }

    Button ({ iconComponent, name, disabled=false, onClickButton=()=>{} }) {
        return (
            <button className='menu-tools--button'
                disabled={disabled}
                onClick={() => { onClickButton() }}>
                {iconComponent}
                <span className='menu-tools--button--name'>{name}</span>
            </button>
        )
    }

    render () {
        const { dispatch } = this.props
        const { tradeWindowIds, positionWindowId, availableBalanceWindowId, exposureWindowId, valueAtRiskWindowId, accountBalanceMonitorWindowId, optionStatsWindowId, testWindowIds } = this.state

        return (
            <div className='menu-tools'>
                <div className='menu-tools--title'>{'Tools'}</div>
                <div className='menu-tools--main'>
                    {this.Button({
                        iconComponent: <MdSwapVerticalCircle />,
                        name: 'Trade' + (tradeWindowIds.length > 0 ? ` (${tradeWindowIds.length} window${tradeWindowIds.length > 1 ? 's' : ''})` : ''),
                        onClickButton: () => { this.setState(prevState => dotProp.merge(prevState, 'tradeWindowIds', uuidv4())) }
                    })}
                    {this.Button({
                        iconComponent: <MdShuffle />,
                        name: 'Bulk Transfer',
                        disabled: false,
                        onClickButton: () => { 
                            dispatch(updateLayoutBulkTransferWindowId()) 
                        }
                    })}
                    {this.Button({
                        iconComponent: <MdAccountBalanceWallet />,
                        name: 'Available Balance',
                        onClickButton: () => {
                            if (this.availableBalanceWindowNode && _.has(this.availableBalanceWindowNode, 'window')) {
                                this.availableBalanceWindowNode.window.focus()
                            } else if (_.isNil(availableBalanceWindowId)) {
                                this.setState({ availableBalanceWindowId: uuidv4() }) 
                            }
                        }
                    })}
                    {this.Button({
                        iconComponent: <FiBriefcase />,
                        name: 'Positions',
                        onClickButton: () => { 
                            if (this.positionWindowNode && _.has(this.positionWindowNode, 'window')) {
                                this.positionWindowNode.window.focus()
                            } else if (_.isNil(positionWindowId)) {
                                this.setState({ positionWindowId: uuidv4() }) 
                            }
                        }
                    })}
                    {this.Button({
                        iconComponent: <MdExposure />,
                        name: 'Exposure & NAV',
                        onClickButton: () => { 
                            this.setState({ exposureWindowId: uuidv4() }) 
                        }
                    })}
                    {this.Button({
                        iconComponent: <IoMdStats />,
                        name: 'Value at Risk',
                        onClickButton: () => { 
                            this.setState({ valueAtRiskWindowId: uuidv4() }) 
                        }
                    })}
                    {/* {this.Button({
                        iconComponent: <MdAccountBalanceWallet />,
                        name: 'Account Balances' + (accountBalancesWithCrossedThreshold.length > 0 ? ` (${accountBalancesWithCrossedThreshold.length})` : ''),
                        onClickButton: () => { 
                            this.setState({ accountBalanceMonitorWindowId: uuidv4() }) 
                        }
                    })} */}
                    {this.Button({
                        iconComponent: <FiTriangle />,
                        name: 'Option Stats',
                        onClickButton: () => { this.setState({ optionStatsWindowId: uuidv4() }) }
                    })}
                    {this.Button({
                        iconComponent: <RiBug2Line />,
                        name: 'Delay Simulator' + (testWindowIds.length > 0 ? ` (${testWindowIds.length} window${testWindowIds.length > 1 ? 's' : ''})` : ''),
                        onClickButton: () => { this.setState(prevState => dotProp.merge(prevState, 'testWindowIds', uuidv4())) }
                    })}
                </div>
                {!_.isEmpty(tradeWindowIds) && 
                <Fragment>
                    {_.map(tradeWindowIds, tradeWindowId => {
                        return (
                            <Fragment key={tradeWindowId}>
                                <TradeWindow 
                                    windowId={tradeWindowId}
                                    onClose={() => { this.setState({ tradeWindowIds: _.without(tradeWindowIds, tradeWindowId) }) }} />
                            </Fragment>
                        )
                    })}
                </Fragment>}
                <BulkTransferWindow />
                {positionWindowId && 
                <NewWindow
                    ref={(node) => { this.positionWindowNode = node }}
                    title={'Positions - Antelope Technology'}
                    features={{ width: '100%' }}
                    onUnload={() => { 
                        this.positionWindowNode = null
                        this.setState({ positionWindowId: null }) 
                    }}>
                    <PositionTable />
                </NewWindow>}
                {availableBalanceWindowId && 
                <NewWindow
                    ref={(node) => { this.availableBalanceWindowNode = node }}
                    title={'Available Balance - Antelope Technology'}
                    features={{ width: 1100, height: 800 }}
                    onUnload={() => { 
                        this.availableBalanceWindowNode = null
                        this.setState({ availableBalanceWindowId: null }) 
                    }}>
                    <AccountAvailableBalanceTable />
                </NewWindow>}
                {exposureWindowId && 
                <ExposureWindow 
                    windowId={exposureWindowId}
                    onClose={() => { this.setState({ exposureWindowId: null }) }} />}
                {valueAtRiskWindowId &&
                <ValueAtRiskWindow 
                    windowId={valueAtRiskWindowId}
                    onClose={() => { this.setState({ valueAtRiskWindowId: null }) }} />}
                {optionStatsWindowId && 
                <OptionWindow 
                    windowId={optionStatsWindowId}
                    onClose={() => { this.setState({ optionStatsWindowId: null }) }} />}
                {accountBalanceMonitorWindowId && <AccountBalanceWindow 
                    hidden={_.isNil(accountBalanceMonitorWindowId)}
                    windowId={accountBalanceMonitorWindowId}
                    onWindowClose={() => { this.setState({ accountBalanceMonitorWindowId: null }) }} 
                    onReceiveAccountBalances={(message) => { this.handleReceiveAccountBalancesMessage(message) }} />}
                {!_.isEmpty(testWindowIds) && 
                <Fragment>
                    {_.map(testWindowIds, testWindowId => {
                        return (
                            <Fragment key={testWindowId}>
                                <TestWindow 
                                    windowId={testWindowId}
                                    onClose={() => { this.setState({ testWindowIds: _.without(testWindowIds, testWindowId) }) }} />
                            </Fragment>
                        )
                    })}
                </Fragment>}
            </div>
        )
    }
}

MenuTools.propTypes = {
    dispatch: PropTypes.func.isRequired,
    soundEffect: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        soundEffect: state.setting.soundEffect
    }
}

export default connect(mapStateToProps)(MenuTools)