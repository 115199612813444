import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'

import { AutoSizer, Table, Column } from 'react-virtualized'
import moment from 'moment'
import { isMetSearchStringCriteria, toNumberInputValue, toNumberWithSmartPrecision } from '../../util/util'
import { accountBorrowFund, accountRepayFund, fetchAccountMarginBalance, fetchMarginLendingMonitorState, updateMarginLendingMonitorVariables } from './accountAction'
import Popup from '../common/popup/Popup'
import Toggle from '../common/toggle/Toggle'
import SaveButton from '../common/saveButton/SaveButton'
import { MdLock, MdLockOpen } from 'react-icons/md'
import Checkbox from '../common/checkbox/Checkbox'

const ACTION_TYPES = {
    BORROW: {
        key: 'BORROW',
        name: 'Borrow'
    },
    REPAY: {
        key: 'REPAY',
        name: 'Repay'
    }
}

const SORT_BYS = {
    ACCOUNT: 'ACCOUNT',
    PAIR: 'PAIR',
    RATIO: 'RATIO',
    AUTO_BORROW: 'AUTO_BORROW'
}

const Action = ({ type, inputAmount=['', ''], isFetching=false }) => {
    return {
        type,
        inputAmount,
        isFetching
    }
}

const AccountAutoRepayParam = ({ accountName, pair='', marginRatioThreshold, coin1MaxAmount, coin2MaxAmount }) => {
    return {
        accountName,
        pair: pair.toUpperCase(),
        marginRatioThreshold,
        coin1MaxAmount,
        coin2MaxAmount
    }
}

const AutoBorrow = ({ accountName, pair='', currency='' }) => {
    return {
        accountName,
        pair: pair.toUpperCase(),
        currency: currency.toUpperCase()
    }
}

const createAccountAutoRepayParamId = ({ accountName, pair }) => {
    return `${accountName}--${(pair || '').toUpperCase()}`
}

class MarginLendingTable extends Component {
    constructor (props) {
        super(props)
        this.state = {
            searchString: '',
            marginAccountIdToActionMaps: {},
            isFetchingMarginAccountBalance: false,
            isFetchingMonitorState: false,
            isSavingVariables: false,
            isSwitchingAutoRepay: false,
            isSwitchingAutoBorrow: false,
            sortBy: SORT_BYS.RATIO,
            marginLendingMonitorState: {
                isAutoRepayEnabled: false,
                isAutoBorrowEnabled: false,
                variables: {
                    isAutoRepayOn: false,
                    isAutoBorrowOn: false,
                    accountAutoRepayParams: {},
                    enabledAutoBorrows: []
                },
                AUTO_REPAY_DEFAULT_MARGIN_RATIO_THRESHOLD: null,
                AUTO_REPAY_DEFAULT_MAX_AMOUNT: null
            },
            editingAccountAutoRepayParams: {},
            isAutoBorrowSwitchesLocked: true,
            shouldShowEnabledAutoBorrowOnly: false
        }

        this._mounted = false
        this.isSchedulerLocked = false
        this.isSchedulerQueued = false
        this.isScrollingTable = null

        this.tableNode = null
        this.marginLendingTableNode = null

    }

    componentDidMount () {
        this._mounted = true
        this.getMarginLendingMonitorState()
    }

    componentDidUpdate (prevProps) {
        const { updateStateId: prevUpdateStateId } = prevProps
        const { updateStateId } = this.props
        if (!_.isEqual(prevUpdateStateId, updateStateId)) {
            this.scheduleGetMarginLendingMonitorState()
        }

        if (window.document.hidden && this.tableNode) {
            this.tableNode.recomputeRowHeights()
        }
    }

    componentWillUnmount () {
        this._mounted = false
    }

    scheduleGetMarginLendingMonitorState () {
        if (!this.isSchedulerLocked) {
            this.isSchedulerLocked = true
            setTimeout(() => {
                if (this._mounted) {
                    this.isSchedulerLocked = false
                }
            }, 3000)
            this.getMarginLendingMonitorState()
        } else if (!this.isSchedulerQueued) {
            this.isSchedulerQueued = true
            setTimeout(() => {
                if (this._mounted) {
                    this.scheduleGetMarginLendingMonitorState()
                    this.isSchedulerQueued = false
                }
            }, 3000)
        }
    }

    getMarginLendingMonitorState (successCallback=()=>{}) {
        const { dispatch } = this.props
        this.setState({ isFetchingMonitorState: true })
        dispatch(fetchMarginLendingMonitorState())
        .then(response => response.json())
        .then(body => {
            if (this._mounted) {
                this.setState({ marginLendingMonitorState: body })
                successCallback()
            }
        })
        .finally(() => {
            if (this._mounted) {
                this.setState({ isFetchingMonitorState: false })
            }
        })
    }

    _removeInnerScrollContainerPointerEvents () {
        if (this.marginLendingTableNode && this.marginLendingTableNode.ownerDocument) {
            const containers = this.marginLendingTableNode.ownerDocument.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer')
            if (!_.isEmpty(containers)) {
                containers[0].style.pointerEvents = null
            }
        }
    }

    canSumbit (marginAccountBalance) {
        const { marginAccountIdToActionMaps } = this.state
        const { id } = marginAccountBalance
        const action = marginAccountIdToActionMaps[id] || {}
        const { type, inputAmount, isFetching } = action

        return !_.isNil(action)
            && isFetching === false
            && !_.isEmpty(type)
            && _.isArray(inputAmount)
            && ((inputAmount[0] === '' && _.isNumber(inputAmount[1])) 
                || (_.isNumber(inputAmount[0]) && inputAmount[1] === '')
            )
            && _.find(inputAmount, amount => _.isNumber(amount)) > 0
    }

    handleClickConfirmButton (marginAccountBalance) {
        const { marginAccountIdToActionMaps } = this.state
        const { dispatch, onBorrowRepaySuccess, onBorrowRepayFail } = this.props
        const canSubmit = this.canSumbit(marginAccountBalance)

        if (canSubmit) {
            const { id, coin1, coin2, acct_name } = marginAccountBalance
            const action = marginAccountIdToActionMaps[id]
            const { type, inputAmount, isFetching } = action
            const indexHasAmount = _.findIndex(inputAmount, amount => _.isNumber(amount)) 
            const amount = inputAmount[indexHasAmount]
            const currency = indexHasAmount === 0 ? coin1
                : indexHasAmount === 1 ? coin2
                : null 
            const request = type === ACTION_TYPES.BORROW.key ? accountBorrowFund
                : type === ACTION_TYPES.REPAY.key ? accountRepayFund
                : null
            if (!isFetching && !_.isEmpty(acct_name) && amount > 0 && !_.isNil(currency) && !_.isNil(request)) {
                const params = {
                    account_name: acct_name,
                    pair: `${coin1}-${coin2}`.toLowerCase(),
                    currency,
                    amount
                }
                this.setState({
                    marginAccountIdToActionMaps: dotProp.set(marginAccountIdToActionMaps, `${id}.isFetching` , true)
                })
                dispatch(request(params))
                .then(response => {
                    if (this._mounted && response && response.status) {
                        response.text().then(text => {
                            this.setState(prevState => {
                                return {
                                    marginAccountIdToActionMaps: response.status === 200
                                        ? dotProp.delete(prevState.marginAccountIdToActionMaps, id)
                                        : dotProp.set(prevState.marginAccountIdToActionMaps, `${id}.isFetching`, false)
                                }
                            })
                            if (response.status === 200) {
                                onBorrowRepaySuccess({ params, type, message: text })
                            } else {
                                onBorrowRepayFail({ params, type, message: text })
                            }
                        })
                    }
                })
                .catch(error => {
                    if (this._mounted) {
                        this.setState(prevState => {
                            return {
                                marginAccountIdToActionMaps: dotProp.set(prevState.marginAccountIdToActionMaps, `${id}.isFetching`, false)
                            }
                        })
                        onBorrowRepayFail({ params, type, message: error.toString() })
                    }
                })
                .finally(() => {
                    dispatch(fetchAccountMarginBalance())
                })
            }
        }
    }

    handleClickPaybackAllCoin (marginAccountBalances=[], coin='') {
        const { dispatch, onBorrowRepaySuccess, onBorrowRepayFail } = this.props
        if (!_.isEmpty(marginAccountBalances) && !_.isEmpty(coin)) {
            let fetchAccountMarginBalanceLocked = false
            marginAccountBalances.forEach((marginAccountBalance, marginAccountBalanceIndex) => {
                const { acct_name, coin1, coin2 } = marginAccountBalance
                const borrowed1 = Number(marginAccountBalance.borrowed1)
                const borrowed2 = Number(marginAccountBalance.borrowed2)
                const lendingFee1 = Number(marginAccountBalance.lending_fee1)
                const lendingFee2 = Number(marginAccountBalance.lending_fee2)
                const balance1 = Number(marginAccountBalance.balance1)
                const balance2 = Number(marginAccountBalance.balance2)
                const coins = [coin1, coin2]
                coins.forEach((c, index) => {
                    if (coin.toLowerCase() === c.toLowerCase()) {
                        const borrowed = index === 0 ? borrowed1 : borrowed2
                        const lendingFee = index === 0 ? lendingFee1 : lendingFee2
                        const balance = index === 0 ? balance1 : balance2
                        const repayableAmount = Math.min(balance, borrowed + lendingFee)
                        
                        if (repayableAmount > 0) {
                            const params = {
                                account_name: acct_name,
                                pair: `${coin1}-${coin2}`.toLowerCase(),
                                currency: coin.toLowerCase(),
                                amount: repayableAmount
                            }
                            dispatch(accountRepayFund(params))
                            .then(response => {
                                if (this._mounted && response && response.status) {
                                    response.text().then(text => {
                                        if (response.status === 200) {
                                            onBorrowRepaySuccess({ params, type: ACTION_TYPES.REPAY.key, message: text })
                                        } else {
                                            onBorrowRepayFail({ params, type: ACTION_TYPES.REPAY.key, message: text })
                                        }
                                    })
                                }
                            })
                            .catch(error => {
                                if (this._mounted) {
                                    onBorrowRepayFail({ params, type: ACTION_TYPES.REPAY.key, message: error.toString() })
                                }
                            })
                            .finally(() => {
                                if (!fetchAccountMarginBalanceLocked || (marginAccountBalanceIndex === _.size(marginAccountBalances) - 1)) {
                                    fetchAccountMarginBalanceLocked = true
                                    setTimeout(() => {
                                        if (this._mounted && fetchAccountMarginBalanceLocked) {
                                            fetchAccountMarginBalanceLocked = false
                                        }
                                    }, 2000)
                                    dispatch(fetchAccountMarginBalance())
                                }
                            })
                        }
                    }
                })
            })
        }
    }

    handleClickSaveButton () {
        const { dispatch } = this.props
        const { editingAccountAutoRepayParams, isSavingVariables } = this.state
        if (!_.isEmpty(editingAccountAutoRepayParams) && !isSavingVariables) {
            this.setState({ isSavingVariables: true })
            dispatch(updateMarginLendingMonitorVariables({
                accountAutoRepayParams: Object.values(editingAccountAutoRepayParams)
            }))
            .then(response => {
                if (response && response.status === 200) {
                    const successCallback = () => {
                        if (this._mounted) {
                            this.setState({ editingAccountAutoRepayParams: {} })
                        }
                    }
                    this.getMarginLendingMonitorState(successCallback)
                }
            })
            .finally(() => {
                if (this._mounted) {
                    this.setState({ isSavingVariables: false })
                }
            })
        }   
    }

    handleChangeAutoBorrowToggle ({ autoBorrow=AutoBorrow({}), newValue=false }) {
        const { dispatch } = this.props
        const { variables } = this.state.marginLendingMonitorState
        let newEnabledAutoBorrows = _.cloneDeep(variables.enabledAutoBorrows || [])
        if (newValue === true && !_.some(newEnabledAutoBorrows, autoBorrow)) {
            newEnabledAutoBorrows.push(autoBorrow)
        } else if (newValue === false) {
            newEnabledAutoBorrows = _.filter(newEnabledAutoBorrows, item => !_.isEqual(item, autoBorrow))
        }
        dispatch(updateMarginLendingMonitorVariables({
            enabledAutoBorrows: newEnabledAutoBorrows
        }))
        .then(response => {
            if (response && response.status === 200) {
                this.getMarginLendingMonitorState()
            }
        })
    }

    handleClickAutoRepayToggle (newIsAutoRepayOn) {
        const { isSwitchingAutoRepay } = this.state
        const { dispatch } = this.props
        if (!isSwitchingAutoRepay) {
            this.setState({ isSwitchingAutoRepay: true })
            dispatch(updateMarginLendingMonitorVariables({ isAutoRepayOn: newIsAutoRepayOn }))
            .finally(() => {
                this.setState({ isSwitchingAutoRepay: false })
                this.getMarginLendingMonitorState()
            })
        }
    }

    handleClickAutoBorrowToggle (newIsAutoBorrowOn) {
        const { isSwitchingAutoBorrow } = this.state
        const { dispatch } = this.props
        if (!isSwitchingAutoBorrow) {
            this.setState({ isSwitchingAutoBorrow: true })
            dispatch(updateMarginLendingMonitorVariables({ isAutoBorrowOn: newIsAutoBorrowOn }))
            .finally(() => {
                this.setState({ isSwitchingAutoBorrow: false })
                this.getMarginLendingMonitorState()
            })
        }
    }

    getLastPriceByMarginAccount (marginAccountBalance) {
        const { accountItems, pricings } = this.props
        const { acct_name: accountName, pair } = marginAccountBalance
        const accountItem = accountItems[accountName]
        const symbolName = _.has(accountItem, 'exchange_name') 
            ? `${(pair || '').replace('-', '_').toLowerCase()}_${accountItem.exchange_name}_SPT`
            : null 
        const pricingItem = symbolName ? pricings[symbolName] : null
        const lastPrice = pricingItem ? pricingItem.last : null
        return lastPrice
    }

    ParamInput ({ value, shouldShowAsPercent=false, isModified=false, disabled=false, onChange=()=>{} }) {
        return (
            <div className='margin-lending-table--param-input-wrapper'>
                <input
                    className={'margin-lending-table--param-input' + (isModified ? ' modified' : '')}
                    type={'number'}
                    disabled={disabled}
                    value={shouldShowAsPercent && _.isNumber(value) ? `${Math.round(value * 10000)/100}` : (value || '')} 
                    onChange={(e) => {
                        let newValue = e.target.value.trim()
                        if (newValue.length > 0) {
                            newValue = Math.round(newValue * 100) / (shouldShowAsPercent ? 10000 : 100)
                        }
                        onChange(newValue)
                    }} />
                {shouldShowAsPercent && <span>{'%'}</span>}
            </div>
        )
    }

    PaybackAllPopup (marginAccountBalances=[]) {
        const totalRepayable = {}
        marginAccountBalances.forEach(marginAccountBalance => {
            const { coin1, coin2 } = marginAccountBalance
            const borrowed1 = Number(marginAccountBalance.borrowed1)
            const borrowed2 = Number(marginAccountBalance.borrowed2)
            const lendingFee1 = Number(marginAccountBalance.lending_fee1)
            const lendingFee2 = Number(marginAccountBalance.lending_fee2)
            const balance1 = Number(marginAccountBalance.balance1)
            const balance2 = Number(marginAccountBalance.balance2)
            const coin1Repayable = Math.min(balance1, borrowed1 + lendingFee1)
            const coin2Repayable = Math.min(balance2, borrowed2 + lendingFee2)
            
            if (coin1Repayable > 0) {
                totalRepayable[coin1] = (totalRepayable[coin1] || 0) + coin1Repayable
            }
            if (coin2Repayable > 0) {
                totalRepayable[coin2] = (totalRepayable[coin2] || 0) + coin2Repayable
            }
        })
        return !_.isEmpty(totalRepayable) ? (
            <Popup className='margin-lending-table--payback-all-popup'
                on={'click'}
                trigger={<button className='margin-lending-table--payback-all-popup--trigger'>{'Repay All'}</button>}>
                <div className='margin-lending-table--payback-all-popup--list'>
                    {_.map(totalRepayable, (total, coin) => {
                        return (
                            <button className='margin-lending-table--payback-all-popup--item' 
                                key={coin} 
                                onClick={() => { this.handleClickPaybackAllCoin(marginAccountBalances, coin) }}>
                                {`${coin.toUpperCase()}: ${toNumberWithSmartPrecision({ number: total, shouldReturnLocalString: true })}`}
                            </button>
                        )
                    })}
                </div>
            </Popup>
        ) : null
    }

    ColumnDirectionFlex ({ className, topComponent, bottomComponent }) {
        return (
            <div className={'margin-lending-table--column-direction-flex' + (className ? ` ${className}` :'')}>
                <div className='margin-lending-table--column-direction-flex--top'>{topComponent}</div>
                <div className='margin-lending-table--column-direction-flex--bottom'>{bottomComponent}</div>
            </div>
        )
    }

    Header () {
        const { searchString, isFetchingMarginAccountBalance, isFetchingMonitorState, isSavingVariables, isSwitchingAutoRepay, isSwitchingAutoBorrow,
            shouldShowEnabledAutoBorrowOnly, marginLendingMonitorState, editingAccountAutoRepayParams } = this.state
        const { dispatch } = this.props
        return (
            <div className='margin-lending-table--header clearfix'>
                <div className='margin-lending-table--header--left-section'>
                    <input className='margin-lending-table--header--search-input vertical-centered' 
                        placeholder={'Search Account, Pair'}
                        spellCheck={false}
                        value={searchString}
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                    <div className='margin-lending-table--header--auto-borrow-only-checkbox'>
                        <Checkbox 
                            checked={shouldShowEnabledAutoBorrowOnly}
                            onChange={(newChecked) => { 
                                this.setState({ shouldShowEnabledAutoBorrowOnly: newChecked })
                            }}/>
                        <span>{'Auto Borrow Only'}</span>
                    </div>
                </div>
                <div className='margin-lending-table--header--right-section'>
                    <div className='margin-lending-table--header--auto-repay-toggle'>
                        <span>{'Auto Repay'}</span>
                        <Toggle 
                            checked={marginLendingMonitorState.isAutoRepayEnabled 
                                && _.has(marginLendingMonitorState, 'variables') 
                                && marginLendingMonitorState.variables.isAutoRepayOn}
                            disabled={!marginLendingMonitorState.isAutoRepayEnabled}
                            trueText={'ON'}
                            falseText={'OFF'}
                            isChanging={isSwitchingAutoRepay}
                            onChange={(newValue) => { this.handleClickAutoRepayToggle(newValue) }} />
                    </div>
                    <div className='margin-lending-table--header--auto-borrow-toggle'>
                        <span>{'Auto Borrow'}</span>
                        <Toggle 
                            checked={marginLendingMonitorState.isAutoBorrowEnabled 
                                && _.has(marginLendingMonitorState, 'variables') 
                                && marginLendingMonitorState.variables.isAutoBorrowOn}
                            disabled={!marginLendingMonitorState.isAutoBorrowEnabled}
                            trueText={'ON'}
                            falseText={'OFF'}
                            isChanging={isSwitchingAutoBorrow}
                            onChange={(newValue) => { this.handleClickAutoBorrowToggle(newValue) }} />
                    </div>
                    {!_.isEmpty(editingAccountAutoRepayParams) && <Fragment>
                        <SaveButton className='margin-lending-table--header--button vertical-centered save'
                            isSaving={isSavingVariables} 
                            onClick={() => { this.handleClickSaveButton() }}/>
                        <button className='margin-lending-table--header--button vertical-centered reset' onClick={() => {
                            this.setState({
                                editingAccountAutoRepayParams: {}
                            })
                        }}>{'RESET'}</button>
                    </Fragment>}
                    <button className='margin-lending-table--header--fetch-latest-button vertical-centered'
                        disabled={isFetchingMarginAccountBalance || isFetchingMonitorState}
                        onClick={() => { 
                            this.setState({ isFetchingMarginAccountBalance: true })
                            this.getMarginLendingMonitorState()
                            dispatch(fetchAccountMarginBalance())
                            .finally(() => {
                                if (this._mounted) {
                                    this.setState({
                                        isFetchingMarginAccountBalance: false
                                    })
                                }
                            })
                        }}>
                        {(!isFetchingMarginAccountBalance && !isFetchingMonitorState) ? 'Fetch Latest' : 'Fetching...'}
                    </button>
                </div>
            </div>
        )
    }
    
    getAccountAutoRepayParam (accountName, pair) {
        const { marginLendingMonitorState } = this.state
        const accountAutoRepayParam = (_.has(marginLendingMonitorState, 'variables.accountAutoRepayParams')
            ? _.find(marginLendingMonitorState.variables.accountAutoRepayParams, { accountName, pair: pair.toUpperCase() }) 
            : null)
            || AccountAutoRepayParam({ 
                accountName, 
                pair: pair.toUpperCase(), 
                marginRatioThreshold: marginLendingMonitorState.AUTO_REPAY_DEFAULT_MARGIN_RATIO_THRESHOLD,
                coin1MaxAmount: marginLendingMonitorState.AUTO_REPAY_DEFAULT_MAX_AMOUNT,
                coin2MaxAmount: marginLendingMonitorState.AUTO_REPAY_DEFAULT_MAX_AMOUNT
            })
        return accountAutoRepayParam
    }

    render () {
        const { searchString, marginAccountIdToActionMaps, sortBy, 
            marginLendingMonitorState, editingAccountAutoRepayParams, 
            isAutoBorrowSwitchesLocked, shouldShowEnabledAutoBorrowOnly } = this.state 
        const { accountItems, marginAccountBalances } = this.props
        const trimmedSearchString = searchString.trim().toLowerCase()

        const marginAccountBalancesWithRatio = marginAccountBalances.map(marginAccountBalance => {
            const { liquidation_price } = marginAccountBalance
            const lastPrice = this.getLastPriceByMarginAccount(marginAccountBalance) 
            const ratio = !_.isNil(liquidation_price) && Number(lastPrice) 
                ? (Number(liquidation_price) - lastPrice) / lastPrice
                : null

            return {
                ...marginAccountBalance,
                ratio
            }
        })
        const filteredMarginAccountBalances = _.filter(marginAccountBalancesWithRatio, marginAccountBalance => {
            const { acct_name, pair } = marginAccountBalance
            return _.has(accountItems, acct_name) 
                && accountItems[acct_name].exchange_name === 'OKEX'
                && (!shouldShowEnabledAutoBorrowOnly || _.some(marginLendingMonitorState.variables.enabledAutoBorrows, autoBorrow => {
                    return autoBorrow.accountName === acct_name && autoBorrow.pair === pair.toUpperCase()
                }))
                && (_.isEmpty(trimmedSearchString) || isMetSearchStringCriteria( `${acct_name} ${pair}`, trimmedSearchString))
        })

        const seivedMarginAccountBalances = _.sortBy(filteredMarginAccountBalances, marginAccountBalance => {
            const { acct_name, pair } = marginAccountBalance
            if (sortBy === SORT_BYS.ACCOUNT) {
                return marginAccountBalance.acct_name
            } else if (sortBy === SORT_BYS.PAIR) {
                return marginAccountBalance.pair
            } else if (sortBy === SORT_BYS.AUTO_BORROW) {
                return _.some(marginLendingMonitorState.variables.enabledAutoBorrows, autoBorrow => {
                    return autoBorrow.accountName === acct_name && autoBorrow.pair === (pair || '').toUpperCase()
                }) ? -1 : 1
            } else {
                return marginAccountBalance.ratio ? Math.abs(marginAccountBalance.ratio) : Infinity
            }
        })

        return (
            <div className='margin-lending-table' ref={(node) => { this.marginLendingTableNode = node }}>
                {this.Header()}
                <div className='margin-lending-table--main'>
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                ref={(node) => { this.tableNode = node }}
                                className='margin-lending-table--table'
                                headerClassName={'margin-lending-table--table--header'}
                                rowClassName={({ index }) => `margin-lending-table--row${index % 2 === 0 ? ' even-row' : ''}`}
                                headerHeight={27}
                                width={Math.max(width, 1000)}
                                height={height}
                                rowCount={seivedMarginAccountBalances.length}
                                rowGetter={({ index }) => seivedMarginAccountBalances[index]}
                                overscanRowCount={5}
                                rowHeight={69}
                                noRowsRenderer={() => ( <div className='margin-lending-table--no-content'>{'There is no matched account.'}</div> )}
                                sort={({ sortBy: newSortBy }) => {
                                    this.setState({
                                        sortBy: newSortBy === 'acct_name' ? SORT_BYS.ACCOUNT
                                            : newSortBy === 'pair' ? SORT_BYS.PAIR
                                            : newSortBy === 'ratio' ? SORT_BYS.RATIO
                                            // : newSortBy === 'auto_borrow' ? SORT_BYS.AUTO_BORROW
                                            : sortBy
                                    })
                                }}
                                onScroll={() => { 
                                    if (window.document.hidden) {
                                        if (this.isScrollingTable) {
                                            window.clearTimeout(this.isScrollingTable)
                                        }
                                        this.isScrollingTable = setTimeout(() => {
                                            this._removeInnerScrollContainerPointerEvents()
                                        }, 66)
                                    }
                                }}>
                                <Column
                                    dataKey={'acct_name'}
                                    className='margin-lending-table--account-name'
                                    headerClassName={'sortable' + (sortBy === SORT_BYS.ACCOUNT ? ' sorted' : '')}
                                    width={180}
                                    flexGrow={1}
                                    label={'Acct | Pair'} 
                                    cellRenderer={({ rowData }) => {
                                        const { acct_name, pair, leverage } = rowData
                                        return (
                                            <div className='margin-lending-table--id'>
                                                <div className='margin-lending-table--id--account'>{acct_name}</div>
                                                <div className='margin-lending-table--id--pair'>
                                                    {(pair || '').toUpperCase()}
                                                    <span>{`${leverage}X`}</span>
                                                </div>
                                            </div>
                                        )
                                    }}/>
                                <Column
                                    dataKey={'timestamp'}
                                    width={80}
                                    flexGrow={1}
                                    label={'Time'} 
                                    cellRenderer={({ rowData }) => {
                                        return (
                                            <span>{moment(rowData.timestamp).format('HH:mm:ss')}</span>
                                        )
                                    }}/>
                                <Column
                                    dataKey={'prices'}
                                    headerClassName={'center-align'}
                                    width={120}
                                    flexGrow={1}
                                    label={'Liq. | PC.'} 
                                    cellRenderer={({ rowData }) => {
                                        const { liquidation_price } = rowData
                                        const lastPrice = this.getLastPriceByMarginAccount(rowData)
                                        return (
                                            <div className='margin-lending-table--prices'>
                                                <div className='margin-lending-table--prices--liquidation'>{liquidation_price}</div>
                                                <div className='margin-lending-table--prices--last'>{lastPrice || 'N/A'}</div>
                                            </div>
                                        )
                                    }} />
                                <Column
                                    dataKey={'risk_rate'}
                                    headerClassName={'center-align'}
                                    className={'center-align'}
                                    width={80}
                                    flexGrow={1}
                                    label={'Risk Rate'}
                                    cellRenderer={({ rowData }) => {
                                        return (
                                            <span>{toNumberWithSmartPrecision({ number: rowData.risk_rate })}</span>
                                        )
                                    }} />
                                <Column
                                    dataKey={'ratio'}
                                    headerClassName={'center-align sortable' + (sortBy === SORT_BYS.RATIO ? ' sorted' : '')}
                                    className={'center-align'}
                                    width={150}
                                    flexGrow={1}
                                    label={'Ratio | THR'}
                                    cellRenderer={({ rowData }) => {
                                        const { acct_name: accountName, liquidation_price, pair } = rowData
                                        const lastPrice = this.getLastPriceByMarginAccount(rowData) 
                                        const ratio = !_.isNil(liquidation_price) && Number(lastPrice) 
                                            ? (Number(liquidation_price) - lastPrice) / lastPrice
                                            : null
                                        const accountAutoRepayParam = this.getAccountAutoRepayParam(accountName, pair)
                                        const paramId = createAccountAutoRepayParamId({ ...accountAutoRepayParam })
                                        const editingParam = editingAccountAutoRepayParams[paramId]
                                        return (
                                            <div className='margin-lending-table--ratio-cell'>
                                                <span>{ratio ? `${(ratio * 100).toFixed(2)}%` : 'N/A'}</span>
                                                {this.ParamInput({
                                                    value: _.has(editingParam, 'marginRatioThreshold') ? editingParam.marginRatioThreshold : accountAutoRepayParam.marginRatioThreshold,
                                                    shouldShowAsPercent: true,
                                                    isModified: _.has(editingParam, 'marginRatioThreshold') && editingParam.marginRatioThreshold !== accountAutoRepayParam.marginRatioThreshold,
                                                    disabled: !_.isNumber(marginLendingMonitorState.AUTO_REPAY_DEFAULT_MARGIN_RATIO_THRESHOLD),
                                                    onChange: (newValue) => {
                                                        const newEditingParam = !_.isNil(editingParam) 
                                                            ? dotProp.set(editingParam, 'marginRatioThreshold', newValue)
                                                            : dotProp.set(accountAutoRepayParam, 'marginRatioThreshold', newValue)
                                                        this.setState({
                                                            editingAccountAutoRepayParams: dotProp.set(editingAccountAutoRepayParams, paramId, newEditingParam)
                                                        })
                                                    }
                                                })}
                                            </div>

                                        )
                                    }} />
                                <Column
                                    dataKey={'coin'}
                                    width={70}
                                    label={'Coin'}
                                    cellRenderer={({ rowData }) => {
                                        return this.ColumnDirectionFlex({
                                            topComponent: (rowData.coin1 || '').toUpperCase(),
                                            bottomComponent: (rowData.coin2 || '').toUpperCase()
                                        })
                                    }} />
                                <Column
                                    dataKey={'balance'}
                                    width={120}
                                    flexGrow={1}
                                    label={'Balance'}
                                    cellRenderer={({ rowData }) => {
                                        return this.ColumnDirectionFlex({
                                            topComponent: toNumberWithSmartPrecision({ number: Number(rowData.balance1), shouldReturnLocalString: true }),
                                            bottomComponent: toNumberWithSmartPrecision({ number: Number(rowData.balance2), shouldReturnLocalString: true })
                                        })
                                    }} />
                                <Column
                                    dataKey={'transferable'}
                                    width={120}
                                    flexGrow={1}
                                    label={'TFRable'}
                                    cellRenderer={({ rowData }) => {
                                        const { transferable1, transferable2 } = rowData
                                        return this.ColumnDirectionFlex({
                                            topComponent: !_.isEmpty(_.toString(transferable1)) ? toNumberWithSmartPrecision({ number: transferable1, shouldReturnLocalString: true }) : 'N/A',
                                            bottomComponent: !_.isEmpty(_.toString(transferable2)) ? toNumberWithSmartPrecision({ number: transferable2, shouldReturnLocalString: true }) : 'N/A'
                                        })
                                    }} />
                                <Column
                                    dataKey={'borrowed'}
                                    width={130}
                                    flexGrow={1}
                                    label={'Borrowed'}
                                    cellRenderer={({ rowData }) => {
                                        return this.ColumnDirectionFlex({
                                            topComponent: toNumberWithSmartPrecision({ number: Number(rowData.borrowed1), shouldReturnLocalString: true }),
                                            bottomComponent: toNumberWithSmartPrecision({ number: Number(rowData.borrowed2), shouldReturnLocalString: true })
                                        })
                                    }} />
                                <Column
                                    dataKey={'max_borrowable'}
                                    width={120}
                                    flexGrow={1}
                                    label={'Borrowable'}
                                    cellRenderer={({ rowData }) => {
                                        return this.ColumnDirectionFlex({
                                            topComponent: toNumberWithSmartPrecision({ number: Number(rowData.max_borrowable1), shouldReturnLocalString: true }),
                                            bottomComponent: toNumberWithSmartPrecision({ number: Number(rowData.max_borrowable2), shouldReturnLocalString: true })
                                        })
                                    }} />
                                <Column
                                    dataKey={'lending_fee'}
                                    width={120}
                                    flexGrow={1}
                                    label={'Lending Fee'}
                                    cellRenderer={({ rowData }) => {
                                        return this.ColumnDirectionFlex({
                                            topComponent: toNumberWithSmartPrecision({ number: Number(rowData.lending_fee1), shouldReturnLocalString: true }),
                                            bottomComponent: toNumberWithSmartPrecision({ number: Number(rowData.lending_fee2), shouldReturnLocalString: true })
                                        })
                                    }} />
                                <Column 
                                    dataKey={'repay_limit'}
                                    width={120} 
                                    flexGrow={1}
                                    label={'Repay Limit'}
                                    cellRenderer={({ rowData }) => {
                                        const { acct_name: accountName, pair } = rowData
                                        const accountAutoRepayParam = this.getAccountAutoRepayParam(accountName, pair)
                                        const paramId = createAccountAutoRepayParamId({ ...accountAutoRepayParam })
                                        const editingParam = editingAccountAutoRepayParams[paramId]
                                        return this.ColumnDirectionFlex({
                                            className: 'margin-lending-table--repay-limit',
                                            topComponent: this.ParamInput({
                                                    value: _.has(editingParam, 'coin1MaxAmount') ? editingParam.coin1MaxAmount : accountAutoRepayParam.coin1MaxAmount,
                                                    shouldShowAsPercent: false,
                                                    isModified: _.has(editingParam, 'coin1MaxAmount') && editingParam.coin1MaxAmount !== accountAutoRepayParam.coin1MaxAmount,
                                                    disabled: !_.isNumber(marginLendingMonitorState.AUTO_REPAY_DEFAULT_MAX_AMOUNT),
                                                    onChange: (newValue) => {
                                                        const newEditingParam = !_.isNil(editingParam) 
                                                            ? dotProp.set(editingParam, 'coin1MaxAmount', newValue)
                                                            : dotProp.set(accountAutoRepayParam, 'coin1MaxAmount', newValue)
                                                        this.setState({
                                                            editingAccountAutoRepayParams: dotProp.set(editingAccountAutoRepayParams, paramId, newEditingParam)
                                                        })
                                                    }
                                                }),
                                            bottomComponent: this.ParamInput({
                                                value: _.has(editingParam, 'coin2MaxAmount') ? editingParam.coin2MaxAmount : accountAutoRepayParam.coin2MaxAmount,
                                                shouldShowAsPercent: false,
                                                isModified: _.has(editingParam, 'coin2MaxAmount') && editingParam.coin2MaxAmount !== accountAutoRepayParam.coin2MaxAmount,
                                                disabled: !_.isNumber(marginLendingMonitorState.AUTO_REPAY_DEFAULT_MAX_AMOUNT),
                                                onChange: (newValue) => {
                                                    const newEditingParam = !_.isNil(editingParam) 
                                                        ? dotProp.set(editingParam, 'coin2MaxAmount', newValue)
                                                        : dotProp.set(accountAutoRepayParam, 'coin2MaxAmount', newValue)
                                                    this.setState({
                                                        editingAccountAutoRepayParams: dotProp.set(editingAccountAutoRepayParams, paramId, newEditingParam)
                                                    })
                                                }
                                            })
                                        })                                        
                                    }} />
                                <Column 
                                    dataKey={'auto_borrow'} 
                                    width={180}
                                    flexGrow={1}
                                    headerClassName={'sortable' + (sortBy === SORT_BYS.AUTO_BORROW ? ' sorted' : '')}
                                    headerRenderer={() => {
                                        return (
                                            <Fragment>
                                                <span>{'Borrow'}</span>
                                                <button className='margin-lending-table--auto-borrow-lock'
                                                    onClick={(e) => { 
                                                        e.stopPropagation()
                                                        this.setState({ isAutoBorrowSwitchesLocked: !isAutoBorrowSwitchesLocked  }) 
                                                    }}>
                                                    {isAutoBorrowSwitchesLocked ? <MdLock /> : <MdLockOpen />}
                                                    {isAutoBorrowSwitchesLocked ? 'Locked' : 'Active'}
                                                </button>
                                            </Fragment>
                                        )
                                    }}
                                    cellRenderer={({ rowData }) => {
                                        const { acct_name: accountName, pair, coin1, coin2 } = rowData
                                        const coin1EnabledAutoBorrow = _.has(marginLendingMonitorState, 'variables.enabledAutoBorrows') 
                                            && _.find(marginLendingMonitorState.variables.enabledAutoBorrows, autoBorrow => {
                                                return autoBorrow.accountName === accountName
                                                    && autoBorrow.pair=== pair.toUpperCase()
                                                    && autoBorrow.currency === coin1.toUpperCase()
                                            })
                                        const coin2EnabledAutoBorrow = _.has(marginLendingMonitorState, 'variables.enabledAutoBorrows') 
                                            && _.find(marginLendingMonitorState.variables.enabledAutoBorrows, autoBorrow => {
                                                return autoBorrow.accountName === accountName
                                                    && autoBorrow.pair === pair.toUpperCase()
                                                    && autoBorrow.currency === coin2.toUpperCase()
                                            })
                                        return this.ColumnDirectionFlex({
                                            className: 'margin-lending-table--auto-borrow',
                                            topComponent: <Toggle
                                                checked={!_.isNil(coin1EnabledAutoBorrow)}
                                                trueText={'ON'}
                                                falseText={'OFF'}
                                                disabled={isAutoBorrowSwitchesLocked}
                                                onChange={(newChecked) => { 
                                                    this.handleChangeAutoBorrowToggle({
                                                        autoBorrow: coin1EnabledAutoBorrow || AutoBorrow({
                                                            accountName,
                                                            pair: (pair || '').toUpperCase(),
                                                            currency: (coin1 || '').toUpperCase()
                                                        }),
                                                        newValue: newChecked
                                                    })
                                                }} />,
                                            bottomComponent: <Toggle
                                                checked={!_.isNil(coin2EnabledAutoBorrow)}
                                                trueText={'ON'}
                                                falseText={'OFF'}
                                                disabled={isAutoBorrowSwitchesLocked}
                                                onChange={(newChecked) => { 
                                                    this.handleChangeAutoBorrowToggle({
                                                        autoBorrow: coin2EnabledAutoBorrow || AutoBorrow({
                                                            accountName,
                                                            pair: (pair || '').toUpperCase(),
                                                            currency: (coin2 || '').toUpperCase()
                                                        }),
                                                        newValue: newChecked
                                                    })
                                                }} />
                                        })   
                                    }} />
                                <Column 
                                    dataKey={'action'}
                                    width={70}
                                    flexGrow={1}
                                    flexShrink={0}
                                    label={''}
                                    headerRenderer={() => {
                                        return !_.isEmpty(marginAccountIdToActionMaps) ? (
                                            <button className='margin-lending-table--reset-action-button'
                                                onClick={() => {
                                                    this.setState({ marginAccountIdToActionMaps: {} })
                                                }}>{'Reset All'}</button>
                                        ) : null
                                    }}
                                    cellRenderer={({ rowData }) => {
                                        const { id } = rowData
                                        const action = marginAccountIdToActionMaps[id]
                                        const hasAction = !_.isEmpty(action)
                                        return this.ColumnDirectionFlex({
                                            className: 'action-button',
                                            topComponent: (
                                                <button className={'margin-lending-table--action-button BORROW' + (hasAction ? ' always-shown' : '') +(hasAction && marginAccountIdToActionMaps[id].type === ACTION_TYPES.BORROW.key ? ' active' : '')}
                                                    disabled={hasAction && action.isFetching}
                                                    onClick={() => {
                                                        const newAction = Action({ type: ACTION_TYPES.BORROW.key })
                                                        this.setState({
                                                            marginAccountIdToActionMaps: dotProp.set(marginAccountIdToActionMaps, id, newAction)
                                                        })
                                                    }}>{'Borrow'}</button>
                                            ),
                                            bottomComponent: (
                                                <button className={'margin-lending-table--action-button REPAY' + (hasAction ? ' always-shown' : '') + (hasAction && marginAccountIdToActionMaps[id].type === ACTION_TYPES.REPAY.key ? ' active' : '')}
                                                    disabled={hasAction && action.isFetching}
                                                    onClick={() => {
                                                        const newAction = Action({ type: ACTION_TYPES.REPAY.key })
                                                        this.setState({
                                                            marginAccountIdToActionMaps: dotProp.set(marginAccountIdToActionMaps, id, newAction)
                                                        })
                                                    }}>{'Repay'}</button>
                                            )
                                        })
                                    }} />
                                <Column 
                                    dataKey={'amount'}
                                    width={120}
                                    flexGrow={1}
                                    label={'Amount'}
                                    cellRenderer={({ rowData }) => {
                                        const { id } = rowData
                                        const action = marginAccountIdToActionMaps[id]
                                        return !_.isNil(action) ? this.ColumnDirectionFlex({
                                            topComponent: (
                                                <input className='margin-lending-table--amount-input' 
                                                    type={'number'}
                                                    disabled={action && action.isFetching}
                                                    value={action.inputAmount[0]}
                                                    placeholder={(rowData.coin1 || '').toUpperCase()}
                                                    autoFocus
                                                    onChange={(e) => {
                                                        this.setState({
                                                            marginAccountIdToActionMaps: dotProp.set(marginAccountIdToActionMaps, `${id}.inputAmount`, [toNumberInputValue(e.target.value), ''])
                                                        })
                                                    }} />
                                            ),
                                            bottomComponent: (
                                                <input className='margin-lending-table--amount-input' 
                                                    type={'number'}
                                                    disabled={action && action.isFetching}
                                                    value={action.inputAmount[1]}
                                                    placeholder={(rowData.coin2 || '').toUpperCase()}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            marginAccountIdToActionMaps: dotProp.set(marginAccountIdToActionMaps, `${id}.inputAmount`, ['', toNumberInputValue(e.target.value)])
                                                        })
                                                    }} />
                                            )
                                        }) : null
                                    }} />
                                <Column 
                                    dataKey={'buttons'}
                                    width={120}
                                    flexGrow={1}
                                    label={''}
                                    headerRenderer={() => {
                                        return (
                                            <Fragment>
                                                {this.PaybackAllPopup(seivedMarginAccountBalances)}
                                            </Fragment>
                                        )
                                    }}
                                    cellRenderer={({ rowData }) => {
                                        const { id } = rowData
                                        const action = marginAccountIdToActionMaps[id] || {}
                                        const { isFetching } = action
                                        const canSubmit = this.canSumbit(rowData)

                                        return !_.isEmpty(action) ? this.ColumnDirectionFlex({
                                            className: 'submit-button',
                                            topComponent: (
                                                <button className='margin-lending-table--button cancel' 
                                                    disabled={isFetching}
                                                    onClick={() => {
                                                        this.setState({
                                                            marginAccountIdToActionMaps: dotProp.delete(marginAccountIdToActionMaps, id)
                                                        })
                                                    }}>{'Cancel'}</button>
                                            ),
                                            bottomComponent: (
                                                <button className='margin-lending-table--button confirm' 
                                                    disabled={!canSubmit}
                                                    onClick={() => {
                                                        this.handleClickConfirmButton(rowData)
                                                    }}>{!isFetching ? 'Confirm' : 'Waiting'}</button>
                                            )
                                        }) : null
                                    }} />
                            </Table>
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}


MarginLendingTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    accountItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,
    marginAccountBalances: PropTypes.array.isRequired,

    updateStateId: PropTypes.string,
    
    onBorrowRepaySuccess: PropTypes.func,
    onBorrowRepayFail: PropTypes.func
}

MarginLendingTable.defaultProps = {
    onBorrowRepaySuccess: () => {},
    onBorrowRepayFail: () => {}
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items,
        pricings: state.symbol.pricings,
        marginAccountBalances: _.map(state.account.balance.margin, (marginAccountBalance, id) => {
            return {
                id,
                ...marginAccountBalance
            }
        })
    }
}

export default connect(mapStateToProps)(MarginLendingTable)
