import React, { Component } from 'react'
import { batch, connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import uuidv4 from 'uuid/v4'
import _ from 'lodash'

import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import { FaSortAmountDownAlt, FaSortAmountDown, FaCheck } from 'react-icons/fa'

import SearchSelect from '../common/searchSelect/SearchSelect'
import PositionItem from './PositionItem'
import MarginAccountBalanceItem from '../account/MarginAccountBalanceItem'
import CrossMarginAccountBalanceItem from '../account/CrossMarginAccountBalanceItem'

import { getPositionLiquidationRatio, getMarginTradingLiquidationRatio, getPositionItemTransferAdvice, getCrossMarginAccountTransferAdivce, getRiskRatioThresholdByPositionItem } from '../../util/tradingUtil'
import { updateLayoutBulkTransferWindowConfig, updateLayoutBulkTransferWindowId } from '../layout/layoutAction'
import { isMetSearchStringCriteria } from '../../util/util'
import { getPortfolioNames } from '../../util/accountUtil'
import { BULK_TRANSFER_MODES, TransferAccount } from '../account/TokenTransferEditor'

const RiskTypes = {
    POSITION: 'POSITION',
    MARGIN: 'MARGIN',
    CROSS_MARGIN: 'CROSS_MARGIN'
}

const RiskItem = ({ type, symbol, ratio, riskRatioThresholdReference, ratioDiff, detail }) => {
    return { type, symbol, ratio, riskRatioThresholdReference, ratioDiff, detail }
}

const ALL = 'ALL'

const getSessionStoragePortfolio = () => {
    const portfolioNames = getPortfolioNames()
    const portfolio = sessionStorage.riskContainerPortfolio
    return portfolioNames.includes(portfolio) ? portfolio : ALL
}

const updateSessionStoragePortfolio = (portfolio) => {
    sessionStorage.riskContainerPortfolio = portfolio
}
class PositionContainer extends Component {
    constructor (props) {
        super(props) 
        
        this.SORT_ORDERS = {
            ASC: 'ASC',
            DESC: 'DESC'
        }

        this.state = {
            showSearch: false,
            searchString: '',
            portfolioName: getSessionStoragePortfolio(),
            shouldShowRiskDetail: this.getShouldShowDetail(),
            sortOrder: this.getSortOrder(),
            riskItemIdsShouldShowDetail: {},
            bulkTransfer: {
                enabled: false,
                token: null,
                accountNameToTransferItemMaps: {},
                positionFilterSymbolName: null
            }
        }

        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false
        })

        this.listNode = null
        this.prevRiskItemIds = []
        this.renderedStartIndex = null
    }

    getRiskItemId (riskItem) {
        return riskItem.type === RiskTypes.POSITION ? `POSITION--${riskItem.detail.account_name}--${riskItem.detail.product_name}`
        : riskItem.type === RiskTypes.MARGIN ? `MARGIN--${riskItem.detail.acct_name}--${riskItem.detail.pair}`
        : riskItem.type === RiskTypes.CROSS_MARGIN ? `CROSS_MARGIN--${riskItem.detail.acct_name}--${riskItem.detail.coin}`
        : null
    }

    getShouldShowDetail () {
        return sessionStorage.shouldShowRiskDetail === 'true'
    }

    updateShouldShowDetail (newShouldShowDetail) {
        sessionStorage.shouldShowRiskDetail = newShouldShowDetail ? 'true' : 'false'
        this.setState({
            shouldShowRiskDetail: this.getShouldShowDetail(),
            riskItemIdsShouldShowDetail: {}
        })
    }

    getSortOrder () {
        return sessionStorage.riskContainerSortOrder || this.SORT_ORDERS.ASC
    }

    updateSortOrder (newSortOrder=this.SORT_ORDERS.ASC) {
        sessionStorage.riskContainerSortOrder = newSortOrder
        this.setState({
            sortOrder: this.getSortOrder()
        })
    }

    getRiskItems () {
        const { searchString, portfolioName, sortOrder } = this.state
        const { positions, marginAccountBalances, crossMarginAccountBalances, symbolPricings, accountItems, riskRatioThresholds } = this.props

        const result = []
        _.forEach(positions, position => {
            const { product_name: symbol, liquidation_price: liquidationPrice, account_name: accountName } = position 
            const accountItem = accountItems[accountName]
            const pricingItem = symbolPricings[symbol]
            const ratio = liquidationPrice > 0 ? getPositionLiquidationRatio(position, pricingItem) : null
            const riskRatioThreshold = getRiskRatioThresholdByPositionItem(riskRatioThresholds, position)
            const riskRatioThresholdReference = _.has(riskRatioThreshold, 'reference') ? riskRatioThreshold.reference: null
            const targetString = `position ${symbol} ${accountName}`
            if (isMetSearchStringCriteria(targetString, searchString) 
                && (portfolioName === ALL 
                    || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName))
                ) {
                result.push(RiskItem({
                    type: RiskTypes.POSITION,
                    symbol,
                    ratio,
                    riskRatioThresholdReference,
                    ratioDiff: !_.isNil(ratio) && !_.isNil(riskRatioThresholdReference) ? Math.abs(ratio) - Number(riskRatioThresholdReference) : null,
                    detail: position
                }))
            }
        })
        _.forEach(marginAccountBalances, marginAccountBalance => {
            const { coin1, coin2, acct_name: accountName, liquidation_price: liquidationPrice } = marginAccountBalance
            const accountItem = accountItems[accountName]
            const pricingItem = _.has(accountItem, 'exchange_name') ? symbolPricings[`${coin1}_${coin2}_${accountItem.exchange_name}_SPT`] : null
            const ratio = liquidationPrice > 0 ? getMarginTradingLiquidationRatio(marginAccountBalance, pricingItem) : null
            const targetString = `margin ${coin1}-${coin2} ${accountName}`
            if (isMetSearchStringCriteria(targetString, searchString)
                && (portfolioName === ALL
                    || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName))
                ) {
                result.push(RiskItem({
                    type: RiskTypes.MARGIN,
                    symbol: _.has(accountItem, 'exchange_name') ? `${coin1}_${coin2}_${accountItem.exchange_name}_SPT` : null,
                    ratio,
                    detail: marginAccountBalance
                }))
            }
        })
        _.forEach(crossMarginAccountBalances, crossMarginAccountBalance => {
            const { acct_name: accountName, coin, risk_rate: riskRate } = crossMarginAccountBalance
            const accountItem = accountItems[accountName]
            const targetString = `cross margin ${coin} ${accountName}`
            if (_.upperCase(coin || '') === 'USDT' && isMetSearchStringCriteria(targetString, searchString)
                && (portfolioName === ALL
                    || (!_.isNil(accountItem) && accountItem.portfolio_name === portfolioName))
                ) {
                const ratio = (riskRate - 1.6) / 1.6
                result.push(RiskItem({
                    type: RiskTypes.CROSS_MARGIN,
                    symbol: null,
                    ratio,
                    ratioDiff: Math.abs(riskRate) - 1.6,
                    detail: crossMarginAccountBalance
                }))
            }
        })

        let sortedRiskItems = []

        const [riskItemsShouldHighlight, riskItemsShouldNotHighlight] = _.partition(result, riskItem => {
            const { ratioDiff } = riskItem
            return !_.isNil(ratioDiff) && ratioDiff < 0
        })

        const [riskItemsHaveNilReference, remainingRiskItems] = _.partition(riskItemsShouldNotHighlight, riskItem => {
            const { type, ratio, riskRatioThresholdReference } = riskItem
            return type === RiskTypes.POSITION && !_.isNil(ratio) && _.isNil(riskRatioThresholdReference)
        })

        if (sortOrder === this.SORT_ORDERS.ASC) {
            sortedRiskItems = _.concat(
                _.sortBy(riskItemsShouldHighlight, riskItem => riskItem.ratioDiff),
                _.sortBy(riskItemsHaveNilReference, riskItem => Math.abs(riskItem.ratio)),
                _.sortBy(remainingRiskItems, riskItem => {
                    return !_.isNil(riskItem.ratioDiff) ? riskItem.ratioDiff
                        : _.isNumber(riskItem.ratio) ? Math.abs(riskItem.ratio)
                        : Infinity
                })
            )
        } else {
            const [remainingRiskItemsHaveRatioDiff, remainingRiskItemsHaveNilRatioDiff] = _.partition(remainingRiskItems, riskItem => !_.isNil(riskItem.ratioDiff))
            sortedRiskItems = _.concat(
                _.sortBy(remainingRiskItemsHaveRatioDiff, riskItem => -riskItem.ratioDiff),
                _.sortBy(riskItemsHaveNilReference, riskItem => -Math.abs(riskItem.ratio)),
                _.sortBy(riskItemsShouldHighlight, riskItem => -riskItem.ratioDiff),
                remainingRiskItemsHaveNilRatioDiff
            )
        }
        return sortedRiskItems
    }

    handleClickAccountName (accountName) {
        const { accountItems } = this.props
        const { searchString } = this.state
        const accountItem = accountItems[accountName]
        const trimmedSearchString = searchString.trim()
        const accountItemsRelated = _.filter(accountItems, item => {
            return item.account_name === accountItem.account_name
                || (!_.isEmpty(item.main_acct_name) 
                    && item.main_acct_name !== '0' 
                    && item.main_acct_name === accountItem.account_name.replace('huobifut_', 'huobi_').replace('bnbfuta_', 'binance_'))
                || (!_.isEmpty(accountItem.main_acct_name) 
                    && accountItem.main_acct_name !== '0' 
                    && accountItem.main_acct_name === item.account_name.replace('huobifut_', 'huobi_').replace('bnbfuta_', 'binance_'))
                || (!_.isEmpty(accountItem.main_acct_name) 
                    && accountItem.main_acct_name !== '0' 
                    && !_.isEmpty(item.main_acct_name) && accountItem.main_acct_name === item.main_acct_name)
        })
        this.setState({
            searchString: trimmedSearchString + (trimmedSearchString.length > 0 ? ' ' : '') + accountItemsRelated.map(item => item.account_name).join(' || ')
        })
    }

    renderRow (params, riskItem) {
        const { shouldShowRiskDetail, riskItemIdsShouldShowDetail, bulkTransfer } = this.state
        const { index, key, parent, style } = params
        const { type, detail } = riskItem
        const riskItemId = this.getRiskItemId(riskItem)
        const headTransferItem = _.head(Object.values(bulkTransfer.accountNameToTransferItemMaps))

        return (
            <CellMeasurer
                key={key} 
                rowIndex={index}
                columnIndex={0}
                parent={parent} 
                cache={this.cellMeasurerCache}>
                {({ measure, registerChild }) => {
                    let transferAdvice = null, bulkTransferSelected = false, bulkTransferSelectable = false

                    if (bulkTransfer.enabled) {
                        if (type === RiskTypes.POSITION) {
                            transferAdvice = getPositionItemTransferAdvice(detail)
                        } else if (type === RiskTypes.CROSS_MARGIN) {
                            transferAdvice = getCrossMarginAccountTransferAdivce(detail)
                        }

                        if (!_.isNil(transferAdvice)) {
                            bulkTransferSelected = transferAdvice.token === bulkTransfer.token && _.has(bulkTransfer.accountNameToTransferItemMaps, detail.account_name || detail.acct_name)
                            bulkTransferSelectable = (_.isNil(headTransferItem) 
                                    && !_.isNil(transferAdvice.token) 
                                    && !_.isNil(transferAdvice.transferItem))
                                || (transferAdvice.token === bulkTransfer.token 
                                    && _.has(transferAdvice.transferItem, 'originTransferAccount')
                                    && _.has(headTransferItem, 'originTransferAccount')
                                    && _.isEqual(transferAdvice.transferItem.originTransferAccount, headTransferItem.originTransferAccount))
                        }
                    }
                    
                    return (
                        <div className={'risk-container--item' + (!shouldShowRiskDetail ? ' clickable' : '') + (bulkTransfer.enabled && !bulkTransferSelectable ? ' not-allowed' : '')} 
                            ref={registerChild}
                            style={style}
                            onClick={(e) => {
                                if (bulkTransfer.enabled) {
                                    if (!bulkTransferSelectable) {
                                        e.stopPropagation()
                                    } else if (!bulkTransferSelected) {
                                        this.setState({
                                            bulkTransfer: Object.assign({}, bulkTransfer, {
                                                token: transferAdvice.token,
                                                accountNameToTransferItemMaps: dotProp.merge(bulkTransfer.accountNameToTransferItemMaps, detail.account_name || detail.acct_name, transferAdvice.transferItem),
                                                positionFilterSymbolName: _.isNil(bulkTransfer.positionFilterSymbolName) ? detail.product_name : bulkTransfer.positionFilterSymbolName
                                            })
                                        })
                                    } else {
                                        this.setState({
                                            bulkTransfer: Object.assign({}, bulkTransfer, {
                                                token: _.size(bulkTransfer.accountNameToTransferItemMaps) === 1 ? null : bulkTransfer.token,
                                                accountNameToTransferItemMaps: _.omit(bulkTransfer.accountNameToTransferItemMaps, detail.account_name || detail.acct_name),
                                                positionFilterSymbolName: _.size(bulkTransfer.accountNameToTransferItemMaps) === 1 ? null : bulkTransfer.positionFilterSymbolName
                                            })
                                        })
                                    }
                                } else if (!shouldShowRiskDetail) {
                                    this.setState({
                                        riskItemIdsShouldShowDetail: _.has(riskItemIdsShouldShowDetail, riskItemId) 
                                            ? dotProp.delete(riskItemIdsShouldShowDetail, riskItemId)
                                            : dotProp.set(riskItemIdsShouldShowDetail, riskItemId, 1)
                                    },() => {
                                        measure()
                                    })
                                }
                            }}>
                            {bulkTransfer.enabled && bulkTransferSelectable && 
                            <div className={'risk-container--item--bulk-transfer-select-mark' + (bulkTransferSelected ? ' marked' : '')}>
                                {bulkTransferSelected && <FaCheck />}
                            </div>}
                            {type === RiskTypes.POSITION && 
                            <PositionItem 
                                positionItem={detail}
                                hideDetails={!shouldShowRiskDetail && !_.has(riskItemIdsShouldShowDetail, riskItemId)} 
                                shouldShowTag 
                                tokenTransferEnabled={!bulkTransfer.enabled}
                                isAccountNameClickable
                                onClickAccountName={(accountName) => { this.handleClickAccountName(accountName) }} />}
                            {type === RiskTypes.MARGIN &&
                            <MarginAccountBalanceItem 
                                marginAccountBalance={detail}
                                shouldShowAccountTypeTag 
                                shouldShowAccountName
                                shouldShowDetail={shouldShowRiskDetail || _.has(riskItemIdsShouldShowDetail, riskItemId)} 
                                tokenTransferEnabled 
                                isAccountNameClickable
                                onClickAccountName={(accountName) => { this.handleClickAccountName(accountName) }}
                                onChangeComponentHeight={() => { setTimeout(() => { measure() }) }} />}
                            {type === RiskTypes.CROSS_MARGIN && 
                            <CrossMarginAccountBalanceItem 
                                crossMarginAccountBalance={detail}
                                shouldShowAccountTypeTag
                                shouldShowAccountName
                                shouldShowDetail={shouldShowRiskDetail || _.has(riskItemIdsShouldShowDetail, riskItemId)} 
                                tokenTransferEnabled={!bulkTransfer.enabled} 
                                onChangeComponentHeight={() => { setTimeout(() => { measure() }) }} />}
                        </div>
                    )
                }}
            </CellMeasurer>
        )
    }

    render () {
        const { dispatch } = this.props
        const { searchString, portfolioName, shouldShowRiskDetail, sortOrder, bulkTransfer } = this.state
        const riskItems = this.getRiskItems()
        const riskItemIds = riskItems.map(riskItem => this.getRiskItemId(riskItem))
        const portfolioNames = getPortfolioNames()
        const portfolioOptions = _.map(_.concat(ALL, portfolioNames), portfolio => {
            return {
                value: portfolio,
                name: portfolio
            }
        })

        if (!_.isEqual(this.prevRiskItemIds, riskItemIds)) {
            this.cellMeasurerCache.clearAll()
            this.prevRiskItemIds = riskItemIds
        }

        return (
            <div className='risk-container'>
                <div className='risk-container--header'>
                    <div className='risk-container--title'>{'Risk'}</div>
                </div>
                <div className='risk-container--menu'>
                    <button className='risk-container--toggle-detail-button' 
                        onClick={() => { 
                            this.cellMeasurerCache.clearAll()
                            this.updateShouldShowDetail(!shouldShowRiskDetail) 
                            this.listNode.scrollToPosition(0)
                        }}>
                        {shouldShowRiskDetail ? 'Hide Details' : 'Show Details'}
                    </button>
                    <button className={'risk-container--toggle-bulk-transfer-selector-button' + (bulkTransfer.enabled ? ' enabled' : '')}
                        onClick={() => {
                            this.setState({
                                bulkTransfer: Object.assign({}, bulkTransfer, {
                                    enabled: !bulkTransfer.enabled,
                                    token: null,
                                    accountNameToTransferItemMaps: {},
                                    positionFilterSymbolName: null
                                })
                            })
                        }}>
                        {bulkTransfer.enabled ? 'Reset Selection' : 'Select to XFR'}
                    </button>
                    <button className='risk-container--sort-order-button' 
                        onClick={() => {
                            this.setState({
                                sortOrder: sortOrder === this.SORT_ORDERS.ASC ? this.SORT_ORDERS.DESC : this.SORT_ORDERS.ASC
                            })
                            this.listNode.scrollToPosition(0)
                        }}>
                        {sortOrder === this.SORT_ORDERS.ASC ? <FaSortAmountDownAlt /> : <FaSortAmountDown />}
                    </button>
                </div>
                <input className='risk-container--search-input' value={searchString}
                    placeholder={'Search Symbol, Account'}
                    autoFocus
                    spellCheck={false}
                    onChange={(e) => { 
                        this.setState({ searchString: e.target.value }) 
                        setTimeout(() => { this.listNode.scrollToPosition(0) })
                    }} />
                <div className='risk-container--portfolio'>
                    <label>{'Portfolio'}</label>
                    <SearchSelect 
                        value={portfolioName}
                        options={portfolioOptions} 
                        hideSearchBar 
                        onChange={(newOption) => {
                            updateSessionStoragePortfolio(newOption.value)
                            this.setState({ portfolioName: getSessionStoragePortfolio() })
                        }} />
                </div>
                <div className='risk-container--body'>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List 
                                ref={(node) => { this.listNode = node }}
                                className='risk-container--list'
                                width={width}
                                height={height} 
                                rowCount={_.size(riskItems)}
                                rowRenderer={params => this.renderRow(params, riskItems[params.index])} 
                                rowHeight={this.cellMeasurerCache.rowHeight} 
                                overscanRowCount={5} 
                                scrollToAlignment={'start'}
                                deferredMeasurementCache={this.cellMeasurerCache}  
                                onRowsRendered={({ startIndex }) => { this.renderedStartIndex = startIndex }}/>
                        )}
                    </AutoSizer>
                </div>
                {bulkTransfer.enabled && 
                <div className='risk-container--bulk-transfer-summary'>
                    <span>{`${_.size(bulkTransfer.accountNameToTransferItemMaps)} Selected Account${_.size(bulkTransfer.accountNameToTransferItemMaps) > 1 ? 's' : ''}`}</span>
                    <button className='risk-container--bulk-transfer-summary--confirm-button n-to-1' 
                        disabled={_.size(bulkTransfer.accountNameToTransferItemMaps) === 0} 
                        onClick={() => {
                            const headTransferItem = _.head(Object.values(bulkTransfer.accountNameToTransferItemMaps))
                            const switchedTransferItems = _.map(Object.values(bulkTransfer.accountNameToTransferItemMaps), transferItem => {
                                const switchedTransferItem = _.cloneDeep(transferItem) 
                                const { originTransferAccount, destinationTransferAccount } = switchedTransferItem
                                switchedTransferItem.originTransferAccount = destinationTransferAccount
                                switchedTransferItem.destinationTransferAccount = originTransferAccount
                                return switchedTransferItem
                            })
                            batch(() => {
                                dispatch(updateLayoutBulkTransferWindowConfig({
                                    updateId: uuidv4(),
                                    token: bulkTransfer.token,
                                    bulkTransferMode: BULK_TRANSFER_MODES.MANY_TO_ONE,
                                    oneToManyOriginTransferAccount: TransferAccount({}),
                                    manyToOneDestinationTransferAccount: headTransferItem.originTransferAccount || TransferAccount({}),
                                    transferItems: switchedTransferItems,
                                    positionFilterSymbolName: bulkTransfer.positionFilterSymbolName
                                }))
                                dispatch(updateLayoutBulkTransferWindowId())
                            })
                        }}>{'N:1'}</button>
                    <button className='risk-container--bulk-transfer-summary--confirm-button 1-to-n' 
                        disabled={_.size(bulkTransfer.accountNameToTransferItemMaps) <= 0}
                        onClick={() => {
                            const headTransferItem = _.head(Object.values(bulkTransfer.accountNameToTransferItemMaps))
                            batch(() => {
                                dispatch(updateLayoutBulkTransferWindowConfig({
                                    updateId: uuidv4(),
                                    token: bulkTransfer.token,
                                    bulkTransferMode: BULK_TRANSFER_MODES.ONE_TO_MANY,
                                    oneToManyOriginTransferAccount: headTransferItem.originTransferAccount || TransferAccount({}),
                                    manyToOneDestinationTransferAccount: TransferAccount({}),
                                    transferItems: Object.values(bulkTransfer.accountNameToTransferItemMaps),
                                    positionFilterSymbolName: bulkTransfer.positionFilterSymbolName
                                }))
                                dispatch(updateLayoutBulkTransferWindowId())
                            })  
                        }}>{'1:N'}</button>
                </div>}
            </div>
        )
    }
} 

PositionContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    positions: PropTypes.array.isRequired,
    marginAccountBalances: PropTypes.object.isRequired,
    crossMarginAccountBalances: PropTypes.object.isRequired,
    symbolPricings: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    riskRatioThresholds: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        positions: state.trading.positions,
        marginAccountBalances: state.account.balance.margin,
        crossMarginAccountBalances: state.account.balance.crossMargin,
        symbolPricings: state.symbol.pricings,
        accountItems: state.account.items,
        riskRatioThresholds: state.trading.riskRatioThresholds
    }
}

export default connect(mapStateToProps)(PositionContainer)