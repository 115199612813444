import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'

import { updateSettingSoundEffect } from './settingAction'
import SearchSelect from '../common/searchSelect/SearchSelect'
import Checkbox from '../common/checkbox/Checkbox'

import { SOUND_EFFECTS, playSound } from '../../util/soundEffectUtil'


class SoundEffectSetting extends Component {
    constructor(props) {
        super(props)
        this.soundEffectEventNames = {
            PROFILE_SWITCH_OFF: 'Profile: SWITCH_OFF',
            PROFILE_QTY_CAPPED: 'Profile: QTY_CAPPED',
            PROFILE_START_FAIL: 'Profile: START_FAIL',
            PROFILE_PARA_LOAD_FAIL: 'Profile: PARA_LOAD_FAIL',
            PROFILE_CRASH: 'Profile: CRASH',
            PROFILE_PAUSED: 'Profile: PAUSED',
            ACCOUNT_BALANCE_MARGIN_RATIO_WARNING: "ACCT BAL.: MR. Warning"
        }
        this.sound = null
    }

    render () {
        const { dispatch, soundEffect } = this.props
        const soundEffectOptions = Object.values(SOUND_EFFECTS).map(effect => {
            return {
                value: effect.value,
                name: effect.name
            }
        })
        return (
            <div className='sound-effect-setting'>
                <div className='sound-effect-setting--title'>{'Sound Effect'}</div>
                <div className='sound-effect-setting--main'>
                    {_.map(soundEffect.events, (soundEffectValue, eventKey) => {
                        return (
                            <div className='sound-effect-setting--row' key={eventKey}>
                                <div className='sound-effect-setting--row--name'>{this.soundEffectEventNames[eventKey] || eventKey}</div>
                                <div className='sound-effect-setting--row--value'>
                                    <SearchSelect 
                                        placeholder={'None'}
                                        options={soundEffectOptions} 
                                        value={soundEffectValue} 
                                        hideOptionsOnSelect={false}
                                        hasClearButton
                                        onClickClearButton={() => {
                                            dispatch(updateSettingSoundEffect({
                                                events: dotProp.set(soundEffect.events, eventKey, null)
                                            }))
                                        }}
                                        onChange={(newOption) => {
                                            playSound(newOption.value)
                                            dispatch(updateSettingSoundEffect({
                                                events: dotProp.set(soundEffect.events, eventKey, newOption.value)
                                            }))
                                        }} />
                                    {eventKey === 'PROFILE_SWITCH_OFF' && <div className='sound-effect-setting--switch-off-including-nac'>
                                        <Checkbox checked={soundEffect.profileSwitchOffIncludingNAC} 
                                            onChange={(newChecked) => {
                                                dispatch(updateSettingSoundEffect({
                                                    profileSwitchOffIncludingNAC: newChecked
                                                }))
                                            }} />
                                        <span>{'Include NAC Switch-Off'}</span>
                                    </div>}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

SoundEffectSetting.propTypes = {
    dispatch: PropTypes.func.isRequired,
    soundEffect: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        soundEffect: state.setting.soundEffect
    }
}
export default connect(mapStateToProps)(SoundEffectSetting)