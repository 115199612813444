import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { FiX } from 'react-icons/fi'

import SoundEffectSetting from './SoundEffectSetting'

export default class SettingModal extends Component {
    render () {
        const { onClickClose } = this.props
        return (
            <Modal
                overlayClassName='setting-modal--overlay'
                className='setting-modal'
                isOpen>
                <div className='setting-modal--body horizontal-centered'>
                    <div className='setting-modal--header clearfix'>
                        <div className='setting-modal--header--title'>{'Setting'}</div>
                        <button className='setting-modal--header--close-button' onClick={() => { onClickClose() }}><FiX /></button>
                    </div>
                    <div className='setting-modal--main'>
                        <SoundEffectSetting />
                    </div>
                </div>  
            </Modal>
        )
    }
}

SettingModal.propTypes = {
    onClickClose: PropTypes.func.isRequired
}

SettingModal.defaultProps = {
    onClickClose: () => {}
}

