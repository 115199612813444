import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FaPlay, FaPlug, FaRedoAlt, FaRegPlayCircle, FaPauseCircle, FaMinusSquare } from 'react-icons/fa'

export default class ProfileActionButtons extends Component {
    render () {
        const { disableRestart, disableStop, disableResume, disablePause, disableCancelOrder, 
            hideResume, hidePause, cancelOrderButtonText,
            onClickRestart, onClickCleanRestart, onClickStop, onClickResume, onClickPause, onClickCancelOrder } = this.props
        return (
            <div className='profile-action-buttons'>
                <button className='profile-action-buttons--button' disabled={disableRestart}
                    onClick={() => { onClickRestart() }}><FaPlay />{'Restart'}</button>
                <button className='profile-action-buttons--button' disabled={disableRestart}
                    onClick={() => { onClickCleanRestart()}}><FaRedoAlt />{'Clean Restart'}</button>
                <button className='profile-action-buttons--button' disabled={disableStop}
                    onClick={() => { onClickStop() }}><FaPlug />{'Stop'}</button>
                {!hideResume && <button className='profile-action-buttons--button' disabled={disableResume} 
                    onClick={() => { onClickResume() }}><FaRegPlayCircle />{'Resume'}</button>}
                {!hidePause && <button className='profile-action-buttons--button' disabled={disablePause}
                    onClick={() => { onClickPause() }}><FaPauseCircle />{'Pause'}</button>}
                <button className='profile-action-buttons--button' disabled={disableCancelOrder}
                    onClick={() => { onClickCancelOrder() }}><FaMinusSquare />{cancelOrderButtonText}</button>
            </div>
        )
    }
}

ProfileActionButtons.propTypes = {
    disableRestart: PropTypes.bool,
    disableStop: PropTypes.bool,
    disableResume: PropTypes.bool,
    disablePause: PropTypes.bool,
    disableCancelOrder: PropTypes.bool,
    hideResume: PropTypes.bool,
    hidePause: PropTypes.bool,
    cancelOrderButtonText: PropTypes.string,
    onClickRestart: PropTypes.func.isRequired,
    onClickCleanRestart: PropTypes.func.isRequired,
    onClickStop: PropTypes.func.isRequired,
    onClickResume: PropTypes.func.isRequired,
    onClickPause: PropTypes.func.isRequired,
    onClickCancelOrder: PropTypes.func.isRequired
}

ProfileActionButtons.defaultProps = {
    cancelOrderButtonText: 'Cancel Orders',
    onClickRestart: () => {},
    onClickCleanRestart: () => {},
    onClickStop: () => {},
    onClickResume: () => {},
    onClickPause: () => {},
    onClickCancelOrder: () => {}
}