import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import randomColor from 'randomcolor'
import dotProp from 'dot-prop-immutable'
import { format } from 'd3-format'
import uuidv4 from 'uuid/v4'

import { ChartCanvas, Chart } from 'react-stockcharts'
import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale'  
import { XAxis, YAxis } from 'react-stockcharts/lib/axes'
import { LineSeries } from 'react-stockcharts/lib/series'
import { CrossHairCursor, MouseCoordinateY } from 'react-stockcharts/lib/coordinates'
import { ClickCallback } from 'react-stockcharts/lib/interactive'

import { ALL } from './ExposureWindow'
import { isMetSearchStringCriteria, toNumberInputValue, toNumberWithSmartPrecision } from '../../util/util'

import { fetchHistoricalCoinExposures } from './tradingAction'
import { IoIosExpand } from 'react-icons/io'
import { FiMinimize } from 'react-icons/fi'

const EXPOSURE_TOKENS = {
    COIN: 'COIN',
    USDT: 'USDT'
}

const VALUE_TYPES = {
    VALUE: 'VALUE',
    DIFF: 'DIFF',
    DIFF_PERCENTAGE: 'DIFF %'
}

const getSessionStorageIsHidden = () => {
    const isHidden = sessionStorage.isExposureChartHidden
    return isHidden === '1'
}

const updateSessionStorageIsHidden = (isHidden='1') => {
    sessionStorage.isExposureChartHidden = isHidden
}

class ExposureChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isFetching: false,
            isHidden: getSessionStorageIsHidden(),
            historicalCoinExposures: [],
            exposureToken: _.keys(EXPOSURE_TOKENS)[0],
            valueType: _.keys(VALUE_TYPES)[1],
            chartId: uuidv4(),
            searchString: '',
            threshold: '',
            coins: [],
            chartCanvasWidth: null,
            chartCanvasHeight: null,
            currentItem: null,
            selectedSnapshot: {
                position: [],
                timestamp: null
            }
        }
        this._mounted = false
        this.exposureChartNode = null
        this.chartCanvasNode = null
        this.isLoadMoreDataLocked = false
        this.chartComponent = {
            data: [],
            xScale: null,
            xAccessor: () => {},
            displayXAccessor: () => {},
            xExtents: []
        }
        this.coinColors = {}
        this.handleResizeWindow = this.handleResizeWindow.bind(this)
    }

    componentDidMount () {
        const window = this._getWindow()
        this._mounted = true
        this._updateChartCanvasWidthHeight()
        this._getData()
        if (window) {
            window.addEventListener('resize', this.handleResizeWindow)
        }
    }

    componentDidUpdate (prevProps) {
        const { portfolioName: prevPortfolioName } = prevProps
        const { portfolioName } = this.props
        const { historicalCoinExposures, exposureToken, valueType } = this.state
        if (!_.isEqual(prevPortfolioName, portfolioName)) {
            this._getDerivedChartComponentFromHistoricalCoinExposures({ historicalCoinExposures, exposureToken, valueType })
            setTimeout(() => {
                this.setState({ 
                    coins: this._getFilteredCoins(this.chartComponent.data),
                    currentItem: null
                })
            })
        }
    }

    componentWillUnmount () {
        const window = this._getWindow()
        this._mounted = false
        if (window) {
            window.removeEventListener('resize', this.handleResizeWindow)
        }
    }

    _getWindow () {
        return this.exposureChartNode && this.exposureChartNode.ownerDocument
            ? (this.exposureChartNode.ownerDocument.defaultView || this.exposureChartNode.ownerDocument.parentWindow)
            : null  
    }

    handleResizeWindow () {
        this._updateChartCanvasWidthHeight()
    }

    _updateChartCanvasWidthHeight () {
        const { shouldUseWrapperHeight } = this.props
        const { exposureChartNode } = this

        if (exposureChartNode) {
            const widthValue = exposureChartNode.offsetWidth
            const heightValue = shouldUseWrapperHeight ? exposureChartNode.offsetHeight : widthValue * 0.62
            exposureChartNode.style.height = `${heightValue}px`
            this.setState({
                chartCanvasWidth: widthValue,
                chartCanvasHeight: heightValue
            })
        }
    }

    _getFilteredCoins (chartData=[]) {
        const { searchString, threshold } = this.state
        const coins = _.reduce(chartData, (result, chartDataItem) => {
            _.forEach(chartDataItem.values, (coinValue, coin) => { 
                if ((searchString.trim().length === 0 || isMetSearchStringCriteria(coin, searchString))
                    && (_.isNil(threshold) || Math.abs(coinValue) >= Number(threshold))) {
                    result[coin] = 1   
                }
            })
            return result
        }, {}) 
        return _.keys(coins)
    }

    _getCoinUSDTPrice (coin, pricings) {
        coin = coin.toLowerCase()
    
        const symbolNamesToInsepct = [`${coin}_usdt_OKEX_SPT`, `${coin}_usdt_HUOBI_SPT`, `${coin}_usdt_BINANCE_SPT`,
            `${coin}_usdt_FTX_SPT`, `${coin}_usd_FTX_SPT`, `${coin}_busd_BINANCE_SPT`, 
            `${coin}_usdt_PHEMEX_SPT`, `${coin}_usdt_CONFLUX_SPT`, `usdt_${coin}_HUOBI_SPT`, `usdt_${coin}_FTX_SPT`]

        const symbolName = _.find(symbolNamesToInsepct, name => _.has(pricings, name))
        const lastPrice = !_.isNil(symbolName) ? pricings[symbolName] : null
        return coin === 'usdt' ? 1
            : lastPrice ? (symbolName.split('_')[0] === coin ? lastPrice : (1 / lastPrice))
            : null  
    }

    _getDerivedChartComponentFromHistoricalCoinExposures ({ historicalCoinExposures=[], exposureToken, valueType }) {     
        const { portfolioName } = this.props

        const CoinValue = ({ coin, netExposure, netExposureInUSDT }) => {
            return { coin, netExposure, netExposureInUSDT }
        }
        
        const getHistoricalItemCoinValues = (snapshot) => {
            const { portfolioExposures, initialBalance, pricings } = snapshot

            let coinExposures = {}
            if (!_.isNil(portfolioExposures)) {
                if (!_.isEmpty(portfolioName) && portfolioName !== ALL) {
                    coinExposures = portfolioExposures[portfolioName] || {}
                } else {
                    _.forEach(portfolioExposures, portfolioCoinExposures => {
                        _.forEach(portfolioCoinExposures, (value, coin) => {
                            coinExposures[coin] = (coinExposures[coin] || 0) + value
                        })
                    })
                }
            }

            const coinValues = {}
            _.forEach(coinExposures, (exposure, coin) => {
                let coinInitialBalance = 0
                if (!_.isEmpty(portfolioName) && portfolioName !== ALL) {
                    if (_.has(initialBalance, `initial_balance_${portfolioName}.${_.lowerCase(coin)}`)) {
                        coinInitialBalance = initialBalance[`initial_balance_${portfolioName}`][_.lowerCase(coin)] || 0
                    }
                } else {
                    _.forEach(initialBalance, portfolioInitialBalance => {
                        if (_.has(portfolioInitialBalance, _.lowerCase(coin))) {
                            coinInitialBalance += Number(portfolioInitialBalance[_.lowerCase(coin)])
                        }
                    })
                }
                const netExposure = Number(exposure) - coinInitialBalance
                const coinUSDTPrice = this._getCoinUSDTPrice(coin, pricings)
                coinValues[coin] = CoinValue({
                    coin,
                    netExposure,
                    netExposureInUSDT: coinUSDTPrice ? netExposure * coinUSDTPrice : null
                })
            })
            return coinValues
        }
    
        const initialChartData = []
        if (!_.isEmpty(historicalCoinExposures)) {
            const lastData = _.last(historicalCoinExposures)
            const lastDataCoinValues = getHistoricalItemCoinValues(lastData)

            _.forEach(historicalCoinExposures, historicalItem => {
                const chartDataItem = {
                    timestamp: historicalItem.timestamp,
                    values: {}
                }
                const coinValues = getHistoricalItemCoinValues(historicalItem)
                _.forEach(coinValues, (coinValue, coin) => {
                    const baseValue = exposureToken === EXPOSURE_TOKENS.COIN ? coinValue.netExposure : exposureToken === EXPOSURE_TOKENS.USDT ?  coinValue.netExposureInUSDT : null
                    if (valueType === 'VALUE') {
                        chartDataItem.values[coin] = baseValue
                    } else if (['DIFF', 'DIFF_PERCENTAGE'].includes(valueType)) {
                        let valueToCompare = null
                        if (_.has(lastDataCoinValues, coin)) {
                            const lastDataCoinValue = lastDataCoinValues[coin]
                            valueToCompare = exposureToken === EXPOSURE_TOKENS.COIN ? lastDataCoinValue.netExposure : exposureToken === EXPOSURE_TOKENS.USDT ?  lastDataCoinValue.netExposureInUSDT : null
                        }
                        if (valueType === 'DIFF') {
                            chartDataItem.values[coin] = baseValue - Number(valueToCompare)
                        } else if (valueType === 'DIFF_PERCENTAGE' && _.isNumber(valueToCompare) && !_.isNaN(valueToCompare) && valueToCompare !== 0) {
                            chartDataItem.values[coin] = (baseValue - valueToCompare) / Math.abs(valueToCompare)
                        }
                    }
                })
                initialChartData.push(chartDataItem)
            })
        }

        const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(d => moment(d.timestamp).toDate())
        const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(initialChartData)
        this.chartComponent = {
            data,
            xScale,
            xAccessor,
            displayXAccessor,
            xExtents: _.isEmpty(this.chartComponent.xExtents) ? [xAccessor(_.last(data)), xAccessor(data[Math.max(0, data.length - 50)])] : this.chartComponent.xExtents
        }
    } 

    _getData () {
        const { dispatch } = this.props
        const { isFetching, historicalCoinExposures } = this.state
        if (!isFetching) {
            const to = !_.isEmpty(historicalCoinExposures) ? _.head(historicalCoinExposures).timestamp : null
            this.setState({ isFetching: true })
            dispatch(fetchHistoricalCoinExposures(to))
            .then(response => response.json())
            .then(body => {
                if (this._mounted) {
                    if (_.isArray(body)) {
                        const newHistoricalCoinExposures = _.sortBy(_.unionBy(this.state.historicalCoinExposures, body, 'timestamp'), data => moment(data.timestamp).valueOf())
                        this._getDerivedChartComponentFromHistoricalCoinExposures({
                            historicalCoinExposures: newHistoricalCoinExposures,
                            exposureToken: this.state.exposureToken,
                            valueType: this.state.valueType
                        })
                        setTimeout(() => {
                            this.setState({
                                isFetching: false,
                                historicalCoinExposures: newHistoricalCoinExposures,
                                coins: this._getFilteredCoins(this.chartComponent.data)
                            })
                        })
                    } else {
                        throw new Error('Expsore Chart getData Error: Unexpected return')
                    }
                } 
            })
            .catch(error => {
                console.error('Exposure Chart Error: ', error)
                if (this._mounted) {
                    this.setState({ isFetching: false })
                }
            })
        }
    }

    _getCoinColor (coin) {
        if (!this.coinColors[coin]) {
            this.coinColors[coin] = randomColor()
        }
        return this.coinColors[coin]
    }

    SelectedSnapshot () {
        const { onClickFetchSnapshotButton } = this.props
        const { selectedSnapshot } = this.state
        return _.isArray(selectedSnapshot.position) && _.size(selectedSnapshot.position) === 2 && !_.isNil(selectedSnapshot.timestamp) ? (
            <div className='exposure-chart--selected-snapshot' style={{ 
                position: 'absolute',
                left: `${selectedSnapshot.position[0]}px`,
                top: `${selectedSnapshot.position[1]}px`
            }}>
                <div className='exposure-chart--selected-snapshot--title'>{`Fetch Snapshot: ${moment(selectedSnapshot.timestamp).format('MM-DD HH:mm:ss')}`}</div>
                <div className='exposure-chart--selected-snapshot--buttons'>
                    <button onClick={() => { 
                        onClickFetchSnapshotButton({ index: 0, timestamp: selectedSnapshot.timestamp }) 
                        this.setState({ selectedSnapshot: dotProp.set(selectedSnapshot, 'position', []) })
                    }}>{'LEFT PANEL'}</button>
                    <button onClick={() => { 
                        onClickFetchSnapshotButton({ index: 1, timestamp: selectedSnapshot.timestamp }) 
                        this.setState({ selectedSnapshot: dotProp.set(selectedSnapshot, 'position', []) })
                    }}>{'RIGHT PANEL'}</button>
                </div>
            </div>
        ) : null
    }

    Header () {
        const { exposureToken, isFetching, valueType, searchString, threshold } = this.state
        const { portfolioName, shouldHideExpandButton, shouldHideMinimizeButton, onClickExpandButton, onClickMinimizeButton } = this.props
        return (
            <div className='exposure-chart--header'>
                <div className='exposure-chart--header--title clearfix'>
                    {`Net Exposure Changes: ${portfolioName}`}
                    {isFetching && <span>{'Fetching...'}</span>}
                    {!shouldHideExpandButton && 
                    <Fragment>
                        <button className='exposure-chart--header--expand-button'
                            onClick={() => { onClickExpandButton() }}><IoIosExpand /></button>
                        <button className='exposure-chart--header--hide-button'
                            onClick={() => {
                                updateSessionStorageIsHidden('1')
                                this.setState({ isHidden: getSessionStorageIsHidden() })
                            }}>{'HIDE'}</button>
                    </Fragment>}
                    {!shouldHideMinimizeButton && <button className='exposure-chart--header--minimize-button'
                        onClick={() => { onClickMinimizeButton() }}><FiMinimize /></button>}
                    <button className='exposure-chart--header--reset-button' 
                        onClick={() => {
                        this.chartComponent = {
                            data: [],
                            xScale: null,
                            xAccessor: () => {},
                            displayXAccessor: () => {},
                            xExtents: []
                        }
                        this.setState({
                            searchString: '',
                            threshold: '',
                            historicalCoinExposures: [],
                            exposureToken: EXPOSURE_TOKENS.COIN,
                            valueType: _.keys(VALUE_TYPES)[1],
                            currentItem: null
                        }, () => {
                            this._getData()
                        })
                    }}>{'RESET'}</button>

                </div>
                <div className='exposure-chart--header--search-row'>
                    <input className='exposure-chart--header--search-input'
                        placeholder={'Search Coins'}
                        spellCheck={false}
                        value={searchString}
                        onChange={(e) => {
                            this.setState({ searchString: e.target.value })
                        }} 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                const newFilteredCoins = this._getFilteredCoins(this.chartComponent.data)
                                this.setState({ coins: newFilteredCoins })
                            }
                        }}/>
                    <input className='exposure-chart--header--threshold-input'
                        placeholder={'Treshold'}
                        type={'number'}
                        min={0}
                        value={threshold}
                        onChange={(e) => {
                            this.setState({ threshold: toNumberInputValue(e.target.value) })
                        }} 
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                const newFilteredCoins = this._getFilteredCoins(this.chartComponent.data)
                                this.setState({ coins: newFilteredCoins })
                            }
                        }}/>
                    <button className='exposure-chart--header--shuffle-color-button'
                        onClick={() => {
                            _.forEach(_.keys(this.coinColors), coin => this.coinColors[coin] = randomColor())
                            this.setState({ chartId: uuidv4() })
                        }}>{'Shuffle Colors'}</button>
                </div>
                <div className='exposure-chart--header--criteria-row clearfix'>
                    <div className='exposure-chart--header--exposure-token'>
                        <label>{'Currency'}</label>
                        <div className='exposure-chart--header--exposure-token--selectors'>
                            {_.map(EXPOSURE_TOKENS, (name, key) => {
                                return (
                                    <button key={key}
                                        className={key === exposureToken ? 'selected' : ''}
                                        onClick={() => {
                                            this._getDerivedChartComponentFromHistoricalCoinExposures({ 
                                                historicalCoinExposures: this.state.historicalCoinExposures,
                                                exposureToken: key,
                                                valueType: this.state.valueType
                                            })
                                            setTimeout(() => {
                                                this.setState({ 
                                                    exposureToken: key,
                                                    coins: this._getFilteredCoins(this.chartComponent.data),
                                                    currentItem: null
                                                })
                                            })
                                        }}>{name}</button>
                                )
                            })}
                        </div>
                    </div>
                    <div className='exposure-chart--header--value-type'>
                        <label>{'Y Axis'}</label>
                        <div className='exposure-chart--header--value-type--selectors'>
                            {_.map(VALUE_TYPES, (name, key) => {
                                return (
                                    <button key={key}
                                        className={key === valueType ? 'selected' : ''}
                                        onClick={() => {
                                            this._getDerivedChartComponentFromHistoricalCoinExposures({ 
                                                historicalCoinExposures: this.state.historicalCoinExposures,
                                                exposureToken: this.state.exposureToken,
                                                valueType: key
                                            })
                                            this.setState({
                                                threshold: ''
                                            }, () => {
                                                this.setState({ 
                                                    valueType: key,
                                                    coins: this._getFilteredCoins(this.chartComponent.data),
                                                    currentItem: null
                                                })
                                            })
                                        }}>{name}</button>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    Tooltip () {
        const { currentItem, coins, valueType } = this.state
        return currentItem ? (
            <div className={'exposure-chart--tooltip'}>
                <div className='exposure-chart--tooltip--timestamp'>{moment(currentItem.timestamp).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div className='exposure-chart--tooltip--data'>
                    {_.sortBy(_.toPairs(currentItem.values), coinValuePair => -coinValuePair[1]).map(coinValuePair => {
                        const [coin, value] = coinValuePair
                        return coins.includes(coin) ? (
                            <div className='exposure-chart--tooltip--item' key={coin}>
                                <span className='exposure-chart--tooltip--item--dot' style={{ background: this._getCoinColor(coin) }} />
                                <span className='exposure-chart--tooltip--item--name'>{coin}</span>
                                <span className='exposure-chart--tooltip--item--value'>
                                    {valueType === 'DIFF_PERCENTAGE' ? ((value * 100).toFixed('1') + '%') : toNumberWithSmartPrecision({ number: value, shouldReturnLocalString: true })}
                                </span>
                            </div>
                        ) : null
                    })}
                </div>
            </div>
        ) : null
    }


    render () {
        const { coins, chartId, chartCanvasWidth, chartCanvasHeight, valueType, selectedSnapshot, isHidden } = this.state
        const { data, xScale, xAccessor, displayXAccessor, xExtents } = this.chartComponent
        const grid = {
            tickStrokeDasharray: 'ShortDash',
            tickStroke: '#dddddd',
            tickStrokeOpacity: 0.3,
            tickStrokeWidth: 0.3
        }

        return isHidden 
        ? <button className='exposure-chart--show-button'
            onClick={() => {
                updateSessionStorageIsHidden('0')
                this.setState({ isHidden: getSessionStorageIsHidden() })
            }}>{'Show Exposure Changes'}</button>
        : <div className='exposure-chart'>
            <div className='exposure-chart--main'>
                {this.Header()}
                <div className='exposure-chart--wrapper' 
                    ref={(node) => { this.exposureChartNode=node }} >
                    {!_.isEmpty(data) && chartCanvasWidth > 0 && chartCanvasHeight > 0 && <ChartCanvas 
                        ref={(node) => { this.chartCanvasNode = node }}
                        type='hybrid'
                        seriesName='exposure-history'
                        ratio={2}
                        width={chartCanvasWidth}
                        height={chartCanvasHeight}
                        margin={{ left: 10, right: 53, top: 10, bottom: 30 }}
                        data={data}
                        xAccessor={xAccessor}
                        displayXAccessor={displayXAccessor}
                        xScale={xScale} 
                        xExtents={xExtents}
                        onLoadMore={() => { 
                            if (!this.isLoadMoreDataLocked) {
                                this.isLoadMoreDataLocked = true
                                this._getData()
                                setTimeout(() => {
                                    this.isLoadMoreDataLocked = false
                                }, 3000)
                            }
                        }}>
                        <Chart id={chartId} 
                            origin={[0, 0]}
                            yExtents={d => Object.values(_.pick(d.values, coins))}
                            padding={{ top: 20, bottom: 20, left: 0, right: 0 }}>
                            <XAxis 
                                axisAt='bottom'
                                orient='bottom'
                                fontSize={12}
                                fontWeight={600}
                                tickPadding={11}
                                ticks={7}
                                stroke={'#dddddd'}
                                fontFamily={'Source Sans Pro'} 
                                innerTickSize={-1 * (chartCanvasHeight - 40)}
                                {...grid}/>
                            <YAxis 
                                axisAt='right'
                                orient='right'
                                tickFormat={format(valueType === 'DIFF_PERCENTAGE' ? ',.1%' : '.3s')}
                                fontSize={12}
                                fontWeight={600}
                                tickPadding={7}
                                ticks={5}
                                stroke={'#dddddd'}
                                fontFamily='Source Sans Pro' 
                                innerTickSize={-1 * (chartCanvasWidth - 40)}
                                {...grid} />
                            <MouseCoordinateY
                                at='right'
                                orient='right'
                                fontSize={12}
                                dx={4}
                                arrowWidth={5}
                                fill={'#081a1b'}
                                textFill={'white'}
                                rectHeight={19}
                                fontFamily={'Source Sans Pro'}
                                displayFormat={format(valueType === 'DIFF_PERCENTAGE' ? ',.1%' : '.3s')} />
                            {coins.map(coin => {
                                return (
                                    <Fragment key={coin}>
                                        <LineSeries 
                                            yAccessor={d => d.values[coin]} 
                                            stroke={this._getCoinColor(coin)}
                                            strokeWidth={1.2} />
                                    </Fragment>
                                )
                            })}
                            <ClickCallback
                                onMouseMove={(moreProps) => {
                                    const { currentItem } = moreProps
                                    this.setState({ 
                                        currentItem: currentItem
                                    })
                                }}
                                onClick={() => {
                                    if (!_.isEmpty(selectedSnapshot.position)) {
                                        this.setState({
                                            selectedSnapshot: {
                                                position: [],
                                                timestamp: null
                                            }
                                        })
                                    }
                                }}
                                onContextMenu={(moreProps) => {
                                    const { currentItem, mouseXY } = moreProps
                                    if (!_.isNil(currentItem) && _.isArray(mouseXY)) {
                                        this.setState({
                                            selectedSnapshot: {
                                                position: mouseXY,
                                                timestamp: currentItem.timestamp
                                            }
                                        })
                                    }
                                }} />
                        </Chart>
                        <CrossHairCursor 
                            stroke={'#042f2e'} 
                            opacity={1} />
                    </ChartCanvas>}
                </div>
            </div>
            {this.Tooltip()}
            {this.SelectedSnapshot()}
        </div>
    }
}

ExposureChart.defaultProps = {
    portfolioName: '',
    shouldHideExpandButton: false,
    shouldHideMinimizeButton: true,
    shouldUseWrapperHeight: false,
    onClickFetchSnapshotButton: () => {},
    onClickExpandButton: () => {},
    onClickMinimizeButton: () => {}
}

ExposureChart.propTypes = {
    dispatch: PropTypes.func.isRequired,
    shouldHideExpandButton: PropTypes.bool,
    shouldHideMinimizeButton: PropTypes.bool,
    shouldUseWrapperHeight: PropTypes.bool,
    onClickFetchSnapshotButton: PropTypes.func,
    onClickExpandButton: PropTypes.func,
    onClickMinimizeButton: PropTypes.func,

    portfolioName: PropTypes.string
}

function mapStateToProps () {
    return {}
}

export default connect(mapStateToProps)(ExposureChart)




