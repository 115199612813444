import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
// import dotProp from 'dot-prop-immutable'

import { IoMdDownload } from 'react-icons/io'
import { CSVLink } from 'react-csv'
import Popup from '../common/popup/Popup'
import { ALL } from './ExposureWindow'
import { toNumberWithSmartPrecision } from '../../util/util'
import { getNotional } from '../../util/tradingUtil'
import { getSymbolAttributeByName } from '../../util/symbolUtil'
import { updateExposureVariables } from './tradingAction'

const DEFAULT_COIN_THRESHOLD_MIN_VALUE = 20000

export const MODES = {
    EXPOSURE: 'EXPOSURE',
    NAV: 'NAV'
}

export const TABLE_GROUP_BYS = {
    COIN: 'COIN',
    ACCOUNT: 'ACCOUNT'
}

class ExposureTable extends Component {
    constructor (props) {
        super(props)
        this.coinSortBys = {
            NAME: 'NAME',
            SUM_EXPOSURE: 'SUM_EXPOSURE',
            SUM_EXPOSURE_IN_USDT: 'SUM_EXPOSURE_IN_USDT',
            INITIAL_BALANCE: 'INITIAL_BALANCE',
            NET_EXPOSURE_IN_ASSET: 'NET_EXPOSURE_IN_ASSET',
            NET_EXPOSURE_IN_USDT: 'NET_EXPOSURE_IN_USDT',
            NET_EXPOSURE_DIFF_IN_USDT: 'NET_EXPOSURE_DIFF_IN_USDT',
            POSITION_NOTIONAL_SUM: 'POSITION_NOTIONAL_SUM'
        }
        this.accountSortBys = {
            NAME: 'NAME',
            SUM_EXPOSURE: 'SUM_EXPOSURE',
            SUM_EXPOSURE_IN_USDT: 'SUM_EXPOSURE_IN_USDT',
            SUM_EXPOSURE_DIFF_IN_USDT: 'SUM_EXPOSURE_DIFF_IN_USDT'
        }
        this.itemSortBys = {
            SOURCE: 'SOURCE',
            TIME: 'TIME',
            EXPOSURE: 'EXPOSURE'
        }
        this.state = {
            groupBy: props.tableGroupBy,
            expandedPaths: props.config.expandedPaths || [], // Valid path can be: 'coin' || 'coin.accountName',
            coinSortBy: props.config.coinSortBy || this.getSessionStorageCoinSortBy(),
            accountSortBy: props.config.accountSortBy || this.getSessionStorageAccountSortBy(),
            itemSortBy: props.config.itemSortBy || this.getSessionStorageItemSortBy(),
        }
        this.editingVariables = {
            positionRatio: null,
            coinThresholdMinValues: {}
        }
    }

    static getDerivedStateFromProps (props, state) {
        if (!_.isEqual(props.tableGroupBy, state.groupBy)) {
            return {
                groupBy: props.tableGroupBy,
                expandedPaths: []
            }
        } else {
            return null
        }
    }

    componentDidUpdate (prevProps, prevState) {
        const { onChangeConfig } = this.props
        if (!_.isEqual(prevProps.config, this.props.config) && !_.isEqual(this.props.config, this.state)) {
            this.mergeState(this.props.config)
        }
        if (!_.isEqual(prevState, this.state)) {
            onChangeConfig(this.state)
        }
    }

    mergeState (newState) {
        this.setState((prevState) => {
            return Object.assign({}, prevState, newState)
        })
    }

    getSessionStorageCoinSortBy () {
        const coinSortBy = sessionStorage.exposureTableCoinSortBy
        return _.has(this.coinSortBys, coinSortBy) ? coinSortBy : Object.keys(this.coinSortBys)[0]
    }

    updateCoinSortBy (coinSortBy) {
        if (_.has(this.coinSortBys, coinSortBy)) {
            sessionStorage.exposureTableCoinSortBy = coinSortBy
            this.setState({ coinSortBy })
        }
    }

    getSessionStorageAccountSortBy () {
        const accountSortBy = sessionStorage.exposureTableAccountSortBy
        return _.has(this.accountSortBys, accountSortBy) ? accountSortBy : Object.keys(this.accountSortBys)[0]
    }

    updateAccountSortBy (accountSortBy) {
        if (_.has(this.accountSortBys, accountSortBy)) {
            sessionStorage.exposureTableAccountSortBy = accountSortBy
            this.setState({ accountSortBy })
        }
    }

    getSessionStorageItemSortBy () {
        const itemSortBy = sessionStorage.exposureTableItemSortBy
        return _.has(this.itemSortBys, itemSortBy) ? itemSortBy : Object.keys(this.itemSortBys)[0]
    }

    updateItemSortBy (itemSortBy) {
        if (_.has(this.itemSortBys, itemSortBy)) {
            sessionStorage.exposureTableItemSortBy = itemSortBy
            this.setState({ itemSortBy })
        }
    }

    isLevel1Expanded () {
        const { expandedPaths } = this.state
        return _.some(expandedPaths, path => path.length > 0 && !path.includes('.'))
    }

    isLevel2Expanded () {
        const { expandedPaths } = this.state
        const expandedLevel1Items = expandedPaths.filter(path => path.length > 0 && !path.includes('.'))
        return _.some(expandedPaths, path => path.length > 0 && path.includes('.') && _.includes(expandedLevel1Items, path.split('.')[0]))
    }

    getUSDTPriceByCoin (coin, pricings) {
        pricings = pricings || this.props.pricings
        coin = coin.includes('OPTION') 
            ? coin.replace(/\s+/g, '').split('-')[0].toLowerCase() 
            : coin.toLowerCase()
    
        const symbolNamesToInsepct = [`${coin}_usdt_BINANCE_SPT`, `${coin}_usdt_OKEX_SPT`, 
            `${coin}_usdt_FTX_SPT`, `usdt_${coin}_FTX_SPT`, 
            `${coin}_usdt_PHEMEX_SPT`, `${coin}_usdt_COINFLEX_SPT`,
            // `${coin}_usdt_HUOBI_SPT`, `usdt_${coin}_HUOBI_SPT`, 
            `${coin}_usd_FTX_SPT`, `${coin}_busd_BINANCE_SPT`, `${coin}_usd_COINFLEX_SPT`]

        const symbolName = _.find(symbolNamesToInsepct, name => _.has(pricings, name))
        const lastPrice = !_.isNil(symbolName) ? pricings[symbolName] : null
        
        return coin === 'usdt' ? 1
            : lastPrice ? (symbolName.split('_')[0] === coin ? lastPrice : (1 / lastPrice))
            : null  
    }

    _getInitialBalanceCoins (initialBalance={}) {
        const { portfolioName } = this.props
        let coins = []
        if (!_.isEmpty(portfolioName) && portfolioName !== ALL) {
            const portfolioInitialBalance = _.has(initialBalance, `initial_balance_${portfolioName}`)
                ? initialBalance[`initial_balance_${portfolioName}`]
                : {}
            _.forEach(portfolioInitialBalance, (value, coin) => {
                if (coin !== 'acct_name' && coin !== 'timestamp' && Number(value) > 0) {
                    coins.push(_.upperCase(coin))
                }
            })
        } else {
            _.forEach(initialBalance, portfolioInitialBalance => {
                _.forEach(portfolioInitialBalance, (value, coin) => {
                    if (coin !== 'acct_name' && coin !== 'timestamp' && Number(value) > 0) {
                        coins.push(_.upperCase(coin))
                    }
                })
            })
        }
        return _.uniq(coins)
    }

    _sortExposureGroups ({ exposureGroups={}, groupBy=TABLE_GROUP_BYS.COIN }) {
        const { accountSortBy, coinSortBy } = this.state
        const { pricings, exposureItemsToCompare, initialBalanceToCompare, pricingsToCompare } = this.props

        let sortedExposureGroups = null, exposureGroupsToCompare = null
        if (groupBy === TABLE_GROUP_BYS.COIN) {
            if (coinSortBy === this.coinSortBys.NET_EXPOSURE_DIFF_IN_USDT) {
                exposureGroupsToCompare = this.getExposureGroups({
                    exposureItems: exposureItemsToCompare,
                    initialBalance: initialBalanceToCompare,
                    pricings: pricingsToCompare,
                    groupBy,
                    shouldSort: false
                })
            }

            _.forEach(exposureGroups, coinExposure => {
                coinExposure.accounts = _.sortBy(coinExposure.accounts, accountExposure => {
                    if (accountSortBy === this.accountSortBys.SUM_EXPOSURE) {
                        return -Math.abs(accountExposure.exposureSum)
                    } else if (accountSortBy === this.accountSortBys.SUM_EXPOSURE_IN_USDT) {
                        return -Math.abs(accountExposure.exposureSumInUSDT)
                    } else {
                        return accountExposure.accountName
                    }
                })
            })
            sortedExposureGroups = _.sortBy(exposureGroups, coinExposure => {
                if (coinSortBy === this.coinSortBys.SUM_EXPOSURE) {
                    return -Math.abs(coinExposure.exposureSum)
                } else if (coinSortBy === this.coinSortBys.SUM_EXPOSURE_IN_USDT) {
                    return -Math.abs(coinExposure.exposureSumInUSDT)
                } else if (coinSortBy === this.coinSortBys.INITIAL_BALANCE) {
                    return -Math.abs(coinExposure.initialExposure)
                } else if (coinSortBy === this.coinSortBys.NET_EXPOSURE_IN_ASSET) {
                    return -Math.abs(coinExposure.netExposure)
                } else if (coinSortBy === this.coinSortBys.NET_EXPOSURE_IN_USDT) {
                    return -Math.abs(coinExposure.netExposureInUSDT)
                } else if (coinSortBy === this.coinSortBys.NET_EXPOSURE_DIFF_IN_USDT) {
                    const netExposureToCompare = _.has(exposureGroupsToCompare, `${coinExposure.coin}.netExposure`) ? Number(exposureGroupsToCompare[coinExposure.coin].netExposure) : 0
                    const coinUSDTPrice = this.getUSDTPriceByCoin(coinExposure.coin, pricings)
                    return -Math.abs((coinExposure.netExposure - netExposureToCompare) * Number(coinUSDTPrice)) 
                } else if (coinSortBy === this.coinSortBys.POSITION_NOTIONAL_SUM) {
                    return -Math.abs(coinExposure.positionNotionalSum)
                } else {
                    return coinExposure.coin
                }
            })
        } else if (groupBy === TABLE_GROUP_BYS.ACCOUNT) {
            if (accountSortBy === this.accountSortBys.SUM_EXPOSURE_DIFF_IN_USDT) {
                exposureGroupsToCompare = this.getExposureGroups({
                    exposureItems: exposureItemsToCompare,
                    initialBalance: initialBalanceToCompare,
                    pricings: pricingsToCompare,
                    groupBy,
                    shouldSort: false
                })
            }
            _.forEach(exposureGroups, accountExposure => {
                accountExposure.coins = _.sortBy(accountExposure.coins, coinExposure => {
                    if (coinSortBy === this.coinSortBys.SUM_EXPOSURE) {
                        return -Math.abs(coinExposure.exposureSum)
                    } else if (coinSortBy === this.coinSortBys.SUM_EXPOSURE_IN_USDT) {
                        return -Math.abs(coinExposure.exposureSumInUSDT)
                    } else {
                        return coinExposure.coin
                    }
                })
            })
            sortedExposureGroups = _.sortBy(exposureGroups, accountExposure => {
                if (accountSortBy === this.accountSortBys.SUM_EXPOSURE_IN_USDT) {
                    return -Math.abs(accountExposure.exposureSumInUSDT)
                } else if (accountSortBy === this.accountSortBys.SUM_EXPOSURE_DIFF_IN_USDT) {
                    const exposureSumInUSDTToCompare = _.has(exposureGroupsToCompare, `${accountExposure.accountName}.exposureSumInUSDT`) ? Number(exposureGroupsToCompare[accountExposure.accountName].exposureSumInUSDT) : 0
                    return -Math.abs(accountExposure.exposureSumInUSDT - exposureSumInUSDTToCompare)
                } else {
                    return accountExposure.accountName
                }
            })
        }

        return _.isArray(sortedExposureGroups) ? sortedExposureGroups : Object.values(exposureGroups)
    }

    getExposureGroups ({ exposureItems=[], initialBalance={}, pricings={}, groupBy=TABLE_GROUP_BYS.COIN, shouldSort=true}) {
        const { itemSortBy } = this.state
        const { portfolioName, mode, accountItems, symbolItems, exposureVariables } = this.props
        const getSortedItems = (exposureItems) => {
            return _.sortBy(exposureItems, item => {
                if (itemSortBy === this.itemSortBys.EXPOSURE) {
                    return -Math.abs(item.exposure)
                } else if (itemSortBy === this.itemSortBys.TIME) {
                    return moment(item.timestamp).valueOf()
                } else if (itemSortBy === this.itemSortBys.SOURCE) {
                    return item.source
                } else {
                    return null
                }
            })
        }

        const BTCUSDIndexLastPrice = _.has(pricings, `btc_usd_OKEX_INDEX.last`) ? Number(pricings['btc_usd_OKEX_INDEX'].last) : null


        let exposureGroups = {}
        exposureItems = mode === MODES.NAV ? _.filter(exposureItems, item => item.source !== 'POSITION') : exposureItems
        exposureItems = _.filter(exposureItems, item => {
            return _.isEmpty(portfolioName) || portfolioName === ALL 
                || item.portfolioName === portfolioName
                || (_.has(accountItems, item.accountName) && accountItems[item.accountName].portfolio_name === portfolioName)
        }).map(item => {
            const { coin, exposure, accountName, detail } = item
            const coinUSDTPrice = this.getUSDTPriceByCoin(coin, pricings)

            if (mode === MODES.NAV && _.has(accountItems, accountName)) {
                let newItem
                if (['DERIBIT'].includes(accountItems[accountName].exchange_name)
                    || (['BNBFUTA'].includes(accountItems[accountName].exchange_name) && item.source === 'FUTURE_BALANCE')) {
                    newItem = item
                } else {
                    newItem = Object.assign({}, item, {
                        exposure: exposure + (_.has(detail, 'unrealizedPnl') ? detail.unrealizedPnl : 0)
                    })
                }
                return {
                    ...newItem,
                    exposureInUSDT: coinUSDTPrice ? newItem.exposure * coinUSDTPrice : null
                }
            } else {
                return {
                    ...item,
                    exposureInUSDT: coinUSDTPrice ? exposure * coinUSDTPrice : null
                }
            }
        })

        if (groupBy === TABLE_GROUP_BYS.COIN) {
            exposureGroups = _.groupBy(exposureItems, 'coin')
            const initialBalanceCoins = this._getInitialBalanceCoins(initialBalance)
            _.forEach(initialBalanceCoins, coin => {
                if (!_.has(exposureGroups, coin)) {
                    exposureGroups[coin] = []
                }
            })

            _.forEach(exposureGroups, (exposureItemsInSameCoin, coin) => {
                const coinUSDTPrice = this.getUSDTPriceByCoin(coin, pricings)
                const exposureSum = _.sumBy(exposureItemsInSameCoin, 'exposure')
                const exposureSumInUSDT = _.sumBy(exposureItemsInSameCoin, 'exposureInUSDT')
                let initialExposure = null
                if (!_.isEmpty(portfolioName) && portfolioName !== ALL) {
                    initialExposure = _.has(initialBalance, `initial_balance_${portfolioName}.${_.lowerCase(coin)}`)
                        ? initialBalance[`initial_balance_${portfolioName}`][_.lowerCase(coin)]
                        : null
                } else {
                    _.forEach(initialBalance, portfolioInitialBalance => {
                        if (_.has(portfolioInitialBalance, _.lowerCase(coin))) {
                            initialExposure = (initialExposure || 0) + Number(portfolioInitialBalance[_.lowerCase(coin)])
                        }
                    })
                }
                const netExposure = exposureSum - (initialExposure || 0)

                const positionNotionalSum = _.sumBy(exposureItemsInSameCoin, exposureItem => {
                    const { source, productName, detail } = exposureItem
                    const { base, quote } = getSymbolAttributeByName(productName || '')

                    let result = 0
                    if (source === 'POSITION' && base === coin) {
                        const notionalValue = getNotional({
                            symbolItem: symbolItems[productName],
                            quantity: Number(detail.longPosition) - Number(detail.shortPosition),
                            price: pricings[productName],
                            BTCUSDIndexLastPrice
                        })
                        result = quote === 'USD' 
                            ? notionalValue 
                            : notionalValue * (quote === 'USDT' ? pricings['usdt_usd_FTX_SPT'] : quote === 'BTC' ? pricings['btc_usd_OKEX_INDEX'] : 0)
                    }

                    return result
                })

                exposureGroups[coin] = {
                    coin,
                    accounts: _.groupBy(exposureItemsInSameCoin, 'accountName'),
                    exposureSum,
                    exposureSumInUSDT,
                    initialExposure,
                    netExposure,
                    netExposureInUSDT: coinUSDTPrice ? netExposure * coinUSDTPrice : null,
                    positionNotionalSum,
                    threshold: Math.max(Math.abs(positionNotionalSum * Number(exposureVariables.positionRatio)), 
                        _.has(exposureVariables.coinThresholdMinValues, coin) ? Number(exposureVariables.coinThresholdMinValues[coin]) : DEFAULT_COIN_THRESHOLD_MIN_VALUE)
                }
                _.forEach(exposureGroups[coin].accounts, (exposureItemsInSameAccount, accountName) => {
                    const exposureSum = _.sumBy(exposureItemsInSameAccount, 'exposure')
                    const exposureSumInUSDT = _.sumBy(exposureItemsInSameAccount, 'exposureInUSDT')
                    exposureGroups[coin].accounts[accountName] = {
                        accountName,
                        items: shouldSort ? getSortedItems(exposureItemsInSameAccount) : exposureItemsInSameAccount,
                        exposureSum,
                        exposureSumInUSDT
                    }
                })
            })
        } else if (groupBy === TABLE_GROUP_BYS.ACCOUNT) {
            exposureGroups = _.groupBy(exposureItems, 'accountName')
            _.forEach(exposureGroups, (exposureItemsInSameAccount, accountName) => {
                const exposureSumInUSDT = _.sumBy(exposureItemsInSameAccount, 'exposureInUSDT')
                exposureGroups[accountName] = {
                    accountName,
                    coins: _.groupBy(exposureItemsInSameAccount, 'coin'),
                    exposureSumInUSDT
                }
                _.forEach(exposureGroups[accountName].coins, (exposureItemsInSameCoin, coin) => {
                    const exposureSum = _.sumBy(exposureItemsInSameCoin, 'exposure')
                    const exposureSumInUSDT = _.sumBy(exposureItemsInSameCoin, 'exposureInUSDT')
                    exposureGroups[accountName].coins[coin] = {
                        coin,
                        items: shouldSort ? getSortedItems(exposureItemsInSameCoin) : exposureItemsInSameCoin,
                        exposureSum,
                        exposureSumInUSDT
                    }
                })
            })
        }
        return shouldSort ? this._sortExposureGroups({ exposureGroups, groupBy }) : exposureGroups
    }

    handleClickSaveButton () {
        const { dispatch } = this.props
        dispatch(updateExposureVariables(this.editingVariables))
        .then(() => { this.setState({ canEditExposureThresholdParams: false }) })
    }

    FileDownload ({ filename }) {
        const { exposureItems, pricings, initialBalance, portfolioName, accountItems } = this.props
        const filteredExposureItems = _.filter(exposureItems, item => {
            return _.isEmpty(portfolioName) || portfolioName === ALL
                || item.portfolioName === portfolioName
                || (_.has(accountItems, item.accountName) && accountItems[item.accountName].portfolio_name === portfolioName)
        })
        const flatExposureItems = _.map(filteredExposureItems, (item, index) => {
            const  { coin, detail, accountName, portfolioName: itemPortfolioName } = item
            let initialBalanceString = ''
            const detailString = _.reduce(detail, (result, value, key) => {
                if (!_.isNil(value)) {
                    result += (`${_.isEmpty(result) ? '' : ', '}${key}: ${value}`)
                }
                return result
            }, '')
            if (index === 0) {
                let coinInitialBalance = {}
                if (!_.isEmpty(portfolioName) && portfolioName !== ALL) {
                    coinInitialBalance = initialBalance[`initial_balance_${portfolioName}`] || {}
                } else {
                    _.forEach(initialBalance, portfolioInitialBalance => {
                        _.forEach(portfolioInitialBalance, (value, coin) => {
                            if (coin !== 'acct_name' && coin !== 'timestamp') {
                                coinInitialBalance[coin] = (coinInitialBalance[coin] || 0) + value
                            }
                        })
                    })
                }
                initialBalanceString = _.reduce(coinInitialBalance, (result, value, key) => {
                    if (!_.isNil(value)) {
                        result += (`${_.isEmpty(result) ? '' : ', '}${key}: ${value}`)
                    }
                    return result
                }, '')
            }
            return Object.assign({}, item, { 
                portfolioName: itemPortfolioName || (_.has(accountItems, accountName) ? accountItems[accountName].portfolio_name : 'UNKOWN'),
                detailString, 
                initialBalanceString, 
                coinPrice: this.getUSDTPriceByCoin(coin, pricings) 
            })
        })

        const headers = [
            { label: 'Account', key: 'accountName' },
            { label: 'Portfolio', key: 'portfolioName' },
            { label: 'Coin', key: 'coin' },
            { label: 'Exposure', key: 'exposure' },
            { label: 'Coin Price (USDT)', key: 'coinPrice' },
            { label: 'Time', key: 'timestamp' },
            { label: 'Source', key: 'source' },
            { label: 'Symbol', key: 'productName' },
            { label: 'detail', key: 'detailString' },
            { label: '', key: 'NULL' },
            { label: 'Initial Balance', key: 'initialBalanceString' }
        ]

        return (
            <div className='exposure-table--file-download'>
                <CSVLink 
                    filename={filename}
                    data={flatExposureItems} 
                    headers={headers}>
                    <button>{'Downlaod CSV'}</button>
                </CSVLink>
            </div>
        )
    }

    ExposureTableData ({ value, diff=0, defaultPrecision=2, highlightThreshold=null }) {
        return (
            <td className={'exposure-table--exposure-data' + (value > 0 ? ' positive' : value < 0 ? ' negative' : '') + (_.isNumber(highlightThreshold) && Math.abs(value) >= highlightThreshold ? ' highlight' : '')}>
                {_.isNumber(value) ? toNumberWithSmartPrecision({ number: value, shouldReturnLocalString: true, defaultPrecision }) : 'N/A'}
                {Number(diff) !== 0 && <span className={'exposure-table--exposure-data--diff' + (diff > 0 ? ' positive' : ' negative')}>
                    {`(${diff > 0 ? '+' : ''}${toNumberWithSmartPrecision({ number: diff, shouldReturnLocalString: true, defaultPrecision })})`}
                </span>}
            </td>
        )
    }

    TableGroupByAccount () {
        const { exposureItems, exposureItemsToCompare, initialBalance, initialBalanceToCompare, 
            pricings, pricingsToCompare, comparisonRules, uniqueExposureBackgroundColor, mode, csvFileName } = this.props
        const { expandedPaths, accountSortBy, coinSortBy, itemSortBy } = this.state
        const isLevel1Expanded = this.isLevel1Expanded()
        const isLevel2Expanded = this.isLevel2Expanded()
        const exposureGroups = this.getExposureGroups({
            exposureItems,
            initialBalance,
            pricings,
            groupBy: TABLE_GROUP_BYS.ACCOUNT,
            shouldSort: true
        })
        const exposureGroupsToCompare = this.getExposureGroups({
            exposureItems: exposureItemsToCompare,
            initialBalance: initialBalanceToCompare,
            pricings: pricingsToCompare,
            groupBy: TABLE_GROUP_BYS.ACCOUNT,
            shouldSort: false
        })
        const accountNamesToCompare = Object.keys(exposureGroupsToCompare)

        let totalNAV = 0, totalPnL = 0
        if (mode === MODES.NAV) {
            const exposureGroupsByCoin = this.getExposureGroups({
                exposureItems,
                initialBalance,
                pricings,
                groupBy: TABLE_GROUP_BYS.COIN,
                shouldSort: false
            })
            _.forEach(exposureGroupsByCoin, coinExposure => {
                const { exposureSumInUSDT, netExposureInUSDT } = coinExposure
                totalNAV += (exposureSumInUSDT || 0)
                totalPnL += (netExposureInUSDT || 0)
            })
        }
        return (
            <table className='exposure-table group-by-account'>
                <thead className='exposure-table--head'>
                    {mode === MODES.NAV && <tr><td className='exposure-table--head--nav-stats' colSpan={'99'}>
                        <span>{`NAV: USDT ${toNumberWithSmartPrecision({ number: totalNAV, shouldReturnLocalString: true, defaultPrecision: 0 })}`}</span>
                        <span>{`Total PnL: USDT ${toNumberWithSmartPrecision({ number: totalPnL, shouldReturnLocalString: true, defaultPrecision: 0 })}`}</span>
                    </td></tr>}
                    <tr className={'exposure-table--head--row' + (mode === MODES.NAV ? ' has-stats' : '')}>
                        <th className={'exposure-table--head-title level-0 index'}>
                            {'#'}
                            {!_.isEmpty(exposureItems) && <Popup on={'click'}
                                className='exposure-table--csv-download-popup'
                                trigger={<button className='exposure-table--csv-download-popup--trigger'><IoMdDownload /></button>}>
                                {this.FileDownload({ 
                                    filename: csvFileName || `EXPOSURE_SNAPSHOT_${moment().format('YYYY-MM-DD-HH_mm_ss')}.csv`,
                                })}
                            </Popup>}
                        </th>
                        <th className={'exposure-table--head-title level-0 account sortable' + (accountSortBy === this.accountSortBys.NAME ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(this.accountSortBys.NAME) }}>{'Account'}</th>
                        {isLevel1Expanded && <th className={'exposure-table--head-title coin level-1 sortable' + (coinSortBy === this.coinSortBys.NAME ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(this.coinSortBys.NAME) }}>{'Coin'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 source sortable' + (itemSortBy === this.itemSortBys.SOURCE ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(this.itemSortBys.SOURCE) }}>{'Source'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 timestamp sortable' + (itemSortBy === this.itemSortBys.TIME ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(this.itemSortBys.TIME) }}>{'Time'}</th>}
                        {isLevel2Expanded && <th className='exposure-table--head-title level-2 detail'>{'Detail'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 source-exposure sortable' + (itemSortBy === this.itemSortBys.EXPOSURE ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(this.itemSortBys.EXPOSURE) }}>{mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}</th>}
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 coin-exposure sortable' + ((coinSortBy === this.coinSortBys.SUM_EXPOSURE ? ' sorted' : ''))}
                            onClick={() => { this.updateCoinSortBy(this.coinSortBys.SUM_EXPOSURE) }}>{`Coin ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}`}</th>}
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 coin-price'}>{`Price (USDT)`}</th>}
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 coin-exposure-in-usdt sortable' + (coinSortBy === this.coinSortBys.SUM_EXPOSURE_IN_USDT ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(this.coinSortBys.SUM_EXPOSURE_IN_USDT) }}>{`Coin ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'} (USDT)`}</th>}
                        <th className={'exposure-table--head-title account-exposure level-0 sortable' + (accountSortBy === this.accountSortBys.SUM_EXPOSURE_IN_USDT ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(this.accountSortBys.SUM_EXPOSURE_IN_USDT) }}>{`Acct. ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'} (USDT)`}</th>
                        {comparisonRules.includes('EXPOSURE_DIFF') && 
                        <th className={'exposure-table--head-title account-exposure-diff level-0 sortable' + (accountSortBy === this.accountSortBys.SUM_EXPOSURE_DIFF_IN_USDT ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(this.accountSortBys.SUM_EXPOSURE_DIFF_IN_USDT) }}>
                            {`Acct. ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'} Diff (USDT)`}
                        </th>}
                    </tr>
                </thead> 
                {exposureGroups.map((exposureGroup, index) => {
                    const { accountName } = exposureGroup
                    const isAccountExpanded = expandedPaths.includes(accountName)
                    const accountExposureToCompare = _.has(exposureGroupsToCompare, accountName) ? exposureGroupsToCompare[accountName] : null
                    const accountExposureSumInUSDTDiff = comparisonRules.includes('EXPOSURE_DIFF') && accountExposureToCompare ? (exposureGroup.exposureSumInUSDT - accountExposureToCompare.exposureSumInUSDT) : null
                    const shouldShowAsUniqueExposureAccount = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && !accountNamesToCompare.includes(accountName)
                    const coinsToCompare = accountExposureToCompare ? Object.keys(accountExposureToCompare.coins) : []

                    return (
                        <tbody className={`exposure-table--body ${accountName}`} key={accountName}>
                            <tr className={'exposure-table--level-1-row' + (isAccountExpanded ? ' expanded' : '') + (shouldShowAsUniqueExposureAccount ? ' unique-account' : '')}
                                style={{ backgroundColor: shouldShowAsUniqueExposureAccount ? uniqueExposureBackgroundColor : null }}
                                onClick={() => {
                                    this.setState({ expandedPaths: isAccountExpanded ? _.without(expandedPaths, accountName) : expandedPaths.concat([accountName]) })
                                }}>
                                <td>{index + 1}</td>
                                <td>{accountName}</td>
                                {isLevel1Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                {isLevel2Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                {this.ExposureTableData({ value: exposureGroup.exposureSumInUSDT, defaultPrecision: 0 })}
                                {comparisonRules.includes('EXPOSURE_DIFF') && this.ExposureTableData({ value: accountExposureSumInUSDTDiff, defaultPrecision: 0 })}
                            </tr>
                            {isAccountExpanded && exposureGroup.coins.map(coinExposure => {
                                const { coin } = coinExposure
                                const isCoinExpanded = expandedPaths.includes(`${accountName}.${coin}`)
                                const coinExposureToCompare = accountExposureToCompare ? accountExposureToCompare.coins[coin] : null
                                const coinExposureSumDiff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (coinExposure.exposureSum - coinExposureToCompare.exposureSum) : null
                                const coinExposureSumInUSDTDIff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (coinExposure.exposureSumInUSDT - coinExposureToCompare.exposureSumInUSDT) : null
                                const shouldShowAsUniqueExposureCoin = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && !coinsToCompare.includes(coin)
                                const coinUSDTPrice = this.getUSDTPriceByCoin(coin, pricings)
                                return (
                                    <Fragment key={coin}>
                                        <tr className={'exposure-table--level-2-row' + (isCoinExpanded ? ' expanded' : '') + (shouldShowAsUniqueExposureCoin ? ' unique-coin' : '')} 
                                            style={{ backgroundColor: shouldShowAsUniqueExposureCoin ? uniqueExposureBackgroundColor : null }}
                                            onClick={() => {
                                                const path = `${accountName}.${coin}`
                                                this.setState({ expandedPaths: isCoinExpanded ? _.without(expandedPaths, path) : expandedPaths.concat([path]) })
                                            }}>
                                            <Fragment><td /><td /></Fragment>
                                            <td className='exposure-table--coin'>{coin}<span className='exposure-table--level-2-row--count'>{`(${coinExposure.items.length})`}</span></td>
                                            {isLevel2Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                            {this.ExposureTableData({ value: coinExposure.exposureSum, diff: coinExposureSumDiff })}
                                            <td className='right-align'>{coinUSDTPrice ? toNumberWithSmartPrecision({ number: coinUSDTPrice, defaultPrecision: 5 }) : 'N/A'}</td>
                                            {this.ExposureTableData({ value: coinExposure.exposureSumInUSDT, diff: coinExposureSumInUSDTDIff, defaultPrecision: 0 })}
                                        </tr>
                                        {isCoinExpanded && coinExposure.items.map((exposureItem, index) => {
                                            const { source, exposure, pair, productName, timestamp } = exposureItem
                                            const exposureItemToCompare = coinExposureToCompare ? _.find(coinExposureToCompare.items, itemToCompare => {
                                                return itemToCompare.source === source 
                                                    && (_.isNil(pair) || itemToCompare.pair === pair)
                                                    && (_.isNil(productName) || itemToCompare.productName === productName)
                                            }) : null
                                            const exposureItemDiff = comparisonRules.includes('EXPOSURE_DIFF') && exposureItemToCompare ? (exposure - exposureItemToCompare.exposure) : null
                                            const shouldShowAsUniqueExposureItem = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && _.isNil(exposureItemToCompare)
                                            return (
                                                <tr className={'exposure-table--exposure-item-row' 
                                                + (index % 2 === 0 ? ' even-row' : ' odd-orw')
                                                + (index === 0 ? ' first-item-row' : '')
                                                + (index === coinExposure.items.length - 1 ? ' last-item-row' : '')
                                                + (shouldShowAsUniqueExposureItem ? ' unique-item' : '')} key={index}
                                                style={{ backgroundColor: shouldShowAsUniqueExposureItem ? uniqueExposureBackgroundColor : null }}>
                                                    <Fragment><td /><td /><td /></Fragment>
                                                    <td className='exposure-table--exposure-item-source'>
                                                        <span className={`exposure-table--exposure-item-source-type ${source}`}>{_.isString(source) ? source.replace(/_/g, ' ') : 'Unkown'}</span>
                                                        {pair || productName || ''}
                                                    </td>
                                                    <td>{moment(timestamp).format('HH:mm:ss')}</td>
                                                    <td className='exposure-table--exposure-item-detail'>
                                                        {_.map(exposureItem.detail, (value, key) => {
                                                            const name = Object.values(key).reduce((result, c) => {
                                                                result += (c === c.toUpperCase() ? ` ${c.toLowerCase()}` : c)
                                                                return result
                                                            }, '')
                                                            return value ? (
                                                                <div className='exposure-table--exposure-item-detail--block' key={key}>
                                                                    <span className='exposure-table--exposure-item-detail--name'>{name}</span>
                                                                    <span className='exposure-table--exposure-item-detail--value'>
                                                                        {_.isNumber(value) ? toNumberWithSmartPrecision({ number: value, shouldReturnLocalString: true }) : value}
                                                                    </span>
                                                                </div>
                                                            ) : null
                                                        })}
                                                    </td>
                                                    {this.ExposureTableData({ value: exposure, diff: exposureItemDiff })}
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    )
                })}
            </table>
        )
    }

    TableGroupByCoin () {
        const { exposureItems, exposureItemsToCompare, initialBalance, initialBalanceToCompare, 
            pricings, pricingsToCompare, comparisonRules, uniqueExposureBackgroundColor, mode, exposureVariables, csvFileName } = this.props
        const { expandedPaths, coinSortBy, accountSortBy, itemSortBy, canEditExposureThresholdParams } = this.state
        const isLevel1Expanded = this.isLevel1Expanded()
        const isLevel2Expanded = this.isLevel2Expanded()
        const exposureGroups = this.getExposureGroups({
            exposureItems,
            initialBalance,
            pricings,
            groupBy: TABLE_GROUP_BYS.COIN,
            shouldSort: true
        })
        const exposureGroupsToCompare = this.getExposureGroups({
            exposureItems: exposureItemsToCompare,
            initialBalance: initialBalanceToCompare,
            pricings: pricingsToCompare,
            groupBy: TABLE_GROUP_BYS.COIN,
            shouldSort: false
        })
        const coinsToCompare = Object.keys(exposureGroupsToCompare)
        let totalNAV = 0, totalPnL = 0
        if (mode === MODES.NAV) {
            _.forEach(exposureGroups, coinExposure => {
                const { exposureSumInUSDT, netExposureInUSDT } = coinExposure
                totalNAV += (exposureSumInUSDT || 0)
                totalPnL += (netExposureInUSDT || 0)
            })
        }

        return (
            <table className='exposure-table group-by-coin'>
                <thead className='exposure-table--head'>
                    {mode === MODES.NAV && <tr><td className='exposure-table--head--nav-stats' colSpan={'99'}>
                        <span>{`NAV: USDT ${toNumberWithSmartPrecision({ number: totalNAV, shouldReturnLocalString: true, defaultPrecision: 0 })}`}</span>
                        <span>{`Total PnL: USDT ${toNumberWithSmartPrecision({ number: totalPnL, shouldReturnLocalString: true, defaultPrecision: 0 })}`}</span>
                    </td></tr>}
                    <tr className={'exposure-table--head--row' + (mode === MODES.NAV ? ' has-stats' : '')}>
                        <th className={'exposure-table--head-title level-0 index'}>
                            {'#'}
                            {!_.isEmpty(exposureItems) && <Popup on={'click'}
                                className='exposure-table--csv-download-popup'
                                trigger={<button className='exposure-table--csv-download-popup--trigger'><IoMdDownload /></button>}>
                                {this.FileDownload({ 
                                    filename: csvFileName || `EXPOSURE_SNAPSHOT_${moment().format('YYYY-MM-DD-HH_mm_ss')}.csv`,
                                })}
                            </Popup>}
                        </th>
                        <th className={'exposure-table--head-title level-0 sortable' + (coinSortBy === this.coinSortBys.NAME ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(this.coinSortBys.NAME) }}>{'Coin'}</th>
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 account sortable' + (accountSortBy === this.accountSortBys.NAME ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(this.accountSortBys.NAME) }}>{'Account'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 source sortable' + (itemSortBy === this.itemSortBys.SOURCE ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(this.itemSortBys.SOURCE) }}>{'Source'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 timestamp sortable' + (itemSortBy === this.itemSortBys.TIME ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(this.itemSortBys.TIME) }}>{'Time'}</th>}
                        {isLevel2Expanded && <th className='exposure-table--head-title level-2 detail'>{'Detail'}</th>}
                        {isLevel2Expanded && <th className={'exposure-table--head-title level-2 source-exposure sortable' + (itemSortBy === this.itemSortBys.EXPOSURE ? ' sorted' : '')}
                            onClick={() => { this.updateItemSortBy(this.itemSortBys.EXPOSURE) }}>{mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}</th>}
                        {isLevel1Expanded && <th className={'exposure-table--head-title level-1 account-exposure sortable' + (accountSortBy === this.accountSortBys.SUM_EXPOSURE ? ' sorted' : '')}
                            onClick={() => { this.updateAccountSortBy(this.accountSortBys.SUM_EXPOSURE) }}>{`Acct. ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}`}</th>}
                        <th className={'exposure-table--head-title coin-exposure level-0 sortable' + (coinSortBy === this.coinSortBys.SUM_EXPOSURE ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(this.coinSortBys.SUM_EXPOSURE) }}>{`Coin ${mode === MODES.EXPOSURE ? 'Expo.' : 'NAV'}`}</th>
                        <th className={'exposure-table--head-title initial-balance level-0 sortable' + (coinSortBy === this.coinSortBys.INITIAL_BALANCE ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(this.coinSortBys.INITIAL_BALANCE) }}>{'Initial Bal.'}</th>
                        <th className={'exposure-table--head-title net-exposure level-0 sortable' + (coinSortBy === this.coinSortBys.NET_EXPOSURE_IN_ASSET ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(this.coinSortBys.NET_EXPOSURE_IN_ASSET) }}>{mode === MODES.EXPOSURE ? 'Net Expo.' : 'PnL'}</th>
                        <th className={'exposure-table--head-title coin-price level-0'}>{'Price (USDT)'}</th>
                        <th className={'exposure-table--head-title net-exposure-in-usdt level-0 sortable' + (coinSortBy === this.coinSortBys.NET_EXPOSURE_IN_USDT ? ' sorted' : '')}
                            onClick={() => { this.updateCoinSortBy(this.coinSortBys.NET_EXPOSURE_IN_USDT) }}>{`${mode === MODES.EXPOSURE ? 'Net Expo.' : 'PnL'} (USDT)`}</th>
                        {comparisonRules.includes('EXPOSURE_DIFF') && 
                        <Fragment>
                            <th className={'exposure-table--head-title coin-exposure-diff-in-usdt level-0 sortable' + (coinSortBy === this.coinSortBys.NET_EXPOSURE_DIFF_IN_USDT ? ' sorted' : '')}
                                onClick={() => { this.updateCoinSortBy(this.coinSortBys.NET_EXPOSURE_DIFF_IN_USDT) }}>
                                {`${mode === MODES.EXPOSURE ? 'Net Expo.' : 'PnL'} Diff (USDT)`}
                            </th>
                            {mode === MODES.EXPOSURE && <Fragment>
                                <th className={'exposure-table--head-title position-notional-sum level-0 sortable' + (coinSortBy === this.coinSortBys.POSITION_NOTIONAL_SUM ? ' sorted' : '')}
                                onClick={() => { this.updateCoinSortBy(this.coinSortBys.POSITION_NOTIONAL_SUM) }}>{'POS Notional(USD)'}</th>
                                <th className={'exposure-table--head-title threshold level-0'}>{'THR.'}
                                    {!canEditExposureThresholdParams && <button className='edit' 
                                        onClick={() => { 
                                            this.editingVariables = _.cloneDeep(exposureVariables)
                                            this.setState({ canEditExposureThresholdParams: true }) }}>
                                        {'EDIT'}
                                    </button>}
                                </th>
                                {canEditExposureThresholdParams && 
                                <th className='exposure-table--head-title exposure-variables level-0'>
                                    <div className='exposure-table--variables--position-threshold'>
                                        <input type={'number'}
                                            min={0}
                                            max={100}
                                            placeholder={'Position Ratio'}
                                            defaultValue={_.isNumber(this.editingVariables.positionRatio) ? Math.round(this.editingVariables.positionRatio * 10000) / 100 : this.editingVariables.positionRatio} 
                                            onChange={(e) => { 
                                                const newValue = e.target.value.trim()
                                                this.editingVariables.positionRatio = newValue.length > 0 ? Math.round(newValue * 100) / 10000 : 0.01
                                            }} />
                                        <span>{'%'}</span>
                                    </div>
                                    <button className='save' onClick={() => { this.handleClickSaveButton() }}>{'SAVE'}</button>
                                    <button className='reset' onClick={() => { this.setState({ canEditExposureThresholdParams: false }) }}>{'RESET'}</button>
                                </th>}
                            </Fragment>}
                        </Fragment>}
                    </tr>
                </thead>
                {exposureGroups.map((exposureGroup, index) => {
                    const { coin, positionNotionalSum, threshold } = exposureGroup
                    const isCoinExpanded = expandedPaths.includes(coin)
                    const coinExposureToCompare = _.has(exposureGroupsToCompare, coin) ? exposureGroupsToCompare[coin] : null
                    const coinUSDTPrice = this.getUSDTPriceByCoin(coin, pricings)
                    const coinExposureSumDiff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (exposureGroup.exposureSum - coinExposureToCompare.exposureSum) : null
                    const coinNetExposureDiff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (exposureGroup.netExposure - coinExposureToCompare.netExposure) : null
                    const coinInitialExposureDiff = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare ? (Number(exposureGroup.initialExposure) - Number(coinExposureToCompare.initialExposure)) : null
                    const coinNetExposureDiffInUSDT = comparisonRules.includes('EXPOSURE_DIFF') && coinExposureToCompare && coinUSDTPrice ? coinNetExposureDiff * coinUSDTPrice : null
                    const shouldShowAsUniqueExposureCoin = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && !coinsToCompare.includes(coin)
                    const accountNamesToCompare = coinExposureToCompare ? Object.keys(coinExposureToCompare.accounts) : []

                    return (
                        <tbody className={`exposure-table--body ${coin}`} key={coin}>
                            <tr className={'exposure-table--level-1-row' + (isCoinExpanded ? ' expanded' : '') + (shouldShowAsUniqueExposureCoin ? ' unique-coin' : '')} 
                                style={{ backgroundColor: shouldShowAsUniqueExposureCoin ? uniqueExposureBackgroundColor : null }}
                                onClick={() => {
                                    this.setState({ expandedPaths: isCoinExpanded ? _.without(expandedPaths, coin) : expandedPaths.concat([coin]) })
                                }}>
                                <td>{index + 1}</td>
                                <td>{coin}</td>
                                {isLevel1Expanded && <Fragment><td /><td /></Fragment>}
                                {isLevel2Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                {this.ExposureTableData({ value: exposureGroup.exposureSum, diff: coinExposureSumDiff })}
                                {this.ExposureTableData({ value: exposureGroup.initialExposure, diff: coinInitialExposureDiff })}
                                {this.ExposureTableData({ value: exposureGroup.netExposure, diff: coinNetExposureDiff })}
                                <td className='right-align'>{coinUSDTPrice ? toNumberWithSmartPrecision({ number: coinUSDTPrice, defaultPrecision: 5 }) : 'N/A'}</td>
                                {this.ExposureTableData({ value: exposureGroup.netExposureInUSDT || null, defaultPrecision: 0 })}
                                {comparisonRules.includes('EXPOSURE_DIFF') && <Fragment>
                                    {this.ExposureTableData({ value: coinNetExposureDiffInUSDT, defaultPrecision: 0, highlightThreshold: mode === MODES.EXPOSURE ? threshold : null })}
                                    {mode === MODES.EXPOSURE && <Fragment>
                                        {this.ExposureTableData({ value: positionNotionalSum, defaultPrecision: 0 })}
                                        <td>{toNumberWithSmartPrecision({ number: threshold, defaultPrecision: 0, shouldReturnLocalString: true })}</td>
                                        {canEditExposureThresholdParams && 
                                        <td className='exposure-table--threshold-min-value-input' onClick={(e) => { e.stopPropagation() }}>
                                            <input type={'number'}
                                                min={0}
                                                placeholder={'THR. Min Value'}
                                                defaultValue={_.has(this.editingVariables.coinThresholdMinValues, coin) ? this.editingVariables.coinThresholdMinValues[coin] : DEFAULT_COIN_THRESHOLD_MIN_VALUE} 
                                                onChange={(e) => { 
                                                    const newValue = e.target.value.trim()
                                                    this.editingVariables.coinThresholdMinValues[coin] = newValue.length > 0 ? Number(newValue) : DEFAULT_COIN_THRESHOLD_MIN_VALUE
                                                }} /> 
                                        </td>}
                                    </Fragment>}
                                </Fragment>}
                            </tr>
                            {isCoinExpanded && exposureGroup.accounts.map(accountExposure => {
                                const { accountName } = accountExposure
                                const isAccountExpanded = expandedPaths.includes(`${coin}.${accountName}`)
                                const accountExposureToCompare = coinExposureToCompare ? coinExposureToCompare.accounts[accountName] : null
                                const accountExposureSumDiff = comparisonRules.includes('EXPOSURE_DIFF') && accountExposureToCompare ? (accountExposure.exposureSum - accountExposureToCompare.exposureSum) : null
                                const shouldShowAsUniqueExposureAccount = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && !accountNamesToCompare.includes(accountName)
                                return (
                                    <Fragment key={accountName}>
                                        <tr className={'exposure-table--level-2-row' + (isAccountExpanded ? ' expanded' : '') + (shouldShowAsUniqueExposureAccount ? ' unique-account' : '')} 
                                            style={{ backgroundColor: shouldShowAsUniqueExposureAccount ? uniqueExposureBackgroundColor : null }}
                                            onClick={() => {
                                                const path = `${coin}.${accountName}`
                                                this.setState({ expandedPaths: isAccountExpanded ? _.without(expandedPaths, path) : expandedPaths.concat([path]) })
                                            }}>
                                            <Fragment><td /><td /></Fragment>
                                            <td className='exposure-table--account-name'>{accountName}<span className='exposure-table--level-2-row--count'>{`(${accountExposure.items.length})`}</span></td>
                                            {isLevel2Expanded && <Fragment><td /><td /><td /><td/></Fragment>}
                                            {this.ExposureTableData({ value: accountExposure.exposureSum, diff: accountExposureSumDiff })}
                                        </tr>
                                        {isAccountExpanded && accountExposure.items.map((exposureItem, index) => {
                                            const { source, exposure, pair, productName, timestamp } = exposureItem
                                            const exposureItemToCompare = accountExposureToCompare ? _.find(accountExposureToCompare.items, itemToCompare => {
                                                return itemToCompare.source === source 
                                                    && (_.isNil(pair) || itemToCompare.pair === pair)
                                                    && (_.isNil(productName) || itemToCompare.productName === productName)
                                            }) : null
                                            const exposureItemDiff = comparisonRules.includes('EXPOSURE_DIFF') && exposureItemToCompare ? (exposure - exposureItemToCompare.exposure) : null
                                            const shouldShowAsUniqueExposureItem = !_.isEmpty(exposureGroupsToCompare) && comparisonRules.includes('UNIQUE_EXPOSURES') && _.isNil(exposureItemToCompare)
                                            return (
                                                <tr className={'exposure-table--exposure-item-row' 
                                                + (index % 2 === 0 ? ' even-row' : ' odd-orw')
                                                + (index === 0 ? ' first-item-row' : '')
                                                + (index === accountExposure.items.length - 1 ? ' last-item-row' : '')
                                                + (shouldShowAsUniqueExposureItem ? ' unique-item' : '')} key={index}
                                                style={{ backgroundColor: shouldShowAsUniqueExposureItem ? uniqueExposureBackgroundColor : null }}>
                                                    <Fragment><td /><td /><td /></Fragment>
                                                    <td className='exposure-table--exposure-item-source'>
                                                        <span className={`exposure-table--exposure-item-source-type ${source}`}>{_.isString(source) ? source.replace(/_/g, ' ') : 'Unkown'}</span>
                                                        {pair || productName || ''}
                                                    </td>
                                                    <td>{moment(timestamp).format('HH:mm:ss')}</td>
                                                    <td className='exposure-table--exposure-item-detail'>
                                                        {_.map(exposureItem.detail, (value, key) => {
                                                            const name = Object.values(key).reduce((result, c) => {
                                                                result += (c === c.toUpperCase() ? ` ${c.toLowerCase()}` : c)
                                                                return result
                                                            }, '')
                                                            return value ? (
                                                                <div className='exposure-table--exposure-item-detail--block' key={key}>
                                                                    <span className='exposure-table--exposure-item-detail--name'>{name}</span>
                                                                    <span className='exposure-table--exposure-item-detail--value'>
                                                                        {_.isNumber(value) ? toNumberWithSmartPrecision({ number: value, shouldReturnLocalString: true }) : value}
                                                                    </span>
                                                                </div>
                                                            ) : null
                                                        })}
                                                    </td>
                                                    {this.ExposureTableData({ value: exposure, diff: exposureItemDiff })}
                                                </tr>
                                            )
                                        })}
                                    </Fragment>
                                )
                            })}
                        </tbody>
                    )
                })}
            </table>
        )
    } 

    render () {
        const { groupBy } = this.state
        return groupBy === TABLE_GROUP_BYS.ACCOUNT ? this.TableGroupByAccount() : this.TableGroupByCoin()
    }
}

ExposureTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    accountItems: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    exposureVariables: PropTypes.object.isRequired,

    portfolioName: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(Object.keys(MODES)),
    tableGroupBy: PropTypes.oneOf(Object.keys(TABLE_GROUP_BYS)),
    exposureItems: PropTypes.array.isRequired,
    exposureItemsToCompare: PropTypes.array,
    initialBalance: PropTypes.object.isRequired,
    initialBalanceToCompare: PropTypes.object,
    pricings: PropTypes.object.isRequired,
    pricingsToCompare: PropTypes.object,
    comparisonRules: PropTypes.arrayOf(PropTypes.oneOf(['EXPOSURE_DIFF', 'UNIQUE_EXPOSURES'])),
    uniqueExposureBackgroundColor: PropTypes.string,
    config: PropTypes.shape({
        expandedPaths: PropTypes.array,
        coinSortBy: PropTypes.string,
        accountSortBy: PropTypes.string,
        itemSortBy: PropTypes.string
    }),
    csvFileName: PropTypes.string,
    onChangeConfig: PropTypes.func
}

ExposureTable.defaultProps = {
    mode: MODES.EXPOSURE,
    tableGroupBy: TABLE_GROUP_BYS.COIN,
    exposureItems: [],
    exposureItemsToCompare: [],
    comparisonRules: [],
    uniqueExposureBackgroundColor: '#2e6f6f',
    config: {},
    onChangeConfig: () => {}
}

function mapStateToProps (state) {
    return {
        accountItems: state.account.items,
        symbolItems: state.symbol.items,
        exposureVariables: state.trading.exposureVariables
    }
}

export default connect(mapStateToProps)(ExposureTable)