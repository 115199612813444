import React, { Component } from 'react'
import { connect } from 'react-redux'
import ProfileContainer from '../components/profile/ProfileContainer'
import Footer from '../components/layout/Footer'
import Menu from '../components/layout/Menu'
import TradingContainer from '../components/trading/TradingContainer'

class HomePage extends Component {

    static getDerivedStateFromError(error) {
        console.log('Error: ', error)
    }

    componentDidCatch(error, info) {
        console.log('Error Catched: ', error, info)
    }

    render () {
        return (
            <div className='home-page'>
                <div className='home-page--main'>
                    <div className='home-page--left'><Menu /></div>
                    <div className='home-page--center'><ProfileContainer /></div>
                    <div className='home-page--right'><TradingContainer /></div>
                </div>
                <div className='home-page--footer'><Footer /></div>
            </div>
        )
    }
}

export default connect()(HomePage)

