import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import moment from 'moment'
import _ from 'lodash'

import NewWindow from 'react-new-window'

import OrderEditor, { MULTIPLE_ACCOUNTS } from './OrderEditor'
import AccountBalanceItem from '../account/AccountBalanceItem'
import AccountAssetItem from '../account/AccountAssetItem'
import ManualOrderContainer from './ManualOrderContainer'
import SocketConnection from '../webSocket/SocketConnection'
import OrderBook from '../symbol/OrderBook'
import PositionItem from './PositionItem'

import { getFilteredAccountBalanceBySymbol } from '../../util/accountUtil'
import { MANUAL_ORDER_PROFILE } from '../../configs/tradingConfig'
import { getSymbolAttributeByName } from '../../util/symbolUtil'
import { webSocketSendData } from '../webSocket/webSocketAction'
import { removeSymbolOrderBook } from '../symbol/symbolAction'

const getSessionStorageTradeWindowSubscribedSymbols = () => {
    return !_.isNil(sessionStorage.tradeWindowSubscribedSymbols) ? JSON.parse(sessionStorage.tradeWindowSubscribedSymbols) : {}
}

const updateSessionStorageTradeWindowSubscribedSymbols = (windowId, symbolName) => {
    const windowSubscribedSymbols = getSessionStorageTradeWindowSubscribedSymbols()
    if (windowId) {
        if (symbolName) {
            windowSubscribedSymbols[windowId] = symbolName
        } else {
            delete windowSubscribedSymbols[windowId]
        }
    }
    sessionStorage.tradeWindowSubscribedSymbols = JSON.stringify(windowSubscribedSymbols)
    return getSessionStorageTradeWindowSubscribedSymbols()
}

export const clearSessionStorageTradeWindowSubscribedSymbols = () => {
    delete sessionStorage.tradeWindowSubscribedSymbols
}
class TradeWindow extends Component {
    constructor (props) {
        super(props)
        this.state = {
            orderEditorConfig: {
                symbolName: null,
                accountName: null,
                accountListString: '',
                price: null,
                unit: null
            },
            shouldShowThreeColumns: false
        }    
        this.symbolNameUpdateTimeValue = null
        this.tradeWindowNode = null
        this.minWindowWidthShouldShowThreeColumns = 1919

        this.handleResizeWindow = this.handleResizeWindow.bind(this)
    }

    componentDidMount () {
        setTimeout(() => {
            const window = this.getWindow()
            if (window) {
                this.updateShouldShowThreeColumns()
                window.addEventListener('resize', this.handleResizeWindow)
                if (window.document && window.document.body) {
                    window.document.body.style.minWidth = '0px'
                }
            }
        })
    }

    componentWillUnmount () {
        const window = this.getWindow()
        if (window) {
            window.removeEventListener('resize', this.handleResizeWindow)
        }
    }

    handleResizeWindow () {
        this.updateShouldShowThreeColumns()
    }

    subscribeSymbolOrderBook (symbolName, shouldForceSubscribe=false) {
        const { windowId } = this.props
        if (symbolName) {
            const tradeWindowSubscribedSymbols = updateSessionStorageTradeWindowSubscribedSymbols(windowId, symbolName)
            const shouldSubscribeSymbol = shouldForceSubscribe
                || _.filter(tradeWindowSubscribedSymbols, symbol => symbol === symbolName).length === 1
            if (shouldSubscribeSymbol) {
                webSocketSendData(process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME, {
                    type: 'SUBSCRIBE_ORDER_BOOK',
                    symbols: [symbolName]
                })
            }  
        }
    }

    unsubscribeSymbolOrderBook (symbolName) {
        const { dispatch, windowId } = this.props
        const tradeWindowSubscribedSymbols = getSessionStorageTradeWindowSubscribedSymbols()
        const shouldUnsubscribeSymbol = symbolName && _.filter(tradeWindowSubscribedSymbols, symbol => symbol === symbolName).length === 1
        updateSessionStorageTradeWindowSubscribedSymbols(windowId, null)
        if (shouldUnsubscribeSymbol) {
            webSocketSendData(process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME, {
                type: 'UNSUBSCRIBE_ORDER_BOOK',
                symbols: [symbolName]
            })
            dispatch(removeSymbolOrderBook(symbolName))
        }
    }

    updateShouldShowThreeColumns () {
        const window = this.getWindow()
        if (window) {
            this.setState({ shouldShowThreeColumns: window.innerWidth > this.minWindowWidthShouldShowThreeColumns })
        }
    }

    getWindow () {
        return this.tradeWindowNode && this.tradeWindowNode.ownerDocument
            ? (this.tradeWindowNode.ownerDocument.defaultView || this.tradeWindowNode.ownerDocument.parentWindow)
            : null  
    }

    EmptyData () {
        return (
            <div className='trade-window--empty-data'>{'Empty Data'}</div>
        )
    }

    AccountBalance () {
        const { symbolName, accountName, accountListString } = this.state.orderEditorConfig
        const { symbolItems, accountBalance, accountAsset } = this.props
        const accountNames = _.isNil(accountName) ? []
            : accountName === MULTIPLE_ACCOUNTS ? (!_.isEmpty(accountListString) ? accountListString.split(',') : [])
            : [accountName]

        if (!_.isNil(symbolName) && !_.isEmpty(accountNames)) {
            const { base, quote } = getSymbolAttributeByName(symbolName)
            const symbolItem = symbolItems[symbolName]

            return _.map(accountNames, name => {
                const filteredAccountBalance = getFilteredAccountBalanceBySymbol({
                    accountBalance,
                    symbolItem,
                    accountName: name
                })
                const accountAssetItem = accountAsset[name]
                return (
                    <Fragment key={name}>
                        {_.some(Object.values(filteredAccountBalance, accountBalances => !_.isEmpty(accountBalances))) ? <AccountBalanceItem 
                            accountName={name}
                            filteredAccountBalance={filteredAccountBalance}
                            emptyMessage={'No Balance Data'}
                            shouldShowDetails
                            shouldShowAccountTypeTab 
                            shouldHideAccountName
                            shouldHideAccountTypeTitle />
                        : !_.isEmpty(accountAssetItem) ? <AccountAssetItem 
                            accountAssetItem={accountAssetItem} 
                            tokensToFilter={[base, quote]} />
                        : _.size(accountNames) === 1 ? this.EmptyData()
                        : null}
                    </Fragment>
                )
            })
        } else {
            return this.EmptyData()
        }
    }

    Position () {
        const { positions } = this.props
        const { symbolName, accountName, accountListString } = this.state.orderEditorConfig
        const accountNames = _.isNil(accountName) ? []
            : accountName === MULTIPLE_ACCOUNTS ? (!_.isEmpty(accountListString) ? accountListString.split(',') : [])
            : [accountName]

        if (!_.isEmpty(symbolName) && !_.isEmpty(accountNames)) {
            return _.map(accountNames, name => {
                const positionItem = !_.isNil(symbolName) 
                    ? _.find(positions, { account_name: name, product_name: symbolName })
                    : null
                return (
                    <Fragment key={name}>
                        { positionItem 
                            ? <PositionItem key={name} positionItem={positionItem} /> 
                            : _.size(accountNames) === 1 ? this.EmptyData()
                            : null}
                    </Fragment>
                )
            })
        } else {
            return this.EmptyData()
        }
    }

    render () {
        const { orderEditorConfig, shouldShowThreeColumns } = this.state
        const { onClose, symbolItems } = this.props
        const symbolItem = symbolItems[orderEditorConfig.symbolName]
        
        const AccountInfo = () => {
            return (
                <Fragment>
                    <div className='trade-window--account-balance'>
                        <div className='trade-window--account-balance--title'>{'Account Balance'}</div>
                        <div className='trade-window--account-balance--main'>{this.AccountBalance()}</div>
                    </div>
                    <div className='trade-window--position'>
                        <div className='trade-window--position--title'>{'Position'}</div>
                        <div className='trade-window--position--main'>{this.Position()}</div>
                    </div>
                </Fragment>
            )
        }

        return (
            <NewWindow
                title={'Trade - Antelope Technology'}
                features={{ width: 1680, height: 900 }}
                onUnload={() => { 
                    this.unsubscribeSymbolOrderBook(orderEditorConfig.symbolName)
                    onClose() 
                }}>
                <div className='trade-window' ref={(node) => { this.tradeWindowNode = node }}>
                    {shouldShowThreeColumns && <div className='trade-window--left'>{AccountInfo()}</div>}
                    <div className='trade-window--center'>
                        <div className='trade-window--order-editor'>
                            <div className='trade-window--order-editor--title'>
                                <span>{'New Order'}</span>
                                <div className='trade-window--order-editor--title--connection'>
                                    <SocketConnection hostname={MANUAL_ORDER_PROFILE.hostname} shouldHideReconnectInfo />
                                </div>
                            </div>
                            <div className='trade-window--order-editor--main'>
                                <OrderEditor 
                                    shouldHideTitle
                                    config={orderEditorConfig}
                                    onChangeConfig={(newConfig) => { 
                                        if (!_.isEmpty(newConfig.symbolName) && !_.isEqual(orderEditorConfig.symbolName, newConfig.symbolName)) {
                                            if (!_.isNil(orderEditorConfig.symbolName)) {
                                                this.unsubscribeSymbolOrderBook(orderEditorConfig.symbolName)
                                            }
                                            this.subscribeSymbolOrderBook(newConfig.symbolName)
                                            this.symbolNameUpdateTimeValue = moment().valueOf()
                                        }
                                        this.setState(dotProp.merge(this.state, 'orderEditorConfig', newConfig)) 
                                    }} />
                            </div>
                        </div>
                        <div className='trade-window--order-book'>
                            <div className='trade-window--order-book--title'>
                                <span>{'Order Book'}</span>
                                <div className='trade-window--order-book--title--connection'>
                                    <SocketConnection hostname={MANUAL_ORDER_PROFILE.hostname} shouldHideReconnectInfo />
                                </div>
                            </div>
                            <div className='trade-window--order-book--main'>
                                {symbolItem 
                                ? <OrderBook 
                                    shouldHideTitle
                                    symbolItem={symbolItem} 
                                    onClickPrice={(price) => { 
                                        this.setState({
                                            orderEditorConfig: dotProp.set(orderEditorConfig, 'price', price)
                                        })
                                    }} 
                                    onClickAmount={(amount) => {
                                        this.setState({
                                            orderEditorConfig: dotProp.set(orderEditorConfig, 'unit', amount)
                                        })
                                    }} 
                                    onClickReSubscribeButton={() => {
                                        if (!_.isNil(orderEditorConfig.symbolName)) {
                                            this.subscribeSymbolOrderBook(orderEditorConfig.symbolName, true)
                                        }
                                    }} />
                                : this.EmptyData()}
                            </div>
                        </div>
                        {!shouldShowThreeColumns && AccountInfo()}
                    </div>
                    <div className='trade-window--right'>
                        <ManualOrderContainer 
                            symbolToFilter={{
                                symbolName: orderEditorConfig.symbolName,
                                id: this.symbolNameUpdateTimeValue
                            }} 
                            onChangeSymbol={(newSymbolName) => {
                                if (!_.isEmpty(newSymbolName) && !_.isEqual(orderEditorConfig.symbolName, newSymbolName)) {
                                    if (!_.isNil(orderEditorConfig.symbolName)) {
                                        this.unsubscribeSymbolOrderBook(orderEditorConfig.symbolName)
                                    }
                                    this.subscribeSymbolOrderBook(newSymbolName)
                                    this.setState({ orderEditorConfig: dotProp.set(orderEditorConfig, 'symbolName', newSymbolName) })
                                }
                            }} />
                    </div>
                </div>
            </NewWindow>
        )
    }
}

TradeWindow.propTypes = {
    dispatch: PropTypes.func.isRequired,
    windowId: PropTypes.string.isRequired,
    accountBalance: PropTypes.object.isRequired,
    accountAsset: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    symbolItems: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
}

TradeWindow.defaultProps = {
    onClose: () => {}
}

function getStateFromProps (state) {
    return {
        accountBalance: state.account.balance,
        accountAsset: state.account.asset,
        positions: state.trading.positions,
        symbolItems: state.symbol.items
    }
}

export default connect(getStateFromProps)(TradeWindow)