import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FaSearch } from 'react-icons/fa'
import TimerEditor from './TimerEditor'
import TimerItem from './TimerItem'

class TimerContainer extends Component {
    
    constructor (props) {
        super(props)
        this.state = {
            creatingTimer: false,
            editingTimer: null,
            hideDetail: true,
            showSearch: false,
            searchString: '',
        }
    }

    render () {
        const { creatingTimer, editingTimer, hideDetail, showSearch, searchString } = this.state
        const { timerItems } = this.props
        const trimmedSearchString = searchString.trim().toLowerCase()
        return (
            <div className='timer-container'>
                <div className='timer-container--header'>
                    <div className='timer-container--title'>
                        <span>{'Timers'}</span>
                        {!_.isEmpty(timerItems) && <button className='timer-container--toggle-detail-button vertical-centered' onClick={() => {
                            this.setState({
                                hideDetail: !hideDetail
                            })
                        }}>
                            {(hideDetail ? 'Show' : 'Hide') + ' Details'}
                        </button>}
                        <button className={'timer-container--search-button' + (showSearch ? ' active' : '')} onClick={() => { 
                            this.setState({ 
                                searchString: '',
                                showSearch: !showSearch 
                            }) 
                        }}><FaSearch /></button>
                    </div>
                    {showSearch && <input className='timer-container--search-input' value={searchString}
                        placeholder={'Search Profiles'}
                        autoFocus
                        spellCheck={false}
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />}
                    <div className='timer-container--new-button-wrapper'>
                        <button className='timer-container--new-button centered' onClick={() => {
                            this.setState({ creatingTimer: true })
                        }}>{'New Timer'}</button>
                    </div>
                </div>
                <div className='timer-container--main'>
                    {_.isEmpty(timerItems) 
                    ? <div className='timer-container--empty-message'>{'Empty pending timers'}</div>
                    : _.sortBy(Object.values(timerItems), ['createdTime'])
                    .reverse().map((timer, index) => {
                        const showItem = trimmedSearchString.length === 0 
                        || _.findIndex(timer.profiles || [], profile => profile.profileId.toLowerCase().includes(trimmedSearchString)) > -1
                        return showItem ? (
                            <div className='timer-container--item' key={index}
                                onClick={() => {
                                    this.setState({ editingTimer: timer })
                                }}>
                                <TimerItem 
                                    timerItem={timer}
                                    searchString={searchString} 
                                    hideDetail={hideDetail} />
                            </div>
                        ) : null
                    })}
                </div>
                {creatingTimer && <TimerEditor 
                    mode={'CREATE'} 
                    closeOnSaveSuccess
                    onClickClose={() => { this.setState({ creatingTimer: false }) }} />}
                {editingTimer && <TimerEditor 
                    mode={'EDIT'}
                    defaultTimer={editingTimer} 
                    closeOnSaveSuccess={false}
                    onClickClose={() => { this.setState({ editingTimer: null }) }} />}
            </div>
        )
    }
}

TimerContainer.propTypes = {
    timerItems: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        timerItems: state.timer.items
    }
}

export default connect(mapStateToProps)(TimerContainer)