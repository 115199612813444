import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'

import { FiX } from 'react-icons/fi'
import Toggle from '../common/toggle/Toggle'
import SearchSelect from '../common/searchSelect/SearchSelect'
import { parameterTypes } from '../../configs/profileConfig'
import { toNumberInputValue } from '../../util/util'

class ProfileParam extends Component {

    _isArrayTypeParameter () {
        const { paramConfig } = this.props
        return [parameterTypes.BUY_SELL_BOOLEAN_ARRAY, 
            parameterTypes.BUY_SELL_NUMBER_ARRAY, 
            parameterTypes.MIN_MAX_NUMBER_ARRAY].includes(paramConfig.type)
    }

    ToggleParam = ({checked, disabled, onChangeToggle}) => {
        return (
            <Toggle className='profile-param--toggle' 
                checked={checked}
                disabled={disabled}
                trueText={'TRUE'}
                falseText={'FALSE'}
                onChange={(newChecked) => { onChangeToggle(newChecked) }} />
        )
    }

    NumberParam = ({number, disabled, onChangeNumber}) => {
        return (
            <input className='profile-param--number-input' 
                type={'number'}
                disabled={disabled} 
                value={!_.isNil(number) ? number : ''}
                onChange={(e) => { onChangeNumber(toNumberInputValue(e.target.value)) }} />
        )
    }

    SelectParam = ({value, options, disabled, onChangeOption}) => {
        return (
            <SearchSelect className='profile-param--select' 
                value={value}
                placeholder={'Select Type'}
                hideSearchBar
                disabled={disabled}
                options={options.map((optionValue) => {
                    return { value: optionValue, name: optionValue}
                })} 
                onChange={(newOption) => { onChangeOption(newOption.value) }} />
        )
    }

    SymbolParam = ({symbolName, onChangeSymbol}) => {
        const { symbolItems } = this.props
        const options = _.map(_.filter(symbolItems, symbolItem => symbolItem.trading === '1'), symbolItem => {
            return {
                value: symbolItem.symbol_name,
                name: symbolItem.symbol_name
            }
        })
        return (
            <SearchSelect className='profile-param--symbol' 
                value={symbolName} 
                options={options} 
                hasClearButton
                onClickClearButton={() => { onChangeSymbol('INVALID') }}
                onChange={(newOption) => { onChangeSymbol(newOption.value) }} />
        )
    }

    ArrayTypeParam = () => {
        const { value, paramConfig, hideArrayLabel, disabled, onChange } = this.props
        const isToggles = paramConfig.type === parameterTypes.BUY_SELL_BOOLEAN_ARRAY
        const isNumbers = [parameterTypes.BUY_SELL_NUMBER_ARRAY, parameterTypes.MIN_MAX_NUMBER_ARRAY].includes(paramConfig.type)
        return (
            _.isArray(value) && value.length > 1
            ? <div className='profile-param--array'>
                <div className='profile-param--array--section first-section'>
                    {!hideArrayLabel && <span>{'B'}</span>}
                    {isToggles && this.ToggleParam({
                        checked: value[0],
                        disabled: disabled,
                        onChangeToggle: (newChecked) => { onChange(dotProp.set(value, '0', newChecked)) }
                    })}
                    {isNumbers && this.NumberParam({
                        number: value[0],
                        disabled: disabled,
                        onChangeNumber: (newValue) => { onChange(dotProp.set(value, '0', newValue)) } 
                    })}
                </div>
                <div className='profile-param--array--section second-section'>
                    {!hideArrayLabel && <span>{'S'}</span>}
                    {isToggles && this.ToggleParam({
                        checked: value[1],
                        disabled: disabled,
                        onChangeToggle: (newChecked) => { onChange(dotProp.set(value, '1', newChecked)) }
                    })}
                    {isNumbers && this.NumberParam({
                        number: value[1],
                        disabled: disabled,
                        onChangeNumber: (newValue) => { onChange(dotProp.set(value, '1', newValue )) } 
                    })}
                </div>
            </div>
            : null
        )
    }

    MultipleLengthArrayParam = ({ values, disabled, onChangeArray }) => {
        const { paramConfig } = this.props
        const { arrayLengthMultiplier, multipleLengthArrayLabels } = paramConfig
        if (_.isArray(values) && arrayLengthMultiplier > 0 && values.length % arrayLengthMultiplier === 0) {
            const arrayList = values.reduce((result, v) => {
                const multiplier = arrayLengthMultiplier || 1
                if (_.isEmpty(result) || _.last(result).length === multiplier) {
                    result.push([])
                }
                _.last(result).push(v)
                return result
            }, [])
            return (
                <div className='profile-param--multiple-length-array'>
                    {!_.isEmpty(arrayList) && <div className='profile-param--multiple-length-array--items'>
                        {arrayList.map((arrayListItem, arrayListIndex) => {
                            return (
                                <div className='profile-param--multiple-length-array--item' key={arrayListIndex}>
                                    <div className='profile-param--multiple-length-array--item--main'>
                                        {arrayListItem.map((innerValue, innerIndex) => {
                                            const label = _.isArray(multipleLengthArrayLabels) && _.has(multipleLengthArrayLabels, innerIndex) ? multipleLengthArrayLabels[innerIndex] : null
                                            return (
                                                <div className='profile-param--multiple-length-array--inner-item' key={innerIndex}>
                                                    {label && <span>{label}</span>}
                                                    {paramConfig.type === parameterTypes.NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER && 
                                                    this.NumberParam({
                                                        number: innerValue,
                                                        disabled: disabled,
                                                        onChangeNumber: (newValue) => { onChangeArray(dotProp.set(values, `${arrayListIndex * arrayLengthMultiplier + innerIndex}`, newValue)) }
                                                    })}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <button className='profile-param--multiple-length-array--item--remove-button' onClick={() => {
                                        const newValues = _.cloneDeep(values)
                                        newValues.splice(arrayLengthMultiplier * arrayListIndex, arrayLengthMultiplier)
                                        onChangeArray(newValues)
                                    }}><FiX /></button>
                                </div>
                            )
                        })}
                    </div>}
                    <button className='profile-param--multiple-length-array--add-element-button' onClick={() => {
                        if (arrayLengthMultiplier > 0) {
                            const defaultElementValue = paramConfig.type === parameterTypes.NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER ? 0 : false
                            const newValues = values.concat(new Array(arrayLengthMultiplier).fill(defaultElementValue))
                            onChangeArray(newValues)
                        }
                    }}>{'Add'}</button>
                </div>
            )
        } else {
            return null
        }
    }

    render () {
        const { paramConfig, value, disabled, hidden, onClick, onChange } = this.props
        return (
            <div className={'profile-param'}
                onClick={(e) => { onClick(e) }}>
                {paramConfig.type === parameterTypes.BOOLEAN && !_.isNil(value) && !hidden &&
                this.ToggleParam({
                    checked: value,
                    disabled: disabled,
                    onChangeToggle: (newChecked) => { onChange(newChecked) }
                })}
                {paramConfig.type === parameterTypes.NUMBER && !_.isNil(value) && !hidden &&
                this.NumberParam({
                    number: value,
                    disabled: disabled,
                    onChangeNumber: (newValue) => { onChange(newValue) }
                })}
                {paramConfig.type === parameterTypes.STRING && _.isArray(paramConfig.options) && !hidden &&
                this.SelectParam({
                    value: value,
                    options: paramConfig.options,
                    disabled: disabled,
                    onChangeOption: (newOption) => { onChange(newOption) }
                })}
                {paramConfig.type === parameterTypes.SYMBOL && !_.isUndefined(value) && !hidden &&
                this.SymbolParam({
                    symbolName: value,
                    onChangeSymbol: (symbolName) => { onChange(symbolName) }
                })}
                {this._isArrayTypeParameter() && !hidden && this.ArrayTypeParam()}
                {paramConfig.type === parameterTypes.NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER && !hidden && 
                this.MultipleLengthArrayParam({
                    values: value,
                    disabled: disabled,
                    onChangeArray: (newArray) => { onChange(newArray) }
                })}
            </div>
        )
    }
}

ProfileParam.propTypes = {
    symbolItems: PropTypes.object.isRequired,
    paramConfig: PropTypes.object.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
        PropTypes.array
    ]),
    hideArrayLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func
}

ProfileParam.defaultProps = {
    onChange: () => {},
    onClick: () => {}
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(ProfileParam)