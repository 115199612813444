import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { ADD_NOTIFICATION_ITEMS, CLEAR_NOTIFICATION_ITEMS, 
	ADD_TRANSACTION_ITEMS, CLEAR_TRANSACTION_ITEMS,
	UPDATE_TRADING_POSITIONS, UPDATE_SINGLE_LIMITS, UPDATE_EXPOSURES, UPDATE_EXPOSURE_VARIABLES,
	UPDATE_INITIAL_BALANCE, UPDATE_IGNORED_ISSUE_IDS, REMOVE_ISSUE_ITEM,
	UPDATE_PROFILE_ORDER, UPDATE_PROFILE_ORDERS, UPDATE_MANUAL_ORDER, UPDATE_MANUAL_ORDERS, REMOVE_MANUAL_ORDERS,
	UPDATE_AUTO_TRANSFERS, UPDATE_EXPOSURE_ADJUSTMENTS, UPDATE_RISK_RATIO_THRESHOLDS, UPDATE_VALUE_AT_RISK,
	UPDATE_OPTION_GREEK } from './tradingAction'

import { UPDATE_WEB_SOCKET_BUFFERED_TRADING_MESSAGE_DATA } from '../webSocket/webSocketAction'
import moment from 'moment'

const getLocalStorageIssues = () => {
	localStorage.removeItem('issues')
	return localStorage.issues && localStorage.issues.length > 0 
		? JSON.parse(localStorage.issues)
		: []
}

const updateIssues = (issues=[]) => {
	localStorage.issues = _.isEmpty(issues) ? '' : JSON.stringify(issues)
	return getLocalStorageIssues()
}

const getLocalStorageIgnoredIssueIds = () => {
	localStorage.removeItem('ignoredIssueIds')
	return localStorage.ignoredIssueIds && localStorage.ignoredIssueIds.length > 0 
		? localStorage.ignoredIssueIds.split(',')
		: []
}

const updateIgnoredIssueIdsToLocalStorage = (ignoredIssueIds=[]) => {
	localStorage.ignoredIssueIds = _.isEmpty(ignoredIssueIds) ? '' : ignoredIssueIds.join(',')
	return getLocalStorageIgnoredIssueIds()
}

const initialState = {
    transactions: [],
	notifications: [],
	positions: [],
	singleLimits: {},
	openInterests: {},
	exposure: {
		items: [],
		timestamp: null,
	},
    exposureVariables: {
        positionRatio: null,
        coinThresholdMinValues: {}
    },
	exposureAdjustments: [],
	initialBalance: {},
	issues: getLocalStorageIssues(),
	ignoredIssueIds: getLocalStorageIgnoredIssueIds(),
	profileOrders: {},
	manualOrders: {},
	autoTransfers: [],
	riskRatioThresholds: {},
	valueAtRisk: [],
	optionGreek: {}
}

// const Issue = ({ id, tag, profileId, originalMessage, timestamp }) => {
// 	return {
// 		id,
// 		tag,
// 		profileId,
// 		originalMessage,
// 		timestamp
// 	}
// }

// const getIssuesFromNotifications = (notifications) => {
// 	const issues = notifications.reduce((result, notificationItem) => {
// 		const { type, originalMessage, profileId, timestamp } = notificationItem
// 		if (type === 'SWITCH_OFF' && originalMessage && originalMessage.reason !== 'NAC switched off') {
// 			const { account, symbol, side, reason } = originalMessage
// 			const issueId = `${type}--${profileId}--${account}--${symbol}--${side}--${reason.replace(/\s+/g, '_')}`
// 			result.push(Issue({
// 				id: issueId,
// 				tag: 'SWITCH OFF',
// 				profileId,
// 				originalMessage,
// 				timestamp
// 			}))
// 		} else if (type === 'CRASH') {
// 			const issueId = `${type}--${profileId}`
// 			result.push(Issue({
// 				id: issueId,
// 				tag: 'PROFILE CRASH',
// 				profileId,
// 				originalMessage,
// 				timestamp
// 			}))
// 		} else if (type === 'QTY_CAPPED' && originalMessage) {
// 			const { account, symbol, side } = originalMessage
// 			const issueId = `${type}--${profileId}--${account}--${symbol}--${side}`
// 			result.push(Issue({
// 				id: issueId,
// 				tag: 'QTY CAPPED',
// 				profileId,
// 				originalMessage,
// 				timestamp
// 			}))
// 		} else if (type === 'SMART_POS_ACCT_EXCEPTION' && originalMessage) {
// 			const { symbol, side } = originalMessage
// 			const issueId = `${type}--${profileId}--${symbol}--${side}`
// 			result.push(Issue({
// 				id: issueId,
// 				tag: 'SMART ACCT EXC',
// 				profileId,
// 				originalMessage,
// 				timestamp
// 			}))
// 		}
// 		return result
// 	}, [])
// 	return issues
// }

export function tradingReducer (state = initialState, action) {
	let newState, newNotifications, newTransactions, newIgnoredIssueIds, profileOrderItems, newAutoTransfers //, newIssues
    switch (action.type) {
		case ADD_NOTIFICATION_ITEMS:
			// newIssues = _.unionBy(state.issues, getIssuesFromNotifications(action.notifications), 'id')
			// newIssues = _.sortBy(newIssues, 'timestamp').reverse()
			newState = dotProp.set(state, 'notifications', _.concat(action.notifications, state.notifications))
			// newState = dotProp.set(newState, 'issues', updateIssues(newIssues))
			return newState

		case CLEAR_NOTIFICATION_ITEMS:
			return dotProp.set(state, 'notifications', [])

		case ADD_TRANSACTION_ITEMS:
			return dotProp.set(state, 'transactions', _.take(action.transactions.concat(state.transactions), 200))

		case UPDATE_WEB_SOCKET_BUFFERED_TRADING_MESSAGE_DATA:
			newNotifications = _.take(action.notificationItems.concat(state.notifications), 200)
			newTransactions = _.take(action.transactionItems.concat(state.transactions), 200)
			newState = Object.assign({}, state, {
				notifications: newNotifications,
				transactions: newTransactions
			})
			return newState

		case CLEAR_TRANSACTION_ITEMS:
				return dotProp.set(state, 'transactions', [])

		case UPDATE_TRADING_POSITIONS:
			return dotProp.set(state, 'positions', action.positions)


		case UPDATE_SINGLE_LIMITS:
			return dotProp.set(state, 'singleLimits', _.keyBy(action.singleLimits, 'prod_name'))

		case UPDATE_EXPOSURES:
			return dotProp.merge(state, 'exposure', {
				items: action.exposures || [],
				timestamp: moment().toISOString()
			})

        case UPDATE_EXPOSURE_VARIABLES:
            return dotProp.merge(state, 'exposureVariables', action.variables)

		case UPDATE_INITIAL_BALANCE:
			return dotProp.set(state, 'initialBalance', action.initialBalance)

		case UPDATE_IGNORED_ISSUE_IDS:
			return dotProp.set(state, 'ignoredIssueIds', updateIgnoredIssueIdsToLocalStorage(action.ignoredIssueIds))

		case REMOVE_ISSUE_ITEM:
			newState = dotProp.set(state, 'issues', updateIssues(state.issues.filter(issueItem => issueItem.id !== action.issueItem.id)))
			newIgnoredIssueIds = updateIgnoredIssueIdsToLocalStorage(_.without(state.ignoredIssueIds, action.issueItem.id))
			newState.ignoredIssueIds = newIgnoredIssueIds
			return newState

		case UPDATE_MANUAL_ORDER:
			return dotProp.set(state, `manualOrders.${action.clientOrderId}`, action.order)

		case UPDATE_MANUAL_ORDERS:
			return dotProp.merge(state, 'manualOrders', action.orders)

		case REMOVE_MANUAL_ORDERS:
			return dotProp.set(state, `manualOrders`, _.omit(state.manualOrders, action.orderIds))

		case UPDATE_PROFILE_ORDER:
			profileOrderItems = _.cloneDeep(state.profileOrders[action.profileId])
			if (_.isEmpty(profileOrderItems)) {
				profileOrderItems = {}
			}
			profileOrderItems[action.clientOrderId] = Object.assign({}, profileOrderItems[action.clientOrderId] || {}, action.order)
			newState = dotProp.set(state, `profileOrders.${action.profileId}`, profileOrderItems)
			return newState

		case UPDATE_PROFILE_ORDERS:
			profileOrderItems = _.cloneDeep(state.profileOrders[action.profileId])
			if (_.isEmpty(profileOrderItems)) {
				profileOrderItems = {}
			}
			profileOrderItems = Object.assign({}, profileOrderItems, action.profileOrders)
			newState = dotProp.set(state, `profileOrders.${action.profileId}`, profileOrderItems)
			return newState

		case UPDATE_AUTO_TRANSFERS:
			newAutoTransfers = _.sortBy(_.unionBy(state.autoTransfers, action.autoTransfers, '_id'), item => item.timestamp).reverse()
			return dotProp.set(state, 'autoTransfers', newAutoTransfers)

		case UPDATE_EXPOSURE_ADJUSTMENTS:
			return dotProp.set(state, 'exposureAdjustments', action.exposureAdjustments)

		case UPDATE_RISK_RATIO_THRESHOLDS:
			return dotProp.merge(state, 'riskRatioThresholds', action.riskRatioThresholds)

		case UPDATE_VALUE_AT_RISK:
			return dotProp.set(state, 'valueAtRisk', action.valueAtRisk)

		case UPDATE_OPTION_GREEK:
			return dotProp.set(state, 'optionGreek', action.optionGreek)

        default: 
            return state
    }
}