import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BigNumber from 'bignumber.js'
import moment from 'moment'
import _ from 'lodash'

import { fetchProfileFillStatistics } from './profileAction'
import { getSymbolAttributeByName } from '../../util/symbolUtil'
import { toNumberWithSmartPrecision } from '../../util/util'

class ProfileFillStatistics extends Component {
    constructor (props) {
        super(props)
        this.state = {
            fromTimestamp: moment().add(-1, 'day').toISOString(),
            toTimestamp: moment().toISOString(),
            isFetching: false,
            fillStatistics: {
                buy: [],
                sell: []
            }
        }

        this.datetimeAPIFormat = 'YYYY-MM-DD HH:mm:ss'
        this.datetimeInputFormat = 'YYYY-MM-DDTHH:mm:ss'
    }

    componentDidMount () {
        this._getFillStatistics()
    }

    _getFillStatistics () {
        const { dispatch, profileItems, profileId } = this.props
        const { fromTimestamp, toTimestamp, isFetching } = this.state
        const profileItem = profileItems[profileId]
        if (!_.isNil(profileItem) && !isFetching 
            && moment(fromTimestamp).isValid() && moment(toTimestamp).isValid()
            && moment(fromTimestamp).isBefore(toTimestamp)) {
            this.setState({ 
                isFetching: true,
                fillStatistics: {
                    buy: [],
                    sell: []
                }
            })
            dispatch(fetchProfileFillStatistics({
                profile: profileItem.name,
                hostname: profileItem.hostname,
                fromTimestamp: moment(fromTimestamp).format(this.datetimeAPIFormat),
                toTimestamp: moment(toTimestamp).format(this.datetimeAPIFormat)
            }))
            .then(result => {
                if (!_.isNil(result) && _.isArray(result.buy) && _.isArray(result.sell)) {
                    this.setState({
                        fillStatistics: result
                    })
                } 
            })
            .finally(() => {
                this.setState({ isFetching: false })
            })
        }
    }

    _getFillStatsBaseAndQuoteTokens (fillStatistics={}) {
        const result = {
            bases: [],
            quotes: []
        }
        _.forEach(_.concat(fillStatistics.buy || [], fillStatistics.sell || []), fillStatsItem => {
            const { prod_name: symbol } = fillStatsItem
            if (!_.isEmpty(symbol)) {
                const { base, quote } = getSymbolAttributeByName(symbol)
                result.bases.push(base)
                result.quotes.push(quote)
            }
        })
        result.bases = _.uniq(result.bases)
        result.quotes = _.uniq(result.quotes)
        return result
    }

    DateTimeTabs () {
        const { isFetching } = this.state
        return (
            <div className='profile-fill-statistics--date-time-tabs'>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={isFetching}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-1, 'hour').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'1 Hour'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={isFetching}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-4, 'hour').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'4 Hours'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={isFetching}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-1, 'day').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'1 Day'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={isFetching}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-3, 'days').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                }}>{'3 Days'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={isFetching}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-7, 'days').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'7 Days'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={isFetching}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-14, 'days').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'14 Days'}</button>
                <button className='profile-fill-statistics--date-time-tab' 
                    disabled={isFetching}
                    onClick={() => {
                        this.setState({
                            fromTimestamp: moment().add(-1, 'month').toISOString(),
                            toTimestamp: moment().toISOString()
                        }, () => { this._getFillStatistics() })
                    }}>{'1 Month'}</button>
            </div>
        )
    }

    DateTimeInput (side='from') {
        const { fromTimestamp, toTimestamp, isFetching } = this.state
        return (
            <div className='profile-fill-statistics--date-time-input'>
                <label>{side === 'to' ? 'To' : 'From'}</label>
                <input type={'datetime-local'}
                    value={moment(side === 'to' ? toTimestamp : fromTimestamp).format(this.datetimeInputFormat)} 
                    disabled={isFetching}
                    onChange={(e) => {
                        const newMoment = moment(e.target.value)
                        if (newMoment.isValid()) {
                            this.setState({
                                [side === 'to' ? 'toTimestamp' : 'fromTimestamp']: moment(e.target.value).toISOString()
                            })
                        }
                    }} />
            </div>
        )
    }

    AggregatedStats () {
        const { fillStatistics } = this.state
        const baseAndQuoteTokens = this._getFillStatsBaseAndQuoteTokens(fillStatistics)
        const usdTokens = ['USD', 'USDT', 'BUSD', 'USDC']
        const shouldHaveAggregatedStats = _.size(baseAndQuoteTokens.bases) === 1 && (
            _.size(baseAndQuoteTokens.quotes) === 1
            || _.every(baseAndQuoteTokens.quotes, quote => usdTokens.includes(quote))
        )
        
        if (shouldHaveAggregatedStats) {
            const aggregatedStats = {
                buy: {
                    coinSum: '0',
                    notionalSum: '0'
                },
                sell: {
                    coinSum: '0',
                    notionalSum: '0'
                }
            }
            _.forEach(_.keys(aggregatedStats), side => {
                _.forEach(fillStatistics[side] || [], fillStatsItem => {
                    const { coin, quote } = fillStatsItem
                    aggregatedStats[side].coinSum = BigNumber(aggregatedStats[side].coinSum).plus(coin)
                    aggregatedStats[side].notionalSum = BigNumber(aggregatedStats[side].notionalSum).plus(quote)
                })
            })
            return (
                <div className='profile-fill-statistics--aggregated-stats'>
                    <div className='profile-fill-statistics--aggregated-stats--title'>{'Aggregated Stats'}</div>
                    <div className='profile-fill-statistics--aggregated-stats--main'>
                        <table>
                            <thead>
                                <tr>
                                    <th>{'Side'}</th>
                                    <th className='buy'>{'BUY'}</th>
                                    <th className='sell'>{'SELL'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{`Notional Sum (${baseAndQuoteTokens.quotes.join(' | ')})`}</th>
                                    <td className='buy'>{BigNumber(aggregatedStats.buy.notionalSum || 0).gte(1000) ? BigNumber(aggregatedStats.buy.notionalSum).toFormat(2, 1) : BigNumber(aggregatedStats.buy.notionalSum || 0).toPrecision(6, 1)}</td>
                                    <td className='sell'>{BigNumber(aggregatedStats.sell.notionalSum || 0).gte(1000) ? BigNumber(aggregatedStats.sell.notionalSum).toFormat(2, 1) : BigNumber(aggregatedStats.sell.notionalSum || 0).toPrecision(6, 1)}</td>
                                </tr>
                                <tr>
                                    <th>{`Coin Sum (${baseAndQuoteTokens.bases[0]})`}</th>
                                    <td className='buy'>{BigNumber(aggregatedStats.buy.coinSum || 0).gte(1000) ? BigNumber(aggregatedStats.buy.coinSum).toFormat(2, 1) : BigNumber(aggregatedStats.buy.coinSum || 0).toPrecision(6, 1)}</td>
                                    <td className='sell'>{BigNumber(aggregatedStats.sell.coinSum || 0).gte(1000) ? BigNumber(aggregatedStats.sell.coinSum).toFormat(2, 1) : BigNumber(aggregatedStats.sell.coinSum || 0).toPrecision(6, 1)}</td>
                                </tr>
                                <tr>
                                    <th>{`Avg Price`}</th>
                                    <td className='buy'>{BigNumber(aggregatedStats.buy.notionalSum).div(aggregatedStats.buy.coinSum).toFormat(5, 1)}</td>
                                    <td className='sell'>{BigNumber(aggregatedStats.sell.notionalSum).div(aggregatedStats.sell.coinSum).toFormat(5, 1)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='profile-fill-statistics--aggregated-stats'>
                    <div className='profile-fill-statistics--aggregated-stats--error-message'>{'Filled symbols are not in the same pair.'}</div>
                </div>
            )
        }
    }

    Result () {
        const { fillStatistics, isFetching } = this.state
        const symbolToFillStatsMaps = _.groupBy(_.concat(fillStatistics.buy, fillStatistics.sell), 'prod_name')
        return (
            <div className='profile-fill-statistics--result'>
                {!isFetching && _.isEmpty(symbolToFillStatsMaps) && <div className='profile-fill-statistics--result--empty-data'>{'Empty Data'}</div>}
                {_.size(symbolToFillStatsMaps) > 1 && this.AggregatedStats(fillStatistics)}
                {_.map(symbolToFillStatsMaps, (fillStatsGroup, symbol) => {
                    const { base, quote } = getSymbolAttributeByName(symbol)
                    const buyFillStats = _.find(fillStatsGroup, { buy_sell: 'BUY' })
                    const sellFillStats = _.find(fillStatsGroup, { buy_sell: 'SELL' })
                    return (
                        <div className='profile-fill-statistics--result--item' key={symbol}>
                            <div className='profile-fill-statistics--result--item--title'>{symbol}</div>
                            <div className='profile-fill-statistics--result--item--main'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>{'Side'}</th>
                                            <th className='buy'>{'BUY'}</th>
                                            <th className='sell'>{'SELL'}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>{`Notional (${quote})`}</th>
                                            <td className='buy'>{buyFillStats ? toNumberWithSmartPrecision({ number: buyFillStats.quote, shouldApplyFloorValue: true, shouldReturnLocalString: true }) : 'N/A'}</td>
                                            <td className='sell'>{sellFillStats ? toNumberWithSmartPrecision({ number: sellFillStats.quote, shouldApplyFloorValue: true, shouldReturnLocalString: true }) : 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th>{`Coin (${base})`}</th>
                                            <td className='buy'>{buyFillStats ? toNumberWithSmartPrecision({ number: buyFillStats.coin, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 6 }) : 'N/A'}</td>
                                            <td className='sell'>{sellFillStats ? toNumberWithSmartPrecision({ number: sellFillStats.coin, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 6 }) : 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <th>{`Avg Price`}</th>
                                            <td className='buy'>{buyFillStats ? toNumberWithSmartPrecision({ number: buyFillStats.avg_fill_price, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 5 })  : 'N/A'}</td>
                                            <td className='sell'>{sellFillStats ? toNumberWithSmartPrecision({ number: sellFillStats.avg_fill_price, shouldApplyFloorValue: true, shouldReturnLocalString: true, defaultPrecision: 5 }) : 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render () {
        const { profileItems, profileId } = this.props
        const { isFetching } = this.state
        const profileItem = profileItems[profileId]
        return (
            <div className='profile-fill-statistics'>
                <div className='profile-fill-statistics--header'>
                    <label>{'Fill Statistics'}</label>
                    {isFetching && <span className='profile-fill-statistics--header--is-fetching'>{'Fetching...'}</span>}
                    <span className='profile-fill-statistics--header--profile-name'>{!_.isNil(profileItem) ? profileItem.name : 'N/A'}</span>
                </div>
                {this.DateTimeTabs()}
                <div className='profile-fill-statistics--date-time-inputs'>
                    {this.DateTimeInput('from')}
                    <span>{' - '}</span>
                    {this.DateTimeInput('to')}
                    <button className='profile-fill-statistics--date-time-inputs--go-button' 
                        disabled={isFetching}
                        onClick={() => {
                            this._getFillStatistics()
                        }}>{'Go'}</button>
                </div>
                {this.Result()}
            </div>
        )
    }
}

ProfileFillStatistics.propTypes = {
    dispatch: PropTypes.func.isRequired,
    profileItems: PropTypes.object.isRequired,

    profileId: PropTypes.string.isRequired
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(ProfileFillStatistics)