import React, { Component } from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import AuthForm from '../components/auth/AuthForm'
import backgroundImage from '../images/antelope.jpg'
import loadingImage from '../images/loading.svg'

import { authVerifyUser } from '../components/auth/authAction'

class LoginPage extends Component {

    constructor (props) {
        super(props)
        this.state = {
            isVerifying: false,
            isVerified: false
        }
    }

    componentDidMount () {
        const {history, auth} = this.props
        if (auth.isLoggedIn) {
            history.push('/')
        } else if (!_.isEmpty(auth.accessToken)) {
            this.verifyUser()
        }
    }

    verifyUser () {
        const {dispatch, history} = this.props
        this.setState({ isVerifying: true })
        dispatch(authVerifyUser())
        .then((result) => {
            const isVerified = result && result === 'success'
            this.setState({ isVerified })
            if (isVerified) {
                setTimeout(() => {
                    history.push('/')
                }, 400)
            }
        })
        .finally(() => {
            this.setState({ isVerifying: false })
        })
    }

    render () {
        const {isVerifying, isVerified} = this.state
        return (
            <div className='login-page' style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <div className='login-page--curtain' />
                <div className='login-page--main centered'>
                    <AuthForm />
                </div>
                {(isVerifying || isVerified) && <div className='login-page--verifying-wrapper'>
                    <div className={'login-page--verifying-wrapper--content centered' + (isVerified ? ' is-verified' : '')}>
                        {<img src={loadingImage} alt={'verifying'} />}
                        <span>{'Verifying'}</span>
                    </div>
                </div>}
            </div>
        )
    }
}

LoginPage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps)(LoginPage)