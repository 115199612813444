import _ from 'lodash'
import fetch from 'isomorphic-fetch'
import uuidv4 from 'uuid/v4'
import moment from 'moment'

import { authLogout } from '../components/auth/authAction'
import { addNotificationItem } from '../components/trading/tradingAction'

export const secureFetch = (url, options={}) => {
    return (dispatch, getState) => {
        const { accessToken, username } = getState().auth
        options.headers = Object.assign({}, options.headers || {}, {
            'Authorization': `Bearer ${accessToken}`
        })
        const request = fetch(url, options)
        request.then((response) => {
            if (response.status === 401) {
                dispatch(authLogout())
            } else if (response.status === 503) {
                dispatch(addNotificationItem({
                    id: uuidv4(),
                    timestamp: moment().toISOString(),
                    user: username,
                    type: 'SERVICE_UNAVAILABLE',
                    message: 'The server might be in maintenance.'
                }))
            }
        })
        return request
    }
}

export const isInViewport = (elem, offsetBottom) => {
    if (elem) {
        const rect = elem.getBoundingClientRect()
        const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
        const windowWidth = (window.innerWidth || document.documentElement.clientWidth)
        const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height - offsetBottom || 0) >= 0)
        const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0)
        return (vertInView && horInView)
    } else {
        return false
    }
}

export const getQueryString = (params) => {
    return Object.keys(params).filter(key => !_.isNil(params[key])).map(key => key + '=' + params[key]).join('&')
}

export const toNumberInputValue = (value) => {
    const valueString = (value || '').toString().trim()
    const numberValue = valueString.length > 0 ? _.toNumber(valueString) : ''
    return numberValue
}

export const toFixedNumber = (number, maxPrecision = 2) => {
    const valueString = !_.isNil(number) ? number.toString(undefined, { maximumFractionDigits: 10 }) : ''
    return valueString.includes('.') && valueString.split('.')[1].length > maxPrecision ? Number(valueString).toFixed(maxPrecision) : valueString
}

export const countDecimals = (value='') => {
    const valueString = value.toString()
    const fractions = valueString.split('.')
    const decimals = fractions.length === 2 ? (fractions[1].length || 0) : 0
    return decimals
}

export const toNumberWithSmartPrecision = ({ number, defaultPrecision=2, shouldApplyFloorValue, shouldReturnLocalString }) => {
    const numberInLocalString = Number(number).toLocaleString(undefined, { maximumFractionDigits: 10, useGrouping: false })
    let precision = number === 0 ? 0 : defaultPrecision
    let result
    if (numberInLocalString.includes('.')) {
        const tokens = numberInLocalString.split('.')
        if (tokens[0] === '0' || tokens[0] === '-0') {
            const firstNonZeroDigitIndex = _.findIndex(tokens[1].split(''), c => c !== '0')
            precision = Math.max(firstNonZeroDigitIndex + 2, precision)
        }
    }
    const multiplier = Math.pow(10, precision)
    if (shouldApplyFloorValue) {
        result = Math.floor(number * multiplier) / multiplier
    } else {
        result = Math.round(number * multiplier) / multiplier
    }
    return shouldReturnLocalString
        ? result.toLocaleString(undefined, { minimumFractionDigits: precision, maximumFractionDigits: precision, useGrouping: true })
        : result
}

export const toAbbreviateNumber = (value, defaultPrecision=2) => {
    const suffixes = ['', 'K', 'M', 'B', 'T']
    const suffixIndex = Math.min(Math.floor((Number.parseInt(value).toString().length - 1) / 3), 12)
    return toNumberWithSmartPrecision({ number: value / Math.pow(10, suffixIndex * 3), defaultPrecision, shouldReturnLocalString: true }) + suffixes[suffixIndex]
}

export const hashCode = (string='') => {
    let hash = 0
    for (let i=0; i<string.length; i++) {
        const char = string.charCodeAt(i)
        hash = ((hash << 5) - hash) + char
        hash |= 0
    }
    return hash
}

export const isMetSearchStringCriteria = (targetString='', searchString='') => {
    const splitSearchStrings = searchString.split(',')
    return _.every(splitSearchStrings, splitSearchString => {
        const trimmedSearchString = splitSearchString.toLowerCase().trim()
        return trimmedSearchString.length === 0 
        || _.some(trimmedSearchString.split(/\|{2}|\s+/), v => {
            const trimmedV = v.trim()
            const isNotLogic = _.startsWith(trimmedV, '!')
            return trimmedV.length > 0 
                && ((isNotLogic && trimmedV.length > 1 && !targetString.toLowerCase().includes(trimmedV.substring(1)))
                    || (!isNotLogic && targetString.toLowerCase().includes(trimmedV))
                )
        })
    })
}