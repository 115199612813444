import { secureFetch } from '../../util/util'
import { DEFAULT_SERVER, ELF_API_BASE_URL } from '../../configs/config'
import _ from 'lodash'

export function postSupportRequest (message='') {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/req_IT_support`, {
            method: 'POST',
            body: JSON.stringify({ message })
        }))
        .then(response => {
            if (response.status === 200) {
                resolve(response)
            } else {
                throw new Error(`Status code: ${response.status}`)
            }
        })
        .catch(error => {
            console.error('postSupportRequest error: ', error)
            reject(error)
        })
    })
}

export function fetchPortfolioDetails () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/portfolioDetails`))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error(`Status code: ${response.status}`)
            }
        })
        .then(body => {
            if (_.isArray(body)) {
                resolve(body)
            } else {
                throw new Error('Unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchPortfolioDetails error: ', error)
            reject(error)
        })
    })
}

export function postAlertAllTraders () {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/alert_all_traders`, {
            method: 'POST'
        }))
        .then(response => {
            if (response.status === 200) {
                resolve(response)
            } else {
                throw new Error(`Status code: ${response.status}`)
            }
        })
        .catch(error => {
            console.error('postAlertAllTraders error: ', error)
            reject(error)
        })
    })
}