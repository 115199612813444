import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import dotProp from 'dot-prop-immutable'
import uuidv4 from 'uuid/v4'
import moment from 'moment'
import _ from 'lodash'

import { CSVLink } from 'react-csv'
import { FiX } from 'react-icons/fi'
import { EXCHANGES_CAN_USE_REDUCE_ONLY, MANUAL_ORDER_PROFILE } from '../../configs/tradingConfig'
import { SYMBOL_SWITCH_TYPES, INSTRUMENT_TYPES, getSymbolAttributeByName, getPricePrecisionBySymbolItem } from '../../util/symbolUtil'
import { countDecimals, hashCode, toNumberInputValue, toNumberWithSmartPrecision } from '../../util/util'

import Popup from '../common/popup/Popup'
import Checkbox from '../common/checkbox/Checkbox'
import Toggle from '../common/toggle/Toggle'
import SearchSelect from '../common/searchSelect/SearchSelect'
import SaveButton from '../common/saveButton/SaveButton'

import { SYMBOLS_WITH_MUTLIPLIER_IN_BTC } from '../../configs/tradingConfig'
import { OrderItem, sendManualOrder } from './tradingAction'
import { updateManualOrderGlobalMarginRatioThreshold } from '../profile/profileAction'
import { ORDER_EDITOR_MULTIPLE_ACCOUNT_BLACKLIST } from '../../configs/config'

export const MULTIPLE_ACCOUNTS = 'MULTIPLE_ACCOUNTS' 

const getSymbolGroupId = (symbolName='') => {
    let groupId = ''
    if (!_.isEmpty(symbolName)) {
        const { quote, exchangeName, instrumentType } = getSymbolAttributeByName(symbolName)
        groupId = exchangeName
        if (exchangeName === 'PHEMEX' && instrumentType === INSTRUMENT_TYPES.SWAP) {
            groupId = `${exchangeName}_${symbolName === 'btc_usd_PHEMEX_SWAP' ? 'BTC' : 'NOT_BTC'}_SWAP`
        } else if (exchangeName === 'BNBFUTA') {
            groupId = `${exchangeName}_${quote}`
        }
    }
    return groupId
}

const getLocalStorageAccountListStringBySymbolName = (symbolName='') => {
    const symbolGroupId = getSymbolGroupId(symbolName)
    const accountListString = !_.isEmpty(symbolGroupId) && _.has(localStorage, `ORDER_EDITOR_${symbolGroupId}_ACCOUNT_LIST_STRING`)
        ? localStorage[`ORDER_EDITOR_${symbolGroupId}_ACCOUNT_LIST_STRING`]
        : ''
    return accountListString
}

export const updateLocalStorageAccountListStringBySymbolName = (symbolName='', accountListString='') => {
    const symbolGroupId = getSymbolGroupId(symbolName)
    localStorage[`ORDER_EDITOR_${symbolGroupId}_ACCOUNT_LIST_STRING`] = accountListString.trim()
}

const getSessionStorageLastRotatedAccountNameBySymbolName = (symbolName='') => {
    const symbolGroupId = getSymbolGroupId(symbolName)
    const accountName = !_.isEmpty(symbolGroupId) && _.has(sessionStorage, `ORDER_EDITOR_${symbolGroupId}_LAST_ROTATED_ACCOUNT_NAME`)
        ? sessionStorage[`ORDER_EDITOR_${symbolGroupId}_LAST_ROTATED_ACCOUNT_NAME`]
        : ''
    return accountName
}

const updateSessionStorageLastRotatedAccountNameBySymbolName = (symbolName='', accountName) => {
    const symbolGroupId = getSymbolGroupId(symbolName)
    sessionStorage[`ORDER_EDITOR_${symbolGroupId}_LAST_ROTATED_ACCOUNT_NAME`] = accountName
}

class OrderEditor extends Component {
    constructor (props) {
        super(props)

        this.sides = {
            BUY: 'BUY',
            SELL: 'SELL'
        }
        this.types = {
            OPEN: 'OPEN',
            CLOSE : 'CLOSE'
        }
        this.initialConfig = {
            symbolName: null,
            accountName: null,
            accountListString: !_.isEmpty(props.config.symbolName) ? getLocalStorageAccountListStringBySymbolName(props.config.symbolName) : '',
            price: null,
            unit: null,
            postOnly: true,
            reduceOnly: false,
            marginTrade: false,
            crossMarginTrade: false,
            leverage: 0
        }

        this.initialMultiOrder = {
            enabled: false,
            priceDiff: null,
            unitDiff: 0,
            unitMaxDeviation: 0,
            steps: 1,
            interval: 0,
            shouldRotateAccounts: true
        }
 
        this.state = {
            side:  Object.keys(this.sides)[0],
            type: Object.keys(this.types)[0],
            ...this.initialConfig,
            multiOrder: this.initialMultiOrder,
            sentOrderSize: null,
            timeoutOrderSize: null,
            failedOrderSize: null,
            message: null,
            globalMarginRatioThresholdInput: null,
            isSendingOrder: false,
            isSavingSmartAccountVariables: false,
            isSavedSmartAccountVariables: false
        }

        this.unitInputNode = null
        this.sendingOrderTimeouts = {}
        this._mounted = false
        this.submitOrdersId = null
        this.orderLogs = {}
    }

    static getDerivedStateFromProps (props) {
        const { config } = props
        const newState = {}
        if (!_.isNil(config.price)) {
            newState.price = config.price
        }
        if (!_.isNil(config.unit)) {
            newState.unit = config.unit
        }
        return !_.isEmpty(newState) ? newState : null
    }

    componentDidMount () {
        this._mounted = true
    }

    componentDidUpdate (prevProps) {
        const { config: prevConfig } = prevProps
        const { config } = this.props
        const { symbolName } = this.state
        if (!_.isEqual(prevConfig.symbolName, config.symbolName) && !_.isNil(config.symbolName) && !_.isEqual(config.symbolName, symbolName)) {
            this._handleUpdateSymbolName(config.symbolName)
        }
    }

    componentWillUnmount () {
        this._mounted = false
        _.forEach(this.sendingOrderTimeouts, timeout => {
            if (timeout) {
                window.clearTimeout(timeout)
            }
        })
    }

    shouldHaveReduceOnly (symbolName) {
        let result = false
        if (symbolName) {
            const { exchangeName, instrumentType } = getSymbolAttributeByName(symbolName)
            result = EXCHANGES_CAN_USE_REDUCE_ONLY.includes(exchangeName) && instrumentType !== INSTRUMENT_TYPES.SPOT
        }
        return result
    }

    shouldHaveMarginTrade (symbolName) {
        let result = false
        if (symbolName) {
            const { instrumentType, exchangeName } = getSymbolAttributeByName(symbolName)
            result = instrumentType === INSTRUMENT_TYPES.SPOT && exchangeName !== 'BINANCE'
        }
        return result
    }

    shouldHaveCrossMarginTrade (symbolName) {
        let result = false
        if (symbolName) {
            const { instrumentType, exchangeName } = getSymbolAttributeByName(symbolName)
            result = instrumentType === INSTRUMENT_TYPES.SPOT && ['HUOBI', 'BINANCE'].includes(exchangeName)
        }
        return result
    }

    shouldHaveLeverage (symbolName) {
        let result = false
        if (symbolName) {
            const { instrumentType } = getSymbolAttributeByName(symbolName)
            result = [INSTRUMENT_TYPES.FUTURE, INSTRUMENT_TYPES.SWAP].includes(instrumentType)
        }
        return result
    }

    _getDefaultLeverage ({ symbolName='', accountName='' }) {
        const { positions } = this.props
        const { exchangeName } = getSymbolAttributeByName(symbolName)
        const positionItem = accountName === MULTIPLE_ACCOUNTS 
            ? _.find(positions, { product_name: symbolName }) 
            : _.find(positions, { account_name: accountName, product_name: symbolName })
        return !this.shouldHaveLeverage(symbolName) ? 0
            : exchangeName === 'BITFINEX' ? 10 
            : !_.isNil(positionItem) ? Number(positionItem.leverage)
            : 0
    }
    
    getClientOrderId (symbolName) {
        const { exchangeName } = getSymbolAttributeByName(symbolName)
        const clientOrderId = _.takeRight(uuidv4().split('-'), 2).join('')
        return ['HUOBIFUT', 'COINFLEX', 'DYDX', 'BITFINEX'].includes(exchangeName)
            ? (Math.abs(hashCode(clientOrderId))).toString()
            : `ATWEB${clientOrderId}`
    }

    getAccountNamesBySymbol (symbolName) {
        const { accountItems } = this.props
        const accountNames = []
        if (symbolName) {
            const { exchangeName } = getSymbolAttributeByName(symbolName)
            _.forEach(accountItems, accountItem => {
                if (accountItem.exchange_name === exchangeName) {
                    accountNames.push(accountItem.account_name)
                }
            })
        }
        return accountNames
    }

    _shouldAccountBeBlackListedInMultipleSelection (accountName, symbolName) {
        const { accountItems } = this.props
        const { exchangeName, instrumentType } = getSymbolAttributeByName(symbolName)
        let shouldDisable = ORDER_EDITOR_MULTIPLE_ACCOUNT_BLACKLIST.includes(accountName)
        if (!shouldDisable && exchangeName === 'PHEMEX' && instrumentType === INSTRUMENT_TYPES.SWAP) {
            const accountItem = accountItems[accountName]
            if (_.isNil(accountItem)) {
                shouldDisable = true
            } else if (symbolName === 'btc_usd_PHEMEX_SWAP' && accountName.includes('_usd')) {
                shouldDisable = true
            } else if (symbolName !== 'btc_usd_PHEMEX_SWAP' && (accountName.includes('_trader') || accountItem.is_main === '1')) {
                shouldDisable = true
            }
        }
        return shouldDisable
    }

    _getFilteredAccountListString (accountListString='') {
        const { accountItems } = this.props
        const { symbolName, accountName } = this.state
        let filteredAccountListString = ''
        if (!_.isEmpty(symbolName) && accountName === MULTIPLE_ACCOUNTS) {
            const { exchangeName } = getSymbolAttributeByName(symbolName)
            const filteredSmartAccountNames = _.filter(accountListString.split(','), accountName => {
                const accountItem = accountItems[accountName]
                return !_.isNil(accountItem) && accountItem.exchange_name === exchangeName
                    && !this._shouldAccountBeBlackListedInMultipleSelection(accountName, symbolName)
            })
            if (!_.isEmpty(filteredSmartAccountNames)) {
                filteredAccountListString = filteredSmartAccountNames.join(',')
            }
        }
        return filteredAccountListString
    }

    handleClickSaveSmartAccountVariablesButton () {
        const { dispatch } = this.props
        const { globalMarginRatioThresholdInput } = this.state
        this.setState({ isSavingSmartAccountVariables: true })
        dispatch(updateManualOrderGlobalMarginRatioThreshold(globalMarginRatioThresholdInput))
        .then(response => {
            if (response && response.status === 200) {
                this.setState({ isSavedSmartAccountVariables: true })
            }
        })
        .finally(() => {
            this.setState({ isSavingSmartAccountVariables: false })
        })
    }

    _handleUpdateSymbolName (newSymbolName='') {
        const { onChangeConfig } = this.props
        const { accountName, symbolName } = this.state

        if (!_.isEmpty(newSymbolName) && !_.isEqual(symbolName, newSymbolName)) {
            const { exchangeName } = getSymbolAttributeByName(newSymbolName)
            const newAccountName = accountName === MULTIPLE_ACCOUNTS || this.getAccountNamesBySymbol(newSymbolName).includes(accountName) ? accountName : null
            const newAccountListString = getLocalStorageAccountListStringBySymbolName(newSymbolName)
            const newConfig = {
                symbolName: newSymbolName,
                accountName: newAccountName,
                accountListString: newAccountListString,
                price: null,
                unit: null,
                postOnly: true,
                reduceOnly: false,
                marginTrade: false,
                crossMarginTrade: false,
                leverage: this._getDefaultLeverage({ symbolName: newSymbolName, accountName }),
                multiOrder: dotProp.set(this.initialMultiOrder, 'interval', ['FTX', 'BNBFUTA', 'BINANCE'].includes(exchangeName) ? 150 : 0)
            }
            onChangeConfig(_.pick(newConfig, ['symbolName', 'accountName', 'accountListString', 'price', 'unit']))
            this.setState(newConfig)
        }
    }

    async handleClickSubmitButton () {
        const { dispatch, symbolItems, positions } = this.props
        const { symbolName, side, type, accountName, accountListString, price, unit, postOnly, reduceOnly, 
            marginTrade, crossMarginTrade, leverage, multiOrder, isSendingOrder } = this.state

        if (!isSendingOrder) {
            this.setState({ 
                sentOrderSize: null,
                timeoutOrderSize: null,
                failedOrderSize: null
            })
            const validAccountNames = this.getAccountNamesBySymbol(symbolName)
            const filteredAccountListString = this._getFilteredAccountListString(accountListString)

            let orderInterval = 0
            if (!symbolName) {
                this.setState({ message: 'Symbol is missing' })
            } else if (!accountName || (accountName !== MULTIPLE_ACCOUNTS && !validAccountNames.includes(accountName))) {
                this.setState({ message: 'Account is invalid' })
            } else if (accountName === MULTIPLE_ACCOUNTS && _.isEmpty(filteredAccountListString)) {
                this.setState({ message: 'Multiple Account List is empty' })
            } else if (!price || Number(price) <= 0) {
                this.setState({ message: 'Price is invalid' })
            } else if (!unit || Number(unit) <= 0) {
                this.setState({ message: 'Unit is invalid' })
            } else if (multiOrder.enabled && (_.isEmpty(_.toString(multiOrder.priceDiff)) || Number(multiOrder.priceDiff) < 0)) {
                this.setState({ message: 'Multi-Order Price Diff is invalid' })
            } else if (multiOrder.enabled && Number(multiOrder.unitMaxDeviation) < 0) {
                this.setState({ message: 'Multi-Order Unit Deviation is invalid' })
            } else if (multiOrder.enabled && (!multiOrder.steps || !multiOrder.steps > 0)) {
                this.setState({ message: 'Multi-Order Steps is invalid' })
            } else if (multiOrder.enabled && (!_.isNumber(multiOrder.interval) || multiOrder.interval < 0)) {
                this.setState({ message: 'Multi-Order Interval is invalid' })
            } else {
                const { switchType, exchangeName } = getSymbolAttributeByName(symbolName)
                const symbolItem = symbolItems[symbolName]
                const orderSide = side + (switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE ? `_${type}` : '')
                let orders = []
                if (!multiOrder.enabled) {
                    if (Number(price) > 0 && Number(unit) > 0) {
                        orders.push(OrderItem({
                            client_order_id: this.getClientOrderId(symbolName),
                            user: MANUAL_ORDER_PROFILE.user,
                            profile: MANUAL_ORDER_PROFILE.name,
                            account_name: accountName === MULTIPLE_ACCOUNTS ? filteredAccountListString : accountName,
                            price: price,
                            qty: unit,
                            symbol: symbolName,
                            side: orderSide,
                            postonly: postOnly,
                            reduceonly: reduceOnly,
                            margin: marginTrade,
                            cross_margin: crossMarginTrade,
                            leverage: parseInt(leverage)
                        }))
                    }
                } else {
                    const pricePrecision = getPricePrecisionBySymbolItem(symbolItem)
                    const symbolTickSize = _.has(symbolItem, 'tick_size') ? Number(symbolItem.tick_size) : 0
                    const orderQtyPrecision = Number(symbolTickSize) > 0 
                        ? countDecimals(symbolTickSize) 
                        : Math.max(countDecimals(unit), countDecimals(multiOrder.unitDiff), countDecimals(multiOrder.unitMaxDeviation))
                    const filteredAccountNames = !_.isEmpty(filteredAccountListString) ? filteredAccountListString.split(',') : []
                    const lastRotatedAccountName = getSessionStorageLastRotatedAccountNameBySymbolName(symbolName)
                    const accountNameSize = _.size(filteredAccountNames)
                    let nextRotatedAccountNameIndex = (_.indexOf(filteredAccountNames, lastRotatedAccountName) + 1) % accountNameSize

                    orderInterval = Math.max(multiOrder.interval, 0)

                    Array(multiOrder.steps).fill(null).forEach((item, index) => {
                        const orderPrice = price + (side === 'BUY' ? -1 : 1) * multiOrder.priceDiff * index
                        const orderQty =  Math.max(0, Number(symbolTickSize),
                            unit + (multiOrder.unitDiff || 0) * index + (Number(multiOrder.unitMaxDeviation) > 0 ? _.random(-Number(multiOrder.unitMaxDeviation), Number(multiOrder.unitMaxDeviation), true) : 0))
                        const orderAccountName = accountName === MULTIPLE_ACCOUNTS ? (multiOrder.shouldRotateAccounts ? filteredAccountNames[nextRotatedAccountNameIndex] : filteredAccountListString) : accountName
                        
                        let orderLeverage = parseInt(leverage)
                        if (accountName === MULTIPLE_ACCOUNTS && multiOrder.shouldRotateAccounts && this.shouldHaveLeverage(symbolName)) {
                            const positionItem = _.find(positions, { account_name: orderAccountName, product_name: symbolName })
                            if (positionItem && _.has(positionItem, 'leverage') && exchangeName !== 'BITFINEX') {
                                orderLeverage = Number(positionItem.leverage)
                            }
                        }
                        
                        if (orderPrice > 0 && orderQty > 0) {
                            orders.push(OrderItem({
                                client_order_id: this.getClientOrderId(symbolName),
                                user: MANUAL_ORDER_PROFILE.user,
                                profile: MANUAL_ORDER_PROFILE.name,
                                account_name: orderAccountName,
                                price: toNumberWithSmartPrecision({ number: orderPrice, defaultPrecision: pricePrecision }),
                                qty: toNumberWithSmartPrecision({ number: orderQty, defaultPrecision: orderQtyPrecision }),
                                symbol: symbolName,
                                side: orderSide,
                                postonly: postOnly,
                                reduceonly: reduceOnly,
                                margin: marginTrade,
                                cross_margin: crossMarginTrade,
                                leverage: orderLeverage
                            }))

                            if (multiOrder.shouldRotateAccounts) {
                                updateSessionStorageLastRotatedAccountNameBySymbolName(symbolName, filteredAccountNames[nextRotatedAccountNameIndex])
                                nextRotatedAccountNameIndex = (nextRotatedAccountNameIndex + 1) % accountNameSize
                            }
                        }
                    })
                }

                if (!_.isEmpty(orders)) {
                    const newSubmitOrdersId = uuidv4()
                    this.submitOrdersId = newSubmitOrdersId
                    this.orderLogs = {}
                    this.sendingOrderTimeouts = {}
                    this.setState({ 
                        message: null,
                        sentOrderSize: 0,
                        timeoutOrderSize: 0,
                        failedOrderSize: 0,
                        isSendingOrder: true
                    })
        
                    for await (const [index, orderItem] of orders.entries()) {
                        if (index > 0 && orderInterval > 0) {
                            await new Promise(resolve => setTimeout(resolve, orderInterval))
                        }
                        if (this._mounted  && this.submitOrdersId === newSubmitOrdersId && this.state.isSendingOrder) {
                            this.sendingOrderTimeouts[index] = setTimeout(() => {
                                if (this._mounted && this.submitOrdersId === newSubmitOrdersId) {
                                    delete this.sendingOrderTimeouts[index]
                                    if (_.has(this.orderLogs, index)) {
                                        this.orderLogs[index].is_time_out = true
                                    }
                                    this.setState(prevState => {
                                        const newTimeoutOrderSize = prevState.timeoutOrderSize + 1
                                        return {
                                            timeoutOrderSize: newTimeoutOrderSize,
                                            isSendingOrder: (prevState.sentOrderSize + newTimeoutOrderSize >= _.size(orders)) ? false : prevState.isSendingOrder 
                                        }
                                    })
                                }
                            }, 10000)
                            this.orderLogs[index] = {
                                ...orderItem,
                                send_request_timestamp: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
                                receive_response_timestamp: null,
                                is_time_out: false
                            }
                            dispatch(sendManualOrder(orderItem))
                            .catch(error => {
                                if (this._mounted && this.submitOrdersId === newSubmitOrdersId) {
                                    this.setState(prevState => {
                                        return {
                                            message: !_.isNil(prevState.message) ? prevState.message : error.toString(),
                                            failedOrderSize: prevState.failedOrderSize + 1
                                        }
                                    })
                                }
                            })
                            .finally(() => {
                                if (this._mounted && this.submitOrdersId === newSubmitOrdersId) {
                                    const isTimeout = !_.has(this.sendingOrderTimeouts, index)
                                    if (!isTimeout) {
                                        window.clearTimeout(this.sendingOrderTimeouts[index])
                                    }
                                    if (_.has(this.orderLogs, index)) {
                                        this.orderLogs[index].receive_response_timestamp = moment().format('YYYY-MM-DD HH:mm:ss.SSS')
                                    }
                                    this.setState(prevState => {
                                        const newSentOrderSize = prevState.sentOrderSize + 1
                                        const newTimeoutOrderSize = prevState.timeoutOrderSize + (isTimeout ? -1 : 0)
                                        return {
                                            sentOrderSize: newSentOrderSize,
                                            timeoutOrderSize: newTimeoutOrderSize,
                                            isSendingOrder: (newSentOrderSize + newTimeoutOrderSize >= _.size(orders)) ? false : prevState.isSendingOrder 
                                        }
                                    })
                                }
                            })
                        } else {
                            break
                        }
                    }
                }
            }
        }
    }

    Row (name, component, className) {
        return (
            <div className={'order-editor--row' + (className ? ` ${className}` : '')}>
                <div className='order-editor--row--name'>{name}</div>
                <div className='order-editor--row--body'>{component}</div>
            </div>
        )
    }

    SymbolRow () {
        const { symbolName } = this.state
        const { symbolItems } = this.props
        const filteredSymbolItems = _.filter(symbolItems, symbolItem => {
            const { instrumentType } = getSymbolAttributeByName(symbolItem.symbol_name)
            return instrumentType !== INSTRUMENT_TYPES.INDEX && symbolItem.trading === '1'
        })
        const symbolOptions = _.map(filteredSymbolItems, symbolItem => {
            return {
                value: symbolItem.symbol_name,
                name: symbolItem.symbol_name
            }
        })

        const component = (
            <SearchSelect className='order-editor--symbol' 
                value={symbolName}
                placeholder={'Select Symbol'}
                options={symbolOptions}
                onChange={(newOption) => {
                    this._handleUpdateSymbolName(newOption.value)
                }} />
        )
        return this.Row('Symbol', component)
    }

    AccountRow () {
        const { onChangeConfig } = this.props
        const { accountName, symbolName } = this.state
        let accountOptions = []
        if (symbolName) {
            const accountNames = this.getAccountNamesBySymbol(symbolName)
            accountOptions = accountNames.map(accountName => {
                return {
                    value: accountName,
                    name: accountName
                }
            })
            accountOptions.unshift({
                value: MULTIPLE_ACCOUNTS,
                name: 'Multiple Accounts'
            })
        }

        const component = (
            <Fragment>
                <SearchSelect className='order-editor--account'
                    value={accountName} 
                    options={accountOptions} 
                    disabled={_.isEmpty(symbolName)} 
                    onChange={(newOption) => {
                        const newAccountName = newOption.value
                        const accountListString = getLocalStorageAccountListStringBySymbolName(symbolName)
                        const newConfig = {
                            accountName: newAccountName,
                            accountListString,
                            leverage: this._getDefaultLeverage({ symbolName, accountName: newAccountName })
                        }
                        onChangeConfig(_.pick(newConfig, ['accountName', 'accountListString']))
                        this.setState(newConfig)                        
                    }} />
                {accountName === MULTIPLE_ACCOUNTS && this.MultipleAccounts()}
            </Fragment>
        )
        return this.Row('Account', component)
    }

    MultipleAccounts () {
        const { accountItems, orderEditorVariables, onChangeConfig } = this.props
        const { symbolName, accountListString, globalMarginRatioThresholdInput, isSavingSmartAccountVariables } = this.state
        const { exchangeName } = getSymbolAttributeByName(symbolName)
        const filteredAccountListString = this._getFilteredAccountListString(accountListString)
        const filteredSmartAccountNames = !_.isEmpty(filteredAccountListString) ? filteredAccountListString.split(',') : []
        const accountNames = _.filter(accountItems, accountItem => accountItem.exchange_name === exchangeName 
            && !this._shouldAccountBeBlackListedInMultipleSelection(accountItem.account_name, symbolName))
            .map(accountItem => accountItem.account_name)
        const accountNameGroups = _.groupBy(accountNames, accountName => {
            return _.has(accountItems, `${accountName}.portfolio_name`) ? accountItems[accountName].portfolio_name : 'Unkown Portfolio'
        })
        const isAllSelected = _.size(accountNames) === _.size(filteredSmartAccountNames)

        return (
            <Popup
                on={'click'} 
                className='order-editor--multiple-accounts'
                trigger={(<button className='order-editor--multiple-accounts--trigger'>{`${_.size(filteredSmartAccountNames)} Selected Account${_.size(filteredSmartAccountNames) > 1 ? 's' :''}`}</button>)}
                onOpen={() => {
                    this.setState({ 
                        globalMarginRatioThresholdInput: orderEditorVariables.global_margin_ratio_threshold,
                        isSavedSmartAccountVariables: false
                    })
                }}>
                <div className='order-editor--multiple-accounts--header'>{'Select Accounts'}</div>
                <div className='order-editor--multiple-accounts--main' onClick={(e) => { e.stopPropagation() }}>
                    <div className='order-editor--multiple-accounts--config-row variables clearfix hidden'>
                        <label>{'Global Margin Ratio Theshold'}</label>
                        <input className='order-editor--multiple-accounts--variable-input'
                            value={_.isNumber(globalMarginRatioThresholdInput) ? globalMarginRatioThresholdInput : ''} 
                            disabled
                            placeholder={'0.15'}
                            type={'number'}
                            onChange={(e) => { 
                                this.setState({ 
                                    globalMarginRatioThresholdInput: toNumberInputValue(e.target.value),
                                    isSavedSmartAccountVariables: false
                                }) 
                            }} />
                        <SaveButton className='order-editor--multiple-accounts--save-variable-button'
                            isSaving={isSavingSmartAccountVariables}
                            // isSaved={isSavedSmartAccountVariables}
                            // disabled={orderEditorVariables.global_margin_ratio_threshold === globalMarginRatioThresholdInput || Number(globalMarginRatioThresholdInput) === 0}
                            disabled
                            text={'SAVE'}
                            isSavingText={'SAVING'}
                            isSavedText={'SAVED'}
                            onClick={() => { this.handleClickSaveSmartAccountVariablesButton() }} />
                        {/* <button className='order-editor--multiple-accounts--save-variable-button' 
                            disabled={orderEditorVariables.global_margin_ratio_threshold === globalMarginRatioThresholdInput || Number(globalMarginRatioThresholdInput) === 0 || isSavingSmartAccountVariables}
                            onClick={() => { this.handleClickSaveSmartAccountVariablesButton() }}>
                            {isSavingSmartAccountVariables ? 'SAVING' : 'SAVE'}
                        </button> */}
                    </div>
                    <div className='order-editor--multiple-accounts--config-row accounts clearfix'>
                        <label>{'Accounts'}</label>
                        <div className='order-editor--multiple-accounts--items'>
                            <div className='order-editor--multiple-accounts--toggle-all'>
                                <button onClick={() => {
                                    if (isAllSelected) {
                                        updateLocalStorageAccountListStringBySymbolName(symbolName, '')
                                    } else {
                                        updateLocalStorageAccountListStringBySymbolName(symbolName, accountNames.join(','))
                                    }
                                    
                                    const newAccountListString = getLocalStorageAccountListStringBySymbolName(symbolName)
                                    onChangeConfig({ accountListString: newAccountListString })
                                    this.setState({ accountListString: newAccountListString })
                                }}>{isAllSelected ? 'Unselect All' : 'Select All'}</button>
                            </div>
                            <div className='order-editor--multiple-accounts--portfolios'>
                                {_.map(accountNameGroups, (portfolioAccountNames, portfolioName) => {
                                    const isAllPortfolioAccountNameSelected = !_.isEmpty(portfolioAccountNames) && _.every(portfolioAccountNames, portfolioAccountName => filteredSmartAccountNames.includes(portfolioAccountName))
                                    return (
                                        <div className='order-editor--multiple-accounts--portfolio' key={portfolioName}>
                                            <div className='order-editor--multiple-accounts--portfolio--header'>
                                                <label>{portfolioName}</label>
                                                <button onClick={() => {
                                                    const newFilteredSmartAccountNames = isAllPortfolioAccountNameSelected 
                                                        ? _.without(filteredSmartAccountNames, ...portfolioAccountNames)
                                                        : _.union(filteredSmartAccountNames, portfolioAccountNames)
                                                    updateLocalStorageAccountListStringBySymbolName(symbolName, newFilteredSmartAccountNames.join(','))
                                                    
                                                    const newAccountListString = getLocalStorageAccountListStringBySymbolName(symbolName)
                                                    onChangeConfig({ accountListString: newAccountListString })
                                                    this.setState({ accountListString: newAccountListString })
                                                }}>{isAllPortfolioAccountNameSelected ? 'Unselect All' : 'Select All'}</button>
                                            </div>
                                            <div className='order-editor--multiple-accounts--portfolio--main'>
                                                {_.map(portfolioAccountNames.sort(), accountName => {
                                                    const isBlackListed = this._shouldAccountBeBlackListedInMultipleSelection(accountName, symbolName)
                                                    return (
                                                        <div className='order-editor--multiple-accounts--item' key={accountName} 
                                                            title={isBlackListed ? 'Black listed' : null}>
                                                            <span className={isBlackListed ? 'black-listed' : null}>{accountName}</span>
                                                            <Checkbox checked={filteredSmartAccountNames.includes(accountName)}
                                                                disabled={isBlackListed}
                                                                onChange={(newChecked) => {
                                                                    const newFilteredSmartAccountNames = newChecked ? _.union(filteredSmartAccountNames, [accountName]) : _.without(filteredSmartAccountNames, accountName)
                                                                    updateLocalStorageAccountListStringBySymbolName(symbolName, newFilteredSmartAccountNames.join(','))
                                                                    
                                                                    const newAccountListString = getLocalStorageAccountListStringBySymbolName(symbolName)
                                                                    onChangeConfig({ accountListString: newAccountListString })
                                                                    this.setState({ accountListString: newAccountListString })
                                                                }} />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        )
    }

    SideRow () {
        const { side, type, symbolName } = this.state
        const switchType = symbolName ? getSymbolAttributeByName(symbolName).switchType : null
        const component = (
            <div className='order-editor--sides'>
                <div className='order-editor--sides--main'>
                    {_.map(this.sides, (name, value) => (
                        <button className={`order-editor--sides--selector ${value}` + (side === value ? ' active' : '')}
                            key={value}
                            onClick={() => { this.setState({ side: value }) }}>
                            {name}
                        </button>
                    ))}
                </div>
                {switchType === SYMBOL_SWITCH_TYPES.BUY_SELL_TO_OPEN_CLOSE && 
                <div className='order-editor--sides--type-selectors'>
                    {_.map(this.types, (name, value) => (
                        <button className={'order-editor--sides--type-selector' + (type === value ? ' active' : '')} 
                            key={value}
                            onClick={() => { this.setState({ type: value }) }}>
                            {name}
                        </button>
                    ))}
                </div>}
            </div>
        )
        return this.Row('Side', component)
    }

    PriceRow () {
        const { onChangeConfig } = this.props
        const { price }  = this.state
        const component = (
            <input className='order-editor--price' 
                type={'number'}
                value={!_.isNil(price) ? price : ''} 
                onChange={(e) => { 
                    const newValue = toNumberInputValue(e.target.value)
                    onChangeConfig({ price: newValue })
                    this.setState({ price: newValue })
                }} />
        )
        return this.Row('Price', component)
    }

    UnitRow () {
        const { unit, symbolName }  = this.state
        const { symbolItems, onChangeConfig } = this.props
        const symbolItem = symbolItems[symbolName]
        
        let placeholder = ''
        if (symbolItem) {
            const { base, quote, instrumentType } = getSymbolAttributeByName(symbolName)
            const { trading_in_notional: tradingInNotional, multiplier } = symbolItem
            placeholder = `${instrumentType === INSTRUMENT_TYPES.REPO ? '1' : multiplier} ${SYMBOLS_WITH_MUTLIPLIER_IN_BTC.includes(symbolName) ? 'BTC' : tradingInNotional === '1' ? quote : base} per Unit`
        }
        const component = (
            <Fragment>
                <input className='order-editor--unit' 
                    ref={(node) => { this.unitInputNode = node }}
                    type={'number'}
                    value={!_.isNil(unit) ? unit : ''} 
                    onChange={(e) => { 
                        const newValue = toNumberInputValue(e.target.value)
                        onChangeConfig({ unit: newValue })
                        this.setState({ unit: newValue })
                    }} />
                {(_.isNil(unit) || unit === '') && <span className='order-editor--unit--placeholder' onClick={() => { this.unitInputNode.focus() }}>{placeholder}</span>}
            </Fragment>
        )
        return this.Row('Unit', component)
    }

    PostOnlyRow () {
        const { postOnly } = this.state
        const component = (
            <Toggle className='order-editor--post-only'
                checked={postOnly}
                trueText={'Enabled'}
                falseText={'Disabled'}
                onChange={(newPostOnly) => { this.setState({ postOnly: newPostOnly }) }} />
        )
        return this.Row('Post Only', component)
    }

    ReduceOnlyRow () {
        const { reduceOnly } = this.state
        const component = (
            <Toggle className='order-editor--reduce-only'
                checked={reduceOnly}
                trueText={'Enabled'}
                falseText={'Disabled'}
                onChange={(newReductOnly) => { this.setState({ reduceOnly: newReductOnly }) }} />
        )
        return this.Row('Reduce Only', component)
    }

    MarginTradeRow () {
        const { marginTrade } = this.state
        const component = (
            <Toggle className='order-editor--margin-trade'
                checked={marginTrade}
                trueText={'Enabled'}
                falseText={'Disabled'}
                onChange={(newMarginTrade) => { this.setState({ marginTrade: newMarginTrade }) }} />
        )
        return this.Row('Isolated Margin', component)
    }

    CrossMarginTradeRow () {
        const { crossMarginTrade } = this.state
        const component = (
            <Toggle className='order-editor--cross-margin-trade'
                checked={crossMarginTrade}
                trueText={'Enabled'}
                falseText={'Disabled'}
                onChange={(newCrossMargin) => { 
                    this.setState({ 
                        crossMarginTrade: newCrossMargin
                    }) 
                }} />
        )
        return this.Row('Cross Margin', component)
    }

    LeverageRow () {
        const { leverage, accountName } = this.state
        const component = (
            <input className='order-editor--leverage' 
                type={'number'}
                value={!_.isNil(leverage) ? leverage : ''} 
                onChange={(e) => { 
                    const newValue = toNumberInputValue(e.target.value)
                    this.setState({ leverage: newValue })
                }} />
        )
        return this.Row(accountName === MULTIPLE_ACCOUNTS ? 'DFLT LVRG' : 'Leverage', component)
    }

    MultiOrders () {
        const { multiOrder, symbolName, accountName } = this.state
        const { symbolItems } = this.props
        const symbolItem = symbolItems[symbolName]
        
        const multiOrderEnabled = (
            <Toggle className='order-editor--multi-order-toggle' 
                checked={multiOrder.enabled}
                trueText={'Enabled'}
                falseText={'Disabled'}
                onChange={(newMultiOrderEnabled) => { 
                    this.setState({ 
                        multiOrder: dotProp.set(multiOrder, 'enabled', newMultiOrderEnabled)
                    }) 
                }} />
        )  
        const multiOrderPriceDiff = (
            <input className='order-editor--multi-order-price-diff' 
                type={'number'}
                placeholder={_.has(symbolItem, 'price_tick') ? symbolItem.price_tick : null}
                value={!_.isNil(multiOrder.priceDiff) ? multiOrder.priceDiff : ''} 
                min={0}
                onChange={(e) => {
                    const newValue = toNumberInputValue(e.target.value)
                    this.setState({ multiOrder: dotProp.set(multiOrder, 'priceDiff', newValue) })
                }} />
        )
        const multiOrderUnitDiff = (
            <input className='order-editor--multi-order-unit-diff'
                type={'number'}
                value={!_.isNil(multiOrder.unitDiff) ? multiOrder.unitDiff : ''}
                onChange={(e) => {
                    const newValue = toNumberInputValue(e.target.value)
                    this.setState({ multiOrder: dotProp.set(multiOrder, 'unitDiff', newValue) })
                }} />
        )
        const multiOrderUnitMaxDeviation = (
            <input className='order-editor--multi-order-max-deviation'
                type={'number'}
                value={!_.isNil(multiOrder.unitMaxDeviation) ? multiOrder.unitMaxDeviation : ''}
                min={0}
                onChange={(e) => {
                    const newValue = toNumberInputValue(e.target.value)
                    this.setState({ multiOrder: dotProp.set(multiOrder, 'unitMaxDeviation', newValue) })
                }} />
        )
        const multiOrderSteps = (
            <input className='order-editor--multi-order-steps'
                type={'number'}
                value={!_.isNil(multiOrder.steps) ? multiOrder.steps : ''} 
                min={1}
                onChange={(e) => {
                    const newValue = toNumberInputValue(e.target.value)
                    this.setState({ multiOrder: dotProp.set(multiOrder, 'steps', newValue) })
                }} />
        )
        const multiOrderInterval = (
            <input className='order-editor--multi-order-interval' 
                type={'number'}
                value={!_.isNil(multiOrder.interval) ? multiOrder.interval : ''} 
                min={0}
                onChange={(e) => {
                    const newValue = toNumberInputValue(e.target.value)
                    this.setState({ multiOrder: dotProp.set(multiOrder, 'interval', newValue) })
                }} />
        )
        const multiOrderAccountRotate = (
            <Checkbox 
                checked={multiOrder.shouldRotateAccounts} 
                disabled
                onChange={(newChecked) => { 
                    this.setState({ multiOrder: dotProp.set(multiOrder, 'shouldRotateAccounts', newChecked) })
                }} />
        )
        return (
            <Fragment>
                {this.Row('Multi Order', multiOrderEnabled, 'multi-order-toggle')}
                {multiOrder.enabled && 
                <Fragment>
                    {this.Row('Price Diff', multiOrderPriceDiff)}
                    {this.Row('Unit Diff', multiOrderUnitDiff)}
                    {this.Row('Unit Max DEV', multiOrderUnitMaxDeviation)}
                    {this.Row('Steps', multiOrderSteps)}
                    {this.Row('Intvl (ms)', multiOrderInterval)}
                    {accountName === MULTIPLE_ACCOUNTS && this.Row('Rotate Acct', multiOrderAccountRotate)}
                </Fragment>}
            </Fragment>
        )
    }

    render () {
        const { shouldHideTitle } = this.props
        const { message, sentOrderSize, timeoutOrderSize, failedOrderSize, isSendingOrder, symbolName, accountName, multiOrder, reduceOnly } = this.state
        const shouldDisableSubmitForSmartAccount = accountName === MULTIPLE_ACCOUNTS && !multiOrder.enabled
        return (
            <div className='order-editor'>
                {!shouldHideTitle && <div className='order-editor--title'>{'New Order'}</div>}
                <div className='order-editor--body'>
                    <div className='order-editor--main'>
                        {this.SymbolRow()}
                        {this.SideRow()}
                        {this.AccountRow()}
                        {this.PriceRow()}
                        {this.UnitRow()}
                        {this.PostOnlyRow()}
                        {(this.shouldHaveReduceOnly(symbolName) || reduceOnly) && this.ReduceOnlyRow()}
                        {this.shouldHaveMarginTrade(symbolName) && this.MarginTradeRow()}
                        {this.shouldHaveCrossMarginTrade(symbolName) && this.CrossMarginTradeRow()}
                        {this.shouldHaveLeverage(symbolName) && this.LeverageRow()}
                        {this.MultiOrders()}
                    </div>
                    {message && <div className='order-editor--message'>
                        <span>{message}</span>
                        <button className='order-editor--message--close-button' 
                            onClick={() => { this.setState({ message: null }) }}><FiX /></button>
                    </div>}
                    {Number(sentOrderSize) > 0 && <div className='order-editor--order-to-send-size'>
                        <div className='order-editor--order-to-send-size--text'>
                            {sentOrderSize}{` order${sentOrderSize > 1 ? 's have' : ' has'} been submitted. `}
                            {Number(failedOrderSize) > 0 && <span className='warning-red'>{`${failedOrderSize} order${failedOrderSize > 1 ? 's have' : ' has'} encountered error. `}</span>}
                            {Number(timeoutOrderSize) > 0 && <span className='warning-yellow'>{`${timeoutOrderSize} order${timeoutOrderSize > 1 ? 's have' : ' has'} not yet received a response for more than 10s, and ${timeoutOrderSize > 1 ? 'their status are' : 'its status is'} unkown. `}</span>}
                            {!isSendingOrder && !_.isEmpty(this.orderLogs) && 
                            <CSVLink 
                                filename={'Order_Logs'}
                                data={Object.values(this.orderLogs)}>
                                <button className='order-editor--download-logs-button'>{'Download Logs'}</button>
                            </CSVLink>}
                        </div>
                        {isSendingOrder 
                        ? <button className='order-editor--order-to-send-size--discard-button' 
                            onClick={() => {
                                this.setState({ isSendingOrder: false })
                            }}>{'Discard'}</button>
                        : <button className='order-editor--order-to-send-size--close-button'
                            onClick={() => {
                                this.setState({ sentOrderSize: null })
                            }}><FiX /></button>}    
                    </div>}
                    <button className='order-editor--submit-button' 
                        disabled={shouldDisableSubmitForSmartAccount || isSendingOrder}
                        onClick={() => { this.handleClickSubmitButton() }}>
                        {shouldDisableSubmitForSmartAccount ? 'Smart Account is not supported yet' : isSendingOrder ? 'Submitting' : 'Submit' }
                    </button>
                </div>
            </div>
        )
    }
}

OrderEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    shouldHideTitle: PropTypes.bool,
    config: PropTypes.shape({
        symbolName: PropTypes.string,
        accountName: PropTypes.string,
        accountListString: PropTypes.string,
        price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        unit: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),

    symbolItems: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    positions: PropTypes.array.isRequired,
    orderEditorVariables: PropTypes.object.isRequired,
    onChangeConfig: PropTypes.func,
}

OrderEditor.defaultProps = {
    shouldHideTitle: false,
    onChangeConfig: () => {}
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items,
        accountItems: state.account.items,
        positions: state.trading.positions,
        orderEditorVariables: state.profile.orderEditorVariables
    }
}

export default connect(mapStateToProps)(OrderEditor)