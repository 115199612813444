import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import uuidv4 from 'uuid/v4'
import arrayMove from 'array-move'

import { SortableContainer, SortableElement } from 'react-sortable-hoc'

import { IoIosArrowDown } from 'react-icons/io'
import FundingTagItem, { FundingTag } from './FundingTagItem'
import Popup from '../common/popup/Popup'
import FundingTagEditor from './FundingTagEditor'

import { fetchFundingTags, updateFundingTags, uploadFundingTags } from './symbolAction'
import { MdPlaylistAdd } from 'react-icons/md'


const SortableFundingTagItem = SortableElement(props => {
    return (
        <FundingTagItem 
            {...props} />
    )
})

const SortableFundingTagList = SortableContainer(({ fundingTags=[], isSavingTags=false, onClickItem=()=>{}, onClickConfirmButton=()=>{}, onClickEditorDeleteButton=()=>{} }) => {
    return (
        <div className='funding-tags--list'>
            {fundingTags.map((fundingTag, index) => {
                const { id } = fundingTag
                return (
                    <SortableFundingTagItem 
                        key={id}
                        index={index} 
                        fundingTag={fundingTag}
                        isSavingTags={isSavingTags}
                        onClickItem={() => { onClickItem(id) }} 
                        onClickEditorConfirm={(newName, newCoins) => { onClickConfirmButton(index, FundingTag({ id, name: newName, coins: newCoins })) }}
                        onClickEditorDelete={() => { onClickEditorDeleteButton(index) }} />
                )
            })}
        </div>
    )
})

class FundingTags extends Component {
    constructor (props) {
        super(props)
        this.state = {
            selectedFundingTagId: props.selectedFundingTagId,
            shouldShowList: false,
            isSavingTags: false,
            addTagPoupCloseId: null
        }

        this._mounted = false
    }

    static getDerivedStateFromProps (props, state) {
        if (!_.isNil(props.selectedFundingTagId) && !_.isEqual(props.selectedFundingTagId, state.selectedFundingTagId)) {
            return { selectedFundingTagId: props.selectedFundingTagId }
        } else {
            return null
        }
    }

    componentDidMount () {
        this._mounted = true
    }

    componentWillUnmount () {
        this._mounted = false
    }

    updateSelectedFundingTagId (newTagId, shouldHideList=true) {
        const { onOnchangeFundingTagId } = this.props
        this.setState({ 
            selectedFundingTagId: newTagId,
            shouldShowList: !shouldHideList
        })
        onOnchangeFundingTagId(newTagId)
    }

    handleClickAddTagConfirmButton (name='', coins=[]) {
        const { isSavingTags } = this.state
        const { dispatch, fundingTags } = this.props
        if (name.length > 0 && _.size(coins) > 0 && !isSavingTags) {
            const newFundingTag = FundingTag({
                id: uuidv4(),
                name,
                coins
            })
            const newFundingTags = fundingTags.concat([newFundingTag])
            this.setState({ isSavingTags: true })
            dispatch(uploadFundingTags(newFundingTags))
            .finally(() => { 
                if (this._mounted) {
                    dispatch(fetchFundingTags())
                    .then(() => {
                        if (this._mounted) {
                            this.setState({ 
                                isSavingTags: false,
                                addTagPoupCloseId: uuidv4()
                            })
                            this.updateSelectedFundingTagId(newFundingTag.id, false)
                        }
                    })
                }
            })
        }
    }

    handleClickUpdateTagConfirmButton (index=0, newFundingTag=FundingTag({})) {
        const { isSavingTags } = this.state
        const { dispatch, fundingTags } = this.props
        if (index >=0 && index < fundingTags.length && !isSavingTags) {
            const newFundingTags = dotProp.set(fundingTags, index, newFundingTag)
            this.setState({ isSavingTags: true })
            dispatch(uploadFundingTags(newFundingTags))
            .finally(() => { 
                dispatch(fetchFundingTags()) 
                if (this._mounted) {
                    this.setState({ isSavingTags: false })
                }
            })
        }
    }

    handleClickDeleteTagButton (index=0) {
        const { dispatch, fundingTags } = this.props
        if (index >=0 && index < fundingTags.length) {
            const newFundingTags = dotProp.delete(fundingTags, index)
            dispatch(uploadFundingTags(newFundingTags))
            .finally(() => { 
                dispatch(fetchFundingTags())
            })
        }
    }

    render () {
        const { selectedFundingTagId, shouldShowList, isSavingTags, addTagPoupCloseId } = this.state
        const { dispatch, fundingTags } = this.props
        const fundingTag = !_.isNil(selectedFundingTagId) ? _.find(fundingTags, { id: selectedFundingTagId }) : null
        return (
            <div className='funding-tags'>
                <div className='funding-tags--current' onClick={() => {
                    this.setState({ shouldShowList: !shouldShowList })
                }}>
                    <label>{'Tag'}</label>
                    <span>{fundingTag ? fundingTag.name : 'ALL'}</span>
                    <IoIosArrowDown />
                </div>
                {shouldShowList && 
                <div className='funding-tags--main'>
                    <div className='funding-tags--main--all' onClick={() => { this.updateSelectedFundingTagId(null) }}>{'ALL'}</div>
                    <Popup
                        className='funding-tags--main--add-tag-popup'
                        closeId={addTagPoupCloseId}
                        trigger={<div className='funding-tags--main--add-tag-button'>
                            <MdPlaylistAdd />
                            {'New Tag'}
                        </div>}
                        align={'horizontal'}
                        on={'click'}>
                        <FundingTagEditor 
                            shouldHaveDeleteButton={false}
                            isSavingTags={isSavingTags}
                            onClickConfirmButton={({ name, coins }) => { this.handleClickAddTagConfirmButton(name, coins) }} />
                    </Popup>
                    <SortableFundingTagList 
                        axis={'y'}
                        pressDelay={200}
                        fundingTags={fundingTags}
                        isSavingTags={isSavingTags}
                        onClickItem={(tagId) => { this.updateSelectedFundingTagId(tagId) }} 
                        onClickConfirmButton={(index, newFundingTag) => { this.handleClickUpdateTagConfirmButton(index, newFundingTag) }}
                        onClickEditorDeleteButton={(index) => { this.handleClickDeleteTagButton(index) }}
                        onSortEnd={({ oldIndex, newIndex }) => {
                            const newFundingTags = arrayMove(fundingTags, oldIndex, newIndex)
                            dispatch(updateFundingTags(newFundingTags))
                            dispatch(uploadFundingTags(newFundingTags))
                        }} />
                </div>}
            </div>
        )
    }

}

FundingTags.propTypes = {
    dispatch: PropTypes.func.isRequired,
    fundingTags: PropTypes.array.isRequired,

    selectedFundingTagId: PropTypes.string,
    onOnchangeFundingTagId: PropTypes.func.isRequired
}

FundingTags.defaultProps = {
    onOnchangeFundingTagId: () => {}
}

function mapStateToProps (state) {
    return {
        fundingTags: state.symbol.fundingTags,
        symbolItems: state.symbol.items
    }
}

export default connect(mapStateToProps)(FundingTags)
