import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'

import { AiOutlineSwap } from 'react-icons/ai'
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized'
import Popup from '../common/popup/Popup'
import { fetchAutoTransfers } from './tradingAction'
import { toNumberWithSmartPrecision } from '../../util/util'

class AutoTransferPopup extends Component {
    constructor (props) {
        super(props)
        this.state = {
            isFetching: false,
            autoTransferIdsShouldShowDetail: {}
        }

        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false
        })
        this.listNode = null
    }

    getAutoTransfers (shouldFetchFromHead=true) {
        const { dispatch } = this.props
        const { isFetching } = this.state
        if (!isFetching) {
            this.setState({ isFetching: true })
            dispatch(fetchAutoTransfers(shouldFetchFromHead))
            .finally(() => {
                this.setState({ isFetching: false })
            })
        }
    }

    DataRow ({className, label, component}) {
        return (
            <div className={'auto-transfer-popup--data-row' + (className ? ` ${className}` : '')}>
                <div className='auto-transfer-popup--data-row--label'>{label}</div>
                <div className='auto-transfer-popup--data-row--component'>{component}</div>
            </div>
        )
    }

    TransferAccount (transferAccount, shouldShowDetail=false) {
        const { accountName, market, pair, detail, netBalance, availableBalance, marginRatio, currency } = transferAccount
        return (
            <div className='auto-transfer-popup--transfer-account'>
                <div className='auto-transfer-popup--transfer-account--main'>
                    <span className='auto-transfer-popup--transfer-account--name'>{accountName}</span>
                    <span className={`auto-transfer-popup--transfer-account--market ${market}`}>{market}</span>
                    {pair && <span className='auto-transfer-popup--transfer-account--pair-name'>{pair}</span>}
                </div>
                {shouldShowDetail && 
                <div className='auto-transfer-popup--transfer-account--detail'>
                    {this.DataRow({
                        className: 'auto-transfer-popup--transfer-account--detail--data',
                        label: 'Timestamp',
                        component: moment(detail.timestamp).format('YYYY-MM-DD HH:mm:ss')
                    })}
                    {this.DataRow({
                        className: 'auto-transfer-popup--transfer-account--detail--data',
                        label: 'Net Bal.',
                        component: `${toNumberWithSmartPrecision({ number: netBalance, shouldReturnLocalString: true })} ${currency}`
                    })}
                    {this.DataRow({
                        className: 'auto-transfer-popup--transfer-account--detail--data',
                        label: 'Available Bal.',
                        component: `${toNumberWithSmartPrecision({ number: availableBalance, shouldReturnLocalString: true })} ${currency}`
                    })}
                    {this.DataRow({
                        className: 'auto-transfer-popup--transfer-account--detail--data',
                        label: 'Margin Ratio',
                        component: (marginRatio * 100).toFixed(2) + '%'
                    })}
                </div>}
            </div>
        )
    }

    renderItem (params, autoTransferItem) {
        const { autoTransferIdsShouldShowDetail } = this.state
        const { index, key, parent, style } = params
        const { _id: id, originTransferAccount, destinationTransferAccount, token, amount, timestamp } = autoTransferItem
        const shouldShowDetail = _.has(autoTransferIdsShouldShowDetail, id)
        return (
            <CellMeasurer
                key={key}
                rowIndex={index}
                columnIndex={0}
                parent={parent} 
                cache={this.cellMeasurerCache}>
                {({ measure, registerChild }) => (
                    <div className='auto-transfer-popup--item'
                        ref={registerChild}
                        style={style}
                        onClick={(e) => {
                            e.stopPropagation()
                            this.setState({
                                autoTransferIdsShouldShowDetail: shouldShowDetail ? dotProp.delete(autoTransferIdsShouldShowDetail, id) : dotProp.set(autoTransferIdsShouldShowDetail, id, 1)
                            })
                            setTimeout(() => { measure() })
                        }}>
                        <div className='auto-transfer-popup--item--main'>
                            {this.DataRow({ 
                                label: 'Timestamp',
                                component: moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
                            })}
                            {this.DataRow({
                                label: 'From',
                                component: this.TransferAccount(originTransferAccount, shouldShowDetail)
                            })}
                            {this.DataRow({
                                label: 'To',
                                component: this.TransferAccount(destinationTransferAccount, shouldShowDetail)
                            })}
                            {this.DataRow({
                                label: 'Amount',
                                component: `${amount} ${token}`
                            })}
                        </div>
                    </div>
                )}
            </CellMeasurer> 
        )
    } 

    render () {
        const { isFetching } = this.state
        const { autoTransfers } = this.props
        return (
            <Popup className='auto-transfer-popup'
                on={'click'}
                trigger={<button className='auto-transfer-popup--trigger'>{<AiOutlineSwap />}</button>}
                onOpen={() => { this.getAutoTransfers(true) }}>
                <div className='auto-transfer-popup--header'>
                    <div className='auto-transfer-popup--title'>{'Auto Transfer Records'}</div>
                </div>
                {(isFetching || !_.isEmpty(autoTransfers)) && <div className='auto-transfer-popup--main'>
                    <AutoSizer>
                        {({ width, height }) => (
                            <List 
                                ref={(node) => { this.listNode = node }}
                                className='auto-transfer-popup--main'
                                width={width}
                                height={height} 
                                rowCount={_.size(autoTransfers)}
                                rowRenderer={params => this.renderItem(params, autoTransfers[params.index])} 
                                rowHeight={this.cellMeasurerCache.rowHeight} 
                                overscanRowCount={5} 
                                scrollToAlignment={'start'}
                                deferredMeasurementCache={this.cellMeasurerCache} 
                                onScroll={({clientHeight, scrollHeight, scrollTop}) => {
                                    if (clientHeight + scrollTop >= scrollHeight) {
                                        this.getAutoTransfers(false)
                                    }
                                }}/>
                        )}
                    </AutoSizer>
                </div>}
                {!isFetching && _.isEmpty(autoTransfers) && <div className='auto-transfer-popup--empty'>{'Empty Records'}</div>}
                {isFetching && <div className='auto-transfer-popup--fetching'>{'Fetching...'}</div>}
            </Popup>
        )
    }
}

AutoTransferPopup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    autoTransfers: PropTypes.array.isRequired
}

function mapStateToProps (state) {
    return {
        autoTransfers: state.trading.autoTransfers
    }
}

export default connect(mapStateToProps)(AutoTransferPopup)