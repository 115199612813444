import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import { getProfileTradingAccountNamesBySymbol } from '../../util/profileUtil'
import { getSymbolAttributeByName, INSTRUMENT_TYPES } from '../../util/symbolUtil'
import { EXCHANGES_CAN_USE_REDUCE_ONLY } from '../../configs/tradingConfig'
import ProfileReduceOnlySwitchItem from './ProfileReduceOnlySwitchItem'

class ProfileReduceOnlySwitches extends Component {
    render () {
        const { profileItem, symbolName, direction, profileRunningState, accountItems } = this.props
        const { exchangeName, instrumentType } = getSymbolAttributeByName(symbolName)
        const symbolTradingAccountNames = getProfileTradingAccountNamesBySymbol(profileItem, symbolName)[direction]
        const filteredReduceOnlySwitchOnItems = _.has(profileRunningState, 'reduceOnlySwitches')
            ? _.filter(profileRunningState.reduceOnlySwitches || [], { symbol: symbolName, side: direction, type: 'REDUCE_ONLY_SWITCH_ON' })
            : []
        const filteredReduceOnlySwitchOnAccountNames = filteredReduceOnlySwitchOnItems.map(item => item.account).filter(accountName => symbolTradingAccountNames.includes(accountName))
        const invalidAccountNames = _.filter(symbolTradingAccountNames, accountName => !_.has(accountItems, accountName))
        
        return (
            <div className='profile-reduce-only-switches'>
                {!_.isEmpty(symbolTradingAccountNames) && EXCHANGES_CAN_USE_REDUCE_ONLY.includes(exchangeName) && instrumentType !== INSTRUMENT_TYPES.SPOT && 
                <Fragment>
                    {_.size(symbolTradingAccountNames) > 1 ? <Popup className='profile-reduce-only-switches--popup'
                        on={'click'}
                        trigger={
                            <div className='profile-reduce-only-switches--summary'>
                                <div className='profile-reduce-only-switches--summary--account-size'>
                                    <span>{_.size(symbolTradingAccountNames)}</span>
                                    {'Account' + (symbolTradingAccountNames.length > 1 ? 's' : '')}
                                </div>
                                {_.size(invalidAccountNames) > 0 && <div className='profile-reduce-only-switches--summary--invalid-size'>
                                    <span>{_.size(invalidAccountNames)}</span>
                                    {'Invalid'}    
                                </div>}
                                <div className='profile-reduce-only-switches--summary--switched-on-size'>
                                    <span>{_.size(filteredReduceOnlySwitchOnAccountNames)}</span>
                                    {'Reduce-Only'}
                                </div>
                            </div>}>
                        <div className='profile-reduce-only-switches--popup--main'>
                            {_.map(symbolTradingAccountNames, accountName => {
                                return (
                                    <Fragment key={accountName}>
                                        <ProfileReduceOnlySwitchItem 
                                            shouldShowAcccountName
                                            profileItem={profileItem}
                                            symbolName={symbolName}
                                            accountName={accountName}
                                            direction={direction} />
                                    </Fragment>
                                )
                            })}    
                        </div>
                    </Popup>
                    : _.size(symbolTradingAccountNames) === 1 ? <ProfileReduceOnlySwitchItem 
                        shouldShowAcccountName
                        profileItem={profileItem}
                        symbolName={symbolName}
                        accountName={symbolTradingAccountNames[0]}
                        direction={direction} />
                    : null}
                </Fragment>}
            </div>
        )
    }
}

ProfileReduceOnlySwitches.propTypes = {
    profileItem: PropTypes.object.isRequired,
    symbolName: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['BUY', 'SELL']).isRequired,

    profileRunningState: PropTypes.object,
    accountItems: PropTypes.object.isRequired,

}

function mapStateToProps (state, ownProps) {
    return {
        profileRunningState: _.has(state.profile.runningState, `${ownProps.profileItem.id}`) ? state.profile.runningState[ownProps.profileItem.id] : null,
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(ProfileReduceOnlySwitches)