import _ from 'lodash'

const parameterConfig = ({category, name, description, type, defaultValue, options, arrayLengthMultiplier, multipleLengthArrayLabels}) => {
    return {
        category: category || 'NO CATEGORY',
        name: name || 'EMPTY NAME',
        description: description || '',
        type: type || parameterTypes.NUMBER,
        defaultValue: _.isNil(defaultValue) ? 0 : defaultValue,
        options: _.isArray(options) && !_.isEmpty(options) ? options : null,
        arrayLengthMultiplier: arrayLengthMultiplier,
        multipleLengthArrayLabels: multipleLengthArrayLabels || null
    }
}

const profileParamterConfig = ({name, description, type, defaultValue}) => {
    return parameterConfig({
        category: 'PROFILE',
        name,
        description,
        type,
        defaultValue
    })
}

const legParameterConfig = ({legNumber, name, description, type, defaultValue}) => {
    return parameterConfig({
        category: `LEG-${legNumber || '1'}`,
        name,
        description,
        type,
        defaultValue
    })
}

const symbolParameterConfig = ({legNumber, name, description, type, defaultValue, options}) => {
    return parameterConfig({
        category: `LEG-${legNumber || '1'}-SYMBOL`,
        name,
        description,
        type,
        defaultValue,
        options
    })
}

const refSymbolParameterConfig = ({name, description, type, defaultValue}) => {
    return parameterConfig({
        category: `REF-SYMBOL`,
        name,
        description,
        type,
        defaultValue
    })
}

const strategyParameterConfig = ({strategyType, name, description, type, defaultValue, arrayLengthMultiplier, multipleLengthArrayLabels, isSharedAmongSymbols}) => {
    return Object.assign({}, parameterConfig({
        category: `STRATEGY-${strategyType}`,
        name,
        description,
        type,
        defaultValue,
        arrayLengthMultiplier,
        multipleLengthArrayLabels
    }), { isSharedAmongSymbols: isSharedAmongSymbols || false})
}

export const ALL_PROFILE_GROUP_ID = 'all'

export const parameterTypes = {
    BOOLEAN: 'BOOLEAN',
    NUMBER: 'NUMBER',
    STRING: 'STRING',
    SYMBOL: 'SYMBOL',
    BUY_SELL_BOOLEAN_ARRAY: 'BUY_SELL_BOOLEAN_ARRAY',
    BUY_SELL_NUMBER_ARRAY: 'BUY_SELL_NUMBER_ARRAY',
    MIN_MAX_NUMBER_ARRAY: 'MIN_MAX_NUMBER_ARRAY',
    NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER: 'NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER',
    STRING_ARRAY: 'STRING_ARRAY'
}

export const profileParameters = {
    MARKET_DEPTH_TIMEOUT: profileParamterConfig({
        name: 'Market Depth Timeout',
        description: 'The trading system stops trading if it fails to receive market data from the exchange within this specifed continuous time period.',
        type: parameterTypes.NUMBER,
        defaultValue: 2000
    }),
    CANCEL_RESENT_TIME_INTERVAL: profileParamterConfig({
        name: 'Cancel Resent Time Interval',
        description: 'The trading system will cancel an <strong>order withdrawal</strong> and send a new request to the exchange if the original one fails to response within this specified time.',
        type: parameterTypes.NUMBER,
        defaultValue: 5000
    }),
    LOOP: profileParamterConfig({
        name: 'Loop',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: true
    }),
    SPECULATE_FILL_FROM_DEPTH_SWITCH: profileParamterConfig({
        name: 'Speculate Fill From Depth Switch',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    ENABLE_SKEW: profileParamterConfig({
        name: 'Enable Skew',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    SKEW: profileParamterConfig({
        name: 'Skew',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    SKEW_BASE_POS: profileParamterConfig({
        name: 'Skew Base Pos',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 100
    }),
    SKEW_MULTI: profileParamterConfig({
        name: 'Skew Multi',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 1
    }),
    SKEW_THRES_1: profileParamterConfig({
        name: 'Skew Threshold 1',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    SKEW_THRES_2: profileParamterConfig({
        name: 'Skew Threshold 2',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 1000
    }),
    ENABLE_OPTION: profileParamterConfig({
        name: 'Enable Option',
        description: ``,
        type: parameterTypes.BOOLEAN,
        defaultValue: false
    }),
    VOL_SKEW: profileParamterConfig({
        name: 'Vol. Skew',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    THEORY_VOL: profileParamterConfig({
        name: 'Theory Vol.',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    VOL_INTEREST_RATE: profileParamterConfig({
        name: 'Vol. Interest Rate',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0
    }),
    OKEXSPOTMARGIN_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'OKEX MARGIN Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXSPOTMARGIN_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'OKEX MARGIN Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXSPOTMARGIN_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'OKEX MARGIN Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    OKEXSPOTMARGIN_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'OKEX MARGIN Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    OKEXCONTRACT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'OKEX CONTRACT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXCONTRACT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'OKEX CONTRACT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXCONTRACT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'OKEX CONTRACT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    OKEXCONTRACT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'OKEX CONTRACT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    OKEXUSDSWAP_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'OKEX USD SWAP Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXUSDSWAP_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'OKEX USD SWAP Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXUSDSWAP_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'OKEX USD SWAP Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    OKEXUSDSWAP_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'OKEX USD SWAP Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    OKEXUSDTSWAP_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'OKEX USDT SWAP Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXUSDTSWAP_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'OKEX USDT SWAP Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    OKEXUSDTSWAP_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'OKEX USDT SWAP Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    OKEXUSDTSWAP_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'OKEX USDT SWAP Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    HUOBIFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'HUOBI Future Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    HUOBIFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'HUOBI Future Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    HUOBIFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'HUOBI Future Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    HUOBIFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'HUOBI Future Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    HUOBIFUTSWAP_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'HUOBIFUT SWAP Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    HUOBIFUTSWAP_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'HUOBIFUT SWAP Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    HUOBIFUTSWAP_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'HUOBIFUT SWAP Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    HUOBIFUTSWAP_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'HUOBIFUT SWAP Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    BNBFUTAUSDT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA USDT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTAUSDT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA USDT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTAUSDT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BNBFUTA USDT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BNBFUTAUSDT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BNBFUTA USDT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    BNBFUTABUSD_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA BUSD Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTABUSD_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA BUSD Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTABUSD_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BNBFUTA BUSD Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BNBFUTABUSD_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BNBFUTA BUSD Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    BNBFUTACOIN_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA COIN Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTACOIN_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA COIN Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTACOIN_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BNBFUTA COIN Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BNBFUTACOIN_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BNBFUTA COIN Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    BNBFUTA_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTA_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BNBFUTA Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BNBFUTA_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BNBFUTA Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BNBFUTA_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BNBFUTA Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    BITMEX_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BITMEX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITMEX_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BITMEX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITMEX_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BITMEX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BITMEX_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BITMEX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    PHEMEX_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEX_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEX_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'PHEMEX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    PHEMEX_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'PHEMEX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    PHEMEXBTCCONTRACT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX BTC Contract Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEXBTCCONTRACT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX BTC Contract Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEXBTCCONTRACT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'PHEMEX BTC Contract Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    PHEMEXBTCCONTRACT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'PHEMEX BTC Contract Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    PHEMEXUSDCONTRACT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX USD Contract Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEXUSDCONTRACT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'PHEMEX USD Contract Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    PHEMEXUSDCONTRACT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'PHEMEX USD Contract Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    PHEMEXUSDCONTRACT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'PHEMEX USD Contract Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    FTXFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'FTX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    FTXFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'FTX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    FTXFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'FTX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    FTXFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'FTX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    BYBIT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BYBIT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BYBIT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BYBIT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BYBIT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BYBIT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BYBIT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BYBIT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    BITFINEX_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BITFINEX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITFINEX_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BITFINEX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BITFINEX_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BITFINEX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BITFINEX_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BITFINEX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    DERIBIT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'DERIBIT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    DERIBIT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'DERIBIT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    DERIBIT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'DERIBIT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    DERIBIT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'DERIBIT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    BTSEFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BTSEFUT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BTSEFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BTSEFUT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BTSEFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BTSEFUT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BTSEFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BTSEFUT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    BIT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'BIT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BIT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'BIT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    BIT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'BIT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    BIT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'BIT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    DYDX_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'DYDX Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    DYDX_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'DYDX Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    DYDX_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'DYDX Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    DYDX_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'DYDX Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    CRYPTOFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'CRYPTOFUT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    CRYPTOFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'CRYPTOFUT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    CRYPTOFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'CRYPTOFUT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    CRYPTOFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'CRYPTOFUT MARGIN Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    KUCOINFUT_SMART_POS_BID_ACCOUNTS: profileParamterConfig({
        name: 'KUCOINFUT Smart POS Bid Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    KUCOINFUT_SMART_POS_ASK_ACCOUNTS: profileParamterConfig({
        name: 'KUCOINFUT Smart POS Ask Accounts',
        description: ``,
        type: parameterTypes.STRING_ARRAY,
        defaultValue: []
    }),
    KUCOINFUT_SMART_POS_SWITCH_INTERVAL_IN_SEC: profileParamterConfig({
        name: 'KUCOINFUT Smart POS Switch Interval In Seconds',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 600
    }),
    KUCOINFUT_SMART_POS_BACKUP_ACCOUNT: profileParamterConfig({
        name: 'KUCOINFUT Smart POS Backup Account',
        description: ``,
        type: parameterTypes.STRING,
        defaultValue: 'INVALID',
    }),
    SMART_POS_MARGINRATIO_THRESHOLD: profileParamterConfig({
        name: 'Global Margin Ratio Threshold',
        description: ``,
        type: parameterTypes.NUMBER,
        defaultValue: 0.15
    })
}

export const legParameters = {
    leg1: {
        QUOTE_DYNAMIC_MAX_EXPOSURE_MULTIPLIER_BY_NEW_ORDER_QUANTITY: legParameterConfig({
            legNumber: 1,
            name: 'New Order Quantity Multiplier (Dynamic Max Exposure)',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SPREAD_FISHING: legParameterConfig({
            legNumber: 1,
            name: 'Spread Fishing',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        QUOTE_MAX_EXPOSURE: legParameterConfig({
            legNumber: 1,
            name: 'Quote Max Exposure',
            description: `The trading system stops trading <strong>Quote Symbols</strong> if <strong>Exposure</strong> is larger than this specified value.`,
            type: parameterTypes.NUMBER,
            defaultValue: 2.8
        }),
        QUOTE_SPREAD_THRESHOLD_RESTRICT_RELIEVED: legParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Restrict Relieved',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        QUOTE_CANCEL_EXPOSURE: legParameterConfig({
            legNumber: 1,
            name: 'Quote Cancel Exposure',
            description: `Cancel quote orders when realized net value of Quote and Hedge is equal to or larger than this specified value`,
            type: parameterTypes.NUMBER,
            defaultValue: 10000000000
        }),
        QUOTE_MAX_PARALLEL_LEGS: legParameterConfig({
            legNumber: 1,
            name: 'Max Parallel Legs',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        })
    },
    leg2: {
        HEDGE_EXPOSURE_THRESHOLD: legParameterConfig({
            legNumber: 2,
            name: 'Hedge Exposure Threshold',
            description: `The trading system trades <strong>Hedge Symbols</strong> if <strong>Exposure</strong> is larger than this specified value.`,
            type: parameterTypes.NUMBER,
            defaultValue: 0.01
        }),
        HEDGE_OPPOSITE_EXPOSURE_CANCEL_RATIO: legParameterConfig({
            legNumber: 2,
            name: 'Opposite Exposure Cancel Ratio',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 1.0
        }),
        HEDGE_REQUIRED_QUANTITY_RATIO_TO_QUOTE_QUANTITY: legParameterConfig({
            legNumber: 2,
            name: 'Required Qty Ratio to Quote Qty',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        })
    }
}

export const symbolParametrs = {
    leg1: {
        LEG1_FX_PROD: symbolParameterConfig({
            legNumber: 1,
            name: 'FX Symbol',
            description: ``,
            type: parameterTypes.SYMBOL,
            defaultValue: 'INVALID'
        }),
        QUOTE_FX_ADJUST_FACTOR: symbolParameterConfig({
            legNumber: 1,
            name: 'FX Adjust Factor',
            description: ``,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [0, 0]
        }),
        LEG1_PROD_INITIAL_LONG_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Initial Long Position',
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        LEG1_PROD_INITIAL_SHORT_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Initial Short Position',
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_POST_ONLY_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'Post Only',
            description: `<strong>Post-only</strong> limit order ensures the order will be added to the order book and not match with a pre-existing order. 
                If your order would cause a match with a pre-existing order, your post-only limit order will be cancelled. It ensures that you will pay the <strong>marker fee</strong> and not the taker fee unless matched with a hidden order.`,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        QUOTE_IOC_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'IOC',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        QUOTE_MARGIN_TRADE_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'Margin Trade',
            description: `If it is enabled, you will use borrowed funds to trade the Quote Instrument`,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        QUOTE_CROSS_MARGIN_TRADE_ENABLED: symbolParameterConfig({
            legNumber: 1,
            name: 'Cross Margin Trade',
            description: ``,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        QUOTE_LEVERAGE: symbolParameterConfig({
            legNumber: 1,
            name: 'Leverage',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_TRADE_IN_NOTIONAL_VALUE: symbolParameterConfig({
            legNumber: 1,
            name: 'Trade In Notional Value',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false,
        }),
        QUOTE_REF_SPREAD_THRESHOLD_TYPE: symbolParameterConfig({
            legNumber: 1,
            name: 'Ref Spread Threshold Type',
            description: ``,
            type: parameterTypes.STRING,
            defaultValue: 'DIFF',
            options: ['DIFF', 'RATIO', 'PREMIUM']
        }),
        QUOTE_REF_ALL_CROSS: symbolParameterConfig({
            legNumber: 1,
            name: 'Ref All Cross',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        QUOTE_REF_TO_HEDGE: symbolParameterConfig({
            legNumber: 1,
            name: 'Ref To Hedge',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: true
        }),
        // QUOTE_REF_SPREAD_THRESHOLD: symbolParameterConfig({
        //     legNumber: 1,
        //     name: 'Ref Spread Threshold',
        //     description: `If <strong>Ref Instrument</strong> is provided, you buy/sell Quote Instrument only when <strong>Ref Spread</strong> is below/above this specified <strong>Buy/Sell Ref Spread Threshold</strong>. 
        //         Ref Spread = Ref Instrument Last Price - Hedge Instrument Price`,
        //     type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
        //     defaultValue: [1.0, 1.5]
        // }),
        // QUOTE_TRADABLE_REF_PRICE_USE_AWAY_PRICE: symbolParameterConfig({
        //     legNumber: 1,
        //     name: 'Tradable Ref Price Use Away Price',
        //     description: `This parameter becomes effective only when the selected Ref Symbol is tradable. In the case of Buy Side, if this specified value is TRUE, Ref Spread = Ref Instrument Ask - Hedge Instrument Bid; otherwise, Ref Spread = Ref Instrument Bid - Hedge Instrument Bid`,
        //     type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
        //     defaultValue: [true, true]
        // }),
        QUOTE_SPREAD_THRESHOLD_TYPE: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Type',
            description: ``,
            type: parameterTypes.STRING,
            defaultValue: 'DIFF',
            options: ['DIFF', 'RATIO', 'PREMIUM']
        }),
        QUOTE_SPREAD_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold',
            description: `You buy/sell <strong>Quote Instrument</strong> only when <strong>Spread</strong> is below/above this specified <strong>Spread Threshold</strong>. Spread = Quote Instrument Price - Hedge Instrument Price`,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [1.0, 1.5]
        }),
        QUOTE_SPREAD_THRESHOLD_REQUIRED_CROSS_MS: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Required Cross MS',
            description: `Orders are sent only when the spread remains crossed for more than this specified period of time in milliseconds`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SPREAD_THRESHOLD_BOUND: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Bound',
            description: ``,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [100000, -100000]
        }),
        QUOTE_SPREAD_THRESHOLD_ADJUST_BASE_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Adjust Base Pos',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SPREAD_THRESHOLD_ADJUST_MULTIPLIER: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Adjust Multiplier',
            description: ``,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [1, 1]
        }),
        QUOTE_SPREAD_THRESHOLD_ADJUST_BY_FUNDING: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Threshold Adjust By Funding',
            description: `When it is enabled, the threshold will be adjusted according to the funding rate if a swap is selected as the trading symbol.`,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        QUOTE_SPREAD_FUNDING_RATE_ADJUST_FACTOR: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Funding Rate Adjust Factor',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0.001
        }),
        QUOTE_SPREAD_FUNDING_RATE_ADJUST_FACTOR_BASE_MINUTES: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Funding Rate Adjust Factor Base Minutes',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SPREAD_FUNDING_RATE_ADJUST_MULTIPLIER: symbolParameterConfig({
            legNumber: 1,
            name: 'Spread Funding Rate Adjust Multiplier',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_MAX_SIDE_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Max Side Position',
            description: `Orders will be sent only when <strong>Virtual Position</strong> is within this specified <strong>Max Side Position</strong>`,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [0.0, 0.0]
        }),
        QUOTE_NEW_ORDER_QTY: symbolParameterConfig({
            legNumber: 1,
            name: 'New Order Qty',
            description: `New Order Quantity`,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [2.0, 2.0]
        }),
        QUOTE_NEW_ORDER_QTY_RANDOM_DELTA: symbolParameterConfig({
            legNumber: 1,
            name: 'New Order Qty Random Delta',
            description: ``,
            type: parameterTypes.MIN_MAX_NUMBER_ARRAY,
            defaultValue: [0, 0]
        }),
        QUOTE_THEORY_BASE_RATIO: symbolParameterConfig({
            legNumber: 1,
            name: 'Theory Base Ratio',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 1.0
        }),
        QUOTE_EXPOSURE_WEIGHT: symbolParameterConfig({
            legNumber: 1,
            name: 'Exposure Weight',
            description: `It is the weight of current <strong>Quote Instrument's Position</strong> when <strong>Exposure</strong> is calculated. Exposue = Σ(Weight * Quote Instrument's Position) + Hedge Instrument's Position.`,
            type: parameterTypes.NUMBER,
            defaultValue: 1.0
        }),
        QUOTE_PRICE_CHANGE_LIMIT_MS_INTERVAL: symbolParameterConfig({
            legNumber: 1,
            name: 'Price Change Limit MS Interval',
            description: `Stop sending orders if the price's fluctuation exceeds <strong>Price Change Limit Threshold</strong> within this specified time window.`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_PRICE_CAHNGE_LIMIT_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'Price Change Limit Threshold',
            description: `Stop sending orders if the price's fluctuation exceeds this specified threshold within <strong>Price Change Limit MS Interval</strong>`,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        QUOTE_SYMBOL_MAX_POS_SWITCH: symbolParameterConfig({
            legNumber: 1,
            name: 'Symbol Max Pos Switch',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        QUOTE_SYMBOL_MAX_POS_THRESHOLD: symbolParameterConfig({
            legNumber: 1,
            name: 'Symbol Max Pos Threshold',
            description: ``,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [0, 0]
        })
    },
    leg2: {
        LEG2_PROD_INITIAL_LONG_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Initial Long Position',
            defaultValue: 0
        }),
        LEG2_PROD_INITIAL_SHORT_POS: symbolParameterConfig({
            legNumber: 1,
            name: 'Initial Short Position',
            defaultValue: 0
        }),
        HEDGE_POST_ONLY_ENABLED: symbolParameterConfig({
            legNumber: 2,
            name: 'Post Only',
            description: `<strong>Post-only</strong> limit order ensures the order will be added to the order book and not match with a pre-existing order. 
                If your order would cause a match with a pre-existing order, your post-only limit order will be cancelled. It ensures that you will pay the <strong>marker fee</strong> and not the taker fee unless matched with a hidden order.`,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        HEDGE_IOC_ENABLED: symbolParameterConfig({
            legNumber: 2,
            name: 'IOC',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        HEDGE_MARGIN_TRADE_ENABLED: symbolParameterConfig({
            legNumber: 2,
            name: 'Margin Trade',
            description: `If it is enabled, you will use borrowed funds to trade the Hedge Instrument.`,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        HEDGE_CROSS_MARGIN_TRADE_ENABLED: symbolParameterConfig({
            legNumber: 2,
            name: 'Cross Margin Trade',
            description: ``,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        HEDGE_LEVERAGE: symbolParameterConfig({
            legNumber: 2,
            name: 'Leverage',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_TRADE_IN_NOTIONAL_VALUE: symbolParameterConfig({
            legNumber: 2,
            name: 'Trade In Notional Value',
            description: ``,
            type: parameterTypes.BOOLEAN,
            defaultValue: false
        }),
        HEDGE_SPREAD_THRESHOLD_ADJUST_BY_FUNDING: symbolParameterConfig({
            legNumber: 2,
            name: 'Spread Threshold Adjust By Funding',
            description: ``,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [false, false]
        }),
        HEDGE_SPREAD_FUNDING_RATE_ADJUST_FACTOR: symbolParameterConfig({
            legNumber: 2,
            name: 'Spread Funding Rate Adjust Factor',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0.001
        }),
        HEDGE_SPREAD_FUNDING_RATE_ADJUST_FACTOR_BASE_MINUTES: symbolParameterConfig({
            legNumber: 2,
            name: 'Spread Funding Rate Adjust Factor Base Minutes',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_SPREAD_FUNDING_RATE_ADJUST_MULTIPLIER: symbolParameterConfig({
            legNumber: 2,
            name: 'Spread Funding Rate Adjust Multiplier',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 0
        }),
        HEDGE_BIG_PRICE_WEIGHT: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Price Weight',
            description: `It is effective and BIG is applied only when you selected more than one hedge instruments. It is the weight of current Hedge Instrument when <strong>Spread</strong> and <strong>Theoretical Price</strong> are calcualted. 
                Spread = Quote Instrument Price - Σ(Weight * Hedge Instrument Price). Theoretical Price = Σ(Weight * Hedge Instrument Price) + Spread Threshold`,
            type: parameterTypes.NUMBER,
            defaultValue: 1.0
        }),
        HEDGE_BIG_FAIR_PRICE_USE_AWAY_PRICE: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Fair Price Use Away Price',
            description: '',
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [true, true]
        }),
        HEDGE_BIG_FAIR_PRICE_OFFSET: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Fair Price Offset',
            description: `When BIG is applied, the Hedge Instrument with best <strong>Fair Price</strong> will be selected to trade. Current Hedge Instrument's <strong>Fair Price</strong> = Hedge Instrument Price + this specified Offset`,
            type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
            defaultValue: [0, 0]
        }),
        HEDGE_BIG_MAX_ORDER_NOTIONAL: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Max Order Notional',
            description: `It defines the maximum order notional value of current Hedge Instrument when BIG is applied.`,
            type: parameterTypes.NUMBER,
            defaultValue: 100000
        }),
        HEDGE_BIG_FAIR_PRICE_OFFSET_TYPE: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Fair Price Offset Type',
            description: ``,
            type: parameterTypes.STRING,
            defaultValue: 'DIFF',
            options: ['DIFF', 'RATIO', 'PREMIUM']
        }),
        HEDGE_BIG_FAIR_PRICE_OFFSET_FUNDING: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG Fair Price Offset Funding',
            description: ``,
            type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
            defaultValue: [true, true]
        }),
        HEDGE_BIG_OB_VOLUME: symbolParameterConfig({
            legNumber: 2,
            name: 'BIG OB Volume',
            description: ``,
            type: parameterTypes.NUMBER,
            defaultValue: 1
        })
    }
}

export const refSymbolParameters = {
    QUOTE_REF_SPREAD_THRESHOLD: refSymbolParameterConfig({
        name: 'Ref Spread Threshold',
        description: ``,
        type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
        defaultValue: [-0.001, 0.001]
    }),
    QUOTE_TRADABLE_REF_PRICE_USE_AWAY_PRICE: refSymbolParameterConfig({
        name: 'Tradable Ref Price Use Away Price',
        description: ``,
        type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
        defaultValue: [true, true]
    })
}

export const strategies = {
    DIME: {
        type: 'DIME',
        name: 'DIME',
        parameters: {
            DIME_TIMEOUT_SINCE_LAST_TRADE: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Timeout Since Last Trade',
                description: `A pending order will be cancelled after this specified number of milliseconds from the last trade. A new order will be sent.`,
                type: parameterTypes.NUMBER,
                defaultValue: 1000000,
                isSharedAmongSymbols: true
            }),
            DIME_MAX_MORE_AGGRESSIVE_QTY: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Max More Aggressive Qty',
                description: `A pending order will be cancelled if the total quantity of orders with higher priority is larger than this specified value. A new order will be sent.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [5.0, 5.0]
            }),
            DIME_AGGRESSIVE_TAKE_ENABLED: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Aggressive Take',
                description: `If it is enabled, <strong>Taker Orders</strong> will be sent to buy/sell Quote Instrument if <strong>Ask/Bid</strong> is below/above the <strong>Aggressive Theoretical Price</strong>.
                    The order's price should be <strong>Ask/Bid</strong> while its quantity should be Min(Market Depth of Ask/Bid, New Order Qty). 
                    <strong>Aggressive Theoretical Price</strong> is calcualted based on <strong>Aggressive Take Offset On Price Threshold</strong>.`,
                type: parameterTypes.BOOLEAN,
                defaultValue: false
            }),
            DIME_AGGRESSIVE_TAKE_TIMEOUT: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Aggressive Take Timeout',
                description: `A pending <strong>Aggressive Take Order</strong> will be cancelled if it is not fulfilled after this specified number of milliseconds.`,
                type: parameterTypes.NUMBER,
                defaultValue: 10
            }),
            DIME_AGGRESSIVE_TAKE_SPREAD_THRESHOLD_OFFSET: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Aggressive Take Spread Threshold Offset',
                description: `On the buy/sell side, <strong>Aggressive Theoretical Price</strong> = Theoretical Price -/+ this specified Offset`,
                type: parameterTypes.NUMBER,
                defaultValue: 0
            }),
            DIME_MIN_REPLACE_THRES: strategyParameterConfig({
                strategyType: 'DIME',
                name: 'Min Replace Thres',
                type: parameterTypes.NUMBER,
                defaultValue: 0
            })
        }
    },
    MULTILEVEL: {
        type: 'MULTILEVEL',
        name: 'Multi-Level',
        parameters: {
            MULTILEVEL_MAX_NEW_ORDER_IN_ONE_SIDE: {
                strategyType: 'MULTILEVEL',
                name: 'Max New Order In One Side',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 3
            },
            MULTILEVEL_MAX_PENDING_ORDER_IN_ONE_SIDE: {
                strategyType: 'MULTILEVEL',
                name: 'Max Pending Order In One Side',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 7
            },
            MULTILEVEL_PRICE_TICK_MULTIPLIER: {
                strategyType: 'MULTILEVEL',
                name: 'Price Tick Multiplier',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            },
            MULTILEVEL_BASE_LEVEL: {
                strategyType: 'MULTILEVEL',
                name: 'Base Level',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            },
            MULTILEVEL_MAX_BASE_LEVEL: {
                strategyType: 'MULTILEVEL',
                name: 'Max Base Level',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 5
            },
            MULTILEVEL_CANCEL_MAX_LEVEL: {
                strategyType: 'MULTILEVEL',
                name: 'Cancel Max Level',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 5
            },
            MULTILEVEL_CANCEL_AGGRESSIVE_TICK_THAN_THEORY: {
                strategyType: 'MULTILEVEL',
                name: 'Cancel Aggressive Tick Than Theory',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1
            },
            MULTILEVEL_CANCEL_PASSIVE_TICK_THAN_THEORY: {
                strategyType: 'MULTILEVEL',
                name: 'Cancel Passive Tick Than Theory',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 10000
            },
            MULTILEVEL_BASE_LEVEL_CHECK_ENABLED: {
                strategyType: 'MULTILEVEL',
                name: 'Base Level Check',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: true
            },
            MULTILEVEL_PASSIVE_CHECK_ENABLED: {
                strategyType: 'MULTILEVEL',
                name: 'Passive Check',
                description: ``,
                type: parameterTypes.BUY_SELL_BOOLEAN_ARRAY,
                defaultValue: [true, true]
            }
        }
    },
    HIT: {
        type: 'HIT',
        name: 'HIT',
        parameters: {
            HIT_AGGRESSIVE_TICKS: strategyParameterConfig({
                strategyType: 'HIT',
                name: 'Aggressive Ticks',
                description: `When you send orders to buy/sell Hedge Instrument, the order price will be Ask/Bid +/- this specified number of ticks.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0, 0],
                isSharedAmongSymbols: false
            }),
            HIT_STOP_TICKS: strategyParameterConfig({
                strategyType: 'HIT',
                name: 'Stop Ticks',
                description: `A pending buy/sell order will be cancelled if its order price is larger/smaller than current Ask/Bid by this specified number of ticks.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [1, 1],
                isSharedAmongSymbols: false
            })
        }
    },
    COG: {
        type: 'COG',
        name: 'COG',
        parameters: {
            COG_IN_FRONT_QTY: strategyParameterConfig({
                strategyType: 'COG',
                name: 'In Front Qty',
                description: `A pending order will be cancelled if the total quantity of orders with higher priority is larger than this specified value. A new order will be sent.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0.4, 0.4],
                isSharedAmongSymbols: false
            }), 
            COG_MAX_CANCEL_TIMES_BEFORE_HIT: strategyParameterConfig({
                strategyType: 'COG',
                name: 'Max Cancel Times Before HIT',
                description: `Switch to HIT Strategy if the count of cancelling pending orders exceeds this specified value.`,
                type: parameterTypes.NUMBER,
                defaultValue: 4,
                isSharedAmongSymbols: false
            }),
            COG_ORDERBOOK_QTY_RATIO_THRESHOLD: strategyParameterConfig({
                strategyType: 'COG',
                name: 'Order Book Qty Ratio Threshold',
                description: `In the case of buying Hedge Instrument, order price should be <strong>Bid Price</strong> if 
                    <strong>Market Depth of Highest Bid</strong>/<strong>Market Depth of Lowest Ask</strong> is smaller than this speciefied value, otherwise order price should Bid Price + 1 Tick. It is similar for the selling case.`,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0, 0],
                isSharedAmongSymbols: false
            }),
            COG_HIT_SEND_ON_MORE_AGGRESSIVE_TICKS: strategyParameterConfig({
                strategyType: 'COG',
                name: 'HIT Send On More Aggressive Ticks',
                description: `After the switch to HIT strategy, the order price will be Ask/Bid +/- this specified number of ticks when you buy/sell the Hedge Instrument.`,
                type: parameterTypes.NUMBER,
                defaultValue: 2,
                isSharedAmongSymbols: false
            }),
            COG_HIT_CANCEL_ON_MORE_PASSIVE_TICKS: strategyParameterConfig({
                strategyType: 'COG',
                name: 'HIT Cancel On More Passive Ticks',
                description: `After the switch to HIT strategy, a pending buy/sell order will be cancelled if current Ask/Bid is smaller/larger than the order price by this specified number of ticks.`,
                type: parameterTypes.NUMBER,
                defaultValue: 1,
                isSharedAmongSymbols: false
            })
        }
    },
    RDIME: {
        type: 'RDIME',
        name: 'RDIME',
        parameters: {
            RDIME_TIMEOUT_SINCE_CONFIRMED: strategyParameterConfig({
                strategyType: 'RDIME',
                name: 'Timeout Since Confirmed',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 5000,
                isSharedAmongSymbols: false
            }),
            RDIME_MIN_BASIS_POINTS: strategyParameterConfig({
                strategyType: 'RDIME',
                name: 'Min Basis Points',
                description: ``,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [10, 10],
                isSharedAmongSymbols: false
            }),
            RDIME_MAX_BASIS_POINTS: strategyParameterConfig({
                strategyType: 'RDIME',
                name: 'Max Basis Points',
                description: ``,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [20, 20],
                isSharedAmongSymbols: false
            }),
            RDIME_QUOTE_BASIS_POINTS: strategyParameterConfig({
                strategyType: 'RDIME',
                name: 'Quote Basis Points',
                description: ``,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0, 0],
                isSharedAmongSymbols: false
            }),
            RDIME_REQUIRED_AGGRESSIVE_QTY: strategyParameterConfig({
                strategyType: 'RDIME',
                name: 'Required Aggressive Qty',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1000,
                isSharedAmongSymbols: false
            }),
            RDIME_EXCHANGE_SPREAD_TICK_ACTIVATE_QUANTITY: strategyParameterConfig({
                strategyType: 'RDIME',
                name: 'Exchange Spread Tick Activate Qty',
                description: ``,
                type: parameterTypes.NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER,
                defaultValue: [],
                isSharedAmongSymbols: false,
                arrayLengthMultiplier: 2,
                multipleLengthArrayLabels: ['SPD PC. Tick', 'New Order Qty']
            }),
            RDIME_NEW_AFTER_CANCELLED: strategyParameterConfig({
                strategyType: 'RDIME',
                name: 'New After Cancelled',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: false,
                isSharedAmongSymbols: false
            })
        }
    },
    DSWAP: {
        type: 'DSWAP',
        name: 'DSWAP',
        parameters: {
            DSWAP_MIN_QUANTITY: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Min Quantity',
                description: ``,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0, 0],
                isSharedAmongSymbols: false
            }),
            DSWAP_SLIPPAGE: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Slippage',
                description: ``,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0.002, 0.002],
                isSharedAmongSymbols: false
            }),
            DSWAP_GAS: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Gas',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 250000,
                isSharedAmongSymbols: false
            }),
            DSWAP_GAS_PRICE_IN_GWEI: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Gas Price (Gwei)',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 20,
                isSharedAmongSymbols: false
            }),
            DSWAP_AUTO_ADJUST: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Auto Adjust',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: false,
                isSharedAmongSymbols: false
            }),
            DSWAP_OBSERVATION_QTY: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Observation Qty',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: false
            }),
            DSWAP_MAX_GAS_PRICE_IN_GWEI: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Max Gas Price (Gwei)',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 250,
                isSharedAmongSymbols: false
            }),
            DSWAP_ORDER_TS_TH: strategyParameterConfig({
                strategyType: 'DSWAP',
                name: 'Order TS TH',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 2500,
                isSharedAmongSymbols: false
            })
        }
    },
    UNISWAPV2: {
        type: 'UNISWAPV2',
        name: 'UNISWAPV2',
        parameters: {
            UNISWAPV2_MIN_QUANTITY: strategyParameterConfig({
                strategyType: 'UNISWAPV2',
                name: 'Min Quantity',
                description: ``,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0, 0],
                isSharedAmongSymbols: false
            }),
            UNISWAPV2_SLIPPAGE: strategyParameterConfig({
                strategyType: 'UNISWAPV2',
                name: 'Slippage',
                description: ``,
                type: parameterTypes.BUY_SELL_NUMBER_ARRAY,
                defaultValue: [0.002, 0.002],
                isSharedAmongSymbols: false
            }),
            UNISWAPV2_GAS: strategyParameterConfig({
                strategyType: 'UNISWAPV2',
                name: 'Gas',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 250000,
                isSharedAmongSymbols: false
            }),
            UNISWAPV2_GAS_PRICE_IN_GWEI: strategyParameterConfig({
                strategyType: 'UNISWAPV2',
                name: 'Gas Price (Gwei)',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 20,
                isSharedAmongSymbols: false
            }),
            UNISWAPV2_AUTO_ADJUST: strategyParameterConfig({
                strategyType: 'UNISWAPV2',
                name: 'Auto Adjust',
                description: ``,
                type: parameterTypes.BOOLEAN,
                defaultValue: false,
                isSharedAmongSymbols: false
            }),
            UNISWAPV2_OBSERVATION_QTY: strategyParameterConfig({
                strategyType: 'UNISWAPV2',
                name: 'Observation Qty',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: false
            }),
            UNISWAPV2_MAX_GAS_PRICE_IN_GWEI: strategyParameterConfig({
                strategyType: 'UNISWAPV2',
                name: 'Max Gas Price (Gwei)',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 250,
                isSharedAmongSymbols: false
            }),
            UNISWAPV2_ORDER_TS_TH: strategyParameterConfig({
                strategyType: 'UNISWAPV2',
                name: 'Order TS TH',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 2500,
                isSharedAmongSymbols: false
            })
        }
    },
    MAKE: {
        type: 'MAKE',
        name: 'MAKE',
        parameters: {
            MAKE_MAX_ALIVE_MS: {
                strategyType: 'MAKE',
                name: 'Max Alive Ms',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 1000,
                isSharedAmongSymbols: true
            },
            MAKE_MAX_EXCHANGE_SPREAD_RATIO: {
                strategyType: 'MAKE',
                name: 'Max Exchange Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            },
            MAKE_SPREAD_MAKER_MIN_SPREAD_RATIO: {
                strategyType: 'MAKE',
                name: 'Spread Maker Min Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            },
            MAKE_SPREAD_MAKER_SPREAD_RATIO: {
                strategyType: 'MAKE',
                name: 'Spread Maker Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            },
            MAKE_SPREAD_MAKER_QUANTITY: {
                strategyType: 'MAKE',
                name: 'Spread Maker Quantity',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            },
            MAKE_DEPTH_MAKER_MIN_SPREAD_RATIO: {
                strategyType: 'MAKE',
                name: 'Depth Maker Min Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            },
            MAKE_DEPTH_MAKER_SPREAD_RATIO: {
                strategyType: 'MAKE',
                name: 'Depth Maker Spread Ratio',
                description: ``,
                type: parameterTypes.NUMBER,
                defaultValue: 0,
                isSharedAmongSymbols: true
            }
        }
    }
}

export const legStrategyTypes = {
    leg1: [strategies.DIME.type, strategies.MULTILEVEL.type, strategies.HIT.type, strategies.COG.type, strategies.RDIME.type, strategies.DSWAP.type, strategies.MAKE.type, strategies.UNISWAPV2.type],
    leg2: [strategies.DIME.type, strategies.HIT.type, strategies.COG.type, strategies.UNISWAPV2.type]
}

export const LOG_LEVELS = {
    INFO: {
        value: 'INFO',
        disabled: false
    }, 
    DEBUG: {
        value: 'DEBUG',
        disabled: false
    },
    WARNING: {
        value: 'WARNING',
        disabled: true
    },
    FATAL: {
        value: 'FATAL',
        disabled: true
    }
}

export const createProfileDefaultParameters = () => {
    return _.mapValues(profileParameters, 'defaultValue')
}

export const createLegDefaultParameters = (legNumber) => {
    return _.mapValues(legParameters[`leg${legNumber}`], 'defaultValue')
}

export const createSymbolDefaultParameters = (legNumber) => {
    return _.mapValues(symbolParametrs[`leg${legNumber}`], 'defaultValue')
}

export const createRefSymbolDefaultParameters = () => {
    return _.mapValues(refSymbolParameters, 'defaultValue')
}

export const createStrategyDefaultSharedParameters = (type) => {
    const parameterConfigs = strategies[type].parameters
    return Object.keys(parameterConfigs).filter(key => parameterConfigs[key].isSharedAmongSymbols)
        .reduce((object, key) => {
            object[key] = parameterConfigs[key].defaultValue
            return object
        }, {})
}

export const createStrategyDefaultProductParameters = (type) => {
    const parameterConfigs = strategies[type].parameters
    return Object.keys(parameterConfigs).filter(key => !parameterConfigs[key].isSharedAmongSymbols)
        .reduce((object, key) => {
            object[key] = parameterConfigs[key].defaultValue
            return object
        }, {})
}

export const createLegSymbol = ({legNumber, symbolName, refSymbolName, strategyType}) => {
    const symbol = {
        name: symbolName || 'INVALID',
        params: createSymbolDefaultParameters(legNumber),
        strategyParams: createStrategyDefaultProductParameters(strategyType)
    }
    if (legNumber === 1) {
        symbol.refSymbol = refSymbolName || 'INVALID'
        symbol.refSymbols = [{
            name: refSymbolName || 'INVALID',
            params: createRefSymbolDefaultParameters()
        }]
    }
    return symbol
}

export const createDefaultProfile = ({leg1SymbolName, leg1RefSymbolName, leg1StrategyType, leg2SymbolName, leg2StrategyType}) => {
    leg1StrategyType = leg1StrategyType || legStrategyTypes['leg1'][0]
    leg2StrategyType = leg2StrategyType || legStrategyTypes['leg2'][0]
    return {
        name: '',
        params: createProfileDefaultParameters(),
        legs: {
            1: {
                params: createLegDefaultParameters(1),
                strategy: {
                    type: leg1StrategyType,
                    params: createStrategyDefaultSharedParameters(leg1StrategyType)
                },
                symbols: [createLegSymbol({
                    legNumber: 1,
                    symbolName: leg1SymbolName || null,
                    refSymbolName: leg1RefSymbolName || null,
                    strategyType: leg1StrategyType
                })]
            },
            2: {
                params: createLegDefaultParameters(2),
                strategy: {
                    type: leg2StrategyType,
                    params: createStrategyDefaultSharedParameters(leg2StrategyType)
                },
                symbols: [createLegSymbol({
                    legNumber: 2,
                    symbolName: leg2SymbolName || null,
                    strategyType: leg2StrategyType
                })]
            }
        },
        accounts: {}
    }
}

export const createTmpParameterConfig = (key, value) => {
    const isBoolean = (v) => {
        return _.isBoolean(v) || (_.isString(v) && (v.toLowerCase() === 'true' || v.toLowerCase() === 'false'))
    }
    let type = parameterTypes.NUMBER
    if (isBoolean(value)) {
        type = parameterTypes.BOOLEAN
    } else if (_.isString(value)) {
        type = parameterConfig.STRING
    } else if (_.isArray(value) && value.length > 1) {
        type = isBoolean(value[0]) ? parameterTypes.BUY_SELL_BOOLEAN_ARRAY : parameterTypes.BUY_SELL_NUMBER_ARRAY
    }
    return {
        category: 'CONFIG MISSING',
        name: key,
        type,
        defaultValue: value
    }
}

export const getStrategyParamConfig = (strategyType, key) => {
    const paramKey = _.startsWith(key, 'QUOTE_') || _.startsWith(key, 'HEDGE_') ? key.substring(6) : key
    return _.has(strategies, `${strategyType}.parameters.${paramKey}`) ? strategies[strategyType].parameters[paramKey] : null
}