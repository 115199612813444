import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SaveButton from '../common/saveButton/SaveButton'
import { postSupportRequest } from './supportAction'

class SupportRequestEditor extends Component {
    constructor (props) {
        super(props)
        this.state = {
            messageInput: '',
            isSendingRequest: false,
            isSentRequest: false
        }
    }

    handleClickSend () {
        const { dispatch } = this.props
        const { messageInput } = this.state

        this.setState({ 
            isSendingRequest: true,
            isSentRequest: false 
        })
        dispatch(postSupportRequest(messageInput.trim()))
        .then(response => {
            if (response && response.status === 200) {
                this.setState({ isSentRequest: true })
            }
        })
        .finally(() => {
            this.setState({ isSendingRequest: false })
        })
    }

    render () {
        const { messageInput, isSendingRequest, isSentRequest } = this.state
        return (
            <div className='support-request-editor'>
                <div className='support-request-editor--main'>
                    <textarea 
                        placeholder={'Write a message...'} 
                        spellCheck={false} 
                        autoFocus
                        value={messageInput}
                        onChange={(e) => { 
                            this.setState({ 
                                messageInput: e.target.value,
                                isSentRequest: false
                            }) 
                        }} />
                    <SaveButton 
                        text={'Send Request'}
                        isSavingText={'Sending'}
                        isSaving={isSendingRequest}
                        onClick={() => { this.handleClickSend() }} />
                    {isSentRequest && <div className='support-request-editor--sent-message'>{'OK! Reuqest is sent.'}</div>}
                </div>
            </div>
        )
    }
}

SupportRequestEditor.propTypes = {
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps () {
    return {}
}

export default connect(mapStateToProps)(SupportRequestEditor)