import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import dotProp from 'dot-prop-immutable'
import _ from 'lodash'

import { IoIosCloseCircleOutline } from 'react-icons/io'
import SocketConnection from '../webSocket/SocketConnection'
import Clock from '../common/clock/Clock'

import { SERVERS } from '../../configs/config'
import NodeWebSocket from '../webSocket/NodeWebSocket'

class Footer extends Component {

    constructor (props) {
        super(props)
        this.initialWebSocketDisconnectedTimestamp = _.filter(SERVERS, { enabled: true }).reduce((result, server) => {
            return dotProp.set(result, server.hostname, null)
        }, {})
        this.state = {
            isOnline: navigator.onLine,
            webSocketDisconnectedTimestamp: this.initialWebSocketDisconnectedTimestamp,
            nodeWebSocketUrl: null
        }
        this.handleOnlineWindow = this.handleOnlineWindow.bind(this)
        this.handleOffLineWindow = this.handleOffLineWindow.bind(this)
    }

    componentDidMount () {
        window.addEventListener('online', this.handleOnlineWindow)
        window.addEventListener('offline', this.handleOffLineWindow)
    }

    componentDidUpdate (prevProps) {
        const { webSocket } = this.props
        _.forEach(prevProps.webSocket, (prevWebSocketItem, hostname) => {
            if (prevWebSocketItem.readyState === WebSocket.OPEN && webSocket[hostname].readyState !== WebSocket.OPEN) {
                this._updateWebSocketDisconnectedTimestamp({ 
                    [hostname] : moment().format('HH:mm:ss')
                })
            }
        })
    }

    componentWillUnmount () {
        window.removeEventListener('online', this.handleOnlineWindow)
        window.removeEventListener('offline', this.handleOffLineWindow)
    }

    _updateWebSocketDisconnectedTimestamp (params) {
        const { webSocketDisconnectedTimestamp } = this.state
        this.setState({ 
            webSocketDisconnectedTimestamp: Object.assign({}, webSocketDisconnectedTimestamp, params) 
        })
    }

    handleOnlineWindow () {
        this.setState({ isOnline: true })
    }

    handleOffLineWindow () {
        this.setState({ isOnline: false })
    }

    render () {
        const { isOnline, webSocketDisconnectedTimestamp } = this.state
        const { auth, webSocket } = this.props
        return (
            <div className='footer'>
                <div className='footer--title vertical-centered'>{'Antelope Technology'}</div>
                <div className='footer--username vertical-centered'>{`User: ${auth.username}`}</div>
                <div className='footer--clock'><Clock /></div>
                <div className='footer--connections'>
                    {!isOnline && <div className='footer--connections--offline-label'>{'Your Internet connection seems not working.'}</div>}
                    {_.map(webSocket, (webSocketItem, hostname) => {
                        return (
                            <Fragment key={hostname}>
                                <SocketConnection 
                                    hostname={hostname} 
                                    shouldHideReconnectInfo={!isOnline} />
                            </Fragment>
                        )
                    })}
                    <NodeWebSocket className='footer--node-websocket'/>
                    {_.some(webSocketDisconnectedTimestamp, v => !_.isNil(v)) && <div className='footer--connections--disconnected-wrapper'>
                        <button className='footer--connections--disconnected-wrapper--close-button'
                        onClick={() => { 
                            this.setState({ 
                                webSocketDisconnectedTimestamp: this.initialWebSocketDisconnectedTimestamp
                            }) 
                        }}><IoIosCloseCircleOutline /></button>
                        <div className='footer-connection--disconnected-wrapper--message'>
                            {_.map(webSocketDisconnectedTimestamp, (timestamp, hostname) => {
                                const { locationName } = SERVERS[hostname]
                                return timestamp ? (
                                    <div key={hostname}>{`${locationName} WebSocket was disconected at `}<b>{timestamp}</b></div>
                                ) : null
                            })}
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

Footer.propTypes = {
    webSocket: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
}

function mapStateToProps (state) {
    return {
        webSocket: state.webSocket,
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Footer)