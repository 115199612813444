import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import moment from 'moment'

import Modal from 'react-modal'
import { FiX, FiAlertCircle, FiEdit3 } from 'react-icons/fi'
import { AutoSizer, Table, Column } from 'react-virtualized'
import Popup from '../common/popup/Popup'
import ProfileActionPopup from './ProfileActionPopup'
import { restartProfile, stopProfile, cancelAndPauseProfile, resumeProfile } from './profileAction'
import { RECOVER_PROFILE_STATE_CHECKING_INTERVAL } from '../../configs/config'


class ProfileStateSnapshotModal extends Component {

    constructor (props) {
        super(props)
        this.state = {
            nameInput: props.snapshot.name,
            isRecovering: false
        }
        this.recoverStateInterval = null
    }

    componentWillUnmount () {
        if (this.recoverStateInterval) {
            window.clearInterval(this.recoverStateInterval)
        }
    }

    handleClickRecoverButton () {
        const { isRecovering } = this.state
        const { dispatch, snapshot } = this.props
        const recoverState = () => {
            let isNotMatchedStateFound = false
            snapshot.profileStates.forEach(snapshotState => {
                const { id: profileId } = snapshotState
                const { profileItems } = this.props
                const profileItem = profileItems[profileId]
                if (profileItem) {
                    if (snapshotState.started && !profileItem.started) {
                        isNotMatchedStateFound = true
                        if (!profileItem.isStarting && !profileItem.isStopping) {
                            dispatch(restartProfile(profileId))
                        }
                    } else if (!snapshotState.started && profileItem.started) {
                        isNotMatchedStateFound = true
                        if (profileItem.resumed && !profileItem.isResuming && !profileItem.isPausing) {
                            dispatch(cancelAndPauseProfile(profileId))
                        } else if (!profileItem.resumed && !profileItem.isResuming && !profileItem.isPausing) {
                            dispatch(stopProfile(profileId))
                        }
                    } else if (snapshotState.started && profileItem.started) {
                        if (snapshotState.resumed && !profileItem.resumed) {
                            isNotMatchedStateFound = true
                            if (!profileItem.isResuming && !profileItem.isPausing) {
                                dispatch(resumeProfile(profileId))
                            }
                        } else if (!snapshotState.resumed && profileItem.resumed) {
                            isNotMatchedStateFound = true
                            if (!profileItem.isResuming && !profileItem.isPausing) {
                                dispatch(cancelAndPauseProfile(profileId))
                            }
                        }
                    }
                }
            })
            return isNotMatchedStateFound
        }

        if (!isRecovering) {
            this.setState({ isRecovering: true })
            const isNotMatchedStateFound = recoverState()
            if (isNotMatchedStateFound) {
                this.recoverStateInterval = setInterval(() => {
                    const isNotMatchedStateFound = recoverState()
                    if (!isNotMatchedStateFound) {
                        window.clearInterval(this.recoverStateInterval)
                        this.setState({ isRecovering: false })
                    }
                }, RECOVER_PROFILE_STATE_CHECKING_INTERVAL) //  !!! the interval should be larger than websocket buffer's interval !!!
            } else {
                this.setState({ isRecovering: false })
            }
        }
    }

    StateItem ({ className, name='', hasMark=true, iconComponent }) {
        return (
            <div className={'profile-state-snapshot-modal--state-item' + (className ? ` ${className}` : '')}>
                {iconComponent}
                {hasMark && <span className='profile-state-snapshot-modal--state-item--mark vertical-centered' />}
                <span className='profile-state-snapshot-modal--state-item--name'>{name}</span>
            </div>
        )
    }

    ProfileState (profile) {
        const profileStartStopState = profile.isStarting ? 'STARTING' : profile.isStopping ? 'STOPPING' : profile.started ? 'STARTED' : 'STOPPED'
        const profileResumePauseState = profile.isResuming ? 'RESUMING' : profile.isPausing ? 'PAUSING' : profile.resumed ? 'RESUMED' : 'PAUSED'
        return (
            <div className='profile-state-snapshot-modal--state'>
                {profile.crashed && this.StateItem({
                    className: 'CRASHED',
                    name: 'CRASHED',
                    hasMark: false,
                    iconComponent: <FiAlertCircle />
                })}
                {this.StateItem({
                    className: profileStartStopState,
                    name: profileStartStopState,
                    hasMark: true
                })}
                {profileStartStopState === 'STARTED' && this.StateItem({
                    className: profileResumePauseState,
                    name: profileResumePauseState,
                    hasMark: true
                })}
            </div>
        )
    }

    render () {
        const { nameInput, isRecovering } = this.state
        const { snapshot, profileItems, onClickCloseButton, onUpdateSnapshot } = this.props
        const profileSize = _.size(snapshot.profileStates)
        return (
            <Modal
                overlayClassName='modal-overlay'
                className='profile-state-snapshot-modal horizontal-centered'
                isOpen>
                <div className='profile-state-snapshot-modal--header'>
                    <div className='profile-state-snapshot-modal--header--title'>{snapshot.name}</div>
                    <Popup className='profile-state-snapshot-modal--header--edit-title-popup'
                        trigger={
                            <button className='profile-state-snapshot-modal--header--edit-title-popup--trigger'>
                                <FiEdit3 />
                            </button>
                        }
                        on={'click'}
                        onOpen={() => { this.setState({ nameInput: snapshot.name }) }}>
                        <div className='profile-state-snapshot-modal--header--edit-title-popup--body'>
                            <div className='profile-state-snapshot-modal--header--edit-title-popup--title'>{'Edit Name'}</div>
                            <div className='profile-state-snapshot-modal--header--edit-title-popup--body--main'>
                                <input className='profile-state-snapshot-modal--header--edit-title-popup--input' 
                                    type={'text'} 
                                    placeholder={'Snapshot Name'}
                                    autoFocus
                                    spellCheck={false}
                                    value={nameInput}
                                    onChange={(e) => { this.setState({ nameInput: e.target.value }) }} />
                                <button className='profile-state-snapshot-modal--header--edit-title-popup--save-button'
                                    disabled={nameInput.trim().length === 0}
                                    onClick={() => {
                                        const newSnapshot = dotProp.set(snapshot, 'name', nameInput.trim())
                                        onUpdateSnapshot(newSnapshot)
                                    }}>{'SAVE'}</button>
                            </div>
                        </div>
                    </Popup>
                    <button className='profile-state-snapshot-modal--header--close-button' onClick={() => { onClickCloseButton() }}><FiX /></button>
                </div>
                <div className='profile-state-snapshot-modal--body'>
                    <AutoSizer disableWidth>
                        {({ height }) => (
                            <Table 
                                className='profile-state-snapshot-modal--table'
                                headerHeight={27}
                                headerClassName={'profile-state-snapshot-modal--table--header'}
                                rowClassName={'profile-state-snapshot-modal--table--row'}
                                width={900}
                                height={height}
                                rowCount={profileSize}
                                rowGetter={({ index }) => snapshot.profileStates[index]}
                                rowHeight={31}
                                overscanRowCount={5}
                                noRowsRenderer={() => (
                                    <div className='profile-state-snapshot-modal--table--no-content'>{'Empty Snapshot'}</div>
                                )}>
                                <Column 
                                    dataKey={'profile'} 
                                    width={200} 
                                    flexGrow={1}
                                    label={`Profile (${profileSize})`}
                                    className={'profile-state-snapshot-modal--table--profile-name'}
                                    cellDataGetter={({ rowData: profile }) => profile.name} />
                                <Column
                                    dataKey={'snapshot'}
                                    width={250}
                                    label={`Snapshot @ ${moment(snapshot.createdTime).format('MM-DD HH:mm:ss')}`} 
                                    cellRenderer={({ rowData: profile }) => this.ProfileState(profile)} />
                                <Column
                                    dataKey={'current'}
                                    width={250}
                                    label={'Current'}
                                    headerRenderer={() => (
                                        <Fragment>
                                            <span>{'Current'}</span>
                                            <button className='profile-state-snapshot-modal--recover-button' 
                                                disabled={isRecovering}
                                                onClick={() => { this.handleClickRecoverButton() }}>
                                                {isRecovering ? 'Recovering ...' : 'Recover To Snapshot'}
                                            </button>
                                        </Fragment>
                                    )}
                                    cellRenderer={({ rowData: profile }) => {
                                        const profileItem = profileItems[profile.id]
                                        return profileItem ? (
                                            <div className='profile-state-snapshot-modal--cell current'>
                                                {this.ProfileState(profileItem)}
                                                <ProfileActionPopup profileItem={profileItem} />
                                            </div>
                                        ) : null
                                    }} />
                            </Table>
                        )}
                    </AutoSizer>
                </div>
            </Modal>
        )
    }
}

ProfileStateSnapshotModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    snapshot: PropTypes.object.isRequired,
    profileItems: PropTypes.object.isRequired,
    onClickCloseButton: PropTypes.func.isRequired,
    onUpdateSnapshot: PropTypes.func.isRequired
}

ProfileStateSnapshotModal.defaultProps = {
    onClickCloseButton: () => {},
    onUpdateSnapshot: () => {}
}

function mapStateToProps (state) {
    return {
        profileItems: state.profile.items
    }
}

export default connect(mapStateToProps)(ProfileStateSnapshotModal)