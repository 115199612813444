import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FiShuffle } from 'react-icons/fi'
import TokenTransferEditor, { OPERATION_MODES, TRANSFER_MODES } from './TokenTransferEditor'
import TokenLendingEditor from './TokenLendingEditor'
import { updateLayoutBulkTransferWindowId } from '../layout/layoutAction'

const MODES = {
    TRANSFER: 'TRANSFER',
    LENDING: 'LENDING'
}

const getSessionStorageMode = () => {
    const mode = sessionStorage.tokenTransferContainerMode
    return _.keys(MODES).includes(mode) ? mode : MODES.TRANSFER
}

const updateSessionStorageMode = (mode) => {
    sessionStorage.tokenTransferContainerMode = mode
}
class TokenTransferContainer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            mode: getSessionStorageMode(),
            shouldCloseBulkTransferPopupOnClickWindow: true
        }
    }

    Header () {
        const { dispatch } = this.props
        const { mode } = this.state
        return (
            <div className='token-transfer-container--header'>
                <div className='token-transfer-container--header--main hidden'>
                    <div className='token-transfer-container--header--title'>{'Transfer Token'}</div>
                    <button className='token-transfer-container--header--bulk-transfer-popup--trigger' onClick={() => { dispatch(updateLayoutBulkTransferWindowId()) }}><FiShuffle /></button>
                </div>

                <div className='token-transfer-container--header--tabs'>
                    {_.map(Object.keys(MODES), (m) => {
                        return (
                            <button className={'token-transfer-container--header--tab' + (mode === m ? ' active' : '')} 
                                key={m} 
                                onClick={() => { 
                                    updateSessionStorageMode(m)
                                    this.setState({ mode: getSessionStorageMode() }) 
                                }}>{m}</button>
                        )
                    })}
                </div>
            </div>
        )
    }

    render () {
        const { mode } = this.state
        return (
            <div className='token-transfer-container'>
                {this.Header()}
                <div className='token-transfer-container--body'>
                    {mode === MODES.TRANSFER && <TokenTransferEditor 
                        defaultOperationMode={OPERATION_MODES.SINGLE} 
                        transferMode={TRANSFER_MODES.TRANSFER} />}
                    {mode === MODES.LENDING && 
                    <TokenLendingEditor 
                        shouldEnableAccountSelector 
                        shouldShowAccountCoinInfo />}
                </div>
            </div>
        )
    }
}

TokenTransferContainer.propTypes = {
    dispatch: PropTypes.func.isRequired
}

function mapStateToProps () {
    return {}
}

export default connect(mapStateToProps)(TokenTransferContainer)