import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { isMetSearchStringCriteria } from '../../util/util'
import { getSymbolFundingRatePrecision, isDynamicFundingRateSymbol, getSymbolAttributeByName } from '../../util/symbolUtil'

class SymbolFundingRates extends Component {
    constructor (props) {
        super(props)
        this.quoteOptions = {
            USD: {
                value: 'USD',
                name: 'USD Quote'
            },
            USDT: {
                value: 'USDT',
                name: 'USDT Quote'
            }
        }
        this.SORT_BY = {
            SYMBOL: 'SYMBOL',
            CURRENT_FUNDING_RATE: 'CURRENT_FUNDING_RATE',
            NEXT_FUNDING_RATE: 'NEXT_FUNDING_RATE'
        }
        this.SORT_ORDER = {
            ASC: 'ASC',
            DESC: 'DESC'
        }
        this.state = {
            quotes: props.quotes || Object.keys(this.quoteOptions),
            sortBy: this.SORT_BY.CURRENT_FUNDING_RATE,
            sortOrder: this.SORT_ORDER.DESC
        }
    }

    handleClickTableHeader (sortBy = this.SORT_BY.CURRENT_FUNDING_RATE) {
        this.setState(prevState => {
            return {
                sortBy: sortBy,
                sortOrder: sortBy === prevState.sortBy 
                    ? (prevState.sortOrder === this.SORT_ORDER.ASC ? this.SORT_ORDER.DESC : this.SORT_ORDER.ASC)
                    : [this.SORT_BY.CURRENT_FUNDING_RATE, this.SORT_BY.NEXT_FUNDING_RATE].includes(sortBy) ? this.SORT_ORDER.DESC 
                    : this.SORT_ORDER.ASC
            }
        })
    }

    renderFundingRateData (time, value, precision=4) {
        return (
            <div className='symbol-funding-rates--data'>
                {time && <div className='symbol-funding-rates--data--time'>{_.last(time.split(' ')).substring(0, 5)}</div>}
                <div className={'symbol-funding-rates--data--value ' + (Number(value) > 0 ? 'positive' : 'negative')}>{(Number(value) * 10000).toFixed(Math.max(precision - 2, 2))+ '%%'}</div>
            </div>
        )
    }

    QuoteSelectors () {
        const { quotes } = this.state
        return (
            <div className='symbol-funding-rates--quote-selectors'>
                {_.map(this.quoteOptions, quoteOption => {
                    const { value, name } = quoteOption
                    const isSelected = quotes.includes(value)
                    return (
                        <button className={'symbol-funding-rates--quote-selectors--item' + (isSelected ? ' selected' : '')} 
                            key={value}
                            onClick={() => {
                                this.setState({
                                    quotes: isSelected ? _.without(quotes, value) : quotes.concat([value])
                                })
                            }}>
                            {name}
                        </button>
                    )
                })}
            </div>
        )
    }

    render () {
        const { quotes, sortBy, sortOrder } = this.state
        const { fundingRates, searchString, coinsToFilter, haveQuoteSelectors, baseToFilter, shouldHideUnkownSymbols, symbolNames } = this.props
        const trimmedSearchString = searchString.trim().toLowerCase()
        const filteredFundingRates = _.filter(fundingRates, item => {
            const { base, quote } = getSymbolAttributeByName(item.symbol)
            return (trimmedSearchString.length === 0 || isMetSearchStringCriteria(item.symbol, trimmedSearchString))
                && (!haveQuoteSelectors || quotes.includes(quote))
                && (!baseToFilter || base === baseToFilter )
                && (!shouldHideUnkownSymbols || symbolNames.includes(item.symbol))
                && (_.isEmpty(coinsToFilter) || coinsToFilter.includes(base))
        })
        const seivedFundingRates = _.sortBy(filteredFundingRates, item => {
            return sortBy === this.SORT_BY.SYMBOL ? item.symbol
                : sortBy === this.SORT_BY.CURRENT_FUNDING_RATE ? Math.abs(item.current_funding_rate)
                : sortBy === this.SORT_BY.NEXT_FUNDING_RATE ? Math.abs(item.indicative_funding_rate)
                : null
        })

        if (sortOrder === this.SORT_ORDER.DESC) {
            seivedFundingRates.reverse()
        }

        return (
            <div className='symbol-funding-rates'>
                {haveQuoteSelectors && this.QuoteSelectors()}
                <table className='symbol-funding-rates--table'>
                    <thead>
                        <tr>
                            <th className={sortBy === this.SORT_BY.SYMBOL ? 'active' : ''} onClick={() => { this.handleClickTableHeader(this.SORT_BY.SYMBOL) }}>{'Symbol'}</th>
                            <th className={sortBy === this.SORT_BY.CURRENT_FUNDING_RATE ? 'active' : ''} onClick={() => { this.handleClickTableHeader(this.SORT_BY.CURRENT_FUNDING_RATE) }}>{'Current'}</th>
                            <th className={sortBy === this.SORT_BY.NEXT_FUNDING_RATE ? 'active' : ''} onClick={() => { this.handleClickTableHeader(this.SORT_BY.NEXT_FUNDING_RATE) }}>{'Next Est.'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(seivedFundingRates, (item) => {
                            const isDynamicFundingRate = isDynamicFundingRateSymbol(item.symbol)
                            const currentFundingRate = item.current_funding_rate
                            const currentSettlementTime = !isDynamicFundingRate ? item.timestamp : null
                            const nextFundingRate = item.indicative_funding_rate
                            const nextSettlementTime = !isDynamicFundingRate ? item.next_period : null
                            const precision = getSymbolFundingRatePrecision(item.symbol)
                            return (
                                <tr key={item.symbol}>
                                    <td><div className='symbol-funding-rates--table--symbol-name' title={item.symbol}>{item.symbol}</div></td>
                                    <td>{currentFundingRate && this.renderFundingRateData(currentSettlementTime, currentFundingRate, precision)}</td>
                                    <td>{nextFundingRate && this.renderFundingRateData(nextSettlementTime, nextFundingRate, precision)}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

SymbolFundingRates.propTypes = {
    searchString: PropTypes.string,
    coinsToFilter: PropTypes.array,
    haveQuoteSelectors: PropTypes.bool,
    baseToFilter: PropTypes.string,
    quotes: PropTypes.array,
    shouldHideUnkownSymbols: PropTypes.bool,

    fundingRates: PropTypes.object.isRequired,
    symbolNames: PropTypes.array.isRequired
}

SymbolFundingRates.defaultProps = {
    searchString: '',
    coinsToFilter: [],
    haveQuoteSelectors: false,
    shouldHideUnkownSymbols: false,
    quotes: []
}

function mapStateToProps (state) {
    return {
        fundingRates: state.symbol.fundingRates,
        symbolNames: Object.keys(state.symbol.items)
    }
}

export default connect(mapStateToProps)(SymbolFundingRates)