import _ from 'lodash'
import dotProp from 'dot-prop-immutable'
import { UPDATE_SETTING_SOUND_EFFECT } from './settingAction'

const initialState = {
    soundEffect: getSoundEffectSettingFromLocalStorage()
}

function saveSoundEffectToLocalStorage (soundEffect) {
    localStorage.soundEffectSetting = JSON.stringify(soundEffect)
}

function getSoundEffectSettingFromLocalStorage () {
    const defaultSoundEffectSetting = {
        events: {
            PROFILE_SWITCH_OFF: null,
            PROFILE_QTY_CAPPED: null,
            PROFILE_START_FAIL: null,
            PROFILE_PARA_LOAD_FAIL: null,
            PROFILE_CRASH: null,
            PROFILE_PAUSED: null,
            ACCOUNT_BALANCE_MARGIN_RATIO_WARNING: null
        },
        profileSwitchOffIncludingNAC: false,
    }
    const localStorageSoundEffect = !_.isEmpty(localStorage.soundEffectSetting) ? (JSON.parse(localStorage.soundEffectSetting) || {}) : {}
    const soundEffect = {}
    soundEffect.events = Object.assign({}, defaultSoundEffectSetting.events, _.pick(localStorageSoundEffect.events || {}, Object.keys(defaultSoundEffectSetting.events)))
    soundEffect.profileSwitchOffIncludingNAC = localStorageSoundEffect.profileSwitchOffIncludingNAC || defaultSoundEffectSetting.profileSwitchOffIncludingNAC
    return soundEffect
}

export function settingReducer (state=initialState, action) {
    let newState
    switch (action.type) {
        case UPDATE_SETTING_SOUND_EFFECT:
            newState = dotProp.merge(state, 'soundEffect', action.params)
            saveSoundEffectToLocalStorage(newState.soundEffect)
            return newState
            
        default:
            return state
    }
}