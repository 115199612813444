import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import uuidv4 from 'uuid/v4'
import moment from 'moment'
import _ from 'lodash'

import { CSVLink } from 'react-csv'
import { IoMdDownload } from 'react-icons/io'
import { AutoSizer, CellMeasurer, CellMeasurerCache, Table, Column } from 'react-virtualized'
import { MANUAL_ORDER_PROFILE, SYMBOLS_WITH_MUTLIPLIER_IN_BTC } from '../../configs/tradingConfig'
import { getNotional } from '../../util/tradingUtil'
import { getPricePrecisionBySymbolItem, getSymbolAttributeByName, INSTRUMENT_TYPES } from '../../util/symbolUtil'
import { toNumberWithSmartPrecision } from '../../util/util'
import { cancelProfileOrder, fetchFills, PROFILE_ORDER_STATUS } from './tradingAction'
import Checkbox from '../common/checkbox/Checkbox'
import Popup from '../common/popup/Popup'

export const MODES = {
    OPEN_ORDERS: 'OPEN_ORDERS',
    TRANSACTIONS: 'TRANSACTIONS'
}

const MODE_TITLES = {
    OPEN_ORDERS: 'Open Orders',
    TRANSACTIONS: 'Transactions'
}

const SORT_BYS = {
    OWNER: 'OWNER',
    SYMBOL: 'SYMBOL',
    ACCOUNT: 'ACCOUNT',
    PRICE: 'PRICE',
    QUANTITY: 'QUANTITY',
    NOTIONAL: 'NOTIONAL',
    TIMESTAMP: 'TIMESTAMP'
}

const SORT_ORDERS = {
    ASC: 'ASC',
    DESC: 'DESC'
}

class ManualOrderTable extends Component {
    constructor (props) {
        super(props)
        this.state = {
            sortBy: SORT_BYS.TIMESTAMP,
            sortOrder: SORT_ORDERS.DESC,
            shouldShowFillOnly: false,
            shouldShowThisWeekOnly: props.defaultMode === MODES.TRANSACTIONS,
            filledOrders: {},
            isFetching: false,
            fetchId: null,
            isAllOrdersSelected: true,
            selectedOrderIds: {},
            unselectedOrderIds: {},
            endTimestamp: null
        }
        this._mounted = false
        this.getFilledOrdersLock = false

        this.cellMeasurerCache = new CellMeasurerCache({
            fixedWidth: true,
            fixedHeight: false,
            minHeight: 29,
            defaultHeight: 29
        })

        this.prevClientOrderIds = {}
        this.manualOrderTableNode = null
        this.isScrollingTable = null
        this.endTimestampInputNode = null
    }

    componentDidMount () {
        const { defaultMode } = this.props
        this._mounted = true
        if (defaultMode === MODES.TRANSACTIONS) {
            this.getFilledOrders()
        }
    }

    componentDidUpdate (prevProps) {
        const { userToFilter: prevUserToFilter, coinToFilter: prevCoinToFilter, symbolNameToFilter: prevSymbolNameToFilter, 
            portfolioToFilter: prevPortfolioToFilter, accountNameToFilter: prevAccountNameToFilter, sideToFilter: prevSideToFilter } = prevProps
        const { defaultMode, userToFilter, coinToFilter, symbolNameToFilter, portfolioToFilter, accountNameToFilter, sideToFilter } = this.props
        if (!_.isEqual(prevUserToFilter, userToFilter) 
            || !_.isEqual(prevCoinToFilter, coinToFilter) 
            || !_.isEqual(prevSymbolNameToFilter, symbolNameToFilter) 
            || !_.isEqual(prevPortfolioToFilter, portfolioToFilter)
            || !_.isEqual(prevAccountNameToFilter, accountNameToFilter)
            || !_.isEqual(prevSideToFilter, sideToFilter)) {
            this._selectAllOrders()
            if (defaultMode === MODES.TRANSACTIONS) {
                this.getFilledOrders()
            }
        }
        
        this.tableNode.recomputeRowHeights()
    }

    componentWillUnmount () {
        this._mounted = false
    }

    _selectAllOrders () {
        this.setState({
            isAllOrdersSelected: true,
            selectedOrderIds: {},
            unselectedOrderIds: {}
        })
    }

    getFilledOrders (shouldFetchOlderRecords=false) {
        const { filledOrders, endTimestamp } = this.state
        const { dispatch, coinToFilter, symbolNameToFilter, portfolioToFilter, accountNameToFilter, userToFilter, sideToFilter, auth } = this.props
        const fetchId = uuidv4()
        let to = endTimestamp ? moment(endTimestamp).format('YYYY-MM-DDTHH:mm:ss') : null
        if (shouldFetchOlderRecords && !_.isEmpty(filledOrders)) {
            const oldestFilledOrder = _.minBy(Object.values(filledOrders), 'timestamp')
            to = oldestFilledOrder ? oldestFilledOrder.timestamp : null
        }

        this.setState({
            filledOrders: shouldFetchOlderRecords ? filledOrders : {},
            isFetching: true,
            fetchId
        })
        dispatch(fetchFills({
            shouldUseManualOrderProfile: true,
            tagName: 'ATWEB',
            currency: coinToFilter,
            symbol: symbolNameToFilter,
            portfolio: portfolioToFilter,
            account: accountNameToFilter,
            side: ['BUY', 'SELL'].includes(sideToFilter) ? sideToFilter.toLowerCase() : null,
            ownerUser: userToFilter === 'MINE' ? auth.username 
                : userToFilter === 'ALL' ? null
                : (userToFilter || null),
            sortBy: 'fill_ts',
            to,
            pageSize: 100
        })).then(body => {
            if (fetchId === this.state.fetchId && this._mounted) {
                if (body && _.isArray(body.info)) {
                    const orders = body.info.reduce((result, fill) => {
                        const clientOrderId = fill.order_ref
                        result[clientOrderId] = {
                            account_name: fill.acct_name,
                            avg_fill_price: Number(fill.avg_fill_price),
                            client_order_id: clientOrderId,
                            create_timestamp: fill.pending_ts,
                            cross_margin: fill.cross_margin || false,
                            hostname: fill.hostname,
                            left_qty: Number(fill.left_qty),
                            leverage: fill.leverage || 0,
                            margin: fill.margin === '1' || false,
                            postonly: fill.postonly === '1',
                            reduceonly: fill.reduceonly === '1',
                            price: Number(fill.price),
                            qty: Number(fill.qty),
                            reject_reason: fill.reject_reason || '',
                            side: fill.side,
                            status: fill.status,
                            symbol: fill.prod_name,
                            timestamp: fill.fill_ts,
                            owner_user: fill.owner_user,
                            cancel_user: fill.cancel_user
                        }
                        return result
                    }, {})
                    const newFilledOrders = shouldFetchOlderRecords ? Object.assign({}, this.state.filledOrders, orders) : orders
                    this.setState({ 
                        filledOrders: newFilledOrders,
                        isFetching: false
                    })
                } else {
                    this.setState({ isFetching: false })
                }
            }
        }).catch(error => {
            console.log('Fetch Fills Error:', error)
            this.setState({ isFetching: false })
        })
    }

    getOrderNotional (order) {
        const { symbolItems, btcIndexPricingItem, defaultMode } = this.props
        const { symbol: symbolName, qty, left_qty: leftQty, price, avg_fill_price: avgFillPrice } = order
        const result = {
            value: 0,
            unit: ''
        }
        if (symbolName && _.has(symbolItems, symbolName)) {
            const symbolItem = symbolItems[symbolName]
            const { quote, instrumentType } = getSymbolAttributeByName(symbolName)
            if (instrumentType !== INSTRUMENT_TYPES.REPO) {
                const shouldUseAvgPrice = Number(avgFillPrice) > 0 && avgFillPrice !== price
                result.value = getNotional({
                    symbolItem,
                    quantity: defaultMode === MODES.TRANSACTIONS ? Number(qty) - Number(leftQty) : Number(qty),
                    price: shouldUseAvgPrice ? avgFillPrice : price,
                    BTCUSDIndexLastPrice: SYMBOLS_WITH_MUTLIPLIER_IN_BTC.includes(symbolName) && _.has(btcIndexPricingItem, 'last')
                        ? btcIndexPricingItem.last
                        : null
                })
                result.unit = quote.toUpperCase()
            }
        }
        return result
    }

    getSeivedOrderItems () {
        const { sortBy, sortOrder, filledOrders, shouldShowFillOnly, shouldShowThisWeekOnly } = this.state
        const { defaultMode, manualOrders, coinToFilter, symbolNameToFilter, portfolioToFilter, accountNameToFilter, userToFilter, sideToFilter, auth, accountItems } = this.props
        const mergedOrders = Object.assign({}, defaultMode === MODES.TRANSACTIONS ? filledOrders : {}, manualOrders)
        const now = moment().utcOffset('+08:00')
        const lastSettlmentMoment = now.day() > 5 || (now.day() === 5 && now.hour() >= 16)
            ? now.day(5).hour(16).minute(0).second(0).millisecond(0)
            : now.day(-2).hour(16).minute(0).second(0).millisecond(0)

        const filteredOrders = _.filter(mergedOrders, order => {
            const accountItem = accountItems[order.account_name]
            return (_.isEmpty(coinToFilter) || getSymbolAttributeByName(order.symbol).base === coinToFilter)
                && (_.isEmpty(symbolNameToFilter) || order.symbol === symbolNameToFilter)
                && (_.isEmpty(portfolioToFilter) || (_.has(accountItem, 'portfolio_name') && accountItem.portfolio_name === portfolioToFilter))
                && (_.isEmpty(accountNameToFilter) || order.account_name === accountNameToFilter)
                && (_.isEmpty(sideToFilter) || sideToFilter === 'ALL' || order.side.includes(sideToFilter))
                && (userToFilter === 'ALL' || _.isNil(userToFilter) || (userToFilter === 'MINE' && order.owner_user === auth.username) || order.owner_user === userToFilter)
                && (
                    (defaultMode === MODES.OPEN_ORDERS && [PROFILE_ORDER_STATUS.SENT_TO_PF, PROFILE_ORDER_STATUS.PENDING, PROFILE_ORDER_STATUS.CONFIRM, PROFILE_ORDER_STATUS.PARTIAL_FILL, PROFILE_ORDER_STATUS.PENDING_CANCEL].includes(order.status))
                    || (defaultMode === MODES.TRANSACTIONS && [PROFILE_ORDER_STATUS.FILL, PROFILE_ORDER_STATUS.CANCELLED, PROFILE_ORDER_STATUS.REJECTED, PROFILE_ORDER_STATUS.EXPIRED].includes(order.status))
                )
                && (!shouldShowFillOnly || order.status === PROFILE_ORDER_STATUS.FILL || order.qty !== order.left_qty)
                && (!shouldShowThisWeekOnly || moment(order.timestamp).isAfter(lastSettlmentMoment))
        })
        const seivedOrders = _.sortBy(filteredOrders, order => {
            return sortBy === SORT_BYS.OWNER ? order.owner_user 
                : sortBy === SORT_BYS.SYMBOL ? order.symbol
                : sortBy === SORT_BYS.ACCOUNT ? order.account_name
                : sortBy === SORT_BYS.PRICE ? Number(order.price)
                : sortBy === SORT_BYS.QUANTITY ? Number(order.qty)
                : sortBy === SORT_BYS.NOTIONAL ? this.getOrderNotional(order).value
                : defaultMode === MODES.OPEN_ORDERS ? moment(order.sentTimestamp || order.create_timestamp || order.timestamp).valueOf()
                : moment(order.timestamp).valueOf()
        })
        if (sortOrder === SORT_ORDERS.DESC) {
            _.reverse(seivedOrders)
        }
        return seivedOrders
    }

    getOrderStats (orderItemsInSinglePair) {
        const { symbolItems, defaultMode } = this.props
        const result = {
            size: _.size(orderItemsInSinglePair),
            quantitySumInUnit: 0,
            quantitySumInAsset: 0,
            notionalSum: 0,
            notionalUnit: '',
            avgPrice: 0,
            asset: null,
            symbolSize: _.uniqBy(orderItemsInSinglePair, 'symbol').length,
            pricePrecision: 5
        }
        if (!_.isEmpty(orderItemsInSinglePair)) {
            const { base } = getSymbolAttributeByName(orderItemsInSinglePair[0].symbol)
            result.asset = base.toUpperCase()

            _.forEach(orderItemsInSinglePair, order => {
                const notional = this.getOrderNotional(order)
                const { symbol: symbolName, qty, left_qty: leftQty, price, avg_fill_price: avgFillPrice } = order
                const shouldUseAvgPrice = Number(avgFillPrice) > 0 && avgFillPrice !== price
                result.pricePrecision = Math.max(result.pricePrecision, getPricePrecisionBySymbolItem(symbolItems[symbolName]))
                result.quantitySumInUnit += (Number(qty) - (defaultMode === MODES.TRANSACTIONS ? Number(leftQty) : 0))
                result.quantitySumInAsset += (notional.value > 0 ? notional.value/(shouldUseAvgPrice ? avgFillPrice : price) : 0)
                result.notionalSum += notional.value
                result.notionalUnit = notional.unit
            })
            result.avgPrice = result.notionalSum / result.quantitySumInAsset
        }
        return result
    }

    _removeInnerScrollContainerPointerEvents () {
        if (this.manualOrderTableNode && this.manualOrderTableNode.ownerDocument) {
            const containers = this.manualOrderTableNode.ownerDocument.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer')
            if (!_.isEmpty(containers)) {
                containers[0].style.pointerEvents = null
            }
        }
    }

    handleScrollTableMain ({ clientHeight, scrollTop, scrollHeight }) {
        const { defaultMode } = this.props

        if (defaultMode === MODES.TRANSACTIONS 
            && (clientHeight + scrollTop + 1 >= scrollHeight)) {
            this.getFilledOrders(true)
        }

        if (window.document.hidden) {
            if (this.isScrollingTable) {
                window.clearTimeout(this.isScrollingTable)
            }
            this.isScrollingTable = setTimeout(() => {
                this._removeInnerScrollContainerPointerEvents()
            }, 66)
        }
    }

    handleClickCancelAllButton (orderItemsToCancel) {
        const { dispatch } = this.props
        _.forEach(orderItemsToCancel, order => {
            const { id_for_cancel: idForCancel } = order
            dispatch(cancelProfileOrder(MANUAL_ORDER_PROFILE.name, idForCancel))
        })
    }

    updateSortRule (newSortBy) {
        const { sortBy, sortOrder } = this.state
        const newSortOrder = newSortBy === sortBy 
            ? (sortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC)
            : newSortBy === SORT_BYS.TIMESTAMP ? SORT_ORDERS.DESC 
            : sortOrder
        this.setState({
            sortBy: newSortBy,
            sortOrder: newSortOrder
        })
    }

    isOrderSelected (orderItem) {
        const { client_order_id: clientOrderId } = orderItem
        const { isAllOrdersSelected, selectedOrderIds, unselectedOrderIds } = this.state
        return (isAllOrdersSelected && !_.has(unselectedOrderIds, clientOrderId))
            || (!isAllOrdersSelected && _.has(selectedOrderIds, clientOrderId))
    }

    shouldOrderShowAvgPrice (orderItem) {
        const { price, avg_fill_price: avgFillPrice } = orderItem
        return Number(avgFillPrice) > 0 && Number(avgFillPrice) !== Number(price)
    }

    shouldOrderShowLeftQty (orderItem) {
        const { qty, left_qty: leftQty } = orderItem
        return Number(leftQty) > 0 && leftQty !== qty
    }

    shouldOrderShowCancelUser (orderItem) {
        const { cancel_user: cancelUser } = orderItem
        return !_.isEmpty(cancelUser)
    }

    Tag (name, className) {
        return (
            <Fragment>
                <div className={`manual-order-table--tag ${className}`}>{name}</div>
                <br />
            </Fragment>
        )
    }

    Table (seivedOrderItems) {
        const { isAllOrdersSelected, selectedOrderIds, unselectedOrderIds, sortBy, sortOrder } = this.state
        const { dispatch, symbolItems, defaultMode, onClickSymbolName, onClickAccountName } = this.props
        const newClientOrderIds = _.reduce(seivedOrderItems, (result, orderItem) => {
            result[orderItem.client_order_id] = 1
            return result
        }, {})
        const diffClientOrderId = _.reduce(newClientOrderIds, (result, v, orderClientId) => {
            if (!_.has(this.prevClientOrderIds, orderClientId)) {
                result[orderClientId] = 1
            }
            return result
        }, {})
        if (!_.isEqual(this.prevClientOrderIds, newClientOrderIds)) {
            this.cellMeasurerCache.clearAll()
            this.prevClientOrderIds = newClientOrderIds
        }
        return (
            <AutoSizer>
                {({ width, height}) => {
                    return (
                        <Table
                            ref={(node) => { this.tableNode = node }}
                            className='manual-order-table--body'
                            headerHeight={19}
                            headerClassName={'manual-order-table--body--header'}
                            rowClassName={({ index }) => {
                                const rowData = seivedOrderItems[index]
                                if (rowData) {
                                    const { client_order_id: clientOrderId } = rowData
                                    return `manual-order-table--body--row ${index % 2 ? 'even-row' : 'odd-row'}${_.has(diffClientOrderId, clientOrderId) ? ' new-item' : ''}`
                                } else {
                                    return null
                                }
                            }}
                            height={height}
                            width={Math.max(width, 1200)}
                            rowCount={seivedOrderItems.length}
                            rowGetter={({ index }) => seivedOrderItems[index]}
                            rowHeight={({ index }) => {
                                const orderItem = seivedOrderItems[index]
                                const { margin, cross_margin: crossMargin, postonly: postOnly, reduceonly: reduceOnly, leverage } = orderItem
                                const featureSize = _.reduce([margin, crossMargin, postOnly, reduceOnly, leverage], (result, value) => {
                                    if (value) {
                                        result++
                                    }
                                    return result
                                }, 0)
                                const minHeight = Math.max(29, this.shouldOrderShowAvgPrice(orderItem)
                                    || this.shouldOrderShowLeftQty(orderItem)
                                    || this.shouldOrderShowCancelUser(orderItem) ? 37 : 0,
                                    featureSize * 21)
                                return Math.max(minHeight, this.cellMeasurerCache.rowHeight({ index }))
                            }}
                            overscanRowCount={5}
                            // deferredMeasurementCache={this.cellMeasurerCache}
                            sort={({ sortBy: newSortBy }) => {
                                const newSortByValue = newSortBy === 'owner_user' ? SORT_BYS.OWNER
                                    : newSortBy === 'symbol' ? SORT_BYS.SYMBOL
                                    : newSortBy === 'account_name' ? SORT_BYS.ACCOUNT
                                    : newSortBy === 'price' ? SORT_BYS.PRICE
                                    : newSortBy === 'qty' ? SORT_BYS.QUANTITY
                                    : newSortBy === 'notional' ? SORT_BYS.NOTIONAL
                                    : newSortBy === 'timestamp' ? SORT_BYS.TIMESTAMP
                                    : sortBy
                                this.setState({
                                    sortBy: newSortByValue,
                                    sortOrder: sortBy === newSortByValue ? (sortOrder === SORT_ORDERS.ASC ? SORT_ORDERS.DESC : SORT_ORDERS.ASC)
                                        : newSortBy === 'timestamp' ? SORT_ORDERS.DESC
                                        : SORT_ORDERS.ASC
                                })
                            }}
                            onScroll={({ clientHeight, scrollHeight, scrollTop }) => { this.handleScrollTableMain({ clientHeight, scrollHeight, scrollTop }) }}>
                            <Column 
                                dataKey={'index'}
                                disableSort
                                width={30}
                                flexGrow={0}
                                flexShrink={0}
                                label={'#'}
                                cellRenderer={({ rowIndex }) => (
                                    <span>{rowIndex + 1}</span>
                                )} />
                            <Column 
                                dataKey={'selected'}
                                className='manual-order-table--order-checkbox'
                                disableSort
                                width={20}
                                flexGrow={0}
                                flexShrink={0}
                                headerRenderer={() => (
                                    <div className='manual-order-table--all-orders-checkbox'>
                                        <Checkbox
                                            checked={isAllOrdersSelected}
                                            onChange={(newChecked) => {
                                                this.setState({
                                                    isAllOrdersSelected: newChecked,
                                                    selectedOrderIds: {},
                                                    unselectedOrderIds: {}
                                                })
                                            }} />
                                    </div>
                                )}
                                cellRenderer={({ rowData, rowIndex }) => {
                                    const { client_order_id: clientOrderId } = rowData
                                    const isSelected = this.isOrderSelected(rowData)
                                    return (
                                        <Checkbox 
                                            className='manual-order-table--order-checkbox'
                                            checked={isSelected} 
                                            onChange={(newChecked, isShiftKeyPressed) => {
                                                const newState = {}
                                                if (isAllOrdersSelected) {
                                                    if (newChecked) {
                                                        newState.unselectedOrderIds = _.omit(unselectedOrderIds, [clientOrderId])
                                                    } else {
                                                        newState.unselectedOrderIds = Object.assign({}, unselectedOrderIds, {
                                                            [clientOrderId]: 1
                                                        })
                                                    }
                                                } else {
                                                    if (newChecked) {
                                                        if (!isShiftKeyPressed) {
                                                            newState.selectedOrderIds = Object.assign({}, selectedOrderIds, {
                                                                [clientOrderId]: 1
                                                            })
                                                        } else {
                                                            const prevSelectedOrderItemIndex = _.findLastIndex(seivedOrderItems, orderItem => selectedOrderIds[orderItem.client_order_id] === 1, rowIndex)
                                                            if (prevSelectedOrderItemIndex >= 0) {
                                                                newState.selectedOrderIds = _.cloneDeep(selectedOrderIds)
                                                                for (let i = prevSelectedOrderItemIndex + 1; i <= rowIndex; i++ ) {
                                                                    newState.selectedOrderIds[seivedOrderItems[i].client_order_id] = 1
                                                                }
                                                            }
                                                        }
                                                    } else {
                                                        newState.selectedOrderIds = _.omit(selectedOrderIds, [clientOrderId])
                                                    }
                                                }
                                                this.setState(newState)
                                            }} />
                                    )
                                }} />
                            <Column 
                                dataKey={'client_order_id'}
                                className='manual-order-table--body--id'
                                disableSort
                                width={160}
                                flexGrow={1}
                                flexShrink={1}
                                label={'ID'} />
                            <Column 
                                dataKey={'owner_user'}
                                headerClassName={'sortable' + (sortBy === SORT_BYS.OWNER ? ' sorted' : '')}
                                width={60}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Owner'} />
                            <Column 
                                dataKey={'symbol'}
                                headerClassName={'sortable' + (sortBy === SORT_BYS.SYMBOL ? ' sorted' : '')}
                                className={'manual-order-table--symbol'}
                                width={170}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Symbol'} 
                                cellRenderer={({ rowData }) => {
                                    const { symbol } = rowData
                                    return (
                                        <span title={symbol} 
                                            onClick={() => { onClickSymbolName(symbol) }}>{symbol}</span>
                                    )
                                }} />
                            <Column 
                                dataKey={'account_name'}
                                headerClassName={'sortable' + (sortBy === SORT_BYS.ACCOUNT ? ' sorted' : '')}
                                className={'manual-order-table--account'}
                                width={140}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Account'} 
                                cellRenderer={({ rowData }) => {
                                    const { account_name: accountName } = rowData
                                    return (
                                        <span title={accountName} 
                                            onClick={() => { onClickAccountName(accountName) }}>{accountName}</span>
                                    )
                                }} />
                            <Column 
                                dataKey={'side'}
                                disableSort
                                width={70}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Side'}
                                cellRenderer={({ rowData }) => {
                                    const { side } = rowData
                                    return (
                                        <span className={`manual-order-table--side left-align ${side}`}>{side}</span>
                                    )
                                }} />
                            <Column 
                                dataKey={'price'}
                                headerClassName={'right-align sortable' + (sortBy === SORT_BYS.PRICE ? ' sorted' : '')}
                                className={'right-align'}
                                width={80}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Price'}
                                cellRenderer={({ rowData }) => {
                                    const { symbol, price, avg_fill_price: avgFillPrice } = rowData
                                    const shouldShowAvgPrice = this.shouldOrderShowAvgPrice(rowData)
                                    const pricePrecision = getPricePrecisionBySymbolItem(symbolItems[symbol])
                                    return (
                                        <div className='manual-order-table--price right-align'>
                                            {price}
                                            {shouldShowAvgPrice && 
                                            <span>{`Avg: ${toNumberWithSmartPrecision({ number: avgFillPrice, defaultPrecision: pricePrecision })}`}</span>}
                                        </div>
                                    )
                                }} />
                            <Column 
                                dataKey={'qty'}
                                headerClassName={'right-align sortable' + (sortBy === SORT_BYS.QUANTITY ? ' sorted' : '')}
                                className={'right-align'}
                                width={60}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Qty'}
                                cellRenderer={({ rowData }) => {
                                    const { qty, left_qty: leftQty } = rowData
                                    const shouldShowLeftQty = this.shouldOrderShowLeftQty(rowData)
                                    return (
                                        <div className='manual-order-table--qty right-align'>
                                            {qty}
                                            {shouldShowLeftQty && <span>{`Left: ${leftQty}`}</span>}
                                        </div>
                                    )
                                }} />
                            <Column 
                                dataKey={'notional'}
                                headerClassName={'right-align sortable' + (sortBy === SORT_BYS.NOTIONAL ? ' sorted' : '')}
                                className={'right-align'}
                                width={100}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Notional'}
                                cellRenderer={({ rowData }) => {
                                    const { instrumentType } = getSymbolAttributeByName(rowData.symbol)
                                    const notional = this.getOrderNotional(rowData)
                                    return (
                                        <span className='manual-order-table--notional right-align'>
                                            {instrumentType !== INSTRUMENT_TYPES.REPO 
                                            ? `${toNumberWithSmartPrecision({ number: notional.value, shouldReturnLocalString: true })} ${notional.unit}`
                                            : 'N/A'}
                                        </span>
                                    )
                                }} />
                            <Column 
                                dataKey={'feature'}
                                disableSort
                                width={80}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Feature'}
                                cellRenderer={({ rowData }) => {
                                    const { margin, cross_margin: crossMargin, postonly: postOnly, reduceonly: reduceOnly, leverage } = rowData
                                    return (
                                        <div className='manual-order-table--features left-align'>
                                            {postOnly && this.Tag('Post Only', 'post-only')}
                                            {reduceOnly && this.Tag('Reduce Only', 'reduce-only')}
                                            {margin && this.Tag('Margin', 'margin-trade')}
                                            {crossMargin && this.Tag('Cross Margin', 'cross-margin')}
                                            {leverage > 0 && this.Tag(`${leverage}X`, 'leverage')}
                                        </div>
                                    )
                                }} />
                            <Column 
                                dataKey={'timestamp'}
                                headerClassName={'sortable' + (sortBy === SORT_BYS.TIMESTAMP ? ' sorted' : '')}
                                width={120}
                                flexGrow={0}
                                flexShrink={0}
                                label={'Timestamp'}
                                cellRenderer={({ rowData }) => {
                                    const { timestamp, create_timestamp: createTimestamp, sentTimestamp } = rowData
                                    const timestampToDisplay = defaultMode === MODES.OPEN_ORDERS ? (sentTimestamp || createTimestamp || timestamp) : timestamp
                                    return (
                                        <span>{moment(timestampToDisplay).format('YYYY-MM-DD HH:mm:ss')}</span>
                                    )
                                }} />
                            <Column 
                                dataKey={'status'}
                                disableSort
                                width={75}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Status'}
                                cellRenderer={({ rowData }) => {
                                    const { cancel_user: cancelUser, status } = rowData
                                    const shouldShowCancelUser = this.shouldOrderShowCancelUser(rowData)
                                    return (
                                        <div className={`manual-order-table--status ${status}`}>
                                            {status}
                                            {shouldShowCancelUser && <span>{`by ${cancelUser}`}</span>}
                                        </div>
                                    )
                                }} />
                            {defaultMode === MODES.TRANSACTIONS 
                            && <Column dataKey={'reject_reason'}
                                disableSort
                                width={150}
                                flexGrow={1}
                                flexShrink={1}
                                label={'MSG'}
                                className={'manual-order-table--reject-message'} 
                                cellRenderer={({ rowData, parent, rowIndex }) => {
                                    const { reject_reason } = rowData
                                    return (
                                        <CellMeasurer
                                            cache={this.cellMeasurerCache}
                                            columnIndex={0}
                                            parent={parent}
                                            rowIndex={rowIndex}>
                                            <div className='manual-order-table--reject-reason'>{reject_reason || ''}</div>
                                        </CellMeasurer>
                                    )
                                }} />}
                            {defaultMode === MODES.OPEN_ORDERS
                            && <Column dataKey={'action'}
                                disableSort
                                width={60}
                                flexGrow={1}
                                flexShrink={0}
                                label={'Action'}
                                cellRenderer={({ rowData }) => {
                                    const { id_for_cancel: idForCancel, status } = rowData
                                    return [PROFILE_ORDER_STATUS.CONFIRM, PROFILE_ORDER_STATUS.PARTIAL_FILL].includes(status) && !_.isEmpty(idForCancel)
                                        ? (<button className='manual-order-table--cancel-button' onClick={() => { 
                                            dispatch(cancelProfileOrder(MANUAL_ORDER_PROFILE.name, idForCancel)) }}>
                                            {'CANCEL'}
                                        </button>)
                                        : null
                                }} />}
                        </Table>
                    )
                }}
            </AutoSizer>
        )
    }

    FileDownload ({ filename, seivedOrderItems }) {
        const { defaultMode } = this.props

        const data = seivedOrderItems.map(orderItem => {
            const { qty, left_qty, timestamp, symbol } = orderItem
            const { instrumentType } = getSymbolAttributeByName(symbol)
            const notional = this.getOrderNotional(orderItem)
            const newOrderItem = Object.assign({}, orderItem, {
                notional: instrumentType !== INSTRUMENT_TYPES.REPO 
                    ? `${toNumberWithSmartPrecision({ number: notional.value, shouldReturnLocalString: true })} ${notional.unit}`
                    : '',
                timestamp: defaultMode === MODES.OPEN_ORDERS && qty === left_qty ? null : timestamp
            })
            return newOrderItem
        })

        const headers = [
            { label: 'ID', key: 'client_order_id' },
            { label: 'Owner', key: 'owner_user' },
            { label: 'Account', key: 'account_name' },
            { label: 'Symbol', key: 'symbol' },
            { label: 'Side', key: 'side' },
            { label: 'Price', key: 'price' },
            { label: 'Avg Price', key: 'avg_fill_price' },
            { label: 'Qty', key: 'qty' },
            { label: 'Left Qty', key: 'left_qty' },
            { label: 'Notional', key: 'notional' },
            { label: 'Post Only', key: 'postonly' },
            { label: 'Reduce Only', key: 'reduceonly' },
            { label: 'Leverage', key: 'leverage' },
            { label: 'Margin', key: 'margin' },
            { label: 'Create Time', key: 'create_timestamp' },
            { label: 'Timestamp', key: 'timestamp' },
            { label: 'Status', key: 'status' },
            { label: 'Reject Reason', key: 'reject_reason' },
            { label: 'Cancel By', key: 'cancel_user' },
        ]

        return (
            <div className='manual-order-table--file-download'>
                <CSVLink 
                    filename={filename}
                    data={data} 
                    headers={headers}>
                    <button>{'Downlaod CSV'}</button>
                </CSVLink>
            </div>
        )
    }

    render () {
        const { shouldShowFillOnly, shouldShowThisWeekOnly, isFetching, endTimestamp } = this.state
        const { defaultMode } = this.props 
        const seivedOrderItems = this.getSeivedOrderItems()
        const selectedOrderItems = _.filter(seivedOrderItems, orderItem => this.isOrderSelected(orderItem))
        const selectedOrderItemsUniqByPair = _.uniqBy(selectedOrderItems, orderItem => {
            const { base, quote } = getSymbolAttributeByName(orderItem.symbol)
            return `${base}-${quote === 'USD' ? 'USDT' : quote}`
        })
        
        const shouldShowOrderStats = selectedOrderItemsUniqByPair.length === 1 && getSymbolAttributeByName(selectedOrderItemsUniqByPair[0].symbol).instrumentType !== INSTRUMENT_TYPES.REPO
        const orderStats = shouldShowOrderStats ? this.getOrderStats(selectedOrderItems) : null
        const orderItemsCanCancel = _.filter(selectedOrderItems, order => [PROFILE_ORDER_STATUS.CONFIRM, PROFILE_ORDER_STATUS.PARTIAL_FILL].includes(order.status))

        return (
            <div className='manual-order-table' ref={(node) => { this.manualOrderTableNode = node }}>
                <div className='manual-order-table--header'>
                    <div className='manual-order-table--header--title'>{MODE_TITLES[defaultMode]}</div>
                    {shouldShowOrderStats && !_.isEmpty(orderStats) && 
                    <div className='manual-order-table--header--order-stats'>
                        <div className='manual-order-table--header--order-stats--section'>
                            {'#: '}<span>{orderStats.size}</span>
                        </div>
                        {orderStats.symbolSize > 1 && <div className='manual-order-table--header--order-stats--section'>
                            {'Symbol #: '}<span>{orderStats.symbolSize}</span>
                        </div>}
                        <div className='manual-order-table--header--order-stats--section'>
                            {'Notional Sum: '}<span>{`${toNumberWithSmartPrecision({ number: orderStats.notionalSum, shouldReturnLocalString: true})} ${orderStats.notionalUnit}`}</span>
                        </div>
                        <div className='manual-order-table--header--order-stats--section'>
                            {`Qty Sum (${orderStats.asset}): `}<span>{toNumberWithSmartPrecision({ number: orderStats.quantitySumInAsset, shouldReturnLocalString: true})}</span>
                        </div>
                        {orderStats.symbolSize === 1 && <div className='manual-order-table--header--order-stats--section'>
                            {'Qty Sum (Unit): '}<span>{toNumberWithSmartPrecision({ number: orderStats.quantitySumInUnit, shouldReturnLocalString: true})}</span>
                        </div>}
                        <div className='manual-order-table--header--order-stats--section'>
                            {'Avg Price: '}<span>{toNumberWithSmartPrecision({ number: orderStats.avgPrice, shouldReturnLocalString: true, defaultPrecision: orderStats.pricePrecision })}</span>
                        </div>
                    </div>}
                    {isFetching && <div className='manual-order-table--header--is-fetching'>{'Fetching ...'}</div>}
                    <div className='manual-order-table--header--right-section'>
                        {!_.isEmpty(seivedOrderItems) && 
                        <Popup
                            on={'click'}
                            className='manual-order-table--csv-download-popup'
                            trigger={<button className='manual-order-table--csv-download-popup--trigger'><IoMdDownload /></button>}>
                            {this.FileDownload({ 
                                filename: `${MODE_TITLES[defaultMode]}_${moment().format('YYYY-MM-DD-HH_mm_ss')}.csv`,
                                seivedOrderItems
                            })}
                        </Popup>}
                        {defaultMode === MODES.OPEN_ORDERS && orderItemsCanCancel.length > 0 && 
                        <button 
                            className='manual-order-table--header--cancel-all-button'
                            onClick={() => { this.handleClickCancelAllButton(orderItemsCanCancel) }}>
                            {`Cancel All (${orderItemsCanCancel.length})`}
                        </button>}
                        {defaultMode === MODES.TRANSACTIONS && <div className='manual-order-table--filters'>
                            <div className='manual-order-table--fill-only-filter'>
                                <span>{'Fill Only'}</span>
                                <Checkbox 
                                    checked={shouldShowFillOnly} 
                                    onChange={(newChecked) => { this.setState({ shouldShowFillOnly: newChecked }) }} />
                            </div>
                            <div className='manual-order-table--this-week-only-filter' title={'Start from last settlement date'}>
                                <span>{'This Week Only'}</span>
                                <Checkbox 
                                    checked={shouldShowThisWeekOnly}
                                    onChange={(newChecked) => { this.setState({ shouldShowThisWeekOnly: newChecked }) }} />
                            </div>
                            <Popup className='manual-order-table--end-timestamp-popup'
                                on={'click'}
                                trigger={<div className='manual-order-table--end-timestamp-popup--trigger'>
                                    <label>{'Until'}</label>
                                    <span>{endTimestamp ? moment(endTimestamp).format('DD/MM/YYYY, hh:mm:ss A') : 'NOW'}</span>
                                </div>}>
                                <button className='manual-order-table--end-timestamp-popup--now-button'
                                    onClick={() => {
                                        this.setState({ 
                                            endTimestamp: null,
                                            filledOrders: {}
                                        }, () => {
                                            this.getFilledOrders()
                                        })
                                    }}>{'NOW'}</button>
                                <input className='manual-order-table--end-timestamp-popup--input' 
                                    ref={(node) => { this.endTimestampInputNode = node }}
                                    type={'datetime-local'}
                                    defaultValue={moment(endTimestamp).format('YYYY-MM-DDTHH:mm:ss')} 
                                    step={1} />
                                <button className='manual-order-table--end-timestamp-popup--confirm-button' onClick={() => {
                                    const value = this.endTimestampInputNode.value
                                    this.setState({ 
                                        endTimestamp: moment(value).toISOString(),
                                        filledOrders: {}
                                    }, () => {
                                        this.getFilledOrders()
                                    })
                                }}>{'GO'}</button>
                            </Popup>
                        </div>}
                    </div>
                </div>
                <div className='manual-order-table--main'>
                    {this.Table(seivedOrderItems)}
                </div>
            </div>
        )
    }
}

ManualOrderTable.propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    manualOrders: PropTypes.object.isRequired,
    symbolItems: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,
    btcIndexPricingItem: PropTypes.object,

    defaultMode: PropTypes.oneOf(Object.values(MODES)).isRequired,
    userToFilter: PropTypes.string,
    portfolioToFilter: PropTypes.string,
    coinToFilter: PropTypes.string,
    symbolNameToFilter: PropTypes.string,
    accountNameToFilter: PropTypes.string,
    sideToFilter: PropTypes.oneOf(['ALL', 'BUY', 'SELL']),
    onClickSymbolName: PropTypes.func,
    onClickAccountName: PropTypes.func
}

ManualOrderTable.defaultProps = {
    onClickSymbolName: () => {},
    onClickAccountName: () => {}
}

function mapStateToProps (state) {
    return {
        auth: state.auth,
        manualOrders: state.trading.manualOrders,
        symbolItems: state.symbol.items,
        accountItems: state.account.items,
        btcIndexPricingItem: _.has(state.symbol.pricings, 'btc_usd_OKEX_INDEX') ? state.symbol.pricings['btc_usd_OKEX_INDEX'] : null
    }
}

export default connect(mapStateToProps)(ManualOrderTable)



