import React, { Component } from 'react'
import { batch, connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import NewWindow from 'react-new-window'
import TokenTransferEditor, { OPERATION_MODES, TRANSFER_MODES } from '../account/TokenTransferEditor'
import { clearLayoutBulkTransferWindowConfig, clearLayoutBulkTransferWindowId } from '../layout/layoutAction'

class BulkTransferWindow extends Component {
    constructor (props) {
        super(props)
        this.reactNewWindowNode = null
    }

    componentDidUpdate (prevProps) {
        const { windowId: prevWindowId } = prevProps
        const { windowId } = this.props
        if (!_.isEqual(prevWindowId, windowId) && _.has(this.reactNewWindowNode, 'window')) {
            this.reactNewWindowNode.window.focus()
        }
    }

    render () {
        const { dispatch, windowId, bulkTransferConfig } = this.props

        return windowId ? (
            <NewWindow
                ref={(node) => { this.reactNewWindowNode = node }}
                name={'Transfer'}
                title={'Bulk Transfer - Antelope Technology'}
                features={{ width: 1200, height: 900 }}
                onUnload={() => { 
                    this.reactNewWindowNode = null
                    batch(() => {
                        dispatch(clearLayoutBulkTransferWindowId()) 
                        dispatch(clearLayoutBulkTransferWindowConfig())
                    })
                }}>
                <div className='bulk-transfer-window'>
                    <div className='bulk-transfer-window--header'>
                        <div className='bulk-transfer-window--title'>{'Bulk Transfer'}</div>
                    </div>
                    <div className='bulk-transfer-window--main'>
                        <TokenTransferEditor 
                            defaultOperationMode={OPERATION_MODES.BULK}
                            transferMode={TRANSFER_MODES.TRANSFER} 
                            bulkTransferConfig={bulkTransferConfig} />
                    </div>
                </div>
            </NewWindow>
        ) : null
    }
}

BulkTransferWindow.propTypes = {
    dispatch: PropTypes.func.isRequired,
    windowId: PropTypes.string,
    bulkTransferConfig: PropTypes.object
}

function mapStateFromProps (state) {
    return {
        windowId: state.layout.bulkTransferWindowId,
        bulkTransferConfig: state.layout.bulkTransferWindowConfig
    }
}

export default connect(mapStateFromProps)(BulkTransferWindow)