import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import SearchSelect from '../common/searchSelect/SearchSelect'
import ManualOrderTable, { MODES as manualOrderTableModes } from './ManualOrderTable'
import { getSymbolAttributeByName, INSTRUMENT_TYPES } from '../../util/symbolUtil'
import { getPortfolioNames } from '../../util/accountUtil'
import { TRADERS } from '../../configs/config'

export const USERS = {
    MINE: 'MINE',
    ...TRADERS,
    ALL: 'ALL'
}

export const SIDES = {
    ALL: 'ALL',
    BUY: 'BUY',
    SELL: 'SELL'
}

const ALL_COINS = 'ALL'
const ALL_SYMBOLS = 'ALL'
const ALL_PORTFOLIOS = 'ALL'
const ALL_ACCOUNTS = 'ALL'

class ManualOrderContainter extends Component {

    constructor (props) {
        super(props)
        this.state = {
            user: USERS.MINE,
            coin: ALL_COINS,
            symbolName: ALL_SYMBOLS,
            portfolio: ALL_PORTFOLIOS,
            accountName: ALL_ACCOUNTS,
            side: SIDES.ALL
        }
        this.containerMainNode = null
        this.draggableLineNode = null
        this.openOrdersNode = null
        this.transactionsNode = null
    }

    componentDidUpdate (prevProps) {
        const { symbolToFilter: prevSymbolToFilter } = prevProps
        const { symbolToFilter } = this.props
    
        const { symbolName } = this.state
        if (symbolName !== ALL_SYMBOLS && !_.isEqual(prevSymbolToFilter, symbolToFilter) 
            && symbolToFilter && !_.isNil(symbolToFilter.symbolName) && !_.isNil(symbolToFilter.id) && !_.isEqual(symbolName, symbolToFilter.symbolName)) {
            this.updateState({
                symbolName: symbolToFilter.symbolName,
                accountName: ALL_ACCOUNTS
            })
        }
    }

    updateState (newParams={}) {
        this.setState(prevState => {
            return Object.assign({}, prevState, newParams)
        })
    }

    handleDragLine (e) {
        let newSplitTop = ((e.clientY - this.containerMainNode.offsetTop) / (this.containerMainNode.clientHeight)) * 100
        if (newSplitTop && newSplitTop > 0 && this.openOrdersNode && this.transactionsNode && this.draggableLineNode) {
            newSplitTop = Math.max(Math.min(newSplitTop, 80), 20) + '%'
            this.openOrdersNode.style.height = newSplitTop
            this.transactionsNode.style.top = newSplitTop
            this.draggableLineNode.style.top = newSplitTop
        }
    }

    Header () {
        const { user, coin, symbolName, portfolio, accountName, side } = this.state
        const { symbolItems, accountItems, onChangeSymbol } = this.props
        const filteredSymbolItems = _.filter(symbolItems, symbolItem => {
            const { instrumentType } = getSymbolAttributeByName(symbolItem.symbol_name)
            return instrumentType !== INSTRUMENT_TYPES.INDEX && symbolItem.trading === '1'
        })
        const userOptions = _.map(USERS, user => {
            return {
                value: user,
                name: user
            }
        })
        const coins = _.uniq(_.map(filteredSymbolItems, symbolItem => getSymbolAttributeByName(symbolItem.symbol_name).base)).sort()
        const coinOptions = [{
            value: ALL_COINS,
            name: ALL_COINS
        }].concat(_.map(coins, coin => {
            return {
                value: coin,
                name: coin
            }
        }))

        const symbolOptions = [{
            value: ALL_SYMBOLS,
            name: ALL_SYMBOLS
        }].concat(_.map(filteredSymbolItems, symbolItem => {
            return {
                value: symbolItem.symbol_name,
                name: symbolItem.symbol_name
            }
        }))

        const portfolioNames = getPortfolioNames()
        const portfolioOptions = _.concat([{
            value: ALL_PORTFOLIOS,
            name: ALL_PORTFOLIOS
        }], _.map(portfolioNames, portfolioName => {
            return {
                value: portfolioName,
                name: portfolioName
            }
        }))

        const accountOptions = [{
            value: ALL_ACCOUNTS,
            name: ALL_ACCOUNTS
        }].concat(_.sortBy(_.map(accountItems, accountItem => {
            return {
                value: accountItem.account_name,
                name: accountItem.account_name
            }
        }), 'name'))

        const sideOptions = _.map(SIDES, side => {
            return {
                value: side,
                name: side
            }
        })

        return (
            <div className='manual-order-container--header'>
                <div className='manual-order-container--header--filter'>
                    <span>{'User'}</span>
                    <SearchSelect 
                        hideSearchBar
                        value={user} 
                        options={userOptions} 
                        onChange={(newOption) => { this.setState({ user: newOption.value }) }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Coin'}</span>
                    <SearchSelect 
                        hasClearButton
                        value={coin}
                        options={coinOptions}
                        onChange={(newOption) => { 
                            this.setState({ 
                                coin: newOption.value,
                                symbolName: ALL_SYMBOLS
                            })
                        }} 
                        onClickClearButton={() => { 
                            this.setState({ 
                                coin: ALL_COINS
                            }) 
                        }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Symbol'}</span>
                    <SearchSelect 
                        hasClearButton
                        value={symbolName}
                        options={symbolOptions}
                        onChange={(newOption) => { 
                            this.setState({ 
                                coin: coin !== ALL_COINS && !_.isEmpty(coin) && getSymbolAttributeByName(newOption.value).base !== coin ? ALL_COINS : coin,
                                symbolName: newOption.value
                            }) 
                            if (newOption.value !== ALL_SYMBOLS) {
                                onChangeSymbol(newOption.value)
                            }
                        }} 
                        onClickClearButton={() => { this.setState({ symbolName: ALL_SYMBOLS }) }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Portfolio'}</span>
                    <SearchSelect 
                        hasClearButton
                        value={portfolio}
                        options={portfolioOptions} 
                        onChange={(newOption) => { this.setState({ portfolio: newOption.value }) }} 
                        onClickClearButton={() => { this.setState({ portfolio: ALL_PORTFOLIOS }) }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Account'}</span>
                    <SearchSelect 
                        hasClearButton
                        value={accountName}
                        options={accountOptions} 
                        onChange={(newOption) => { this.setState({ accountName: newOption.value }) }} 
                        onClickClearButton={() => { this.setState({ accountName: ALL_ACCOUNTS }) }} />
                </div>
                <div className='manual-order-container--header--filter'>
                    <span>{'Side'}</span>
                    <SearchSelect 
                        hideSearchBar
                        value={side}
                        options={sideOptions} 
                        onChange={(newOption) => { this.setState({ side: newOption.value }) }} />
                </div>
                <button className='manual-order-container--header--reset-button' onClick={() => {
                    this.setState({
                        user: USERS.MINE,
                        coin: ALL_COINS,
                        symbolName: ALL_SYMBOLS,
                        accountName: ALL_ACCOUNTS,
                        side: SIDES.ALL
                    })
                }}>{'RESET'}</button>
            </div>
        )
    }

    render () {
        const { user, coin, symbolName, portfolio, accountName, side } = this.state
        const { onChangeSymbol } = this.props
        const tableCoinToFilter = coin !== ALL_COINS ? coin : null
        const tableSymbolNameToFilter = symbolName !== ALL_SYMBOLS ? symbolName : null
        const tablePortfolioToFilter = portfolio !== ALL_PORTFOLIOS ? portfolio : null
        const tableAccountNameToFilter = accountName !== ALL_ACCOUNTS ? accountName : null
        const tableSideToFilter = side !== SIDES.ALL ? side : null
        return (
            <div className='manual-order-container'>
                {this.Header()}
                <div className='manual-order-container--main' ref={(node) => { this.containerMainNode = node }}>
                    <div className='manual-order-container--open-orders' ref={(node) => { this.openOrdersNode = node }}>
                        <ManualOrderTable 
                            defaultMode={manualOrderTableModes.OPEN_ORDERS}
                            userToFilter={user}
                            coinToFilter={tableCoinToFilter}
                            symbolNameToFilter={tableSymbolNameToFilter}
                            portfolioToFilter={tablePortfolioToFilter}
                            accountNameToFilter={tableAccountNameToFilter} 
                            sideToFilter={tableSideToFilter}
                            onClickSymbolName={(newSymbolName) => { 
                                this.setState({ symbolName: newSymbolName }) 
                                onChangeSymbol(newSymbolName)
                            }} 
                            onClickAccountName={(accountName) => { this.setState({ accountName }) }} />
                    </div>
                    <div className='manual-order-container--draggable-line' 
                        ref={(node) => { this.draggableLineNode = node }}
                        draggable
                        onDragOver={(e) => { e.preventDefault() }}
                        onDrag={(e) => { this.handleDragLine(e) }} />
                    <div className='manual-order-container--transactions' ref={(node) => { this.transactionsNode = node }}>
                        <ManualOrderTable 
                            defaultMode={manualOrderTableModes.TRANSACTIONS}
                            userToFilter={user}
                            coinToFilter={tableCoinToFilter}
                            symbolNameToFilter={tableSymbolNameToFilter}
                            portfolioToFilter={tablePortfolioToFilter}
                            accountNameToFilter={tableAccountNameToFilter}
                            sideToFilter={tableSideToFilter}
                            onClickSymbolName={(symbolName) => { 
                                this.setState({ symbolName }) 
                                onChangeSymbol(symbolName)
                            }} 
                            onClickAccountName={(accountName) => { this.setState({ accountName }) }} />
                    </div>
                </div>
            </div>
        )
    }
}

ManualOrderContainter.propTypes = {
    symbolToFilter: PropTypes.shape({
        symbolName: PropTypes.string,
        id: PropTypes.number
    }),

    symbolItems: PropTypes.object.isRequired,
    accountItems: PropTypes.object.isRequired,

    onChangeSymbol: PropTypes.func
}

ManualOrderContainter.defaultProps = {
    onChangeSymbol: () => {}
}

function mapStateToProps (state) {
    return {
        symbolItems: state.symbol.items,
        accountItems: state.account.items
    }
}

export default connect(mapStateToProps)(ManualOrderContainter)