import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import dotProp from 'dot-prop-immutable'
import moment from 'moment'
import _ from 'lodash'

import Popup from '../common/popup/Popup'
import { MdExpandLess, MdExpandMore, MdTune } from 'react-icons/md'
import { IoMdSwap, IoIosTimer } from 'react-icons/io'
import { FiAlertCircle } from 'react-icons/fi'

import ProfileEditor from './ProfileEditor'
import ProfileActionPopup from './ProfileActionPopup'
import ProfileCopyPopup from './ProfileCopyPopup'
import QuoteSymbolTable from './QuoteSymbolTable'
import HedgeSymbolTable from './HedgeSymbolTable'
import ProfileAccounts from './ProfileAccounts'
import SearchSelect from '../common/searchSelect/SearchSelect'
import SaveButton from '../common/saveButton/SaveButton'
import SymbolPopup from '../symbol/NewSymbolPopup'
import TimerItem from '../timer/TimerItem'
import TimerEditor from '../timer/TimerEditor'
import FillContainer from '../trading/FillContainer'
import ProfileNotificationPopup from './ProfileNotificaionPopup'
import ProfileManagers from './ProfileManagers'
import ProfileFillStatistics from './ProfileFillStatistics'

import { updateProfileParams, updateProfileLogLevel } from './profileAction'
import { LOG_LEVELS } from '../../configs/profileConfig'
import { getSymbolAttributeByName } from '../../util/symbolUtil'
import { toFixedNumber } from '../../util/util'
import { FaCalculator } from 'react-icons/fa'

class ProfileItem extends Component {
    constructor (props) {
        super(props)
        this.state = {
            profile: props.profile,
            showEditor: false,
            isSaving: false,
            isSaved: false,
            message: null,
            editingTimer: null,
            lastParaLoadFailNotificationId: null 
        }
        this.toggleExpandButton = null
    }

    static getDerivedStateFromProps (props, state) {
        const { notifications } = props
        const firstNotification = _.head(notifications) 
        if (!_.isNil(firstNotification) 
            && ['PARA_LOAD_FAIL', 'PARA_UPDATED'].includes(firstNotification.type)
            && firstNotification.profileId === state.profile.id) {
            if (firstNotification.type === 'PARA_LOAD_FAIL'
                && !_.isEqual(firstNotification.id, state.lastParaLoadFailNotificationId)) {
                return {
                    message: `PARA_LOAD_FAIL: ${firstNotification.message}`,
                    lastParaLoadFailNotificationId: firstNotification.id
                }
            } else if (firstNotification.type === 'PARA_UPDATED' 
                && !_.isNil(state.lastParaLoadFailNotificationId)
                && (state.message || '').includes('PARA_LOAD_FAIL')) {
                return {
                    message: null,
                    lastParaLoadFailNotificationId: null
                }
            }
        } else {
            return null
        }
    }

    shouldComponentUpdate (nextProps, nextState) {
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
    }
    
    componentDidUpdate (prevProps) {
        const { profile: prevProfile } = prevProps
        const { profile, onChangeItemHeight } = this.props
        if (!_.isEqual(prevProps.profile, profile)) {
            this._updateProfile(profile)
        }
        if (!prevProfile.start_timestamp && profile.start_timestamp) {
            setTimeout(() => {
                onChangeItemHeight()
            }, 0)
        }
    }

    _updateProfile (profile) {
        this.setState({
            profile: profile,
            message: null
        })
    }

    _saveProfile () {
        const {profile, isSaving, isSaved} = this.state
        const {dispatch} = this.props
        if (!isSaving && !isSaved) {
            this.setState({ isSaving: true, message: null })
            dispatch(updateProfileParams(profile.id, _.pick(profile, ['accounts', 'legs', 'params']))).then((response) => {
                this.setState({
                    isSaving: false,
                    isSaved: response && response.status === 200,
                    message: _.isError(response) ? response.toString() : null
                })
            })
        }
    }

    renderTimerPopup () {
        const { profile } = this.state
        const { profileTimers } = this.props
        return (
            <Popup
                className='profile-item--timer-popup' 
                margin={0}
                on={'click'}
                trigger={<button className='profile-item--timer-popup--trigger-button'><IoIosTimer /></button>}>
                {profileTimers.map((timerItem, index) => {
                    return (
                        <div className='profile-item--timer-popup--item' key={index}
                            onClick={() => { this.setState({ editingTimer: timerItem }) }}>
                            <TimerItem 
                                timerItem={timerItem} 
                                pickProfileId={profile.id} />
                        </div>
                    )
                })}
            </Popup>
        )
    }

    renderFillStatisticsPopup () {
        const { profile } = this.state
        return (
            <Popup 
                className='profile-item--fill-statistics-popup'
                margin={0}
                on={'click'}
                trigger={<button className='profile-item--fill-statistics-popup--trigger-button'><FaCalculator /></button>}>
                <ProfileFillStatistics profileId={profile.id} />
            </Popup>
        )
    }

    renderFillPopup () {
        const { profile } = this.state
        return (
            <Popup 
                className='profile-item--fill-popup'
                margin={0}
                on={'click'}
                trigger={<button className='profile-item--fill-popup--trigger-button'><IoMdSwap /></button>}>
                <FillContainer profileId={profile.id} />
            </Popup>
        )
    }

    renderHeader () {
        const { dispatch, expanded, onClickToggleExpand, runningState, profileTimers, dragHandle } = this.props
        const { profile } = this.state
        
        const profileStartStopStatus = profile.isStarting ? 'starting' : profile.isStopping ? 'stopping' : profile.started ? 'started' : 'stopped'
        const profileResumePauseStatus = profile.isResuming ? 'resuming' : profile.isPausing ? 'pausing' : profile.resumed ? 'resumed' : 'paused'
        const loopStatus = profile.params.LOOP ? 'loop-on' : 'loop-off'
        const logLevelOptions = Object.values(LOG_LEVELS).map(logLevel => {
            return {
                value: logLevel.value,
                name: logLevel.value,
                disabled: logLevel.disabled
            }
        })

        return (
            <div className='profile-item--header clearfix' onDoubleClick={() => {
                if (this.toggleExpandButton) {
                    this.toggleExpandButton.click()
                }
            }}>
                <div className='profile-item--header--left'>
                    <div className='profile-item--header--left--main'>
                        <div className='profile-item--name'>{profile.name}</div>
                        <div className='profile-item--user-hostname'>
                            <b>{profile.user}</b>{' @ '}<b>{profile.hostname}</b>
                        </div>
                        <div className='profile-item--managers' onDoubleClick={(e) => { e.stopPropagation() }}>
                            <ProfileManagers profileId={profile.id} />
                        </div>
                    </div>
                    <div className='profile-item--header--state-row'>
                        {profile.crashed && <div className='profile-item--status crashed-status'>
                            <FiAlertCircle className='profile-item--status--crashed-icon' />
                            <div className='profile-item--status--label'>{'CRASHED'}</div>
                        </div>}
                        <div className='profile-item--status start-stop-status'>
                            <div className={`profile-item--status--bulb vertical-centered ${profileStartStopStatus}`} />
                            <div className='profile-item--status--label'>{profileStartStopStatus.toUpperCase()}</div>
                        </div>
                        <div className='profile-item--status loop-status'>
                            <div className={`profile-item--status--bulb vertical-centered ${loopStatus}`} />
                            <div className='profile-item--status--label'>{loopStatus === 'loop-on' ? 'LOOP ON' : 'LOOP OFF'}</div>
                        </div>
                        {profile.started && !profile.isStarting && !profile.isStopping &&
                        <div className='profile-item--status resume-pause-status'>
                            <div className={`profile-item--status--bulb vertical-centered ${profileResumePauseStatus}`} />
                            <div className={'profile-item--status--label'}>
                                {profileResumePauseStatus.toUpperCase()}
                                {profileResumePauseStatus === 'paused' && <span>{`: ${profile.pauseReason || 'Unkown Reason'} @ 
                                    ${_.has(profile, 'pauseTimestamp') && moment(profile.pauseTimestamp).isValid() ? moment(profile.pauseTimestamp).format('MM-DD HH:mm:ss') : 'Unkown Time'}`}</span>}
                            </div>
                        </div>}
                    </div>
                    {(_.has(profile, 'clean_start_timestamp') || _.has(profile, 'start_timestamp') || _.has(runningState, 'strategyInfo.timestamp')) && 
                    <div className='profile-item--header--left--timestamps'>
                        {_.has(profile, 'clean_start_timestamp') && profile.clean_start_timestamp !== '*' && 
                        <div className='profile-item--header--timestamp'>
                            <span>{'Clean Started'}</span>
                            {moment(profile.clean_start_timestamp).format('MM-DD HH:mm:ss')}
                        </div>}
                        {_.has(profile, 'start_timestamp') && profile.start_timestamp !== '*' &&
                        <div className='profile-item--header--timestamp'>
                            <span>{'Started'}</span>
                            {moment(profile.start_timestamp).format('MM-DD HH:mm:ss')}
                        </div>}
                        {_.has(runningState, 'strategyInfo.timestamp') && 
                        <div className='profile-item--header--timestamp'>
                            <span>{'Last Updated'}</span>
                            {moment(runningState.strategyInfo.timestamp).format('HH:mm:ss')}
                        </div>}
                    </div>}
                </div>
                <div className='profile-item--header--right' onDoubleClick={(e) => { e.stopPropagation() }}>
                    {dragHandle}
                    {profile.started && profile.log_level && 
                    <div className='profile-item--header--log-level' onDoubleClick={(e) => { e.stopPropagation() }}>
                        <span>{'LOG'}</span>
                        <SearchSelect className='profile-item--header--log-level-select'
                            hideSearchBar
                            value={profile.log_level === 'INVALID' ? 'INFO' : profile.log_level} 
                            options={logLevelOptions} 
                            onChange={(newOption) => { dispatch(updateProfileLogLevel(profile.id, newOption.value)) }} />
                    </div>}
                    <ProfileNotificationPopup profileId={profile.id} />
                    <ProfileCopyPopup 
                        triggerClassName='profile-item--copy-button'
                        profileItem={profile} />
                    {this.renderFillStatisticsPopup()}
                    {this.renderFillPopup()}
                    {!_.isEmpty(profileTimers) && this.renderTimerPopup()}
                    <ProfileActionPopup 
                        triggerClassName='profile-item--action-trigger-button'
                        profileItem={profile} />
                    <button className='profile-item--tune-button' title={'Settings'}
                        onClick={() => { this.setState({ showEditor: true }) }}><MdTune /></button>
                    <button className='profile-item--toggle-expand-button'
                        ref={(node) => { this.toggleExpandButton = node }}
                        title={expanded ? 'Collapse' : 'Expand'}
                        onClick={(e) => { 
                            e.stopPropagation()
                            onClickToggleExpand(!expanded) 
                        }}>
                        {expanded ? <MdExpandLess /> : <MdExpandMore />}
                    </button>
                </div>
            </div>
        )
    }

    renderStateRow (name, value, className) {
        return (
            <div className={'profile-item--state-row' + (className ? ` ${className}` : '')}>
                <div className='profile-item--state-row-name'>{name}</div>
                <div className='profile-item--state-row-value'>{value}</div>
            </div>
        )
    }

    renderMiniTable () {
        const { profile } = this.state
        const { symbolItems, runningState } = this.props
        const renderSymbolPosition = (symbolItem) => {
            const symbolPositionData = profile.started && _.has(runningState, `position.${symbolItem.name}`) && !_.isEmpty(runningState.position[symbolItem.name]) 
                ? runningState.position[symbolItem.name] : {}
            const symbolNetPosition = _.reduce(symbolPositionData, (result, positionData) => {
                const { long, open_long, short, open_short } = positionData
                result += (Number(long) + Number(open_long) - Number(short) - Number(open_short))
                return result
            }, 0)
            const fixedNetPosition = toFixedNumber(symbolNetPosition, 4)       

            return (
                <div className='profile-item--mini-table--symbol-position' key={symbolItem.name}>
                    <div className='profile-item--mini-table--symbol-position--symbol-name'>
                        {symbolItems[symbolItem.name]
                        ? <SymbolPopup
                            symbolName={symbolItem.name}
                            profileId={profile.id} />
                        : symbolItem.name}
                    </div>
                    <div className={'profile-item--mini-table--symbol-position--position' + (symbolNetPosition ?  
                        (symbolNetPosition > 0 ? ' positive' : ' negative')
                        : '')}>
                        {!_.isEmpty(fixedNetPosition) ? fixedNetPosition : '0'}
                    </div>
                </div>
            )
        }
        return (
            <table className='profile-item--mini-table'>
                <thead>
                    <tr>
                        <th>{'Quote Net Positions'}</th>
                        <th>{'Hedge Net Positions'}</th>
                        <th>{'Exposure'}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{_.has(profile, 'legs.1.symbols') && _.isArray(profile.legs['1'].symbols) && profile.legs['1'].symbols.map(symbolItem => renderSymbolPosition(symbolItem))}</td>
                        <td>{_.has(profile, 'legs.2.symbols') && _.isArray(profile.legs['2'].symbols) && profile.legs['2'].symbols.map(symbolItem => renderSymbolPosition(symbolItem))}</td>
                        <td>{_.has(runningState, 'strategyInfo.expo') ? toFixedNumber(runningState.strategyInfo.expo, 5) : 'N/A'}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    render () {
        const { profile, showEditor, isSaving, isSaved, message, editingTimer } = this.state
        const { pricings, runningState, expanded, onChangeItemHeight } = this.props
        const leg1Symbols = _.has(profile, `legs.1.symbols`) ? profile.legs['1'].symbols : []
        const leg1HeadSymbol = _.head(leg1Symbols)
        const isProfileEdited = !_.isEqual(profile, this.props.profile)

        let profileAsset = null, assetLastUSDTPrice = null
        if (leg1HeadSymbol) {
            profileAsset = getSymbolAttributeByName(leg1HeadSymbol.name).base
            const pricingItem = pricings[`${profileAsset.toLowerCase()}_usdt_OKEX_SPT`]
            if (pricingItem) {
                const { bid, ask, last } = pricingItem
                assetLastUSDTPrice = last ? last 
                    : bid && ask ? (bid + ask) / 2
                    : null
            }
        }
        return (
            <div className='profile-item' profile-id={profile.id}>
                {this.renderHeader()}
                {expanded ? <div className='profile-item--main'>
                    <div className='profile-item--top'>
                        <div className='profile-item--quote-symbol-table'>
                            <QuoteSymbolTable 
                                profile={profile} 
                                onChangeProfile={(newProfile) => {
                                    this.setState({
                                        profile: newProfile,
                                        message: null,
                                        isSaved: false
                                    })
                                }} 
                                onKeyDownEnter={() => { this._saveProfile() }} 
                                onChangeHeight={() => { onChangeItemHeight() }} />
                        </div>
                    </div>
                    <div className='profile-item--bottom'>
                        <div className='profile-item--hedge-symbol-table'>
                            <HedgeSymbolTable 
                                profile={profile} 
                                onChangeProfile={(newProfile) => {
                                    this.setState({
                                        profile: newProfile,
                                        message: null,
                                        isSaved: false
                                    })
                                }} 
                                onKeyDownEnter={() => { this._saveProfile() }} 
                                onChangeHeight={() => { onChangeItemHeight() }} />
                        </div>
                        <div className='profile-item--info'>
                            <div className='profile-item--state'>
                                <div className='profile-item--state-header'>{'State'}</div>
                                <div className='profile-item--state-main'>
                                    {this.renderStateRow('Exposure', _.has(runningState, 'strategyInfo.expo') 
                                        ? (toFixedNumber(runningState.strategyInfo.expo, 5) + ` ${profileAsset}`) : 'N/A')}
                                    {_.has(runningState, 'strategyInfo.expo') && assetLastUSDTPrice && 
                                    this.renderStateRow('', toFixedNumber(runningState.strategyInfo.expo * assetLastUSDTPrice, 3) + ' USDT', 'exposure-in-usdt')}
                                    {this.renderStateRow('Quote Max Exposure', profile.legs['1'].params.QUOTE_MAX_EXPOSURE)}
                                    {this.renderStateRow('Hedge Exposure Threshold', profile.legs['2'].params.HEDGE_EXPOSURE_THRESHOLD)}
                                </div>
                            </div>
                            <div className='profile-item--accounts'>
                                <ProfileAccounts 
                                    disableModification={profile.started}
                                    profileItem={profile}
                                    optionsMaxHeight={(leg1Symbols || []).length <= 1 ? 94 : 124}
                                    onChangeAccounts={(newAccounts) => {
                                        const newProfile = dotProp.set(profile, 'accounts', newAccounts)
                                        this.setState({ 
                                            profile: newProfile,
                                            message: null,
                                            isSaved: false
                                        })
                                    }} 
                                    onToggleSmartPosAccount={() => { onChangeItemHeight() }} />
                            </div>
                        </div>
                    </div>
                    <div className={'profile-item--buttons ' + (isSaved && _.isEmpty(message) ? 'is-saved' : !isProfileEdited && _.isEmpty(message) ? 'hide' : 'show')}>
                        {(isProfileEdited || isSaved) && <Fragment>
                            <SaveButton className={'profile-item--button save-button' + (isSaved ? ' is-saved' : '')}
                                isSaving={isSaving} 
                                isSaved={isSaved} 
                                onClick={() => { setTimeout(() => { this._saveProfile() }) }} />
                            {!isSaved && <button className={'profile-item--button reset-button'} 
                                onClick={() => {  this._updateProfile(this.props.profile) }}>{'Reset'}</button>}
                        </Fragment>}
                        {message && <div className='profile-item--message' dangerouslySetInnerHTML={{ __html: message }} />}
                    </div>
                </div> : this.renderMiniTable()}
                {showEditor && <ProfileEditor
                    mode={'EDIT'}
                    profile={profile}
                    onClickClose={() => { this.setState({ showEditor: false }) }}  />}
                {editingTimer && 
                <TimerEditor 
                    mode={'EDIT'}
                    defaultTimer={editingTimer}
                    closeOnSaveSuccess={false}
                    onClickClose={() => { this.setState({ editingTimer: null }) }} />}
            </div>
        )
    }
}

const strategyShape = PropTypes.shape({
    type: PropTypes.oneOf(['DIME', 'RDIME', 'MULTILEVEL', 'HIT', 'COG', 'DSWAP', 'MAKE']),
    params: PropTypes.object
})

const legShape = PropTypes.shape({
    params: PropTypes.object,
    strategy: strategyShape,
    symbols: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        refSymbol: PropTypes.string,
        params: PropTypes.object,
        strategyParams: PropTypes.object
    }))
})

export const profileShape = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    params: PropTypes.object,
    legs: PropTypes.shape({
        1: legShape,
        2: legShape
    }),
    accounts: PropTypes.object,
    started: PropTypes.bool,
    start_timestamp: PropTypes.string
})

ProfileItem.propTypes = {
    dispatch: PropTypes.func.isRequired,
    symbolItems: PropTypes.object.isRequired,
    pricings: PropTypes.object.isRequired,
    profileTimers: PropTypes.array.isRequired,
    notifications: PropTypes.array.isRequired,

    profile: profileShape.isRequired,
    dragHandle: PropTypes.node,
    runningState: PropTypes.object.isRequired,
    expanded: PropTypes.bool,
    onClickToggleExpand: PropTypes.func,
    onChangeItemHeight: PropTypes.func
}

ProfileItem.defaultProps = {
    expanded: false,
    onClickToggleExpand: () => {},
    onChangeItemHeight: () => {}
}

function mapStateToProps (state, ownProps) {
    return {
        symbolItems: state.symbol.items,
        pricings: state.symbol.pricings,
        runningState: state.profile.runningState[ownProps.profile.id] || {},
        profileTimers: Object.values(state.timer.items).filter(timer => (timer.profiles || []).map(profile => profile.profileId).includes(ownProps.profile.id)),
        notifications: state.trading.notifications
    }
}

export default connect(mapStateToProps)(ProfileItem)