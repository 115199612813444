import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import _ from 'lodash'
import dotProp from 'dot-prop-immutable'

import { MdClose } from 'react-icons/md'
import { FiCheck, FiAlertCircle } from 'react-icons/fi'
import Popup from '../common/popup/Popup'

import ProfileBulkUploadStatusRow from './ProfileBulkUploadStatusRow'
import ProfileBulkUploadRefSymbolRow from './ProfileBulkUploadRefSymbolRow'
import ProfileBulkUploadParamRow from './ProfileBulkUploadParamRow' 
import SingleLimit from '../trading/SingleLimit'
import { profileParameters, legParameters, symbolParametrs, strategies, legStrategyTypes } from '../../configs/profileConfig'
import { updateProfileParams, fetchProfileParams } from './profileAction'
import rollingImage from '../../images/rolling.svg'

Modal.setAppElement('#root')

const ProfileItemToUpdate = ({ id, originalIndex, name, newParams={}, success=false, failed=false, errorMessage }) => {
    return { id, originalIndex, name, newParams, success, failed, errorMessage }
}

const ProfileItemToSync = ({ id, name, success=false, failed=false, errorMessage }) => {
    return { id, name, success, failed, errorMessage }
}
class ProfileBulkUploadEditor extends Component {
    constructor (props) {
        super(props)
        const filteredProfileItems = this.props.profileItems.filter(profileItem => !_.isEmpty(profileItem.params))
        this.initialProfileItems = _.keyBy(filteredProfileItems, 'id')
        this.state = {
            editingProfileItems: filteredProfileItems,
            shouldShowUpdatingList: false,
            profileItemsToUpdate: {},
            shouldShowSyncingList: false,
            profileItemsToSync: {},
            lastParaLoadFailNotificationId: null 
        }
    }

    static getDerivedStateFromProps (props, state) {
        const { notifications } = props
        const firstNotification = _.head(notifications) 
        if (!_.isNil(firstNotification) 
            && firstNotification.type === 'PARA_LOAD_FAIL'
            && !_.isEqual(firstNotification.id, state.lastParaLoadFailNotificationId)) {
            const newProfileItemsToUpdate = _.cloneDeep(state.profileItemsToUpdate)
            _.forEach(newProfileItemsToUpdate, (profileItemToUpdate, key) => {
                if (profileItemToUpdate.id === firstNotification.profileId) {
                    newProfileItemsToUpdate[key] = Object.assign({}, profileItemToUpdate, {
                        success: false,
                        failed: true,
                        errorMessage: `PARA_LOAD_FAIL: ${firstNotification.message}`
                    })
                }
            })
            return {
                profileItemsToUpdate: newProfileItemsToUpdate,
                lastParaLoadFailNotificationId: firstNotification.id
            }
        } else {
            return null
        }
    }

    handleClickResetButton () {
        this.setState({
            editingProfileItems: Object.values(this.initialProfileItems)
        })
    }

    handleClickSaveButton () {
        const { initialProfileItems } = this
        const { editingProfileItems } = this.state
        const { dispatch } = this.props

        const profileItemsToUpdate = editingProfileItems.reduce((items, profileItem, profileIndex) => {
            const initialProfileParams = _.pick(initialProfileItems[profileItem.id], ['accounts', 'legs', 'params'])
            const profileParams = _.pick(profileItem, ['accounts', 'legs', 'params'])
            if (!_.isEqual(initialProfileParams, profileParams)) {
                items[profileItem.id] = ProfileItemToUpdate({
                    id: profileItem.id,
                    originalIndex: profileIndex,
                    name: profileItem.name,
                    newParams: profileParams,
                    success: false,
                    failed: false,
                    errorMessage: null
                })
            }
            return items
        }, {})

        this.setState({
            shouldShowUpdatingList: true,
            shouldShowSyncingList: false,
            profileItemsToUpdate: profileItemsToUpdate
        })

        _.forEach(profileItemsToUpdate, (profileItem) => {
            const { id, newParams, originalIndex } = profileItem
            dispatch(updateProfileParams(id, newParams)).then((response) => {
                if (response && !_.isError(response) && response.status === 200) {
                    this.initialProfileItems[id] = editingProfileItems[originalIndex]
                    this.setState((prevState) => {
                        return {
                            profileItemsToUpdate: dotProp.set(prevState.profileItemsToUpdate, `${id}.success`, true)
                        }
                    })
                } else {
                    this.setState((prevState) => {
                        return {
                            profileItemsToUpdate: dotProp.merge(prevState.profileItemsToUpdate, id, {
                                failed: true,
                                errorMessage: _.isError(response) ? response.toString() : 'Network Error'
                            })
                        }
                    })
                }
            })
        })
    }

    handleClickSyncButton () {
        const { dispatch, profileItems } = this.props
        const profileItemsToSync = profileItems.reduce((result, profileItem) => {
            if (!profileItem.started && !profileItem.isStopping && !profileItem.isStarting) {
                result[profileItem.id] = ProfileItemToSync({
                    id: profileItem.id,
                    name: profileItem.name,
                    success: false,
                    failed: false,
                    errorMessage: null
                })
            }
            return result
        }, {})

        this.setState({
            shouldShowSyncingList: true,
            shouldShowUpdatingList: false,
            profileItemsToSync: profileItemsToSync
        })

        _.forEach(profileItemsToSync, profileItemToSync => {
            const { id, name } = profileItemToSync
            dispatch(fetchProfileParams({ 
                profileId: id,
                updateStore: true
            })).then(body => {
                if (body && body[name]) {
                    const newProfileConfig = body[name]
                    this.initialProfileItems[id] = Object.assign({}, this.initialProfileItems[id], newProfileConfig)
                    this.setState(prevState => {
                        const newEditingProfileItems = prevState.editingProfileItems.map(profileItem => {
                            return profileItem.id === id
                                ? Object.assign({}, profileItem, newProfileConfig)
                                : profileItem
                        })
                        return {
                            editingProfileItems: newEditingProfileItems,
                            profileItemsToSync: dotProp.set(prevState.profileItemsToSync, `${id}.success`, true)
                        }
                    })
                } else {
                    this.setState(prevState => {
                        return {
                            profileItemToSync: dotProp.merge(prevState.profileItemsToSync, id, {
                                failed: true,
                                errorMessage: 'Synced Failed'
                            })
                        }
                    })
                }
            })
        })
    }

    renderSectionTitleRow (title) {
        const { editingProfileItems } = this.state
        return (
            <tr className='profile-bulk-upload-editor--table--section-title'>
                <th>{title}</th>
                <td colSpan={editingProfileItems.length} />
            </tr>
        )
    }

    renderSymbolRow (legNumber) {
        const { editingProfileItems } = this.state
        return (
            <tr className='profile-bulk-upload-editor--symbol-row'>
                <th>{'Symbols'}</th>
                {editingProfileItems.map((profileItem) => {
                    const symbols = profileItem.legs[legNumber].symbols
                    const symbolNameWidth = `${100 / (symbols.length || 1)}%`
                    return (
                        <td key={profileItem.id}>
                            <div className='profile-bulk-upload-editor--symbol-row--names'>
                                {symbols.map((symbol, symbolIndex) => {
                                    return (
                                        <div className='profile-bulk-upload-editor--symbol-row--symbol-name' 
                                            key={symbolIndex}
                                            style={{ width: symbolNameWidth }}>
                                            <Popup className='profile-bulk-upload-editor--symbol-row--popup'
                                                trigger={<span>{symbol.name}</span>}>
                                                <SingleLimit symbolName={symbol.name} />
                                            </Popup>
                                        </div>                                        
                                    )
                                })}
                            </div>
                        </td>
                    )
                })}
            </tr>
        )
    }

    renderStrategyRow (legNumber) {
        const { editingProfileItems } = this.state
        return (
            <tr className='profile-bulk-upload-editor--strategy-row'>
                <th>{'Strategy'}</th>
                {editingProfileItems.map((profileItem) => {
                    const strategyType = profileItem.legs[legNumber].strategy.type
                    return (
                        <td key={profileItem.id} className={`profile-bulk-upload-editor--strategy-value ${strategyType}`}>
                            {strategyType}
                        </td>
                    )
                })}
            </tr>
        )
    }

    renderProfileParamRows () {
        const { editingProfileItems } = this.state
        return (
            _.map(profileParameters, (paramConfig, paramKey) => {
                const profileParams = editingProfileItems.map((profileItem) => {
                    return {
                        value: profileItem.params[paramKey]
                    }
                })
                return _.some(['_SMART_POS_ASK_ACCOUNTS', '_SMART_POS_BID_ACCOUNTS', '_SMART_POS_SWITCH_INTERVAL_IN_SEC', '_SMART_POS_BACKUP_ACCOUNT'], smartPosRelatedParamSubKey => paramKey.includes(smartPosRelatedParamSubKey))
                    ? null 
                    : (<ProfileBulkUploadParamRow
                        key={paramKey}
                        paramConfig={paramConfig} 
                        profileParams={profileParams} 
                        onChangeParams={(newParams) => {
                            const newProfileItems = _.cloneDeep(editingProfileItems)
                            newParams.forEach((newParam) => {
                                newProfileItems[newParam.profileIndex].params[paramKey] = newParam.newValue
                            })
                            this.setState({
                                editingProfileItems: newProfileItems
                            })
                        }} />
                    )
            })
        )
    }

    renderLegParamRows (legNumber) {
        const { editingProfileItems } = this.state
        const parameters = legParameters[`leg${legNumber}`]
        return (
            _.map(parameters, (paramConfig, paramKey) => {
                const profileParams = editingProfileItems.map((profileItem) => {
                    return {
                        value: profileItem.legs[legNumber].params[paramKey]
                    }
                })
                return (
                    <ProfileBulkUploadParamRow
                        key={paramKey}
                        paramConfig={paramConfig}
                        profileParams={profileParams}
                        onChangeParams={(newParams) => {
                            const newProfileItems = _.cloneDeep(editingProfileItems)
                            newParams.forEach((newParam) => {
                                newProfileItems[newParam.profileIndex].legs[legNumber].params[paramKey] = newParam.newValue
                            })
                            this.setState({
                                editingProfileItems: newProfileItems
                            })
                        }} />
                )
            })
        )
    }

    renderSymbolParamRows (legNumber) {
        const { editingProfileItems } = this.state
        const parameters = symbolParametrs[`leg${legNumber}`]
        return (
            _.map(parameters, (paramConfig, paramKey) => {
                const disableEditingIfStarted = ['LEG1_PROD_INITIAL_LONG_POS', 'LEG1_PROD_INITIAL_SHORT_POS', 
                'LEG2_PROD_INITIAL_LONG_POS', 'LEG2_PROD_INITIAL_SHORT_POS', 'LEG1_FX_PROD', 'QUOTE_FX_ADJUST_FACTOR'].includes(paramKey)
                const profileParams = editingProfileItems.map((profileItem) => {
                    const profileSymbols = profileItem.legs[legNumber].symbols
                    const propsProfileItem = _.find(this.props.profileItems, { id: profileItem.id })
                    return {
                        symbolValues: profileSymbols.reduce((values, symbolItem) => {
                            return values.concat([symbolItem.params[paramKey]])
                        }, []),
                        symbolNames: profileSymbols.map(profileSymbolItem => profileSymbolItem.name),
                        disabled: disableEditingIfStarted && (_.isEmpty(propsProfileItem) || propsProfileItem.started)
                    }
                })
                return (
                    <ProfileBulkUploadParamRow
                        key={paramKey}
                        paramConfig={paramConfig}
                        profileParams={profileParams}
                        onChangeParams={(newParams) => {
                            const newProfileItems = _.cloneDeep(editingProfileItems)
                            newParams.forEach((newParam) => {
                                newProfileItems[newParam.profileIndex].legs[legNumber].symbols[newParam.symbolIndex].params[paramKey] = newParam.newValue
                            })
                            this.setState({
                                editingProfileItems: newProfileItems
                            })
                        }} />
                )
            })
        )
    }

    renderStrategyParamRows (legNumber) {
        const { editingProfileItems } = this.state
        const filteredStrategyTypes = editingProfileItems.reduce((strategyTypes, profileItem) => {
            const strategyType = profileItem.legs[legNumber].strategy.type
            if (!strategyTypes.includes(strategyType)) {
                strategyTypes.push(strategyType)
            }
            return strategyTypes
        }, [])
        const sortedFilteredStrategyTypes = legStrategyTypes[`leg${legNumber}`].filter((strategyType) => filteredStrategyTypes.includes(strategyType), [])
        const parameters = sortedFilteredStrategyTypes.reduce((parameters, strategyType) => {
            return Object.assign({}, parameters, strategies[strategyType].parameters)
        }, {})

        return (
            _.map(parameters, (paramConfig, paramKey) => {
                const adjustedParamKey = `${legNumber === 1 ? 'QUOTE' : 'HEDGE'}_${paramKey}`
                const { isSharedAmongSymbols } = paramConfig
                const profileParams = editingProfileItems.map((profileItem) => {
                    const profileStrategy = profileItem.legs[legNumber].strategy
                    if (isSharedAmongSymbols) {
                        return {
                            value: _.has(profileStrategy, `params.${adjustedParamKey}`) ? profileStrategy.params[adjustedParamKey] : null,
                        }
                    } else {
                        return {
                            symbolValues: isSharedAmongSymbols ? [] : profileItem.legs[legNumber].symbols.reduce((values, symbolItem) => {
                                return values.concat([_.has(symbolItem, `strategyParams.${adjustedParamKey}`) ? symbolItem.strategyParams[adjustedParamKey] : null])
                            }, []),
                            symbolNames: isSharedAmongSymbols ? null : profileItem.legs[legNumber].symbols.map(legSymbolItem => legSymbolItem.name)
                        }
                    }
                })
                return (
                    <ProfileBulkUploadParamRow
                        key={paramKey}
                        paramConfig={paramConfig}
                        profileParams={profileParams} 
                        onChangeParams={(newParams) => {
                            const newProfileItems = _.cloneDeep(editingProfileItems)
                            newParams.forEach((newParam) => {
                                if (isSharedAmongSymbols) {
                                    newProfileItems[newParam.profileIndex].legs[legNumber].strategy.params[adjustedParamKey] = newParam.newValue
                                } else {
                                    newProfileItems[newParam.profileIndex].legs[legNumber].symbols[newParam.symbolIndex].strategyParams[adjustedParamKey] = newParam.newValue
                                }
                            })
                            this.setState({
                                editingProfileItems: newProfileItems
                            })
                        }} />
                )
            })
        )
    }

    renderUpdatingList () {
        const { profileItemsToUpdate } = this.state
        return (
            <div className='profile-bulk-upload-editor--updating-list'>
                <div className='profile-bulk-upload-editor--updating-list--header clearfix'>
                    <div className='profile-bulk-upload-editor--updating-list--title'>{'Update Profile' + (Object.keys(profileItemsToUpdate).length > 1 ? 's' : '')}</div>
                    <button className='profile-bulk-upload-editor--updating-list--close-button' onClick={() => {
                        this.setState({
                            shouldShowUpdatingList: false
                        })
                    }}><MdClose /></button>
                </div>
                <div className='profile-bulk-upload-editor--updating-list--main'>
                    {!_.isEmpty(profileItemsToUpdate) ? _.map(profileItemsToUpdate, (item) => {
                        return (
                            <div className='profile-bulk-upload-editor--updating-list--item' key={item.id}>
                                <div className='profile-bulk-upload-editor--updating-list--item-name'>{item.name}</div>
                                {!item.success && !item.failed && <img className='profile-bulk-upload-editor--updating-list--item-rolling-image' 
                                    alt={'Saving'}
                                    src={rollingImage} />}
                                {item.success && <FiCheck className='profile-bulk-upload-editor--updating-list--item-success-icon'/>}
                                {item.failed && 
                                <Popup
                                    className='profile-bulk-upload-editor--updating-list--error-popup'
                                    align={'horizontal'}
                                    trigger={<div className='profile-bulk-upload-editor--updating-list--item-failed-icon'><FiAlertCircle /></div>}>
                                    <span dangerouslySetInnerHTML={{ __html: item.errorMessage }} />
                                </Popup>}
                            </div>
                        )
                    }) : <div className='profile-bulk-upload-editor--updating-list--no-change-message'>{'You make no changes on any profile.'}</div>}
                </div>
            </div>
        )
    }

    renderSyncingList () {
        const { profileItemsToSync } = this.state
        return (
            <div className='profile-bulk-upload-editor--syncing-list'>
                <div className='profile-bulk-upload-editor--syncing-list--header clearfix'>
                    <div className='profile-bulk-upload-editor--syncing-list--title'>{`Sync Profile${Object.keys(profileItemsToSync).length > 1 ? 's' : ''}`}</div>
                    <button className='profile-bulk-upload-editor--syncing-list--close-button' onClick={() => {
                        this.setState({
                            shouldShowSyncingList: false
                        })
                    }}><MdClose /></button>
                </div>
                <div className='profile-bulk-upload-editor--syncing-list--main'>
                    {!_.isEmpty(profileItemsToSync) ? _.map(profileItemsToSync, item => {
                        return (
                            <div className='profile-bulk-upload-editor--syncing-list--item' key={item.id}>
                                <div className='profile-bulk-upload-editor--syncing-list--item-name'>{item.name}</div>
                                {!item.success && !item.failed && <img className='profile-bulk-upload-editor--syncing-list--item-rolling-image' 
                                    alt={'Syncing'}
                                    src={rollingImage} />}
                                {item.success && <FiCheck className='profile-bulk-upload-editor--syncing-list--item-success-icon'/>}
                                {item.failed && 
                                <Popup
                                    className='profile-bulk-upload-editor--syncing-list--error-popup'
                                    align={'horizontal'}
                                    trigger={<div className='profile-bulk-upload-editor--syncing-list--item-failed-icon'><FiAlertCircle /></div>}>
                                    <span dangerouslySetInnerHTML={{ __html: item.errorMessage }} />
                                </Popup>}
                            </div>
                        )
                    }) : <div className='profile-bulk-upload-editor--syncing-list--no-change-message'>{'There is no available profile to sync.'}</div>}
                </div>
            </div>
        )
    }

    render () {
        const { editingProfileItems, shouldShowUpdatingList, shouldShowSyncingList } = this.state
        const { onClickClose } = this.props
        return (
            <Modal
                overlayClassName='modal-overlay'
                className='profile-bulk-upload-editor horizontal-centered'
                isOpen>
                <div className='profile-bulk-upload-editor--header'>
                    <button className='profile-bulk-upload-editor--reset-button' onClick={() => { this.handleClickResetButton() }}>{'Reset'}</button>
                    <button className='profile-bulk-upload-editor--sync-button' onClick={() => { this.handleClickSyncButton() }}>{'Sync All Stopped Profiles'}</button>
                    <button className='profile-bulk-upload-editor--save-button' onClick={() =>{ this.handleClickSaveButton() }}>{'Save'}</button>
                    <button className='profile-bulk-upload-editor--close-button' onClick={() => { onClickClose() }}><MdClose /></button>
                </div>
                <div className='profile-bulk-upload-editor--table-wrapper'>
                    <table className='profile-bulk-upload-editor--table'>
                        <thead>
                            <tr>
                                <th>{`${editingProfileItems.length} PROFILE${editingProfileItems.length > 1 ? 'S' : ''}`}</th>
                                {editingProfileItems.map((profileItem) => { 
                                    return (<th key={profileItem.id}>{profileItem.name}</th>)
                                })}
                            </tr>
                            <ProfileBulkUploadStatusRow 
                                profileIds={editingProfileItems.map(profileItem => profileItem.id)} 
                                onClickSortButton={(newProfileIds) => {
                                    const idToItemMap = _.keyBy(editingProfileItems, 'id')
                                    const newEditingProfileItems = newProfileIds.map(profileId => idToItemMap[profileId])
                                    this.setState({ editingProfileItems: newEditingProfileItems })
                                }} />
                        </thead>
                        <thead>
                            {this.renderSectionTitleRow('Setting')}
                        </thead>
                        <tbody>
                            {this.renderProfileParamRows(1)}
                        </tbody>
                        <thead>
                            {this.renderSectionTitleRow('Quote')}
                        </thead>
                        <tbody>
                            {this.renderLegParamRows(1)}
                            {this.renderSymbolRow(1)}
                            <ProfileBulkUploadRefSymbolRow 
                                editingProfiles={editingProfileItems} 
                                onChangeEditingProfiles={(newProfiles) => {
                                    this.setState({ editingProfileItems: newProfiles })
                                }} />
                            {this.renderSymbolParamRows(1)}
                            {this.renderStrategyRow(1)}
                            {this.renderStrategyParamRows(1)}
                        </tbody>
                        <thead>
                            {this.renderSectionTitleRow('Hedge')}
                        </thead>
                        <tbody>
                            {this.renderLegParamRows(2)}
                            {this.renderSymbolRow(2)}
                            {this.renderSymbolParamRows(2)}
                            {this.renderStrategyRow(2)}
                            {this.renderStrategyParamRows(2)}
                        </tbody>
                    </table>
                </div>
                {shouldShowUpdatingList && this.renderUpdatingList()}
                {shouldShowSyncingList && this.renderSyncingList()}
            </Modal>
        )
    }
}

ProfileBulkUploadEditor.propTypes = {
    dispatch: PropTypes.func.isRequired,
    notifications: PropTypes.array.isRequired,

    profileItems: PropTypes.array.isRequired,
    onClickClose: PropTypes.func,
}

ProfileBulkUploadEditor.defaultProps = {
    profileItems: [],
    onClickClose: () => {}
}

function mapStateToProps (state) {
    return {
        notifications: state.trading.notifications
    }
}

export default connect(mapStateToProps)(ProfileBulkUploadEditor)