import moment from 'moment'
import _ from 'lodash'
import uuidv4 from 'uuid/v4'
import { batch } from 'react-redux'

import { addNotificationItem } from '../trading/tradingAction'
import { secureFetch, getQueryString } from '../../util/util'
import { getApiBaseUrlByHostname, SERVERS, ELF_API_BASE_URL, DEFAULT_SERVER } from '../../configs/config'
import { getProfileExchangeNames, getProfileExchangeNameBySymbol, getExchangeDisplayName, getProfileSymbolNames, getProfileSmartPositionAccountsByExchangeName } from '../../util/profileUtil'
import { ALL_PROFILE_GROUP_ID, parameterTypes, profileParameters, legParameters, 
    legStrategyTypes, symbolParametrs, getStrategyParamConfig } from '../../configs/profileConfig'
import { webSocketConnect } from '../webSocket/webSocketAction'

export const ADD_PROFILE_ITEM = 'ADD_PROFILE_ITEM'
export const ADD_PROFILE_ITEMS = 'ADD_PROFILE_ITEMS'
export const UPDATE_PROFILE_ITEM = 'UPDATE_PROFILE_ITEM'
export const UPDATE_PROFILE_ITEMS = 'UPDATE_PROFILE_ITEMS'
export const ADD_PROFILE_GROUP_ITEM = 'ADD_PROFILE_GROUP_ITEM'
export const UPDATE_PROFILE_GROUP_ITEM = 'UPDATE_PROFILE_GROUP_ITEM'
export const UPDATE_PROFILE_GROUP = 'UPDATE_PROFILE_GROUP'
export const UPDATE_PROFILE_GROUP_FOCUSED_ID = 'UPDATE_PROFILE_GROUP_FOCUSED_ID'
export const UPDATE_PROFILE_GROUP_IDS = 'UPDATE_PROFILE_GROUP_IDS'
export const REMOVE_PROFILE_GROUP_ITEM = 'REMOVE_PROFILE_GROUP_ITEM'
export const UPDATE_PROFILE_RUNNING_STATE = 'UPDATE_PROFILE_RUNNING_STATE'
export const UPDATE_PROFILE_RUNNING_STATES = 'UPDATE_PROFILE_RUNNING_STATES'
export const COPY_PROFILE_SUCCESS = 'COPY_PROFILE_SUCCESS'
export const UPDATE_PROFILE_SEARCH_STRING = 'UPDATE_PROFILE_SEARCH_STRING'
export const UPDATE_HOSTNAME_PROFILE_STATE = 'UPDATE_HOSTNAME_PROFILE_STATE'
export const UPDATE_PROFILE_STOPPED_UPDATING_SYMBOL_PRICINGS = 'UPDATE_PROFILE_STOPPED_UPDATING_SYMBOL_PRICINGS'
export const UPDATE_PROFILE_ORDER_EDITOR_VARIABLES = 'UPDATE_PROFILE_ORDER_EDITOR_VARIABLES'
export const UPDATE_USER_PROFILES = 'UPDATE_USER_PROFILES'
export const UPDATE_SIGNED_SWITCHED_OFF_PROFILE_SYMBOLS = 'UPDATE_SIGNED_SWITCHED_OFF_PROFILE_SYMBOLS'

export const HostnameProfileState = ({ isFetching=false, isFetched=false, errorMessage=null }) => {
    return {
        isFetching,
        isFetched,
        errorMessage
    }
}

const profileDefaultStatus = {
    started: false,
    resumed: false
}

const ProfileItem = ({profileId, profileParams, profileName, hostname, user, status={}}) => {
    profileId = profileId || `${profileName}_${hostname}`
    return Object.assign({}, profileParams, {
        id: profileId,
        name: profileName,
        hostname,
        user
    }, status)
}

export function createProfileItem (profile) {
    return (dispatch) => {
        profile.id = moment().valueOf()
        return new Promise((resolve) => {
            dispatch({
                type: ADD_PROFILE_ITEM,
                profile
            })
            resolve('success')
        })
    }
}

export function fetchProfilesByHostname (hostname) {
    return (dispatch, getState) => {
        const server = SERVERS[hostname]
        if (server && server.enabled) {
            dispatch({
                type: UPDATE_HOSTNAME_PROFILE_STATE,
                hostname,
                params: HostnameProfileState({
                    isFetching: true,
                    isFetched: false,
                    errorMessage: null
                })
            })
            dispatch(secureFetch(`${server.apiBaseUrl}/profiles`))
            .then(response => {
                if (response.status === 200) {
                    response.json()
                    .then(body => {
                        const profileItems = getState().profile.items
                        const newProfileItems = {}
                        _.forEach(body, (profiles, profileUser) => {
                            _.forEach(profiles, (profileParams, profileName) => {
                                if (!_.isEmpty(profileParams)) {
                                    const profileId = `${profileName}_${hostname}`
                                    newProfileItems[profileId] = ProfileItem({
                                        profileId,
                                        profileParams,
                                        profileName,
                                        hostname,
                                        user: profileUser,
                                        status: _.isEmpty(profileItems[profileId]) ? profileDefaultStatus : {}
                                    })
                                }
                            })
                        })
                        batch(() => {
                            dispatch({
                                type: ADD_PROFILE_ITEMS,
                                profiles: newProfileItems
                            }),
                            dispatch({
                                type: UPDATE_HOSTNAME_PROFILE_STATE,
                                hostname,
                                params: HostnameProfileState({
                                    isFetching: false,
                                    isFetched: true,
                                    errorMessage: null
                                })
                            })
                        })
                        dispatch(webSocketConnect(hostname))
                    })
                } else {
                    response.text()
                    .then(text => {
                        dispatch({
                            type: UPDATE_HOSTNAME_PROFILE_STATE,
                            hostname,
                            params: HostnameProfileState({
                                isFetching: false,
                                isFetched: false,
                                errorMessage: `Status Code: ${response.status}, ${text}`
                            })
                        })
                    })
                }
            })
            .catch(error => {
                const errorString = error.toString()
                dispatch({
                    type: UPDATE_HOSTNAME_PROFILE_STATE,
                    hostname,
                    params: HostnameProfileState({
                        isFetching: false,
                        isFetched: false,
                        errorMessage: errorString
                    })
                })
            })
        }
    }
}

export function fetchProfilesAndConnectWSS () {
    return (dispatch) => {
        _.filter(SERVERS, server => server.enabled).forEach(server => {
            if (!server.profileDisabled) {
                dispatch(fetchProfilesByHostname(server.hostname))
            } else {
                dispatch(webSocketConnect(server.hostname))
            }
        })
    }
}


export function fetchProfileParams ({profileId, quoteAlgo, hedgeAlgo, updateStore=false}) {
    return (dispatch, getState) => {
        let profileItem = getState().profile.items[profileId]
        if (profileItem) {
            const queryParams = {
                profile: profileItem.name,
                user: profileItem.user,
                'quote-algo': quoteAlgo,
                'hedge-algo': hedgeAlgo
            }
            return dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/get-params?${getQueryString(queryParams)}`, {
                method: 'GET',
            })).then((response) => {
                if (response.status === 200) {
                    const getBody = response.json()
                    if (updateStore) {
                        getBody.then(body => {
                            profileItem = getState().profile.items[profileId]
                            if (body && profileItem && !_.isEmpty(body[profileItem.name]) && !profileItem.started) {
                                dispatch(updateProfileItem( profileId, body[profileItem.name]))
                            }
                        })
                    }
                    return getBody
                }
            })
        }
    }
}

export function updateProfileLogLevel (profileId, logLevel) {
    return (dispatch, getState) => {
        const profileItem = getState().profile.items[profileId]
        if (profileItem) {
            dispatch(addNotificationItem({
                id: uuidv4(),
                timestamp: moment().toISOString(),
                profileId: profileItem.id,
                user: profileItem.user,
                hostname: 'Client',
                type: 'OPERATION',
                message: `Try to update <i>LOG LEVEL</i> as <strong>${logLevel}</strong>`
            }))
            dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/set-log-level` , {
                method: 'PUT',
                body: JSON.stringify({
                    profile: profileItem.name,
                    user: profileItem.user,
                    level: logLevel
                })
            }))
        }
    }
}

export function restartProfile (profileId, cleanRestart=false) {
    return (dispatch, getState) => {
        const profileItem = getState().profile.items[profileId]
        if (profileItem) {
            dispatch(updateProfileItem(profileId, { isStarting: true }))
            dispatch(addNotificationItem({
                id: uuidv4(),
                timestamp: moment().toISOString(),
                profileId: profileItem.id,
                user: profileItem.user,
                hostname: 'Client',
                type: 'OPERATION',
                message: `Try to ${cleanRestart ? 'clean ': ''}restart the profile.`
            }))
            dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/restart`, {
                method: 'PUT',
                body: JSON.stringify({
                    profile: profileItem.name,
                    user: profileItem.user,
                    'clean-shm': cleanRestart ? '1' : null
                })
            })).then((response) => {
                if (response.status === 200) {
                    let startingTimeout, checkProfileInterval
                    startingTimeout = setTimeout(() => {
                        const profileItem = getState().profile.items[profileId]
                        if (profileItem.isStarting) {
                            dispatch(updateProfileItem(profileId, { isStarting: false }))
                            dispatch(addNotificationItem({
                                id: uuidv4(),
                                timestamp: moment().toISOString(),
                                profileId: profileItem.id,
                                user: profileItem.user,
                                hostname: 'Client',
                                type: 'RESTART_TIMEOUT',
                                message: `Restarting Profile seems failed for receiving no response.`
                            }))
                        }
                        window.clearInterval(checkProfileInterval)
                    }, 10000)
                    checkProfileInterval = setTimeout(() => {
                        const profileItem = getState().profile.items[profileId]
                        if (profileItem.started && !profileItem.isStarting) {
                            window.clearTimeout(startingTimeout)
                            window.clearInterval(checkProfileInterval)
                        }
                    }, 800)
                } else {
                    dispatch(updateProfileItem(profileId, { isStarting: false }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: moment().toISOString(),
                        profileId: profileItem.id,
                        user: profileItem.user,
                        hostname: 'Client',
                        type: 'START_FAIL',
                        message: `Restarting Profile failed due to ${response.status === 403 ? '403 Forbidden' : ('API Error: ' + response.status)}`
                    }))
                }
            }).catch((error) => {
                dispatch(updateProfileItem(profileId, { isStarting: false }))
                dispatch(addNotificationItem({
                    id: uuidv4(),
                    timestamp: moment().toISOString(),
                    profileId: profileItem.id,
                    user: profileItem.user,
                    hostname: 'Client',
                    type: 'START_FAIL',
                    message: `Restarting Profile seems failed due to ${error}.`
                }))
            })
        }
    }
}

export function stopProfile (profileId) {
    return (dispatch, getState) => {
        const profileItem = getState().profile.items[profileId]
        if (profileItem) {
            dispatch(updateProfileItem(profileId, { isStopping: true }))
            dispatch(addNotificationItem({
                id: uuidv4(),
                timestamp: moment().toISOString(),
                profileId: profileItem.id,
                user: profileItem.user,
                hostname: 'Client',
                type: 'OPERATION',
                message: `Try to stop the profile.`
            }))
            dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/stop`, {
                method: 'PUT',
                body: JSON.stringify({
                    profile: profileItem.name,
                    user: profileItem.user
                })
            })).then((response) => {
                if (response.status === 200) {
                    let stoppingTimeout, checkProfileInterval
                    stoppingTimeout = setTimeout(() => {
                        const profileItem = getState().profile.items[profileId]
                        if (profileItem.isStopping) {
                            dispatch(updateProfileItem(profileId, { isStopping: false }))
                            dispatch(addNotificationItem({
                                id: uuidv4(),
                                timestamp: moment().toISOString(),
                                profileId: profileItem.id,
                                user: profileItem.user,
                                hostname: 'Client',
                                type: 'STOP_TIMEOUT',
                                message: `Stopping Profile seems failed for receiving no response.`
                            }))
                        }
                        window.clearInterval(checkProfileInterval)
                    }, 10000)
                    checkProfileInterval = setTimeout(() => {
                        const profileItem = getState().profile.items[profileId]
                        if (!profileItem.started) {
                            window.clearTimeout(stoppingTimeout)
                            window.clearInterval(checkProfileInterval)
                        }
                    }, 800)
                } else {
                    dispatch(updateProfileItem(profileId, { isStopping: false }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: moment().toISOString(),
                        profileId: profileItem.id,
                        user: profileItem.user,
                        hostname: 'Client',
                        type: 'STOP_FAIL',
                        message: `Stopping Profile failed due to ${response.status === 403 ? '403 Forbidden' : ('API Error: ' + response.status)}.`
                    }))
                }
            }).catch((error) => {
                dispatch(updateProfileItem(profileId, { isStopping: false }))
                dispatch(addNotificationItem({
                    id: uuidv4(),
                    timestamp: moment().toISOString(),
                    profileId: profileItem.id,
                    user: profileItem.user,
                    hostname: 'Client',
                    type: 'STOP_FAIL',
                    message: `Stopping Profile seems failed due to ${error}.`
                }))
            })
        }
    }
}

export function resumeProfile (profileId) {
    return (dispatch, getState) => {
        const profileItem = getState().profile.items[profileId]
        if (profileItem) {
            dispatch(updateProfileItem(profileId, { isResuming: true }))
            dispatch(addNotificationItem({
                id: uuidv4(),
                timestamp: moment().toISOString(),
                profileId: profileItem.id,
                user: profileItem.user,
                hostname: 'Client',
                type: 'OPERATION',
                message: `Try to resume the profile.`
            }))
            dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/resume`, {
                method: 'PUT',
                body: JSON.stringify({
                    profile: profileItem.name,
                    user: profileItem.user
                })
            })).then((response) => {
                if (response.status === 200) {
                    let resumingTimeout, checkProfileInterval
                    resumingTimeout = setTimeout(() => {
                        const profileItem = getState().profile.items[profileId]
                        if (profileItem.isResuming) {
                            dispatch(updateProfileItem(profileId, { isResuming: false }))
                            dispatch(addNotificationItem({
                                id: uuidv4(),
                                timestamp: moment().toISOString(),
                                profileId: profileItem.id,
                                user: profileItem.user,
                                hostname: 'Client',
                                type: 'RESUME_TIMEOUT',
                                message: `Resuming Profile seems failed for receiving no response.`
                            }))
                        }
                        window.clearInterval(checkProfileInterval)
                    }, 10000)
                    checkProfileInterval = setTimeout(() => {
                        const profileItem = getState().profile.items[profileId]
                        if (profileItem.resumed) {
                            window.clearTimeout(resumingTimeout)
                            window.clearInterval(checkProfileInterval)
                        }
                    }, 800)
                } else {
                    dispatch(updateProfileItem(profileId, { isResuming: false }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: moment().toISOString(),
                        profileId: profileItem.id,
                        user: profileItem.user,
                        hostname: 'Client',
                        type: 'RESUME_FAIL',
                        message: `Resuming Profile failed due to ${response.status === 403 ? '403 Forbidden' : ('API Error: ' + response.status)}.`
                    }))
                }
            }).catch((error) => {
                dispatch(updateProfileItem(profileId, { isResuming: false }))
                dispatch(addNotificationItem({
                    id: uuidv4(),
                    timestamp: moment().toISOString(),
                    profileId: profileItem.id,
                    user: profileItem.user,
                    hostname: 'Client',
                    type: 'RESUME_FAIL',
                    message: `Resuming Profile seems failed due to ${error}.`
                }))
            })
        }
    }
}

export function pauseProfile (profileId) {
    return (dispatch, getState) => {
        const profileItem = getState().profile.items[profileId]
        if (profileItem) {
            dispatch(updateProfileItem(profileId, { isPausing: true }))
            dispatch(addNotificationItem({
                id: uuidv4(),
                timestamp: moment().toISOString(),
                profileId: profileItem.id,
                user: profileItem.user,
                hostname: 'Client',
                type: 'OPERATION',
                message: `Try to pause the profile.`
            }))
            dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/pause`, {
                method: 'PUT',
                body: JSON.stringify({
                    profile: profileItem.name,
                    user: profileItem.user
                })
            })).then((response) => {
                if (response.status === 200) {
                    let pausingTimeout, checkProfileInterval
                    pausingTimeout = setTimeout(() => {
                        const profileItem = getState().profile.items[profileId]
                        if (profileItem.isPausing) {
                            dispatch(updateProfileItem(profileId, { isPausing: false }))
                            dispatch(addNotificationItem({
                                id: uuidv4(),
                                timestamp: moment().toISOString(),
                                profileId: profileItem.id,
                                user: profileItem.user,
                                hostname: 'Client',
                                type: 'PAUSE_TIMEOUT',
                                message: `Pausing Profile seems failed for receiving no response.`
                            }))
                        }
                        window.clearInterval(checkProfileInterval)
                    }, 10000)
                    checkProfileInterval = setTimeout(() => {
                        const profileItem = getState().profile.items[profileId]
                        if (!profileItem.resumed) {
                            window.clearTimeout(pausingTimeout)
                            window.clearInterval(checkProfileInterval)
                        }
                    }, 800)
                } else {
                    dispatch(updateProfileItem(profileId, { isPausing: false }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: moment().toISOString(),
                        profileId: profileItem.id,
                        user: profileItem.user,
                        hostname: 'Client',
                        type: 'PAUSE_FAIL',
                        message: `Pausing Profile failed due to ${response.status === 403 ? '403 Forbidden' : ('API Error: ' + response.status)}.`
                    }))
                }
            }).catch((error) => {
                dispatch(updateProfileItem(profileId, { isPausing: false }))
                dispatch(addNotificationItem({
                    id: uuidv4(),
                    timestamp: moment().toISOString(),
                    profileId: profileItem.id,
                    user: profileItem.user,
                    hostname: 'Client',
                    type: 'PAUSE_FAIL',
                    message: `Pausing Profile seems failed due to ${error}.`
                }))
            })
        }
    }
}

export function cancelAndPauseProfile (profileId) {
    return (dispatch, getState) => {
        const profileItem = getState().profile.items[profileId]
        if (profileItem) {
            if (profileItem.resumed === true) {
                dispatch(updateProfileItem(profileId, { isPausing: true }))
            }
            dispatch(addNotificationItem({
                id: uuidv4(),
                timestamp: moment().toISOString(),
                profileId: profileItem.id,
                user: profileItem.user,
                hostname: 'Client',
                type: 'OPERATION',
                message: `Try to cancel pending orders and pause the profile.`
            }))
            dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/cancel-and-pause`, {
                method: 'PUT',
                body: JSON.stringify({
                    profile: profileItem.name,
                    user: profileItem.user
                })
            }))
            .then(response => {
                if (response.status !== 200) {
                    dispatch(updateProfileItem(profileId, { isPausing: false }))
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: moment().toISOString(),
                        profileId: profileItem.id,
                        user: profileItem.user,
                        hostname: 'Client',
                        type: 'CANCEL_AND_PAUSE_FAIL',
                        message: `Canceling Profile's Orders failed due to ${response.status === 403 ? '403 Forbidden' : ('API Error: ' + response.status)}.`
                    }))
                }
            })
            .catch((error) => {
                dispatch(addNotificationItem({
                    id: uuidv4(),
                    timestamp: moment().toISOString(),
                    profileId: profileItem.id,
                    user: profileItem.user,
                    hostname: 'Client',
                    type: 'CANCEL_AND_PAUSE_FAIL',
                    message: `Canceling profile's orders seems failed due to ${error}.`
                }))
            })
        }
    }
}

export function switchOnProfileSymbol ({ profileId, symbolName, accountName, side }) {
    return (dispatch, getState) => {
        const { profile } = getState()
        const profileItem = profile.items[profileId]
        if (profileItem) {
            const profileSwitchOffs = _.has(profile.runningState, `${profileId}.switchOffs`) ? profile.runningState[profileId].switchOffs : []
            const switchOffItem = _.find(profileSwitchOffs, { symbol: symbolName, side: side, account: accountName })
            if (switchOffItem) {
                dispatch(addNotificationItem({
                    id: uuidv4(),
                    timestamp: moment().toISOString(),
                    profileId: profileItem.id,
                    user: profileItem.user,
                    hostname: 'Client',
                    type: 'OPERATION',
                    message: `Try to switch on <strong>${symbolName}</strong> in <strong>${side}</strong> side @ <strong>${accountName}</strong>.`
                }))
                dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/switch-on`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        profile: profileItem.name,
                        user: profileItem.user,
                        symbol: symbolName,
                        side: side,
                        account_name: accountName
                    })
                }))
                .then(response => {
                    if (response.status !== 200) {
                        dispatch(addNotificationItem({
                            id: uuidv4(),
                            timestamp: moment().toISOString(),
                            profileId: profileItem.id,
                            user: profileItem.user,
                            hostname: 'Client',
                            type: 'SWITCH_ON_FAIL',
                            message: `The switch-on action seems failed due to ${response.status === 403 ? '403 Forbidden' : ('API Error: ' + response.status)}.`
                        }))
                    }
                })
            }            
        }
    }
}

export function switchOffProfileSymbol ({ profileId, symbolName, side, accountName }) {
    return (dispatch, getState) => {
        const { profile } = getState()
        const profileItem = profile.items[profileId]
        if (profileItem) {
            const profileSwitchOffs = _.has(profile.runningState, `${profileId}.switchOffs`) ? profile.runningState[profileId].switchOffs : []
            const switchOffItem = _.find(profileSwitchOffs, { symbol: symbolName, side: side, account: accountName })
            if (!switchOffItem) {
                dispatch(addNotificationItem({
                    id: uuidv4(),
                    timestamp: moment().toISOString(),
                    profileId: profileItem.id,
                    user: profileItem.user,
                    hostname: 'Client',
                    type: 'OPERATION',
                    message: `Try to switch off <strong>${symbolName}</strong> in <strong>${side}</strong> side @ <strong>${accountName}</strong>.`
                }))
                dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/switch-off`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        profile: profileItem.name,
                        user: profileItem.user,
                        symbol: symbolName,
                        side: side,
                        account_name: accountName
                    })
                })) 
                .then(response => {
                    if (response.status !== 200) {
                        dispatch(addNotificationItem({
                            id: uuidv4(),
                            timestamp: moment().toISOString(),
                            profileId: profileItem.id,
                            user: profileItem.user,
                            hostname: 'Client',
                            type: 'SWITCH_OFF_FAIL',
                            message: `The switch-off action seems failed due to ${response.status === 403 ? '403 Forbidden' : ('API Error: ' + response.status)}.`
                        }))
                    }
                })
            }
        }
    }
}

export function switchProfileSymbolReduceOnly ({ profileId, symbolName, accountName, side, action='ON' }) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        const { profile } = getState()
        const profileItem = profile.items[profileId]
        if (profileItem) {
            const profileReduceOnlySwitches = _.has(profile.runningState, `${profileId}.reduceOnlySwitches`) ? profile.runningState[profileId].reduceOnlySwitches : []
            const isSwitchedOn = _.some(profileReduceOnlySwitches, { account: accountName, symbol: symbolName, side, type: 'REDUCE_ONLY_SWITCH_ON' })
            if ((action === 'ON' && !isSwitchedOn) || (action === 'OFF' && isSwitchedOn)) {
                dispatch(addNotificationItem({
                    id: uuidv4(),
                    timestamp: moment().toISOString(),
                    profileId,
                    user: profileItem.user,
                    hostname: 'Client',
                    type: 'OPERATION',
                    message: `Try to Switch ${action} Reduce-Only for <strong>${symbolName}</strong> in <strong>${side}</strong> side @ <strong>${accountName}</strong>.`
                }))
                dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/reduceonly-switch`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        profile: profileItem.name,
                        user: profileItem.user,
                        symbol: symbolName,
                        side,
                        account_name: accountName,
                        switch: action
                    })
                }))
                .then(response => {
                    if (response.status !== 200) {
                        dispatch(addNotificationItem({
                            id: uuidv4(),
                            timestamp: moment().toISOString(),
                            profileId,
                            user: profileItem.user,
                            hostname: 'Client',
                            type: 'SWITCH_REDUCE_ONLY_FAIL',
                            message: `The switch-reduce-only action seems failed due to ${response.status === 403 ? '403 Forbidden' : ('API Error: ' + response.status)}.`
                        }))
                    } else {
                        resolve(response)
                    }
                })
                .catch(error => reject(error))
            }
        } else {
            reject(new Error('Profile item does not exist'))
        }
    })
}

export function updateProfileParams (profileId, params) {
    return (dispatch, getState) => {
        const { profile, account } = getState()
        const profileItem = profile.items[profileId]
        if (profileItem) {
            const newProfileItem = Object.assign({}, profileItem, params)
            const validation = validateProfileItem({
                profileItem: newProfileItem,
                accountItems: account.items
            })
            if (_.isError(validation)) {
                return new Promise((resolve) => {
                    resolve(validation)
                })
            } else if (validation === true) {
                return dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/profile/update-params`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        profile: profileItem.name,
                        user: profileItem.user,
                        info: params
                    })
                })).then((response) => {
                    if (response.status === 200) {
                        dispatch({
                            type: UPDATE_PROFILE_ITEM,
                            profileId,
                            params
                        })
                    } else {
                        dispatch(addNotificationItem({
                            id: uuidv4(),
                            timestamp: moment().toISOString(),
                            profileId: profileItem.id,
                            user: profileItem.user,
                            hostname: 'Client',
                            type: 'UPDATE_PARAMS_FAIL',
                            message: `Updating Profile's Params failed due to ${response.status === 403 ? '403 Forbidden' : ('API Error: ' + response.status)}.`
                        }))
                    }
                    return response
                }).catch((error) => {
                    dispatch(addNotificationItem({
                        id: uuidv4(),
                        timestamp: moment().toISOString(),
                        profileId: profileItem.id,
                        user: profileItem.user,
                        hostname: 'Client',
                        type: 'UPDATE_PARAMS_FAIL',
                        message: `Updating Profile's Params failed due to ${error}.`
                    }))
                })
            }
        }
    }
}

export function updateProfileItem (profileId, params) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_ITEM,
            profileId,
            params
        })
    }
}

export function updateProfileItems (profileItems) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_ITEMS,
            profileItems
        })
    }
}

export function createProfileGroupItem (group) {
    return (dispatch) => {
        return new Promise((resolve) => {
            group.id = moment().valueOf()
            dispatch({
                type: ADD_PROFILE_GROUP_ITEM,
                group
            })
            dispatch(saveProfileGroups())
            resolve('success')
        })
    }
}

export function updateProfileGroupItem (groupId, params) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_GROUP_ITEM,
            groupId,
            params
        })
        if (groupId !== ALL_PROFILE_GROUP_ID) {
            dispatch(saveProfileGroups())
        }
    }
}

export function updateProfileGroupFocusedId (groupId) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_GROUP_FOCUSED_ID,
            groupId
        })
    }
}

export function updateProfileGroupIds (groupIds) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_GROUP_IDS,
            groupIds
        })
        dispatch(saveProfileGroups())
    }
}

export function deleteProfileGroup (groupId) {
    return (dispatch) => {
        return new Promise((resolve) => {
            dispatch({
                type: REMOVE_PROFILE_GROUP_ITEM,
                groupId
            })
            dispatch(saveProfileGroups())
            resolve('success')
        })
    }
}

export function updateProfileRunningState (profileId, params) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_RUNNING_STATE,
            profileId,
            params
        })
    }
}

export function updateProfileRunningStates (runningStates) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_RUNNING_STATES,
            runningStates
        })
    }
}

const validateProfileItem = ({profileItem, accountItems }) => {
    const { name, params, legs, accounts, user } = profileItem

    const isEmptyString = (value) => {
        return _.isString(value) && value.trim().length === 0
    }

    const isNonEmptyString = (value) => {
        return _.isString(value) && value.trim().length > 0
    }

    const getLegNameByIndex = (index) => {
        return index === 0 ? 'QUOTE' : index === 1 ? 'HEDGE' : `LEG ${index+1}`
    }

    const validateParam = (paramValue, paramConfig) => {
        const paramType = _.has(paramConfig, 'type') ? paramConfig.type : null
        if (paramType === parameterTypes.BOOLEAN) {
            return _.isBoolean(paramValue)
        } else if (paramType === parameterTypes.NUMBER) {
            return _.isFinite(paramValue)
        } else if ([parameterTypes.STRING, parameterTypes.SYMBOL].includes(paramType)) {
            return isNonEmptyString(paramValue)
        } else if (paramType === parameterTypes.BUY_SELL_BOOLEAN_ARRAY) {
            return _.isArray(paramValue) && paramValue.length === 2 && paramValue.every(v => _.isBoolean(v))
        } else if ([parameterTypes.BUY_SELL_NUMBER_ARRAY, parameterTypes.MIN_MAX_NUMBER_ARRAY].includes(paramType)) {
            return _.isArray(paramValue) && paramValue.length === 2 && paramValue.every(v => _.isFinite(v))
        } else if (paramType === parameterTypes.NUMBER_ARRAY_WITH_LENGTH_MUTLIPLIER) {
            return _.isArray(paramValue) && paramConfig.arrayLengthMultiplier > 0 && paramValue.length % paramConfig.arrayLengthMultiplier === 0 && paramValue.every(v => _.isFinite(v))
        } else if (paramType === parameterTypes.STRING_ARRAY) {
            return _.isArray(paramValue) && paramValue.every(v => _.isString(v))
        } else {
            return !_.isNil(paramValue) && !isEmptyString(paramValue) &&
            (_.isArray(paramValue) ? paramValue.every(v => !_.isNil(v) && !isEmptyString(v)) : true)
        }
    }

    const validateLegSymbols = () => {
        let error = null
        Object.values(profileItem.legs).forEach((legItem, legIndex) => {
            const legSymbols = legItem.symbols
            if (_.isNull(error) && (_.isEmpty(legSymbols) || _.find(legSymbols, (symbolItem) => !isNonEmptyString(symbolItem.name) || symbolItem.name === 'INVALID'))) {
                error = new Error(`${getLegNameByIndex(legIndex)} has invalid symbol`)
            }
            if (_.size(_.uniqBy(legSymbols, 'name')) < _.size(legSymbols)) {
                error = new Error(`${getLegNameByIndex(legIndex)} have duplicate symbols`)
            }
        })
        return error || true
    }

    const validateProfileParams = () => {
        let error = null
        _.forEach(profileItem.params, (paramValue, paramKey) => {
            const paramConfig = profileParameters[paramKey]
            if (_.isNull(error) && validateParam(paramValue, paramConfig) === false && !paramKey.includes('_MARGINRATIO_THRESHOLD')) {
                error = new Error(`${_.has(paramConfig, 'name') ? paramConfig.name : paramKey} is not valid`)
            }
        })
        return error || true
    }

    const validateLegParams = () => {
        let error = null
        Object.values(profileItem.legs).forEach((legItem, legIndex) => {
            _.forEach(legItem.params, (paramValue, paramKey) => {
                const paramConfig = legParameters[`leg${legIndex+1}`][paramKey]
                if (_.isNull(error) && validateParam(paramValue, paramConfig) === false) {
                    error = new Error(`${getLegNameByIndex(legIndex)} ${_.has(paramConfig, 'name') ? paramConfig.name : paramKey} is not valid`)
                }
            })
        })
        return error || true
    }

    const validateStrategyParams = () => {
        let error = null
        Object.values(profileItem.legs).forEach((legItem, legIndex) => {
            const strategyType = _.has(legItem, 'strategy.type') ? legItem.strategy.type : null
            if (_.isNull(error)) {
                if (_.isNil(strategyType) || !legStrategyTypes[`leg${legIndex+1}`].includes(strategyType)) {
                    error = new Error(`${getLegNameByIndex(legIndex)} strategy type is not valid`)
                } else {
                    _.forEach(legItem.strategy.params, (paramValue, paramKey) => {
                        const paramConfig = getStrategyParamConfig(strategyType, paramKey)
                        if (_.isNull(error) && validateParam(paramValue, paramConfig) === false) {
                            error = new Error(`${getLegNameByIndex(legIndex)} ${_.has(paramConfig, 'name') ? paramConfig.name : paramKey} is not valid`)
                        }
                    })
                }
            }
        })
        return error || true
    }

    const validateSymbolParams = () => {
        let error = null
        Object.values(profileItem.legs).forEach((legItem, legIndex) => {
            const legSymbols = legItem.symbols
            const strategyType = _.has(legItem, 'strategy.type') ? legItem.strategy.type : null
            if (!_.isArray(legSymbols) || _.isEmpty(legSymbols)) {
                error = new Error(`${getLegNameByIndex(legIndex)} symbols cannot be empty`)
            } else {
                const sortedSymbolParamKeys = Object.keys(legSymbols[0].params || {}).sort()
                const sortedSymbolStrategyParamKeys = Object.keys(legSymbols[0].strategyParams || {}).sort()
                legSymbols.forEach((symbol, symbolIndex) => {
                    if (_.isNull(error)) {
                        const differentParamKeySet = symbolIndex > 0 
                        && (!_.isEqual(sortedSymbolParamKeys, Object.keys(symbol.params || {}).sort()) 
                            || !_.isEqual(sortedSymbolStrategyParamKeys, Object.keys(symbol.strategyParams || {}).sort()))

                        if (!isNonEmptyString(symbol.name)) {
                            error = new Error(`${getLegNameByIndex(legIndex)} has invalid symbol at INDEX ${symbolIndex}`)
                        } else if (differentParamKeySet) {
                            error = new Error(`${getLegNameByIndex(legIndex)} symbols have inconsistent set of parameters`)
                        } else {
                            _.forEach(symbol.params, (paramValue, paramKey) => {
                                const paramConfig = symbolParametrs[`leg${legIndex + 1}`][paramKey]
                                if (_.isNull(error) && validateParam(paramValue, paramConfig) === false) {
                                    error = new Error(`${getLegNameByIndex(legIndex)} ${symbol.name} ${_.has(paramConfig, 'name') ? paramConfig.name : paramKey} is not valid`)
                                }
                            })
                            _.forEach(symbol.strategyParams, (paramValue, paramKey) => {
                                const paramConfig = getStrategyParamConfig(strategyType, paramKey)
                                if (_.isNull(error) && validateParam(paramValue, paramConfig) === false) {
                                    error = new Error(`${getLegNameByIndex(legIndex)} ${symbol.name} ${_.has(paramConfig, 'name') ? paramConfig.name : paramKey} is not valid`)
                                }
                            })
                        }
                    }
                })
            }
        })
        return error || true
    }

    const validateAccounts = () => {
        let error = null
        let accountPortfolioName = null
        const profileExchangeNames = getProfileExchangeNames(profileItem)
        const profileSymbolNames = getProfileSymbolNames(profileItem)

        const isInvalidAccount = (accountName) => {
            return !isNonEmptyString(accountName) || accountName === 'INVALID'
        }

        profileExchangeNames.forEach(profileExchangeName => {
            const accountName = profileItem.accounts[profileExchangeName]
            if (_.isNull(error)) {
                if (profileExchangeName !== 'OKEXSPOTMARGIN' && isInvalidAccount(accountName)) {
                    const symbolNamesTradedInCurrentExchange = _.filter(profileSymbolNames, symbolName => {
                        const symbolExchangeName = getProfileExchangeNameBySymbol(profileItem, symbolName)
                        return [symbolExchangeName.BUY, symbolExchangeName.SELL].includes(profileExchangeName)
                    })
                    error = new Error(`You have to select an account at ${getExchangeDisplayName(profileExchangeName)} to trade ${symbolNamesTradedInCurrentExchange.join(', ')}`)
                } else {
                    if (accountName === 'smart_pos_acct') {
                        const profileSmartPositionAccounts = getProfileSmartPositionAccountsByExchangeName(profileItem, profileExchangeName)
                        _.forEach(_.concat(profileSmartPositionAccounts.BUY, profileSmartPositionAccounts.SELL, profileSmartPositionAccounts.BACKUP), accountName => {
                            const accountItem = accountItems[accountName]
                            if (!_.isNil(accountItem)) {
                                if (_.isNull(accountPortfolioName)) {
                                    accountPortfolioName = accountItem.portfolio_name
                                } 
                                if (accountItem.portfolio_name !== accountPortfolioName && _.isNull(error)) {
                                    error = new Error('The profile is not allowed to have accounts belonging to different portfolios')
                                }
                            }
                        })
                    } else {
                        const accountItem = accountItems[accountName]
                        if (!_.isNil(accountItem)) {
                            if (_.isNull(accountPortfolioName)) {
                                accountPortfolioName = accountItem.portfolio_name
                            }
                            if (accountItem.portfolio_name !== accountPortfolioName && _.isNull(error)) {
                                error = new Error('The profile is not allowed to have accounts belonging to different portfolios')
                            }
                        }
                    }
                }
            }
        })

        return error || true
    }

    let error = null
    if (!isNonEmptyString(name)) {
        error = new Error('Profile name cannot be empty')
    } else if (_.isEmpty(params)) {
        error = new Error('Params cannot be empty')
    } else if (_.isEmpty(legs)) {
        error = new Error('Legs cannot be empty')
    } else if (_.isEmpty(accounts)) {
        error = new Error('Accounts cannot be empty')
    } else if (!isNonEmptyString(user)) {
        error = new Error('User cannot be empty')
    } else {
        error = [validateLegSymbols, validateProfileParams, validateLegParams, validateStrategyParams, validateSymbolParams, validateAccounts].reduce((errorResult, validate) => {
            const validation = validate()
            if (_.isError(validation) && _.isNull(errorResult)) {
                errorResult = validation
            }
            return errorResult
        }, null)
    }
    return error || true
}

export const copyProfile = (profileItem, newName, newUser) => {
    return (dispatch) => {
        return dispatch(secureFetch(`${getApiBaseUrlByHostname(profileItem.hostname)}/create-profile`, {
            method: 'POST',
            body: JSON.stringify({
                new_profile: newName,
                new_profile_user: newUser,
                copy_profile: profileItem.name,
                copy_profile_user: profileItem.user
            })
        })).then((response) => {
            if (response.status === 200) {
                const newProfileId = `${newName}_${profileItem.hostname}`
                const newProfileItem = ProfileItem({
                    profileId: newProfileId,
                    profileName: newName,
                    hostname: profileItem.hostname,
                    user: newUser
                })
                dispatch(updateProfileItem(newProfileId, newProfileItem))
                dispatch(fetchProfileParams({
                    profileId: newProfileId
                })).then((body) => {
                    if (body && !_.isEmpty(body[newName])) {
                        const newProfileItem = ProfileItem({
                            profileId: newProfileId,
                            profileParams: body[newName],
                            profileName: newName,
                            hostname: profileItem.hostname,
                            user: newUser,
                            status: profileDefaultStatus
                        })
                        dispatch({
                            type: COPY_PROFILE_SUCCESS,
                            originalProfileItem: profileItem,
                            newProfileItem
                        })
                        dispatch(saveProfileGroups())
                    }
                })
            }
            return response
        })
    }
}

export const fetchProfileGroups = () => {
    return (dispatch, getState) => new Promise(resolve => {
        const username = getState().auth.username
        dispatch(secureFetch(`${ELF_API_BASE_URL}/profile-groups?username=${username}`))
        .then(response => response.json())
        .then(body => {
            if (body && _.isArray(body.profileGroups)) {
                const newProfileGroup = _.cloneDeep(getState().profile.group)
                _.merge(newProfileGroup.items, _.keyBy(body.profileGroups, 'id'))
                newProfileGroup.ids = [ALL_PROFILE_GROUP_ID].concat(body.profileGroups.map(profileGroupItem => profileGroupItem.id))
                dispatch({
                    type: UPDATE_PROFILE_GROUP,
                    profileGroup: newProfileGroup
                })
                resolve(newProfileGroup)
            } else {
                throw new Error('unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchProfileGroups error: ', error)
        })
    })
}

export const saveProfileGroups = () => {
    return (dispatch, getState) => {
        const { username } = getState().auth
        const { group } = getState().profile
        const profileGroups = _.filter(group.ids, profileGroupId => profileGroupId !== ALL_PROFILE_GROUP_ID && _.has(group.items, profileGroupId))
            .map(profileGroupId => group.items[profileGroupId])
        return dispatch(secureFetch(`${ELF_API_BASE_URL}/profile-groups?username=${username}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(profileGroups)
        })).then(response => {
            dispatch(addNotificationItem({
                id: uuidv4(),
                timestamp: moment().toISOString(),
                user: username,
                type: response.status === 200 ? 'SAVE_PROFILE_GROUPS' : 'PROFILE_GROUPS_SAVE_FAIL',
                message: response.status === 200 ? `You succesfully update profile groups.` : `Error Code: ${response.status}`
            }))
        })
    }
}

export const updateProfileSearchString = (searchString, shouldSwitchToAllProfileGroup=true) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_SEARCH_STRING,
            searchString,
            shouldSwitchToAllProfileGroup
        })
    }
}

export const verfiyProfilesPermission = (profiles=[]) => {
    return (dispatch) => new Promise(resovle => {
        dispatch(secureFetch(`${DEFAULT_SERVER.apiBaseUrl}/profiles/permission`, {
            method: 'POST',
            body: JSON.stringify({
                profiles: profiles.map(profileItem => _.pick(profileItem, ['name', 'hostname', 'user']))
            })
        }))
        .then(response => {
            resovle(response.status === 200)
        })
        .catch(e => {
            console.log('Verify Profiles Permission Error: ', e)
            resovle(false)
        })
    })
}

export const updateProfileStoppedUpdatingSymbolPricings = (stoppedUpdatingSymbolPricings=[]) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_STOPPED_UPDATING_SYMBOL_PRICINGS,
            stoppedUpdatingSymbolPricings
        })
    }
}

export const updateProfileOrderEditorVariables = (variables={}) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PROFILE_ORDER_EDITOR_VARIABLES,
            variables
        })
    }
}

export const updateManualOrderGlobalMarginRatioThreshold = (globalMarginRatioThreshold=0.15) => {
    return (dispatch) => new Promise((resolve, reject) => {
        const manualOrderProfileServer = SERVERS[process.env.REACT_APP_MANUAL_ORDER_PROFILE_HOSTNAME]
        if (manualOrderProfileServer) {
            dispatch(secureFetch(`${manualOrderProfileServer.apiBaseUrl}/web_global_margin_ratio_threshold`, {
                method: 'POST',
                body: JSON.stringify({
                    web_global_margin_ratio_threshold: globalMarginRatioThreshold
                })
            }))
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                console.error('postProfileOrderEditorVariables Error,', error)
                reject(error)
            })
        }
    })
}

export const fetchUserProfiles = () => {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/userProfiles`))
        .then(response => response.json())
        .then(body => {
            if (_.isObject(body)) {
                dispatch(updateUserProfiles(body))
                resolve(body)
            } else {
                throw new Error('unexpted return')
            }
        })
        .catch(error => {
            console.error('fetchUserProfiles error', error)
            reject(error)
        })
    })
}

export const updateUserProfiles = (userProfiles={}) => {
    return (dispatch, getState) => {
        const prevUserProfiles = getState().profile.userProfiles
        if (_.isEmpty(prevUserProfiles.lastUpdateTime)
            || (!_.isEmpty(userProfiles.lastUpdateTime) && moment(userProfiles.lastUpdateTime).isAfter(prevUserProfiles.lastUpdateTime))) {
            dispatch({
                type: UPDATE_USER_PROFILES,
                userProfiles
            })
        }
    }
}

export const fetchSignedSwitchedOffProfileSymbols = () => {
    return (dispatch) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/signedSwitchedOffProfileSymbols`))
        .then(response => response.json())
        .then(body => {
            if (_.isObject(body)) {
                dispatch(updateSignedSwitchedOffProfileSymbols(body))
                resolve(body)
            } else {
                throw new Error('Unexpected return')
            }
        })
        .catch(error => {
            console.error('fetchUserProfiles error', error)
            reject(error)
        })
    })
}

export const postSignedSwitchOffProfileSymbols = (signedSwitchedOffProfileSymbolItems=[]) => {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/signedSwitchedOffProfileSymbols`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                signedSwitchedOffProfileSymbolItems,
                updateBy: getState().auth.username,
                updateId: uuidv4()
            })
        }))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error(`Status code: ${response.status}`)
            }
        })
        .then(body => {
            if (_.isObject(body)) {
                dispatch(updateSignedSwitchedOffProfileSymbols(body))
                resolve(body)
            } else {
                throw new Error('Unexpected return')
            }
        })
        .catch(error => {
            console.error('postSignedSwitchOffProfileSymbols error', error)
            reject(error)
        })
    })
}

export const deleteSignedSwitchOffProfileSymbols = (signedSwitchedOffProfileSymbolItems=[]) => {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(secureFetch(`${ELF_API_BASE_URL}/signedSwitchedOffProfileSymbols`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                signedSwitchedOffProfileSymbolItems,
                updateBy: getState().auth.username,
                updateId: uuidv4()
            })
        }))
        .then(response => {
            if (response.status === 200) {
                return response.json()
            } else {
                throw new Error(`Status code: ${response.status}`)
            }
        })
        .then(body => {
            if (_.isObject(body)) {
                dispatch(updateSignedSwitchedOffProfileSymbols(body))
                resolve(body)
            } else {
                throw new Error('Unexpected return')
            }
        })
        .catch(error => {
            console.error('deleteSignedSwitchOffProfileSymbols error', error)
            reject(error)
        })
    })
}

export const updateSignedSwitchedOffProfileSymbols = (signedSwitchedOffProfileSymbols={}) => {
    return (dispatch, getState) => {
        const prevSignedSwitchedOffProfileSymbols = getState().profile.signedSwitchedOffProfileSymbols
        if (_.isEmpty(prevSignedSwitchedOffProfileSymbols.lastUpdateTime)
            || (!_.isEmpty(signedSwitchedOffProfileSymbols.lastUpdateTime) && moment(signedSwitchedOffProfileSymbols.lastUpdateTime).isAfter(prevSignedSwitchedOffProfileSymbols.lastUpdateTime))) {
            dispatch({
                type: UPDATE_SIGNED_SWITCHED_OFF_PROFILE_SYMBOLS,
                signedSwitchedOffProfileSymbols
            })
        }
    }
}

export const fetchProfileFillStatistics = ({ profile, hostname, fromTimestamp, toTimestamp }) => {
    return (dispatch) => new Promise((resolve, reject) => {
        const query = getQueryString({
            profile,
            hostname,
            fromTimestamp,
            toTimestamp
        })
        dispatch(secureFetch(`${ELF_API_BASE_URL}/profileFillStatistics?${query}`))
        .then(response => response.json())
        .then(body => resolve(body))
        .catch(error => {
            console.error('fetchProfileFillStatistics error: ', error)
            reject(error)
        })
    })
}