import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { FiEye, FiEyeOff } from 'react-icons/fi'
import { MdMultilineChart } from 'react-icons/md'
import SymbolFundingRates from './SymbolFundingRates'
import Popup from '../common/popup/Popup'
import SymbolFundingRateHistory from './SymbolFundingRateHistory'
import FundingTags from './FundingTags'

const getSessionStorageFundingTagId = () => {
    return sessionStorage.fundingTagId || null
}

const updateSessionStorageFundingTagId = (newFundingTagId) => {
    sessionStorage.fundingTagId = newFundingTagId
}

class SymbolFundingRateContainer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            searchString: '',
            shouldHideUnkownSymbols: this.getShouldHideUnkownSymbols(),
            selectedFundingTagId: getSessionStorageFundingTagId()
        }
    }

    getShouldHideUnkownSymbols () {
        return _.has(sessionStorage, 'symbolRundingRateShouldHideUnkownSymbols') && sessionStorage.symbolRundingRateShouldHideUnkownSymbols === '1'
    }

    updateShouldHideUnkownSymbols (newShouldHideUnkownSymbols) {
        sessionStorage.symbolRundingRateShouldHideUnkownSymbols = newShouldHideUnkownSymbols ? '1' : '0'
        this.setState({
            shouldHideUnkownSymbols: this.getShouldHideUnkownSymbols()
        })
    }

    CumulativeFundingRatePopup () {
        return (
            <Popup className='cumulative-funding-rate-popup' 
                align={'horizontal'}
                on={'click'}
                trigger={<button className='cumulative-funding-rate-popup--trigger-button'><MdMultilineChart /></button>}>
                <div className='cumulative-funding-rate-popup--header'>
                    <div className='cumulative-funding-rate-popup--header--title'>{'Cumulative Funding %'}</div>
                </div>
                <div className='cumulative-funding-rate-popup--body'>
                    <SymbolFundingRateHistory />
                </div>
            </Popup>
        )
    }

    render () {
        const { searchString, shouldHideUnkownSymbols, selectedFundingTagId } = this.state
        const { lastUpdateTime, fundingTags } = this.props
        const selectedFundingTag = _.find(fundingTags, { id: selectedFundingTagId })
        return (
            <div className='symbol-funding-rate-container'>
                <div className='symbol-funding-rate-container--header'>
                    <span className='symbol-funding-rate-container--title'>{'Funding Rates'}</span>
                    <input className='symbol-funding-rate-container--search' 
                        placeholder={'Search Symbol'}
                        spellCheck={false}
                        value={searchString} 
                        onChange={(e) => { this.setState({ searchString: e.target.value }) }} />
                    <div className='symbol-funding-rate-container--info'>
                        {lastUpdateTime && <span className='symbol-funding-rate-container--last-update-time'>{`Update Time: ${lastUpdateTime.split(' ')[1]}`}</span>}
                    </div>
                    <div className='symbol-funding-rate-container--menu clearfix'>
                        <div className='symbol-funding-rate-container--menu--tags'>
                            <FundingTags 
                                selectedFundingTagId={selectedFundingTagId}
                                onOnchangeFundingTagId={(newTagId) => {
                                    updateSessionStorageFundingTagId(newTagId)
                                    this.setState({ selectedFundingTagId: getSessionStorageFundingTagId() })
                                }} />
                        </div>
                        <Popup className='symbol-funding-rate-container--toggle-unkown-symbol-button--popup'
                            trigger={<button className='symbol-funding-rate-container--toggle-unkown-symbol-button'
                                onClick={() => {
                                    this.updateShouldHideUnkownSymbols(!shouldHideUnkownSymbols)
                                }}>
                                {shouldHideUnkownSymbols ? <FiEyeOff /> : <FiEye />}
                            </button>}>
                            {shouldHideUnkownSymbols ? 'Show All Symbols' : 'Hide Unconfigured Symbols'}
                        </Popup>
                        {this.CumulativeFundingRatePopup()}
                    </div>
                </div>
                <div className='symbol-funding-rate-container--body'>
                    <SymbolFundingRates 
                        searchString={searchString} 
                        coinsToFilter={_.has(selectedFundingTag, 'coins') ? selectedFundingTag.coins : []}
                        shouldHideUnkownSymbols={shouldHideUnkownSymbols} />
                </div>
            </div>    
        )
    }
}

SymbolFundingRateContainer.propTypes = {
    lastUpdateTime: PropTypes.string,
    fundingTags: PropTypes.array.isRequired
}

function mapStateToProps (state) {
    const fundingRates = state.symbol.fundingRates
    return {
        lastUpdateTime: !_.isEmpty(fundingRates) ? Object.values(fundingRates)[0].current : null,
        fundingTags: state.symbol.fundingTags
    }
}

export default connect(mapStateToProps)(SymbolFundingRateContainer)

