import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { authLogin } from './authAction'

class AuthForm extends Component {
    constructor (props) {
        super(props)
        this.formTypes = ['LOG_IN', 'RESET_PASSWORD']
        this.emailDomain = '@antelopetechnology.com'
        this.state = {
            formType: this.formTypes[0],
            message: null,
            isLoggingIn: false
        }
        this.usernameInput = null
        this.passwordInput = null
        this.gaCodeInput = null
        this.loginButton = null
        this.resetPasswordButton = null
    }

    _capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }
    
    handleClickChangeFormButton (e) {
        e.preventDefault()
        this.setState((prevState) => {
            return {
                formType: prevState.formType === 'LOG_IN' ? 'RESET_PASSWORD' : 'LOG_IN',
                message: null
            }
        })
    }

    handleClickLoginButton (e) {
        e.preventDefault()
        const {dispatch, history} = this.props
        const username = this.usernameInput.value.trim()
        const password = this.passwordInput.value.trim()
        const gaCode = this.gaCodeInput.value.trim()
        if (_.isEmpty(username)) {
            this.setState({ message: 'Please enter your username' })
        } else if (_.isEmpty(password)) {
            this.setState({ message: 'You forgot to enter your password' })
        } else if (_.isEmpty(gaCode)) {
            this.setState({ message: 'You forgot to enter GA Code' })
        } else {
            this.setState({ 
                message: null,
                isLoggingIn: true
            })
            dispatch(authLogin(username, password, gaCode))
            .then(() => { history.push('/') })
            .catch((errorMessage) => {
                const message = !_.isEmpty(errorMessage) 
                    ? (errorMessage === 'Invalid user' ? 'The username is not permitted to access.' 
                        : errorMessage === 'passwd is wrong' ? 'Wrong password' 
                        : this._capitalize(errorMessage)) 
                    : 'Network error! Please try again later.'
                this.setState({
                    isLoggingIn: false,
                    message: message
                })
            })
        }
    }
    
    renderFormInput ({className, label, autoFocus, inputType, onMount}) {
        const {formType, message} = this.state
        return (
            <div className={'auth-form--input-block' + (className ? ` ${className}` : '')}>
                <label className='auth-form--input-label'>{label}</label>
                <input className='auth-form--input'
                    ref={(node) => { if (_.isFunction(onMount)){ onMount(node) } }}
                    autoFocus={autoFocus} 
                    type={inputType} 
                    spellCheck={false} 
                    autoComplete={''}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            const button = formType === 'LOG_IN' ? this.loginButton : this.resetPasswordButton
                            button.click()
                        } else if (message) {
                            this.setState({ message: null })
                        }
                    }} />
            </div>
        )
    }

    render () {
        const {formType, message, isLoggingIn} = this.state
        return (
            <div className='auth-form clearfix'>
                <div className='auth-form--title'>{'Antelope Technology'}</div>
                <div className='auth-form--caption'>{'Win or Go Home'}</div>
                <form className='auth-form--main'>
                    {this.renderFormInput({
                        label: 'Username',
                        autoFocus: true,
                        inputType: 'text',
                        onMount: (node) => { this.usernameInput = node } 
                    })}
                    {formType === 'LOG_IN' && this.renderFormInput({
                        label: 'Password',
                        autoFocus: false,
                        inputType: 'password',
                        onMount: (node) => { this.passwordInput = node }
                    })}
                    {formType === 'LOG_IN' && this.renderFormInput({
                        label: 'GA Code',
                        autoFocus: false,
                        inputType: 'text',
                        onMount: (node) => { this.gaCodeInput = node }
                    })}
                    {message && <div className='auth-form--message'>{message}</div>}
                    {formType === 'LOG_IN' 
                    ? <button className='auth-form--login-button' ref={(node) => { this.loginButton = node }}
                        disabled={isLoggingIn}
                        onClick={(e) => { this.handleClickLoginButton(e) }}>{isLoggingIn ? 'LOGGING IN ...' : 'LOG IN'}</button>
                    : <button className='auth-form--reset-password-button' ref={(node) => { this.resetPasswordButton = node }}
                        onClick={(e) => { e.preventDefault() }}>{'RESET PASSWORD'}</button>}
                    <button className={'auth-form--change-form-button hidden ' + (formType === 'LOG_IN' ? 'forgot_password' : 'log_in')}
                        onClick={(e) => { this.handleClickChangeFormButton(e) }}>
                        {formType === 'LOG_IN' ? 'Forgot Password?' : 'Log In'}
                    </button>
                </form>
            </div>
        )
    }
}

AuthForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}


export default withRouter(connect()(AuthForm))