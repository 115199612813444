import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { toNumberWithSmartPrecision } from '../../util/util'

export default class SpotAccountBalanceItem extends Component {
    Row ({ name, value }) {
        return (
            <div className='spot-account-balance-item--row'>
                <span className='spot-account-balance-item--row--name'>{name}</span>
                <div className='spot-account-balance-item--row--value'>{value}</div>
            </div>
        )
    }
    render () {
        const { spotAccountBalance, shouldShowAccountTypeTag, shouldShowAccountName, shouldShowDetail } = this.props
        return (
            <div className='spot-account-balance-item'>
                <div className='spot-account-balance-item--header clearfix'>
                    {shouldShowAccountTypeTag && <div className='spot-account-balance-item--header--info-bar'>
                        <div className='spot-account-balance-item--header--info-bar--account-type spot'>{'SPOT'}</div>
                        <div className='spot-account-balance-item--header--info-bar--timestamp'>{moment(spotAccountBalance.timestamp).format('HH:mm:ss')}</div>
                    </div>}
                    <div className='spot-account-balance-item--coin'>
                        {spotAccountBalance.coin}
                        {shouldShowAccountName ? ` @ ${spotAccountBalance.acct_name}` : ''}
                    </div>
                    {!shouldShowAccountTypeTag && <div className='spot-account-balance-item--timestamp'>{moment(spotAccountBalance.timestamp).format('HH:mm:ss')}</div>}
                </div>
                <div className='spot-account-balance-item--rows'>
                    {shouldShowDetail && this.Row({
                        name: 'Available',
                        value: toNumberWithSmartPrecision({ number: spotAccountBalance.available, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                    {shouldShowDetail && this.Row({
                        name: 'On Hold',
                        value: toNumberWithSmartPrecision({ number: spotAccountBalance.hold, shouldReturnLocalString: true })
                    })}
                    {this.Row({
                        name: 'Acct Balance',
                        value: toNumberWithSmartPrecision({ number: spotAccountBalance.balance, shouldReturnLocalString: true, shouldApplyFloorValue: true })
                    })}
                </div>
            </div>
        )
    }
}

SpotAccountBalanceItem.propTypes = {
    spotAccountBalance: PropTypes.object.isRequired,
    shouldShowAccountTypeTag: PropTypes.bool,
    shouldShowAccountName: PropTypes.bool,
    shouldShowDetail: PropTypes.bool,
}